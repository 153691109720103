import React, { Suspense, useEffect, useState } from 'react'
import Footer from '../../../common/Footer/Footer';
import Header from '../../../common/Header/Header';
import { fetchHackathonContentSuccess, getBlogathonListData, getCompetitionData, updateWelcomeLoginModal } from '../../../../store/Actions/Action';
import { connect } from 'react-redux';
import { calculateTimeRemainingInMilliSeconds, convertDateInFormat, convertDateToLocalDatetime, fetchDatahackData } from '../../../../utils';
import Flashstrip from '../../../common/Header/Flashstrip';
import AvLogo from '../../../../assets/images/AV_logo.png';
import Cookies from '../../../common/Footer/Cookies';
import $ from 'jquery';
const CompetitionHomeSwiperComponent1 = React.lazy(() => import("../components/CompetitionHomeSwiperComponent1"));
const CompetitionHomeSwiperComponent2 = React.lazy(() => import("../components/CompetitionHomeSwiperComponent2"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
const datahack_s3_url=process.env.REACT_APP_DATAHACK_S3_URL;


function CompetitionHome({getCompetitionDataHandler,competitioncontent,hackathonContent,fetchHackathonContentSuccessHandler,fetchBlogathonListContentSuccessHandler,activeBlogathonTournament,islogin,updateWelcomeLoginModalHandler,csrf}) {
    const [activeBlogathon,setActiveBlogathon] = useState(null);
    const [dataminParticipant,setDataminParticipant] = useState(null);

    useEffect(()=>{
        if(!competitioncontent){
            getCompetitionDataHandler();
        }

        console.log( competitioncontent && competitioncontent.trendingTournaments)

        // Function calling for getting datahack data for homepage
        if(!hackathonContent){
            fetchDatahackData('hackathon')
            .then((data)=>{
                fetchHackathonContentSuccessHandler(data)
            })
            .catch((error)=>{
            console.log(error);
            })
        }
    },[competitioncontent])

    useEffect(()=>{
        if(!activeBlogathonTournament){
            fetchBlogathonListContentSuccessHandler();
        }
        console.log(activeBlogathonTournament && activeBlogathonTournament.tournamentData[0]);
        // fetch("https://datahack.analyticsvidhya.com/api/get-active-blogathons/", {
        //     method: 'GET',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       'Authorization': 'Token dfff2677f82706479cd30c26e03a14e2ed9add10'
        //     },
        //   })
        // .then((response) => {
        //   if (!response.ok) {
        //     console.error('Network response was not ok');
        //   }
        //   return response.json();
        // })
        // .then((data) => {
        //   console.log(data);
        //   setActiveBlogathon(data.active_blogathons[0])
        // })
        // .catch((error) => {
        //   console.error(error.message);
        // });
    },[activeBlogathonTournament])

    useEffect(()=>{
        if(csrf && islogin){
            const formData = new FormData();
            formData.append("platform", 'newcompetition');
            formData.append("url", 'api/datamin-participant-count/');
            formData.append("method","GET");
            formData.append("forward_user_data","TRUE");
        
            fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
            }).then(response => {
              return response.json();
            })
            .then((result)=> {
                console.log(result.response.data);
                setDataminParticipant(result.response.data);
            })
            .catch(error => console.log('error', error));
        }
        if(csrf && islogin == false){
            const formData = new FormData();
            formData.append("platform", 'newcompetition');
            formData.append("url", 'api/datamin-participant-count/');
            formData.append("method","GET");
        
            fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },

            }).then(response => {
              return response.json();
            })
            .then((result)=> {
                console.log(result.response.data);
                setDataminParticipant(result.response.data);
            })
            .catch(error => console.log('error', error));
        }
    },[csrf]);


    useEffect(()=>{
        $('#experience .nav-link').click((e)=>{
            let height = $('#experience .nav-link.active').index();
            $('#v-pills-tab').css('--height', `${25 * (height+1)}%`);
        })
    },[]);

  return (
    <>
        <Header />
        <Suspense fallback={<></>}>
            <CompetitionHomeSwiperComponent1 />
        </Suspense>
        <section className='background-dark-secondary' id='number'>
            <div className='container'>
                <div className='row gx-4'>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>4M+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Community Members</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>300+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Competitions</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>500K+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Participants</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>400+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Clients</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='common-style common-card-style background-dark-primary hover-state pb-5' id='compete'>
            <div className="container">
                <div className='text-white mx-auto text-center col-lg-8 heading-with-text mb-5'>
                    <h2 className='fs-40 fw-normal mb-3'>Choose Your AI Battle</h2>
                    <p className='fs-18 fw-normal'>Boost your skills with daily challenges and climb the leaderboard to stand out in the community</p>
                </div>
                <div className="row g-4">
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div id='dataEngineeringDatamin' className='card p-0 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3 overflow-hidden'>
                            {islogin ?
                            <a href="/datahack/datamin/test-yourself-datamin/data-engineering-datamin/3f672898-1b76-469b-be17-f08f91d7876d" target='_blank' rel="noreferrer" >
                                <div className='card-img rounded-0 position-relative overflow-hidden'>
                                    <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/data_engineering.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                    {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </button> */}
                                </div>
                            </a>
                            :
                            <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                <div className='card-img rounded-0 position-relative overflow-hidden'>
                                    <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/data_engineering.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                    {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </button> */}
                                </div>
                            </button>
                            }
                            <div className='card-body px-4 pb-0 pt-3'>
                                {islogin?
                                <a className='text-decoration-none' href='/datahack/datamin/test-yourself-datamin/data-engineering-datamin/3f672898-1b76-469b-be17-f08f91d7876d' target='_blank' rel="noreferrer" >
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Engineering</h2>
                                </a>
                                :
                                <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Engineering</h2>
                                </button>
                                }
                                <h3 className='text-dark-tertiary fs-16 mb-3 d-flex align-items-center fw-light'>
                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clip-path="url(#clip0_13711_73719)">
                                            <path d="M18 11.25C18.8734 11.2493 19.7349 11.4524 20.516 11.843C21.2972 12.2335 21.9765 12.8009 22.5 13.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.5 13.5C2.0235 12.8009 2.70281 12.2335 3.48398 11.843C4.26514 11.4524 5.12663 11.2493 6 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 17.25C14.0711 17.25 15.75 15.5711 15.75 13.5C15.75 11.4289 14.0711 9.75 12 9.75C9.92893 9.75 8.25 11.4289 8.25 13.5C8.25 15.5711 9.92893 17.25 12 17.25Z" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.75 20.25C7.2884 19.3364 8.0559 18.579 8.97665 18.0529C9.8974 17.5267 10.9395 17.25 12 17.25C13.0605 17.25 14.1026 17.5267 15.0233 18.0529C15.9441 18.579 16.7116 19.3364 17.25 20.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.0938 7.5C15.234 6.95678 15.5238 6.46377 15.9302 6.07697C16.3366 5.69017 16.8433 5.42508 17.3927 5.31179C17.9422 5.19851 18.5125 5.24158 19.0387 5.43611C19.5649 5.63064 20.0261 5.96883 20.3697 6.41229C20.7134 6.85574 20.9258 7.38668 20.9829 7.9448C21.04 8.50293 20.9394 9.06587 20.6926 9.56971C20.4458 10.0735 20.0627 10.4981 19.5867 10.7951C19.1108 11.0921 18.561 11.2497 18 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5.99849 11.25C5.43745 11.2497 4.88774 11.0921 4.41177 10.7951C3.93581 10.4981 3.55267 10.0735 3.30587 9.56971C3.05906 9.06587 2.95849 8.50293 3.01557 7.9448C3.07264 7.38668 3.28508 6.85574 3.62876 6.41229C3.97243 5.96883 4.43357 5.63064 4.9598 5.43611C5.48604 5.24158 6.05627 5.19851 6.60575 5.31179C7.15523 5.42508 7.66194 5.69017 8.06832 6.07697C8.4747 6.46377 8.76448 6.95678 8.90474 7.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_13711_73719">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {dataminParticipant && dataminParticipant["Data engineering datamin"] && dataminParticipant["Data engineering datamin"]["Data engineering datamin"]} already participated
                                </h3>
                            </div>
            
                            <div className='card-footer border-0 px-4 pb-3'>
                                {islogin?
                                <a className='text-decoration-none text-white' href="/datahack/datamin/test-yourself-datamin/data-engineering-datamin/3f672898-1b76-469b-be17-f08f91d7876d" target='_blank'>
                                {dataminParticipant && dataminParticipant["Data engineering datamin"] && dataminParticipant["Data engineering datamin"]["already_participated"]?"Resume Now":"Test Now"}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </a>
                                :
                                <button className='btn border-0 text-white'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    Test Now
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div id='dataScienceDatamin' className='card p-0 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3 overflow-hidden'>
                            {islogin ?
                            <a href="/datahack/datamin/test-yourself-datamin/data-science-datamin/b280ffce-0372-438a-a7a1-cbc135c24584" target='_blank' rel="noreferrer" >
                            <div className='card-img rounded-0 position-relative overflow-hidden'>
                                <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/data_science.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </button> */}
                            </div>
                            </a>
                            :
                            <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                <div className='card-img rounded-0 position-relative overflow-hidden'>
                                    <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/data_science.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                    {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </button> */}
                                </div>
                            </button>
                            }
                            <div className='card-body px-4 pb-0 pt-3'>
                                {islogin?
                                <a className='text-decoration-none' href='/datahack/datamin/test-yourself-datamin/data-science-datamin/b280ffce-0372-438a-a7a1-cbc135c24584' target='_blank' rel="noreferrer" >
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Science</h2>
                                </a>
                                :
                                <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Science</h2>
                                </button>
                                }
                                <h3 className='text-dark-tertiary fs-16 mb-3 d-flex align-items-center fw-light'>
                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clip-path="url(#clip0_13711_73719)">
                                            <path d="M18 11.25C18.8734 11.2493 19.7349 11.4524 20.516 11.843C21.2972 12.2335 21.9765 12.8009 22.5 13.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.5 13.5C2.0235 12.8009 2.70281 12.2335 3.48398 11.843C4.26514 11.4524 5.12663 11.2493 6 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 17.25C14.0711 17.25 15.75 15.5711 15.75 13.5C15.75 11.4289 14.0711 9.75 12 9.75C9.92893 9.75 8.25 11.4289 8.25 13.5C8.25 15.5711 9.92893 17.25 12 17.25Z" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.75 20.25C7.2884 19.3364 8.0559 18.579 8.97665 18.0529C9.8974 17.5267 10.9395 17.25 12 17.25C13.0605 17.25 14.1026 17.5267 15.0233 18.0529C15.9441 18.579 16.7116 19.3364 17.25 20.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.0938 7.5C15.234 6.95678 15.5238 6.46377 15.9302 6.07697C16.3366 5.69017 16.8433 5.42508 17.3927 5.31179C17.9422 5.19851 18.5125 5.24158 19.0387 5.43611C19.5649 5.63064 20.0261 5.96883 20.3697 6.41229C20.7134 6.85574 20.9258 7.38668 20.9829 7.9448C21.04 8.50293 20.9394 9.06587 20.6926 9.56971C20.4458 10.0735 20.0627 10.4981 19.5867 10.7951C19.1108 11.0921 18.561 11.2497 18 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5.99849 11.25C5.43745 11.2497 4.88774 11.0921 4.41177 10.7951C3.93581 10.4981 3.55267 10.0735 3.30587 9.56971C3.05906 9.06587 2.95849 8.50293 3.01557 7.9448C3.07264 7.38668 3.28508 6.85574 3.62876 6.41229C3.97243 5.96883 4.43357 5.63064 4.9598 5.43611C5.48604 5.24158 6.05627 5.19851 6.60575 5.31179C7.15523 5.42508 7.66194 5.69017 8.06832 6.07697C8.4747 6.46377 8.76448 6.95678 8.90474 7.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_13711_73719">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {dataminParticipant && dataminParticipant["Data Science - Datamin"] && dataminParticipant["Data Science - Datamin"]["Data Science - Datamin"]} already participated
                                </h3>
                            </div>
            
                            <div className='card-footer border-0 px-4 pb-3'>
                                {islogin?
                                <a className='text-decoration-none text-white' href="/datahack/datamin/test-yourself-datamin/data-science-datamin/b280ffce-0372-438a-a7a1-cbc135c24584" target='_blank'>
                                {dataminParticipant && dataminParticipant["Data Science - Datamin"] && dataminParticipant["Data Science - Datamin"]["already_participated"]?"Resume Now":"Test Now"}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </a>
                                :
                                <button className='btn border-0 text-white'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    Test Now
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-12'>
                        <div id='dataVisualizationDatamin' className='card p-0 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3 overflow-hidden'>
                            {islogin ?
                            <a href="/datahack/datamin/test-yourself-datamin/data-visualization-datamin/ac2967c5-a8fb-4b75-baf6-9be2842bb21b" target='_blank' rel="noreferrer" >
                            <div className='card-img rounded-0 position-relative overflow-hidden'>
                                <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/generative_ai.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                    </svg>
                                </button> */}
                            </div>
                            </a>
                            :
                            <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                <div className='card-img rounded-0 position-relative overflow-hidden'>
                                    <img className="card-img-top rounded-0 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/generative_ai.png?format=webp&width=400"} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                    {/* <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                        </svg>
                                    </button> */}
                                </div>
                            </button>
                            }
                            <div className='card-body px-4 pb-0 pt-3'>
                                {islogin?
                                <a className='text-decoration-none' href='/datahack/datamin/test-yourself-datamin/data-visualization-datamin/ac2967c5-a8fb-4b75-baf6-9be2842bb21b' target='_blank' rel="noreferrer" >
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Visualization</h2>
                                </a>
                                :
                                <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    <h2 className="card-text text-white fs-24 mb-3 fw-semibold">Data Visualization</h2>
                                </button>
                                }
                                <h3 className='text-dark-tertiary fs-16 mb-3 d-flex align-items-center fw-light'>
                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <g clip-path="url(#clip0_13711_73719)">
                                            <path d="M18 11.25C18.8734 11.2493 19.7349 11.4524 20.516 11.843C21.2972 12.2335 21.9765 12.8009 22.5 13.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M1.5 13.5C2.0235 12.8009 2.70281 12.2335 3.48398 11.843C4.26514 11.4524 5.12663 11.2493 6 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M12 17.25C14.0711 17.25 15.75 15.5711 15.75 13.5C15.75 11.4289 14.0711 9.75 12 9.75C9.92893 9.75 8.25 11.4289 8.25 13.5C8.25 15.5711 9.92893 17.25 12 17.25Z" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M6.75 20.25C7.2884 19.3364 8.0559 18.579 8.97665 18.0529C9.8974 17.5267 10.9395 17.25 12 17.25C13.0605 17.25 14.1026 17.5267 15.0233 18.0529C15.9441 18.579 16.7116 19.3364 17.25 20.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M15.0938 7.5C15.234 6.95678 15.5238 6.46377 15.9302 6.07697C16.3366 5.69017 16.8433 5.42508 17.3927 5.31179C17.9422 5.19851 18.5125 5.24158 19.0387 5.43611C19.5649 5.63064 20.0261 5.96883 20.3697 6.41229C20.7134 6.85574 20.9258 7.38668 20.9829 7.9448C21.04 8.50293 20.9394 9.06587 20.6926 9.56971C20.4458 10.0735 20.0627 10.4981 19.5867 10.7951C19.1108 11.0921 18.561 11.2497 18 11.25" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M5.99849 11.25C5.43745 11.2497 4.88774 11.0921 4.41177 10.7951C3.93581 10.4981 3.55267 10.0735 3.30587 9.56971C3.05906 9.06587 2.95849 8.50293 3.01557 7.9448C3.07264 7.38668 3.28508 6.85574 3.62876 6.41229C3.97243 5.96883 4.43357 5.63064 4.9598 5.43611C5.48604 5.24158 6.05627 5.19851 6.60575 5.31179C7.15523 5.42508 7.66194 5.69017 8.06832 6.07697C8.4747 6.46377 8.76448 6.95678 8.90474 7.5" stroke="#8E8E8E" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_13711_73719">
                                            <rect width="24" height="24" fill="white"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    {dataminParticipant && dataminParticipant["Data Visualization - Datamin"] && dataminParticipant["Data Visualization - Datamin"]["Data Visualization - Datamin"]} already participated
                                </h3>
                            </div>
            
                            <div className='card-footer border-0 px-4 pb-3'>
                                {islogin?
                                <a className='text-decoration-none text-white' href="/datahack/datamin/test-yourself-datamin/data-visualization-datamin/ac2967c5-a8fb-4b75-baf6-9be2842bb21b" target='_blank'>
                                {dataminParticipant && dataminParticipant["Data Visualization - Datamin"] && dataminParticipant["Data Visualization - Datamin"]["already_participated"]?"Resume Now":"Test Now"}
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                </a>
                                :
                                <button className='btn border-0 text-white'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                    Test Now
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M4.16406 10H15.8307" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M10 4.16663L15.8333 9.99996L10 15.8333" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-slider-group mt-3 float-end pb-5 d-none">
                    <button className='btn border-0' id='compete1PrevBtn' aria-label='compete prev button'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M25.9998 16L19.414 22.5858C18.633 23.3668 18.633 24.6332 19.414 25.4142L25.9998 32M45.9998 24C45.9998 11.8497 36.1501 2 23.9998 2C11.8496 2 1.99982 11.8497 1.99982 24C1.99982 36.1503 11.8496 46 23.9998 46C36.1501 46 45.9998 36.1503 45.9998 24Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                    </button>
                    <button className='btn border-0' id='compete1NextBtn' aria-label='compete next button'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M21.9998 16L28.5856 22.5858C29.3667 23.3668 29.3667 24.6332 28.5856 25.4142L21.9998 32M1.99982 24C1.99982 11.8497 11.8496 2 23.9998 2C36.1501 2 45.9998 11.8497 45.9998 24C45.9998 36.1503 36.1501 46 23.9998 46C11.8496 46 1.99982 36.1503 1.99982 24Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                    </button>
                </div>
            </div>
        </section>
        <section className='common-style background-dark-primary event-tabs pb-5' id='experience'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text">
                        <h2 className="fs-40 fw-normal mb-3">Boost Your AI Skills With Competitions</h2>
                        <p className="fs-18 fw-normal">Challenge yourself, gain recognition, and unlock new career opportunities</p>
                    </div>
                </div>
                <div className="d-flex align-items-start col-lg-11 mx-auto" id='experienceTabs'>
                    <div className="nav flex-column nav-pills me-3 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active p-4 text-start" id="v-pills-discover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-discover" type="button" role="tab" aria-controls="v-pills-discover" aria-selected="true">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Compete</h3>
                        <p className='fs-16 fw-light'>Tackle challenges, refine skills, and rise up the leaderboard</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-learn-tab" data-bs-toggle="pill" data-bs-target="#v-pills-learn" type="button" role="tab" aria-controls="v-pills-learn" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Earn jobs</h3>
                        <p className='fs-16 fw-light'>Winning competitions puts you in front of top recruiters</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-engage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-engage" type="button" role="tab" aria-controls="v-pills-engage" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Rewards</h3>
                        <p className='fs-16 fw-light'>Gain prizes, recognition, and growth for your triumphs</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-contribute-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contribute" type="button" role="tab" aria-controls="v-pills-contribute" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Recognition</h3>
                        <p className='fs-16 fw-light'>Be seen for your talent in a community that values innovation</p>
                        </button>
                    </div>
                    <div className="tab-content col-lg-6 col-12 rounded-3" id="v-pills-tabContent">
                        <div className="card tab-pane fade show active bg-transparent border-0 m-0" id="v-pills-discover" role="tabpanel" aria-labelledby="v-pills-discover-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-discover">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-discover" data-bs-parent="#experienceTabs" aria-expanded="true" aria-controls="v-collapse-discover">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Compete</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Tackle challenges, refine skills, and rise up the leaderboard</p>
                                </a>
                            </div>
                            <div id="v-collapse-discover" className="collapse show" role="tabpanel" aria-labelledby="v-collapse-heading-discover" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'competitions/compete.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent tab-pane fade" id="v-pills-learn" role="tabpanel" aria-labelledby="v-pills-learn-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-learn">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-learn" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-learn">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Earn jobs</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Winning competitions puts you in front of top recruiters</p>
                                </a>
                            </div>
                            <div id="v-collapse-learn" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-learn" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'competitions/earn_jobs.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-engage" role="tabpanel" aria-labelledby="v-pills-engage-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-engage">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-engage" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-engage">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Rewards</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Gain prizes, recognition, and growth for your triumphs</p>
                                </a>
                            </div>
                            <div id="v-collapse-engage" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-engage" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'competitions/rewards.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-contribute" role="tabpanel" aria-labelledby="v-pills-contribute-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-contribute">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-contribute" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-contribute">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Recognition</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Be seen for your talent in a community that values innovation</p>
                                </a>
                            </div>
                            <div id="v-collapse-contribute" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-contribute" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'competitions/recognition.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='background-dark-secondary common-style-py common-card-style'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                        <h2 className="fs-40 fw-normal mb-3">Uncover Your Format in AI Competitions</h2>
                        <p className="fs-18 fw-normal">Explore our diverse AI challenges and find the perfect match for your skills and interests</p>
                    </div>
                </div>
                <div className='row gx-4'>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div id='hackathonListingCard' className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href={'/datahack/hackathon'}>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/hackathons.png?format=webp&amp;width=392"} alt="Card cap" loading="lazy" width="392" height="220" />
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>Hackathons</h3>
                                <p className='fs-14 mb-3 text-dark-secondary'>Solve real-world challenges in competitive environment</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a href={'/datahack/hackathon'} className="btn btn-dark btn-dark-primary rounded-3 w-100">
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div id='dataminCard' className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            {islogin ?
                            <a  href={'/datahack/datamin/test-yourself-datamin/datamin-quiz-testing/0d305307-7a78-4f7d-bfd3-fbc7f23e746b'} target="_blank">
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/skill_test.png?format=webp&amp;width=392"} alt="Card cap" loading="lazy" width="392" height="220" />
                                </div>
                            </a>:
                            <button className='btn border-0'  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/skill_test.png?format=webp&amp;width=392"} alt="Card cap" loading="lazy" width="392" height="220" />
                                </div>
                            </button>
                            }
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>Datamin</h3>
                                <p className='fs-14 mb-3 text-dark-secondary'>Elevate your data expertise with quick tests, instant feedback and constant improvement</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                {islogin ?
                                <a href={'/datahack/datamin/test-yourself-datamin/datamin-quiz-testing/0d305307-7a78-4f7d-bfd3-fbc7f23e746b'} className="btn btn-dark btn-dark-primary rounded-3 w-100" target='_blank' >
                                    Participate Now
                                </a>:
                                <button className="btn btn-dark btn-dark-primary rounded-3 w-100"  onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>Participate Now</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div id='blogathonListingCard' className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href={'/datahack/blogathon'}>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/blogathon.png?format=webp&amp;width=392"} alt="Card cap" loading="lazy" width="392" height="220" />
                                    
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>Blogathon</h3>
                                <p className='fs-14 mb-3 text-dark-secondary'>Create impactful blogs, explore tech trends, engage readers, and inspire the community</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a href={'/datahack/blogathon'} className="btn btn-dark btn-dark-primary rounded-3 w-100" >
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-3 col-xl-3 col-md-6 mb-4 d-none'>
                        <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href={'/coming-soon'}>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/jobathon.png?format=webp&amp;width=392"} alt="Card cap" loading="lazy" width="392" height="220" />
                                    
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>Jobathon</h3>
                                <p className='fs-14 mb-3 text-dark-secondary'>Showcase your talent and connect with top companies seeking the best</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={'/coming-soon'} >
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {competitioncontent && competitioncontent.trendingTournaments.length>0 && <section className='common-style common-card-style background-dark-primary pb-5' id='compete'>
            <div className="container">
            <div className='text-white mx-auto text-center col-lg-8 heading-with-text mb-5'>
                <h2 className='fs-40 fw-normal mb-3'>Hackathons</h2>
                <p className='fs-18 fw-normal'>Dive into practical challenges and enhance your AI skills</p>
            </div>
            <div className='row g-4'
            >
                {competitioncontent && competitioncontent.trendingTournaments.map((hackathon,index)=>{
                return(<div className='col-lg-4 col-md-6 col-12' key={index}>
                    <div id={hackathon.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href={`/datahack/contest/${hackathon.slug}`} target='_blank' rel="noreferrer" >
                        <div className='card-img position-relative overflow-hidden rounded-2'>
                            <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={datahack_s3_url+hackathon.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                            <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                            </button>
                        </div>
                        </a>
                        <div className='card-body px-0'>
                            <div className='card-body-top d-felx align-items-center'>
                                {/* <p className='fs-14 mb-0 fw-light text-dark-tertiary'>
                                    <span>Started on </span>
                                    {convertDateInFormat(convertDateToLocalDatetime(hackathon.startDate),"DD MMM YYYY")}
                                </p> */}
                                <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                                    <span>{calculateTimeRemainingInMilliSeconds(hackathon.startDate) > 0?"Starts on":"Started on"} </span>
                                    {convertDateInFormat(convertDateToLocalDatetime(hackathon.startDate),"DD MMM YYYY")} -
                                    <span> Ends on </span>
                                    {convertDateInFormat(convertDateToLocalDatetime(hackathon.endDate),"DD MMM YYYY")}
                                </p>
                            </div>
                            <a className='text-decoration-none' href={hackathon.url} target='_blank' rel="noreferrer" ><h2 className="card-text text-white fs-20 mb-3">{hackathon.title}</h2></a>
                            <h3 className='text-white fs-18 mb-3 d-flex fw-light'>
                                {hackathon && hackathon.prize ?<svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                </svg>:
                                <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                    <path d="M22 17.2399V5.16994C22 3.96994 21.02 3.07994 19.83 3.17994H19.77C17.67 3.35994 14.48 4.42994 12.7 5.54994L12.53 5.65994C12.24 5.83994 11.76 5.83994 11.47 5.65994L11.22 5.50994C9.44 4.39994 6.26 3.33994 4.16 3.16994C2.97 3.06994 2 3.96994 2 5.15994V17.2399C2 18.1999 2.78 19.0999 3.74 19.2199L4.03 19.2599C6.2 19.5499 9.55 20.6499 11.47 21.6999L11.51 21.7199C11.78 21.8699 12.21 21.8699 12.47 21.7199C14.39 20.6599 17.75 19.5499 19.93 19.2599L20.26 19.2199C21.22 19.0999 22 18.1999 22 17.2399Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M12 5.98999V20.99" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.75 8.98999H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M8.5 11.99H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>}
                                <p className='html-field' dangerouslySetInnerHTML={{__html:hackathon.prize ? hackathon.prize : 'Knowledge and Learning'}}></p>
                            </h3>
                            <div className="d-flex img-row align-items-center my-4">
                                {hackathon.companyLogo ? <img className='rounded-1 bg-white me-2 object-fit-contain' width={80} height={30} src={hackathon.companyLogo} alt="company logo" loading="lazy" />:<img className='rounded-1 me-2 object-fit-contain' width={80} height={30} src={AvLogo} alt="company logo" loading="lazy" />}
                            </div>


                        {hackathon.registrationCount > 0 && <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                            <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                            </svg>
                            {hackathon.registrationCount}
                        </button>}
                        {hackathon.teamsCount > 0 && <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                            <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03977 1.89009C7.12868 1.88012 7.21911 1.875 7.30696 1.875C8.2719 1.875 9.05415 2.65724 9.05415 3.62219C9.05415 4.58714 8.2719 5.36938 7.30696 5.36938C7.09223 5.36938 6.8811 5.38219 6.67485 5.40694C6.40067 5.43985 6.20508 5.68879 6.23799 5.96296C6.27089 6.23713 6.51983 6.43272 6.794 6.39982C6.96064 6.37982 7.13198 6.36938 7.30696 6.36938C8.2021 6.36938 8.99326 6.64213 9.54936 7.05921C10.1053 7.47617 10.4025 8.01402 10.4025 8.56601C10.4025 8.84215 10.6263 9.06601 10.9025 9.06601C11.1786 9.06601 11.4025 8.84215 11.4025 8.56601C11.4025 7.62869 10.8947 6.81823 10.1494 6.25921C9.84348 6.02979 9.49355 5.83879 9.11186 5.69332C9.68928 5.18971 10.0541 4.44856 10.0541 3.62219C10.0541 2.10496 8.82419 0.875 7.30696 0.875C6.99436 0.875 6.63767 0.920273 6.32319 1.02612C6.29746 1.03478 6.2729 1.04533 6.24962 1.05756C6.37333 1.13614 6.49035 1.22618 6.59945 1.32829C6.77722 1.49466 6.92362 1.68337 7.03977 1.89009Z" fill="white"/>
                                <path d="M4.59551 5.49438C5.8366 5.49438 6.8427 4.48828 6.8427 3.24719C6.8427 2.0061 5.8366 1 4.59551 1C3.35442 1 2.34831 2.0061 2.34831 3.24719C2.34831 4.48828 3.35442 5.49438 4.59551 5.49438ZM4.59551 5.49438C2.60976 5.49438 1 6.7017 1 8.19101M4.59551 5.49438C6.58125 5.49438 8.19101 6.7017 8.19101 8.19101" stroke="white" stroke-linecap="round"/>
                            </svg>
                            {hackathon.teamsCount}
                        </button>}
                        {((calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(hackathon.startDate))/3600000/24).toFixed(0)>0) && <button type="button" className={`btn ${(calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(hackathon.startDate))/3600000/24).toFixed(0) >=10 ? "btn-green":((calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(hackathon.startDate))/3600000/24).toFixed(0)>=5 ?"btn-yellow":"btn-red")} text-success btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center`}>
                            <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <g clip-path="url(#clip0_16631_89205)">
                                <path d="M6 3.81927V6.81927H9" stroke="#85D992" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M6 11.8207C8.7615 11.8207 11 9.58221 11 6.82071C11 4.05921 8.7615 1.82071 6 1.82071C3.2385 1.82071 1 4.05921 1 6.82071C1 9.58221 3.2385 11.8207 6 11.8207Z" stroke="#85D992" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <clipPath id="clip0_16631_89205">
                                <rect width="12" height="12" fill="white" transform="translate(0 0.819275)"/>
                                </clipPath>
                                </defs>
                            </svg>
                            {(calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(hackathon.startDate))/3600000/24).toFixed(0)} day left
                        </button>}
                        </div>
        
                        <div className='card-footer border-0 px-0'>
                        <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={`/datahack/contest/${hackathon.slug}`} target='_blank' rel="noreferrer" >
                            Participate Now
                        </a>
                        </div>
                    </div>
                    </div>)
                })}
            </div>
            <a id='seeAllHackathonBtn' className='btn btn-dark btn-dark-primary mt-5 mx-auto d-table' href={"/datahack/hackathon"}>See all Hackathons</a>
            <div className="btn-slider-group mt-3 float-end d-none">
                <button className='btn border-0' id='competePrevBtn' aria-label='compete prev button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M25.9998 16L19.414 22.5858C18.633 23.3668 18.633 24.6332 19.414 25.4142L25.9998 32M45.9998 24C45.9998 11.8497 36.1501 2 23.9998 2C11.8496 2 1.99982 11.8497 1.99982 24C1.99982 36.1503 11.8496 46 23.9998 46C36.1501 46 45.9998 36.1503 45.9998 24Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
                </button>
                <button className='btn border-0' id='competeNextBtn' aria-label='compete next button'>
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                    <path d="M21.9998 16L28.5856 22.5858C29.3667 23.3668 29.3667 24.6332 28.5856 25.4142L21.9998 32M1.99982 24C1.99982 11.8497 11.8496 2 23.9998 2C36.1501 2 45.9998 11.8497 45.9998 24C45.9998 36.1503 36.1501 46 23.9998 46C11.8496 46 1.99982 36.1503 1.99982 24Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                </svg>
                </button>
            </div>
            </div>
        </section>}
        {activeBlogathonTournament && activeBlogathonTournament.tournamentData.length > 0 && <section className='common-style pb-5 background-dark-primary clearfix blogathon-banner' id='moneyGuarantee'>
            <div className='container'>
                <div className='text-white col-lg-8 heading-with-text text-center mx-auto mb-5'>
                    <h2 className='fs-40 fw-normal mb-3'>Be A Part of Our Writing Community</h2>
                    <p className='fs-18 fw-normal'>Share your expertise, earn rewards and reach a wider audience in the data science community</p>
                </div>
                <div className='row background-dark-secondary p-3 rounded-4 position-relative p-md-5'>
                    <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12 order-2 order-lg-1'>
                        <div className='text-white heading-with-text mb-5'>
                            <h2 className='fs-32 fw-normal mb-3 fs-18'>{activeBlogathonTournament.tournamentData[0].title}</h2>
                            <p className='text-dark-tertiary fs-24 lh-4'>
                                The best way to learn any concept, specifically in Data Tech, is by sharing it. 
                            </p>
                        </div>
                        <div className="tag-group pb-4">
                            <div className="d-inline-flex background-dark-tertiary align-items-center py-2 px-4 rounded-2 mb-3 me-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M3 10H21M9 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.0799 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.0799 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H15M9 5H15M9 5V4.5C9 3.67157 8.32843 3 7.5 3C6.67157 3 6 3.67157 6 4.5V5M15 5V4.5C15 3.67157 15.6716 3 16.5 3C17.3284 3 18 3.67157 18 4.5V5" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                                <span className='text-white fs-16 ms-2'>{convertDateInFormat(convertDateToLocalDatetime(activeBlogathonTournament.tournamentData[0].startDate),"DD")} - {convertDateInFormat(convertDateToLocalDatetime(activeBlogathonTournament.tournamentData[0].endDate),"DD MMM YYYY")}</span>
                            </div>
                            <div className="d-inline-flex background-dark-tertiary align-items-center py-2 px-4 rounded-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                </svg>
                                <span className='html-field text-white fs-16 ms-2' dangerouslySetInnerHTML={{ __html:activeBlogathonTournament.tournamentData[0].prize}}></span>
                            </div>
                        </div>
                        <a className='btn btn-dark btn-dark-primary rounded-3 d-table mt-5 align-items-center' href={`/datahack/contest/${activeBlogathonTournament.tournamentData[0].slug}`}>
                            Start Writing Now
                            <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M4.16406 10H15.8307" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M10 4.1665L15.8333 9.99984L10 15.8332" stroke="#F8F8F8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 order-1 order-lg-2'>
                        <img className='img-fluid rounded-2 d-table mx-auto position-absolute bottom-0 end-0 h-100' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"competitions/blogathon-3.png"} alt="blogathon banner" width={536} />
                    </div>
                </div>
            </div>
        </section>}
        <Suspense fallback={<></>}>
            <CompetitionHomeSwiperComponent2 />
        </Suspense>
        <section className="seo-links background-dark-secondary d-none" id='seoFooter'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Footer platform={"newcompetitionhome"}/>
        <Cookies platform={'newcompetition'}  />
        <Flashstrip />
    </>
    
    
  )
}


const mapStateToProps = (state) => ({
    competitioncontent:state.competitionContentReducer.competitioncontent,
    hackathonContent:state.hackathonContentReducer.hackathoncontent,
    activeBlogathonTournament:state.competitionContentReducer.activeBlogathonTournament,
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });

const mapDispatchToProps = dispatch=>({
  getCompetitionDataHandler:data=>dispatch(getCompetitionData(data)),
  fetchHackathonContentSuccessHandler:data=>dispatch(fetchHackathonContentSuccess(data)),
  fetchBlogathonListContentSuccessHandler:data=>{dispatch(getBlogathonListData(data))},
  updateWelcomeLoginModalHandler:data=>{dispatch(updateWelcomeLoginModal(data))}
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionHome);

import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header'
import { getQueryData, getQueryDataElevate } from '../../../../utils'
import { connect } from 'react-redux';
import Pagenumber from '../../../common/Page/Pagination';

function AVCCPanel({islogin,csrf}) {
    const [avccmembers,setAvccmembers] = useState(null);

    useEffect(()=>{
        let query = `query MyQuery {
            avccMemberView {
                articleUrl
                isAvccMember
                pageViews
                slug
                title
                userId
            }
        }`;

        getQueryData(query,"newleaderboard")
        .then((data)=>{
            setAvccmembers(data.data.avccMemberView);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[])

  return (
    <>
        <Header />
        <section id="tournament" className='background-dark-primary min-vh-100 common-style'>
            <div className="container">
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Slug</th>
                                        <th>Title</th>
                                        <th>Article Url</th>
                                        <th>Is Avcc Member</th>
                                        <th>Page Views</th>
                                        <th>User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {avccmembers && avccmembers.map((obj,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td>{obj.slug}</td>
                                                <td>{obj.title}</td>
                                                <td>{obj.articleUrl}</td>
                                                <td>{obj.isAvccMember ? "True":"False"}</td>
                                                <td>{obj.pageViews}</td>
                                                <td>{obj.username}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(AVCCPanel);
import React, { useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';


const CreateRanker = ({csrf,islogin}) => {
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    const [name,setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [bio, setBio] = useState("");
    const [photo, setPhoto] = useState(null);
    const [rankerType, setRankerType] = useState("");

    const resetFormFields = () => {
        setName("");
        setDesignation("");
        setBio("");
        setRankerType("");
        setPhoto(null);
        document.getElementById("fileInput").value = "";
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();

        let mutationString=`mutation MyMutation($photo: Upload!,$name: String!, $designation: String!, $ranker: String!){createTopRanker(bio: """${bio}""",designation: $designation,name: $name,photo: $photo,rankerType: $ranker) {topRanker {photo,id}}}`;

        let variables = {
            name: name,
            ranker: rankerType,
            designation: designation,
            ...(photo && {photo :null}),
        }

        if (photo) {
            formdata.append("0", photo);
            variables.photo = null; 
        }

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (photo) map["0"] = ["variables.photo"];
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }
    
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };

        console.log("Formdata", formdata);
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((result)=> {
            console.log(result);
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Ranker added successfully"} />}
            <section id='createRanker' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Ranker Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        
                                        <div className="form-group mb-3">
                                            <label className="text-white">Name <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Name' 
                                            value={name}
                                            onChange={(event)=>setName(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Designation <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Designation' 
                                            value={designation}
                                            onChange={(event)=>setDesignation(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Bio <sup className='text-danger'>*</sup></label>
                                            <textarea  
                                            className='form-control' 
                                            value={bio}
                                            onChange={(event)=>setBio(event.target.value)}
                                            style={{ height: '100px' }}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Photo <sup className='text-danger'>*</sup></label>
                                            <input
                                                id="fileInput"
                                                className="form-control"
                                                type="file"
                                                name="photo"
                                                onChange={(e) => setPhoto(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Ranker Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="sessionType"
                                                className="form-select"
                                                value={rankerType}
                                                onChange={(event) => setRankerType(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Ranker Type</option>
                                                {["author", "hacker"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Ranker
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
    islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateRanker);
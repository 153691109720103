import React from 'react';
import MainRouter from './Routers/MainRouter';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store/Reducers/index';
import thunk from 'redux-thunk';

const store  = createStore( rootReducer,applyMiddleware(thunk));
function App() {
  return (

    <Provider store={store}>
      <MainRouter />
    </Provider>
  );
}

export default App;

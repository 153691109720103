import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header'
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import Pagination from '../../../common/Page/Pagination';

function Question({csrf}) {

    const [questions,setQuestions] = useState(null);
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState('');
    const [time,setTime] = useState('');
    const [subject,setSubject] = useState('');
    const [difficulty,setDifficulty] = useState('');
    const [pool,setPool] = useState('');
    const [quizContext,setQuizContext] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState();

    useEffect(() => {
        fetchQuestions(currentPage);
    }, [csrf, currentPage]);

    useEffect(()=>{
        if(csrf){
            const formData = new FormData();
            formData.append("url","quiz/context/");
            formData.append("platform", 'quizmania');
            formData.append("method", 'GET');

            fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
                method: 'POST',
                body:formData,
                headers: {
                    "X-CSRFToken":csrf,
                },
                credentials:process.env.REACT_APP_CREDENTIAL_TYPE
            })
            .then(response => response.json())
            .then(data => {
                console.log("Quiz related data is: ", data.response);
                setQuizContext(data.response)
            })
            .catch(error => {
                console.log(error);
            });
        }
    },[csrf])

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const fetchQuestions = (page) => {
        if(csrf){
            const formData = new FormData();
            formData.append("platform", 'quizmania');
            formData.append("url", `quiz/question/all/?page=${page}&limit=20${time?(time=="all"?"":"&time="+time):""}${subject?(subject=="all"?"":"&subject="+subject):""}${difficulty?(difficulty=="all"?"":"&difficulty="+difficulty):""}${pool?(pool=="all"?"":"&pool="+pool):""}`);
            formData.append("forward_user_data", "True");
            formData.append("method","GET");

            fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                method: 'POST',
                body:formData,
                headers: {
                    "X-CSRFToken":csrf,
                },
                credentials:process.env.REACT_APP_CREDENTIAL_TYPE
            })
            .then(response => {
                return response.json();
            })
            .then(result => {
                console.log(result.response);
                setQuestions(result.response.page_questions);
                setTotalPage(result.response.total_pages);
            })
            .catch(error => {
                console.log(error);
            });
        }
    }

    const deleteQuestion = (question_id)=>{
        if (window.confirm("Are You Sure.\nYou want to delete a question?") === true) {
            if (window.confirm("Are You 100% Sure.\nYou want to delete a question?\nThis will be informed to admin") === true) {
                deleteQuestionApi(question_id);
            }
        } 
    }
    
    const deleteQuestionApi = (question_id)=>{
        const formData = new FormData();
        formData.append("platform", 'quizmania');
        formData.append("url", `quiz/question/delete/${question_id}`);
        formData.append("method", 'DELETE');
        formData.append("forward_user_data","True");

        console.log("Form Data: ", formData);

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => {
            return response.json();
        })
        .then(result => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                    window.location.reload();
                }, 3000);
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
        });
    }

    const applyFilter = ()=>{
        fetchQuestions(1);
    }
  return (      
    <>
        <Header />
        {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={"Your question deleted successfully"} />}
        <section id="quiz" className='common-style min-vh-100 background-dark-primary'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/question/create-question'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Question
                </a>
                <div className="row">
                    <div className="col-12 col-md">
                        <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Question Id</th>
                                        <th>Title</th>
                                        <th className='d-none'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questions && questions.map((question)=>{
                                        return(
                                            <tr>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/question/'+question.question_id}>{question.question_id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/question/'+question.question_id}>{question.title}</a></td>
                                                <td className='d-none'>
                                                    <svg onClick={()=>{deleteQuestion(question.question_id)}} className='cursor-pointer' fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className='py-4'>
                                <Pagination updatePageNumber={handlePageChange} last_page={totalPage} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-2 sticky-top">
                        <div className="filter-box border border-white p-2 rounded-1">
                            <h2 className='text-white fs-18 mb-3'>Filters</h2>
                            <label className='text-white'>Time</label>
                            <div className="mb-3 position-relative">
                                <select class="form-select background-dark-tertiary text-white border-0 pe-5 shadow-none" aria-label="Default select example" value={time} onChange={(e)=>{setTime(e.target.value)}}>
                                    <option value="all">All</option>
                                    <option value="today">Today</option>
                                    <option value="past_7_days">Past 7 Days</option>
                                    <option value="past_1_month">Past 1 Month</option>
                                </select>
                                <svg className='position-absolute top-0 end-0 me-4 mt-3' xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                    <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>
                            {quizContext && quizContext.subjects && <label className='text-white'>Subject</label>}
                            {quizContext && quizContext.subjects && <div className="mb-3 position-relative">
                                 <select class="form-select background-dark-tertiary text-white border-0 pe-5 shadow-none" aria-label="Default select example" value={subject} onChange={(e)=>{setSubject(e.target.value)}}>
                                    <option value="all">All</option>
                                    {quizContext.subjects.map((obj)=>{
                                        return(<option value={obj}>{obj}</option>)
                                    })}
                                </select>
                                <svg className='position-absolute top-0 end-0 me-4 mt-3' xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                    <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>}
                            {quizContext && quizContext.difficulty && <label className='text-white'>Difficulty</label>}
                            {quizContext && quizContext.difficulty && <div className="mb-3 position-relative">
                                <select class="form-select background-dark-tertiary text-white border-0 pe-5 shadow-none" aria-label="Default select example" value={difficulty} onChange={(e)=>{setDifficulty(e.target.value)}}>
                                    <option value="all">All</option>
                                    {quizContext.difficulty.map((obj)=>{
                                        return(<option value={obj}>{obj}</option>)
                                    })}
                                </select>
                                <svg className='position-absolute top-0 end-0 me-4 mt-3' xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                    <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>}
                            {quizContext && quizContext.pools && <label className='text-white'>Pool</label>}
                            {quizContext && quizContext.pools && <div className="mb-3 position-relative">
                                <select class="form-select background-dark-tertiary text-white border-0 pe-5 shadow-none" aria-label="Default select example" value={pool} onChange={(e)=>{setPool(e.target.value)}}>
                                    <option value="all">All</option>
                                    {quizContext.pools.map((obj)=>{
                                        return(<option value={obj[1]}>{obj[0]}</option>)
                                    })}
                                </select>
                                <svg className='position-absolute top-0 end-0 me-4 mt-3' xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                    <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                            </div>}
                            <button className='btn btn-dark btn-dark-primary w-100 mt-3' onClick={applyFilter}>Search</button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Question);

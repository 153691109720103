import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header';
import { getCSRF } from '../../../../utils/Login';
import $ from 'jquery';
import { submitPlatformFormData } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

export default function TestimonialAndMentors() {
	const [platform, setPlatform] = useState('genaipinnacle');  
    const [testimonialData, setTestimonialData] = useState(null);
    const [mentorsData, setMentorsData] = useState(null);
    const [originalData,setOriginalData] = useState(null);
    const [csrfToken, setCsrfToken] = useState('');
    const [testimonialType,setTestimonialType] = useState('video');
    const [order,setOrder] = useState(0);
    const [name,setName] = useState("");
    const [designation,setDesignation] = useState("");
    const [title,setTitle] = useState("");
    const [thumbnailImage,setThumbnailImage] = useState("");
    const [userImageSrc,setUserImageSrc] = useState("");
    const [userImageAlt,setUserImageAlt] = useState("");
    const [companyLogoSrc,setCompanyLogoSrc] = useState("");
    const [companyLogoAlt,setCompanyLogoAlt] = useState("");
    const [textTestimonial,setTextTestimonial] = useState("");

    const [mentorOrder,setMentorOrder] = useState(0);
    const [mentorName,setMentorName] = useState("");
    const [mentorDesignation,setMentorDesignation] = useState("");
    const [mentorDescription,setMentorDescription] = useState("");
    const [mentorCompany,setMentorCompany] = useState("");
    const [mentorCompanyAlt,setMentorCompanyAlt] = useState("");
    const [mentorImage,setMentorImage] = useState("");
    const [mentorImageAlt,setMentorImageAlt] = useState("");

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");
    const [videoUrl,setVideoUrl] = useState("");
    

	const handlePlatformChange = (e) => {
		setPlatform(e.target.value);
	};

    useEffect(()=>{
        getCSRF()
        .then((data)=>{
        setCsrfToken(data);
        })
        .catch((error)=>{
        console.log(error);
        })
    },[]);

    useEffect(()=>{
        const formData = new FormData();
        formData.append('query', `
            query MyQuery {
                getPlatformJsonDataByName(platformName: "${platform}") {
                    platformData
                    platform {
                    platformName
                    }
                }
            }
        ` );
        formData.append('source', 'newmarketingpages');
        fetch(process.env.REACT_APP_GENAI_CONTENT,{
            method: 'POST',
            body:formData,
            credentials:"omit",
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            let temp_data = JSON.parse(data.data.getPlatformJsonDataByName.platformData);
            setOriginalData(temp_data);
            setTestimonialData(temp_data.hearFromOurLearners.feedback);
            setMentorsData(temp_data.meetTheInstructorsMentors.teamMembers);
            setOrder(temp_data.hearFromOurLearners.feedback.length);
            setMentorOrder(temp_data.meetTheInstructorsMentors.teamMembers.length);
        })
        .catch((error) => {
            console.error(error.message);
        });
    },[platform])

    let changeOrder = (currentIndex,changedIndex) =>{
        let temp_data = [...testimonialData];
        let temp_object = temp_data[currentIndex];
        temp_data.splice(currentIndex, 1);
        temp_data.splice(changedIndex, 0, temp_object);
        setTestimonialData(temp_data);
    }

    let changeOrderMentors = (currentIndex,changedIndex) =>{
        let temp_data = [...mentorsData];
        let temp_object = temp_data[currentIndex];
        temp_data.splice(currentIndex, 1);
        temp_data.splice(changedIndex, 0, temp_object);
        setMentorsData(temp_data);
    }

    let deleteItem = (index) =>{
        const result = window.confirm(`⚠️ WARNING: You are about to permanently delete the testimonial from '${platform}' named '${testimonialData[index].name}'. This action CANNOT be undone! Press 'Submit' ONLY if you are absolutely certain. Once deleted, there is NO way to recover this testimonial.`);
        if (result) {
            let temp_data = [...testimonialData];
            temp_data.splice(index, 1);
            setTestimonialData(temp_data);
        } else {
            console.log("User clicked Cancel.");
        }
        
    }

    let deleteItemMentors = (index) =>{
        const result = window.confirm(`⚠️ WARNING: You are about to permanently delete the mentor from '${platform}' named '${mentorsData[index].name}'. This action CANNOT be undone! Press 'Submit' ONLY if you are absolutely certain. Once deleted, there is NO way to recover this testimonial.`);
        if (result) {
            let temp_data = [...mentorsData];
            temp_data.splice(index, 1);
            setMentorsData(temp_data);
        }
        
    }

    let updateValueWithKey = (key,value,index) => {
        let temp_list = [...testimonialData];
        temp_list[index][key] =  value;
        setTestimonialData(temp_list);
    }   
    
    let updateValueWithKeyMentors = (key,value,index) => {
        let temp_list = [...mentorsData];
        temp_list[index][key] =  value;
        setMentorsData(temp_list);
    }  

    let updateValueWithMultipleKeys = (key1,key2,value,index) => {
        let temp_list = [...testimonialData];
        temp_list[index][key1][key2] =  value;
        setTestimonialData(temp_list);
    }

    let updateValueWithMultipleKeysMentors = (key1,key2,value,index) => {
        let temp_list = [...mentorsData];
        temp_list[index][key1][key2] =  value;
        setMentorsData(temp_list);
    }

    let uploadImageToSvg = (file,bucket_name,path,content_type)=>{
        const payload = {
            "bucket": bucket_name,
            "path": path,
            "content_type": content_type
        };
      
        const formData = new FormData();
        formData.append("file", file);
        formData.append("platform", 'newmarketingpages');
        formData.append("url", 'api/s3-upload/');
        formData.append("payload", JSON.stringify(payload));
        formData.append("forward_user_data","True");
    
        return fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrfToken,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => response.json())
        .then(data => {
            return data
        })
        .catch(error => {
            console.log(error);
            return error;
        });
    }

    let validateImage = (e, maxKBSize, requiredRatio, bucket_name, path, setFuction) => {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;
        const fileType = file.type;

        if (!file) {
            $(e.target).after(`<small class="warning text-danger">No file selected.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        if (fileType !== "image/webp") {
            $(e.target).after(`<small class="warning text-danger">Invalid file format. Please upload a .webp image.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        if(fileSize > maxKBSize){
            $(e.target).after(`<small class="warning text-danger">File is too large. Please upload an image smaller than ${maxKBSize} KB.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);
    
        img.onload = async function() {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
    
            if (aspectRatio !== requiredRatio) {
                $(e.target).after(`<small class="warning text-danger">Invalid aspect ratio. Please upload an image with a ${requiredRatio}:1 ratio.</small>`)
                setTimeout(() => {
                    $(".warning").remove()
                }, 3000);
                $(e.target).val("");
                return(false);
            } else {
                const result = window.confirm(`Great! You've passed all the checks. Now, it's time to upload this image to the S3 bucket. Please confirm that you're sure about uploading this image, as multiple uploads may lead to a larger bucket size and increased costs. Ensure the image is accurate and necessary before proceeding.`);
                if(result){
                    let response = await uploadImageToSvg(file,bucket_name,path,fileType);
                    if(response.response.status_code == 200){
                        setFuction(response.response.file_path);
                    }
                    else{
                        $(e.target).after(`<small class="warning text-danger">Something went wrong at server end.</small>`)
                        setTimeout(() => {
                            $(".warning").remove()
                        }, 3000);
                        $(e.target).val("");
                        return(false);
                    }
                    return(true);
                }
                else{
                    return false;
                }
            }
        };
    
        img.onerror = function() {
            $(e.target).after(`<small class="warning text-danger">There was an error reading the image file.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return(false);
        };
    }

    let validateImageInList = (e, maxKBSize, requiredRatio, bucket_name, path, setFuction, setdata, index, key) => {
        let file = e.target.files[0];
        let fileSize = file.size / 1024;
        const fileType = file.type;

        if (!file) {
            $(e.target).after(`<small class="warning text-danger">No file selected.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        if (fileType !== "image/webp") {
            $(e.target).after(`<small class="warning text-danger">Invalid file format. Please upload a .webp image.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        if(fileSize > maxKBSize){
            $(e.target).after(`<small class="warning text-danger">File is too large. Please upload an image smaller than ${maxKBSize} KB.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return false;
        }

        const img = new Image();
        img.src = URL.createObjectURL(file);
    
        img.onload = async function() {
            const width = img.width;
            const height = img.height;
            const aspectRatio = width / height;
    
            if (aspectRatio !== requiredRatio) {
                $(e.target).after(`<small class="warning text-danger">Invalid aspect ratio. Please upload an image with a ${requiredRatio}:1 ratio.</small>`)
                setTimeout(() => {
                    $(".warning").remove()
                }, 3000);
                $(e.target).val("");
                return(false);
            } 
            else {
                const result = window.confirm(`Great! You've passed all the checks. Now, it's time to upload this image to the S3 bucket. Please confirm that you're sure about uploading this image, as multiple uploads may lead to a larger bucket size and increased costs. Ensure the image is accurate and necessary before proceeding.`);
                if(result){
                    let response = await uploadImageToSvg(file,bucket_name,path,fileType);
                    if(response.response.status_code == 200){
                        let tempdata = [...setdata];
                        if(key != "thumbnail_image"){
                            tempdata[index][key].src = response.response.file_path;
                        }
                        else{
                            tempdata[index][key] = response.response.file_path;
                        }
                        setFuction(tempdata);
                    }
                    else{
                        $(e.target).after(`<small class="warning text-danger">Something went wrong at server end.</small>`)
                        setTimeout(() => {
                            $(".warning").remove()
                        }, 3000);
                        $(e.target).val("");
                        return(false);
                    }
                    return(true);
                }
                else{
                    return(false);
                }
            }
        };
    
        img.onerror = function() {
            $(e.target).after(`<small class="warning text-danger">There was an error reading the image file.</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            $(e.target).val("");
            return(false);
        };
    }

    let addTestimonial = ()=>{
        if(name == ""){
            $("#addTestimonialModal #name").after(`<small class="warning text-danger">Please Enter name to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "video" && designation == ""){
            $("#addTestimonialModal #designation").after(`<small class="warning text-danger">Please Enter designation to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(title == ""){
            $("#addTestimonialModal #title").after(`<small class="warning text-danger">Please Enter title to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "video" && thumbnailImage == ""){
            $("#addTestimonialModal #thumbnail_image").after(`<small class="warning text-danger d-block">Please select a thumbnail image to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "video" && userImageSrc == ""){
            $("#addTestimonialModal #user_image").after(`<small class="warning text-danger d-block">Please select a user image to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "video" && companyLogoSrc == ""){
            $("#addTestimonialModal #company_image").after(`<small class="warning text-danger d-block">Please select a company image to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "text" && textTestimonial == ""){
            $("#addTestimonialModal #text").after(`<small class="warning text-danger d-block">Please enter text testimonial to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(testimonialType == "video" && videoUrl == ""){
            $("#addTestimonialModal #videoUrl").after(`<small class="warning text-danger d-block">Please enter video url to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        const result = window.confirm(`Are you sure you want to add this testimonial by ${name} to the Genaipinnacle ${platform}? By clicking "Submit," the testimonial will be displayed on the webpage, and it will be your responsibility to ensure its accuracy and appropriateness.`);
        if(result){
            let temp_dict = {
                testimonial_type: testimonialType,
                name: name,
                designation: designation,
                title: title,
                thumbnail_image: thumbnailImage,
                text: textTestimonial,
                userImage: {
                    src: userImageSrc,
                    alt: userImageAlt
                },
                companyImage: {
                    src: companyLogoSrc,
                    alt: companyLogoAlt
                },
                video_url: videoUrl
            }
            let temp_list = [...testimonialData];
            temp_list.splice(order, 0, temp_dict);
            setTestimonialData(temp_list);
            window.$("#addTestimonialModal").modal("hide");
        }
    }

    let addMentor = ()=>{
        if(mentorName == ""){
            $("#addMentorModal #name").after(`<small class="warning text-danger">Please Enter name to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(mentorDesignation == ""){
            $("#addMentorModal #designation").after(`<small class="warning text-danger">Please Enter designation to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(mentorDescription == ""){
            $("#addMentorModal #description").after(`<small class="warning text-danger">Please Enter description to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(mentorImage == ""){
            $("#addMentorModal #mentor_image").after(`<small class="warning text-danger d-block">Please select a mentor image to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        if(mentorCompany == ""){
            $("#addMentorModal #company_image").after(`<small class="warning text-danger d-block">Please select a company image to procced</small>`)
            setTimeout(() => {
                $(".warning").remove()
            }, 3000);
            return false;
        }
        const result = window.confirm(`Are you sure you want to add this mentor by ${mentorName} to the Genaipinnacle ${platform}? By clicking "Submit," the testimonial will be displayed on the webpage, and it will be your responsibility to ensure its accuracy and appropriateness.`);
        if(result){
            let temp_dict = {
                name: mentorName,
                designation: mentorDesignation,
                description: mentorDescription,
                image: {
                    src: mentorImage,
                    alt: mentorImageAlt
                },
                companyImage: {
                    src: mentorCompany,
                    alt: mentorCompanyAlt
                }
            }
            let temp_list = [...mentorsData];
            temp_list.splice(mentorOrder, 0, temp_dict);
            setMentorsData(temp_list);
            window.$("#addMentorModal").modal("hide");   
        }
    }

    let handleSubmit = (e)=>{
        e.preventDefault();
        let temp_data = {...originalData};
        originalData.hearFromOurLearners.feedback = testimonialData;
        originalData.meetTheInstructorsMentors.teamMembers = mentorsData;
        submitPlatformFormData(platform,temp_data,csrfToken)
        .then((data)=>{
            setIsSuccess(true);
            setTimeout(() => {
                setIsSuccess(false);
            }, 3000);
        })
        .catch((error)=>{
            console.log(error);
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 4000);
        });
    }

  return (
	<>	
        {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={"🎉 Success! Your testimonials have been successfully updated on the Genaipinnacle platform. Please note that these changes have been made by you, and you will be responsible for them."} />}
		<Header />
		<section className='bg-light 100vh common-style' id='platformDataUpdate'>
			<div className="container">
				<div className='card p-4 w-50 mx-auto border-0 shadow background-dark-secondary'>
					<h1 className='text-center text-white mb-2'>CMS</h1>
					<label className='text-white mb-2'>Select platform:</label>
					<select value={platform} onChange={handlePlatformChange} className="form-select mb-5 background-dark-tertiary text-white border-0">
						<option defaultValue value="genaipinnacle">Genaipinnacle</option>
						<option value="blackbelt">Blackbelt</option>
						<option value="agenticai">Agentic AI</option>
					</select>

                <div>
                <h3 className='text-center mb-3 text-white'>{platform.toUpperCase()} TESTIMONIALS</h3>
                {testimonialData && 
                    <form onSubmit={handleSubmit} className='json-update-form'>
                    <div className="av-tab-design background-dark-secondary border-start-0 border-end-0 d-none">
                        <ul class="nav nav-tabs py-4 border-0 flex-nowrap me-5" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="testimonials-tab" data-bs-toggle="tab" data-bs-target="#testimonials" type="button" role="tab" aria-controls="testimonials" aria-selected="true">Testimonials</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="mentors-tab" data-bs-toggle="tab" data-bs-target="#mentors" type="button" role="tab" aria-controls="mentors" aria-selected="false">Mentors</button>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content pt-3" id="myTabContent">
                        <div class="tab-pane fade show active" id="testimonials" role="tabpanel" aria-labelledby="testimonials-tab">
                            {testimonialData.map((obj,index)=>{
                                return(
                                <div className='border rounded-2 p-3 mb-4 position-relative'>
                                    <button onClick={()=>{deleteItem(index)}} className='btn btn-delete position-absolute background-dark-secondary border rounded-circle' type='button'><svg className='cursor-pointer' width={32} height={32} fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg></button>
                                    <div className="form-group mb-3">
                                        <label className="me-3 text-white">Order</label>
                                        <select
                                            name="testimonialOrder"
                                            className="form-select background-dark-tertiary border-0 text-white"
                                            value={index}
                                            onChange={(e)=>{changeOrder(index,e.target.value)}}
                                        >
                                            <option value="" disabled>Select Order of Tournament</option>
                                            {testimonialData && testimonialData.map((obj,index) => (
                                                <option key={index} value={index}>
                                                    {index}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="me-3 text-white">Testimonial Type</label>
                                        <select
                                            name="testimonialType"
                                            className="form-select background-dark-tertiary border-0 text-white"
                                            value={obj.testimonial_type}
                                            onChange={(e)=>{updateValueWithKey("testimonial_type",e.target.value,index)}}
                                        >
                                            <option value="" disabled>Select Type of Testimonial</option>
                                            <option value="video">Video</option>
                                            <option value="text">Text</option>
                                        </select>
                                    </div>
                                    <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Name:</label>
                                        <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.name}
                                        onChange={(e)=>{updateValueWithKey("name",e.target.value,index)}}
                                        />
                                    </div>
                                    {obj.testimonial_type == 'video' && <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Designation:</label>
                                        <input
                                        type="text"
                                        id="designation"
                                        name="designation"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.designation}
                                        onChange={(e)=>{updateValueWithKey("designation",e.target.value,index)}}
                                        />
                                    </div>}
                                    <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Title:</label>
                                        <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.title}
                                        onChange={(e)=>{updateValueWithKey("title",e.target.value,index)}}
                                        />
                                    </div>
                                    {obj.testimonial_type == 'video' && <div className="mb-3 ">   
                                        <label htmlFor="Name" className="form-label text-white">Thumbnail Preview:</label>
                                        <img className='img-fluid mb-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+obj.thumbnail_image} alt={obj.thumbnail_image}/>
                                        <label htmlFor="Name" className="form-label text-white">Thumbnail Image:</label>
                                        <input
                                            type="file"
                                            id="thumbnail_image"
                                            name="thumbnail_image"
                                            className="form-control background-dark-tertiary border-0 text-white"
                                            onChange={(e)=>{validateImageInList(e,50,853/480,"imgcdn.analyticsvidhya.com",`${platform}/testimonials/testimonial thumbnail/`,setTestimonialData,testimonialData,index,"thumbnail_image")}}
                                            placeholder='Enter Thumbnail Url'
                                            accept='image/webp'
                                        />
                                    </div>}
                                    {obj.testimonial_type == 'text' && <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Text Testimonial:</label>
                                        <input
                                        type="text"
                                        id="text"
                                        name="text"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.text}
                                        onChange={(e)=>{updateValueWithKey("text",e.target.value,index)}}
                                        />
                                    </div>}
                                    {obj.testimonial_type == 'video' && <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">User Image Preview:</label>
                                        <img className='img-fluid mb-3 mx-auto d-table' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+obj.userImage.src} alt={obj.userImage.alt}/>
                                        <label htmlFor="Name" className="form-label text-white">User Image Src:</label>
                                        <input
                                            type="file"
                                            id="user_image"
                                            name="thumbnail_image"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            onChange={(e)=>{validateImageInList(e,50,1,"imgcdn.analyticsvidhya.com",`${platform}/testimonials/testimonial images/`,setTestimonialData,testimonialData,index,"userImage")}}
                                            placeholder='Enter Thumbnail Url'
                                            accept='image/webp'
                                        />
                                        <label htmlFor="Name" className="form-label text-white">User Image Alt:</label>
                                        <input
                                        type="text"
                                        id="userImageAlt"
                                        name="userImageAlt"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.userImage.alt}
                                        onChange={(e)=>{updateValueWithMultipleKeys("userImage","alt",e.target.value,index)}}
                                        />
                                    </div>}
                                    {obj.testimonial_type == 'video' && <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Company Logo Preview:</label>
                                        <img className='img-fluid mb-3 mx-auto d-table bg-white' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL+obj.companyImage.src} alt={obj.companyImage.alt}/>
                                        <label htmlFor="Name" className="form-label text-white">Company Logo Src:</label>
                                        <input
                                            type="file"
                                            id="company_image"
                                            name="thumbnail_image"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            onChange={(e)=>{validateImageInList(e,50,8/3,"svgcdn.analyticsvidhya.com",`${platform}/testimonials company logo`,setTestimonialData,testimonialData,index,"companyImage")}}
                                            placeholder='Enter Thumbnail Url'
                                            accept='image/webp'
                                        />
                                        <label htmlFor="Name" className="form-label text-white">Company Logo Alt:</label>
                                        <input
                                        type="text"
                                        id="companyLogoAlt"
                                        name="companyLogoAlt"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.companyImage.alt}                                        
                                        onChange={(e)=>{updateValueWithMultipleKeys("companyImage","alt",e.target.value,index)}}
                                        />
                                    </div>}
                                    {obj.testimonial_type == 'video' && <div className="mb-3 ">
                                        <label htmlFor="Name" className="form-label text-white">Video Url:</label>
                                        <input
                                        type="text"
                                        id="videoUrl"
                                        name="videoUrl"
                                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                                        value={obj.video_url}
                                        onChange={(e)=>{updateValueWithKey("video_url",e.target.value,index)}}
                                        />
                                    </div>}
                                </div>)
                            })}
                            <button type="button" className='btn border-0 mx-auto d-table text-white' data-bs-toggle="modal" data-bs-target="#addTestimonialModal">
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9999 3.51472V20.4853M20.4852 12L3.51466 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                </svg> Add New
                            </button>
                        </div>
                        <div class="tab-pane fade" id="mentors" role="tabpanel" aria-labelledby="mentors-tab">
                            {mentorsData.map((obj,index)=>{
                                    return(
                                    <div className='border rounded-2 p-3 mb-4 position-relative'>
                                        <button onClick={()=>{deleteItemMentors(index)}} className='btn btn-delete position-absolute background-dark-secondary border rounded-circle' type='button'><svg className='cursor-pointer' width={32} height={32} fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="delete"><path d="M24.2,12.193,23.8,24.3a3.988,3.988,0,0,1-4,3.857H12.2a3.988,3.988,0,0,1-4-3.853L7.8,12.193a1,1,0,0,1,2-.066l.4,12.11a2,2,0,0,0,2,1.923h7.6a2,2,0,0,0,2-1.927l.4-12.106a1,1,0,0,1,2,.066Zm1.323-4.029a1,1,0,0,1-1,1H7.478a1,1,0,0,1,0-2h3.1a1.276,1.276,0,0,0,1.273-1.148,2.991,2.991,0,0,1,2.984-2.694h2.33a2.991,2.991,0,0,1,2.984,2.694,1.276,1.276,0,0,0,1.273,1.148h3.1A1,1,0,0,1,25.522,8.164Zm-11.936-1h4.828a3.3,3.3,0,0,1-.255-.944,1,1,0,0,0-.994-.9h-2.33a1,1,0,0,0-.994.9A3.3,3.3,0,0,1,13.586,7.164Zm1.007,15.151V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Zm4.814,0V13.8a1,1,0,0,0-2,0v8.519a1,1,0,0,0,2,0Z"></path></svg></button>
                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Order</label>
                                            <select
                                                name="testimonialOrder"
                                                className="form-select background-dark-tertiary border-0 text-white"
                                                value={index}
                                                onChange={(e)=>{changeOrderMentors(index,e.target.value)}}
                                            >
                                                <option value="" disabled>Select Order of Tournament</option>
                                                {mentorsData && mentorsData.map((obj,index) => (
                                                    <option key={index} value={index}>
                                                        {index}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="mb-3 ">
                                            <label htmlFor="Name" className="form-label text-white">Name:</label>
                                            <input
                                            type="text"
                                            id="name"
                                            name="name"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            value={obj.name}
                                            onChange={(e)=>{updateValueWithKeyMentors("name",e.target.value,index)}}
                                            />
                                        </div>
                                        <div className="mb-3 ">
                                            <label htmlFor="Name" className="form-label text-white">Designation:</label>
                                            <input
                                            type="text"
                                            id="designation"
                                            name="designation"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            value={obj.designation}
                                            onChange={(e)=>{updateValueWithKeyMentors("designation",e.target.value,index)}}
                                            />
                                        </div>
                                        <div className="mb-3 ">
                                            <label htmlFor="Name" className="form-label text-white">Description:</label>
                                            <input
                                            type="text"
                                            id="description"
                                            name="description"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            value={obj.description}
                                            onChange={(e)=>{updateValueWithKeyMentors("description",e.target.value,index)}}
                                            />
                                        </div>
                                        <div className="mb-3 ">
                                            <label htmlFor="Name" className="form-label text-white">User Image Preview:</label>
                                            <img className='img-fluid mb-3 mx-auto d-table' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+obj.image.src} alt={obj.image.alt}/>
                                            <label htmlFor="Name" className="form-label text-white">User Image Src:</label>
                                            <input
                                                type="file"
                                                id="mentor_image"
                                                name="mentor_image"
                                                className="form-control background-dark-tertiary border-0 text-white mb-3"
                                                onChange={(e)=>{validateImageInList(e,50,38/43,"imgcdn.analyticsvidhya.com",`${platform}/mentors/`,setMentorsData,mentorsData,index,"image")}}
                                                placeholder='Enter Thumbnail Url'
                                                accept='image/webp'
                                                />
                                            <label htmlFor="Name" className="form-label text-white">User Image Alt:</label>
                                            <input
                                            type="text"
                                            id="userImageAlt"
                                            name="userImageAlt"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            value={obj.image.alt}
                                            onChange={(e)=>{updateValueWithMultipleKeysMentors("image","alt",e.target.value,index)}}
                                            />
                                        </div>
                                        <div className="mb-3 ">
                                            <label htmlFor="Name" className="form-label text-white">Company Logo Preview:</label>
                                            <img className='img-fluid mb-3 mx-auto d-table bg-white' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL+obj.companyImage.src} alt={obj.companyImage.alt}/>
                                            <label htmlFor="Name" className="form-label text-white">Company Logo Src:</label>
                                            <input
                                                type="file"
                                                id="company_image"
                                                name="company_image"
                                                className="form-control background-dark-tertiary border-0 text-white mb-3"
                                                onChange={(e)=>{validateImageInList(e,50,8/3,"svgcdn.analyticsvidhya.com",`${platform}/company_logo/`,setMentorsData,mentorsData,index,"companyImage")}}
                                                placeholder='Enter Thumbnail Url'
                                                accept='image/webp'
                                                />
                                            <label htmlFor="Name" className="form-label text-white">Company Logo Alt:</label>
                                            <input
                                            type="text"
                                            id="companyLogoAlt"
                                            name="companyLogoAlt"
                                            className="form-control background-dark-tertiary border-0 text-white mb-3"
                                            value={obj.companyImage.alt}                                        
                                            onChange={(e)=>{updateValueWithMultipleKeysMentors("companyImage","alt",e.target.value,index)}}
                                            />
                                        </div>
                                    </div>)
                            })}
                            <button type="button" className='btn border-0 mx-auto d-table text-white' data-bs-toggle="modal" data-bs-target="#addMentorModal">
                                <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M11.9999 3.51472V20.4853M20.4852 12L3.51466 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                </svg> Add New
                            </button>
                        </div>
                    </div>
                        <button type="submit" className="btn btn-dark btn-dark-primary px-5 py-1 mx-auto mt-4 d-block">Submit</button>
                    </form>
                }
                </div>
				</div>
			</div>
		</section>
        <div className="modal lead-modal" data-bs-backdrop="static" id='addTestimonialModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content background-dark-primary shadow-sm rounded-4 p-0">
                <div className="modal-body p-4 pt-0">
                    <div className="form-group mb-3">
                        <label className="me-3 text-white">Order</label>
                        <select
                            name="testimonialOrder"
                            className="form-select background-dark-tertiary border-0 text-white"
                            value={order}
                            onChange={(e)=>{setOrder(e.target.value)}}
                        >
                            <option value="" disabled>Select Order of Tournament</option>
                            {testimonialData && testimonialData.map((obj,index) => (
                                <option key={index} value={index}>
                                    {index}
                                </option>
                            ))}
                            <option key={testimonialData?.length} value={testimonialData?.length}>
                            {testimonialData?.length}
                            </option>
                        </select>
                    </div>
                    <div className="form-group mb-3">
                        <label className="me-3 text-white">Testimonial Type</label>
                        <select
                            name="testimonialType"
                            className="form-select background-dark-tertiary border-0 text-white"
                            value={testimonialType}
                            onChange={(e)=>{setTestimonialType(e.target.value)}}
                        >
                            <option value="" disabled>Select Type of Testimonial</option>
                            <option value="video">Video</option>
                            <option value="text">Text</option>
                        </select>
                    </div>
                    <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Name: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={name}
                        onChange={(e)=>{setName(e.target.value)}}
                        placeholder='Enter Name'
                        />
                    </div>
                    {testimonialType == 'video' && <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Designation: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="designation"
                        name="designation"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={designation}
                        onChange={(e)=>{setDesignation(e.target.value)}}
                        placeholder='Enter Designation'
                        />
                    </div>}
                    <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Title: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="title"
                        name="title"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={title}
                        onChange={(e)=>{setTitle(e.target.value)}}
                        placeholder='Enter Title'
                        />
                    </div>
                    {testimonialType == 'video' && <div className="mb-3 ">   
                        {thumbnailImage && <label htmlFor="Name" className="form-label text-white d-block">Thumbnail Preview:</label>}
                        {thumbnailImage && <img className='img-fluid mb-3 d-block mx-auto' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+thumbnailImage} alt={thumbnailImage}/>}
                        <label htmlFor="Name" className="form-label text-white">Thumbnail Image: <sup className='text-danger'>*</sup></label>
                        <input
                        type="file"
                        id="thumbnail_image"
                        name="thumbnail_image"
                        className="form-control background-dark-tertiary border-0 text-white"
                        onChange={(e)=>{validateImage(e,50,853/480,"imgcdn.analyticsvidhya.com",`${platform}/testimonials/testimonial thumbnail/`,setThumbnailImage)}}
                        placeholder='Enter Thumbnail Url'
                        accept='image/webp'
                        />
                    </div>}
                    {testimonialType == 'text' && <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Text Testimonial: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="text"
                        name="text"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={textTestimonial}
                        onChange={(e)=>{setTextTestimonial(e.target.value)}}
                        placeholder='Enter Text testimonials'
                        />
                    </div>}
                    {testimonialType == 'video' && <div className="mb-3 ">   
                        {userImageSrc && <label htmlFor="Name" className="form-label text-white d-block">User Image Preview:</label>}
                        {userImageSrc && <img className='img-fluid mb-3 mx-auto d-table' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+userImageSrc} alt={userImageAlt}/>}
                        <label htmlFor="Name" className="form-label text-white">User Image: <sup className='text-danger'>*</sup></label>
                        <input
                        type="file"
                        id="user_image"
                        name="thumbnail_image"
                        className="form-control background-dark-tertiary border-0 text-white"
                        onChange={(e)=>{validateImage(e,50,1,"imgcdn.analyticsvidhya.com",`${platform}/testimonials/testimonial images/`,setUserImageSrc)}}
                        placeholder='Enter Thumbnail Url'
                        accept='image/webp'
                        />
                        <label htmlFor="Name" className="form-label text-white mt-3">User Image Alt: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="userImageAlt"
                        name="userImageAlt"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={userImageAlt}
                        onChange={(e)=>{setUserImageAlt(e.target.value)}}
                        placeholder='Enter User Image Alt'
                        />
                    </div>}
                    {testimonialType == 'video' && <div className="mb-3 ">   
                        {companyLogoSrc && <label htmlFor="Name" className="form-label text-white d-block">Company Logo Preview:</label>}
                        {companyLogoSrc && <img className='img-fluid mb-3 mx-auto d-table bg-white' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL+companyLogoSrc} alt={companyLogoAlt}/>}
                        <label htmlFor="Name" className="form-label text-white">Company Logo Image: <sup className='text-danger'>*</sup></label>
                        <input
                        type="file"
                        id="company_image"
                        name="thumbnail_image"
                        className="form-control background-dark-tertiary border-0 text-white"
                        onChange={(e)=>{validateImage(e,50,8/3,"svgcdn.analyticsvidhya.com",`${platform}/testimonials company logo`,setCompanyLogoSrc)}}
                        placeholder='Enter Thumbnail Url'
                        accept='image/webp'
                        />
                        <label htmlFor="Name" className="form-label text-white mt-3">Company Logo Alt: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="userImageAlt"
                        name="userImageAlt"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={companyLogoAlt}
                        onChange={(e)=>{setCompanyLogoAlt(e.target.value)}}
                        placeholder='Enter Company Logo Alt'
                        />
                    </div>}
                    {testimonialType == 'video' && <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Video Url: <sup className='text-danger'>*</sup></label>
                        <input
                        type="text"
                        id="videoUrl"
                        name="videoUrl"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={videoUrl}
                        onChange={(e)=>{setVideoUrl(e.target.value)}}
                        placeholder='Enter Video url'
                        />
                    </div>}
                </div>
                <div className="modal-footer border-0 background-dark-secondary px-4 ">                
                <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 m-0 me-3" onClick={addTestimonial}>Add</button>
                    <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 m-0" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
        </div>
        <div className="modal lead-modal" data-bs-backdrop="static" id='addMentorModal' tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content background-dark-primary shadow-sm rounded-4 p-0">
                <div className="modal-body p-4 pt-0">
                    <div className="form-group mb-3">
                        <label className="me-3 text-white">Order</label>
                        <select
                            name="testimonialOrder"
                            className="form-select background-dark-tertiary border-0 text-white"
                            value={mentorOrder}
                            onChange={(e)=>{setMentorOrder(e.target.value)}}
                        >
                            <option value="" disabled>Select Order of Tournament</option>
                            {mentorsData && mentorsData.map((obj,index) => (
                                <option key={index} value={index}>
                                    {index}
                                </option>
                            ))}
                            <option key={mentorOrder} value={mentorOrder}>
                            {mentorOrder}
                        </option>
                        </select>
                    </div>
                    <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Name:</label>
                        <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={mentorName}
                        onChange={(e)=>{setMentorName(e.target.value)}}
                        placeholder='Enter name of mentor'
                        />
                    </div>
                    <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Designation:</label>
                        <input
                        type="text"
                        id="designation"
                        name="designation"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={mentorDesignation}
                        onChange={(e)=>{setMentorDesignation(e.target.value)}}
                        placeholder='Enter designation of mentor'
                        />
                    </div>
                    <div className="mb-3 ">
                        <label htmlFor="Name" className="form-label text-white">Description:</label>
                        <input
                        type="text"
                        id="description"
                        name="description"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={mentorDescription}
                        onChange={(e)=>{setMentorDescription(e.target.value)}}
                        placeholder='Enter description of mentor'
                        />
                    </div>
                    <div className="mb-3 ">
                        {mentorImage && <label htmlFor="Name" className="form-label text-white">User Image Preview:</label>}
                        {mentorImage && <img className='img-fluid mb-3 mx-auto d-table' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+mentorImage} alt={mentorImageAlt}/>}
                        <label htmlFor="Name" className="form-label text-white">User Image Src:</label>
                        <input
                        type="file"
                        id="mentor_image"
                        name="mentor_image"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        onChange={(e)=>{validateImage(e,50,	38/43,"imgcdn.analyticsvidhya.com",`${platform}/mentors/`,setMentorImage)}}
                        placeholder='Enter Thumbnail Url'
                        accept='image/webp'
                        />
                        <label htmlFor="Name" className="form-label text-white">User Image Alt:</label>
                        <input
                        type="text"
                        id="userImageAlt"
                        name="userImageAlt"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={mentorImageAlt}
                        onChange={(e)=>{setMentorImageAlt(e.target.value)}}
                        placeholder='Enter mentor image alt text'
                        />
                    </div>
                    <div className="mb-3 ">
                        {mentorCompany && <label htmlFor="Name" className="form-label text-white">Company Logo Preview:</label>}
                        {mentorCompany && <img className='img-fluid mb-3 mx-auto d-table bg-white' width={200} src={process.env.REACT_APP_BASE_CLOUDFLARE_URL+mentorCompany} alt={mentorCompanyAlt}/>}
                        <label htmlFor="Name" className="form-label text-white">Company Logo Src:</label>
                        <input
                        type="file"
                        id="company_image"
                        name="company_image"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        onChange={(e)=>{validateImage(e,50,8/3,"svgcdn.analyticsvidhya.com",`${platform}/company_logo/`,setMentorCompany)}}
                        placeholder='Enter Thumbnail Url'
                        accept='image/webp'
                        />
                        <label htmlFor="Name" className="form-label text-white">Company Logo Alt:</label>
                        <input
                        type="text"
                        id="companyLogoAlt"
                        name="companyLogoAlt"
                        className="form-control background-dark-tertiary border-0 text-white mb-3"
                        value={mentorCompanyAlt}                                        
                        onChange={(e)=>{setMentorCompanyAlt(e.target.value)}}
                        placeholder='Enter company image alt text'
                        />
                    </div>
                </div>
                <div className="modal-footer border-0 background-dark-secondary px-4 ">                
                <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 m-0 me-3" onClick={addMentor}>Add</button>
                    <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 m-0" data-bs-dismiss="modal">Cancel</button>
                </div>
            </div>
            </div>
        </div>
	</>
  )
}
import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';
import { connect } from 'react-redux';
import Pagenumber from '../../../common/Page/Pagination';

function ProblemStatement({csrf,islogin}) {
    const [problemStatement,setProblemStatement] = useState([]);
    const [totalPages,setTotalPages] = useState(null);
    useEffect(()=>{
        if(islogin){
            let query = `
            query MyQuery {
                allProblemStatements(limit: 10, page: 1) {
                  totalPages
                  problemStatement{
                    createdAt
                    id
                    updatedAt
                    title
                    stage {
                    id
                    }
                  }
                }
            }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data.allProblemStatements.problemStatement);
                setProblemStatement(data.data.allProblemStatements.problemStatement);
                setTotalPages(data.data.allProblemStatements.totalPages);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])

    const updateProblemStatementByPageNumber = (pagenumber)=>{
        let query = `
            query MyQuery {
                allProblemStatements(limit: 10, page: ${pagenumber}) {
                  totalPages
                  problemStatement{
                    createdAt
                    id
                    updatedAt
                    title
                    stage {
                    id
                    }
                  }
                }
            }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data.allProblemStatements.problemStatement);
                setProblemStatement(data.data.allProblemStatements.problemStatement);
                setTotalPages(data.data.allProblemStatements.totalPages);
            })
            .catch((error)=>{
                console.log(error);
            })
    }
  return (
    <> 
        <Header />
        <section id="updateProblemStatement" className='common-style min-vh-100 background-dark-primary'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/problem-statement/create-problem-statement'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Problem Statement
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Problem Id</th>
                                        <th>Title</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {problemStatement && problemStatement.map((problem)=>{
                                        return(
                                            <tr>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/problem-statement/'+problem.stage.id}>{problem.id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/problem-statement/'+problem.stage.id}>{problem.title}</a></td>
                                                <td>{problem.createdAt}</td>
                                                <td>{problem.updatedAt}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                    {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateProblemStatementByPageNumber} />}
                </div>
            </div>
        </section>
    </>
  )
}



const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(ProblemStatement);

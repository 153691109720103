import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Homepage from '..';
import CookiesPolicy from '../StaticPages/CookiesPolicy';
import AboutUs from '../StaticPages/AboutUs';
import Error404 from '../../../common/Alert/Error404';
import PrivacyPolicy from '../StaticPages/PrivacyPolicy';
import RefundPolicy from '../StaticPages/RefundPolicy';
import TermsOfUse from '../StaticPages/TermsOfUse';
import ContactUs from '../StaticPages/ContactUs';
import Careers from '../StaticPages/Careers';

export default function HomepageRouters() {
  return (
    <>
      <Routes>
          <Route path='*' element={<Error404 />} />
          <Route path='/' exact element={<Homepage />} />
          <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
          <Route path='/cookies-policy' exact element={<CookiesPolicy />} />
          <Route path='/refund-policy' exact element={<RefundPolicy />} />
          <Route path='/terms' exact element={<TermsOfUse />} />
          <Route path='/contact' exact element={<ContactUs />} />
          <Route path='/careers' exact element={<Careers />} />
          <Route path='/about' exact element={<AboutUs />}/>
      </Routes>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header'
import { connect } from 'react-redux';

function Quiz({csrf}) {
    const [quizs,setQuizs] = useState(null);
    useEffect(()=>{
        if(csrf){
            const formData = new FormData();
            formData.append("platform", 'quizmania');
            formData.append("url", 'quiz/all');
            formData.append("forward_user_data", "True");
            formData.append("method","GET");
            formData.append("timeout", 100);

            fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
                method: 'POST',
                body:formData,
                headers: {
                    "X-CSRFToken":csrf,
                },
                credentials:process.env.REACT_APP_CREDENTIAL_TYPE
            })
            .then(response => {
                return response.json();
            })
            .then(result => {
                console.log(result.response[0])
                setQuizs(result.response)
            })
            .catch(error => {
                console.log(error);
            });
        }
    },[csrf])
  return (
    <>
        <Header />
        <section id="quiz" className='common-style min-vh-100 background-dark-primary'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/quiz/create-quiz'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Quiz
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Quiz Id</th>
                                        <th>Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {quizs && quizs.map((quiz)=>{
                                        return(
                                            <tr>
                                                <td><a className='text-white text-nowrap' href={'/av-admin/datahack-admin/quiz/'+quiz.quiz_id}>{quiz.quiz_id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/quiz/'+quiz.quiz_id}>{quiz.title}</a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
import { FETCH_FREE_COURSES_CONTENT_REQUEST, FETCH_FREE_COURSES_CONTENT_SUCCESS, FETCH_FREE_COURSES_CONTENT_FAILURE } from '../Contants/index';
  
const initialState = {
    freecoursecontent: null,
    loading: false,
    error: null,
    country_code:null
};
  
export const freeCourseContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_FREE_COURSES_CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                country_code:null
            };
        case FETCH_FREE_COURSES_CONTENT_SUCCESS:
            return {
                ...state,
                freecoursecontent: JSON.parse(action.payload.data.getPlatformJsonDataByName.platformData),
                loading: false,
                error: null,
                country_code:action.payload.country_code
            };
        case FETCH_FREE_COURSES_CONTENT_FAILURE:
        return {
            ...state,
            freecoursecontent: null,
            loading: false,
            error: action.payload,
            country_code:null
        };
        default:
            return state;
    }
};
  
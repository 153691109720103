import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import Pagination from '../../../common/Page/Pagination';
import {
    convertDateInFormat,
    convertDateToLocalDatetime,
    convertTimeToFormattedString,
    scrollTop,
} from '../../../../utils';
import { getLeadingWithDataByPageNumberData, getLeadingWithDataListData } from '../../../../store/Actions/Action';
import { connect } from 'react-redux';
import Cookies from '../../../common/Footer/Cookies';
import Flashstrip from '../../../common/Header/Flashstrip';
import Loader from '../../../common/Loader';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

const LeadingWithDataListing = ({leadingwithdataList,getLeadingWithDataListDataHandler,leadingwithdataByPageNumber,getLeadingWithDataByPageNumberDataHandler}) => {
    const [tab,setTab] = useState('');
    const [isLoader, setIsLoader] = useState(false);

    useEffect(()=>{
        getLeadingWithDataByPageNumberDataHandler(1);
        getLeadingWithDataListDataHandler();
    },[getLeadingWithDataByPageNumberDataHandler, getLeadingWithDataListDataHandler])

    let updatePageNumber = (page_number)=>{
        setIsLoader(true);
        getLeadingWithDataByPageNumberDataHandler(page_number,setIsLoader);
    }

    useEffect(()=>{
        scrollTop();
    },[])

    useEffect(()=>{
        console.log("leadingwithdataByPageNumber",leadingwithdataByPageNumber);
        console.log("leadingwithdata", leadingwithdataList);
      },[leadingwithdataByPageNumber, leadingwithdataList])

  return (
    <>
        {isLoader && <Loader />}
        <Header />
        <section id="heroEventDetail" className='common-style background-dark-primary pb-5'>
            <div className="container">
                <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item text-dark-tertiary"><a href="/events" className='text-dark-tertiary text-decoration-none'>Events</a></li>
                        <li class="breadcrumb-item active text-white" aria-current="page">Leading with Data</li>
                    </ol>
                </nav>
                <h1 className='text-white pb-3'>Latest in Leading with Data</h1>
                {leadingwithdataList && leadingwithdataList.videoData && leadingwithdataList.videoData[0] && <div className="banner-image-wrapper">
                    <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[0].slug} id={`lwd-latest-`+leadingwithdataList.videoData[0].slug}><img src={leadingwithdataList.videoData[0].bannerUrl} alt="Leading with Data" className='img-fluid rounded-4'/></a>
                </div>}
            </div>
        </section>
        <section className='background-dark-secondary border border-dark'>
            <div className='container'>
                <div className='row'>
                    <ul class="nav nav-tabs px-2 py-4 rounded-0 bg-transparent av-tab-design border-0" id="datahourDetailTab" role="tablist">
                        <li class="nav-item d-none" role="presentation">
                        <button class="nav-link active" id="last-week-tab" data-bs-toggle="tab" data-bs-target="#last-week" type="button" role="tab" aria-controls="last-week" aria-selected="true" onClick={()=>{setTab("last week")}}>Last week</button>
                        </li>
                        <li class="nav-item d-none" role="presentation">
                        <button class="nav-link" id="past-1-month-tab" data-bs-toggle="tab" data-bs-target="#past-1-month" type="button" role="tab" aria-controls="past-1-month" aria-selected="false" onClick={()=>{setTab("last month")}}>Past 1 month</button>
                        </li>
                        <li class="nav-item d-none" role="presentation">
                        <button class="nav-link" id="past-3-months-tab" data-bs-toggle="tab" data-bs-target="#past-3-months" type="button" role="tab" aria-controls="past-3-months" aria-selected="false" onClick={()=>{setTab("last 3 months")}}>Past 3 months</button>
                        </li>
                        <li class="nav-item d-none" role="presentation">
                        <button class="nav-link" id="past-6-months-tab" data-bs-toggle="tab" data-bs-target="#past-6-months" type="button" role="tab" aria-controls="past-6-months" aria-selected="false" onClick={()=>{setTab("last 6 months")}}>Past 6 months</button>
                        </li>
                        <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="all-ldw-list-tab" data-bs-toggle="tab" data-bs-target="#all-ldw-list" type="button" role="tab" aria-controls="all-ldw-list" aria-selected="true">All Episodes</button>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section className='background-dark-primary'>
            <div className='container'>
            <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-12'>
              
                <div class="tab-content" id="datahourDetailContent">
                    <div class="tab-pane fade show active" id="all-ldw-list" role="tabpanel" aria-labelledby="all-ldw-list-tab">
                    {leadingwithdataByPageNumber && <section className='background-dark-primary common-style-py common-card-style' id='leadingWithData'>
                        <div className='container'>
                            <div className='row gx-4'>
                                {leadingwithdataByPageNumber.allVideos && leadingwithdataByPageNumber.allVideos.videoData && leadingwithdataByPageNumber.allVideos.videoData.map((video,index)=>(
                                    <div key={index} className="col-lg-4 col-md-4 col-12 mb-3">
                                        <a href={'/events/leading-with-data/'+video.slug} className='text-decoration-none' id={`lwd-`+video.slug+`CardLink`}>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body p-0">
                                                <div className="overlay-wrapper position-relative img-ratio">
                                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                        <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                                </div>
                                                <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <Pagination last_page={leadingwithdataByPageNumber.allVideos.totalPages} updatePageNumber={updatePageNumber} />
                        </div>
                    </section>}
                    </div>
                    {/* <div class="tab-pane fade show active d-none" id="last-week" role="tabpanel" aria-labelledby="last-week-tab">
                    {lastweekData && <section className='background-dark-primary common-style-py common-card-style' id='leadingWithData'>
                        <div className='container'>
                            <div className='row gx-4'>
                                {lastweekData.videoData && lastweekData.videoData.map((video,index)=>(
                                    <div key={index} className="col-lg-4 col-md-4 col-12 mb-3">
                                        <a href={video.slug} className='text-decoration-none'>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body p-0">
                                                <div className="overlay-wrapper position-relative img-ratio">
                                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                        <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                                </div>
                                                <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <Pagination last_page={lastweekData.totalPages} updatePageNumber={updatePageNumber} />
                        </div>
                    </section>}
                    </div>
                    <div class="tab-pane fade d-none" id="past-1-month" role="tabpanel" aria-labelledby="past-1-month-tab">
                    {lastMonthData && <section className='background-dark-primary common-style-py common-card-style' id='leadingWithData'>
                        <div className='container'>
                            <div className='row gx-4'>
                                {lastMonthData.videoData && lastMonthData.videoData.map((video,index)=>(
                                    <div key={index} className="col-lg-4 col-md-4 col-12 mb-3">
                                        <a href={video.slug} className='text-decoration-none'>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body p-0">
                                                <div className="overlay-wrapper position-relative img-ratio">
                                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                        <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                                </div>
                                                
                                                    <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                                
                                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <Pagination last_page={lastMonthData.totalPages} updatePageNumber={updatePageNumber} />
                        </div>
                    </section>}
                    </div>
                    <div class="tab-pane fade d-none" id="past-3-months" role="tabpanel" aria-labelledby="past-3-months-tab">
                    {last3monthsData && <section className='background-dark-primary common-style-py common-card-style' id='leadingWithData'>
                        <div className='container'>
                            <div className='row gx-4'>
                                {last3monthsData.videoData && last3monthsData.videoData.map((video,index)=>(
                                    <div key={index} className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
                                        <a href={video.slug} className='text-decoration-none'>
                                        <div className="card bg-transparent h-100 border-0">
                                            <div className="card-body p-0">
                                                <div className="overlay-wrapper position-relative img-ratio">
                                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                        <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                                </div>
                                                
                                                    <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                                
                                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <Pagination last_page={last3monthsData.totalPages} updatePageNumber={updatePageNumber} />
                        </div>
                    </section>}
                    </div>
                    <div class="tab-pane fade d-none" id="past-6-months" role="tabpanel" aria-labelledby="past-6-months-tab">
                    {last6monthsData && <section className='background-dark-primary common-style-py common-card-style' id='leadingWithData'>
                        <div className='container'>
                            <div className='row gx-4'>
                                {last6monthsData.videoData && last6monthsData.videoData.map((video,index)=>(
                                    <div key={index} className="col-lg-4 col-md-4 col-12 mb-3">
                                        <a href={video.slug} className='text-decoration-none'>
                                        <div className="card bg-transparent border-0">
                                            <div className="card-body p-0">
                                                <div className="overlay-wrapper position-relative img-ratio">
                                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                        <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                    <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                                </div>
                                                
                                                    <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                                
                                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                ))}
                            </div>
                            <Pagination last_page={last6monthsData.totalPages} updatePageNumber={updatePageNumber} />
                        </div>
                    </section>}
                    </div> */}
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className='common-style-py background-dark-primary clearfix hover-state d-none' id='moneyGuarantee'>
            <div className='container'>
            <div className='row background-dark-secondary p-5 rounded-4 px-2 px-md-5'>
                <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
                    <div className='text-white heading-with-text mb-5'>
                        <h2 className='fs-40 fw-normal mb-3 fs-18'>Become a Speaker
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M6.67188 15.9995H25.3385" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16 6.66602L25.3333 15.9993L16 25.3327" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </h2>
                        <p className='text-dark-tertiary'> Share your vision, inspire change, and leave a mark on the industry. We're calling for innovators and thought leaders to speak at our event</p>
                    </div>
                    <div className='d-flex flex-wrap'>
                        <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Professional Exposure</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Networking Opportunities</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Thought Leadership</span>
                            </li>
                        </ul>
                        <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Knowledge Exchange</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Leading-Edge Insights</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Community Contribution</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 my-auto'>
                    <img className='img-fluid rounded-2 d-table mx-auto' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/become_a_speaker_listing.png?format=webp&width=648"} alt="" />
                </div>
            </div>
            </div>
        </section>
        <Footer platform={"newevents_leading_with_data_listing"}/>
        <Cookies platform={'newevents'}  />
        <Flashstrip />
    </>
  )
}

const mapStateToProps = (state) => ({
	leadingwithdataList:state.leadingWithDataContentReducer.leadingwithdataList,
	// lastweekData:state.leadingWithDataContentReducer.lastweekData,
	// lastMonthData:state.leadingWithDataContentReducer.lastMonthData,
	// last3monthsData:state.leadingWithDataContentReducer.last3monthsData,
	// last6monthsData:state.leadingWithDataContentReducer.last6monthsData
    leadingwithdataByPageNumber:state.leadingWithDataContentReducer.leadingwithdataByPageNumber,
});

const mapDispatchToProps = dispatch=>({
    getLeadingWithDataByPageNumberDataHandler:(data,setIsLoader)=>{dispatch(getLeadingWithDataByPageNumberData(data,setIsLoader))},
    getLeadingWithDataListDataHandler:data=>{dispatch(getLeadingWithDataListData(data))},
});

export default connect(mapStateToProps,mapDispatchToProps)(LeadingWithDataListing);
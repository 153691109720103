import React, { useState } from 'react';
import $ from "jquery";
import { getQueryDataElevateUser } from '../../../../utils';


const EventModal = ({ islogin, csrf, sessionName, sessionId, setSuccess, setSuccessMessage, getEventDatahourDetailDataHandler, eventSlug, user_data, urlSearchParams }) => {

    const [autoRegister, setAutoRegister] = useState(false);

    const utm_source = urlSearchParams.get("utm_source");
    const utm_medium = urlSearchParams.get("utm_medium");
    const utm_term = urlSearchParams.get("utm_term");
    const utm_content = urlSearchParams.get("utm_content");
    const utm_campaign = urlSearchParams.get("utm_campaign");

    const buildArguments = (sessionId, autoRegister, utm_source, utm_medium, utm_term, utm_content, utm_campaign) => {
        const args = [];
        args.push(`sid: ${sessionId}`);
        args.push(`autoRegister: ${autoRegister}`);
        args.push(`termsAccepted: true`);
        if (utm_source) args.push(`utmSource: "${utm_source}"`);
        if (utm_medium) args.push(`utmMedium: "${utm_medium}"`);
        if (utm_term) args.push(`utmTerm: "${utm_term}"`);
        if (utm_content) args.push(`utmContent: "${utm_content}"`);
        if (utm_campaign) args.push(`utmCampaign: "${utm_campaign}"`);
        return `(${args.join(', ')})`;
    };

    const removebackdrop = () => {
        $('.modal-backdrop.show').remove();
        $('body').removeClass('modal-open');
        $('body').removeAttr('style');
    }

    const registerEvent = (e) => {
        e.preventDefault();

        // let query = `
        //     mutation create{
        //         createRegistration(sid: ${sessionId}, autoRegister: ${autoRegister}, termsAccepted: true){
        //         message
        //         register{
        //             userId
        //             registered
        //             termsAccepted
        //             session{
        //             sessionName
        //             sessionType
        //             }
        //         }
        //         }
        //     }
        // `;
        let query = `
            mutation create{
                createRegistration${buildArguments(sessionId, autoRegister, utm_source, utm_medium, utm_term, utm_content, utm_campaign)} {
                message
                register{
                    userId
                    registered
                    termsAccepted
                    session{
                    sessionName
                    sessionType
                    }
                }
                }
            }
        `;

        getQueryDataElevateUser(query, "newevents", 'True', csrf)
            .then((data) => {
                if (csrf != "") {
                    let tempdata = {
                        eventSlug: eventSlug,
                        csrf: csrf,
                        islogin
                    }
                    getEventDatahourDetailDataHandler(tempdata);
                }
                window.$('#eventModal').modal('hide');
                window.$('#eventRegistrationSuccessModal').modal('show');
            })
            .catch((error) => {
                console.log(error);
            })
    };

    return (
        <>
            <div className="modal lead-modal" data-bs-backdrop="static" id='eventModal' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content background-dark-primary shadow-sm rounded-4">
                        <div className="modal-header border-0 p-0">
                            <h2 className='text-white fs-32'>Register to {sessionName}!</h2>
                            <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body p-0 pt-4 pb-4">
                            <div className="mt-4 mb-4">
                                <div className="form-check d-flex align-items-center mb-3">
                                    <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionModal" defaultChecked disabled />
                                    <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>Terms & Conditions</a>{" "}and allow Analytics Vidhya to notify me for upcoming Datahours.</label>
                                </div>
                                <div className="form-check d-flex align-items-center mb-3">
                                    <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isAutoRegisterModal" onChange={(e) => setAutoRegister(e.target.checked)} />
                                    <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isAutoRegisterModal">Auto-register in similar DataHours</label>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer border-0 p-0">
                            <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0 accept-register" onClick={registerEvent}>Accept & Register</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal lead-modal" data-bs-backdrop="static" id='eventRegistrationSuccessModal' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content background-dark-primary shadow-sm rounded-4">
                        <div className="modal-header border-0 p-0">
                            <button type="button" className="btn background-dark-secondary p-2 rounded-circle close ms-auto" data-bs-dismiss="modal" aria-label="Close" >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="hurray-icon mx-auto d-table mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="88" height="88" viewBox="0 0 88 88" fill="none">
                                    <g clip-path="url(#clip0_15270_425350)">
                                        <path d="M1.40713 85.5862C2.83249 87.0115 4.99628 87.3956 6.8249 86.5474L56.4537 65.1804C58.8828 64.0541 59.4504 60.8544 57.5573 58.9611L28.1395 29.5433C26.2461 27.6499 23.0465 28.2176 21.9201 30.6469L0.445893 80.1684C-0.402089 81.997 -0.0182305 84.1608 1.40713 85.5862Z" fill="#FFE266" />
                                        <path d="M42.749 60.5012L6.26728 76.2078C4.97793 76.8057 3.46349 76.5686 2.41723 75.6239L0.445893 80.1699C-0.402089 81.9987 -0.0182305 84.1623 1.40713 85.5877C2.83249 87.013 4.99628 87.3969 6.8249 86.5489L55.9777 65.3869C54.8454 64.933 42.9332 60.4158 42.749 60.5012Z" fill="#EFAA66" />
                                        <path d="M57.903 63.1732C61.2942 59.782 56.3637 49.3533 46.8904 39.8801C37.4172 30.4068 26.9885 25.4763 23.5973 28.8675C20.2061 32.2587 25.1366 42.6874 34.6099 52.1606C44.0831 61.6339 54.5118 66.5644 57.903 63.1732Z" fill="#FFE266" />
                                        <path d="M49.305 58.8038C51.5295 56.5793 47.9263 49.3693 41.2569 42.6999C34.5875 36.0305 27.3775 32.4272 25.1529 34.6518C22.9284 36.8763 26.5316 44.0863 33.201 50.7557C39.8704 57.4251 47.0804 61.0284 49.305 58.8038Z" fill="#EFAA66" />
                                        <path d="M54.8495 66.0124C49.4435 66.0124 41.0715 61.1124 33.3696 53.4105C26.376 46.4168 21.5836 38.7145 20.8633 33.3096C20.7347 32.3438 21.413 31.4567 22.3788 31.3281C23.3437 31.1998 24.2318 31.8778 24.3604 32.8436C24.9741 37.4474 29.4897 44.5412 35.8645 50.9158C44.0097 59.061 51.5205 62.6124 55.0504 62.4804C56.0227 62.445 56.8442 63.1997 56.8831 64.1732C56.9217 65.1466 56.1638 65.9673 55.1903 66.0059C55.0782 66.0101 54.9639 66.0124 54.8495 66.0124Z" fill="#D0844B" />
                                        <path d="M35.9884 38.7814C35.5744 38.3674 35.2549 37.8472 35.0775 37.246L33.4462 31.7245C32.8301 29.6394 33.3861 27.4054 34.897 25.8945L37.8624 22.9291C39.3735 21.418 41.6075 20.8621 43.6926 21.4783L49.6322 23.1547L47.6289 16.0076C47.0612 14.0864 48.1587 12.0688 50.0799 11.5012C52.0011 10.9335 54.0187 12.0309 54.5865 13.9522L56.2178 19.4737C56.8338 21.5589 56.2779 23.793 54.767 25.3037L51.8016 28.2691C50.2907 29.78 48.0567 30.3358 45.9716 29.7198L39.9713 27.7631L42.0351 35.1905C42.6028 37.1118 41.5053 39.1293 39.5841 39.697C38.2637 40.087 36.8978 39.6908 35.9884 38.7814Z" fill="#CAB4EF" />
                                        <path d="M49.6311 23.1576L43.6915 21.4812C41.8992 20.9517 39.9976 21.2886 38.5391 22.3529C38.9474 23.9858 39.467 26.1581 39.9701 27.7659L45.9703 29.7226C47.8476 30.2772 49.8451 29.8815 51.3274 28.6946C50.8024 27.0512 50.1307 24.8088 49.6311 23.1576Z" fill="#A183E2" />
                                        <path d="M58.9597 36.195C59.3738 36.609 59.8942 36.9285 60.4952 37.106L66.0167 38.7372C68.1016 39.3532 70.3358 38.7973 71.8467 37.2864L74.8121 34.321C76.3232 32.8099 76.879 30.5759 76.2628 28.4908L74.5865 22.5512L81.7335 24.5543C83.6548 25.122 85.6725 24.0246 86.2402 22.1034C86.8078 20.1821 85.7104 18.1646 83.7892 17.5969L78.2677 15.9657C76.1824 15.3497 73.9484 15.9057 72.4377 17.4164L69.4723 20.3818C67.9614 21.8927 67.4055 24.1267 68.0215 26.2118L69.978 32.2121L62.5506 30.1483C60.6294 29.5807 58.6119 30.6781 58.0442 32.5993C57.654 33.9197 58.0504 35.2856 58.9597 36.195Z" fill="#F7C4DB" />
                                        <path d="M76.2617 28.4956L74.5853 22.556C72.8572 22.1836 70.4398 21.7072 68.715 21.3203C67.7916 22.7413 67.5213 24.5277 68.0204 26.2168L69.9769 32.2171C71.7658 32.6588 73.6588 32.9634 75.3763 33.6668C76.4515 32.2058 76.7935 30.2955 76.2617 28.4956Z" fill="#EF8BBD" />
                                        <path d="M67.4068 6.37167C66.2567 7.52183 64.3917 7.52183 63.2415 6.37167C62.0914 5.2215 62.0914 3.35654 63.2415 2.20637C64.3917 1.05621 66.2567 1.05621 67.4068 2.20637C68.557 3.35672 68.557 5.2215 67.4068 6.37167Z" fill="#FFA585" />
                                        <path d="M71.8515 55.3046C73.4782 55.3046 74.7968 53.986 74.7968 52.3593C74.7968 50.7327 73.4782 49.4141 71.8515 49.4141C70.2249 49.4141 68.9062 50.7327 68.9062 52.3593C68.9062 53.986 70.2249 55.3046 71.8515 55.3046Z" fill="#78EAC4" />
                                        <path d="M55.7389 54.9732C54.5887 56.1234 52.7237 56.1234 51.5736 54.9732C50.4234 53.8231 50.4234 51.9581 51.5736 50.8079C52.7237 49.6578 54.5887 49.6578 55.7389 50.8079C56.889 51.9583 56.889 53.8231 55.7389 54.9732Z" fill="#78EAC4" />
                                        <path d="M55.7389 54.9732C54.5887 56.1234 52.7237 56.1234 51.5736 54.9732C50.4234 53.8231 50.4234 51.9581 51.5736 50.8079C52.7237 49.6578 54.5887 49.6578 55.7389 50.8079C56.889 51.9583 56.889 53.8231 55.7389 54.9732Z" fill="#8BB6EF" />
                                        <path d="M16.037 63.6087C15.8043 63.6087 15.5676 63.5623 15.3404 63.4644C14.4455 63.0791 14.0323 62.0415 14.4178 61.1466L16.6518 55.9578C17.037 55.063 18.075 54.6492 18.9696 55.0352C19.8645 55.4204 20.2776 56.4581 19.8922 57.353L17.6582 62.5418C17.3708 63.2091 16.7204 63.6087 16.037 63.6087Z" fill="white" />
                                        <path d="M20.7714 52.6109C20.5387 52.6109 20.3021 52.5645 20.0746 52.4666C19.1798 52.0813 18.7667 51.0437 19.1522 50.1486L19.8331 48.567C20.2183 47.6724 21.2556 47.2586 22.151 47.6445C23.0458 48.0298 23.4589 49.0674 23.0737 49.9625L22.3927 51.5442C22.1052 52.2113 21.4546 52.6109 20.7714 52.6109Z" fill="white" />
                                        <path d="M34.6134 1.43184L36.0624 5.50646C36.1245 5.68093 36.2473 5.81923 36.4023 5.88908L40.0224 7.52013C40.5338 7.75052 40.5338 8.56534 40.0224 8.7959L36.4023 10.4269C36.2473 10.4968 36.1245 10.6351 36.0624 10.8094L34.6134 14.884C34.4088 15.4596 33.6846 15.4596 33.48 14.884L32.031 10.8094C31.9689 10.6349 31.8461 10.4966 31.6911 10.4269L28.0711 8.7959C27.5596 8.56551 27.5596 7.75069 28.0711 7.52013L31.6911 5.88908C31.8461 5.81923 31.9689 5.68093 32.031 5.50646L33.48 1.43184C33.6846 0.856053 34.4088 0.856053 34.6134 1.43184Z" fill="#F7C4DB" />
                                        <path d="M83.7039 38.0624L84.7788 41.0847C84.8248 41.2142 84.9158 41.3167 85.0308 41.3686L87.7161 42.5784C88.0955 42.7493 88.0955 43.3537 87.7161 43.5246L85.0308 44.7344C84.9158 44.7862 84.8248 44.8887 84.7788 45.0182L83.7039 48.0406C83.552 48.4677 83.015 48.4677 82.8632 48.0406L81.7883 45.0182C81.7423 44.8887 81.6513 44.7862 81.5362 44.7344L78.851 43.5246C78.4715 43.3537 78.4715 42.7493 78.851 42.5784L81.5362 41.3686C81.6513 41.3167 81.7423 41.2142 81.7883 41.0847L82.8632 38.0624C83.015 37.6355 83.552 37.6355 83.7039 38.0624Z" fill="#CAB4EF" />
                                        <path d="M63.7164 15.3C64.4702 15.3 65.0813 14.689 65.0813 13.9352C65.0813 13.1814 64.4702 12.5703 63.7164 12.5703C62.9626 12.5703 62.3516 13.1814 62.3516 13.9352C62.3516 14.689 62.9626 15.3 63.7164 15.3Z" fill="#78EAC4" />
                                        <path d="M84.3816 5.48799C84.5514 4.10629 83.5688 2.84858 82.187 2.67881C80.8052 2.50904 79.5474 3.49151 79.3777 4.87321C79.2079 6.25491 80.1905 7.51262 81.5723 7.68239C82.9541 7.85216 84.2119 6.86969 84.3816 5.48799Z" fill="#8BB6EF" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_15270_425350">
                                            <rect width="88" height="88" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <h3 className="fs-20 fw-normal text-white text-center mb-2">Congratulatons {user_data.username}!</h3>
                            <h4 className="fs-24 fw-semibold text-white text-center mb-3">You are successfully registered</h4>
                            <p className="fs-14 fw-normal text-dark-secondary text-center mb-3">Get ready to explore, learn, and connect.</p>
                        </div>
                        <div className="modal-footer border-0 p-0">
                            <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0 lets-get-started" data-bs-dismiss="modal">Let's Get Started</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventModal;
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import $ from 'jquery';
import React,{ Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import leftshap from '../../../../../assets/images/bb-image/left-shap.png';
import { fetchBlackbeltContent } from '../../../../../store/Actions/Action';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import Footer from '../../../../common/Footer/Footer';
import 'swiper/css';
import 'swiper/css/pagination';
import moment from 'moment';
import { getCSRF, sendOtp } from '../../../../../utils/Login';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Flashstrip from '../../../../common/Header/Flashstrip';
import Title from '../../../../common/Header/Title';
import BlackbeltVideoModal from '../components/BlackbeltVideoModal';
import FreeTrialHeader from '../components/FreeTrialHeader';
import FreeTrialTwoFieldLeadModal from '../components/FreeTrialTwoFieldLeadModal';
const BlackbeltFreeTrialSwiperComponent = React.lazy(() => import("../components/BlackbeltFreeTrialSwiperComponent"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;
var iti2 = null;

function FreeTrialBlackbelt({blackbeltcontent, fetchBlackbeltContent,country_name}) {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [success,setSuccess] = useState(false);
  const [iserror,setIsError] = useState(false);
  const [formname,setFormname] = useState(null);
  const [section,setSection] = useState(null);
  const [successMessage,setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
  const [leadFormHeading,setLeadFormHeading] = useState(null);
  const [leadFormCta,setLeadFormCta] = useState(null);  
  const [csrf, setCsrf] = useState('');
  const [otprequestid,setOtpRequestId] = useState('');
  const [countryCode,setCountryCode] = useState('');
  const [videoUrl,setVideoUrl] = useState(null);

  useEffect(() => {
    if(!blackbeltcontent){
      fetchBlackbeltContent();
    }
    scrollTop();
  }, [fetchBlackbeltContent]);

  useEffect(()=>{
    console.log(blackbeltcontent)
    const input = document.getElementById("bbNumber");
    const input1 = document.getElementById('contactPhoneNumber');
    if(input){
      iti1 = intlTelInput(input, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti1.setCountry(country_name);
      }
    }
    if(input1){
      iti2 = intlTelInput(input1, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti2.setCountry(country_name);
      }
    }
  },[blackbeltcontent])

  useEffect(()=>{
		// Timer Script
    if(blackbeltcontent){
      var now = new Date();
      var diffInMilliSeconds = (moment((blackbeltcontent && blackbeltcontent.latestOffers.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
      if (diffInMilliSeconds >0){

      
      var days = Math.floor( diffInMilliSeconds / 86400);
      diffInMilliSeconds -= days * 86400;
      
      const hours = Math.floor(diffInMilliSeconds / 3600);
      diffInMilliSeconds -= hours * 3600;
      
      const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
      diffInMilliSeconds -= minutes * 60;
      diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
      
      var countdownNumberEl = $('.seconds');
      var countdown = diffInMilliSeconds;
      countdownNumberEl.text(countdown);
      
      var countMin = $('.minutes')
      var countmin=minutes;
      countMin.text(countmin);
      
      var countHour = $('.hours')
      var counthour=hours;
      countHour.text(counthour);
      
      var countDay = $('.days')
      var countday=days;
      countDay.text(countday);
      
      var timer=setInterval(timer_function,1000);
      function timer_function() {
      if(--countdown<= -1)
      {
        if(countmin!=0)
        {
        countdown=59;
        --countmin;
        }
        else
        {
        if(counthour!=0)
        {
          countdown=59
          countmin=59;
          --counthour;
        }
        else
        {
          if(countday!=0)
          { 
          countdown=59
          countmin=59;
          counthour=23;
          --countday;
          }
          else
          {
          countdown=0;
            if (countdown==0&&countmin==0&&counthour==0)
            {
            var myobj=document.querySelectorAll('.offer-strip');
            myobj.remove();
            clearInterval(timer);
            }
          }
          }
        }
        }
      else
      {
        countdown=countdown
      }
      
      
      countdownNumberEl.text(countdown);
      countMin.text(countmin);
      countHour.text(counthour);
      countDay.text(countday);
      } 
      }
      else{
      var myobj=$('.offer-strip');
      myobj.remove();
      }
    }
  },[blackbeltcontent])
  
  useEffect(()=>{
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
        return false;
        }
      }
    });

    $('.scrolling-method[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 100
        }, 100);
        return false;
        }
      }
    });   
  },[]);

  // useEffect(()=>{
  //   setTimeout(() => {
  //     if(!$('#blackbeltLeadModal').hasClass('show')){
  //       updateLeadForm('roadmap','Your Data Science Blueprint',"Create My Roadmap","popup");
  //       window.$('#blackbeltLeadModal').modal('show')
  //     }
  //   }, 10000);
  // },[])

  useEffect(()=>{
    getCSRF()
    .then((data)=>{
      setCsrf(data);
      console.log(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  },[])

  const playVideo = ()=>{
    $('#modalVideo').trigger('play');
  }


  const saveLeadForm = (e)=>{
      e.preventDefault();
      let country_code = '+'+iti1.getSelectedCountryData().dialCode;     
      setCountryCode(country_code);
      if(!validatedMobile(phone)){
          $('.error').remove();
          console.log($('#bbNumber').closest('.col')[0])
          $('#bbNumber').closest('.col-12').append(`<small class="text-danger error text-start d-block">Enter a Valid Phone Number</small>`);
          $('#bbNumber').css('border-color','red');
          $('#bbNumber').css('color','red');
          setTimeout(()=>{
              $('#bbNumber').css('border-color','#383838');
              $('#bbNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },3000)
          return false;
      }
      if(!validatedEmail(email)){
        $('.error').remove();
        console.log($('#bbEmail').closest('.col')[0])
        $('#bbEmail').closest('.col-12').append(`<small class="text-danger error text-start d-block">Enter a Valid Email Id</small>`);
        $('#bbEmail').css('border-color','red');
        $('#bbEmail').css('color','red');
        setTimeout(()=>{
            $('#bbEmail').css('border-color','#383838');
            $('#bbEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
      let user_data = {
          "fullname":null,
          "email":email,
          "phone":phone,
          "country_code":country_code,
      }
      let additional_detail ={
        "tnc":true,
        "is_whatsapp":true,
        "section":"hero_fold",
        "page_url":window.location.href
      }
      sendEmail();
      submitRegisterationForm(user_data,additional_detail,'blackbelt',"free_trial",csrf)
      .then(()=>{
      })
      .catch((error)=>{
        console.log(error)
        setIsError(true);
        setTimeout(()=>{
          setIsError(false);
        },4000)
      })
  }

  const updateLeadForm = (formname,heading,cta,section)=>{
    setFormname(formname);
    setLeadFormHeading(heading);
    setLeadFormCta(cta);
    setSection(section)
  }

  let sendEmail = ()=>{
    sendOtp(email,csrf)
    .then((data)=>{
      setOtpRequestId(data.otp_request_id);
      window.$('#freetrialLeadModal').modal('show');
      setSection('first-fold');
    })
    .catch((error)=>{
      console.log(error);
    })
  }



  return (
    <>
      <FreeTrialHeader updateLeadForm={updateLeadForm} />
      <Title title={'Best Data Science Course Online: Comprehensive AI & ML Certifications program'} description={'Embark on a certified Data Science journey with our online training program. Acquire sought-after skills in Python, AI, and ML. Enroll today and chart your path to becoming a certified Data Scientist.'} image_og={cloudflare_size_url+'blackbelt/bb_og_image.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <FreeTrialTwoFieldLeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} csrf={csrf} email={email} phone={phone} otp_request_id={otprequestid} country_code={countryCode} setEmail={setEmail} setPhone={setPhone} setCsrf={setCsrf} />
      <BlackbeltVideoModal videoUrl={videoUrl} />
      {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}
      {success && <SuccessAlert message={successMessage} />}
      <section id='firstFold' className='bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative' style={{ backgroundImage:`url('https://imgcdn.analyticsvidhya.com/blackbelt/hero-image-bb.png')` }}>
        <div className='container justify-content-center align-items-center h-100'>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-lg-8 mx-auto text-center position-realtive pt-5'>
              <h1 className="fs-56 text-gradient mb-3 fw-semibold">
                <span className="text-white">Certified AI & ML</span> BlackBelt Plus 
                <span className='text-white ms-2'>Program</span>
              </h1>
              <ul className='text-white'>
                <li><i></i>Custom Learning for Your Career Goals</li>
                <li><i></i>Personalized 1:1  Mentorship from Industry Experts</li>
                <li><i></i>Focused Interview and Job Placement Assistance</li>
              </ul>
              <div className='row gx-4 text-ceter text-white mt-5 pb-5'>
                <div className='col-6 col-lg-3 col-xl-3'>
                  <h3 className='fs-20 fw-light fw-semibold'>4.6</h3>
                  <p>Top-Rated Mentorship</p>
                </div>
                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                  <h3 className='fs-20 fw-light fw-semibold'>500+ Hrs</h3>
                  <p>Comprehensive Learning</p>
                </div>
                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                  <h3 className='fs-20 fw-light fw-semibold'>50+</h3>
                  <p>Real-World Projects</p>
                </div>
                <div className='col-6 col-lg-3 col-md-6 col-xl-3'>
                  <h3 className='fs-20 fw-light fw-semibold'>100%</h3>
                  <p>Placement Assistance</p>
                </div>
              </div>
              <div className='enroll-number p-4 text-center text-white w-75 mx-auto rounded-3'>
                <h4 className='fs-24 fw-semibold mb-4'>Get personalised roadmap and Start Free Trial</h4>
                <form className="w-100" role="Phone">
                  <div className="row align-items-baseline">
                    <div className="col-12 col-md mb-3 mb-md-0">
                      <input className="form-control px-4 py-2 ps-5" type="tel" placeholder="Your Phone Number" aria-label="Phone No." min={0} value={phone} onChange={(e)=>{setPhone(e.target.value)}} id="bbNumber" />
                    </div>
                    <div className="col-12 col-md">
                      <input className="form-control px-4 py-2" type="email" placeholder="Your Email Id" value={email} onChange={(e)=>{setEmail(e.target.value)}} id="bbEmail" />
                    </div>
                  </div>
                 <div className='text-start mt-3'>
                  <div className="form-check form-check-inline mb-3">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked disabled />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox1">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>terms & conditions</a></label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox2" value="option2" defaultChecked />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox2">Send WhatsApp Updates</label>
                    </div>
                 </div>
                  <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={saveLeadForm}>Start Now!</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {blackbeltcontent && <section className='common-style' id='whyChoose'>
        <div className='shpe-left z-0'>
          <img src={leftshap} className="img-fluid" />
        </div>
        <div className='container z-2'>
          <div className='text-white mx-auto text-center col-lg-8 heading-with-text'>
            <h2 className='fs-40 fw-normal mb-3'>{blackbeltcontent && blackbeltcontent.whychooseblackbeltplus.heading}</h2>
          </div>
          <div className='card text-bg-dark p-5 border-0 rounded-3 mt-5'>
             <div className='row'>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                {blackbeltcontent && blackbeltcontent.whychooseblackbeltplus.headings.map((headingObj,index)=>{
                  return(
                    <div className='key-points' key={index}>
                      <h3 className='fs-24 fw-semibold mb-4'>{headingObj.text}</h3>
                      <ul className={`${(index == blackbeltcontent.whychooseblackbeltplus.headings.length -1)?'mb-4 mb-md-0':'mb-4'}`}>
                        {headingObj.points.map((point,index)=>{
                          return <li className='mb-4 fs-16 text-dark-secondary' key={index}>{point.text}</li>
                        })}
                      </ul>
                    </div>
                  )
                })}
              </div>
              <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 position-relative'>
                <div className='video-thumb position-relative mb-5'>
                  <img src={blackbeltcontent && cloudflare_size_url+blackbeltcontent.whychooseblackbeltplus.video.videoThumbnail.src+'?format=webp&width=643'} className="img-fluid rounded-2" />
                  <button className="btn play-btn position-absolute rounded-circle d-flex align-items-center justify-content-center z-3" data-bs-toggle="modal" data-bs-target="#kunalVideoModal" onClick={playVideo}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                      <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                    </svg>
                  </button>
                </div>
                <button className='btn btn-dark btn-dark-primary rounded-3 z-3' data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('enroll','Discover the Path to Innovation',"Enroll Now","feature_listing")}}>Start Free Trial</button>
              </div>
             </div>
          </div>
        </div>
      </section>}
      {blackbeltcontent && <section className='common-style background-dark-primary position-relative' id='roadMap'>
        <div className="container">
          <div className='text-white col-lg-8 mb-5 heading-with-text text-center mx-auto'>
            <h2 className='fs-40 fw-normal mb-3'>{blackbeltcontent && blackbeltcontent.personalisedRoadmap.heading}</h2>
            <p>{blackbeltcontent && blackbeltcontent.personalisedRoadmap.subheading}</p>
          </div>
          <ul className='roadmap-mobile'>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>1</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Start</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>2</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Develop ML Skills</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>3</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Develop Problem Solving Skills</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>4</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Work on Hackathons & Projects</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>5</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Develop Storytelling Skills</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>6</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Build AI & ML Portfolio</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>7</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Start Deep Learning Journey</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>8</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Learn Natural  Language Processing</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>9</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Learn Computer Vision</span>
              </li>
              <li className='text-start d-flex align-items-center'>
                <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>10</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                  <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                  <defs>
                    <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                      <stop stop-color="white"/>
                      <stop offset="1" stop-color="white" stop-opacity="0"/>
                    </linearGradient>
                  </defs>
                </svg>
                <span className='roadmap-text fs-14'>Master Apache Spark</span>
              </li>
          </ul>
        </div>
      <img src={cloudflare_size_url+(blackbeltcontent && blackbeltcontent.personalisedRoadmap.image.src+'?format=webp')} className="img-fluid mb-5" alt={blackbeltcontent && blackbeltcontent.personalisedRoadmap.image.alt} />
      <div className="container">
        <button className='btn btn-dark btn-dark-primary rounded-3 mx-auto d-table' id='roadmapBtn' data-bs-toggle="modal" data-bs-target="#blackbeltLeadModal" onClick={()=>{updateLeadForm('roadmap','Your Data Science Blueprint',"Create My Roadmap","roadmap")}}>Get Personalized Roadmap</button>
      </div>
      </section>}
      <Suspense fallback={<div>Loading</div>}>
        <BlackbeltFreeTrialSwiperComponent updateLeadForm={updateLeadForm} setVideoUrl={setVideoUrl} />
      </Suspense>
      <Cookies platform={'blackbeltFreeTrial'}  />
      <Footer platform={'blackbeltFreeTrial'} isWhatsApp={true}/>
      <Flashstrip />
      </>
  )
}


const mapStateToProps = (state) => ({
  blackbeltcontent:state.blackbeltContentReducer.blackbeltcontent,
  loading:state.blackbeltContentReducer.loading,
  error:state.blackbeltContentReducer.error,
  country_name:state.blackbeltContentReducer.country_code
});

const mapDispatchToProps = {
  fetchBlackbeltContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(FreeTrialBlackbelt);

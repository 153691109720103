import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';


const Speaker = () => {

    const [speakers, setSpeakers] = useState([]);

    useEffect(()=>{
        const query = `
        query MyQuery {
            speakers {
                edges {
                    node {
                        createdAt
                        modifiedAt
                        id
                        name
                    }
                }
            }
        }`

        getQueryDataElevate(query, "newevents", "True")
        .then((data)=>{
            setSpeakers(data?.data?.speakers);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    return (
        <>
            <Header />
            <section id="speakerList" className='common-style min-vh-100 background-dark-primary'>
                <div className="container">
                    <div className="row">
                        <div className='text-end p-0'>
                            <a href={'/av-admin/datahack-admin/speaker/create-speaker'} className='btn btn-dark btn-dark-primary px-5 py-2 my-3 text-uppercase fw-bold'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                               New Speaker
                            </a>
                        </div>
                        <table className='table table-striped text-center table-dark'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Created At</th>
                                    <th>Modified At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {speakers && speakers.edges && speakers.edges.map((val) => (
                                    <tr>
                                        <td><a href={'/av-admin/datahack-admin/speaker/'+val.node.id} className='text-white'>{val.node.id}</a></td>
                                        <td><a href={'/av-admin/datahack-admin/speaker/'+val.node.id} className='text-white'>{val.node.name}</a></td>
                                        <td>{val.node.createdAt}</td>
                                        <td>{val.node.modifiedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Speaker;
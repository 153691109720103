import React, { useEffect, useState } from 'react'

export default function Pagination({updatePageNumber,last_page}) {
    let [currentpage,setCurrentPage] = useState(1);
    let [showpage,setShowPage] = useState([1,2,3,4]);
    const showpagefixed = [1,2,3,4];
    let [lastPage,setLastPage] = useState(last_page);

    const [isHidden, setIsHidden] = useState(false);

    // Function to update visibility based on screen width
    const updateBoxVisibility = () => {
        const screenWidth = window.innerWidth;
        setIsHidden(screenWidth < 380);
    };

    useEffect(() => {
        updateBoxVisibility();

        window.addEventListener('resize', updateBoxVisibility);

        return () => {
        window.removeEventListener('resize', updateBoxVisibility);
        };
    }, []);

    useEffect(()=>{
        setLastPage(last_page);
        setCurrentPage(1);
    },[last_page])

    
    useEffect(()=>{
        setShowPage((currPages)=>{
            return showpagefixed.filter((currPage) => {
                return (currPage <= lastPage);
            })
        })
    // eslint-disable-next-line
    },[lastPage])

    let updateCurrentPage = (pagenumber)=>{
        setCurrentPage(pagenumber);
        updatePageNumber(pagenumber);
    }

    let goToPreviousPage = ()=>{
        let update_page_number = currentpage - 1;
        if(update_page_number <= 0){
            return false;
        }
        if(!showpage.includes(update_page_number)){
            let newshowpage = showpage.map((page)=>{
                return page - 1;
            })
            setShowPage(newshowpage);
        }
        updateCurrentPage(update_page_number);

    }

    let goToNextPage = ()=>{
        let update_page_number = currentpage + 1;
        if(update_page_number > lastPage){
            return false;
        }
        if(!showpage.includes(update_page_number)){
            let newshowpage = showpage.map((page)=>{
                return page + 1;
            })
            setShowPage(newshowpage);
        }
        updateCurrentPage(update_page_number);

    }

  return (
    <>
        {lastPage > 1 && <nav aria-label="Page navigation example">
            <ul class="pagination justify-content-center m-0">
                <li class="page-item cursor-pointer">
                    <a class="page-link" aria-label="Previous" onClick={goToPreviousPage}>
                        <span aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M12.6693 7.99992H3.33594M3.33594 7.99992L8.0026 12.6666M3.33594 7.99992L8.0026 3.33325" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </a>
                </li>
                <li class="page-item cursor-pointer"><a class={`page-link ${currentpage === showpage[0] && 'active'}`} onClick={()=>{updateCurrentPage(showpage[0])}}>{showpage[0]}</a></li>
                <li class="page-item cursor-pointer"><a class={`page-link ${currentpage === showpage[1] && 'active'}`} onClick={()=>{updateCurrentPage(showpage[1])}}>{showpage[1]}</a></li>
                {lastPage>2 && <li class="page-item cursor-pointer"><a class={`page-link ${currentpage === showpage[2] && 'active'}`} onClick={()=>{updateCurrentPage(showpage[2])}}>{showpage[2]}</a></li>}
                {lastPage>3 && <li class="page-item cursor-pointer"><a class={`page-link ${currentpage === showpage[3] && 'active'}`} onClick={()=>{updateCurrentPage(showpage[3])}}>{showpage[3]}</a></li>}
                {lastPage>4 && currentpage!==lastPage && <li class={`page-item ${isHidden ? 'd-none' : ''}`}><a class="page-link">...</a></li>}
                <li class="page-item">
                    <a class="page-link cursor-pointer" aria-label="Next" onClick={goToNextPage}>
                        <span aria-hidden="true">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M3.33073 8.00008H12.6641M12.6641 8.00008L7.9974 3.33341M12.6641 8.00008L7.9974 12.6667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </span>
                    </a>
                </li>
            </ul>
        </nav>}
    </>
  )
}

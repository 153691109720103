import React, { useEffect, useState } from "react";
import { getCSRF, getSession } from "../../../../utils/Login";
import { connect } from "react-redux";
import Header from "../../../common/Header/Header";
import { scrollTop } from "../../../../utils";
import ErrorAlert from "../../../common/Alert/ErrorAlert";
import SuccessAlert from "../../../common/Alert/SuccessAlert";

const CreateAttendee = ({islogin,csrf}) => {

    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [company, setCompany] = useState("");
    const [description, setDescription] = useState("");
    const [email, setEmail] = useState("");
    const [photo, setPhoto] = useState(null);
    const [logo, setLogo] = useState(null);
    const [linkedin, setLinkedin] = useState("");

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    const resetFormFields = () => {
        setName("");
        setDesignation("");
        setCompany("");
        setDescription("");
        setEmail("");
        setPhoto(null);
        setLogo(null);
        setLinkedin("");
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        var formdata = new FormData();

        const mutationString = `mutation MyMutation($company: String!, $designation: String!, $email: String!, $linkedin: String!, $name:String!, $photo: Upload!, $logo:Upload!) { 
            addAttendee( company: $company, designation: $designation, email: $email, linkedin: $linkedin, name:$name, description:"""${description}""", photo:$photo, logo:$logo ) { 
                attendee { 
                    id 
                    name
                } 
            } 
        }`

        const variables = {
            company: company,
            designation: designation,
            email: email,
            linkedin: linkedin,
            name: name,
            ...(photo && { photo: null }),
            ...(logo && { logo: null })
        }

        if (photo) {
            formdata.append("0", photo);
            variables.photo = null; 
        }

        if (logo) {
            formdata.append("1", logo);
            variables.logo = null; 
        }

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (photo) map["0"] = ["variables.photo"];
        if (logo) map["1"] = ["variables.logo"];
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }

        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((result)=> {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                    window.location.reload();
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });
        return false;
    }

    useEffect(()=>{
        scrollTop();
    },[])

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Attendee added successfully"} />}
            <section id='createAttendee' className="min-vh-100 background-dark-primary common-style">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Attendee Form</h3>

                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Name <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Name' 
                                            value={name}
                                            onChange={(event)=>setName(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Designation <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Designation' 
                                            value={designation}
                                            onChange={(event)=>setDesignation(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Company <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Company' 
                                            value={company}
                                            onChange={(event)=>setCompany(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description <sup className='text-danger'>*</sup></label>
                                            <textarea 
                                            className='form-control' 
                                            value={description}
                                            onChange={(event)=>setDescription(event.target.value)}
                                            style={{ height: '100px' }}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Email <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="email" 
                                            className='form-control' 
                                            placeholder='Email' 
                                            value={email}
                                            onChange={(event)=>setEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Photo: <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="file"
                                                name="photo"
                                                onChange={(e) => setPhoto(e.target.files[0])}
                                                required
                                                className="text-white"
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Logo: <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="file"
                                                name="logo"
                                                onChange={(e) => setLogo(e.target.files[0])}
                                                required
                                                className="text-white"
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Linkedin</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Linkedin' 
                                            value={linkedin}
                                            onChange={(event)=>setLinkedin(event.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Attendee
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CreateAttendee);
import React, { useEffect, useState } from 'react';
import { getCSRF, getSession, openGoogleLoginPage, sendOtp, verifyOtp } from '../../../utils/Login';
import { connect } from 'react-redux';
import { clearCSRF, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import { validatedEmail } from '../../../utils/InputValidation';
import $ from 'jquery';

let interval;

function LoginModal({welcomeModal,updateWelcomeLoginModalHandler,emailModal,otpModal,redirect_url,callbackFunction,csrfRedux}) {
  const [email, setEmail] = useState('');
  const [csrf, setCsrf] = useState('');
  const [otprequestid,setOtpRequestId] = useState('');
  const [otp,setOtp] = useState({
    digit1:'',
    digit2:'',
    digit3:'',
    digit4:'',
    digit5:'',
    digit6:''
  });
  const [resendOtpTimer,setResendOtpTimer] = useState(30);
  const [isresend,setIsResend] = useState(false);

  useEffect(()=>{
    if(!csrfRedux) {
      getSession()
      .then((data)=>{
        if (data.isAuthenticated) {
          updateWelcomeLoginModalHandler({islogin:true,userData:data.user_data})
        }
        else{
          updateWelcomeLoginModalHandler({islogin:false});
        }
        updateCsrf();
        getCSRF()
        .then((data)=>{
          setCsrf(data);
        })
        .catch((error)=>{
          console.log(error);
        })
        if(data.user_data && data.user_data.email){
          let dataLayer = setInterval(() => {
            if(window.dataLayer){
              clearInterval(dataLayer);
              window.dataLayer.push({'event': `Login`,'email':data.user_data.email});
            }
          }, 3000);
        }
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  },[]);

  useEffect(()=>{
    clearInterval(interval);
    interval = setInterval(() => {
      if(otpModal){
        setResendOtpTimer((prev)=>{
          if(prev === 0){
            clearInterval(interval);
            setIsResend(true);
            return 30;
          }
          return prev-1;
        });
      }
    },1000);
  },[otpModal])

  let sendEmail = ()=>{
    if(!validatedEmail(email)){
      $('.warning-danger').remove();
      $('#emailLogin').parent().append('<p class="warning-danger w-100 mt-2 fs-13">Enter a valid email id</p>');
      setTimeout(() => {
        $('.warning-danger').slideUp(500,'linear',()=>{
          $('.warning-danger').remove();
        });
      }, 2000);
      return false;
    }
    sendOtp(email,csrf)
    .then((data)=>{
      setOtpRequestId(data.otp_request_id);
      updateWelcomeLoginModalHandler({emailModal:false,otpModal:true});
      clearInterval(interval);
      interval = setInterval(() => {
        if(otpModal){
          setResendOtpTimer((prev)=>{
            if(prev === 0){
              clearInterval(interval);
              setIsResend(true);
              return 30;
            }
            return prev-1;
          });
        }
      },1000);
    setIsResend(false);
    })
    .catch((error)=>{
      console.log(error);
    })
  }

  let verifyEmailOtp = ()=>{
    if(otp.digit1 === '' && otp.digit2 === '' && otp.digit3 === '' && otp.digit4 === '' && otp.digit5 === '' && otp.digit6 === ''){
      return false;
    }
    let otp_string = `${otp.digit1}${otp.digit2}${otp.digit3}${otp.digit4}${otp.digit5}${otp.digit6}`;
    verifyOtp(email,otp_string,otprequestid,csrf,null,redirect_url)
    .then((data)=>{
      console.log(data);
      if(data.is_verified){
        if(redirect_url){
          window.location.href = redirect_url;
        }
        else if(callbackFunction){
          updateWelcomeLoginModalHandler({otpModal:false});
          callbackFunction();
        }
        else{
          updateWelcomeLoginModalHandler({otpModal:false});
        }
        updateCsrf();
        updateWelcomeLoginModalHandler({islogin:true,userData:data.user_data})
        setEmail('');
        setOtp({
          digit1:'',
          digit2:'',
          digit3:'',
          digit4:'',
          digit5:'',
          digit6:''
        });
        if(data.user_data && data.user_data.email){
          window.dataLayer.push({'event': `Login`,'email':data.user_data.email});
        }
      }
      else{
        $('.warning').remove();
        $('#otp').parent().parent().append(`<p class="warning text-danger text-center w-100 mt-2 fs-13">${data.response}</p>`);
        setTimeout(() => {
          $('.warning').slideUp(1500,'linear',()=>{
            $('.warning').remove();
          });
        }, 3000);
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  let updateCsrf = ()=>{
    getCSRF()
      .then((data)=>{
        updateWelcomeLoginModalHandler({csrf:data});
      })
      .catch((error)=>{
        console.log(error);
      })
  }

  // Otp input script
  useEffect(()=>{
    const inputs = document.querySelectorAll('#otp > *[id]');
    for (let i = 0; i < inputs.length; i++) { 
      inputs[i].addEventListener('keydown', function (event) {
          if (event.key === "Backspace") {
            inputs[i].value = ''; 
            if (i !== 0) inputs[i - 1].focus(); 
          } 
          else {
            if (i === inputs.length - 1 && inputs[i].value !== '') {
              return true; 
            } 
            else if (event.keyCode > 47 && event.keyCode < 58) {
              inputs[i].value = event.key; 
              if (i !== inputs.length - 1) inputs[i + 1].focus(); 
              event.preventDefault(); 
            } 
            else if (event.keyCode > 64 && event.keyCode < 91) {
              // inputs[i].value = String.fromCharCode(event.keyCode);
              // if (i !== inputs.length - 1) inputs[i + 1].focus();
              // event.preventDefault(); 
              return true;
            } 
          } 
        });
      }
  },[])

  let resendOtp = ()=>{
    setOtp({
      digit1:'',
      digit2:'',
      digit3:'',
      digit4:'',
      digit5:'',
      digit6:''
    });
    sendEmail();
    $('.warning').remove();
        $('#otp').parent().parent().append(`<p class="warning text-success text-center w-100 mt-2 fs-13">OTP has been sent on ${email}</p>`);
        setTimeout(() => {
          $('.warning').slideUp(1500,'linear',()=>{
            $('.warning').remove();
          });
    }, 3000);
    clearInterval(interval);
    interval = setInterval(() => {
      if(otpModal){
        setResendOtpTimer((prev)=>{
          if(prev === 0){
            clearInterval(interval);
            setIsResend(true);
            return 30;
          }
          return prev-1;
        });
      }
    },1000);
    setIsResend(false);
  }

  useEffect(()=>{
    if(otp.digit1 === '' || otp.digit2 === '' || otp.digit3 === '' || otp.digit4 === '' || otp.digit5 === '' || otp.digit6 === ''){
      $('#otpSubmitBtn').attr('disabled','disabled');
      $('#otpSubmitBtn').removeClass('hover');
    }
    else{
      $('#otpSubmitBtn').removeAttr('disabled');
    }
  },[otp])

  const copyPaste = (event)=>{
    let paste = (event.clipboardData || window.clipboardData).getData("text");
    if (/^\d{6}$/.test(paste)) {
      setOtp({
        digit1: paste[0],
        digit2: paste[1],
        digit3: paste[2],
        digit4: paste[3],
        digit5: paste[4],
        digit6: paste[5],
      });
      $('#otpSubmitBtn').addClass('hover');
    }
    event.preventDefault();
  }

  return (
    <>
      <div className={`modal login-modal ${welcomeModal ? 'fade show':'' } shadow`} id='welcomeModal' tabIndex="-1" style={welcomeModal ? {display:'block'}:{display:'none'}}>
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4 p-4">
            <div className="modal-header border-0 p-0">
              <img src={'https://imgcdn.analyticsvidhya.com/AV_logo.png'}  width={'114'} height={'33'} alt="Av Logo White" loading="lazy" />
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:false})}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-5">
              <h2 className='fs-20 text-white mb-4'>Login now to continue</h2>
              <button className="btn btn-dark btn-dark-primary mb-4 rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center" onClick={()=>{openGoogleLoginPage(redirect_url)}} >
                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M19.8023 10.2297C19.8023 9.54995 19.7472 8.8665 19.6296 8.19775H10.1973V12.0486H15.5987C15.3746 13.2905 14.6544 14.3892 13.5998 15.0873V17.586H16.8223C18.7146 15.8443 19.8023 13.2722 19.8023 10.2297Z" fill="#4285F4"/>
                  <path d="M10.198 20C12.895 20 15.1695 19.1145 16.8267 17.5859L13.6042 15.0873C12.7076 15.6973 11.5502 16.0427 10.2016 16.0427C7.59279 16.0427 5.38077 14.2826 4.58709 11.9163H1.26172V14.492C2.95931 17.8689 6.41697 20 10.198 20Z" fill="#34A853"/>
                  <path d="M4.5832 11.9168C4.16432 10.6748 4.16432 9.32995 4.5832 8.08799V5.51221H1.26151C-0.15683 8.33785 -0.15683 11.6669 1.26151 14.4925L4.5832 11.9168Z" fill="#FBBC04"/>
                  <path d="M10.198 3.95781C11.6237 3.93576 13.0016 4.47223 14.0341 5.45698L16.8891 2.60194C15.0813 0.904343 12.6819 -0.0289658 10.198 0.000429748C6.41696 0.000429748 2.95931 2.13161 1.26172 5.5121L4.58342 8.08788C5.37342 5.71786 7.58911 3.95781 10.198 3.95781Z" fill="#EA4335"/>
                </svg>                        
                Continue with Google
              </button>
              <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center mb-4" onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:false,emailModal:true})}} >
                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5.8335 7.5L10.0002 10.4167L14.1668 7.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M1.6665 14.1667V5.83341C1.6665 5.39139 1.8421 4.96746 2.15466 4.6549C2.46722 4.34234 2.89114 4.16675 3.33317 4.16675H16.6665C17.1085 4.16675 17.5325 4.34234 17.845 4.6549C18.1576 4.96746 18.3332 5.39139 18.3332 5.83341V14.1667C18.3332 14.6088 18.1576 15.0327 17.845 15.3453C17.5325 15.6578 17.1085 15.8334 16.6665 15.8334H3.33317C2.89114 15.8334 2.46722 15.6578 2.15466 15.3453C1.8421 15.0327 1.6665 14.6088 1.6665 14.1667Z" stroke="white" strokeWidth="2"/>
                </svg>                      
                Continue with Email
              </button>
              <a href='https://id.analyticsvidhya.com/auth/password/reset/?utm_source=newhomepage' target='_blank' className='text-white fs-16 fw-light d-none'>Forgot your password?</a>
            </div>
            <div className="modal-footer border-0 p-0 justify-content-start">
              <div className="form-check d-flex align-items-center mb-2">
                <input className="form-check-input rounded-1 me-2" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                <label className="form-check-label text-white fs-16 opacity-100" htmlFor="termAndConditionLoginModal">I accept the <a href='/terms' target='_blank' className='text-white fw-semibold'>Term & Conditions</a></label>
              </div>
              <div className="form-check d-flex align-items-center mb-2">
                <input className="form-check-input rounded-1 me-2" type="checkbox" value="" id="privacyPolicyLoginModal" defaultChecked disabled />
                <label className="form-check-label text-white fs-16 opacity-100" htmlFor="privacyPolicyLoginModal">I have read the <a href='/privacy-policy' target='_blank' className='text-white fw-semibold'>Privacy Policy</a></label>
              </div>
              <div className="form-check d-flex align-items-center">
                <input className="form-check-input rounded-1 me-2" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                <label className="form-check-label text-white fs-16 opacity-100" htmlFor="isWhatsappLoginModal">Receive updates on WhatsApp</label>
              </div>
            </div>
        </div>
        </div>
      </div>
      <div className={`modal login-modal ${emailModal ? 'fade show':'' } shadow`} id='emailModal' tabIndex="-1" style={emailModal ? {display:'block'}:{display:'none'}}>
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4 p-4">
            <div className="modal-header border-0 p-0">
              <img src={'https://imgcdn.analyticsvidhya.com/AV_logo.png'} width={'114'} height={'33'} alt="Av Logo White" loading="lazy" />
            </div>
            <div className="modal-body p-0 pt-5">
              <div className="d-flex">
                <svg onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true,emailModal:false})}} className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h2 className='fs-20 text-white mb-4'>Enter email address to continue</h2>
              </div>
              <div className="mb-3">
                <label htmlFor="emailLogin" className="form-label text-white fs-14">Email address</label>
                <input type="email" className="form-control background-dark-tertiary" id="emailLogin" placeholder="Enter your email address" value={email} onChange={(e)=>{setEmail(e.target.value)}} autoComplete='off' />
              </div>
            </div>
            <div className="modal-footer border-0 p-0 justify-content-start">
              <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center mb-4" onClick={sendEmail} >                 
                Get OTP
              </button>
            </div>
        </div>
        </div>
      </div>
      <div className={`modal login-modal ${otpModal ? 'fade show':'' } shadow`} id='otpModal' tabIndex="-1" style={otpModal ? {display:'block'}:{display:'none'}}>
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4 p-4">
            <div className="modal-header border-0 p-0">
              <img src={'https://imgcdn.analyticsvidhya.com/AV_logo.png'}  width={'114'} height={'33'} alt="Av Logo White" loading="lazy" />
            </div>
            <div className="modal-body p-0 pt-5">
              <div className="d-flex">
                <svg onClick={()=>{updateWelcomeLoginModalHandler({emailModal:true,otpModal:false})}} className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <div className="header">
                  <h2 className='fs-20 text-white'>Enter OTP sent to</h2>
                  <p className='text-white'>{email} <span className='text-white ms-2 text-decoration-underline pointer' onClick={()=>{updateWelcomeLoginModalHandler({emailModal:true,otpModal:false})}}>Edit</span></p>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="otpLogin" className="form-label text-white fs-14 d-none">Enter the OTP</label>
                <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2"> 
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="first" maxLength="1" value={otp.digit1} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit1:e.target.value}})}} autoComplete='off'/> 
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="second" maxLength="1" value={otp.digit2} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit2:e.target.value}})}} autoComplete='off'/>
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="third" maxLength="1" value={otp.digit3} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit3:e.target.value}})}} autoComplete='off'/> 
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="fourth" maxLength="1" value={otp.digit4} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit4:e.target.value}})}} autoComplete='off'/> 
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="fifth" maxLength="1" value={otp.digit5} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit5:e.target.value}})}} autoComplete='off'/> 
                  <input className="m-2 text-center form-control rounded background-dark-tertiary otp-input" type="tel" placeholder='-' id="sixth" maxLength="1" value={otp.digit6} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit6:e.target.value}})}} autoComplete='off'/> 
                </div>
              </div>
              {isresend ? <p className='blue pointer' onClick={resendOtp}>Resend OTP</p>:<p className='text-dark-tertiary'>Resend OTP in <span className='blue'>{resendOtpTimer}s</span></p>}   
            </div>
            <div className="modal-footer border-0 p-0 justify-content-start">
              <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center mb-4" id='otpSubmitBtn' onClick={verifyEmailOtp} >                 
                Verify OTP
              </button>
            </div>
        </div>
        </div>
      </div>
      <div className={`modal-backdrop ${welcomeModal || emailModal || otpModal ? 'show':'fade'}`}  style={welcomeModal || emailModal || otpModal  ? {display:'block'}:{display:'none'}}></div>
    </>
  )
}




const mapStateToProps = (state) => ({
  welcomeModal:state.LoginModalReducer.welcomeloginmodal,
  emailModal:state.LoginModalReducer.emailModal,
  otpModal:state.LoginModalReducer.otpModal,
  csrfRedux:state.LoginModalReducer.csrf
});

const mapDispatchToProps = dispatch=>({
  updateWelcomeLoginModalHandler:data=>{dispatch(updateWelcomeLoginModal(data))},
  clearCSRFhandler: ()=>{dispatch(clearCSRF())}
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

import React from 'react'
import GenAiPinnacleForm from './GenAiPinnacleForm';
import BlackbeltForm from './BlackbeltForm';
import HomepageForm from './HomepageForm';
import AgenticAiForm from './AgenticAiForm';

export default function DynamicFormFields({ selectedPlatform }) {
  if (selectedPlatform === 'genaipinnacle') {
    return (
      <GenAiPinnacleForm platform={selectedPlatform}/>
    );
  } else if (selectedPlatform === 'blackbelt') {
    return (
      <BlackbeltForm platform={selectedPlatform} />
    );
  } else if (selectedPlatform === 'homepage') {
    return (
      <HomepageForm platform={selectedPlatform} />
    );
  } else if (selectedPlatform === 'agenticai') {
    return (
      <AgenticAiForm platform={selectedPlatform} />
    );
  } else {
    return null;
  }
}

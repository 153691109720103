import React, { useEffect, useState } from "react";
import { getCSRF, getSession } from "../../../../utils/Login";
import Header from "../../../common/Header/Header";
import { connect } from "react-redux";
import { validateLinkedInUrl } from "../../../../utils/InputValidation";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import { getQueryDataElevateUser, trimNewlines } from "../../../../utils";

const CreateSpeaker = ({ csrf }) => {

    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyLogo, setCompanyLogo] = useState(null);
    const [displayPicture, setDisplayPicture] = useState(null);
    const [isFeaturedSpeaker, setIsFeaturedSpeaker] = useState(false);
    const [speakerDetail, setSpeakerDetail] = useState("");
    const [linkedin, setLinkedin] = useState("");

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);

    const resetFormFields = () => {
        setName("");
        setDesignation("");
        setCompanyName("");
        setIsFeaturedSpeaker(false);
        setSpeakerDetail("");
        setLinkedin("");
        setDisplayPicture(null);
        setCompanyLogo(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateLinkedInUrl(linkedin)) {
            alert("LinkedIn URL is not valid.");
            return false;
        }
        const htmlSpeakerDetail = trimNewlines(speakerDetail);
        var formdata = new FormData();

        if(!companyLogo && !displayPicture){
            let query = `mutation MyMutation {
                createSpeaker(
                    name: "${name}"
                    designation: "${designation}"
                    companyName: "${companyName}"
                    linkedin: "${linkedin}"
                    isFeaturedSpeaker: ${isFeaturedSpeaker}
                    speakerDetail: "${htmlSpeakerDetail}"
                ) {
                    speaker { 
                        name
                        companyName 
                    }
                }
            }`;
            console.log(query);
            getQueryDataElevateUser(query,"newevents","True",csrf)
            .then((data)=>{
                if(!data.errors){
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else{
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => console.log('error', error));
            return false;
        }

        let mutationString=`mutation MyMutation($name: String!, $designation: String!, $companyName: String!, $companyLogo: Upload, $displayPicture: Upload!, $linkedin: String!, $speakerDetail: String!) { createSpeaker( name: $name, designation: $designation, companyName: $companyName, speakerDetail: $speakerDetail,isFeaturedSpeaker: ${isFeaturedSpeaker}, linkedin: $linkedin, index: 1, companyLogo: $companyLogo, displayPicture: $displayPicture ) { speaker { name, companyName }}}`;

        let variables = {
            name: name,
            designation: designation,
            companyName: companyName,
            linkedin: linkedin,
            speakerDetail: htmlSpeakerDetail,
            ...(companyLogo && {companyLogo :null}),
            ...(displayPicture && {displayPicture :null}),
        }

        if (companyLogo) {
            formdata.append("0", companyLogo);
            variables.companyLogo = null; 
        }

        if (displayPicture) {
            formdata.append("1", displayPicture);
            variables.displayPicture = null; 
        } 

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (companyLogo) map["0"] = ["variables.companyLogo"];
        if (displayPicture) map["1"] = ["variables.displayPicture"];
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }
    
        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };

        console.log("Formdata", formdata);
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result) => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => console.log('error', error));
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Network response was not ok"} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Speaker created successfully"} />}
            <section id='createSpeaker' className="common-style background-dark-primary min-vh-100">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white text-uppercase fw-semibold">Speaker Form</h3>

                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Name <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Name'
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Designation <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Designation'
                                                value={designation}
                                                onChange={(event) => setDesignation(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Company Name <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Company Name'
                                                value={companyName}
                                                onChange={(event) => setCompanyName(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Featured Speaker:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isFeaturedSpeaker}
                                                onChange={(event) => setIsFeaturedSpeaker(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Linkedin <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Linkedin'
                                                value={linkedin}
                                                onChange={(event) => setLinkedin(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Speaker Detail <sup className='text-danger'>*</sup></label>
                                            <textarea
                                                className='form-control'
                                                value={speakerDetail}
                                                onChange={(event) => setSpeakerDetail(event.target.value)}
                                                style={{ height: '100px' }}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Display Picture: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="displayPicture"
                                                onChange={(e) => setDisplayPicture(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Company Logo: </label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="companyLogo"
                                                onChange={(e) => setCompanyLogo(e.target.files[0])}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Speaker
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSpeaker);

import { FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS, FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS,FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS,FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS, FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS, CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    leadingwithdataList:null,
    // lastweekData:null,
    // lastMonthData:null,
    // last3monthsData:null,
    // last6monthsData:null,
    leadingwithdataByPageNumber:null,
    leadingWithDatadetails:null,
    leadingwithdataSpeakerSession:null
};
  
export const leadingWithDataContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS:
            return {
                ...state,
                leadingwithdataList: action.payload.allVideos,
                // lastweekData: action.payload.lastWeekData,
                // lastMonthData: action.payload.lastMonthData,
                // last3monthsData: action.payload.last3monthsData,
                // last6monthsData: action.payload.last6monthsData,
            };
        // case FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS:
        //     return {
        //         ...state,
        //         lastweekData: action.payload.allVideos,
        //     };
        // case FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS:
        //     return {
        //         ...state,
        //         lastMonthData: action.payload.allVideos,
        //     };
        // case FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS:
        //     return {
        //         ...state,
        //         last3monthsData: action.payload.allVideos,
        //     };
        // case FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS:
        //     return {
        //         ...state,
        //         last6monthsData: action.payload.allVideos,
        //     };
        case FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                leadingwithdataByPageNumber: action.payload,
            };
        case FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS:
            return {
                ...state,
                leadingWithDatadetails: action.payload,
            };
        case CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS:
            return {
                ...state,
                leadingWithDatadetails: null,
            };
        case FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                leadingwithdataSpeakerSession:action.payload
            };
        default:
            return state;
    }
};
  
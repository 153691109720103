import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import $ from 'jquery';
import { getSession } from '../../../../utils/Login';

export default function Search() {
  const [value,setValue] = useState('');
  const [result,setResult] = useState(null);

  useEffect(()=>{
    let search_timeout;
      
    if(value === ""){
      $('.result ').slideUp();
    }
    else{
      search_timeout = setTimeout(() => {
        fetchSearchResult();
      }, 300);
    }

    return () => clearTimeout(search_timeout);
  },[value]);

  const fetchSearchResult = ()=>{
    let data = {
      'indexes' : 'contest blog course',
      'size' : 4,
      'search_text' : value
    }

    const formData = new FormData();
    formData.append('url', 'http://web.newsearch/');
    formData.append('platform', 'newsearch');
    formData.append('payload', JSON.stringify(data));
    fetch(process.env.REACT_APP_BLOG_DATA, {
      method: 'POST',
      body: formData,
      credentials: process.env.REACT_APP_CREDENTIAL_TYPE
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      setResult(Object.entries(data.response.response));
      $('.result').slideDown();
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const submitForm = (e)=>{
    e.preventDefault();
    return false;
  }


  return (
    <>
    <Header />
    <section id="search">
      <div className="container h-100">
        <div className="row justify-content-center">
          <form action='' onSubmit={submitForm} className='d-flex mt-5'>
            <div className="col">
              <input className='form-control background-dark-tertiary px-3 py-2 h-100' type="text" placeholder="Enter Keywords?" value={value} onChange={(e)=>{setValue(e.target.value)}} />
            </div>
          </form>
          <div className="result mt-5 rounded-2 shadow-1">
            {result && result.map(([key, value])=>{
              return(
                <>
                  <h5 className='text-white pt-3 ps-4'>{key}</h5>
                  <ul>
                    {value.length == 0 ?<p className='text-secondary text-center py-4'>No Data Found</p>:value.map((obj,index)=>{
                      return(<li className='list-style-none py-3' key={index}><a className='text-white' href={obj.url} target='_blank'>{obj.name}</a></li>)
                    })}
                  </ul>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

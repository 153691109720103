import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagenumber from '../../common/Page/Pagination';
import { getCommentData, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataElevateUser } from '../../../utils';
import $ from 'jquery';
import Reply from './Reply';


const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

function Comment({uniqueId,islogin,updateWelcomeLoginModalHandler,csrf,getCommentDataHandler,commentData,lastpage,resourceName,isUserRegistered}) {
    const [newComment, setNewComment] = useState("");

    useEffect(()=>{
        if(csrf != ""){
        let data = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
        }
        getCommentDataHandler(data);
        }
    },[islogin,csrf]);

    const createNewComment = (e) => {
        e.preventDefault();

        if(!islogin) {
                updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
            }

        if(newComment === ""){
        $('.comment-warning').remove();
        $('#commentTextarea').css("border-color","red");
        $('#commentTextarea').parent().append(`<small class="comment-warning col-12 text-danger">Comment shouldn't be empty</small>`)
        setTimeout(() => {
            $('#commentTextarea').css("border-color","gray");
            $('.comment-warning').slideUp();
            $('.comment-warning').remove();
        }, 2000);
        return false;
        }

        let mutation = `
        mutation {
            createComment(commentText: """${newComment}""", resourceId: "${uniqueId}",resourceName:"${resourceName}") {
            comment {
                userId
                commentMentions
                commentText
                createdAt
                id
                isDeleted
                isEdited
                likedBy
                resourceId
                updatedAt
                createdAt
            }
            }
        }
        `;

        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            setNewComment("");
            let tempdata = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
            }
            getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const likeCommentHandler = (comment_id) => {
        if(!islogin) {
        updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
        }
        let mutation = `
        mutation{
            likeComment(commentId: "${comment_id}") {
                comment {
                userId
                }
            }
        }
        `;
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
        let tempdata = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
        }
        getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    const updateCommentByPageNumber = (page_number)=>{
        if(csrf != ""){
        let data = {
            uniqueId:uniqueId,
            page:page_number,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
        }
        getCommentDataHandler(data);
        }
    }

    const likeReplyHandler = (reply_id) => {

        if(!islogin) {
        updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
        }
        let mutation = `
        mutation{
            likeReply(replyId: "${reply_id}") {
                reply {
                userId
                }
            }
        }
        `
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
        let tempdata = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
        }
        getCommentDataHandler(tempdata);
        })
        .catch((error)=>{
            console.log(error);
    })
    };

  return (
    <div className="comment-section position-relative">
        {!islogin && <div className="not-login-blur position-absolute d-flex align-items-center justify-content-center">
        <div className="blur"></div>
        <p className='text-white mb-0 background-dark-tertiary p-4 w-auto rounded-2 cursor-pointer' onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}}>
        <svg className='background-dark-primary me-3 p-1 rounded-circle' xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path d="M11.1641 12V9.33333C11.1641 6.38781 13.5519 4 16.4974 4C19.4429 4 21.8307 6.38781 21.8307 9.33333V12M11.4307 28H21.5641C23.0575 28 23.8043 28 24.3747 27.7094C24.8765 27.4537 25.2844 27.0457 25.5401 26.544C25.8307 25.9735 25.8307 25.2268 25.8307 23.7333V16.2667C25.8307 14.7732 25.8307 14.0265 25.5401 13.456C25.2844 12.9543 24.8765 12.5463 24.3747 12.2906C23.8043 12 23.0575 12 21.5641 12H11.4307C9.93726 12 9.19052 12 8.62009 12.2906C8.11832 12.5463 7.71037 12.9543 7.45471 13.456C7.16406 14.0265 7.16406 14.7732 7.16406 16.2667V23.7333C7.16406 25.2268 7.16406 25.9735 7.45471 26.544C7.71037 27.0457 8.11832 27.4537 8.62009 27.7094C9.19052 28 9.93726 28 11.4307 28Z" stroke="white" stroke-width="2.55" stroke-linecap="round"/>
        </svg>
            Please login/signup to participate in the discussion</p>
        </div>}
        {islogin && isUserRegistered == false && <div className="not-login-blur position-absolute d-flex align-items-center justify-content-center">
        <div className="blur"></div>
        <p className='text-white mb-0 background-dark-tertiary p-4 w-auto rounded-2'>
        <svg className='background-dark-primary me-3 p-1 rounded-circle' xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
            <path d="M11.1641 12V9.33333C11.1641 6.38781 13.5519 4 16.4974 4C19.4429 4 21.8307 6.38781 21.8307 9.33333V12M11.4307 28H21.5641C23.0575 28 23.8043 28 24.3747 27.7094C24.8765 27.4537 25.2844 27.0457 25.5401 26.544C25.8307 25.9735 25.8307 25.2268 25.8307 23.7333V16.2667C25.8307 14.7732 25.8307 14.0265 25.5401 13.456C25.2844 12.9543 24.8765 12.5463 24.3747 12.2906C23.8043 12 23.0575 12 21.5641 12H11.4307C9.93726 12 9.19052 12 8.62009 12.2906C8.11832 12.5463 7.71037 12.9543 7.45471 13.456C7.16406 14.0265 7.16406 14.7732 7.16406 16.2667V23.7333C7.16406 25.2268 7.16406 25.9735 7.45471 26.544C7.71037 27.0457 8.11832 27.4537 8.62009 27.7094C9.19052 28 9.93726 28 11.4307 28Z" stroke="white" stroke-width="2.55" stroke-linecap="round"/>
        </svg>
            Please register to participate in the discussion</p>
        </div>}
        <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3">
        <textarea class="form-control background-transparent mb-3" id="commentTextarea" placeholder='What are your thoughts?...' value={newComment} onChange={(e)=>{setNewComment(e.target.value)}}></textarea>
        <div className="btn-group-comment d-flex flex-wrap justify-content-end">
            <button className="btn text-white border-0 text-decoration-underline" onClick={()=>{setNewComment("")}}>Clear</button>
            <button className="btn btn-dark btn-dark-primary ms-3" onClick={createNewComment}>Submit Reply</button>
        </div>
        </div>

        {commentData && commentData.length > 0 ?
        <div className="card background-dark-primary border-dark mb-3 overflow-auto">
            <div className="card-body">
                {commentData && commentData.map((comment,index)=>{
                return(
                    <div className="comment-card py-3" key={index}>
                    <div className="row">
                        <div className="col-auto">
                        {/* <div className="avtar px-0 overflow-hidden rounded-circle">
                            <img src="https://secure.gravatar.com/avatar/039be6ff55635c93638f6fbbc0be78cf?s=96&d=mm&r=g" alt="avtar images" width={48} height={48} className="rounded-circle object-fit-contain" loading="lazy" />
                        </div> */}
                        <p class="avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle">{comment.username[0]}</p>
                        </div>
                        <div className="col">
                        <h3 className='text-white fs-20 fw-normal text-decoration-underline mb-0'>{comment.username}</h3>
                        <p className='text-dark-tertiary fw-normal fs-16'>{convertDateInFormat(convertDateToLocalDatetime(comment.createdAt),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(comment.createdAt),"hh:mm A")}</p>
                        </div>
                    </div>
                    <p className='fs-16 text-white'>{comment.commentText}</p>
                    <div className="comment-btn-group d-flex">
                        <button className='btn text-white d-flex border-0' onClick={()=>{likeCommentHandler(comment.id)}} disabled={comment.isLikedByUser}>
                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <g clip-path="url(#clip0_14754_207828)">
                            <path d="M5.19571 6.61772C4.44689 5.86819 5.73599 4.68957 6.3792 5.33431L7.4974 6.29839M5.19571 6.61772C6.15886 7.5818 7.44306 9.1886 8.3055 10.1548M5.19571 6.61772C3.59045 4.36821 1.62729 6.15966 2.8468 7.58397M2.8468 7.58397C4.77284 9.83349 6.27219 11.0118 7.0213 11.7616M2.8468 7.58397C2.20526 8.0125 1.22141 8.69813 1.8845 9.83349C2.2586 10.474 4.7731 12.5113 5.73625 13.3683L2.90073 11.1168C2.25863 11.1168 1.56234 12.0808 1.88364 12.7256C2.60019 14.1634 7.76402 19.4721 10.8739 19.1528C11.6516 19.0729 12.3677 18.4052 13.1213 17.9405M7.4974 6.29839L11.8371 10.7974C12.157 11.1168 12.157 10.7934 11.1938 8.22452C10.8923 7.42024 11.195 7.26044 11.516 7.04473M7.4974 6.29839C7.4974 5.44153 8.03663 4.36821 8.94649 5.01093C10.2901 5.96005 11.195 6.79595 11.516 7.04473M11.516 7.04473C11.8371 6.93908 12.1581 6.61772 12.4792 6.94111C12.6313 7.1239 12.8527 7.41584 13.1213 7.80513M13.1213 7.80513C13.7969 8.78468 14.7707 10.3805 15.6897 12.4042C16.8579 14.9767 15.4692 16.2585 13.1213 17.9405M13.1213 7.80513C13.3353 6.9822 14.185 5.975 14.7265 6.29839C15.1487 6.55055 17.939 10.7954 18.2581 12.4042C18.6378 14.3193 17.616 19.301 13.1213 17.9405M10.2318 0.833252V2.44005M13.1213 1.15461L12.2112 2.44005M7.71645 1.15461L8.6796 2.44005" stroke="white" stroke-width="1.5"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_14754_207828">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                            </defs>
                        </svg>
                        {comment.numLikesOnComment}
                        </button>
                        <button className='btn text-white d-flex border-0 ms-3'  data-bs-toggle="collapse" data-bs-target={`#replyinput${comment.id}`} aria-expanded="false" aria-controls={`replyinput${comment.id}`}>
                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10 17.5C8.51664 17.5 7.06659 17.0601 5.83322 16.236C4.59986 15.4119 3.63856 14.2406 3.0709 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.74559 3.64781 7.08197 2.9335 8.53682 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.5 11.24 17.2 12.4083 16.6667 13.4392L17.5 17.5L13.4392 16.6667C12.4092 17.1992 11.2392 17.5 10 17.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Reply
                        </button>
                        {comment.replySet.length > 0 && <button className="btn text-white border-0 ms-auto d-table" data-bs-toggle="collapse" data-bs-target={`#reply${comment.id}`} aria-expanded="false" aria-controls={`reply${comment.id}`}>Show {comment.replySet.length} reply</button>}
                    </div>
                    <div class="collapse" id={`replyinput${comment.id}`}>
                        <Reply comment={comment} uniqueId={uniqueId} resourceName={resourceName} />
                    </div>
                    <div class="collapse" id={`reply${comment.id}`}>
                        {comment.replySet && comment.replySet.map((reply,index)=>{
                        return(
                            <div className="reply-card ps-5 py-4" key={`reply${index}`}>
                            <div className="row">
                            <div className="col-auto">
                                {/* <div className="avtar px-0 overflow-hidden rounded-circle">
                                <img src="https://secure.gravatar.com/avatar/039be6ff55635c93638f6fbbc0be78cf?s=96&d=mm&r=g" alt="avtar images" width={48} height={48} className="rounded-circle object-fit-contain" loading="lazy" />
                                </div> */}
                                <p class="avtar text-white fw-bold background-dark-tertiary fs-24 m-0 p-2 rounded-circle">{reply.username[0]}</p>
                            </div>
                            <div className="col">
                                <h3 className='text-white fs-20 fw-normal text-decoration-underline mb-0'>{reply.username}</h3>
                                <p className='text-dark-tertiary fw-normal fs-16'>{convertDateInFormat(convertDateToLocalDatetime(reply.createdAt),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(reply.createdAt),"hh:mm A")}</p>
                            </div>
                            </div>
                            <p className='fs-16 text-white'>{reply.repliedText}</p>
                            <div className="comment-btn-group d-flex">
                            <button className='btn text-white d-flex border-0' onClick={()=>{likeReplyHandler(reply.id)}} disabled={reply.isLikedByUser}>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <g clip-path="url(#clip0_14754_207828)">
                                    <path d="M5.19571 6.61772C4.44689 5.86819 5.73599 4.68957 6.3792 5.33431L7.4974 6.29839M5.19571 6.61772C6.15886 7.5818 7.44306 9.1886 8.3055 10.1548M5.19571 6.61772C3.59045 4.36821 1.62729 6.15966 2.8468 7.58397M2.8468 7.58397C4.77284 9.83349 6.27219 11.0118 7.0213 11.7616M2.8468 7.58397C2.20526 8.0125 1.22141 8.69813 1.8845 9.83349C2.2586 10.474 4.7731 12.5113 5.73625 13.3683L2.90073 11.1168C2.25863 11.1168 1.56234 12.0808 1.88364 12.7256C2.60019 14.1634 7.76402 19.4721 10.8739 19.1528C11.6516 19.0729 12.3677 18.4052 13.1213 17.9405M7.4974 6.29839L11.8371 10.7974C12.157 11.1168 12.157 10.7934 11.1938 8.22452C10.8923 7.42024 11.195 7.26044 11.516 7.04473M7.4974 6.29839C7.4974 5.44153 8.03663 4.36821 8.94649 5.01093C10.2901 5.96005 11.195 6.79595 11.516 7.04473M11.516 7.04473C11.8371 6.93908 12.1581 6.61772 12.4792 6.94111C12.6313 7.1239 12.8527 7.41584 13.1213 7.80513M13.1213 7.80513C13.7969 8.78468 14.7707 10.3805 15.6897 12.4042C16.8579 14.9767 15.4692 16.2585 13.1213 17.9405M13.1213 7.80513C13.3353 6.9822 14.185 5.975 14.7265 6.29839C15.1487 6.55055 17.939 10.7954 18.2581 12.4042C18.6378 14.3193 17.616 19.301 13.1213 17.9405M10.2318 0.833252V2.44005M13.1213 1.15461L12.2112 2.44005M7.71645 1.15461L8.6796 2.44005" stroke="white" stroke-width="1.5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_14754_207828">
                                    <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                                {reply.numLikesOnReply}
                            </button>
                            <button className='btn text-white d-flex border-0 ms-3'   data-bs-toggle="collapse" data-bs-target={`#replyinput${comment.id}-${reply.id}`} aria-expanded="false" aria-controls={`replyinput${comment.id}-${reply.id}`}>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10 17.5C8.51664 17.5 7.06659 17.0601 5.83322 16.236C4.59986 15.4119 3.63856 14.2406 3.0709 12.8701C2.50325 11.4997 2.35472 9.99168 2.64411 8.53683C2.9335 7.08197 3.64781 5.7456 4.6967 4.6967C5.74559 3.64781 7.08197 2.9335 8.53682 2.64411C9.99168 2.35472 11.4997 2.50325 12.8701 3.07091C14.2406 3.63856 15.4119 4.59986 16.236 5.83323C17.0601 7.0666 17.5 8.51664 17.5 10C17.5 11.24 17.2 12.4083 16.6667 13.4392L17.5 17.5L13.4392 16.6667C12.4092 17.1992 11.2392 17.5 10 17.5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Reply
                            </button>
                            </div>
                            <div class="collapse" id={`replyinput${comment.id}-${reply.id}`}>
                                <Reply comment={comment} uniqueId={uniqueId} resourceName={resourceName} />
                            </div>
                            </div>)
                        })}
                    </div>
                    </div>)
                })}
            </div>
        </div>
        :<div className='mt-5'>
            <img className='img-fluid mx-auto d-table' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/no_comment.png"} alt="no comment" />
        </div>}
        <Pagenumber last_page={lastpage} updatePageNumber={updateCommentByPageNumber} />
    </div>
  )
}


const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
    commentData:state.commentReducer.commentData,
    lastpage:state.commentReducer.lastpage
  });
  
  const mapDispatchToProps = dispatch=>({
    updateWelcomeLoginModalHandler:data=>dispatch(updateWelcomeLoginModal(data)),
    getCommentDataHandler:data=>dispatch(getCommentData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Comment);
import React, { useEffect, useState } from "react";
import { getQueryData, getQueryDataElevate, getQueryDataElevateUser } from "../../../../utils";
import { getCSRF, getSession } from "../../../../utils/Login";
import Header from "../../../common/Header/Header";
import { connect } from "react-redux";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const CreateTournament = ({csrf}) => {
    const [tournamentTypes, setTournamentTypes] = useState([]);
    const [tournamentPurpose, setTournamentPurpose] = useState([]);
    const [tournamentTypeId, setTournamentTypeId] = useState();
    const [tournamentPurposeId, setTournamentPurposeId] = useState();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [isPublic, setIsPublic] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isFormRequired, setIsFormRequired] = useState(false);
    const [discussId, setDiscussId] = useState("");
    const [isTeamRequired, setIsTeamRequired] = useState(false);
    const [teamSize, setTeamSize] = useState("");
    const [prize, setPrize] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [customFormId, setCustomFormId] = useState("");
    const [autoRegistrationAllowed,setAutoRegistrationAllowed] = useState(false);
    const [registrationDisabled, setRegistrationDisabled] = useState(false);
    const [showLeaderboard, setShowLeaderboard] = useState(true);
    const [metatitle, setMetaTitle] = useState("");
    const [metadescription, setMetaDescription] = useState("");
    const [lateSubmissionAllowed, setLateSubmissionAllowed] = useState(false);
    const [allowedEmailDomains, setAllowedEmailDomains] = useState("");

    const [bannerImage, setBannerImage] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    useEffect(()=>{
        const queryForPurpose = `
        query {
            tournamentPurpose {
                id
                name
                createdAt
                updatedAt
            }
            tournamentTypes {
                id
                name
                createdAt
                updatedAt
            }
        }`

        getQueryData(queryForPurpose, "newcompetition")
        .then((data)=>{
            setTournamentPurpose(data?.data?.tournamentPurpose);
            setTournamentTypes(data?.data?.tournamentTypes);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const resetFormFields = () => {
        setTitle("");
        setDescription("");
        setIsPublic(false);
        setIsPublished(false);
        setIsFormRequired(false);
        setDiscussId("");
        setIsTeamRequired(false);
        setTeamSize("");
        setPrize("");
        setStartTime("");
        setEndTime("");
        setIsAnonymous(false);
        setCustomFormId("");
        setAutoRegistrationAllowed(false);
        setRegistrationDisabled(false);
        setShowLeaderboard(true);
        setMetaTitle("");
        setMetaDescription("");
        setLateSubmissionAllowed(false);
        setAllowedEmailDomains("");
    };

    const handleSubmit = (e)=>{
        e.preventDefault();

        const startDate = new Date(startTime);
        const startOffset = startDate.getTimezoneOffset(); 
        startDate.setMinutes(startDate.getMinutes() - startOffset - 330); 

        const endDate = new Date(endTime);
        const endOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();
        
        let mutation=`mutation MyMutation($endDate: DateTime!, $startDate: DateTime!, $title: String!, $bannerImage: Upload, $companyLogo: Upload, $thumbnailImage: Upload) {
            createTournament(description: """${description}""",metaTitle: "${metatitle}", metaDescription: "${metadescription}", discussId: ${discussId?discussId:null}, customFormId: ${customFormId?customFormId:null}, teamSize: ${teamSize?teamSize:0}, endDate: $endDate, isAnonymous: ${isAnonymous}, isFormRequired: ${isFormRequired}, isPublic: ${isPublic}, isPublished: ${isPublished}, isTeamRequired: ${isTeamRequired}, autoRegistrationAllowed: ${autoRegistrationAllowed}, registrationDisable: ${registrationDisabled}, prize: """${prize}""", startDate: $startDate, title: $title, bannerImage: $bannerImage, companyLogo: $companyLogo, thumbnailImage: $thumbnailImage, tournamentPurposeId: ${tournamentPurposeId}, tournamentTypeId: ${tournamentTypeId}, showLeaderboard: ${showLeaderboard},lateSubmissionAllowed: ${lateSubmissionAllowed}) { 
                tournament { 
                    title 
                }
            }
        }`
        
        let variables = {
            title:title,
            startDate:formattedStartDate,
            endDate:formattedEndDate,

            ...(bannerImage && {bannerImage :null}),
            ...(thumbnailImage && {thumbnailImage :null}),
            ...(companyLogo && {companyLogo :null})
        }
        
        var formdata = new FormData();
        const map = {};

        if(bannerImage){
            formdata.append("0", bannerImage);
            map["0"] = ["variables.bannerImage"]
        }
        if(thumbnailImage){
            formdata.append("1", thumbnailImage); 
            map["1"] = ["variables.thumbnailImage"];
        }
        if(companyLogo){
            formdata.append("2", companyLogo); 
            map["2"] = ["variables.companyLogo"];
        }
        formdata.append("operations", JSON.stringify({ query: mutation, variables: variables }));  
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((result)=> {
            console.log(result);
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Tournament created successfully"} />}
            <section id='createTournament' className="common-style background-dark-primary">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-bold text-uppercase">Tournament Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Description' 
                                            value={description}
                                            onChange={(event)=>setDescription(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Tournament Purpose <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="tournamentPurposeId"
                                                className="form-select"
                                                value={tournamentPurposeId}
                                                onChange={(event) => setTournamentPurposeId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Tournament Purpose</option>
                                                {tournamentPurpose && tournamentPurpose.map((purpose) => (
                                                    <option key={purpose.id} value={purpose.id}>
                                                        {purpose.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Tournament Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="tournamentTypeId"
                                                className="form-select"
                                                value={tournamentTypeId}
                                                onChange={(event) => setTournamentTypeId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Tournament Type</option>
                                                {tournamentTypes && tournamentTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Public:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isPublic}
                                                onChange={(event) => setIsPublic(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Published:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isPublished}
                                                onChange={(event) => setIsPublished(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Registration Disabled:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={registrationDisabled}
                                                onChange={(event) => setRegistrationDisabled(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='Start Date' 
                                            value={startTime}
                                            onChange={(e)=>setStartTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='End Date' 
                                            value={endTime}
                                            onChange={(e)=>setEndTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Form Required:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isFormRequired}
                                                onChange={(event) => setIsFormRequired(event.target.checked)}
                                            />
                                        </div>

                                        
                                        {isFormRequired && <div className="form-group mb-3">
                                            <label className="text-white">Custom Form Id</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Custom Form ID'
                                                value={customFormId}
                                                onChange={(event) => setCustomFormId(event.target.value)}
                                            />
                                        </div>}

                                        <div className="form-group mb-3">
                                            <label className="text-white">Discuss Id</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Discuss ID'
                                                value={discussId}
                                                onChange={(event) => setDiscussId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Team Required:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isTeamRequired}
                                                onChange={(event) => setIsTeamRequired(event.target.checked)}
                                            />
                                        </div>

                                        {isTeamRequired && <div className="form-group mb-3">
                                            <label className="text-white">Team Size</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Team Size'
                                                value={teamSize}
                                                onChange={(event) => setTeamSize(event.target.value)}
                                            />
                                        </div>}

                                        <div className="form-group mb-3">
                                            <label className="text-white">Prize <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Prize'
                                                value={prize}
                                                onChange={(event) => setPrize(event.target.value)}
                                                required
                                            />
                                        </div>
                                        

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Anonymous:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isAnonymous}
                                                onChange={(event) => setIsAnonymous(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Auto Registration Allowed:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={autoRegistrationAllowed}
                                                onChange={(event) => setAutoRegistrationAllowed(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Show Leaderboard:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={showLeaderboard}
                                                onChange={(event) => setShowLeaderboard(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Late Submission Allowed:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={lateSubmissionAllowed}
                                                onChange={(event) => setLateSubmissionAllowed(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Banner Image: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="bannerImage"
                                                onChange={(e) => setBannerImage(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Thumbnail Image: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="thumbnailImage"
                                                onChange={(e) => setThumbnailImage(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Company Logo</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                 name="companyLogo"
                                                onChange={(e) => setCompanyLogo(e.target.files[0])}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Title</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Title'
                                                value={metatitle}
                                                onChange={(event) => setMetaTitle(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Description</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Description'
                                                value={metadescription}
                                                onChange={(event) => setMetaDescription(event.target.value)}
                                            />
                                        </div>
                            
                                        <div className="form-group mb-3 d-none">
                                            <label className="text-white">Allowed Email Domains</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Email domains should be comma seperated gmail.com,outlook.com'
                                                value={allowedEmailDomains}
                                                onChange={(event) => setAllowedEmailDomains(event.target.value)}
                                            />
                                        </div>
                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Tournament
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);
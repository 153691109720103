import React, { useEffect, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import * as $ from 'jquery';
import { validatedMobile } from '../../../../../utils/InputValidation';
import { getUTMParameters, submitRegisterationForm } from '../../../../../utils';

var iti3 = null;

export default function GenAiTwoFieldLeadModal({formname,setSuccess,setIsError,country_name,setSuccessMessage,leadFormCta,leadFormHeading,section,csrf,platformName,genaioffercontent}) {
    const [userDetails,setUserDetails] = useState({
        fullname:null,
        email:null,
        phone:'',
    })
    const [additionalDetail,setAdditionalDetail] = useState({
        experience:"0-3yrs"
    })

    useEffect(()=>{
        const input = document.getElementById("leadPhoneNumber");
        if(input){
          iti3 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry: country_name?country_name:"IN",
            preferredCountries: ["in", "us"],
          });
          if(country_name){
            iti3.setCountry(country_name);
          }
        }
    },[country_name])

    useEffect(()=>{
        setUserDetails({
            fullname:'',
            email:'',
            phone:'',
        });
        setAdditionalDetail({
        experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
    },[formname])

    const saveLeadForm = (e)=>{
        e.preventDefault();
        let country_code = '+'+iti3.getSelectedCountryData().dialCode;
        // if(userDetails.fullname === ''){
        //     $('.error').remove();
        //     $('#leadFullName').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
        //     $('#leadFullName').css('border-color','red');
        //     $('#leadFullName').css('color','red');
        //     setTimeout(()=>{
        //         $('#leadFullName').css('border-color','#383838');
        //         $('#leadFullName').css('color','white');
        //         $('.error').slideUp();
        //         $('.error').remove();
        //     },2000)
        //     return false;
        // }
        if(!validatedMobile(userDetails.phone)){
          $('.error').remove();
          $('#leadPhoneNumber').closest('.col-xl-12').append(`<small class="text-danger error d-block">Enter a valid phone number</small>`);
          $('#leadPhoneNumber').css('border-color','red');
          $('#leadPhoneNumber').css('color','red');
          setTimeout(()=>{
              $('#leadPhoneNumber').css('border-color','#383838');
              $('#leadPhoneNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }
      //   if(!validatedEmail(userDetails.email)){
      //     $('.error').remove();
      //     $('#leadEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
      //     $('#leadEmail').css('border-color','red');
      //     $('#leadEmail').css('color','red');
      //     setTimeout(()=>{
      //         $('#leadEmail').css('border-color','#383838');
      //         $('#leadEmail').css('color','white');
      //         $('.error').slideUp();
      //         $('.error').remove();
      //     },2000)
      //     return false;
      // }
       
        let user_data = {
            "email":'',
            "phone":userDetails.phone,
            "country_code":country_code,
        }
        let additional_detail ={
          "tnc":true,
          "is_whatsapp":true,
          "section":section,
          "page_url":window.location.href
        }
        if(genaioffercontent && genaioffercontent.discountEndDate){
          additional_detail["offer_valid_till"] = genaioffercontent.discountEndDate
        }
        setUserDetails({
          fullname:'',
          email:'',
          phone:'',
        });
        setAdditionalDetail({
          experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
        $('#blackbeltLeadModal .close').click();
        if(formname === 'projects'){
          setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if(formname === 'curriculum'){
          setSuccessMessage('Curriculum Sent. Check Your Inbox')
        }
        else if(formname === 'roadmap'){
          setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if(formname === 'instructor_profile'){
          setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if(formname === 'workshops'){
          setSuccessMessage('Details on the way! Check Your Inbox')
        }
        else if(formname === 'free_trial'){
          setSuccessMessage('Thank you for enrolling in the free trial! You will now be redirected.')
        }
        else if(formname === "emi"){
          // if(country_name === 'IN'){
            setSuccessMessage("Our learning counselor will contact you shortly")
          // }
          // else{
          // setSuccessMessage("Great! You're almost there. Proceed to pay")
          // }
        }
        else if(['enroll','full_payment'].includes(formname)){
          // if(country_name === 'IN'){
          //   setSuccessMessage("Our learning counselor will contact you shortly")
          // }
          // else{
          setSuccessMessage("Great! You're almost there. Proceed to pay")
          // }
        }
        setSuccess(true);
        if(['enroll','full_payment'].includes(formname)){
          if(country_name === 'IN')
          {              
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=IN129712';
          }
          else if(['US','CA'].includes(country_name)){
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP2713718';
          }
          else{
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP129712';
          }
        }
        setTimeout(()=>{
          setSuccess(false);
          setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!')
        },4000)
        window.$('#genaiLeadModal').modal('hide');
        if (formname === 'free_trial') {
          genAiFreeTrialEnrollment(e);
        }
        else {
          submitRegisterationForm(user_data,additional_detail,platformName,formname,csrf)
          .then((data)=>{
            
          })
          .catch((error)=>{
            console.log(error)
            // setIsError(true);
            // setTimeout(()=>{
            //   setIsError(false);
            // },4000)
          })
        }
    }

    const updateExperience = (value)=>{
        setAdditionalDetail((prev)=>{return({...prev,experience:value})})
    }

    const removebackdrop = ()=>{
      $('.modal-backdrop.show').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
    }

    const genAiFreeTrialEnrollment = (e) =>{
      e.preventDefault();
      console.log("Here it is");
      const formData = new FormData();
  
      const utmParameters = getUTMParameters();
      console.log("params", utmParameters);
      let payload = {
          'form_type': 'free_trial',
          'platform_name': 'genaipinnacle',
          'country_code': '+' + iti3.getSelectedCountryData().dialCode,
          'phone': userDetails.phone,
          'is_whatsapp': $("#isWhatsappLoginModal").is(":checked"),
          'utm_source': utmParameters ? utmParameters.utm_source : null,
          'utm_medium': utmParameters ? utmParameters.utm_medium : null,
          'utm_campaign': utmParameters ? utmParameters.utm_campaign : null,
          'utm_term': utmParameters ? utmParameters.utm_term : null,
          'utm_content': utmParameters ? utmParameters.utm_content : null,
          'gcl_id': utmParameters ? utmParameters.gcl_id : null,
          'irclickid': utmParameters ? utmParameters.irclickid : null
      };
  
      formData.append("url","api/genai/");
      formData.append('platform', 'newmarketingpages');
      formData.append("payload", JSON.stringify(payload));
      formData.append("timeout", 100);
  
      fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
        method: 'POST',
        headers: {
            "X-CSRFToken":csrf,
        },
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.response && data.response.status_code === 201 && data.response.redirect_url) {
            window.location.href = data.response.redirect_url;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

  return (
    <>
    <div className="modal lead-modal" id='genaiLeadModal' data-bs-backdrop="static" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>{leadFormHeading} <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-5">
                <div className="row">
                    {/* <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Full Name</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadFullName" placeholder="Your Full Name" autoComplete='off' value={userDetails.fullname} onChange={e=>setUserDetails((prev)=>{return{...prev,fullname:e.target.value}})} />
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-12 mb-3">
                        <label htmlFor="leadPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                        <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="leadPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e=>setUserDetails((prev)=>{return{...prev,phone:e.target.value}})} />
                    </div>
                    {/* <div className="col-xl-12 col-lg-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                    </div> */}
                    {/* <div className="col-lg col-md-12 col-12 my-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Experience :</label>
                        <div className="experience-tag">
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox1"} name="experience" onChange={()=>{updateExperience("0-3yrs")}} defaultChecked />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox1"}>0-3yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox2"} name="experience" onChange={()=>{updateExperience("3-8yrs")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox2"}>3-8yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox3"} name="experience" onChange={()=>{updateExperience("8-12yrs")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox3"}>8-12yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox4"} name="experience" onChange={()=>{updateExperience("12yrs+")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox4"}>12yrs+</label>
                        </div>
                    </div> */}
                </div>
                <div className="mt-4 mb-4 d-flex flex-wrap">
                    <div className="form-check d-flex align-items-center mb-2 me-4">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>Terms & Conditions</a></label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0 p-0">
                <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>{leadFormCta}</button>
            </div>
        </div>
        </div>
      </div>
    </>
  )
}

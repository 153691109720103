import React, { useEffect } from 'react'
import Footer from '../../../common/Footer/Footer';
import HomepageHeader from '../components/HomepageHeader';
import { connect } from 'react-redux';
import { fetchHomepageContent } from '../../../../store/Actions/Action';
import { scrollTop } from '../../../../utils';
import Flashstrip from '../../../common/Header/Flashstrip';
import Cookies from '../../../common/Footer/Cookies';

function RefundPolicy({homepagecontent, fetchHomepageContentHandler}) {

	useEffect(()=>{
		if(!homepagecontent){
			fetchHomepageContentHandler();
        }
        scrollTop();
	},[]);

   return (
       <>
       <main className='background-dark-primary'>
		    <HomepageHeader header_content={homepagecontent && homepagecontent.header} />
            <Flashstrip />
            <section className='static-pages' id='refundPolicy'>
               <div className='container'>
                   <div className="row">
                       <div className="col-md-8 col-10 mx-auto">

                           <h2>Refund policy</h2>
                               <p>Analytics Vidhya aims at providing the most useful knowledge platform for analytics lovers and learners across the world. Our refund policy is as simple as it can get.</p>

                           <h3>Advertisements, Job Postings and Corporate Trainings</h3>
                               <p>
                                   <ul>
                                       <li>Advertisement- No refund will be initiated once the order has been placed</li>
                                       <li>No refund request will be entertained for Job Posting & Corporate Trainings.</li>
                                   </ul>
                               </p>

                           <h3>Courses and Programs</h3>
                               <p>
                                   <ul>
                                        <li>It is the sole responsibility of the user enrolling into a Program to check the accuracy of, and evaluate the suitability and relevance of, the Program elected. The enrolment into a Program is non-transferable.</li>
                                        <li>There is no refund available on purchasing individual courses.</li>
                                        <li>There is a 7 days no questions asked Money back Guarantee ONLY on programs. Following the 7-day refund period, there will be no further refunds.</li>
                                        <li>To request a refund, simply contact us with your purchase details within seven (7) days of your purchase. Please include your transaction number (sent to you via email after purchasing) and optionally tell us why you’re requesting a refund – we take customer feedback very seriously and use it to constantly improve our products and quality of service.</li>
                                        <li>Refunds will be processed within 30 days from the date you request.</li>
                                        <li>If program is bought in pre-launch offer or discounted price or on EMI, then the fee paid is non-refundable.</li>
                                   </ul>
                               </p>

                           <h3>To Request a refund for programs</h3>
                               <p>
                                   <ul>
                                       <li>Kindly fill the form at <a href='https://docs.google.com/forms/d/e/1FAIpQLSdPFVvUE6q_WOwWxhiV3PeHWkTujx8SXAdVcAJkftosivtElw/viewform?usp=pp_url' target='_blank'>fill the refund form</a> to request a refund.</li>
                                       <li>Notwithstanding the mode of payment used for paying the fees, the refund shall be paid to a bank account (as per the details provided by you). We may request information/documents (which may include a cancelled cheque pertaining to the bank account of the customer) to verify your credentials and details of the bank account before initiating the refund.</li>
                                       <li>We shall strive to process the refunds within thirty (30) days of receipt of all the relevant information and documentation from you.</li>
                                       <li>If you are not happy with our products, please do let us know your reasons. Do give us a chance to improvise on our products & services by sharing your valuable feedback.</li>
                                   </ul>
                               </p>

                           <h3>Return Policy</h3>
                               <p>Note that all the educational material is online and hence there are no returns</p>

                           <h3>Cancellation Policy</h3>
                               <p>You can cancel from a Program within the first 7 days from your purchase date in order to be eligible for a refund, unless you have graduated from the program within this timeframe. Note that cancellation option is available ONLY for programs and not for individual courses. In order to request a refund please see the refund policy</p>

                           <h3>Shipping Policy</h3>
                               <p>This is an online course and can be taken on the portal, hence there is no physical shipping of items</p>

                            <h3>Scholarship Offer:</h3>
                            <ul>
                                <li>This scholarship offer is valid only for enrollments done during the Scholarship Week only. Specific dates of this offer will be communicated on our website and marketing materials.</li>
                                <li>The scholarship applies to the full fees of the Program, excluding any taxes or transaction processing charges.</li>
                                <li>To be eligible for the scholarship refund, the user must successfully complete the mandatory modules of the scholarship offer with all assignments, assessments and projects within the defined time period (number of months) from the date of enrollment in the specific program.</li>
                                <li>Completion criteria will be clearly defined within the course platform. This may include assignments, quizzes, assessments or project submissions.</li>
                                <li>The scholarship refund will be processed after successful completion of the mandatory modules and verification by Analytics Vidhya. </li>
                                <li>The scholarship refund will be initiated within 1 month of confirming completion of the modules. The refund will be processed through the original payment method used for paying the course enrollment fees.</li>
                                <li>This scholarship offer cannot be combined with any other discounts or promotions and further this scholarship is non – transferable.</li>
                                <li>Any case of copying, plagiarism, cheating or getting others to complete the course modules shall disqualify the user from being eligible for this offer.</li>
                                <li>Loan, EMI, Financing or Part-payment options cannot be availed for Scholarship Offer.</li>
                                <li>Analytics Vidhya reserves the right to modify or cancel this scholarship offer at any time without prior notice.</li>
                            </ul>

                       </div>
                   </div>
               </div>
           </section>
           <Footer platform={'newrefundpolicy'} />
       </main>
       <Cookies platform={'newhomepage'} />
       </>
   );
}

const mapStateToProps = (state) => ({
	homepagecontent:state.homepageContentReducer.homepagecontent
  });
  
  const mapDispatchToProps = dispatch=>({
	fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(RefundPolicy);
import React, { Suspense, useEffect, useState } from 'react'
import B2Bheader from '../components/B2Bheader';
import Footer from '../../../../common/Footer/Footer';
import $ from 'jquery';
import Modal from '../components/Modal';
import { validatedEmail } from '../../../../../utils/InputValidation';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import { connect } from 'react-redux';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

function Training({csrf}) {
    const [formData,setFormData] = useState({
        formType:"",
        section:""
    });
    const [email,setEmail] = useState('');
    const [success,setSuccess] = useState(false);
    const [heading,setHeading] = useState(<>Contact us today!</>);
    const [buttonTxt, setButtonTxt] = useState(<>Contact us</>);
    const [message, setMessage] = useState("");

    useEffect(()=>{
        $(document).ready(function() {
            $(window).scroll(function() {
                if ($(this).scrollTop() >= 50) {
                $('.navbar').addClass('active');
                } else {
                $('.navbar').removeClass('active');
                }
            });
        });
    },[]);

    useEffect(()=>{
        $('#experience .nav-link').click(()=>{
            let height = $('#experience .nav-link.active').index();
            $('#v-pills-tab').css('--height', `${33.33 * (height+1)}%`);
        })
    },[]);

    const saveNewsletterForm = (e)=>{
        e.preventDefault();

        if(!validatedEmail(email)){
        $('.error').remove();
        $('#newsletterEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
        $('#newsletterEmail').css('border-color','red');
        $('#newsletterEmail').css('color','red');
        setTimeout(()=>{
            $('#newsletterEmail').css('border-color','#383838');
            $('#newsletterEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },2000)
        return false;
        }
    
        let user_data = {
            "fullname":null,
            "email":email,
            "phone":null,
            "country_code":null,
        }
        let additional_detail ={
        "page_url":window.location.href
        }
        setEmail("");
        setSuccess(true);
        setTimeout(()=>{
        setSuccess(false);
        window.open('https://newsletter.ai/',"_blank");
        },4000)
        submitRegisterationForm(user_data,additional_detail,"enterprise-training","newsletter",csrf)
        .then(()=>{
        })
        .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
        })
    }

    useEffect(()=>{
        scrollTop();
    },[])

    useEffect(()=>{
        new window.Swiper('.swiper1', {
            loop: true,
            spaceBetween:0,
            slidesPerView:7,
            speed:3000,
            autoplay:{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter:true
            },
            breakpoints:{
                0: {
                    slidesPerView: 2,
                },
                599: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 6,
                },
            }
        });

        new window.Swiper('.swiper2', {
            spaceBetween:24,
            slidesPerView:1,
            navigation: {
                nextEl: "#testimonialNextBtn",
                prevEl: "#testimonialPrevBtn",
            },
        });
    },[]);

  return (
    <main id='b2b'>
        <B2Bheader />
        {success && <SuccessAlert message={message} />}
        <Modal platform="enterprise-training" formtype={formData.formType} section={formData.section} heading={heading} buttonTxt={buttonTxt} message={message}/>
        <section id="b2bHeroFold" style={{backgroundImage: `url('https://d26nt4befnrop2.cloudfront.net/b2b/training.png'), linear-gradient(to right, #1A181B 0 80%, transparent)`}}>
            <div className="container align-items-center h-100">
                <div className="row align-items-center h-100">
                    <div className="col-lg-7 col-md-12 col-12">
                        <h1 className="fs-56  mb-3 fw-semibold">
                            <span className="text-gradient">Building Next-Gen AI </span>
                            <span className='text-white'>Capabilities</span>
                        </h1>
                        <p className='text-white fs-20 mb-4'>Master AI with targeted training programs and practical, hands-on learning experiences</p>
                        <button className='btn btn-dark btn-dark-primary me-3 mb-5' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"hero-fold"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
                        <button className='btn btn-dark btn-dark-primary mb-5' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'assess_team_skills',section:"hero-fold"}); setHeading(<>Evaluate Team Expertise</>); setButtonTxt(<>Discover Now</>); setMessage("Thanks for reaching out! We will be in touch shortly.")}}>Assess team skills</button>
                        <ul className='list-style-none mt-5 d-flex justify-content-between flex-wrap'>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>10.1 Million+</h2>
                                <p className='text-dark-secondary fs-18'>Professionals benefited</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>350K+</h2>
                                <p className='text-dark-secondary fs-18'>Learners impacted</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>400+</h2>
                                <p className='text-dark-secondary fs-18'>Global firms impacted</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className='background-dark-secondary py-60' id='alumniLogo'>
            <div className='text-white col-lg-8 heading-with-text mb-5 text-center mx-auto'>
            <h2 className='fs-40 fw-normal mb-3'>Trusted by 500+ Enterprises</h2>
            <p className='text-dark-secondary'>Your enterprise could be the next to leverage our proven AI expertise and strong partnership</p>
            </div>
            <div className='swiper1 overflow-hidden'> 
                <div className="swiper-wrapper">
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/ABINBEV-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/AE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/Artboard 23-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/B&C-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/BOSCH-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/CBRE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/FRACTAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/GENPACT-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/HDFC-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/INFOSYS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/JSL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/L&T-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/LM-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/M&c-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PIRAMAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/RIPIK.AI-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/SB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/SYNCHRONY-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/TVS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/WNS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/ZEPTO-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                </div>
            </div>
        </section>
        <section className='bg-white py-120' id='trainingSolution'>
            <div className='container'>
                <div className="mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Cutting-Edge Training Solutions</h2>
                    <p className="fs-18 fw-normal">Empower your team with the latest AI skills and unlock innovation and growth</p>
                </div>
                <div className='row g-4'>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/training_solutions1.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Enterprise Programs</h2>
                            <p className='fs-16 text-light-primary'>Empower your teams to make data-driven decisions with our customized training programs</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/proctored_tests.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Proctored Assessments</h2>
                            <p className='fs-16 text-light-primary'>Assess and benchmark your team's AI skills with our secure, proctored tests</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/certification.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Certifications</h2>
                            <p className='fs-16 text-light-primary'>Validate your skills and expertise with our industry-recognized certifications</p>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mx-auto d-table mt-5" data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"cutting-edge-training-solution"}); setHeading(<>Get in Touch</>); setButtonTxt(<>Connect with us</>); setMessage("Thank you for your interest. We will be in touch shortly.")}}>Get in touch</button>
            </div>
        </section>
        <section className='py-60 event-tabs background-light-secondary' id='experience'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text">
                        <h2 className="fs-40 fw-normal mb-3">Assess AI Capabilities with Skill Survey </h2>
                        <p className="fs-18 fw-normal">Discover your team's true potential, upskill them with confidence</p>
                    </div>
                </div>
                <div className="d-flex align-items-start col-lg-11 mx-auto" id='experienceTabs'>
                    <div className="nav flex-column nav-pills me-3 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active p-4 pt-0 text-start" id="v-pills-discover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-discover" type="button" role="tab" aria-controls="v-pills-discover" aria-selected="true">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Assess Team Skills</h3>
                        <p className='fs-16 fw-normal'>Leverage our AI skill survey to identify individual and team strengths. Optimize performance and make informed decisions</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-learn-tab" data-bs-toggle="pill" data-bs-target="#v-pills-learn" type="button" role="tab" aria-controls="v-pills-learn" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Compare & Benchmark</h3>
                        <p className='fs-16 fw-normal'>Gain valuable insights by comparing your team's skills against industry benchmarks. Identify skill gaps and target training efforts for measurable progress</p>
                        </button>
                        <button className="nav-link p-4 pb-0 fw-bold fs-20 text-start" id="v-pills-engage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-engage" type="button" role="tab" aria-controls="v-pills-engage" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-0 text-uppercase'>Custom Learning Path</h3>
                        <p className='fs-16 fw-normal my-0 mt-3'>Develop customized learning roadmaps based on the skill surveys and benchmarks. Make informed decisions on customized learning paths</p>
                        </button>
                    </div>
                    <div className="tab-content col-lg-6 col-12 rounded-3" id="v-pills-tabContent">
                        <div className="card tab-pane fade show active bg-transparent border-0 m-0" id="v-pills-discover" role="tabpanel" aria-labelledby="v-pills-discover-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-discover">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-discover" data-bs-parent="#experienceTabs" aria-expanded="true" aria-controls="v-collapse-discover">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Assess Team Skills</h3>
                                <p className='fs-16 fw-normal pe-4 text-white mb-3'>Leverage our AI skill survey to identify individual and team strengths. Optimize performance and make informed decisions</p>
                                </a>
                            </div>
                            <div id="v-collapse-discover" className="collapse show pb-4 pt-2" role="tabpanel" aria-labelledby="v-collapse-heading-discover" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={cloudflare_size_url+'b2b/training_homepage.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent tab-pane fade" id="v-pills-learn" role="tabpanel" aria-labelledby="v-pills-learn-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-learn">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-learn" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-learn">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Compare & Benchmark</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>Gain valuable insights by comparing your team's skills against industry benchmarks. Identify skill gaps and target training efforts for measurable progress</p>
                                </a>
                            </div>
                            <div id="v-collapse-learn" className="collapse pb-4 pt-2" role="tabpanel" aria-labelledby="v-collapse-heading-learn" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={cloudflare_size_url+'b2b/compare_benchmarking.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-engage" role="tabpanel" aria-labelledby="v-pills-engage-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-engage">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-engage" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-engage">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Custom Learning Path</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>Develop customized learning roadmaps based on the skill surveys and benchmarks. Make informed decisions on customized learning paths</p>
                                </a>
                            </div>
                            <div id="v-collapse-engage" className="collapse pb-4 pt-2" role="tabpanel" aria-labelledby="v-collapse-heading-engage" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto rounded-3' src={cloudflare_size_url+'b2b/data_culture_homepage.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary mx-auto d-table py-2 px-4 my-4' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'skill_survey',section:"assess-AI-capabilities-with-skill-survey"}); setHeading(<>Explore Skill Survey</>); setButtonTxt(<>Reach us</>); setMessage("Thank you for your interest. We will be in touch shortly.")}}>Explore Skill Survey</button>
            </div>
        </section>
        <section className='background-dark-secondary py-60 d-none'>
            <div className="container">
                <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Hear From Our Clients</h2>
                    <p className="fs-18 fw-normal text-dark-secondary">Discover how we helped organizations achieve accelerated success</p>
                </div>
                <div className="row">
                    <div className='swiper2 overflow-hidden'>
                        <div className="swiper-wrapper">
                            <div className='swiper-slide' >
                                <div class="card mb-3 background-dark-tertiary rounded-2">
                                    <div class="row g-0">
                                        <div class="col-lg-4 col-md-12 col-12">
                                            <img src={cloudflare_size_url+'b2b/demo_customer.png'} class="img-fluid w-100" alt="demo_customer" />
                                        </div>
                                        <div class="col-lg-8 col-md-12 col-12">
                                            <div class="card-body p-5">
                                                <div className="company-logo-wrapper">
                                                    <img className='bg-white img-fluid mb-3 rounded-2' src={cloudflare_url+'blackbelt/company_logo/fractal.svg'} alt="fractal" width={160} />
                                                </div>
                                                <p class="card-text text-white fs-24 mb-5">“Analytics Vidhya revolutionized our workforce. Training 2,000+ employees, their expertise brought invaluable insights, driving innovation and proficiency across our organization. Grateful for the transformative partnership.”</p>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <h2 className='fs-20 text-white'>TASHI MEHRA</h2>
                                                        <p className='text-dark-secondary fs-18'>Senior HR Executive</p>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className='btn btn-dark btn-dark-primary d-flex' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'case_study',section:"hear-from-our-customer"})}}>
                                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                <path d="M17.5 13V14C17.5 15.4001 17.5 16.1002 17.2275 16.635C16.9878 17.1054 16.6054 17.4878 16.135 17.7275C15.6002 18 14.9001 18 13.5 18H6.5C5.09987 18 4.3998 18 3.86502 17.7275C3.39462 17.4878 3.01217 17.1054 2.77248 16.635C2.5 16.1002 2.5 15.4001 2.5 14V13M14.1667 8.83333L10 13M10 13L5.83333 8.83333M10 13V3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Download case study
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='swiper-slide' >
                                <div class="card mb-3 background-dark-tertiary rounded-2">
                                    <div class="row g-0">
                                        <div class="col-md-4">
                                            <img src={cloudflare_size_url+'b2b/demo_customer.png'} class="img-fluid rounded-start" alt="demo_customer" />
                                        </div>
                                        <div class="col-md-8">
                                            <div class="card-body p-5">
                                                <div className="company-logo-wrapper">
                                                    <img className='bg-white img-fluid mb-3 rounded-2' src={cloudflare_url+'blackbelt/company_logo/fractal.svg'} alt="fractal" width={160} />
                                                </div>
                                                <p class="card-text text-white fs-24 mb-5">“Analytics Vidhya revolutionized our workforce. Training 2,000+ employees, their expertise brought invaluable insights, driving innovation and proficiency across our organization. Grateful for the transformative partnership.”</p>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <h2 className='fs-20 text-white'>TASHI MEHRA</h2>
                                                        <p className='text-dark-secondary fs-18'>Senior HR Executive</p>
                                                    </div>
                                                    <div className="col-auto">
                                                        <button className='btn btn-dark btn-dark-primary d-flex' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'case_study',section:"hear-from-our-customer"})}}>
                                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                                <path d="M17.5 13V14C17.5 15.4001 17.5 16.1002 17.2275 16.635C16.9878 17.1054 16.6054 17.4878 16.135 17.7275C15.6002 18 14.9001 18 13.5 18H6.5C5.09987 18 4.3998 18 3.86502 17.7275C3.39462 17.4878 3.01217 17.1054 2.77248 16.635C2.5 16.1002 2.5 15.4001 2.5 14V13M14.1667 8.83333L10 13M10 13L5.83333 8.83333M10 13V3" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                            </svg>
                                                            Download case study
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-slider-group mt-3 ms-auto d-table">
                    <button className='btn border-0' id='testimonialPrevBtn' aria-label='prev button'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M25.9998 16L19.414 22.5858C18.633 23.3668 18.633 24.6332 19.414 25.4142L25.9998 32M45.9998 24C45.9998 11.8497 36.1501 2 23.9998 2C11.8496 2 1.99982 11.8497 1.99982 24C1.99982 36.1503 11.8496 46 23.9998 46C36.1501 46 45.9998 36.1503 45.9998 24Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </button>
                    <button className='btn border-0' id='testimonialNextBtn' aria-label='next button'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M21.9998 16L28.5856 22.5858C29.3667 23.3668 29.3667 24.6332 28.5856 25.4142L21.9998 32M1.99982 24C1.99982 11.8497 11.8496 2 23.9998 2C36.1501 2 45.9998 11.8497 45.9998 24C45.9998 36.1503 36.1501 46 23.9998 46C11.8496 46 1.99982 36.1503 1.99982 24Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </section>
        <section className='common-style pb-60 bg-white mb-5' id='avAdvantage'>
            <div className='container'>
                <div className="mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">The AV Advantage</h2>
                    <p className="fs-18 fw-normal">Delivering AI expertise at scale and speed</p>
                </div>
                <div className='row g-4 align-items-center mb-4'>
                    <div className="col-lg-5 col-md-12 col-12">
                        <h2 className='fw-normal'>Discover</h2>
                        <p>Through our skill survey and assessments, we identify your team's potential and pinpoint areas for growth</p>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto">
                        <img className='img-fluid w-100 rounded-3' src={cloudflare_size_url+"b2b/discover.png?format=webp"} alt="av advantage" />
                    </div>
                </div>
                <div className='row g-4 align-items-center mb-4'>
                    <div className="col-lg-5 col-md-12 col-12 order-2 order-lg-1">
                        <img className='img-fluid w-100 rounded-3' src={cloudflare_size_url+"b2b/design.png?format=webp"} alt="av advantage" />
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto order-1 order-lg-2">
                        <h2 className='fw-normal'>Design</h2>
                        <p>In design, we focus on individual growth, creating training modules that build the skills your team needs to thrive</p>
                    </div>
                </div>
                <div className='row g-4 align-items-center'>
                    <div className="col-lg-5 col-md-12 col-12">
                        <h2 className='fw-normal'>Deliver</h2>
                        <p>We streamline delivery with end-to-end support, turning training into tangible growth and enabling your team to excel</p>
                    </div>
                    <div className="col-lg-5 col-md-12 col-12 ms-auto">
                        <img className='img-fluid w-100 rounded-3' src={cloudflare_size_url+"b2b/deploy.png?format=webp"} alt="av advantage" />
                    </div>
                </div>
            </div>
        </section>
        <section className='background-dark-secondary common-style-py'>
            <div className='container'>
                <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">AI Training Panorama</h2>
                    <p className="fs-18 fw-normal text-dark-secondary">Explore the full spectrum of AI training opportunities, from foundational skills to advanced techniques, tailored to empower your team</p>
                </div>
                <div className='row g-4'>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-dark-tertiary p-3">
                            <div className="image-wrapper mb-3 overflow-hidden rounded-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/industries.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-white mb-3'>Industries</h2>
                            <ul className='text-white fs-18'>
                                <li className='mb-2'>BFSI</li>
                                <li className='mb-2'>IT/ITES</li>
                                <li className='mb-2'>Analytics Companies</li>
                                <li className='mb-2'>Global Capability Centers</li>
                                <li className='mb-2'>Manufacturing</li>
                                <li className='mb-2'>&  Others..</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-dark-tertiary p-3">
                            <div className="image-wrapper mb-3 overflow-hidden rounded-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/training_panaroma2.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-white mb-3'>Personas</h2>
                            <ul className='text-white fs-18'>
                                <li className='mb-2'>Freshers</li>
                                <li className='mb-2'>Data Scientists</li>
                                <li className='mb-2'>Data Engineers</li>
                                <li className='mb-2'>Data/Business Analysts</li>
                                <li className='mb-2'>AI Managers</li>
                                <li className='mb-2'>&  Others..</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-dark-tertiary p-3">
                            <div className="image-wrapper mb-3 overflow-hidden rounded-3">
                                <img className='card-img-top' src={cloudflare_size_url+'b2b/training_panaroma3.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-white mb-3'>Expertise</h2>
                            <ul className='text-white fs-18'>
                                <li className='mb-2'>Business Analytics</li>
                                <li className='mb-2'>Machine Learning</li>
                                <li className='mb-2'>Cloud Computing</li>
                                <li className='mb-2'>Deep Learning</li>
                                <li className='mb-2'>Data Engineering</li>
                                <li className='mb-2'>Generative AI</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='common-style-py d-none'>
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <h2 className='fs-24 fw-normal'>Stay ahead of the curve with top AI stories and insights delivered to your inbox</h2>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <form id='newsletterForm' className='row g-4' onSubmit={saveNewsletterForm}>
                            <div className="col-lg col-md col-12">
                                <input className='form-control h-100 text-dark' type="email" id='newsletterEmail' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Email address' autoComplete='off' />
                            </div>
                            <div className="col-lg-auto col-md-auto col-12">
                                <button className='btn btn-primary px-4 py-2 w-100'>Sign Me Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section className='background-dark-primary py-60' id='aiEdge'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="text-white heading-with-text mb-5">
                            <h2 className="fs-40 fw-normal mb-3">Get the Generative AI Edge</h2>
                            <p className="fs-18 fw-normal text-dark-secondary">Don't get left behind. Enable your team with the latest Gen AI skills</p>
                        </div>
                        <ul className='text-white fs-16 mb-5 list-style-none'>
                            <li className='mb-3'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M2.25 8.55049L7.12793 13.3126L16.5026 3.93799" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Large Language Model</li>
                            <li className='mb-3'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M2.25 8.55049L7.12793 13.3126L16.5026 3.93799" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                Diffusion Model</li>
                            <li className='mb-3'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M2.25 8.55049L7.12793 13.3126L16.5026 3.93799" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            Responsible AI</li>
                        </ul>
                        <button className='btn btn-dark btn-dark-primary' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'generative_ai_solution',section:"get-the-generative-ai-edge"}); setHeading(<>Discover GenAI Solutions</>); setButtonTxt(<>Unlock Now</>); setMessage("We appreciate your curiosity. We will get in touch with you shortly.")}}>Discover Generative AI Solutions</button>
                    </div>
                </div>
            </div>
        </section>
        <section id='b2bPartnerWithUs' className='background-light-secondary py-60'>
            <div className="container">
                <div className="text-dark mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Are You Ready to Make Your Organisation AI-ready?</h2>
                    <p className="fs-18 fw-normal">Partner with us to innovate and drive growth with AI</p>
                </div>
                <button className='btn btn-primary mx-auto d-table py-2 px-4' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"partner-with-us"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
            </div>
        </section>
        <Footer platform={"new_b2b"}/>
    </main>
  )
}




const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = ()=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Training);
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import DatahackHome from '../pages/index';
import SkillTest from '../pages/SkillTest';
// import EventHome from '../pages/EventHome';
import CompetitionHome from '../pages/CompetitionHome';
// import CompetitionListingPage from '../pages/CompetitionListingPage';
// import EventDetail from '../pages/EventDetail';
// import EventListingPage from '../pages/EventListingPage';
import HackthonDetail from '../pages/HackthonDetail';
import Leaderboard from '../pages/Leaderboard';
// import LeadingWithDataListing from '../pages/LeadingWithDataListing';
// import LeadingWithDataDetails from '../pages/LeadingWithDataDetails';
import Error404 from '../../../common/Alert/Error404';
import HackathonListing from '../pages/HackathonListing';
import BlogathonListingPage from '../pages/BlogathonListingPage';
import BlogathonLeaderboard from '../pages/BlogathonLeaderboard';
import SkillTestLeaderboard from '../pages/SkillTestLeaderboard';
import Datamin from '../pages/Datamin';
import InviteListingPage from '../pages/InviteListingPage';
import Title from '../../../common/Header/Title';

const cloudflare_url = process.env.REACT_APP_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_CLOUDFLARE_URL_SIZE;

export default function CompetitionRouters() {

  return (
    <>
      <Title title={'The Competitive Platform for AI Experts'} description={'Analytics Vidhya is the leading community of Analytics, Data Science and AI professionals. We are building the next generation of AI professionals. Get the latest data science, machine learning, and AI courses, news, blogs, tutorials, and resources.'} image_og={cloudflare_size_url+'og.jpg?format=avif'} title_og={'The Competitive Platform for AI Experts'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <Routes>
          <Route path='/' exact element={<CompetitionHome />} />
           <Route path='/hackathon' exact element={<HackathonListing />}/>
          <Route path='/contest/:hackathonSlug' exact element={<HackthonDetail />}/>
          <Route path='/leaderboard/:hackathonSlug' exact element={<Leaderboard />}/>
          <Route path='/skill-test/:tournamentSlug/:stageSlug/:attemptId' exact element={<SkillTest />}/>
          <Route path='/datamin/:tournamentSlug/:stageSlug/leaderboard' exact element={<SkillTestLeaderboard />}/>
          <Route path='/datamin/:tournamentSlug/:stageSlug/:quizId' exact element={<Datamin />}/>
          <Route path='/blogathon' exact element={<BlogathonListingPage />}/>
          <Route path='/blogathon/:blogathonSlug/leaderboard/:stageSlug' exact element={<BlogathonLeaderboard />}/>
          <Route path='/invites/:hackathonSlug' exact element={<InviteListingPage />}/>
          <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  )
}

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryDataElevateUser } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import Cookies from '../../../common/Footer/Cookies';
import Flashstrip from '../../../common/Header/Flashstrip';
import { updateWelcomeLoginModal } from '../../../../store/Actions/Action';

function InviteListingPage({islogin,csrf,updateWelcomeLoginModalHandler}) {
      const { hackathonSlug } = useParams();
      const [invites,setInvites] = useState(null);
      const [iserror,setIsError] = useState(false);
      const [issuccess,setIsSuccess] = useState(false);
      const [message,setMessage] = useState("");
      
      useEffect(()=>{
        if(islogin && csrf){
            fetchInvites();
        }
        if(islogin == false){
          updateWelcomeLoginModalHandler({welcomeloginmodal:true});
        }
    },[islogin,csrf]);

    const fetchInvites = () => {
      let query = `query MyQuery {
        allInvites(tournamentSlug: "${hackathonSlug}") {
          invitedById
          id
          status
          team {
            name
            tournament {
              title
            }
          }
        }
      }`;

      getQueryDataElevateUser(query,"newcompetition","True",csrf)
      .then((data)=>{
          setInvites(data.data.allInvites)
          console.log(data.data.allInvites);
      })
      .catch((error)=>{
          console.log(error);
      })
    }

    const handleInvite = (inviteId, status) => {
        let mutation = `
        mutation {
            updateInvite(inviteId: ${inviteId}, status:"${status}") {
                invite {
                    id
                    status
                    team {
                      name
                    }
                }
                success
            }
        }
        `
        getQueryDataElevateUser(mutation, 'newcompetition','True', csrf)
        .then((data)=>{
            if(data.errors){
              setMessage(data.errors[0].message)
              setIsError(true);
              setTimeout(() => {
                setIsError(false);
              }, 3000);
            }
            else {
              if(data.data.updateInvite.invite.status == "ACCEPTED"){
                setMessage(`Congratulation,You have successfully added to ${data.data.updateInvite.invite.team.name}`);
                setTimeout(() => {
                  window.location.href = `/datahack/contest/${hackathonSlug}/`;
                }, 3000);
              }
              else{
                setMessage(`Awh,You have rejected invite from ${data.data.updateInvite.invite.team.name}`)
              }
              setIsSuccess(true);
              setTimeout(() => {
                setIsSuccess(false);
              }, 3000);
              fetchInvites();
            }
            
        })
        .catch((error)=>{
            console.log(error);
        })
    };

    return (
      <>
        <Header />
        {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={message} />}
        <section id="inviteList" className='background-dark-primary min-vh-100 common-style'>
          <div className="container">
            <div className="row">
              <ul>
                {invites && invites.map((invite,index)=>{
                  return(
                    <li key={index} className='row align-items-center flex-wrap g-3 background-dark-tertiary rounded-bottom-4 p-4 mb-5'>
                      <div className="col-lg col-md col-sm-12">
                        <p className='fs-24 text-white fw-semibold m-0 pe-3'>{invite.username} has invited you to team “{invite.team.name}” for “{invite.team.tournament.title}” competition</p>
                      </div>
                      {invite.status == "PENDING" && <div className="col-lg-auto col-md-auto col-6">
                        <button className='btn btn-dark btn-dark-primary w-100' onClick={()=>{handleInvite(invite.id,"reject")}}>Reject</button>
                      </div>}
                      {invite.status == "PENDING" && <div className="col-lg-auto col-md-auto col-6">
                        <button className='btn btn-dark btn-dark-primary w-100' onClick={() => handleInvite(invite.id, "accept")}>Accept</button>
                      </div>}
                      {invite.status != "PENDING" && <div className="col-lg-auto col-md-auto col-12">
                        <p className={`${invite.status == "ACCEPTED"?"text-success":"text-danger"} mx-2`}>{invite.status}</p>
                      </div>}
                    </li>)
                })}
                {invites && invites.length == 0 && <p className='text-dark-tertiary text-center fs-32 text-uppercase mt-5'>Invites not Found</p>}
              </ul>
            </div>
            <a href={`/datahack/contest/${hackathonSlug}`} className='btn btn-dark btn-dark-primary mx-auto d-table mt-4'>Back to Hackthon</a>
          </div>
        </section>
        <Cookies platform={'newcompetition'}  />
        <Flashstrip />
      </>
    )
}

const mapStateToProps = (state) => ({
  csrf:state.LoginModalReducer.csrf,
  islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
  updateWelcomeLoginModalHandler:data=>{dispatch(updateWelcomeLoginModal(data))},
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteListingPage);
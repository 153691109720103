import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';
import Pagenumber from '../../../common/Page/Pagination';

const EvaluationMetric = () => {
    const [metrics, setMetrics] = useState([]);
    const [totalPages,setTotalPages] = useState(null);

    useEffect(()=>{
        const query = `query MyQuery {
            allMetrics(limit: 10, page: 1) {
              evaluationMetric {
                id
                name
                codeHandlesValidation
              }
              totalPages
            }
        }`;

        getQueryDataElevate(query, "newcompetition", "True")
        .then((data)=>{
            setMetrics(data?.data?.allMetrics.evaluationMetric);
            console.log(data);
            setTotalPages(data?.data?.allMetrics.totalPages);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const updateMetricsByPageNumber = (pagenumber)=>{
        const query = `query MyQuery {
            allMetrics(limit: 10, page: ${pagenumber}) {
              evaluationMetric {
                id
                name
                codeHandlesValidation
              }
              totalPages
            }
        }`;

        getQueryDataElevate(query, "newcompetition", "True")
        .then((data)=>{
            setMetrics(data?.data?.allMetrics.evaluationMetric);
            console.log(data);
            setTotalPages(data?.data?.allMetrics.totalPages);
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    return (
        <>
            <Header />
            <section id="metricsList" className='common-style min-vh-100 background-dark-primary'>
                <div className="container">
                    <div className="row">
                        <div className='text-end p-0'>
                            <a href={'/av-admin/datahack-admin/evaluation-metric/create-evaluation-metric'} className='btn btn-dark btn-dark-primary px-5 py-2 my-3 text-uppercase fw-bold'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                               New Evaluation Metric
                            </a>
                        </div>
                        <table className='table table-striped text-center table-dark'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {metrics && metrics.map((val) => (
                                    <tr>
                                        <td><a href={'/av-admin/datahack-admin/evaluation-metric/'+val.id} className='text-white'>{val.id}</a></td>
                                        <td><a href={'/av-admin/datahack-admin/evaluation-metric/'+val.id} className='text-white'>{val.name}</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateMetricsByPageNumber} />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default EvaluationMetric;
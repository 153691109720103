import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header'
import { getQueryDataElevate } from '../../../../utils'
import { connect } from 'react-redux';
import Pagenumber from '../../../common/Page/Pagination';

function Tournament({islogin,csrf}) {
    const [tournament,setTournament] = useState(null);
    const [totalPages,setTotalPages] = useState(null);

    useEffect(()=>{
        if(islogin){
            let query = `query MyQuery {
                allTournament(limit: 10, page: 1) {
                  totalPages
                  tournamentData{
                      id
                      title
                      updatedAt
                      createdAt
                      slug
                      tournamentType{
                      name
                    }
                  }
                }
              }`

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                setTournament(data.data.allTournament.tournamentData);
                setTotalPages(data.data.allTournament.totalPages);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])

    const updateTournamentByPageNumber = (pagenumber) => {
        let query = `query MyQuery {
            allTournament(limit: 10, page: ${pagenumber}) {
              totalPages
              tournamentData{
                  id
                  title
                  updatedAt
                  createdAt
                  slug
                    tournamentType{
                    name
                }
              }
            }
        }`;

        getQueryDataElevate(query,"newcompetition","True")
        .then((data)=>{
            setTournament(data.data.allTournament.tournamentData);
            setTotalPages(data.data.allTournament.totalPages);
        })
        .catch((error)=>{
            console.log(error);
        })
    }
  return (
    <>
        <Header />
        <section id="tournament" className='background-dark-primary min-vh-100 common-style'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/tournament/create-tournament'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Tournament
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Tournament Id</th>
                                        <th>Title</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tournament && tournament.map((obj,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/tournament/'+obj.slug}>{obj.id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/tournament/'+obj.slug}>{obj.title}</a></td>
                                                <td>{obj.createdAt}</td>
                                                <td>{obj.updatedAt}</td>
                                                <td>{obj.tournamentType.name !== "blogathon" && <a className='btn btn-dark btn-dark-primary text-white' href={'/av-admin/datahack-admin/clone-tournament/'+obj.id}>Clone</a>}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                    {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateTournamentByPageNumber} />}
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
import React, { useEffect, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import * as $ from 'jquery';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { getUTMParameters, submitRegisterationForm } from '../../../../../utils';
import { getCSRF, sendOtp, verifyOtp } from '../../../../../utils/Login';

var iti3 = null;
let interval;

export default function GenAiTwoFieldLeadModal({formname,setSuccess,setIsError,country_name,setSuccessMessage,leadFormCta,leadFormHeading,section,csrf,email,phone,otp_request_id,country_code,setEmail,setPhone,setCsrf,platformName}) {
    const [userDetails,setUserDetails] = useState({
        fullname:null,
        email:null,
        phone:'',
    })
    const [additionalDetail,setAdditionalDetail] = useState({
        experience:"0-3yrs"
    })

    useEffect(() => {
      if (email) {
        setUserDetails(prevDetails => ({
          ...prevDetails,
          email: email
        }));
      }
      if (phone) {
        setUserDetails(prevDetails => ({
          ...prevDetails,
          phone: phone
        }));
      }
      if(otp_request_id) {
        setOtpRequestId(otp_request_id);
      }
    }, [email, phone, otp_request_id]);

    // For Free Trial OTP
    const [otprequestid,setOtpRequestId] = useState('');
    const [otp,setOtp] = useState({
      digit1:'',
      digit2:'',
      digit3:'',
      digit4:'',
      digit5:'',
      digit6:''
    });
    const [resendOtpTimer,setResendOtpTimer] = useState(30);
    const [isresend,setIsResend] = useState(false);

    useEffect(()=>{
      if(otp.digit1 === '' || otp.digit2 === '' || otp.digit3 === '' || otp.digit4 === '' || otp.digit5 === '' || otp.digit6 === ''){
        $('#otpSubmitBtn').attr('disabled','disabled');
      }
      else{
        $('#otpSubmitBtn').removeAttr('disabled');
      }
    },[otp])

    useEffect(()=>{
      const inputs = document.querySelectorAll('#otp > *[id]');
      for (let i = 0; i < inputs.length; i++) { 
        inputs[i].addEventListener('keydown', function (event) {
            if (event.key === "Backspace") {
              inputs[i].value = ''; 
              if (i !== 0) inputs[i - 1].focus(); 
            } 
            else {
              if (i === inputs.length - 1 && inputs[i].value !== '') {
                return true; 
              } 
              else if (event.keyCode > 47 && event.keyCode < 58) {
                inputs[i].value = event.key; 
                if (i !== inputs.length - 1) inputs[i + 1].focus(); 
                event.preventDefault(); 
              } 
              else if (event.keyCode > 64 && event.keyCode < 91) {
                return true;
              } 
            } 
          }); 
        }
    },[])

    useEffect(()=>{
        const input = document.getElementById
        ("leadPhoneNumber");
        if(input){
          iti3 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry: country_name?country_name:"IN",
            preferredCountries: ["in", "us"],
          });
          if(country_name){
            iti3.setCountry(country_name);
          }
        }
    },[country_name])

    useEffect(()=>{
        setUserDetails({
            fullname:'',
            email:'',
            phone:'',
        });
        setAdditionalDetail({
        experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
    },[formname])

    const saveLeadForm = (e)=>{
        e.preventDefault();
        let country_code = '+'+iti3.getSelectedCountryData().dialCode;
        if(!validatedMobile(userDetails.phone)){
          $('.error').remove();
          $('#leadPhoneNumber').closest('.col-xl-12').append(`<small class="text-danger error d-block">Enter a valid phone number</small>`);
          $('#leadPhoneNumber').css('border-color','red');
          $('#leadPhoneNumber').css('color','red');
          setTimeout(()=>{
              $('#leadPhoneNumber').css('border-color','#383838');
              $('#leadPhoneNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }
        if(!validatedEmail(userDetails.email)){
          $('.error').remove();
          $('#leadEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
          $('#leadEmail').css('border-color','red');
          $('#leadEmail').css('color','red');
          setTimeout(()=>{
              $('#leadEmail').css('border-color','#383838');
              $('#leadEmail').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }
       
        let user_data = {
            "email":userDetails.email,
            "phone":userDetails.phone,
            "country_code":country_code,
        }
        let additional_detail ={
          "tnc":true,
          "is_whatsapp":true,
          "section":section,
          "page_url":window.location.href
        }
        setUserDetails({
          fullname:'',
          email:'',
          phone:'',
        });
        setAdditionalDetail({
          experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
        $('#genaiLeadModal .close').click();
        if(formname === 'projects'){
          setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if(formname === 'curriculum'){
          setSuccessMessage('Curriculum Sent. Check Your Inbox')
        }
        else if(formname === 'roadmap'){
          setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if(formname === 'instructor_profile'){
          setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if(formname === 'workshops'){
          setSuccessMessage('Details on the way! Check Your Inbox')
        }
        else if(formname === "emi"){
          // if(country_name === 'IN'){
            setSuccessMessage("Our learning counselor will contact you shortly")
          // }
          // else{
          // setSuccessMessage("Great! You're almost there. Proceed to pay")
          // }
        }
        else if(['enroll','full_payment'].includes(formname)){
          // if(country_name === 'IN'){
          //   setSuccessMessage("Our learning counselor will contact you shortly")
          // }
          // else{
          setSuccessMessage("Great! You're almost there. Proceed to pay")
          // }
        }
        setSuccess(true);
        if(['enroll','full_payment'].includes(formname)){
          if(country_name === 'IN')
          {              
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=IN129712';
          }
          else if(['US','CA'].includes(country_name)){
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP2713718';
          }
          else{
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP129712';
          }
        }
        setTimeout(()=>{
          setSuccess(false);
          setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!')
        },4000)
        if (formname !== 'free_trial') {
            submitRegisterationForm(user_data,additional_detail,platformName,formname,csrf)
          .then((data)=>{
            
          })
          .catch((error)=>{
            console.log(error)
            // setIsError(true);
            // setTimeout(()=>{
            //   setIsError(false);
            // },4000)
          })
        }
    }

    const removebackdrop = ()=>{
      $('.modal-backdrop.show').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
    }

    const genAiFreeTrialEnrollment = (tempcsrf, isVerified) =>{
      const formData = new FormData();  
      const utmParameters = getUTMParameters();

      let payload = {
          'email': userDetails.email,
          'form_type': 'free_trial',
          'platform_name': 'genaipinnacle',
          'country_code': '+' + iti3.getSelectedCountryData().dialCode,
          'phone': userDetails.phone,
          'is_whatsapp': $("#isWhatsappLoginModal").is(":checked"),
          'utm_source': utmParameters ? utmParameters.utm_source : null,
          'utm_medium': utmParameters ? utmParameters.utm_medium : null,
          'utm_campaign': utmParameters ? utmParameters.utm_campaign : null,
          'utm_term': utmParameters ? utmParameters.utm_term : null,
          'utm_content': utmParameters ? utmParameters.utm_content : null,
          'gcl_id': utmParameters ? utmParameters.gcl_id : null,
          'irclickid': utmParameters ? utmParameters.irclickid : null,
          'is_otp_verified': isVerified ? isVerified : false
      };
  
      formData.append("url","api/genai/");
      formData.append('platform', 'newmarketingpages');
      formData.append("payload", JSON.stringify(payload));
      formData.append("timeout", 100);
  
      fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
        method: 'POST',
        headers: {
            "X-CSRFToken":tempcsrf,
        },
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        body: formData,
        })
        .then((response) => {
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.response && data.response.status_code === 201 && data.response.redirect_url) {
            setSuccessMessage('Thank you for enrolling in the free trial! You will now be redirected.');
            setSuccess(true);
            setTimeout(() => {
              setSuccess(false);
              setSuccessMessage("");
            }, 4000);
            window.location.href = data.response.redirect_url;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    let sendEmail = ()=>{
        sendOtp(userDetails.email,csrf)
        .then((data)=>{
          setOtpRequestId(data.otp_request_id);
          window.$('#genaiLeadModal').modal('hide');
          window.$('#freetrialLeadModal').modal('show');

          clearInterval(interval);
          interval = setInterval(() => {
            setResendOtpTimer((prev)=>{
            if(prev === 0){
                clearInterval(interval);
                setIsResend(true);
                return 30;
            }
            return prev-1;
            });
          },1000);
          setIsResend(false);
        })
        .catch((error)=>{
          console.log(error);
        })
    }

    let verifyEmailOtp = ()=>{
        if(otp.digit1 === '' && otp.digit2 === '' && otp.digit3 === '' && otp.digit4 === '' && otp.digit5 === '' && otp.digit6 === ''){
          return false;
        }
        let otp_string = `${otp.digit1}${otp.digit2}${otp.digit3}${otp.digit4}${otp.digit5}${otp.digit6}`;
        verifyOtp(userDetails.email,otp_string,otprequestid,csrf)
        .then((data)=>{
          window.$('#freetrialLeadModal').modal('hide');
          console.log("data from otp verification api", data);
          getCSRF()
          .then((csrfData) => {
            setCsrf(csrfData);
            genAiFreeTrialEnrollment(csrfData, data.is_verified);
          });
          if(!data.is_verified){
            $('.warning').remove();
            $('#otp').parent().parent().append(`<p class="warning text-danger text-center w-100 mt-2 fs-13">${data.response}</p>`);
            setTimeout(() => {
              $('.warning').slideUp(1500,'linear',()=>{
                $('.warning').remove();
              });
            }, 3000);
          }
          setOtp({
            digit1: '',
            digit2: '',
            digit3: '',
            digit4: '',
            digit5: '',
            digit6: ''
          });
        })
        .catch((error)=>{
          console.log(error)
        })
      }
  
      let resendOtp = ()=>{
        setOtp({
          digit1:'',
          digit2:'',
          digit3:'',
          digit4:'',
          digit5:'',
          digit6:''
        });
        sendEmail();
        $('.warning').remove();
            $('#otp').parent().parent().append(`<p class="warning text-success text-center w-100 mt-2 fs-13">Otp resend successfully on ${userDetails.email}</p>`);
            setTimeout(() => {
              $('.warning').slideUp(1500,'linear',()=>{
                $('.warning').remove();
              });
        }, 3000);
        clearInterval(interval);
        interval = setInterval(() => {
          setResendOtpTimer((prev)=>{
            if(prev === 0){
              clearInterval(interval);
              setIsResend(true);
              return 30;
            }
            return prev-1;
          });
        },1000);
        setIsResend(false);
      }

    const copyPaste = (event)=>{
        let paste = (event.clipboardData || window.clipboardData).getData("text");
        if (/^\d{6}$/.test(paste)) {
          setOtp({
            digit1: paste[0],
            digit2: paste[1],
            digit3: paste[2],
            digit4: paste[3],
            digit5: paste[4],
            digit6: paste[5],
          });
          $('#otpSubmitBtn').addClass('hover');
        }
        event.preventDefault();
    }

  return (
    <>
    <div className="modal lead-modal" id='genaiLeadModal' tabIndex="-1" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>{leadFormHeading} <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-5">
                <div className="row">
                    {/* <div className="col-lg-12 col-md-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Full Name</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadFullName" placeholder="Your Full Name" autoComplete='off' value={userDetails.fullname} onChange={e=>setUserDetails((prev)=>{return{...prev,fullname:e.target.value}})} />
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-xl-12 col-lg-12 col-12 mb-3">
                        <label htmlFor="leadPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                        <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="leadPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e=>setUserDetails((prev)=>{return{...prev,phone:e.target.value}})} />
                    </div>
                    <div className="col-xl-12 col-lg-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                    </div>
                </div>
                <div className="mt-4 mb-4 d-flex flex-wrap">
                    <div className="form-check d-flex align-items-center mb-2 me-4">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>Terms & Conditions</a></label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0 p-0">
                {formname !== 'free_trial' && <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>{leadFormCta}</button>}
                {formname === 'free_trial' && <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={sendEmail}>Send OTP</button>}
            </div>
        </div>
        </div>
      </div>
      <div className="modal lead-modal" id='freetrialLeadModal' tabIndex="-1" data-bs-backdrop="static">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content background-dark-primary shadow-sm rounded-4">
              <div className="modal-header border-0 p-0">
                <h2 className='text-white fs-32'>Almost Done!<span className='text-orange d-none'>20% off</span></h2>
              </div>
              <div className="modal-body p-0 pt-5">
                  <div className="row">
                      <div className="mb-3">
                        <label htmlFor="otpLogin" className="form-label text-white fs-14">Enter the OTP sended on {userDetails.email}</label>
                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2"> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="first" maxLength="1" value={otp.digit1} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit1:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="second" maxLength="1" value={otp.digit2} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit2:e.target.value}})}} autoComplete='off'/>
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="third" maxLength="1" value={otp.digit3} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit3:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="fourth" maxLength="1" value={otp.digit4} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit4:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="fifth" maxLength="1" value={otp.digit5} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit5:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="sixth" maxLength="1" value={otp.digit6} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit6:e.target.value}})}} autoComplete='off'/> 
                        </div>
                      </div>
                  </div>
                  {isresend ? <p className='blue pointer' onClick={resendOtp}>Resend OTP</p>:<p className='text-dark-tertiary'>Resend OTP in <span className='blue'>{resendOtpTimer}s</span></p>}  
              </div>
              <div className="modal-footer border-0 p-0">
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" id='otpSubmitBtn' onClick={verifyEmailOtp}>Submit</button>
              </div>
          </div>
          </div>
      </div>
    </>
  )
}

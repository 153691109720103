import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Header from '../../../common/Header/Header';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import intlTelInput from 'intl-tel-input';

const identity_host = process.env.REACT_APP_IDENTITY_HOST;
var iti1 = null;

const BundleEnrollment = ({ islogin, csrf }) => {
    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [leadEmail, setLeadEmail] = useState("");
    const [paymentEmail, setPaymentEmail] = useState("");
    const [enrollEmail, setEnrollEmail] = useState("");
    const [program, setProgram] = useState("");
    const [startDate, setStartDate] = useState("");
    const [validity, setValidity] = useState("");
    const [paymentReceived, setPaymentReceived] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const [groupName, setGroupName] = useState("");
    const [mentorshipType, setMentorshipType] = useState("ROW");

    const resetFormFields = () => {
        setLeadEmail("");
        setEnrollEmail("");
        setPaymentEmail("");
        setValidity("");
        setPaymentReceived("");
        setStartDate("");
        setProgram("");
        setPhoneNumber("");
        setGroupName("");
        setMentorshipType("ROW");
    }

    useEffect(()=>{
        const input = document.getElementById("phoneNumber");
        if(input){
          iti1 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry:"IN",
            preferredCountries: ["in", "us"],
          });
        }
      },[])

    const handleSubmit = async (e) => {
        e.preventDefault();

        let country_code = '+'+iti1.getSelectedCountryData().dialCode;

        const formData = new FormData();
        
        formData.append("enrollment_url", program);
        formData.append("lead_email", leadEmail);
        formData.append("payment_email", paymentEmail);
        formData.append("enroll_email", enrollEmail);
        formData.append("bundle_validity", validity);
        formData.append("payment_recieved", paymentReceived);
        formData.append("phone",phoneNumber);
        formData.append("country_code",country_code);
        formData.append("mentorship_type",mentorshipType);

        if(startDate != "") {
            formData.append("start_date", startDate);
        }

        if(groupName != "") {
            formData.append("group_name", groupName);
        }

        console.log("formData", formData);
        return fetch(identity_host+"/api/auto-bundle-enrollment/", {
            method: 'POST',
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
            body: formData,
        })
        .then((response) => {
            if (!response.ok) {
                if (response.status === 403 || response.status === 401) {
                    throw new Error('You are not authorized to perform this action.');
                }
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            setIsSuccess(true); 
            setIsError(false); 
            setMessage(data);
            setTimeout(() => {
                setIsSuccess(false);
                setMessage("");
            }, 5000);
            resetFormFields();
        })
        .catch((error) => {
            setIsError(true); 
            setIsSuccess(false);
            setMessage(error.message); 
            setTimeout(() => {
                setIsError(false);
                setMessage("");
            }, 5000);
        });
    };

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Enrolled successfully"} />}
            <section id='createBundleEnrollment' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-bold text-uppercase">Bundle Enrollment Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Program <sup className='text-danger'>*</sup></label>
                                            <select
                                            name="program"
                                            className="form-select"
                                            value={program}
                                            onChange={(event) => setProgram(event.target.value)}
                                            required
                                            >
                                                <option value="">Select Program</option>
                                                <option value="https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus">Blackbelt</option>
                                                <option value="https://courses.analyticsvidhya.com/bundles/genai-pinnacle-program">GenAI</option>
                                                <option value="https://courses.analyticsvidhya.com/bundles/agentic-ai-pioneer-program">AgenticAI</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Lead Email <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="email"
                                            className='form-control'
                                            placeholder='Lead Email'
                                            value={leadEmail}
                                            onChange={(event) => setLeadEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Payment Email <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="email"
                                            className='form-control'
                                            placeholder='Payment Email'
                                            value={paymentEmail}
                                            onChange={(event) => setPaymentEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Enroll Email <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="email"
                                            className='form-control'
                                            placeholder='Enroll Email'
                                            value={enrollEmail}
                                            onChange={(event) => setEnrollEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Group Name</label>
                                            <input
                                            type="text"
                                            className='form-control'
                                            placeholder='Group Name'
                                            value={groupName}
                                            onChange={(event) => setGroupName(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date</label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='Start Date' 
                                            value={startDate}
                                            onChange={(e)=>setStartDate(e.target.value)}
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Bundle Validity <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="Bundle Validity"
                                                className="form-select"
                                                value={validity}
                                                onChange={(event) => setValidity(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Validity</option>
                                                <option value="7_days">7 Days</option>
                                                <option value="1_month">1 Month</option>
                                                <option value="full_access">Full Access</option>
                                            </select>
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Payment Received <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="Payment Received"
                                                className="form-select"
                                                value={paymentReceived}
                                                onChange={(event) => setPaymentReceived(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Payment</option>
                                                <option value="full_payment">Full Payment</option>
                                                <option value="partial_payment">Partial</option>
                                                <option value="down_payment">Down Payment</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Phone Number <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="tel"
                                            id='phoneNumber'
                                            className='form-control'
                                            placeholder='Phone Number'
                                            value={phoneNumber}
                                            onChange={(event) => setPhoneNumber(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Mentorship Type</label>
                                            <select
                                            name="program"
                                            className="form-select"
                                            value={mentorshipType}
                                            onChange={(event) => setMentorshipType(event.target.value)}
                                            required
                                            >
                                                <option value="" disabled>Select Mentorship Type</option>
                                                <option value="ROW">ROW</option>
                                                <option value="USA">USA</option>
                                            </select>
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Enroll
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
    islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(BundleEnrollment);
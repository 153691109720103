import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';

const DatahourResource = () => {

    const [resources, setResources] = useState([]);

    useEffect(() => {
        const query = `
        query MyQuery {
            datahourResources {
                thumbnail
                modifiedAt
                createdAt
                file
                fileUrl
                id
                link
                resourceType
                session {
                    sessionName
                }
                title
                thumbnailUrl
            }
        }`

        getQueryDataElevate(query, "newevents", "True")
            .then((data) => {
                console.log(data.data.datahourResources);
                setResources(data?.data?.datahourResources);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    return (
        <>
            <Header />
            <section id="speakerList" className='common-style min-vh-100 background-dark-primary'>
                <div className="container-fluid">
                    <div className="row mx-5">
                        <div className='text-end p-0'>
                            <a href={'/av-admin/datahack-admin/datahour-resources/create-datahour-resources'} className='btn btn-dark btn-dark-primary px-5 py-2 my-3 text-uppercase fw-bold'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                                New Datahour Resource
                            </a>
                        </div>
                        <table className='table table-striped text-center table-dark'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Datahour Name</th>
                                    <th>Title</th>
                                    <th>Resource Type</th>
                                    <th>Modified At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {resources && resources.map((val) => (
                                    <tr>
                                        <td><a href={'/av-admin/datahack-admin/datahour-resources/'+val.id} className='text-white'>{val.id}</a></td>
                                        <td><a href={'/av-admin/datahack-admin/datahour-resources/'+val.id} className='text-white'>{val.session && val.session.sessionName}</a></td>
                                        <td>{val.title}</td>
                                        <td>{val.resourceType}</td>
                                        <td>{val.modifiedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DatahourResource;
import { FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_COMPETITION_CONTENT_SUCCESS, FETCH_COMPETITION_LIST_CONTENT_SUCCESS, FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS, FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS, FETCH_BLOGATHON_LIST_CONTENT_SUCCESS, FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    competitioncontent: null,
    competitionList:null,
    blogathonList:null,
    activeBlogathonTournament:null,
    closedBlogathonTournament:null,
    activeHackathonTournament:null,
    closedHackathonTournament:null,
    lateSubmissionHackthonTournament:null,
    closedJobathonTournament:null,
    competitiondetail:null,
};
  
export const competitionContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMPETITION_CONTENT_SUCCESS:
            return {
                ...state,
                competitioncontent: action.payload,
            };
        case FETCH_COMPETITION_LIST_CONTENT_SUCCESS:
            return {
                ...state,
                competitionList: action.payload,
                activeHackathonTournament:action.payload.activeHackathonTournament,
                closedHackathonTournament: action.payload.closedHackathonTournament,
                lateSubmissionHackthonTournament: action.payload.lateSubmissionHackthonTournament,
            };
        case FETCH_BLOGATHON_LIST_CONTENT_SUCCESS:
            return {
                ...state,
                blogathonList: action.payload,
                activeBlogathonTournament: action.payload.activeBlogathonTournament,
                closedBlogathonTournament: action.payload.closedBlogathonTournament,
            };
        case FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                closedBlogathonTournament: action.payload.closedBlogathonTournament,
            };
        case FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                activeHackathonTournament: action.payload.activeHackathonTournament,
            };
        case FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                closedHackathonTournament: action.payload.closedHackathonTournament,
            };
        case FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                lateSubmissionHackthonTournament: action.payload.lateSubmissionHackthonTournament,
            };
        case FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                closedJobathonTournament: action.payload.closedJobathonTournament,
            };
        case FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                competitiondetail: action.payload,
            };
        default:
            return state;
    }
};

import React from 'react';

export default function Error403() {
  return (
    <section id='error' className='vh-100 background-dark-primary'>
      <div className="container d-flex align-items-center h-100 justify-content-center">
        <div className="row col-lg-4 col-12">
            <h1 class='text-white text-center m-0 fw-bold'>403</h1>
            <h2 class='text-white text-center m-0 fw-semibold mb-2'>Access Denied</h2>
            <p class='text-white text-center m-0 mb-5'>You do not have permission to access this page or you are not logged in</p>
            <a class='btn btn-dark btn-dark-primary w-auto mx-auto' href='/'>Back to Homepage</a>
        </div>
      </div>
    </section>
  )
}

import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header'
import { connect } from 'react-redux';
import { getQueryDataElevate } from '../../../../utils';
import Pagenumber from '../../../common/Page/Pagination';


function Stage({islogin,csrf}) {
    const [stages,setStages] = useState(null);
    const [totalPages,setTotalPages] = useState(null);

    useEffect(()=>{
        if(islogin){
            let query = `
            query MyQuery {
              allStage(limit: 10, page: 1) {
                totalPages
                stageData {
                  id
                  slug
                  updatedAt
                  createdAt
                  title
                  stageType {
                    name
                  }
                }
              }
            }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data.allStage.stageData);
                setStages(data.data.allStage.stageData);
                setTotalPages(data.data.allStage.totalPages);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])

    const createPrivateLeaderboard = (stage_id) =>{
        let query = `mutation {
            generatePrivateLeaderboard(stageId:${stage_id}) {
              success
              message
            }
          }
          `;
          var formData = new FormData();
          formData.append('query',query);
          formData.append('source', "newcompetition");
          formData.append('forward_user_data', "True");
      
          fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL,{
            method:"POST",
            body:formData,
            headers:{
              "X-CSRFToken":csrf
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
          }) 
          .then(response => response.json())
          .then((data)=>{
            if(!data.error){
              if(data.data.generatePrivateLeaderboard.success){
                alert('private leaderboard created successfully');
              }
              else{
                alert('private leaderboard not created');
              }
            }
            else{
              console.log(data.error[0].message);
            }
          })
          .catch((error)=>{
            console.log(error.message);
          })
    }

    const updateStageByPageNumber = (pagenumber) => {
      let query = `
            query MyQuery {
              allStage(limit: 10, page: ${pagenumber}) {
                totalPages
                stageData {
                  id
                  slug
                  updatedAt
                  createdAt
                  title
                  stageType {
                    name
                  }
                }
              }
          }`;

          getQueryDataElevate(query,"newcompetition","True")
          .then((data)=>{
              console.log(data.data.allStage.stageData);
              setStages(data.data.allStage.stageData);
              setTotalPages(data.data.allStage.totalPages);
          })
          .catch((error)=>{
              console.log(error);
          })
  }

  return (
    <>
        <Header />
        <section id="updateProblemStatement" className='common-style min-vh-100 background-dark-primary'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/stage/create-stage'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Stage
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Stage Id</th>
                                        <th>Title</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                        <th>Create Private Leaderboard</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stages && stages.map((stage)=>{
                                        return(
                                            <tr>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/stage/'+stage.slug}>{stage.id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/stage/'+stage.slug}>{stage.title}</a></td>
                                                <td>{stage.createdAt}</td>
                                                <td>{stage.updatedAt}</td>
                                                <td>
                                                  {stage.stageType.name === "practice" ? <button className='btn btn-dark btn-dark-primary' onClick={()=>{createPrivateLeaderboard(stage.id)}}>Create</button>:"NA"}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                    {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateStageByPageNumber} />}
                </div>
            </div>
        </section>
    </>
  )
}


const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Stage);
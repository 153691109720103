import intlTelInput from 'intl-tel-input';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { validateWorkEmail, validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { submitRegisterationForm } from '../../../../../utils';
import LoginModal from '../../../../common/Login/LoginModal';
import { connect } from 'react-redux';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';


var iti1 = null;

function Modal({csrf,platform,formtype,section,heading,buttonTxt,message}) {
    const [userDetails,setUserDetails] = useState({
        fullname:'',
        phone:'',
        email:''
    });
    const [additionalDetail,setAdditionalDetail] = useState({
        organization:'',
        designation:'',
        is_whatsapp:true
    });
    const [success,setSuccess] = useState(false);


  useEffect(()=>{
    const input = document.getElementById("enterprisePhoneNumber");
    if(input){
      iti1 = intlTelInput(input, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry:"IN",
        preferredCountries: ["in", "us"],
      });
    //   if(country_name){
    //     iti1.setCountry(country_name);
    //   }
    }
  },[]);


  const saveContactForm = (e)=>{
    e.preventDefault();
    let country_code = '+'+iti1.getSelectedCountryData().dialCode;

    if(userDetails.fullname === ''){
        $('.error').remove();
        $('#enterpriseFullname').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
        $('#enterpriseFullname').css('border-color','red');
        $('#enterpriseFullname').css('color','red');
        setTimeout(()=>{
            $('#enterpriseFullname').css('border-color','#383838');
            $('#enterpriseFullname').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }

    if(!validatedMobile(userDetails.phone)){
      $('.error').remove();
      $('#enterprisePhoneNumber').closest('.mb-3').append(`<small class="text-danger error d-block">Enter a Valid Phone Number</small>`);
      $('#enterprisePhoneNumber').css('border-color','red');
      $('#enterprisePhoneNumber').css('color','red');
      setTimeout(()=>{
          $('#enterprisePhoneNumber').css('border-color','#383838');
          $('#enterprisePhoneNumber').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
    }

    if(!validatedEmail(userDetails.email)){
      $('.error').remove();
      $('#enterpriseWorkEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
      $('#enterpriseWorkEmail').css('border-color','red');
      $('#enterpriseWorkEmail').css('color','red');
      setTimeout(()=>{
          $('#enterpriseWorkEmail').css('border-color','#383838');
          $('#enterpriseWorkEmail').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
    }

    if(!validateWorkEmail(userDetails.email)){
      $('.error').remove();
      $('#enterpriseWorkEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Work Email Id</small>`);
      $('#enterpriseWorkEmail').css('border-color','red');
      $('#enterpriseWorkEmail').css('color','red');
      setTimeout(()=>{
          $('#enterpriseWorkEmail').css('border-color','#383838');
          $('#enterpriseWorkEmail').css('color','white');
          $('.error').slideUp();
          $('.error').remove();
      },2000)
      return false;
    }

    if(additionalDetail.organization === ''){
        $('.error').remove();
        $('#enterpriseOrganizationName').parent().append(`<small class="text-danger error d-block">Please Enter Your Oraganization Name</small>`);
        $('#enterpriseOrganizationName').css('border-color','red');
        $('#enterpriseOrganizationName').css('color','red');
        setTimeout(()=>{
            $('#enterpriseOrganizationName').css('border-color','#383838');
            $('#enterpriseOrganizationName').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }

    if(additionalDetail.designation === ''){
        $('.error').remove();
        $('#enterpriseDesignation').parent().append(`<small class="text-danger error d-block">Please Enter Your Designation</small>`);
        $('#enterpriseDesignation').css('border-color','red');
        $('#enterpriseDesignation').css('color','red');
        setTimeout(()=>{
            $('#enterpriseDesignation').css('border-color','#383838');
            $('#enterpriseDesignation').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
   
    let user_data = {
        "fullname":userDetails.fullname,
        "email":userDetails.email,
        "phone":userDetails.phone,
        "country_code":country_code,
    }
    let additional_detail ={
      "organization":additionalDetail.organization,
      "designation":additionalDetail.designation,
      "tnc":true,
      "is_whatsapp":additionalDetail.is_whatsapp,
      "page_url":window.location.href,
      "section":section
    }

    setUserDetails({
      fullname:'',
      email:'',
      phone:'',
    });
    setAdditionalDetail({
        organization:'',
        designation:'',
        is_whatsapp:true
    })
    window.$('#b2bModal').modal('hide');
    setSuccess(true);
    setTimeout(()=>{
      setSuccess(false);
    },4000)
    console.log(user_data,additional_detail,platform,formtype)
    submitRegisterationForm(user_data,additional_detail,platform,formtype,csrf)
    .then((data)=>{
    })
    .catch((error)=>{
      console.log(error)
      // setIsError(true);
      // setTimeout(()=>{
      //   setIsError(false);
      // },4000)
    })
  }

  const emptyFormFields = () => {
    userDetails.fullname=''
    userDetails.phone=''
    userDetails.email=''
    additionalDetail.organization=''
    additionalDetail.designation=''
  }

  return (
  <>
    <LoginModal />
    {success && <SuccessAlert message={message} />}
    <div className="modal lead-modal" data-bs-backdrop="static" id='b2bModal' tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content background-dark-primary shadow-sm rounded-4">
                <div className="modal-header border-0 p-0">
                    <h2 className='text-white fs-32'>{heading}</h2>
                    <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>emptyFormFields()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </button>
                </div>
                <div className="modal-body p-0 pt-4 pb-4">
                    <div class="mb-3">
                        <label for="enterpriseFullname" class="form-label text-white fs-14 fw-normal">Full Name</label>
                        <input type="text" class="form-control background-dark-tertiary text-white" id="enterpriseFullname" placeholder="Enter Your Full Name" value={userDetails.fullname} onChange={(e)=>{setUserDetails((prev)=>{return({...prev,fullname:e.target.value})})}}/>
                    </div>
                    <div class="mb-3">
                        <label for="enterprisePhoneNumber" class="form-label text-white fs-14 fw-normal">Phone Number</label>
                        <input type="tel" class="form-control background-dark-tertiary text-white" id="enterprisePhoneNumber" placeholder="Enter your phone number" value={userDetails.phone} onChange={(e)=>{setUserDetails((prev)=>{return({...prev,phone:e.target.value})})}}/>
                    </div>
                    <div class="mb-3">
                        <label for="enterpriseWorkEmail" class="form-label text-white fs-14 fw-normal">Work Email</label>
                        <input type="email" class="form-control background-dark-tertiary text-white" id="enterpriseWorkEmail" placeholder="Enter your work email" value={userDetails.email} onChange={(e)=>{setUserDetails((prev)=>{return({...prev,email:e.target.value})})}}/>
                    </div>
                    <div class="mb-3">
                        <label for="enterpriseOrganizationName" class="form-label text-white fs-14 fw-normal">Organization Name</label>
                        <input type="text" class="form-control background-dark-tertiary text-white" id="enterpriseOrganizationName" placeholder="Enter your organization name" value={additionalDetail.organization} onChange={(e)=>{setAdditionalDetail((prev)=>{return({...prev,organization:e.target.value})})}}/>
                    </div>
                    <div class="mb-3">
                        <label for="enterpriseDesignation" class="form-label text-white fs-14 fw-normal">Designation</label>
                        <input type="text" class="form-control background-dark-tertiary text-white" id="enterpriseDesignation" placeholder="Enter your designation" value={additionalDetail.designation} onChange={(e)=>{setAdditionalDetail((prev)=>{return({...prev,designation:e.target.value})})}}/>
                    </div>
                    <div className="mt-4 mb-4 d-flex flex-wrap">
                        <div className="form-check d-flex align-items-center mb-3 me-3">
                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionModal" defaultChecked disabled />
                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary'>Terms & Conditions</a>{" "}</label>
                        </div>
                        <div className="form-check d-flex align-items-center mb-3">
                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isAutoRegisterModal" checked={additionalDetail.is_whatsapp} onChange={(e)=>{setAdditionalDetail((prev)=>{return({...prev,is_whatsapp:e.target.checked})})}}/>
                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isAutoRegisterModal">Send me updates on Whatsapp</label>
                        </div>
                    </div>
                </div>
                <div className="modal-footer border-0 p-0">
                    <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveContactForm}>{buttonTxt}</button>
                </div>
            </div>
        </div>
    </div>
    <div className="modal lead-modal" data-bs-backdrop="static" id='b2bSuccessModal' tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close ms-auto" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body pt-0">
                <div className="check-icon mx-auto d-table mb-4 p-3 rounded-circle background-dark-tertiary">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path d="M33.3346 11.6665L16.1798 28.8213C15.5289 29.4722 14.4737 29.4722 13.8228 28.8213L6.66797 21.6665" stroke="white" stroke-width="5" stroke-linecap="round"/>
                </svg>
                </div>
                <h4 className="fs-24 fw-semibold text-white text-center mb-3">Details are submitted</h4>
                <p className="fs-14 fw-normal text-dark-secondary text-center mb-3"></p>
            </div>
            <div className="modal-footer border-0 p-0">
                <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" data-bs-dismiss="modal">Okay</button>
            </div>
        </div>
        </div>
      </div>
  </>
  )
}



const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
  });

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
import React, { useEffect, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import * as $ from 'jquery';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { submitRegisterationForm } from '../../../../../utils';

var iti3 = null;

export default function BlackBeltSwiper1({formname,setSuccess,setIsError,country_name,setSuccessMessage,leadFormCta,leadFormHeading,section,csrf,platformName}) {
    const [userDetails,setUserDetails] = useState({
        fullname:null,
        email:null,
        phone:'',
    })
    const [additionalDetail,setAdditionalDetail] = useState({
        experience:"0-3yrs"
    })

    useEffect(()=>{
        const input = document.getElementById("leadPhoneNumber");
        if(input){
          iti3 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry: country_name?country_name:"IN",
            preferredCountries: ["in", "us"],
          });
          if(country_name){
            iti3.setCountry(country_name);
          }
        }
    },[country_name])

    useEffect(()=>{
        setUserDetails({
            fullname:'',
            email:'',
            phone:'',
        });
        setAdditionalDetail({
        experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
    },[formname])

    const saveLeadForm = (e)=>{
        e.preventDefault();
        let country_code = '+'+iti3.getSelectedCountryData().dialCode;
        // if(userDetails.fullname === ''){
        //     $('.error').remove();
        //     $('#leadFullName').parent().append(`<small class="text-danger error d-block">Enter your fullname</small>`);
        //     $('#leadFullName').css('border-color','red');
        //     $('#leadFullName').css('color','red');
        //     setTimeout(()=>{
        //         $('#leadFullName').css('border-color','#383838');
        //         $('#leadFullName').css('color','white');
        //         $('.error').slideUp();
        //         $('.error').remove();
        //     },2000)
        //     return false;
        // }
        if(!validatedMobile(userDetails.phone)){
          $('.error').remove();
          $('#leadPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a Valid Phone Number</small>`);
          $('#leadPhoneNumber').css('border-color','red');
          $('#leadPhoneNumber').css('color','red');
          setTimeout(()=>{
              $('#leadPhoneNumber').css('border-color','#383838');
              $('#leadPhoneNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }
        if(!validatedEmail(userDetails.email)){
          $('.error').remove();
          $('#leadEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
          $('#leadEmail').css('border-color','red');
          $('#leadEmail').css('color','red');
          setTimeout(()=>{
              $('#leadEmail').css('border-color','#383838');
              $('#leadEmail').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
      }
       
        let user_data = {
            "fullname":userDetails.fullname,
            "email":userDetails.email,
            "phone":userDetails.phone,
            "country_code":country_code,
        }
        let additional_detail ={
          "tnc":true,
          "is_whatsapp":true,
          "section":section,
          "page_url":window.location.href
        }
        setUserDetails({
          fullname:'',
          email:'',
          phone:'',
        });
        setAdditionalDetail({
          experience:"0-3yrs"
        })
        $('#checkbox1').prop('checked',true);
        $('#blackbeltLeadModal .close').click();
        if(formname === 'projects'){
          setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if(formname === 'curriculum'){
          setSuccessMessage('Curriculum Sent. Check Your Inbox')
        }
        else if(formname === 'roadmap'){
          setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if(formname === 'instructor_profile'){
          setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if(['enroll','full_payment','emi'].includes(formname)){
          // if(country_name === 'IN'){
          //   setSuccessMessage("Our learning counselor will contact you shortly")
          // }
          // else{
          setSuccessMessage("Great! You're almost there. Proceed to pay")
          // }
        }
        if(['enroll','full_payment','emi'].includes(formname)){
          if(country_name === 'IN')
          {
            if(formname === 'emi'){
              window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=IN2249690';
            }
            else{                
              window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=IN48808&utm-source=blackbelt-landing-page&utm-medium=landing-page';
            }
          }
          else if(['US','CA'].includes(country_name)){
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP2132411&utm-source=blackbelt-landing-page&utm-medium=landing-page';
          }
          else{
            window.location.href = 'https://id.analyticsvidhya.com/ecommerce/basket/add?sku=PP48808&utm-source=blackbelt-landing-page&utm-medium=landing-page';
          }
        }
        setSuccess(true);
        setTimeout(()=>{
          setSuccess(false);
          setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!')
        },4000)
        submitRegisterationForm(user_data,additional_detail,platformName,formname,csrf)
        .then((data)=>{
          
        })
        .catch((error)=>{
          console.log(error)
          // setIsError(true);
          // setTimeout(()=>{
          //   setIsError(false);
          // },4000)
        })
    }

    const updateExperience = (value)=>{
        setAdditionalDetail((prev)=>{return({...prev,experience:value})})
    }

    const removebackdrop = ()=>{
      $('.modal-backdrop.show').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
    }

  return (
    <>
    <div className="modal lead-modal" data-bs-backdrop="static" id='blackbeltLeadModal' tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>{leadFormHeading} <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-5">
                <div className="row">
                    {/* <div className="col-lg col-md-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Full name</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadFullName" placeholder="Enter your Full name" autoComplete='off' value={userDetails.fullname} onChange={e=>setUserDetails((prev)=>{return{...prev,fullname:e.target.value}})} />
                    </div> */}
                    <div className="col-lg col-md-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Phone Number</label>
                        <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="leadPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e=>setUserDetails((prev)=>{return{...prev,phone:e.target.value}})} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg col-md-12 col-12 mb-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                        <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                    </div>
                    {/* <div className="col-lg col-md-12 col-12 my-3">
                        <label htmlFor="leadFullName" className="form-label text-white fs-14">Experience :</label>
                        <div className="experience-tag">
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox1"} name="experience" onChange={()=>{updateExperience("0-3yrs")}} defaultChecked />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox1"}>0-3yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox2"} name="experience" onChange={()=>{updateExperience("3-8yrs")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox2"}>3-8yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox3"} name="experience" onChange={()=>{updateExperience("8-12yrs")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox3"}>8-12yrs</label>
                            <input type='radio' className="form-check-input me-2 d-none" id={"checkbox4"} name="experience" onChange={()=>{updateExperience("12yrs+")}} />
                            <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox4"}>12yrs+</label>
                        </div>
                    </div> */}
                </div>
                <div className="mt-4 mb-4 d-flex flex-wrap">
                    <div className="form-check d-flex align-items-center mb-2 me-4">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>terms & conditions</a></label>
                    </div>
                    <div className="form-check d-flex align-items-center mb-2">
                        <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                        <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0 p-0">
                <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>{leadFormCta}</button>
            </div>
        </div>
        </div>
      </div>
    </>
  )
}

import { FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    genaileadpagescontent: null,
};
  
export const genaiLeadPagesContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS:
            return {
                ...state,
                genaileadpagescontent: JSON.parse(action.payload.data.getPlatformJsonDataByName.platformData),
            };
        default:
            return state;
    }
};
  
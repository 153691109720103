import React from 'react';
import { Route, Routes } from 'react-router-dom';
import BlackbeltHome from '../Blackbelt/pages';
import FreeTrialBlackbelt from '../Blackbelt/pages/FreeTrialBlackbelt';
import BlackbeltLeadPagesOneField from '../Blackbelt/pages/BlackbeltLeadPagesOneField';

export default function BlackbeltRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<BlackbeltHome />} />
        <Route path='/free-trial' exact element={<FreeTrialBlackbelt />} />
        <Route path='/:platformName' exact element={<BlackbeltLeadPagesOneField />} />
    </Routes>
  )
}

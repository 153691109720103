import { FETCH_HOMEPAGE_CONTENT_REQUEST, FETCH_HOMEPAGE_CONTENT_SUCCESS, FETCH_HOMEPAGE_CONTENT_FAILURE } from '../Contants/index';
  
const initialState = {
    homepagecontent: null,
    loading: false,
    error: null,
    country_code:null
};
  
export const homepageContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HOMEPAGE_CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error:null,
                country_code:null
            };
        case FETCH_HOMEPAGE_CONTENT_SUCCESS:
            return {
                ...state,
                homepagecontent: JSON.parse(action.payload.data.getPlatformJsonDataByName.platformData),
                loading: false,
                error: null,
                country_code:action.payload.country_code
            };
        case FETCH_HOMEPAGE_CONTENT_FAILURE:
        return {
            ...state,
            homepagecontent: null,
            loading: false,
            error: action.payload,
        };
        default:
            return state;
    }
};
  
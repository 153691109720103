import React, { useEffect } from 'react';
import $ from 'jquery';
import Player from '@vimeo/player';

export default function VideoModal({ videoUrl }) {

    useEffect(() => {
        // if (videoUrl) {
        //     const iframe = $('#modalDynamicIframe')[0];
        //     iframe.src = '';
        //     setTimeout(() => {
        //         iframe.src = videoUrl + (videoUrl.includes('?') ? '&' : '?') + 'autoplay=1&mute=1';
        //     }, 50);
        // }
    }, [videoUrl]);

    useEffect(()=>{
        if(videoUrl){
            var player = new Player($("#modalDynamicIframe")[0]);

            window.$('#dynamicVideoModal').on('hidden.bs.modal', function () {
                    player.pause();
            })

            window.$('#dynamicVideoModal').on('shown.bs.modal', function () {
                setTimeout(() => {
                    player.play();
                }, 400);
            })
        }
    },[videoUrl])


    return (
        <>
            <div className="modal fade video-modal" id="dynamicVideoModal" tabIndex="-1" aria-labelledby="dynamicVideoModalLabel" aria-hidden="true">
                <button type="button" className="btn-close btn-close-white fs-4 p-3 position-absolute z-3 end-0" data-bs-dismiss="modal" aria-label="Close"></button>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="ratio ratio-16x9">
                                {videoUrl && <iframe src={videoUrl} id="modalDynamicIframe" title="Dynamic Video" allow="autoplay"></iframe>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryData } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import $ from 'jquery';

const GenerateBlogathonCSV = ({ csrf }) => {
    const [tournamentData, setTournamentData] = useState(null);
    const [tournamentId, setTournamentId] = useState(null);
    const [selectedTournamentTitle, setSelectedTournamentTitle] = useState("");

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        let query = `query MyQuery {
            allTournament(limit: 100000, page: 1) {
                tournamentData {
                    id
                    title
                    tournamentType{
                        name
                    }
                }
            }
        }`;
        getQueryData(query, "newcompetition")
            .then(data => {
                setTournamentData(data?.data?.allTournament.tournamentData);
            })
            .catch(error => {
                console.error(error);
            })
    }, [])

    const resetFormFields = () => {
        setSelectedTournamentTitle("");
        setTournamentId(null);
        document.getElementById("tournamentIdValue").value = null;
    }

    const handleTournamentChange = (event) => {
        if (tournamentData.some((tournament) => {
            if (tournament.id === event.target.value) {
                setSelectedTournamentTitle(tournament.title);
            }
            return tournament.id === event.target.value;
        })) {
            setTournamentId(event.target.value);
        } else {
            setTournamentId(null);
            setSelectedTournamentTitle("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        $('#csvGenerateBtn').addClass('d-none');
        $('#generatingBtn').removeClass('d-none');

        const formData = new FormData();
        formData.append("platform", 'newcompetition');
        formData.append("method", 'GET');
        formData.append("forward_user_data", "True");
        formData.append("url", 'download-blogathon-csv/?tournament_id='+tournamentId);
        formData.append("timeout", 100);
    
        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
        method: 'POST',
        body:formData,
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        headers: {
            "X-CSRFToken":csrf,
        }
        })
        .then(response => {
            $('#csvGenerateBtn').removeClass('d-none');
            $('#generatingBtn').addClass('d-none');
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result)=> {
            if(result?.response?.url){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();

                const link = document.createElement('a');
                link.href = result?.response?.url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }   
            else {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            console.log('error', error);
            $('#csvGenerateBtn').removeClass('d-none');
            $('#generatingBtn').addClass('d-none');
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
        });
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Error occured."} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"CSV Generated Successfully."} />}
            <section id='privateRegistration' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-3 text-center text-white fw-semibold text-uppercase">Generate Blogathon CSV</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <span className=" text-white me-2">Select Tournament <sup className='text-danger'>*</sup></span>
                                            <input id="tournamentIdValue" list="tournaments" className='form-control' onChange={handleTournamentChange} placeholder="Select a Tournament" required autoComplete='off'/>
                                            <datalist id="tournaments">
                                                {tournamentData && tournamentData.map((tournament) => (<>
                                                    {tournament?.tournamentType?.name === "blogathon" && <option key={tournament.id} value={tournament.id}>
                                                        {tournament.title}
                                                    </option>}
                                                </>
                                                ))}
                                            </datalist>
                                            {selectedTournamentTitle && <p className="text-white pt-2">Selected Tournament Name: <strong>{selectedTournamentTitle}</strong></p>}
                                        </div>

                                        <button type="submit" id='csvGenerateBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Generate CSV
                                        </button>
                                        <button id='generatingBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100 d-none' disabled>
                                            Generating CSV...
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateBlogathonCSV);
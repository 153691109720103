import React, { useState, useEffect } from 'react';

const Timer = ({ startDate , message }) => {
    const [timeRemaining, setTimeRemaining] = useState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    });

    const calculateTimeRemaining = () => {
        const now = new Date().getTime();
        const startTime = new Date(startDate).getTime();
        const timeDiff = startTime - now;

        if (timeDiff <= 0) {
            setTimeRemaining(null);
        } else {
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

            setTimeRemaining({
                days,
                hours,
                minutes,
                seconds,
            });
        }
    };


    useEffect(() => {
        calculateTimeRemaining();

        const timer = setInterval(() => {
            calculateTimeRemaining();
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [startDate]);

    if (timeRemaining === null) {
        return (
            <div className="countdown-timer">
                <p className="fs-20 text-white mb-0">
                    <span className='background-dark-tertiary p-1 rounded-1'>00</span> :
                    <span className='background-dark-tertiary p-1 rounded-1 ms-1'>00</span> :
                    <span className='background-dark-tertiary p-1 rounded-1 ms-1'>00</span> :
                    <span className='background-dark-tertiary p-1 rounded-1 ms-1'>00</span>
                </p>
                {message && <p className='fs-14 text-dark-tertiary'>{message}</p>}
            </div>
        );
    }

    return (
        <div className="countdown-timer">
            <p className="fs-20 text-white mb-0">
                <span className='background-dark-tertiary p-1 rounded-1'>{(timeRemaining.days/10 >= 1)?timeRemaining.days:"0"+timeRemaining.days}</span> : 
                <span className='background-dark-tertiary p-1 rounded-1 ms-1'>{timeRemaining.hours/10>=1?timeRemaining.hours:"0"+timeRemaining.hours}</span> : 
                <span className='background-dark-tertiary p-1 rounded-1 ms-1'>{timeRemaining.minutes/10>=1?timeRemaining.minutes:"0"+timeRemaining.minutes}</span> : 
                <span className='background-dark-tertiary p-1 rounded-1 ms-1'>{timeRemaining.seconds/10>=1?timeRemaining.seconds:"0"+timeRemaining.seconds}</span>
            </p>
            {message && <p className='fs-14 text-dark-tertiary'>{message}</p>}
        </div>
    );
};

export default Timer;

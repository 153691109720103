import React from 'react'

export default function ErrorAlert({message,setIsError}) {
  return (
    <div id='errorAlert' className="toast text-white background-dark-tertiary border-0 show position-fixed z-3 rounded-2 p-4 row" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex align-items-center col">
            <div className="error-icon rounded-circle p-2 me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="#F04438" strokeWidth="2" strokeLinecap="round"/>
                </svg>
            </div>
            <div className="toast-body fs-18 p-0">{message}</div>
        </div>
        <button type="button" className="btn-close btn-close-white m-0 me-2 col-auto ms-2" onClick={()=>{setIsError(false)}}></button>
    </div>
  )
}

import { getUTMParameters } from './index';
let new_identity_csrf = process.env.REACT_APP_NEW_IDENTITY_CSRF;
let new_identity_session = process.env.REACT_APP_NEW_IDENTITY_SESSION;
let new_identity_send_otp = process.env.REACT_APP_NEW_IDENTITY_SEND_OTP;
let new_identity_verify_otp = process.env.REACT_APP_NEW_IDENTITY_VERIFY_OTP
let new_identity_logout = process.env.REACT_APP_NEW_IDENTITY_LOGOUT;

if(typeof window !== 'undefined' && window.location.host == "elevate.analyticsvidhya.com"){
  new_identity_csrf = 'https://elevate.analyticsvidhya.com/api/auth/csrf/';
  new_identity_session = 'https://elevate.analyticsvidhya.com/api/auth/session/';
  new_identity_send_otp = 'https://elevate.analyticsvidhya.com/api/auth/otp-send/';
  new_identity_verify_otp = 'https://elevate.analyticsvidhya.com/api/auth/verify/';
  new_identity_logout = 'https://elevate.analyticsvidhya.com/api/auth/logout/';
}

export const getCSRF = () => {
    return fetch(new_identity_csrf, {
      credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
    })
    .then((res) => {
      return res.headers.get("X-CSRFToken");
    })
    .catch((err) => {
      return err;
    });
}

export const getSession = () => {
    return fetch(new_identity_session, {
      credentials:process.env.REACT_APP_CREDENTIAL_TYPE,
    })
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return err;
    });
}

export const openGoogleLoginPage = (redirect_url) => {
    const googleAuthUrl = process.env.REACT_APP_GOOGLE_AUTH_URL;  
    let next_url = null;
    if(redirect_url){
      next_url = redirect_url
    }
    else{
      next_url = window.location.href;
    }
    let source_name = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1]:"homepage";
    let utm_params = getUTMParameters();
    let utm_params_object = {};
    if(utm_params){
      for (var key in utm_params) {
        if (utm_params.hasOwnProperty(key)) {
            if(utm_params[key]){
              utm_params_object[key] = utm_params[key];
            }
        }
      }
    }
    const params = {
      next  : next_url,
      source_url : window.location.href,
      source_name : source_name,
      ...utm_params_object
    };
    const urlParams = new URLSearchParams(params).toString();
  
    window.location = `${googleAuthUrl}?${urlParams}`;
};

export const sendOtp = (email,csrfToken)=>{
    const formData = new FormData();
    formData.append('email', email);
    return fetch(new_identity_send_otp, {
    method: 'POST',
    headers: {
        "X-CSRFToken":csrfToken,
    },
    credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
    body: formData,
    })
    .then((response) => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json(); // Parse the response as JSON
    })
    .then((data) => {
      return data
    })
    .catch((error) => {
      return error;
    });
}

export const verifyOtp = (email,otp,otpRequestId,csrfToken,enrollment_url,next_url)=>{
  const formData = new FormData();
  formData.append('email', email);
  formData.append('entered_otp', otp);
  formData.append('otp_request_id', otpRequestId);
  formData.append('next', next_url?next_url:"");
  formData.append('source_url', window.location.href);
  
  let source_name = window.location.pathname.split('/')[1] ? window.location.pathname.split('/')[1]:"homepage";
  let utm_params = getUTMParameters();
  formData.append('source_name', source_name);

  if(utm_params){
    for (var key in utm_params) {
      if (utm_params.hasOwnProperty(key)) {
          if(utm_params[key]){
            formData.append(key, utm_params[key]);
          }
      }
    }
  }

  if(enrollment_url){
    formData.append('enrollment_url', enrollment_url);
  }
  return fetch(new_identity_verify_otp, {
  method: 'POST',
  headers: {
      "X-CSRFToken":csrfToken,
  },
  credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
  body: formData,
  })
  .then((response) => {
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response as JSON
  })
  .then((data) => {
    return data;
  })
  .catch((error) => {
    return error;
  });
}


export const logout = (csrfToken)=>{
  return fetch(new_identity_logout, {
  method: 'GET',
  headers: {
      "X-CSRFToken":csrfToken,
  },
  credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
  })
  .then((response) => {
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      return response.json(); // Parse the response as JSON
  })
  .then((data) => {
    return data;
  })
  .catch((error) => {
    return error;
  });
}
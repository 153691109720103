import React, { useEffect, useState } from "react";
import { getQueryData } from "../../../../utils";
import Header from "../../../common/Header/Header";
import { Editor } from '@tinymce/tinymce-react';
import { connect } from "react-redux";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';


const CreateStage = ({csrf}) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [discussId, setDiscussId] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [dailySubmissionLimit, setDailySubmissionLimit] = useState("");
    const [isAscendingLb, setIsAscendingLb] = useState(true);
    const [quizId, setQuizId] = useState("");
    const [stageType, setStageType] = useState([]);
    const [stageTypeId, setStageTypeId] = useState();
    const [isProctored,setIsProctored] = useState(false);
    const [isCodeFileMandatory,setIsCodeFileMandatory] = useState(false);
    const [rakshakId,setRakshakId] = useState('');
    const [blogathonreward,setBlogathonRewards] = useState('');
    const [blogathontimelines,setBlogathonTimelines] = useState('');
    const [rules,setRules] = useState('');

    const [bannerImage, setBannerImage] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState(false);

    useEffect(()=>{
        let queryForStageType = `
        query {
            stageTypes {
                id
                name
                createdAt
                updatedAt
            }
        }
        `
        getQueryData(queryForStageType, "newcompetition")
        .then((data)=>{
            setStageType(data?.data?.stageTypes);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const resetFormFields = () => {
        setTitle("");
        setDescription("");
        setDiscussId("");
        setStartTime("");
        setEndTime("");
        setDailySubmissionLimit("");
        setIsAscendingLb(true);
        setQuizId("");
        setBannerImage("");
        setThumbnailImage("");
        setRules("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const startDate = new Date(startTime);
        const startOffset = startDate.getTimezoneOffset(); 
        startDate.setMinutes(startDate.getMinutes() - startOffset - 330); 

        const endDate = new Date(endTime);
        const endOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        // let mutation = `{\"query\":\"mutation MyMutation($bannerImage: Upload, $thumbnailImage: Upload, $title:String!, $description:String!, $starttime:DateTime!,$endtime:DateTime!,$stageTypeId:ID!,$quizId:String!,$blogathonRewards:String!,$blogathonTimelines: Upload,$rakshakId:String!){createStage(title:$title, description:$description, startDate: $starttime, endDate: $endtime, stageTypeId: $stageTypeId, dailySubmissionLimit: ${dailySubmissionLimit !== "" ? dailySubmissionLimit : 0}, quizId: $quizId, discussId: ${discussId !== "" ? discussId : null}, isAscending: ${isAscending}, bannerImage: $bannerImage, blogathonRewards: $blogathonRewards, blogathonTimelines: $blogathonTimelines, isProctored: ${isProctored}, isCodeFileMandatory: ${isCodeFileMandatory}, rakshakId: $rakshakId, thumbnailImage: $thumbnailImage ) { stage { id, title, description }}}\",
        // "variables":{\"rakshakId\":\"${rakshakId}\",\"blogathonRewards\":\"${blogathonreward}\",\"quizId\":\"${quizId}\",\"stageTypeId\":\"${stageTypeId}\",\"endtime\":\"${endTime}\",\"starttime\":\"${startTime}\",\"description\":\"${description}\",\"title\":\"${title}\",\"bannerImage\":null,\"thumbnailImage\":null,\"blogathonTimelines\":null}} `;

        let mutation = `mutation MyMutation($bannerImage: Upload, $thumbnailImage: Upload, $title:String!, $starttime:DateTime!,$endtime:DateTime!,$stageTypeId:ID!,$quizId:String!,$blogathonRewards:String!,$blogathonTimelines: Upload,$rakshakId:String!){ createStage(title:$title, description:"""${description}""", startDate: $starttime, endDate: $endtime, stageTypeId: $stageTypeId, dailySubmissionLimit: ${dailySubmissionLimit !== "" ? dailySubmissionLimit : 0}, quizId: $quizId,rules:"""${rules}""" discussId: ${discussId !== "" ? discussId : null}, isAscendingLb: ${isAscendingLb}, bannerImage: $bannerImage, blogathonRewards: $blogathonRewards, blogathonTimelines: $blogathonTimelines, isProctored: ${isProctored}, isCodeFileMandatory: ${isCodeFileMandatory} ,rakshakId: $rakshakId, thumbnailImage: $thumbnailImage ) { stage { id, title, description }}}`;

        let variables = {
            title:title,
            starttime:formattedStartDate,
            endtime:formattedEndDate,
            stageTypeId:stageTypeId,
            quizId:quizId,
            blogathonRewards:blogathonreward,
            rakshakId:rakshakId,
            ...(bannerImage && {bannerImage :null}),
            ...(thumbnailImage && {thumnailImage :null}),
            ...(blogathontimelines && {blogathonTimelines :null})
        }

        let formdata = new FormData();
        const map = {};

        if(bannerImage){
            formdata.append("0", bannerImage);
            map["0"] = ["variables.bannerImage"]
        }
        if(thumbnailImage){
            formdata.append("1", thumbnailImage); 
            map["1"] = ["variables.thumnailImage"];
        }
        if(blogathontimelines){
            formdata.append("2", blogathontimelines); 
            map["2"] = ["variables.blogathonTimelines"];
        }

        formdata.append("operations", JSON.stringify({ query: mutation, variables: variables }));  
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setMessage("Something went wrong!")
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result)=> {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });

    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your stage created successfully"} />}
            <section id='createStage' className="common-style background-dark-primary">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-uppercase text-white fw-semibold">Stage Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Description' 
                                            value={description}
                                            onChange={(event)=>setDescription(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white me-3">Stage Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="stageTypeId"
                                                className="form-select"
                                                value={stageTypeId}
                                                onChange={(event) => setStageTypeId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Stage Type</option>
                                                {stageType && stageType.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.id}-{type.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Ascending Leaderboard:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isAscendingLb}
                                                onChange={(event) => setIsAscendingLb(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='Start Date' 
                                            value={startTime}
                                            onChange={(e)=>setStartTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='End Date' 
                                            value={endTime}
                                            onChange={(e)=>setEndTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Daily Submission Limit</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                value={dailySubmissionLimit}
                                                placeholder="Daily submission limit"
                                                onChange={(event) => setDailySubmissionLimit(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Discuss Id</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Discuss ID'
                                                value={discussId}
                                                onChange={(event) => setDiscussId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Quiz Id</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Quiz ID"
                                                value={quizId}
                                                onChange={(event) => setQuizId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Proctored:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isProctored}
                                                onChange={(event) => setIsProctored(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Code File Mandatory:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isCodeFileMandatory}
                                                onChange={(event) => setIsCodeFileMandatory(event.target.checked)}
                                            />
                                        </div>

                                        {isProctored && <div className="form-group mb-3">
                                            <label className="text-white">Rakshak Id</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={rakshakId}
                                            onChange={(event)=>setRakshakId(event.target.value)}
                                            />
                                        </div>}

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Banner Image:  <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="bannerImage"
                                                onChange={(e) => setBannerImage(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Thumbnail Image:  <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="thumbnailImage"
                                                onChange={(e) => setThumbnailImage(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="mb-3">                                         
                                            <label className="me-3 text-white">Rules</label>
                                            <Editor
                                                tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                                                init={{
                                                    plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste help wordcount codesample',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }}
                                                initialValue=""
                                                onEditorChange={(content,editor)=>{setRules(content)}}
                                            />
                                        </div>
                                        
                                        <div className="mb-3">                                         
                                            <label className="me-3 text-white">Blogathon Reward</label>
                                            <Editor
                                                tinymceScriptSrc={'https://pkg.analyticsvidhya.com/tinymce/tinymce.min.js'}
                                                init={{
                                                    plugins: 'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste help wordcount codesample',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }}
                                                initialValue=""
                                                onEditorChange={(content,editor)=>{setBlogathonRewards(content)}}
                                            />
                                        </div>

                                        <div className="form-group mb-3">                                         
                                            <label className="me-3 text-white">Blogathon Timelines </label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="blogathonTimelinesImage"
                                                onChange={(e) => setBlogathonTimelines(e.target.files[0])}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Stage
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateStage);

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EventHome from '../pages/EventHome';
import EventDetail from '../pages/EventDetail';
import EventListingPage from '../pages/EventListingPage';
import LeadingWithDataListing from '../pages/LeadingWithDataListing';
import LeadingWithDataDetails from '../pages/LeadingWithDataDetails';
import Error404 from '../../../common/Alert/Error404';
import Title from '../../../common/Header/Title';

const cloudflare_url = process.env.REACT_APP_CLOUDFLARE_URL;
const cloudflare_size_url = process.env.REACT_APP_CLOUDFLARE_URL_SIZE;

export default function EventsRouters() {

  return (
    <>
      <Title title={'Explore the Future of AI with Expert-led Events'} description={'Analytics Vidhya is the leading community of Analytics, Data Science and AI professionals. We are building the next generation of AI professionals. Get the latest data science, machine learning, and AI courses, news, blogs, tutorials, and resources.'} image_og={cloudflare_size_url + 'og.jpg?format=avif'} title_og={'The Competitive Platform for AI Experts'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <Routes>
        <Route path='/' exact element={<EventHome />} />
        <Route path='/datahour' exact element={<EventListingPage />} />
        <Route path='/datahour/:eventSlug' exact element={<EventDetail />} />
        <Route path='/leading-with-data' exact element={<LeadingWithDataListing />} />
        <Route path='/leading-with-data/:eventSlug' exact element={<LeadingWithDataDetails />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  )
}

import React, { useEffect } from 'react'
import Footer from '../../../common/Footer/Footer';
import HomepageHeader from '../components/HomepageHeader';
import { connect } from 'react-redux';
import { fetchHomepageContent } from '../../../../store/Actions/Action';
import { scrollTop } from '../../../../utils';
import Flashstrip from '../../../common/Header/Flashstrip';
import Cookies from '../../../common/Footer/Cookies';

function TermsOfUse({homepagecontent, fetchHomepageContentHandler}) {

	useEffect(()=>{
		if(!homepagecontent){
			fetchHomepageContentHandler();
			}
			scrollTop();
	},[]);

	return (
		<>
		<main className='background-dark-primary'>
			<HomepageHeader header_content={homepagecontent && homepagecontent.header} />
			<Flashstrip />
				<section className='static-pages' id='termsOfUse'>
					<div className='container'>
						<div className="row">
							<div className="col-md-8 col-10 mx-auto">
								<h2>Terms of use</h2>
									<p>PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES (DEFINED BELOW) ARE SUBJECT TO THE FOLLOWING TERMS, IF, YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MUST NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</p>
									<p>Welcome to Analytics Vidhya. Please read on to learn the rules and restrictions that govern the use of our website(s), products, services and applications (the “Services”). These Terms of Use (the “Terms”) are a binding contract between You (the terms “you”, “your”, “yours” refer to you as a user of the website(s)) and Analytics Vidhya Inc. (“Analytics Vidhya,” “we” and “us”). These Terms include the provisions in this document, as well as those in the <a href='https://www.analyticsvidhya.com/privacy-policy/' target='_blank'>Privacy Policy</a>. In these Terms, the words “include” or “including” mean “including but not limited to”, and examples are for illustration purposes and are not limiting.</p>
									<p>These Terms are entered into by and between You and Us, by using our services, you accept and agree to be bound and abide by these Terms, including the policies referenced in these Terms.  Any use or access by anyone under the age of 18 is prohibited, and certain regions may have additional requirements and/or restrictions. By using the Platform, you represent and warrant that you meet all of the forgoing eligibility requirements and that all personal information you provide will be governed by our Privacy Policy. Further, you consent to all actions taken by us in compliance with the Privacy Policy. If you do not meet our eligibility requirements, you must not access or use the Platform</p>
									<p>If you have any questions, comments, or concerns regarding these terms or the Services, please contact us.</p>
									<p>Contact us, if you have any questions or concerns about our cookie policy, please contact us at <a href='mailto:privacy@analyticsvidhya.com' className='breakAll'>privacy@analyticsvidhya.com</a></p>
								<h4>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY BEFORE USING THE SERVICES OF THIS WEBSITE.</h4>
								<h3>Will these Terms ever change?</h3>
									<p>We are constantly trying to improve our Services, so these Terms may need to change along with the Services. We reserve the right to change the Terms at any time, but if we do, we will bring it to your attention by placing a notice on the www.analyticsvidhya.com website or by sending you an email, or by some other means. Changes will not apply retroactively and will become effective no sooner than 14 days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you don’t agree with the new Terms, you are free to reject them, however, that would mean you will no longer be able to use the Services. If you continue to use the Services in any way after a change to the Terms is effective, that shall mean that you agree to all of the changes made to the terms automatically.</p>
									<p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and agreed by both you and us.</p>

								<h3>What about my privacy?</h3>
								<p>Analytics Vidhya takes the privacy of its users very seriously. For the current Analytics Vidhya Privacy Policy, please click <a href='https://www.analyticsvidhya.com/privacy-policy/' target='_blank'>here</a>.</p>
								<h3>Our refund policy</h3>								
								<p>To know about our refund policy please <a href='https://www.analyticsvidhya.com/refund-policy/' target='_blank'>click here</a>.</p>
								<h3>Scholarship Offers</h3>		
								<ul>
									<li>This scholarship offer is valid only for enrollments done during the Scholarship Week only. Specific dates of this offer will be communicated on our website and marketing materials.</li>
									<li>The scholarship applies to the full fees of the Program, excluding any taxes or additional charges  (such as loan fees etc.)</li>
									<li>To be eligible for the scholarship refund, the user must successfully complete the mandatory modules of the scholarship offer with all assignments, assessments and projects within the defined time period (number of months) from the date of enrollment in the specific program.</li>
									<li>Following are the mandatory courses for GenAI Pinnacle Program:
										<ul>
											<li>Introduction to GenAI</li>
											<li>Getting Started with LLMs</li>
											<li>Building LLM Apps using Prompt Engineering</li>
											<li>Advanced Prompt Engineering</li>
											<li>Building End-to-End Generative AI Applications</li>
											<li>Introduction to LangChain - Building Generative AI Apps & Agents</li>
											<li>Building Production ready RAG systems using LlamaIndex</li>
											<li>Introduction to AI Agents</li>
											<li>Mastering LLMOps: From Build to Deployment</li>
											<li>Mastering Reinforcement Learning: Foundations to Human Feedback</li>
											<li>Finetuning LLMsTraining LLMs from Scratch</li>
											<li>Computer Vision using Pytorch</li>
											<li>Getting started with Stable Diffusion</li>
											<li>Mastering Methods and Tools of Stable Diffusion</li>
											<li>Advanced Stable Diffusion Techniques</li>
											<li>Responsible AI in the Generative AI Era</li>
											<li>Generative AI for Business - A Leaders' Handbook</li>
											<li>Successful AI Strategies: A CEO's Perspective</li>
										</ul>
									</li>
									<li>For being eligible for scholarship for Certified AI&ML BlackBelt Plus Program. The entire program must be completed within the given duration.</li>
									<li>Completion criteria will be clearly defined within the course platform. This may include assignments, quizzes, assessments or project submissions.</li>
									<li>The scholarship refund will be processed after successful completion of the mandatory modules and verification by Analytics Vidhya. </li>
									<li>The scholarship refund will be initiated within 1 month of confirming completion of the modules. The refund will be processed through the original payment method used for paying the course enrollment fees.</li>
									<li>This scholarship offer cannot be combined with any other discounts or promotions.</li>
									<li>Any case of copying, plagiarism, cheating, or getting others to complete the course modules shall disqualify the user from being eligible for this offer.</li>
									<li>No partial payments shall be refunded for this program to any user</li>
									<li>Analytics Vidhya reserves the right to modify or cancel this scholarship offer at any time without prior notice.</li>
								</ul>						
								<h3>What are the basics of using Analytics Vidhya?</h3>
									<p>You may be required to sign up for an account, and select a password and username (“Analytics Vidhya User ID”). You promise to provide us with accurate, complete, and updated registration information about yourself. You may not select as your Analytics Vidhya User ID a name that you don’t have the right to use, or another person’s name with the intent to impersonate that person. You may not transfer your account to anyone else without our prior written permission. In the spirit of fairness, you may not have or control more than one active Analytics Vidhya User ID and participate in Competitions (defined below). You agree that you are solely responsible for maintaining the confidentiality of your Account and for all activities that occur under it. If we determine, that you are operating under more than one Analytics Vidhya User ID, we may disqualify you from any Competition without notice and revoke access to your Analytics Vidhya User ID. We shall not under any circumstances be held liable for any claims related to the use or misuse of your Account due to the activities of any third party outside of our control or due to your failure to maintain the confidentiality and security of your Account.</p>
									<p>You represent and warrant that you are of legal age to form a binding contract. If you are not of legal age to form a binding contract, please see the <a href='https://www.analyticsvidhya.com/privacy-policy/' target='_blank'>Privacy Policy</a> and contact us at <a href="https://analyticsvidhya.com/contact/" target='_blank'>Analytics Vidhya Support</a> for more information on the parental consent process. You must either be of legal age to form a binding contract or have completed the parental consent process to use the Services.</p>
									<p>If you’re agreeing to these Terms on behalf of an organization or entity, you represent and warrant that you are authorized to agree to these Terms on that organization or entity’s behalf and bind them to these Terms, but you are still required to sign up each individual user from your organization with an Analytics Vidhya User ID. You are not allowed to allow multiple individuals to operate under one Analytics Vidhya User ID, even if you are an organization or entity.</p>
									<p>You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren’t authorized to use the Services. We are not responsible if you use the Services in a way that breaks the law.</p>
									<p>You will keep all your registration information accurate and current. You will not share your account or password with anyone, and you must protect the security of your account and your password. You’re responsible for any activity associated with your account. At no point in time, will we be liable for any kind of losses or damages caused by any unauthorized use of your account, and you shall solely be liable for the losses caused to us or others due to such unauthorized use, if any.</p>

								<h3>Are there any additional restrictions on my use of the Services?</h3>
									<p>Yes. <br/> You represent, warrant, and agree that you will not contribute any Content or User Submission or otherwise use the Services or interact with the Services in a manner that:</p>
									<p>
										<ol>
											<li>Infringes or violates the intellectual property rights or any other rights of anyone else (including Analytics Vidhya), including privacy and data protection rights, or creates a liability for Analytics Vidhya.</li>
											<li>Violates any law or regulation, including any applicable export control laws.</li>
											<li>Is harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable.</li>
											<li>Jeopardizes the security of your Analytics Vidhya account or anyone else’s (such as allowing someone else to log into the Services as you).</li>
											<li>Attempts, in any manner, to obtain the password, account, or other security information from any other user.</li>
											<li>Violates the security of any computer network, or cracks any passwords or security encryption codes.</li>
											<li>Runs Maillist, Listserv, any form of auto-responder or “spam” on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services’ infrastructure).</li>
											<li>“Crawls,” “scrapes,” or “spiders” any page, data, or portion of or relating to the Services or Content (through use of manual or automated means).</li>
											<li>Copies or stores any significant portion of the Content.</li>
											<li>Decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</li>
										</ol>
									</p>
									<p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services. We reserve the right to remove any Content or User Submissions from the Services at any time, for any reason (including if someone alleges you contributed that Content in violation of these Terms), and without notice.</p>

								<h3>What are my rights in Analytics Vidhya?</h3>
									<p>The materials displayed or performed or available on or through the Services, including text, graphics, data, articles, photos, images, illustrations, and User Submissions (collectively, the “Content”), are protected by copyright and other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won’t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else’s (including Analytics Vidhya’s) rights.</p>
									<p>You understand that Analytics Vidhya owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.</p>
									<p>You understand that Analytics Vidhya owns the Services. You won’t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services.</p>
									<p>The Services may allow you to copy or download certain Content; please remember that just because this functionality exists, doesn’t mean that all the restrictions above don’t apply — they do!</p>

								<h3>Who is responsible for what I see and do on the Services?</h3>
									<p>Any information or content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such content originated, and you access all such information and content at your own risk. We aren’t liable for any errors or omissions in that information or content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you release us from all liability for you having acquired or not acquired Content through the Services. We can’t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</p>
									<p>You are responsible for all Content you contribute to the Services, and you represent and warrant you have all rights necessary to do so.</p>
									<p>The Services may contain links, information or connections to third party websites or services that are not owned or controlled by Analytics Vidhya (including job postings). When you access third party websites or engage with third party services, you accept that there are risks in doing so, and that Analytics Vidhya is not responsible for such risks. We encourage you to be aware when you leave the Services and to read the terms and privacy policy of each third party website or service that you visit or utilize.</p>
									<p>Analytics Vidhya has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third party websites or by any third party that you interact with through the Services. In addition, Analytics Vidhya will not and cannot monitor, verify, censor or edit the content of any third party site or service. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third party website or service.</p>
									<p>Your interactions with organizations or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Analytics Vidhya will not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>
									<p>If there is a dispute between participants on this site, or between users and any third party, you agree that Analytics Vidhya is under no obligation to become involved. If you have a dispute with one or more other users, you indemnify Analytics Vidhya, its officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes or our Services.</p>

								<h3>What are the rules for competitions on Analytics Vidhya?</h3>
									<p>Subject to these Terms and any contract with Analytics Vidhya, a user may post (“Host User”) a skill-based competition or challenge on the Services (“Competition”) for other users to participate in such Competition (“Participant User”). Competitions are subject to separate Competition Rules (defined below) that are established by the Host User. Competitions exclude games of chance, and you may not use or attempt to use the Service to host any such game of chance. Competitions are open to residents of the India and worldwide, except if you are a resident of Crimea, Cuba, Iran, Syria, North Korea, Sudan, or any other place prohibited by law you may not enter any Competition. Certain Competitions may also have further geographic restrictions and be open only to residents of certain countries.</p>
									<h4>If you would like to host a competition on Analytics Vidhya,</h4>
										<p>Go to <a href='https://www.analyticsvidhya.com/enterprise' target='_blank' className='breakAll'>https://www.analyticsvidhya.com/enterprise</a> and contact our sales team. Competitions are run according to rules that describe participation guidelines, the data set(s) to be used by the Participant Users, the criteria used by the Host User to select a winner of the Competition (the “Metric”), the prize awarded to such winner, and when such prize will be awarded. Such rules and selection criteria must comply with all applicable laws and these Terms (collectively, “Competition Rules”). Such Competition Rules will also include how and when a Participant User must submit Competition Entries (defined below) and the rights the Host User will be granted in such Competition Entry upon selecting such Competition Entry as the winner (“Winning Entry”). The Host User will be granted rights in the Competition Entry only if the winner accepts payment of the prize. Certain rights granted in the Competition Entries and Winning Entries are described in Section 8 below. The Competition Rules may impose additional restrictions or requirements for Competitions.</p>
										<p>The Host User must conspicuously display the Metric within the Competition Rules. The Host User must select an objective Metric and must apply that Metric impartially to each Team’s (defined below) selected entries. In selecting a winner, the Host User must apply the Metric and select the Participant Users with the best rankings based on the Metric.</p>
										<p>The Host User and each Participant User will comply with all Competition Rules. The Host User (including any user from Host User’s organization) may not compete in its hosted Competition nor cancel a Competition without first contacting us and receiving our consent to such cancellation. Any participation by a Host User (or any user from Host User’s organization) in its hosted Competitions is done so on an ineligible basis (e.g. they cannot receive a prize) and will not otherwise affect the selection of the Winning Entry.</p>
										<p>Subject to the Competition Rules, Participant Users may collaborate as a team as long as each such Participant User confirms its team membership through the Service and does not participate on more than one team for a specific Competition (“Team”). To be clear, you may not participate on more than one team per Competition. Subject to the Competition Rules, Teams may, however, merge with other Teams, but the merged Team will be responsible for all past entries by the component Teams. If your Team wins a Competition, the prize for winning will be distributed equally amongst the members of the winning Team, unless we receive identical requests for unequal prize splits from every member of the Team.</p>
										<p>You may be a Host User or a Participant User for a specific Competition (but not both!). A Competition creates a direct relationship between a Host User and a Participant User, and Analytics Vidhya will have no liability for any actions or content of a Host User or a Participant User. Analytics Vidhya may provide a Host User with a template for the Competition Rules, but such template is provided without any warranty whatsoever and the Host User is solely responsible for its Competition Rules. It is the sole responsibility of the Host User to ensure that the Competition Rules comply with applicable law.</p>
										<p>You acknowledge and agree that Analytics Vidhya may, without any liability but without any obligation to do so, remove or disqualify a Participant User, a Host User or a Competition if Analytics Vidhya believes that such Participant User, Competition or Host User are in violation these Terms or otherwise pose a risk to Analytics Vidhya, the Service or another user of the Service.</p>
										<p>Regardless of anything to the contrary, Participant Users acknowledge and agree that Analytics Vidhya and Host Users have no obligation to hold a Competition Entry in confidence or otherwise restrict their activities based on receipt of such Competition Entry. Analytics Vidhya has no obligation to become involved in disputes between users (for example, between a Participant User and a Host User) or between users and any third party relating the use of the Services. All content you access or submit via the Services is at your own risk. You are solely responsible for any resulting damage or loss to any party.</p>

								<h3>Do I have to grant any licenses to Analytics Vidhya or to other users?</h3>
									<p>Anything you post, upload, share, store, or otherwise provide through the Services is your “User Submission.” Some User Submissions are viewable by other users. To display your User Submissions on the Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions. Please note that all of the following licenses are subject to our <a href='https://www.analyticsvidhya.com/privacy-policy/' target='_blank'>Privacy Policy</a> to the extent they relate to User Submissions that are also your personal information.</p>
									<p>For all User Submissions, you grant Analytics Vidhya a license to translate, modify (for technical purposes, for example making sure your content is viewable on an iPhone/ Android device as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Services, as described in more detail below. You acknowledge and agree that Analytics Vidhya, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of communication networks, devices, services, or media, and the licenses you grant under these Terms include the rights to do so. You also agree that all of the licenses you grant under these Terms are royalty-free, perpetual, irrevocable, and worldwide. These are licenses only — your ownership in User Submissions is not affected.</p>
									<p>If you share a User Submission on the Services or in a manner that allows more than just you or certain specified users to view it (such as a Dataset), or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, or feature requests relating to the Services (each a “Public User Submission”), then you grant Analytics Vidhya the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all Analytics Vidhya users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Services for any purpose. Also, you grant all other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</p>
									<p>If you are a Participant User and submit an entry to a Competition (“Competition Entry”), then you grant Analytics Vidhya the license stated in the second paragraph of this Section 8, as well as a license to display, perform, and distribute your Competition Entry for the purpose of making that Competition Entry accessible to the Host User, making that Competition Entry available to other Analytics Vidhya users as a Dataset, and providing the Services necessary to do so. Also, you grant such Host User a limited license to access and use the Competition Entry solely for the purposes of evaluating the Competition Entry under the Competition Rules. If you win a Competition, your Competition Entry for such Competition will be subject to further licensing as stated in the Competition Rules, but other than the limited licenses stated in these Terms, the intellectual property rights in your Competition Entries will not be transferred or licensed to the Competition Sponsor or Host User unless you accept the payment of the applicable prize stated in the applicable Competition Rules.</p>

								<h3>Will Analytics Vidhya ever change the Services?</h3>
									<p>We’re always trying to improve the Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We’ll try to give you notice when we make a material change to the Services that would adversely affect you, but this isn’t always practical.</p>

								<h3>Does Analytics Vidhya cost anything?</h3>
									<p>The Analytics Vidhya Services may be free or we may charge a fee for using the Services. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services.</p>
									<h4>a. Paid Services.</h4>
										<p>Certain of our Services may be subject to payments now or in the future (the “Paid Services”). Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of these Terms.</p>
									<h4>b. Billing</h4>
										<p>We use a third-party payment processor (the “Payment Processor”) to bill you through a payment account linked to your Account on the Services (your “Billing Account”) for use of the Paid Services. The processing of payments will be subject to the terms, conditions and privacy policies of the Payment Processor in addition to these Terms. We are not responsible for errors by the Payment Processor. By choosing to use Paid Services, you agree to pay us, through the Payment Processor, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms and you authorize us, through the Payment Processor, to charge your chosen payment provider (your “Payment Method”). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that it makes even if it has already requested or received payment.</p>
									<h4>c. Payment Method.</h4>
										<p>The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the financial institution, credit card issuer or other provider of your chosen Payment Method. If we, through the Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.</p>

								<h3>What if I want to stop using Analytics Vidhya?</h3>
									<p>Your free to stop using the Service at any time; please refer to our <a href='https://www.analyticsvidhya.com/privacy-policy/' target='_blank'>Privacy Policy</a>, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>
									<p>Analytics Vidhya is also free to terminate (or suspend access to) your use of the Services or your account, for any reason in our discretion, including your breach of these Terms. Analytics Vidhya has the sole right to decide whether you are in violation of any of the restrictions in these Terms.</p>
									<p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account.</p>

								<h3>What else do I need to know?</h3>
									<h4>Warranty Disclaimer</h4>
										<p>The content available on Analytics Vidhya may contain typographical errors or inaccuracies and may not be complete or current. Analytics Vidhya, therefore, reserves the right to correct any errors, inaccuracies or omissions (including after an order has been submitted) and to change or update information at any time without prior notice</p>
										<p>Neither Analytics Vidhya nor its licensors or suppliers makes any representations or warranties concerning any content contained in or accessed through the Services (including Competitions), and we will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services. We (and our licensors and suppliers) make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through the Services. Products and services purchased or offered (whether or not following such recommendations and suggestions) through the Services are provided “AS IS” and without any warranty of any kind from Analytics Vidhya or others (unless, with respect to such others only, provided expressly and unambiguously in writing by a designated third party for a specific product). THE SERVICES AND CONTENT ARE PROVIDED BY ANALYTICS VIDHYA (AND ITS LICENSORS AND SUPPLIERS) ON AN “AS-IS” BASIS, WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, OR THAT USE OF THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.</p>
										<p>Under no circumstances will Analytics Vidhya (and our licensors and suppliers) be liable in any way for use of any content or any other information, data, or other material downloaded or otherwise obtained through or from the website or Programs, including any errors or omissions, or any loss or damage or defamation of any kind incurred as a result of your use of or reliance on such information or data. -No advice or information, whether oral or written, obtained by the user from Analytics Vidhya (and our licensors and suppliers) or through or from the services, shall create any warranty by Analytics Vidhya (and our licensors and suppliers).</p>
									<h4>Intellectual Property Rights</h4>
									<p>You acknowledge that we are the sole and exclusive owner of the Platform, the services provided by us, the curriculum and its content, and as such we are vested with all the Intellectual Property Rights and other proprietary rights in the Website, the Programs, content and the curriculum which may include but not be limited to text, software, scripts, graphics, maps, photos, sounds, music, videos, logos, offers, advertisements, interactive features and other materials.</p>
									<p>Analytics Vidhya logos, trademarks and service marks that may appear on the Platform and in the Program (“Analytics Vidhya Marks”) are the property of Analytics Vidhya and are protected under Indian and foreign laws. All other trademarks, service marks and logos used on the Platforms, Online Courses or Programs, with or without attribution, are the trademarks, service marks or logos of their respective owners. In addition, elements of the Platforms are protected by trade dress and other Indian and international intellectual property laws and may not be copied, reproduced, downloaded or distributed in any way in whole or in part without the express written consent of Analytics Vidhya.</p>
									<p>You agree and acknowledge that: (i) you shall not reproduce, duplicate, copy, sell, resell or exploit for any commercial purpose any Analytics Vidhya content or any portion of it thereof, other than as expressly allowed under these Terms; (ii) you shall not use the Analytics Vidhya Marks or the name, trademarks, service marks, or other materials of any Educational Partner in connection with, or to transmit, any unsolicited communications or emails or for any other unauthorised purpose; (iii) We shall have the right to record (in any form and manner) the classes, discussions, processes, events, conversations and feedback pertaining to the participation of the users in the Programs, offered in online or any other format; and (iv) We shall have an exclusive right, including in the nature of intellectual properties throughout the world to use, publish, display, exhibit, transmit, broadcast, disseminate, market, advertise, license, transfer, modify, and create derivative works from such recorded contents mentioned hereinabove, for any purpose whatsoever, through various medium including, but not limited, to social media, webpages, electronic or print media, news articles, blogs etc.</p>
									<p>Analytics Vidhya shall not be held liable for the unauthorised use of any third-party Intellectual Property Rights, and the user that carries out such unauthorised use or infringes any Intellectual Property available on the Platform shall fully indemnify and hold Analytics Vidhya harmless against any and all claims that may arise as a result of such use.</p>
									<h4>Limitation of Liability</h4>
										<p>TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (INCLUDING TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE) WILL ANALYTICS VIDHYA (OR ITS LICENSORS OR SUPPLIERS) BE LIABLE TO YOU OR TO ANY OTHER PERSON FOR (A) ANY INDIRECT, SPECIAL OR INCIDENTAL DAMAGES OF ANY KIND, INCLUDING DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, WORK STOPPAGE, ACCURACY OF RESULTS, OR COMPUTER FAILURE OR MALFUNCTION, OR (B) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF (I) $100 OR (II) THE AMOUNTS PAID BY YOU TO ANALYTICS VIDHYA IN CONNECTION WITH THE SERVICES IN THE TWELVE (12) MONTH PERIOD PRECEDING THIS APPLICABLE CLAIM, OR (C) ANY MATTER BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>
									<h4>Indemnity</h4>
										<p>To the fullest extent allowed by applicable law, you will defend, indemnify and hold Analytics Vidhya, its affiliates, officers, agents, employees, and partners harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys’ fees) arising from or in any way related to any third party claims (including from other users) relating to (a) your submissions to the Services including any Content, User Submissions or Competitions, (b) your use of the Services (including any actions taken by a third party using your account), and (c) your violation of these Terms.</p>
									<h4>Assignment</h4>
										<p>You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Analytics Vidhya’s prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>
									<h4>Severability</h4>
									<p>If any court of competent jurisdiction or competent authority finds that any provision of these Terms (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision shall, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of these Terms shall not be affected.</p>
									<h4>Termination</h4>
									<p>You agree that Analytics Vidhya in its sole discretion, may deactivate your account or otherwise terminate your use of the Platform or enrolment to a Program with or without any reason, including, without limitation, if we believe that you have (a) breached the Terms; (b) infringed the intellectual property rights of a third party; (c) posted, uploaded or transmitted unauthorised content on the Platform; or (d) violated or acted inconsistently with the letter or spirit of these Terms or any other applicable code of conduct. You agree that any deactivation or termination of your access to the Platforms or Programs may be effected without prior notice to you and that we shall not be liable to you nor any third party for any termination of your account or enrolment into a Program. You also acknowledge that we may retain and store your information on our systems notwithstanding any termination of your account or enrolment into the Programs.</p>
									<h3>About These Terms</h3>
									<p>These Terms control the relationship between Analytics Vidhya and you. They do not create any third party beneficiary rights.</p>
									<p>If you do not comply with these Terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).</p>
									<p>If it turns out that a particular term is not enforceable, this will not affect any other part of the Terms.</p>
									<p>The laws of Indore, Madhya Pradesh, India, will apply to any disputes arising out of or relating to these Terms or the Services. All claims arising out of or relating to these Terms or the Services will be litigated exclusively in Indore courts, and you and Analytics Vidhya consent to personal jurisdiction in those courts.</p>
							</div>
						</div>
					</div>
				</section>
				<Footer platform={'newtermsofuse'} />
		</main>
		<Cookies platform={'newhomepage'} />
		</>
	);
}

const mapStateToProps = (state) => ({
	homepagecontent:state.homepageContentReducer.homepagecontent
});
  
const mapDispatchToProps = dispatch=>({
	fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(TermsOfUse);
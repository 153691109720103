import { FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    agenticaileadpagescontent: null,
};
  
export const agenticaiLeadPagesContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS:
            return {
                ...state,
                agenticaileadpagescontent: JSON.parse(action.payload.data.getPlatformJsonDataByName.platformData),
            };
        default:
            return state;
    }
};
  
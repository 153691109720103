import React from 'react'
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom'
import Header from '../components/common/Header/Header';
import MandatoryLogin from '../components/common/Login/MandatoryLogin';

function ProtectedRouter({islogin,children}) {
    return (children)
}


const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin
});
  
const mapDispatchToProps = dispatch=>({
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRouter);
import React, { useEffect, useRef, useState } from "react";
import { getQueryData, getQueryDataElevateUser } from "../../../../utils";
import Header from "../../../common/Header/Header";
import { connect } from "react-redux";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const CreateDatahourResource = ({csrf}) => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [file, setFile] = useState("");
    const [resourceType, setResourceType] = useState("");
    const [datahours, setDatahours] = useState([]);
    const [datahourId, setDatahourId] = useState("");
    const [thumbnail, setThumbnail] = useState("");

    const fileRef = useRef(null);
    const thumbnailRef = useRef(null);

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    useEffect(()=>{
        const query = `
        query MyQuery {
            allDatahours {
                edges {
                    node {
                        sessionName
                        id
                    }
                }
            }
        }`

        getQueryData(query, "newevents")
        .then((data)=>{
            setDatahours(data.data.allDatahours);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const resetFormFields = () => {
        setTitle("");
        setLink("");
        setFile("");
        setThumbnail("");
    };

    const handleSubmit = (e)=>{
        e.preventDefault();

        if(!(fileRef.current?.files[0] || thumbnailRef.current?.files[0])) {
            let query = `mutation {
                createDatahourResource(
                    resourceType: "${resourceType}"
                    link: "${link}"
                    title: "${title}"
                    sessionId: "${datahourId}"
                    ) { 
                    datahourResource { 
                            id session { id } resourceType 
                        } 
                    }
                }
            `;
            getQueryDataElevateUser(query, "newevents", "True", csrf)
            .then((data) => {
                if (!data.errors) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000)
                }
                else {
                    setIsError(true);
                    setMessage(data.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setIsError(true);
                setMessage(error.message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                console.log('error', error)
            });
            return false;
        }

        var formdata = new FormData();

        const mutationString = `
        mutation MyMutation($sessionId: ID!, $resourceType: String!, $title: String, $link: String, $file: Upload, $thumbnail: Upload) {
        createDatahourResource(sessionId: $sessionId, resourceType: $resourceType, link: $link, ,title: $title, thumbnail: $thumbnail, file: $file) {
                datahourResource {
                    id
                    session {
                        id
                    }
                    resourceType
                    link
                    file
                    title
                    thumbnail
                }
            }
        }
        `;

        const variables = {
            sessionId: datahourId,
            resourceType: resourceType,
            link: link,
            title: title,
            ...(file && { file: null }),
            ...(thumbnail && { thumbnail: null }),
        };

        if (file) {
            formdata.append("0", file);
            variables.file = null; 
        }

        if (thumbnail) {
            formdata.append("1", thumbnail);
            variables.thumbnail = null; 
        }

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (file) map["0"] = ["variables.file"];
        if (thumbnail) map["1"] = ["variables.thumbnail"];
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }
    
        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setMessage("Something went wrong");
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result) => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error);
        });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Resources added successfully"} />}
            <section id='createDatahourResource' className="common-style min-vh-100 background-dark-primary">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white text-uppercase fw-semibold">Datahour Resources Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Datahour <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="datahourId"
                                                className="form-select"
                                                value={datahourId}
                                                onChange={(event) => setDatahourId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Datahour</option>
                                                {datahours && datahours.edges && datahours.edges.map((val) => (
                                                    <option key={val.node.id} value={val.node.id}>
                                                        {val.node.sessionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Resource Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="resourceType"
                                                className="form-select"
                                                value={resourceType}
                                                onChange={(event) => setResourceType(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Resource Type</option>
                                                {["video", "document", "notes"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        {(resourceType == "notes" || resourceType == "document") && <div className="form-group mb-3">
                                            <label className="me-3 text-white">File:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="file"
                                                ref={fileRef}
                                                onChange={(e) => setFile(e.target.files[0])}
                                            />
                                        </div>}

                                        {resourceType == "video" && <div className="form-group mb-3">
                                            <label className="text-white">Link</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Link' 
                                            value={link}
                                            onChange={(event)=>setLink(event.target.value)}
                                            />
                                        </div>}

                                        {resourceType =="video" && <div className="form-group mb-3">
                                            <label className="me-3 text-white">Thumbnail:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="thumbnail"
                                                ref={thumbnailRef}
                                                onChange={(e) => setThumbnail(e.target.files[0])}
                                            />
                                        </div>}

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Add Resources
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDatahourResource);
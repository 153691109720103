import React, { useEffect, useState } from "react";
import { getCSRF, getSession } from "../../../../utils/Login";
import { connect } from "react-redux";
import { getQueryData, getQueryDataElevateUser, scrollTop } from "../../../../utils";
import Header from "../../../common/Header/Header";
import ErrorAlert from "../../../common/Alert/ErrorAlert";
import SuccessAlert from "../../../common/Alert/SuccessAlert";
import { useParams } from "react-router-dom";

const UpdateVideo= ({islogin,csrf}) => {

    const [title, setTitle] = useState("");
    const [episodeNo, setEpisodeNo] = useState(null);
    const [description, setDescription] = useState("");
    const [attendeeData, setAttendeeData] = useState([]);
    const [selectedAttendeeName, setSelectedAttendeeName] = useState("");
    const [selectedAttendeeId, setSelectedAttendeeId] = useState(null);
    const [videoUrl, setVideoUrl] = useState("");
    const [videoFile, setVideoFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [banner, setBanner] = useState(null);
    const [uploadedAt, setUploadedAt] = useState("");
    const [duration, setDuration] = useState("");
    const [metatitle,setMetatitle] = useState("");
    const [metadescription,setMetadescription] = useState("");

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    const {episodeSlug} = useParams();
    const [videoId, setVideoId] = useState(null);
    const [initialVideoFileUrl, setInitialVideoFileUrl] = useState("");

    useEffect(()=>{
        let query = `query MyQuery {
            allAttendees(limit: 10000, page: 1) {
              attendeeData {
                id
                name
              }
            }
            videoData(slug: "${episodeSlug}") {
                videoData {
                    id
                    title
                    episodeNo
                    description
                    uploadedAt
                    duration
                    videoUrl
                    videoFileUrl
                    thumbnailUrl
                    bannerUrl
                    slug
                    metaTitle
                    metaDescription
                }
                attendeeData {
                  id
                  name
                }
            }
        }`;
        getQueryData(query,"newevents")
        .then((data)=>{
            setAttendeeData(data?.data?.allAttendees?.attendeeData);

            if(data?.data?.videoData?.videoData) {
                const videoData = data.data.videoData.videoData;
                setTitle(videoData.title);
                setEpisodeNo(videoData.episodeNo);
                setDescription(videoData.description);
                setUploadedAt(videoData.uploadedAt);
                setDuration(videoData.duration);
                setVideoId(videoData.id);
                videoData.videoUrl ? setVideoUrl(videoData.videoUrl) : setVideoUrl("");
                setInitialVideoFileUrl(videoData.videoFileUrl);
                setMetatitle(videoData.metaTitle);
                setMetadescription(videoData.metaDescription);
            }
            if(data?.data?.videoData?.attendeeData) {
                const attendeeData = data.data.videoData.attendeeData;
                setSelectedAttendeeId(attendeeData.id);
                setSelectedAttendeeName(attendeeData.name);
            }
        })
        .catch((error)=>{
            console.error(error);
        })
    },[])

    const handleAttendeeChange = (event) => {
        if (attendeeData.some((attendee) => {
            if(attendee.id === event.target.value) {
                setSelectedAttendeeName(attendee.name);
            }
            return attendee.id === event.target.value;
        })) {
            setSelectedAttendeeId(event.target.value);
          } else {
            setSelectedAttendeeId(null);
            setSelectedAttendeeName("");
          }
    };

    const handleSubmit = (e)=>{
        e.preventDefault();
        var formdata = new FormData();

        if(!initialVideoFileUrl && !videoFile && !videoUrl) {
            setIsError(true);
            setMessage("Please provide either video url or video file");
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
        }

        if(!videoFile && !thumbnail && !banner){
            let query = `mutation MyMutation {
                updateVideo(id: ${videoId} , title: """ ${title} """, description: """${description}""",metaDescription: "${metadescription}", metaTitle: "${metatitle}", videoUrl: "${videoUrl}", uploadedAt: "${uploadedAt}", duration: "${duration}", episodeNo: ${episodeNo}, attendeeId: ${selectedAttendeeId}) { 
                    video { 
                        id 
                        title 
                        attendee {  
                            id 
                            name
                        } 
                    } 
                }
            }`;
            getQueryDataElevateUser(query,"newevents","True",csrf)
            .then((data)=>{
                if(!data.errors){
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else{
                    setIsError(true);
                    setMessage(data.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setIsError(true);
                setMessage(error.message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                console.log('error', error);
            });
            return false;
        }

        const mutationString = `mutation MyMutation($title: String!, $videoUrl: String, $videoFile: Upload, $banner: Upload, $thumbnail: Upload, $uploadedAt:Date!, $duration:Time! ) { 
            updateVideo( id:${videoId} , title: $title, description: """${description}""",metaDescription: "${metadescription}", metaTitle: "${metatitle}", videoUrl: $videoUrl, videoFile: $videoFile, banner: $banner,uploadedAt:$uploadedAt, duration:$duration, thumbnail:$thumbnail, episodeNo:${episodeNo}, attendeeId:${selectedAttendeeId}) { 
                video { 
                    id 
                    title 
                    attendee {  
                        id 
                        name
                    } 
                } 
            } 
        }`

        const variables = {
            title: title,
            videoUrl: videoUrl,
            duration: duration,
            uploadedAt: uploadedAt,
            ...(videoFile && { videoFile: null }),
            ...(banner && { banner: null }),
            ...(thumbnail && { thumbnail: null })
        }

        if (videoFile) {
            formdata.append("0", videoFile);
            variables.videoFile = null; 
        }

        if (banner) {
            formdata.append("1", banner);
            variables.banner = null; 
        }

        if (thumbnail) {
            formdata.append("2", thumbnail);
            variables.thumbnail = null; 
        }

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (videoFile) map["0"] = ["variables.videoFile"];
        if (banner) map["1"] = ["variables.banner"];
        if (thumbnail) map["2"] = ["variables.thumbnail"];
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }

        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((result)=> {
            console.log(result);
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });
        return false;
    }

    useEffect(()=>{
        scrollTop();
    },[])

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Video updated successfully"} />}
            <section id='updateVideo' className="min-vh-100 background-dark-primary common-style">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Update Video Form</h3>

                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Episode No. <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Episode Number' 
                                            value={episodeNo}
                                            onChange={(event)=>setEpisodeNo(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description <sup className='text-danger'>*</sup></label>
                                            <textarea 
                                            className='form-control' 
                                            value={description}
                                            onChange={(event)=>setDescription(event.target.value)}
                                            style={{ height: '100px' }}
                                            required
                                            />
                                        </div>

                                        <span className="me-2 text-white">Choose Attendee from the list: <sup className='text-danger'>*</sup></span>
                                        <input list="attendees" className="form-control" onChange={handleAttendeeChange} placeholder="Select an attendee" required/>
                                        <datalist id="attendees">
                                            {attendeeData && attendeeData.map((attendee) => (
                                            <option key={attendee.id} value={attendee.id}>
                                                {attendee.name}
                                            </option>
                                            ))}
                                        </datalist>
                                        <p className="pt-2 text-white">Selected attendee Name: <strong>{selectedAttendeeName}</strong></p>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Video Url</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Video Url' 
                                            value={videoUrl}
                                            onChange={(event)=>setVideoUrl(event.target.value.trim())}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Video File:</label>
                                            <input
                                                type="file"
                                                name="videoFile"
                                                onChange={(e) => setVideoFile(e.target.files[0])}
                                                className="text-white"
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Thumbnail:</label>
                                            <input
                                                type="file"
                                                name="thumbnail"
                                                onChange={(e) => setThumbnail(e.target.files[0])}
                                                className="text-white"
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Banner:</label>
                                            <input
                                                type="file"
                                                name="banner"
                                                onChange={(e) => setBanner(e.target.files[0])}
                                                className="text-white"
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Uploaded At <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="date" 
                                            className='form-control' 
                                            placeholder='Uploaded At' 
                                            value={uploadedAt}
                                            onChange={(e)=>setUploadedAt(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Duration <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Please write duration in format(Hours:Minutes:Seconds) : HH:MM:SS' 
                                            value={duration}
                                            onChange={(event)=>setDuration(event.target.value.trim())}
                                            required
                                            />
                                        </div>
                                        
                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='meta title' 
                                            value={metatitle}
                                            onChange={(event)=>setMetatitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Description <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='meta description' 
                                            value={metadescription}
                                            onChange={(event)=>setMetadescription(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Update Video
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(UpdateVideo);
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../../common/Header/Header';
import { getQueryDataElevate, getQueryDataElevateUser } from '../../../../utils';

function UpdateTournamentStageMapping({islogin,csrf}) {
    const { stageSlug } = useParams();
    const [id,setId] = useState('');
    const [stageId, setStageId] = useState();
    const [tournamentId, setTournamentId] = useState();
    const [stages, setStages] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [leaderboardWeightage,setLeaderboardWeightage] = useState("");

    useEffect(() => {
        if(islogin){
            let query = ` 
            query {
                allStage(limit: 1000000, page: 1) {
                    totalPages
                    stageData {
                      id
                      slug
                      updatedAt
                      createdAt
                      title
                    }
                }

                allTournament(limit: 100000, page: 1) {
                    totalPages
                    tournamentData{
                        id
                        title
                        updatedAt
                        createdAt
                        slug
                    }
                }

                stageData(stageSlug: "${stageSlug}") {
                    tournamentstagemappingSet {
                      id
                      leaderboardWeightage
                      stage {
                        id
                      }
                      tournament {
                        id
                      }
                    }
                  }
            }
            `
            getQueryDataElevate(query, "newcompetition","True")
            .then((data) => {
                setStages(data?.data?.allStage.stageData);
                setTournaments(data?.data?.allTournament.tournamentData);
                console.log(data.data.stageData.tournamentstagemappingSet[0])
                setStageId(data.data.stageData.tournamentstagemappingSet[0].stage.id)
                setTournamentId(data.data.stageData.tournamentstagemappingSet[0].tournament.id);
                setId(data.data.stageData.tournamentstagemappingSet[0].id);
                setLeaderboardWeightage(data.data.stageData.tournamentstagemappingSet[0].leaderboardWeightage)
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [islogin]);

    const updateTournamentStageMapping = (e)=>{
        e.preventDefault();

        let mutation = `
        mutation{
            updateTournamentStageMapping(id: ${id}, stageId: "${stageId}", tournamentId: "${tournamentId}",leaderboardWeightage:${leaderboardWeightage?leaderboardWeightage:0}) {
                tournamentStageMapping {
                    stage {
                        id
                        title
                    }
                    tournament {
                        id
                        title
                    }
                }
            }
        }
        `

        getQueryDataElevateUser(mutation, "newcompetition", "True", csrf)
        .then(response => {
            return response
        })
        .then((result) => {
            if(!result.errors)
            {
                alert("Mapping update successfully");
                window.location.href = "/av-admin/datahack-admin/tournament-stage-mapping";
            }
            else{
                alert(result.errors[0].message);
            }
        })
        .catch((error) => {
            alert(error.message);
            console.log(error);
        })
    }

  return (
    <>
        <Header />
        <section id="updateTournamentStageMapping" className='common-style background-dark-primary min-vh-100'>
            <div className='container py-5 px-5'>
                <div className='row d-flex justify-content-center align-items-center'>
                    <div className='col-lg-8'>
                        <div className='card p-4 border-0 shadow background-dark-tertiary'>
                            <div className="card-body">
                                <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Tournament Stage Mapping Form</h3>
                                <form className='w-100' onSubmit={updateTournamentStageMapping}>
                                    <div className="form-group mb-3">
                                        <label className="me-3 text-white">Tournament <sup className='text-danger'>*</sup></label>
                                        <select
                                            name="tournamentId"
                                            value={tournamentId}
                                            onChange={(event) => setTournamentId(event.target.value)}
                                            className='form-select'
                                            required
                                        >
                                            <option value="" disabled>Select Tournament</option>
                                            {tournaments && tournaments.map((items) => (
                                                <option key={items.id} value={items.id}>
                                                    {items.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                        <label className="me-3 text-white">Stage <sup className='text-danger'>*</sup></label>
                                        <select
                                            name="stageId"
                                            value={stageId}
                                            onChange={(event) => setStageId(event.target.value)}
                                            className='form-select'
                                            required
                                            disabled
                                        >
                                            <option value="" disabled>Select Stage</option>
                                            {stages && stages.map((items) => (
                                                <option key={items.id} value={items.id}>
                                                    {items.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group mb-3">
                                            <label className="text-white">Leaderboard Weightage</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='LeaderBoard Weightage' 
                                            value={leaderboardWeightage}
                                            onChange={(event)=>setLeaderboardWeightage(event.target.value)}
                                            />
                                    </div>

                                    <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 mx-auto d-table'>
                                        Update 
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}


const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTournamentStageMapping);
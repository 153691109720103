import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import $ from 'jquery';
import moment from 'moment';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { fetchGenAIContent, fetchGenaiLeadPagesContent } from '../../../../../store/Actions/Action';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { getCSRF } from '../../../../../utils/Login';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Title from '../../../../common/Header/Title';
import GenAiLeadHeader from '../components/GeanAiLeadHeader';
import GenAiTwoFieldLeadModal from '../components/GenAiTwoFieldLeadModal';
const GenAiLeadPagesTwoFieldSwiperComponent1 = React.lazy(() => import("../components/GenAiLeadPagesTwoFieldSwiperComponent1"));
const GenAiLeadPagesTwoFieldSwiperComponent2 = React.lazy(() => import("../components/GenAiLeadPagesTwoFieldSwiperComponent2"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;
var iti2 = null;

const genai_lead_pages_list = eval(process.env.REACT_APP_GENAI_LEAD_PAGES_LIST)

function GenAiLeadPagesTwoField({ genaicontent, fetchGenAIContent, fetchGenAIContentHandler, genaileadpagescontent, country_name, fetchGenaiLeadPagesContent, fetchGenaiLeadPagesContentHandler }) {
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [fullname, setFullname] = useState('');
    const [success, setSuccess] = useState(false);
    const [iserror, setIsError] = useState(false);
    const [userDetails, setUserDetails] = useState({
        fullname: '',
        email: '',
        phone: '',
    })
    const [additionalDetail, setAdditionalDetail] = useState({
        experience: "0-3yrs"
    })
    const [formname, setFormname] = useState(null);
    const [section, setSection] = useState(null);
    const [successMessage, setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
    const [leadFormHeading, setLeadFormHeading] = useState(null);
    const [leadFormCta, setLeadFormCta] = useState(null);
    const [csrf, setCsrf] = useState('');
    const [platformType, setPlatformType] = useState('');
    const recaptchaRef = useRef(null); 
    const [captcha, setCaptcha] = useState(false);

    const { platformName } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!genai_lead_pages_list.includes(platformName)) {
            navigate('/');
        }
    }, []);

    useEffect(() => {
        if (!genaileadpagescontent) {
            fetchGenaiLeadPagesContentHandler(platformName);
        }
        scrollTop();
    }, [fetchGenaiLeadPagesContentHandler]);

    useEffect(() => {
        if (!genaicontent) {
            fetchGenAIContentHandler();
        }
        scrollTop();
    }, [fetchGenAIContentHandler]);

    useEffect(() => {
        const input = document.getElementById("genaiNumber");
        const input1 = document.getElementById('contactPhoneNumber');
        if (input) {
            iti1 = intlTelInput(input, {
                allowDropdown: true,
                autoHideDialCode: true,
                initialCountry: country_name ? country_name : "IN",
                preferredCountries: ["in", "us"],
            });
            if (country_name) {
                iti1.setCountry(country_name);
            }
        }
        if (input1) {
            iti2 = intlTelInput(input1, {
                allowDropdown: true,
                autoHideDialCode: true,
                initialCountry: country_name ? country_name : "IN",
                preferredCountries: ["in", "us"],
            });
            if (country_name) {
                iti2.setCountry(country_name);
            }
        }
    }, [genaicontent, genaileadpagescontent])

    useEffect(() => {
        $('a[href*="#"]:not([href="#"])').click(function () {
            if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top
                    }, 100);
                    return false;
                }
            }
        });

        $('.scrolling-method[href*="#"]:not([href="#"])').click(function () {
            if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && window.location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html,body').animate({
                        scrollTop: target.offset().top - 100
                    }, 100);
                    return false;
                }
            }
        });
    }, []);

    useEffect(() => {
        getCSRF()
            .then((data) => {
                setCsrf(data);
                console.log(data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])

    // useEffect(() => {
    //     setTimeout(() => {
    //         if (!$('#genaiLeadModal').hasClass('show')) {
    //             updateLeadForm('projects', 'AI is the Next Big Leap: Be Part of It', "Download Projects", "popup");
    //             window.$('#genaiLeadModal').modal('show')
    //         }
    //     }, 10000);
    // }, [])

    useEffect(() => {
        // Timer Script
        if(country_name === "IN" && genaicontent && genaileadpagescontent){
        var date = new Date();
        var now = new Date();
        var diffInMilliSeconds = (moment((genaicontent && genaicontent.latestOffers.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
        if (diffInMilliSeconds >0){
  
        
        var days = Math.floor( diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        
        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;
        
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
        
        var countdownNumberEl = $('.seconds');
        var countdown = diffInMilliSeconds;
        countdownNumberEl.text(countdown);
        
        var countMin = $('.minutes')
        var countmin=minutes;
        countMin.text(countmin);
        
        var countHour = $('.hours')
        var counthour=hours;
        countHour.text(counthour);
        
        var countDay = $('.days')
        var countday=days;
        countDay.text(countday);
        
        var timer=setInterval(timer_function,1000);
        function timer_function() {
        if(--countdown<= -1)
        {
          if(countmin!=0)
          {
          countdown=59;
          --countmin;
          }
          else
          {
          if(counthour!=0)
          {
            countdown=59
            countmin=59;
            --counthour;
          }
          else
          {
            if(countday!=0)
            { 
            countdown=59
            countmin=59;
            counthour=23;
            --countday;
            }
            else
            {
            countdown=0;
              if (countdown==0&&countmin==0&&counthour==0)
              {
              var myobj=document.querySelectorAll('.offer-strip');
              myobj.remove();
              clearInterval(timer);
              }
            }
            }
          }
          }
        else
        {
          countdown=countdown
        }
        
        
        countdownNumberEl.text(countdown);
        countMin.text(countmin);
        countHour.text(counthour);
        countDay.text(countday);
        } 
        }
        else{
        var myobj=$('.offer-strip');
        myobj.remove();
        }
        }
        else if((country_name === "CAN" || country_name === "US" || country_name === "CA") && genaicontent && genaileadpagescontent){
            var date = new Date();
            var now = new Date();
            var diffInMilliSeconds = (moment((genaicontent && genaicontent.latestOffersUsAndCanada.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
            if (diffInMilliSeconds >0){
    
            
            var days = Math.floor( diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;
            
            const hours = Math.floor(diffInMilliSeconds / 3600);
            diffInMilliSeconds -= hours * 3600;
            
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
            
            var countdownNumberEl = $('.seconds');
            var countdown = diffInMilliSeconds;
            countdownNumberEl.text(countdown);
            
            var countMin = $('.minutes')
            var countmin=minutes;
            countMin.text(countmin);
            
            var countHour = $('.hours')
            var counthour=hours;
            countHour.text(counthour);
            
            var countDay = $('.days')
            var countday=days;
            countDay.text(countday);
            
            var timer=setInterval(timer_function,1000);
            function timer_function() {
            if(--countdown<= -1)
            {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
                countdown=59
                countmin=59;
                --counthour;
            }
            else
            {
                if(countday!=0)
                { 
                countdown=59
                countmin=59;
                counthour=23;
                --countday;
                }
                else
                {
                countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=document.querySelectorAll('.offer-strip');
                myobj.remove();
                clearInterval(timer);
                }
                }
                }
            }
            }
            else
            {
            countdown=countdown
            }
            
            
            countdownNumberEl.text(countdown);
            countMin.text(countmin);
            countHour.text(counthour);
            countDay.text(countday);
            } 
            }
            else{
            var myobj=$('.offer-strip');
            myobj.remove();
            }
        }
        else if((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && genaicontent && genaileadpagescontent){
            var date = new Date();
            var now = new Date();
            var diffInMilliSeconds = (moment((genaicontent && genaicontent.latestOffersRestOfWorld.discountEndDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
            if (diffInMilliSeconds >0){
    
            
            var days = Math.floor( diffInMilliSeconds / 86400);
            diffInMilliSeconds -= days * 86400;
            
            const hours = Math.floor(diffInMilliSeconds / 3600);
            diffInMilliSeconds -= hours * 3600;
            
            const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
            diffInMilliSeconds -= minutes * 60;
            diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
            
            var countdownNumberEl = $('.seconds');
            var countdown = diffInMilliSeconds;
            countdownNumberEl.text(countdown);
            
            var countMin = $('.minutes')
            var countmin=minutes;
            countMin.text(countmin);
            
            var countHour = $('.hours')
            var counthour=hours;
            countHour.text(counthour);
            
            var countDay = $('.days')
            var countday=days;
            countDay.text(countday);
            
            var timer=setInterval(timer_function,1000);
            function timer_function() {
            if(--countdown<= -1)
            {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
                countdown=59
                countmin=59;
                --counthour;
            }
            else
            {
                if(countday!=0)
                { 
                countdown=59
                countmin=59;
                counthour=23;
                --countday;
                }
                else
                {
                countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=document.querySelectorAll('.offer-strip');
                myobj.remove();
                clearInterval(timer);
                }
                }
                }
            }
            }
            else
            {
            countdown=countdown
            }
            
            
            countdownNumberEl.text(countdown);
            countMin.text(countmin);
            countHour.text(counthour);
            countDay.text(countday);
            } 
            }
            else{
            var myobj=$('.offer-strip');
            myobj.remove();
            }
        }
    }, [genaicontent, genaileadpagescontent])

    const playVideo = () => {
        $('#modalVideo').trigger('play');
    }

    const playVideoTestimonial = (videoNumber) => {
        $(`#modalVideo${videoNumber}`).trigger('play');
    }

    const saveLeadForm = (e, formname) => {
        e.preventDefault();
        let country_code = '+' + iti1.getSelectedCountryData().dialCode;
        //   if(fullname == ''){
        //     $('.error').remove();
        //     $('#genaifullname').closest('.col-12').append(`<small class="text-danger error text-start d-block">Please Enter Your Full Name</small>`);
        //     $('#genaifullname').css('border-color','red');
        //     $('#genaifullname').css('color','red');
        //     setTimeout(()=>{
        //         $('#genaifullname').css('border-color','#383838');
        //         $('#genaifullname').css('color','white');
        //         $('.error').slideUp();
        //         $('.error').remove();
        //     },3000)
        //     return false;
        // }     
        if (!validatedMobile(phone)) {
            $('.error').remove();
            $('#genaiNumber').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid phone number</small>`);
            $('#genaiNumber').css('border-color', 'red');
            $('#genaiNumber').css('color', 'red');
            setTimeout(() => {
                $('#genaiNumber').css('border-color', '#383838');
                $('#genaiNumber').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        if (!validatedEmail(email)) {
            $('.error').remove();
            $('#genaiEmail').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid email Id</small>`);
            $('#genaiEmail').css('border-color', 'red');
            $('#genaiEmail').css('color', 'red');
            setTimeout(() => {
                $('#genaiEmail').css('border-color', '#383838');
                $('#genaiEmail').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        let user_data = {
            "email": email,
            "phone": phone,
            "country_code": country_code,
        }
        let additional_detail = {
            "tnc": true,
            "is_whatsapp": true,
            "section": "hero_fold",
            "page_url": window.location.href
        }
        setFullname('');
        setPhone('');
        setEmail('');
        if (formname === 'projects') {
            setSuccessMessage('Projects Are on Their Way to Your Inbox')
        }
        else if (formname === 'curriculum') {
            setSuccessMessage('Curriculum Sent. Check Your Inbox')
        }
        else if (formname === 'roadmap') {
            setSuccessMessage('Roadmap on Its Way! Check Your Inbox')
        }
        else if (formname === 'instructor_profile') {
            setSuccessMessage('Profiles on the way! Check Your Inbox')
        }
        else if (formname === 'brochure') {
            setSuccessMessage('Generating Brochure. Check Your Inbox')
        }
        else if (formname === 'tools') {
            setSuccessMessage('Tools on the way. Check Your Inbox')
        }
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
            setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        }, 4000)
        submitRegisterationForm(user_data, additional_detail, 'genaipinnacle', formname, csrf)
            .then((data) => {

            })
            .catch((error) => {
                console.log(error)
                // setIsError(true);
                // setTimeout(()=>{
                //   setIsError(false);
                // },4000)
            })
    }

    const toggleEmiTab = (e) => {
        const tab = $(e.target).prop('checked');
        if (tab) {
            $('#toggleCard1').removeClass('active');
            $('#toggleCard2').addClass('active');
        }
        else {
            $('#toggleCard2').removeClass('active');
            $('#toggleCard1').addClass('active');
        }
    }

    const updateExperience = (value) => {
        setAdditionalDetail((prev) => { return ({ ...prev, experience: value }) })
    }

    const saveContactForm = (e) => {
        e.preventDefault();
        let country_code = '+' + iti2.getSelectedCountryData().dialCode;
        if (userDetails.fullname === '') {
            $('.error').remove();
            $('#contactFullName').parent().append(`<small class="text-danger error d-block">Please Enter Your Full Name</small>`);
            $('#contactFullName').css('border-color', 'red');
            $('#contactFullName').css('color', 'red');
            setTimeout(() => {
                $('#contactFullName').css('border-color', '#383838');
                $('#contactFullName').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 3000)
            return false;
        }
        if (!validatedMobile(userDetails.phone)) {
            $('.error').remove();
            $('#contactPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a valid phone number</small>`);
            $('#contactPhoneNumber').css('border-color', 'red');
            $('#contactPhoneNumber').css('color', 'red');
            setTimeout(() => {
                $('#contactPhoneNumber').css('border-color', '#383838');
                $('#contactPhoneNumber').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 2000)
            return false;
        }
        if (!validatedEmail(userDetails.email)) {
            $('.error').remove();
            $('#contactEmail').parent().append(`<small class="text-danger error d-block">Enter a valid email id</small>`);
            $('#contactEmail').css('border-color', 'red');
            $('#contactEmail').css('color', 'red');
            setTimeout(() => {
                $('#contactEmail').css('border-color', '#383838');
                $('#contactEmail').css('color', 'white');
                $('.error').slideUp();
                $('.error').remove();
            }, 2000)
            return false;
        }

        if(!captcha){
            setIsError(true);
                setTimeout(() => {
                  setIsError(false);
            }, 2000);
            return false;
        }

        let user_data = {
            "fullname": userDetails.fullname,
            "email": userDetails.email,
            "phone": userDetails.phone,
            "country_code": country_code,
        }
        let additional_detail = {
            "experience": additionalDetail.experience,
            "tnc": true,
            "is_whatsapp": true,
            "section": "contact",
            "page_url": window.location.href
        }
        setUserDetails({
            fullname: '',
            email: '',
            phone: '',
        });
        setAdditionalDetail({
            experience: "0-3yrs"
        })
        recaptchaRef.current?.reset();
        setCaptcha(false);
        $('#contact1').prop('checked', true);
        setSuccess(true);
        setTimeout(() => {
            setSuccess(false);
        }, 4000)
        submitRegisterationForm(user_data, additional_detail, 'genaipinnacle', "contact", csrf)
            .then((data) => {

            })
            .catch((error) => {
                console.log(error)
                // setIsError(true);
                // setTimeout(()=>{s
                //   setIsError(false);
                // },4000)
            })
    }

    const updateLeadForm = (formname, heading, cta, section, platform) => {
        setFormname(formname);
        setLeadFormHeading(heading);
        setLeadFormCta(cta);
        setSection(section);
        setPlatformType(platform);
    }


    function onChange(value) {
        setCaptcha(true);
    }


    return (
        <main className='background-dark-primary' id='genai'>
            <GenAiLeadHeader updateLeadForm={updateLeadForm} platformName={platformName} />

            <Title title={'Generative AI Course: In-Depth Training & Mentorship with AI Pinnacle Program'} description={'Explore Generative AI online: LLMs, reinforcement learning, and diffusion models. Elevate your AI skills. Join now.'} image_og={cloudflare_size_url + 'genaipinnacle/GenAI_og_image.jpg?format=avif'} title_og={'Generative AI Course: In-Depth Training & Mentorship with AI Pinnacle Program'} url_og={'https://www.analyticsvidhya.com/genaipinnacle'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />

            <GenAiTwoFieldLeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} csrf={csrf} platformName={platformType} />

            {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}

            {success && <SuccessAlert message={successMessage} />}

            {genaileadpagescontent && genaicontent && (<section id='firstFold' className='pb-5 bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative' style={{ backgroundImage: `url('https://imgcdn.analyticsvidhya.com/blackbelt/hero-image-bb.png')` }}>
                <div className='container justify-content-center align-items-center h-100'>
                    <div className='row justify-content-center align-items-center h-100'>
                        <div className='col-lg-8 mx-auto text-center position-realtive pt-5 px-4'>
                            <h1 className="fs-56 text-gradient mb-3 fw-semibold text-center">
                                <span className='text-white ms-2'>GenAI </span>
                                <span className='text-gradient'>Pinnacle </span>
                                <span className='text-white'>Program</span>
                            </h1>
                            <h2 className='text-white fs-24 mb-4 mx-auto px-2 text-center'>{genaileadpagescontent.firstFold.subtitle}</h2>
                            <ul className='text-white text-center'>
                                {genaileadpagescontent.firstFold.list.map((item, index) => (
                                    <li key={index}><i></i>{item}</li>
                                ))}
                            </ul>
                            {(country_name === "IN" && genaicontent && genaicontent.latestOffers.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {genaicontent && genaicontent.latestOffers.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            {((country_name === "CAN" || country_name === "US" || country_name === "CA") && genaicontent && genaicontent.latestOffersUsAndCanada.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {genaicontent && genaicontent.latestOffersUsAndCanada.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && genaicontent && genaicontent.latestOffersRestOfWorld.active) && <div className='offer-block position-absolute offer-strip'>
                                <a href='#offerSection' className='text-decoration-none text-light-primary fw-semibold'>
                                {genaicontent && genaicontent.latestOffersRestOfWorld.first_fold_text}
                                <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                                </svg>
                                </a>
                                <div className='text-white ms-auto mt-1 timmer-strip'>
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='days'></span>d 
                                </div>: 
                                <div className="timmer-box d-inline-block me-1">
                                    <span className='hours'></span>h
                                </div>:
                                <div className="timmer-box d-inline-block me-2">
                                    <span className='minutes'></span>m 
                                </div>:
                                <div className="timmer-box d-inline-block">
                                    <span className='seconds'></span>s
                                </div>
                                </div>
                            </div>}
                            <div className='enroll-number p-4 text-center text-white w-75 mx-auto rounded-3'>
                                <h4 className='fs-24 fw-semibold mb-4'>Become a Generative AI expert : Start Here</h4>
                                <form className="w-100" role="Phone">
                                    <div className="row align-items-baseline">
                                        {/* <div className="col-12 mb-3">
                                            <input className="form-control px-4 py-2" type="text" placeholder="Your Full Name" aria-label="Full name" value={fullname} onChange={(e)=>{setFullname(e.target.value)}} id='genaifullname'/>
                                        </div> */}
                                        <div className="col-12 col-xl mb-3 mb-xl-0">
                                            <input className="form-control px-4 py-2 ps-5" type="tel" placeholder="Your Phone Number" aria-label="Phone No." min={0} value={phone} onChange={(e) => { setPhone(e.target.value) }} id="genaiNumber" autocomplete="off" />
                                        </div>
                                        <div className="col-12 col-xl mb-3 mb-xl-0">
                                            <input className="form-control px-4 py-2" type="email" placeholder="Your Email Id" value={email} onChange={(e) => { setEmail(e.target.value) }} id="genaiEmail" autocomplete="off" />
                                        </div>
                                    </div>
                                    <div className='text-start mt-3'>
                                        <div className="form-check form-check-inline mb-3">
                                            <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked disabled />
                                            <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox1">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox2" value="option2" defaultChecked />
                                            <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox2">Send WhatsApp Updates</label>
                                        </div>
                                    </div>

                                    {
                                        platformName === 'genai-tools' && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'tools')}>Download Toolkit</button>
                                        )
                                    }
                                    {
                                        platformName === 'genai-mentors' && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'instructor_profile')}>Download Instructors Profile</button>
                                        )
                                    }
                                    {
                                        platformName === 'genai-roadmap' && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'roadmap')}>Download Your Roadmap</button>
                                        )
                                    }

                                    {
                                        platformName === 'genai-projects' && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'projects')}>Download Projects</button>
                                        )
                                    }

                                    {
                                        platformName === 'genai-brochure' && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'brochure')}>Download Brochure</button>
                                        )
                                    }

                                    {
                                        ((platformName === 'genai-curriculum') || (platformName === 'genai-llm') || (platformName === 'genai-stable-diffusion')) && (
                                            <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={(e) => saveLeadForm(e, 'curriculum')}>Download Curriculum</button>
                                        )
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>)}

            
            <Suspense fallback={<div>Loading</div>}>
                <GenAiLeadPagesTwoFieldSwiperComponent1 platformName={platformName} updateLeadForm={updateLeadForm} />
            </Suspense>

            {
                platformName === 'genai-roadmap' && (
                    <>
                        {genaicontent && genaileadpagescontent && <section className='common-style background-dark-primary position-relative' id='roadMap'>
                            <div className="container">
                                <div className='text-white col-lg-8 mb-5 heading-with-text text-center mx-auto'>
                                    <h2 className='fs-40 fw-normal mb-3'>{genaileadpagescontent && genaileadpagescontent.secondFold.title}</h2>
                                    <p>{genaileadpagescontent && genaileadpagescontent.secondFold.subtitle}</p>
                                </div>
                                <ul className='roadmap-mobile'>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>1</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Introduction to Gen AI</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>2</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Human Decision Making and its Biases</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>3</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Structured Approach to Problem Solving</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>4</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Applied ML, DL, NLP</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>5</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Getting Started with Large Language Models</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>6</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Building LLM Applications using Prompt Engineering</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>7</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Building Production Ready RAG systems using LlamaIndex</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>8</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Fine Tuning LLMs</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>9</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Training LLMs from Scratch</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>10</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Getting started with Stable Diffusion</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>11</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Mastering Methods and Tools of Stable Diffusion</span>
                                    </li>
                                    <li className='text-start d-flex align-items-center'>
                                    <span className='roadmap-number rounded-circle d-flex align-items-center justify-content-center'>12</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="2" viewBox="0 0 51 2" fill="none">
                                        <rect x="50.5" y="1.5" width="50" height="1" transform="rotate(180 50.5 1.5)" fill="url(#paint0_linear_6010_20964)"/>
                                        <defs>
                                        <linearGradient id="paint0_linear_6010_20964" x1="100.5" y1="2.00005" x2="50.5" y2="2.00003" gradientUnits="userSpaceOnUse">
                                            <stop stop-color="white"/>
                                            <stop offset="1" stop-color="white" stop-opacity="0"/>
                                        </linearGradient>
                                        </defs>
                                    </svg>
                                    <span className='roadmap-text fs-14'>Advanced Stable Diffusion Techniques</span>
                                    </li>
                                </ul>
                            </div>
                            <img src={cloudflare_size_url + (genaicontent && genaicontent.personalisedRoadmap.image.src + '?format=webp')} className="img-fluid mb-5 w-100" alt={genaicontent && genaicontent.personalisedRoadmap.image.alt} />
                            <div className="container">
                                <button className='btn btn-dark btn-dark-primary rounded-3 mx-auto d-table' id='roadmapBtn' data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={() => { updateLeadForm('roadmap', 'Your GenAI Blueprint', "Create My Roadmap", "roadmap", "genaipinnacle") }}>Get Personalized Roadmap</button>
                            </div>
                        </section>}
                    </>
                )
            }

            
            <Suspense fallback={<div>Loading</div>}>
                <GenAiLeadPagesTwoFieldSwiperComponent2 platformName={platformName}  updateLeadForm={updateLeadForm} />
            </Suspense>

            {
                platformName === 'genai-brochure' && (
                    <>
                        {genaileadpagescontent && <section className='common-style'>
                            <div className='container'>
                                <div className='text-white mx-auto text-center col-lg-8 heading-with-text'>
                                    <h2 className='fs-40 fw-normal mb-3'>{genaileadpagescontent && genaileadpagescontent.secondFold.title}</h2>
                                </div>
                                <div className='card text-bg-dark p-5 border-0 rounded-3 mt-5'>
                                    <div className='row'>
                                        {genaileadpagescontent && genaileadpagescontent.secondFold.headings.map((headingObj, index) => {
                                            return (
                                                <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12 mb-4 mb-lg-0 px-3'>
                                                    <div className='key-points' key={index}>
                                                        <h3 className='fs-24 fw-semibold mb-4'>{headingObj.text}</h3>
                                                        <ul className={`${(index == genaileadpagescontent.secondFold.headings.length - 1) ? 'mb-4 mb-md-0' : 'mb-4'} ps-3`}>
                                                            {headingObj.points.map((point, index) => {
                                                                return <li className='mb-4 fs-16 text-dark-secondary' key={index}>{point.text}</li>
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </section>}
                    </>
                )
            }

            {
                ((platformName === 'genai-curriculum') || (platformName === 'genai-llm') || (platformName === 'genai-stable-diffusion')) && (
                    <>
                        {genaileadpagescontent && genaicontent && <div className='common-style container position-relative z-3' id='chooseLearn'>
                            <div className='text-white col-lg-8 heading-with-text mx-auto text-center'>
                                <h2 className='fs-40 fw-normal mb-3'>{genaileadpagescontent && genaileadpagescontent.secondFold.title}</h2>
                                <p>{genaileadpagescontent && genaileadpagescontent.secondFold.subtitle}</p>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                                    <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{genaicontent && genaicontent.chooseWhatToLearn.projects}</div>
                                    <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{genaicontent && genaicontent.chooseWhatToLearn.hours}</div>
                                    <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{genaicontent && genaicontent.chooseWhatToLearn.tools}</div>
                                    <div className="background-dark-tertiary p-2 text-white d-inline-block mb-3 rounded-1 me-2">{genaicontent && genaicontent.chooseWhatToLearn.assignments}</div>
                                    <div className="accordion" id="accordionChoose">
                                        {genaicontent && genaicontent.chooseWhatToLearn.courseDetails.map((section, index) => {
                                            return (
                                                <div className="accordion-item border-0" key={index}>
                                                    <h2 className="accordion-header mb-3">
                                                        <button className={`accordion-button border-0 text-white rounded-3 ${index !== 0 && "collapsed"}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded={index === 0 ? "true" : "false"} aria-controls={`collapse${index}`}>
                                                            <div className="content">
                                                                <p className='fs-20 m-0'>{section.heading}</p>
                                                                <p className='mb-0 d-flex align-items-center flex-wrap d-none'>
                                                                    {section.projects &&
                                                                        <div>
                                                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <g clip-path="url(#clip0_8995_145943)">
                                                                                    <path d="M11.4224 2.48873L8.48968 5.42145C8.17043 5.74071 8.17043 6.25832 8.48968 6.57757L11.4224 9.5103C11.7417 9.82955 12.2593 9.82955 12.5785 9.5103L15.5113 6.57757C15.8305 6.25832 15.8305 5.74071 15.5113 5.42145L12.5785 2.48873C12.2593 2.16947 11.7417 2.16947 11.4224 2.48873Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M17.4224 8.48873L14.4897 11.4215C14.1704 11.7407 14.1704 12.2583 14.4897 12.5776L17.4224 15.5103C17.7417 15.8295 18.2593 15.8295 18.5785 15.5103L21.5113 12.5776C21.8305 12.2583 21.8305 11.7407 21.5113 11.4215L18.5785 8.48873C18.2593 8.16947 17.7417 8.16947 17.4224 8.48873Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M5.42241 8.4897L2.48968 11.4224C2.17043 11.7417 2.17043 12.2593 2.48968 12.5785L5.42241 15.5113C5.74166 15.8305 6.25927 15.8305 6.57853 15.5113L9.51125 12.5785C9.8305 12.2593 9.8305 11.7417 9.51125 11.4224L6.57852 8.4897C6.25927 8.17045 5.74166 8.17045 5.42241 8.4897Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    <path d="M11.4224 14.4897L8.48968 17.4224C8.17043 17.7417 8.17043 18.2593 8.48968 18.5785L11.4224 21.5113C11.7417 21.8305 12.2593 21.8305 12.5785 21.5113L15.5113 18.5785C15.8305 18.2593 15.8305 17.7417 15.5113 17.4224L12.5785 14.4897C12.2593 14.1705 11.7417 14.1705 11.4224 14.4897Z" stroke="white" stroke-width="0.9" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </g>
                                                                                <defs>
                                                                                    <clipPath id="clip0_8995_145943">
                                                                                        <rect width="24" height="24" fill="white" />
                                                                                    </clipPath>
                                                                                </defs>
                                                                            </svg>
                                                                            <span className='me-4'>{section.projects} {section.projects == 1 ? 'Project' : 'Projects'}</span>
                                                                        </div>}
                                                                    {section.assignments &&
                                                                        <div>
                                                                            <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path d="M22 16.7399V4.66994C22 3.46994 21.02 2.57994 19.83 2.67994H19.77C17.67 2.85994 14.48 3.92994 12.7 5.04994L12.53 5.15994C12.24 5.33994 11.76 5.33994 11.47 5.15994L11.22 5.00994C9.44 3.89994 6.26 2.83994 4.16 2.66994C2.97 2.56994 2 3.46994 2 4.65994V16.7399C2 17.6999 2.78 18.5999 3.74 18.7199L4.03 18.7599C6.2 19.0499 9.55 20.1499 11.47 21.1999L11.51 21.2199C11.78 21.3699 12.21 21.3699 12.47 21.2199C14.39 20.1599 17.75 19.0499 19.93 18.7599L20.26 18.7199C21.22 18.5999 22 17.6999 22 16.7399Z" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M12 5.48999V20.49" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M7.75 8.48999H5.5" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                <path d="M8.5 11.49H5.5" stroke="#DEDEDE" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                            <span>{section.assignments}  {section.assignments == 1 ? 'Assignment' : 'Assignments'}</span>
                                                                        </div>}
                                                                    &nbsp;
                                                                </p>
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    <div id={`collapse${index}`} className={`accordion-collapse collapse ${index === 0 && 'show'}`} data-bs-parent="#accordionChoose">
                                                        <div className="accordion-body border-0">
                                                            <ol className='px-2 px-md-4'>
                                                                {section.lectures.map((obj) => {
                                                                    return (
                                                                        <li>
                                                                            <h4 className='fs-16 ms-4'>{obj}</h4>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 position-relative">
                                    <div className="video-thumb position-relative mx-auto d-table mt-lg-0 mt-4">
                                        <img src={genaicontent && cloudflare_size_url + genaicontent.chooseWhatToLearn.image.src + "?format=webp&width=648"} alt={genaicontent && genaicontent.chooseWhatToLearn.image.alt} className="img-fluid rounded-2" />
                                        <button className="btn play-btn position-absolute rounded-circle d-flex align-items-center justify-content-center z-3 d-none">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='row mt-5'>
                                        <div className="col-12 col-lg-6">
                                            <button className="btn btn-dark btn-dark-primary rounded-3 me-0 me-md-3  mb-3 w-100" data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={() => { updateLeadForm('curriculum', 'Access Curriculum for Free', "Unlock Content", "curriculum", "genaipinnacle") }}>
                                                Download Free Curriculum
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </>
                )
            }


            {(country_name === "IN" && genaicontent && genaicontent.latestOffers.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
                <div className='container'>
                <div className='row'>
                    <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{genaicontent && genaicontent.latestOffers.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{genaicontent && genaicontent.latestOffers.discountPercentage}</h3>
                        <ul className='text-white'>
                            {genaicontent && genaicontent.latestOffers.points.map((point,index)=>{
                            return(
                                <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                            })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}}>Enroll Now</button>
                        <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                            <h4 className='text-white'>{genaicontent && genaicontent.latestOffers.text}</h4>
                            <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                                <h4 className='days'>1</h4>
                                <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='hours'>5</h4>
                                <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='minutes'>35</h4>
                                <h5>min</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='seconds'>20</h4>
                                <h5>Sec</h5>
                            </li>
                            </ul>
                            <a  data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                                {genaicontent && genaicontent.latestOffers.cta.text}
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>}

            {((country_name === "CAN" || country_name === "US" || country_name === "CA") && genaicontent && genaicontent.latestOffersUsAndCanada.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
                <div className='container'>
                <div className='row'>
                    <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{genaicontent && genaicontent.latestOffersUsAndCanada.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{genaicontent && genaicontent.latestOffersUsAndCanada.discountPercentage}</h3>
                        <ul className='text-white'>
                            {genaicontent && genaicontent.latestOffersUsAndCanada.points.map((point,index)=>{
                            return(
                                <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                            })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}}>Enroll Now</button>
                        <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                            <h4 className='text-white'>{genaicontent && genaicontent.latestOffersUsAndCanada.text}</h4>
                            <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                                <h4 className='days'>1</h4>
                                <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='hours'>5</h4>
                                <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='minutes'>35</h4>
                                <h5>min</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='seconds'>20</h4>
                                <h5>Sec</h5>
                            </li>
                            </ul>
                            <a  data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                                {genaicontent && genaicontent.latestOffersUsAndCanada.cta.text}
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>}

            {((country_name !== "IN" && country_name !== "US" && country_name !== "CA" && country_name !== "CAN") && genaicontent && genaicontent.latestOffersRestOfWorld.active) && <section className='common-style background-dark-primary offer-strip pb-5' id='offerSection'>
                <div className='container'>
                <div className='row'>
                    <div className='col-xl-10 col-lg-10 col-md-12 col-sm-12 mx-auto'>
                    <div className='row align-items-center'>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <h2 className='fs-40 fw-normal mb-3 text-white'>{genaicontent && genaicontent.latestOffersRestOfWorld.heading}</h2>
                        <h3 className='fs-56 my-3 fw-bold'>{genaicontent && genaicontent.latestOffersRestOfWorld.discountPercentage}</h3>
                        <ul className='text-white'>
                            {genaicontent && genaicontent.latestOffersRestOfWorld.points.map((point,index)=>{
                            return(
                                <li className='fs-20 mb-4' key={index}>{point}</li>
                            )
                            })}
                        </ul>
                        <button class="btn btn-dark btn-dark-primary rounded-3 mt-3 me-3" data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}}>Enroll Now</button>
                        <a class="btn btn-dark btn-dark-primary rounded-3 mt-3 d-none" href='#personalised'>Get Details</a>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                        <div className='timmer-card'>
                            <h4 className='text-white'>{genaicontent && genaicontent.latestOffersRestOfWorld.text}</h4>
                            <ul className='text-white d-flex w-100 list-unstyled align-items-center p-3 justify-content-around'>
                            <li className='text-center'>
                                <h4 className='days'>1</h4>
                                <h5>Day</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='hours'>5</h4>
                                <h5>hr</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='minutes'>35</h4>
                                <h5>min</h5>
                            </li>
                            <li className='text-center'>
                                <h4 className='seconds'>20</h4>
                                <h5>Sec</h5>
                            </li>
                            </ul>
                            <a  data-bs-toggle="modal" data-bs-target="#genaiLeadModal" onClick={()=>{updateLeadForm('enroll','Enroll Today, Excel Tomorrow',"Enroll Now","offer")}} className='text-decoration-none pointer' rel="noopener noreferrer">
                            <div className='offer-cta text-light-primary'>
                                {genaicontent && genaicontent.latestOffersRestOfWorld.cta.text}
                                <svg className='ms-2' xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                                <path d="M1 8.65039H15M15 8.65039L8 1.65039M15 8.65039L8 15.6504" stroke="#383838" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            </a>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </section>}

            {genaicontent && <section className='common-style background-dark-primary pb-5' id='contact'>
                <div className='container'>
                    <div className='text-white heading-with-text mb-5 text-center'>
                        <h2 className='fs-40 fw-normal mb-3 fs-18'> {genaicontent && genaicontent.contactUsToday.heading}</h2>
                        <p>{genaicontent && genaicontent.contactUsToday.subheading}</p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-7 col-md-12 col-sm-12 mb-3'>
                            <div className="card background-dark-secondary shadow-sm rounded-4 py-3 border border-1">
                                <div className="card-header border-0">
                                    <h2 className='text-center fs-24 text-white fw-medium'>Upskill, Reskill, Thrive</h2>
                                </div>
                                <div className="card-body border-0">
                                    <div className="row">
                                        <div className="col-xl col-md-12 col-12 mb-3">
                                            <label htmlFor="contactFullName" className="form-label text-white fs-14">Full Name</label>
                                            <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactFullName" placeholder="Your Full Name" autoComplete='off' value={userDetails.fullname} onChange={e => setUserDetails((prev) => { return { ...prev, fullname: e.target.value } })} />
                                        </div>
                                        <div className="col-xl col-md-12 col-12 mb-3">
                                            <label htmlFor="contactPhoneNumber" className="form-label text-white fs-14">Phone Number</label>
                                            <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="contactPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e => setUserDetails((prev) => { return { ...prev, phone: e.target.value } })} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xl col-md-12 col-12">
                                            <label htmlFor="contactEmail" className="form-label text-white fs-14">Email Id</label>
                                            <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="contactEmail" placeholder="Your Email Id" autoComplete='off' value={userDetails.email} onChange={e => setUserDetails((prev) => { return { ...prev, email: e.target.value } })} />
                                        </div>
                                        <div className="col-xl col-md-12 col-12 my-3">
                                            <label htmlFor="leadFullName" className="form-label text-white fs-14">Experience :</label>
                                            <div className="experience-tag">
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact1"} name="experience" onChange={() => { updateExperience("0-3yrs") }} defaultChecked />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact1"}>0-3yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact2"} name="experience" onChange={() => { updateExperience("3-8yrs") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact2"}>3-8yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact3"} name="experience" onChange={() => { updateExperience("8-12yrs") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact3"}>8-12yrs</label>
                                                <input type='radio' className="form-check-input me-2 d-none" id={"contact4"} name="experience" onChange={() => { updateExperience("12yrs+") }} />
                                                <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"contact4"}>12yrs+</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 mb-2 d-flex flex-wrap">
                                        <div className="form-check d-flex align-items-center mb-2 me-4">
                                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                                        </div>
                                        <div className="form-check d-flex align-items-center">
                                            <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappContactModal" defaultChecked />
                                            <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappContactModal">Send WhatsApp Updates</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer border-0">
                                    <ReCAPTCHA
                                        className='mb-3'
                                        ref={recaptchaRef}
                                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={onChange}
                                    />
                                    <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center" onClick={saveContactForm} disabled={!captcha}>Get Started</button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-5 col-md-12 col-sm-12 mb-3 ps-lg-5'>
                            <h3 className='text-white'>{genaicontent && genaicontent.contactUsToday.support.heading}</h3>
                            <p className='text-dark-tertiary mt-4 fs-20 fw-light mb-5'>{genaicontent && genaicontent.contactUsToday.support.subheading}</p>
                            <ul>
                                <li className='row mb-3'>
                                    <div className='col-auto mb-3'>
                                        <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                                            <img src={cloudflare_url + genaicontent.contactUsToday.support.items[0].icon} alt={genaicontent.contactUsToday.support.items[0].icon} width={24} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md'>
                                        <p className='fw-bold fs-16 text-white mb-0 text-break'><a className='text-white' href='tel:+91-9354711240'>+91-9354711240</a></p>
                                        <span className='d-block text-dark-tertiary'>{genaicontent.contactUsToday.support.items[0].text}</span>
                                    </div>
                                </li>
                                <li className='row mb-3'>
                                    <div className='col-auto mb-3'>
                                        <div className='icon-box rounded-circle background-dark-tertiary p-3'>
                                            <img src={cloudflare_url + genaicontent.contactUsToday.support.items[1].icon} alt={genaicontent.contactUsToday.support.items[1].icon} width={24} className='img-fluid' />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md'>
                                        <a className='text-white' href='mailto:Training_queries@analyticsvidhya.com'><p className='fw-bold fs-16 text-white mb-0 text-break'>{genaicontent.contactUsToday.support.items[1].heading}</p></a>
                                        <span className='d-block text-dark-tertiary'>{genaicontent.contactUsToday.support.items[1].text}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>}

            <Cookies platform={platformName} />

            <Footer platform={platformName} isWhatsApp={true}/>

        </main>
    )
}

const mapStateToProps = (state) => ({
    genaicontent: state.genAIContentReducer.genaicontent,
    loading: state.genAIContentReducer.loading,
    error: state.genAIContentReducer.error,
    country_name: state.genAIContentReducer.country_code,
    genaileadpagescontent: state.genaiLeadPagesContentReducer.genaileadpagescontent
});

const mapDispatchToProps = dispatch => ({
    fetchGenAIContentHandler: data => dispatch(fetchGenAIContent(data)),
    fetchGenaiLeadPagesContentHandler: data => dispatch(fetchGenaiLeadPagesContent(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GenAiLeadPagesTwoField);

import { FETCH_DATAHOUR_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    datahourcontent: null,
    loading: false,
    error: null,
};
  
export const datahourContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DATAHOUR_CONTENT_SUCCESS:
            return {
                ...state,
                datahourcontent: action.payload,
                loading: false,
                error: null,
        };
        default:
            return state;
    }
};
  
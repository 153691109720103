import { UPDATE_WELCOME_LOGIN_MODAL,CLEAR_CSRF } from "../Contants";

const initialState = {
    islogin:null,
    welcomeloginmodal:false,
    emailModal:false,
    otpModal:false,
    userData:{},
    csrf:""
};

export const LoginModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_WELCOME_LOGIN_MODAL:
            return {
                ...state,
                ...action.payload
        };
        case CLEAR_CSRF:
            return {
                ...state,
                csrf: ""
            }
        default:
            return state;
    }
};
  
import React, {useState} from 'react'
import { submitPlatformFormData } from '../../../../utils';

export default function HomepageForm({platform}) {
  
  const [formData, setFormData] = useState({
    title: '',
    subtitle: ''
  });

  const { title, subtitle } = formData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitPlatformFormData(platform,formData);
  };

  return (
    <div>
      <h3 className='text-center mb-3'>Homepage</h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title:</label>
          <input type="text"
            id="title"
            name="title"
            value={title}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subtitle" className="form-label">Subtitle:</label>
          <input
            type="text"
            id="subtitle"
            name="subtitle"
            value={subtitle}
            onChange={handleInputChange}
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary px-4 py-2 mx-auto mt-4 d-block">Submit</button>
      </form>
    </div>
  )
}

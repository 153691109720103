import React from 'react'

export default function SuccessAlert({message}) {
  return (
    <div className="toast toast-success text-white background-dark-tertiary border-0 show position-fixed z-3 rounded-2 p-4 row" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="d-flex align-items-center col">
            <div className="success-icon rounded-circle p-2 me-4">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20 7L9.70711 17.2929C9.31658 17.6834 8.68342 17.6834 8.29289 17.2929L4 13" stroke="#85D992" stroke-width="2" stroke-linecap="round"/>
                </svg>
            </div>
            <div className="toast-body fs-18 p-0">{message}</div>
        </div>
        <button type="button" className="btn-close btn-close-white m-0 me-2 col-auto ms-2" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
  )
}

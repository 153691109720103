import React from 'react'
import Header from '../../../../common/Header/Header';
import Footer from '../../../../common/Footer/Footer';
import Flashstrip from '../../../../common/Header/Flashstrip';
import Cookies from '../../../../common/Footer/Cookies';

const AllFreeCourses = () => {
  return (
    <main className='background-dark-primary'>
    <Header />
    <section id="heroEventListing" className='common-style pb-5 background-dark-primary'>
        <div className="container">
            <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item text-dark-tertiary">Courses</li>
                        <li class="breadcrumb-item active text-white" aria-current="page">Free Courses</li>
                    </ol>
                
            </nav>
            <h1 className='text-white mb-3 mb-md-4'>Free Courses</h1>
            <a href="https://courses.analyticsvidhya.com/courses/genai-a-way-of-life?ref=new_page_banner" className="banner-image-wrapper pointer" target='_blank' alt="Free Courses Banner">
                <img className='img-fluid rounded-2' src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/hero_banner.webp" width="1320" alt="Featured Session Banner" />
            </a>
        </div>
    </section>
    <section className='av-tab-design background-dark-secondary border-start-0 border-end-0' id='tabs'>
        <div className='container'>
            <div className='row align-items-center flex-nowrap overflow-auto'>
                <ul class="col nav nav-tabs py-4 border-0 flex-nowrap me-5" id="datahourListTabs" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-nowrap active" id="popular-tab" data-bs-toggle="tab" data-bs-target="#popular-tab-pane" type="button" role="tab" aria-controls="popular-tab-pane" aria-selected="true">Popular</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-nowrap" id="GenAI-tab" data-bs-toggle="tab" data-bs-target="#GenAI-tab-pane" type="button" role="tab" aria-controls="GenAI-tab-pane" aria-selected="false">GenAI</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-nowrap" id="machineLearning-tab" data-bs-toggle="tab" data-bs-target="#machineLearning-tab-pane" type="button" role="tab" aria-controls="machineLearning-tab-pane" aria-selected="false">Machine Learning</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-nowrap" id="deepLearning-tab" data-bs-toggle="tab" data-bs-target="#deepLearning-tab-pane" type="button" role="tab" aria-controls="deepLearning-tab-pane" aria-selected="false">Deep Learning</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link text-nowrap" id="dataAnalytics-tab" data-bs-toggle="tab" data-bs-target="#dataAnalytics-tab-pane" type="button" role="tab" aria-controls="dataAnalytics-tab-pane" aria-selected="false">Data Analytics</button>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section className="common-style-py common-card-style background-dark-primary">
        <div className="container">
            <div className="tab-content" id="eventsTabContent">
                <div class="tab-pane fade show active" id="popular-tab-pane" role="tabpanel" aria-labelledby="popular-tab" tabindex="0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/microsoft-excel-formulas-functions?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Microsoft%20Excel:%20Formulas%20&%20Functions.webp" alt="Microsoft Excel: Formulas & Functions" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-felx align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>2.45 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                32 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Microsoft Excel: Formulas & Functions</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-data-science?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Python.webp" alt="Introduction to Python.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-felx align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Python</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/Machine-Learning-Certification-Course-for-Beginners?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Machine%20Learning%20Certification%20Course%20for%20Beginners.webp" alt="Machine Learning Certification Course for Beginners.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-felx align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>5.5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                140+ Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Machine Learning Certification Course for Beginners</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/improving-real-world-rag-systems-key-challenges?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Improving%20Real-World%20RAG%20Systems:%20Key%20Challenges%20&%20Practical%20Solutions%20.webp" alt="Improving Real-World RAG Systems: Key Challenges & Practical Solutions .webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-felx align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>51 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                12 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Improving Real-World RAG Systems: Key Challenges & Practical Solutions</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/genai-a-way-of-life?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Generative%20AI%20-%20A%20Way%20of%20Life%20-%20Free%20Course.webp" alt="Generative AI - A Way of Life - Free Course.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>6 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                30 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Generative AI - A Way of Life - Free Course</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-llms?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20Started%20with%20Large%20Language%20Models.webp" alt="Getting Started with Large Language Models.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                16 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting Started with Large Language Models</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/building-llm-applications-using-prompt-engineering-free?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Building%20LLM%20Applications%20using%20Prompt%20Engineering%20-%20Free%20Course.webp" alt="Building LLM Applications using Prompt Engineering - Free Course.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>2 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                17 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building LLM Applications using Prompt Engineering - Free Course</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-decision-trees?ref=new_page_popular" rel="noreferrer" class="card-link text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20started%20with%20Decision%20Trees.webp" alt="Getting started with Decision Trees.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting started with Decision Trees</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/Intro-to-NLP?ref=new_page_popular" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20NLP.webp" alt="Introduction to Natural Language Processing" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Natural Language Processing</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/tableau-for-beginners?ref=new_page_popular" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Tableau%20for%20Beginners.webp" alt="Tableau for Beginners" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>15 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                17 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Tableau for Beginners</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/data-science-hacks-tips-and-tricks?ref=new_page_popular" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Data%20Science%20Hacks,%20Tips%20and%20Tricks.webp" alt="Data Science Hacks, Tips and Tricks" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>30 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                36 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Data Science Hacks, Tips and Tricks</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/a-comprehensive-learning-path-to-become-a-data-scientist-in-twenty-twenty-four?ref=new_page_popular" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/A%20Comprehensive%20Learning%20Path%20to%20Become%20a%20Data%20Scientist%20in%202024.webp" alt="A Comprehensive Learning Path to Become a Data Scientist in 2024" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>2 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                150+ Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">A Comprehensive Learning Path to Become a Data Scientist in 2024</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="tab-pane fade" id="GenAI-tab-pane" role="tabpanel" aria-labelledby="GenAI-tab" tabindex="0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/genai-a-way-of-life?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Generative%20AI%20-%20A%20Way%20of%20Life%20-%20Free%20Course.webp" alt="Generative AI - A Way of Life - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>6 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                30 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">GenAI - A Way of Life - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-llms?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20Started%20with%20Large%20Language%20Models.webp" alt="Getting Started with Large Language Models" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                16 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting Started with Large Language Models</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/building-llm-applications-using-prompt-engineering-free?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Building%20LLM%20Applications%20using%20Prompt%20Engineering%20-%20Free%20Course.webp" alt="Building LLM Applications using Prompt Engineering - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>2 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                17 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building LLM Applications using Prompt Engineering - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/building-first-rag-systems-using-llamaindex?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Building%20Your%20first%20RAG%20System%20using%20LlamaIndex%20-%20Free%20Course%20.webp" alt="Building Your first RAG System using LlamaIndex - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                12 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building Your first RAG System using LlamaIndex - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/improving-real-world-rag-systems-key-challenges?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Improving%20Real-World%20RAG%20Systems-%20Key%20Challenges%20&%20Practical%20Solutions%20.webp" alt="Improving Real-World RAG Systems: Key Challenges & Practical Solutions" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>51 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                12 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Improving Real-World RAG Systems: Key Challenges & Practical Solutions</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/creating-problem-solving-agents-using-genai-for-action-composition?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Creating%20Problem-Solving%20Agents%20using%20GenAI%20for%20Action%20Composition%20.webp" alt="Creating Problem-Solving Agents using GenAI for Action Composition" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>44 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                6 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Creating Problem-Solving Agents using GenAI for Action Composition</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/take/choosing-the-right-LLM-for-your-business/lessons/58630591-conclusion?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Framework%20to%20choose%20the%20right%20LLM%20for%20your%20Business.webp" alt="Framework to choose the right LLM for your Business" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>32 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                5 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Framework to choose the right LLM for your Business</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/exploring-stability-ai?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/%13Exploring%20Stability.webp" alt="Exploring Stability.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                7 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Exploring Stability.AI</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/midjourney_from_inspiration_to_implementation?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/MidJourney:%20From%20Inspiration%20to%20Implementation.webp" alt="MidJourney: From Inspiration to Implementation.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>33 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                5 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">MidJourney: From Inspiration to Implementation</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/nano-course-dreambooth-stable-diffusion-for-custom-images?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Stable%20Diffusion%20for%20Custom%20Images.webp" alt="Stable Diffusion for Custom Images.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                13 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Nano Course: Dreambooth-Stable Diffusion for Custom Images</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/building-large-language-models-for-code?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Nano%20Course:%20Building%20Large%20Language%20Models%20for%20Code.webp" alt="Nano Course: Building Large Language Models for Code.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>38 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                10 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Nano Course: Building Large Language Models for Code</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/cutting-edge-llm-tricks?ref=new_page_GenAI" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Nano%20Course:%20Cutting%20Edge%20LLM%20Tricks.webp" alt="Nano Course: Cutting Edge LLM Tricks.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>38 Mins
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                12 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Nano Course: Cutting Edge LLM Tricks</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="tab-pane fade" id="machineLearning-tab-pane" role="tabpanel" aria-labelledby="machineLearning-tab" tabindex="0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-ai-ml?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20AI%20&%20ML.webp" alt="Introduction to AI & ML.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>1.5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                17 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to AI & ML</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/Machine-Learning-Certification-Course-for-Beginners?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Machine%20Learning%20Certification%20Course%20for%20Beginners.webp" alt="Machine Learning Certification Course for Beginners" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>5.5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                140+ Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Machine Learning Certification Course for Beginners</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/free-understanding-linear-regression?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Understanding%20Linear%20Regression%20-%20Free%20Course.webp" alt="Understanding Linear Regression - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>50 Mins
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                10 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Understanding Linear Regression - Free Course</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/Fundamentals-of-Regression-Analysis?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Fundamentals%20of%20Regression%20Analysis.webp" alt="Fundamentals of Regression Analysis" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>40 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                24 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Fundamentals of Regression Analysis</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/K-Nearest-Neighbors-KNN-Algorithm?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/K-Nearest%20Neighbors%20(KNN)%20Algorithm%20in%20Python%20and%20R.webp" alt="K-Nearest Neighbors (KNN) Algorithm in Python and R" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>30 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                10 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">K-Nearest Neighbors (KNN) Algorithm in Python and R</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-decision-trees?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20started%20with%20Decision%20Trees.webp" alt="Getting started with Decision Trees" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting started with Decision Trees</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/naive-bayes?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/naive%20bayes%20.webp" alt="Naive Bayes" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>30 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                16 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Naive Bayes from Scratch</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/evaluation-metrics-for-machine-learning-models?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Evaluation%20Metrics%20for%20Machine%20Learning%20Models.webp" alt="Evaluation Metrics for Machine Learning Models" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                25 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Evaluation Metrics for Machine Learning Models</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/dimensionality-reduction-for-machine-learning?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/reduce%20the%20number%20of%20features%20in%20a%20dataset.webp" alt="Reduce the number of features in a dataset" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>30 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                23 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Dimensionality Reduction for Machine Learning</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/bagging-boosting-ML-Algorithms?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/machine%20learning%20ensemble%20.webp" alt="Machine Learning Ensemble" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                16 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Bagging and Boosting ML Algorithms - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/free-unsupervised-ml-guide?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/The%20A%20to%20Z%20of%20Unsupervised%20ML%20-%20Free%20Course.webp" alt="The A to Z of Unsupervised ML - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>52 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                13 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">The A to Z of Unsupervised ML - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/data-preprocessing?ref=new_page_ML" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Data%20Preprocessing%20on%20a%20Real-World%20Problem%20Statement%20-%20Free%20Course.webp" alt="Data Preprocessing on a Real-World Problem Statement - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                13 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Data Preprocessing on a Real-World Problem Statement - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3> 
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="tab-pane fade" id="deepLearning-tab-pane" role="tabpanel" aria-labelledby="deepLearning-tab" tabindex="0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/Intro-to-NLP?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20NLP.webp" alt="Introduction to NLP.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Natural Language Processing</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-neural-networks?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20Started%20with%20Neural%20Networks.webp" alt="Getting Started with Neural Networks.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>70 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                46 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting Started with Neural Networks</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/exploring-natural-language-processing-nlp-using-deep-learning?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Exploring%20Natural%20Language%20Processing%20(NLP)%20using%20Deep%20Learning.webp" alt="Exploring Natural Language Processing (NLP) using Deep Learning.webp" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>4.15 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                91 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Exploring Natural Language Processing (NLP) using Deep Learning</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                    <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/free-building-textclassification-natural-language-processing?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Building%20a%20Text%20Classification%20Model%20with%20Natural%20Language%20Processing%20-%20Free%20Course.webp" alt="Building a Text Classification Model with Natural Language Processing - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>1.15 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                16 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building a Text Classification Model with Natural Language Processing - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/getting-started-with-deep-learning?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Getting%20Started%20with%20Deep%20Learning.webp" alt="Getting Started with Deep Learning" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>8.5 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                300+ Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Getting Started with Deep Learning</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/building-your-first-computer-vision-model?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Building%20Your%20First%20Computer%20Vision%20Model%20-%20Free%20Course.webp" alt="Building Your First Computer Vision Model - Free Course" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>34 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                7 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building Your First Computer Vision Model - Free Course</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-pytorch-for-deeplearning?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20PyTorch%20for%20Deep%20Learning.webp" alt="Introduction to PyTorch for Deep Learning" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                28 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to PyTorch for Deep Learning</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-cloud?ref=new_page_DL" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Cloud.webp" alt="Introduction to Cloud" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>17 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                7 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Cloud</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
                <div class="tab-pane fade" id="dataAnalytics-tab-pane" role="tabpanel" aria-labelledby="dataAnalytics-tab" tabindex="0">
                    <div class="row g-4">
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-data-science?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Python.webp" alt="Introduction to Python" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>60 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                20 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Python</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/pandas-for-data-analysis-in-python?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Pandas%20for%20Data%20Analysis%20in%20Python.webp" alt="Pandas for Data Analysis in Python" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>30 Mins
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                25 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Pandas for Data Analysis in Python</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/microsoft-excel-formulas-functions?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Microsoft%20Excel:%20Formulas%20&%20Functions.webp" alt="Microsoft Excel: Formulas & Functions" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>2.45 Hours 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                32 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Microsoft Excel: Formulas & Functions</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-data-visualisation?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Data%20Visualization.webp" alt="Introduction to Data Visualization" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>19 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                6 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Data Visualization</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/introduction-to-analytics?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Business%20Analytics.webp" alt="Introduction to Business Analytics" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>57 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                32 Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Introduction to Business Analytics</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4"> 
                            <a href="https://courses.analyticsvidhya.com/courses/tableau-for-beginners?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Tableau%20for%20Beginners.webp" alt="Tableau for Beginners" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>15 Mins 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                17 Lessons
                                            </p>       
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Tableau for Beginners</h2>
                                        <div>
                                            <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">
                                                FREE 
                                            </h3> 
                                        </div>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-12 col-md-6 col-lg-4">
                            <a href="https://courses.analyticsvidhya.com/courses/Building-Data-Stories-using-Excel-Tableau?ref=new_page_DA" rel="noreferrer" class="text-decoration-none" target="_blank">
                                <div class="card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3">
                                    <div class="card-img position-relative overflow-hidden rounded-2">
                                        <img class="card-img-top rounded-2 img-fluid object-fit-contain" src="https://imgcdn.analyticsvidhya.com/free_courses/card_listing/Introduction%20to%20Business%20Analytics.webp" alt="Introduction to Business Analytics" loading="lazy" width="392" height="220"/>
                                    </div>
                                    <div class="card-body px-0">
                                        <div class="card-body-top d-flex align-items-center justify-content-between">
                                            <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                <span>9.2 Hour 
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none" class="mx-1">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                </span>
                                                160+ Lessons
                                            </p>
                                        </div>
                                        <h2 class="card-text text-white fs-20 mb-3 text-truncate-2">Building Data Stories using Excel and Tableau</h2>
                                        <h3 class="text-white fs-20 mb-3 d-flex justify-content-between align-items-start">FREE</h3>
                                    </div>
                                    <div class="card-footer border-0 px-0">
                                        <button class="btn btn-dark btn-dark-primary rounded-3 w-100">Enroll now</button>
                                    </div>
                                </div>
                            </a>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Footer />
    <Flashstrip />
    <Cookies platform={'freeCourses'}  />
    </main>
  )
}

export default AllFreeCourses;
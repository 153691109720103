import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryData } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import $ from 'jquery';

const GeneratDatahourCSV = ({ csrf }) => {
    const [datahours, setDatahours] = useState(null);
    const [datahourId, setDatahourId] = useState(null);
    const [selectedDatahourTitle, setSelectedDatahourTitle] = useState("");

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(()=>{
        const query = `
        query MyQuery {
            allDatahours {
                edges {
                    node {
                        sessionName
                        id
                    }
                }
            }
        }`
        getQueryData(query, "newevents")
            .then((data)=>{
                setDatahours(data?.data?.allDatahours?.edges);
            })
            .catch((error)=>{
                console.log(error);
            })
    },[]);

    const resetFormFields = () => {
        setSelectedDatahourTitle("");
        setDatahourId(null);
        document.getElementById("datahourIdValue").value = null;
    }

    const handleDatahourChange = (event) => {
        if (datahours.some((datahour) => {
            if (datahour.node.id === event.target.value) {
                setSelectedDatahourTitle(datahour.node.sessionName);
            }
            return datahour.node.id === event.target.value;
        })) {
            setDatahourId(event.target.value);
        } else {
            setDatahourId(null);
            setSelectedDatahourTitle("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        $('#csvGenerateBtn').addClass('d-none');
        $('#generatingBtn').removeClass('d-none');

        const formData = new FormData();
        formData.append("platform", 'newevents');
        formData.append("method", 'GET');
        formData.append("forward_user_data", "True");
        formData.append("url", 'download-registration-csv/?session_id='+datahourId);
        formData.append("timeout", 100);
    
        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
        method: 'POST',
        body:formData,
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        headers: {
            "X-CSRFToken":csrf,
        }
        })
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result)=> {
            if(result?.response?.url){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();

                const link = document.createElement('a');
                link.href = result?.response?.url;
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }   
            else {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            console.log('error', error);
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
        });

        formData.set("url", 'download-auto-subscribed-csv/?session_id='+datahourId);
        setTimeout(() => {
            fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
            headers: {
                "X-CSRFToken":csrf,
            }
            })
            .then(response => {
                $('#csvGenerateBtn').removeClass('d-none');
                $('#generatingBtn').addClass('d-none');
                if (!response.ok) {
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                    return false;
                }
                return response.json();
            })
            .then((result)=> {
                if(result?.response?.url){
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    resetFormFields();
    
                    const link2 = document.createElement('a');
                    link2.href = result?.response?.url;
                    document.body.appendChild(link2);
                    link2.click();
                    link2.parentNode.removeChild(link2);
                }   
                else {
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                    return false;
                }
            })
            .catch(error => {
                console.log('error', error);
                $('#csvGenerateBtn').removeClass('d-none');
                $('#generatingBtn').addClass('d-none');
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            });
        }, 1000);
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Error occured."} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"CSV Generated Successfully."} />}
            <section id='privateRegistration' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-3 text-center text-white fw-semibold text-uppercase">Generate Datahour CSV</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <span className=" text-white me-2">Select Datahour <sup className='text-danger'>*</sup></span>
                                            <input id="datahourIdValue" list="datahours" className='form-control' onChange={handleDatahourChange} placeholder="Select a datahour" required autoComplete='off'/>
                                            <datalist id="datahours">
                                                {datahours && datahours.map((datahour) => (
                                                    <option key={datahour.node.id} value={datahour.node.id}>
                                                        {datahour.node.sessionName}
                                                    </option>
                                                ))}
                                            </datalist>
                                            {selectedDatahourTitle && <p className="text-white pt-2">Selected Stage Name: <strong>{selectedDatahourTitle}</strong></p>}
                                        </div>

                                        <button type="submit" id='csvGenerateBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Generate CSV
                                        </button>
                                        <button id='generatingBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100 d-none' disabled>
                                            Generating CSV...
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneratDatahourCSV);
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import $ from 'jquery';
import { connect } from 'react-redux';
import { getSession } from '../../../utils/Login';

function Flashstrip() {
    let [flashstrip,setFlashtrip] = useState(null);

    useEffect(()=>{
        getSession()
        .then((data)=>{
            getFlashstripData(data.isAuthenticated);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const getFlashstripData  = (islogin)=>{
        let currentHost = window.location.hostname;
        let currentPath = window.location.href;

        let data = {
            currentHost: currentHost,
            currentPath: currentPath,
            isAuthenticated: islogin
        };
        fetch(process.env.REACT_APP_FLASHSTRIP, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Handle the response data here
            setFlashtrip(data);
            if(data.is_banner){
                let utm_params = "";
                if(window.location.pathname.includes('competitions')){
                    utm_params = "utm_source=competitions&utm_medium=navbar";
                }
                else{
                    utm_params = "utm_source=events&utm_medium=navbar";
                }
                if(data.banner_url.includes("?")){
                    $('#exploreBanner').attr('href',`${data.banner_url}&${utm_params}`);
                }else{
                    $('#exploreBanner').attr('href',`${data.banner_url}?${utm_params}`);
                }
                $("#exploreBanner img").attr('src',data.banner_image);
            }
            if(data.is_popup){
                $("#popModal #popHref").attr('href',`${data.popup_url}`);
                $("#popModal #popImage").attr('src',`${data.popup_image}`);
                setTimeout(() => {
                    window.$('#popModal').modal("show");
                }, 20000);
            }
        })
        .catch(error => {
            // Handle errors here
            console.error('There was a problem with the fetch operation:', error);
        });
    }

    useEffect(()=>{
		// Timer Script
        if(flashstrip && flashstrip.is_flashstrip && flashstrip.timer_end_date){
        var date = new Date();
        var now = new Date();
        var diffInMilliSeconds = (moment((flashstrip && flashstrip.timer_end_date), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
        if (diffInMilliSeconds >0){

        
        var days = Math.floor( diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;
        
        const hours = Math.floor(diffInMilliSeconds / 3600);
        diffInMilliSeconds -= hours * 3600;
        
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;
        diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
        
        var countdownNumberEl = $('.countdown-number#second');
        var countdown = diffInMilliSeconds;
        countdownNumberEl.text(countdown);
        console.log(countdownNumberEl,countdown);
        
        var countMin = $('#minute')
        var countmin=minutes;
        countMin.text(countmin);
        
        var countHour = $('#hour')
        var counthour=hours;
        countHour.text(counthour);
        
        var countDay = $('#day')
        var countday=days;
        countDay.text(countday);
        
        var timer=setInterval(timer_function,1000);
        function timer_function() {
        if(--countdown<= -1)
        {
            if(countmin!=0)
            {
            countdown=59;
            --countmin;
            }
            else
            {
            if(counthour!=0)
            {
            countdown=59
            countmin=59;
            --counthour;
            }
            else
            {
            if(countday!=0)
            { 
            countdown=59
            countmin=59;
            counthour=23;
            --countday;
            }
            else
            {
            countdown=0;
                if (countdown==0&&countmin==0&&counthour==0)
                {
                var myobj=$('.offer-sticky');
                myobj.remove();
                clearInterval(timer);
                }
            }
            }
            }
            }
        else
        {
            countdown=countdown
        }
        
        
        countdownNumberEl.text(countdown);
        countMin.text(countmin);
        countHour.text(counthour);
        countDay.text(countday);
        } 
        }
        else{
        var myobj=$('.offer-sticky');
        myobj.remove();
        }
        }
  },[flashstrip])

  return (
    <>
        {flashstrip && flashstrip.is_flashstrip &&  <div className={`offer-sticky alert alert-dismissible fade show m-0 rounded-0 position-fixed z-2 bottom-0 w-100 4 ${flashstrip.is_gradient && "is-gradient"}`} id="avFlashSale" role="alert" >
            <div className="container">
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center flex-wrap p-0">
                        <p className="text-white fs-18 mb-0 col-12 col-md pe-5 pe-md-0">{flashstrip && flashstrip.text}</p>
                        <div className={`col col-md-auto d-flex align-items-center ${flashstrip && flashstrip.timer_end_date?"justify-content-between":"justify-content-center"}`}>
                            {flashstrip && flashstrip.timer_end_date && <div className="timmer-block align-items-center me-3" id="clockdiv" >
                                <ul className="count-row align-items-center mb-0">
                                    <li>
                                        <h6 className="count-day text-white fs-24 fw-semibold" id="day"></h6>
                                        <small className='text-white fs-16 fw-light'>d</small>
                                    </li>
                                    <span className='text-white mx-2 fw-bold'>:</span>
                                    <li>
                                        <h6 className="count-hour text-white fs-24 fw-semibold" id="hour"></h6>
                                        <small className='text-white fs-16 fw-light'>h</small>
                                    </li>
                                    <span className='text-white mx-2 fw-bold'>:</span>
                                    <li>
                                        <h6 className="count-min text-white fs-24 fw-semibold" id="minute"></h6>
                                        <small className='text-white fs-16 fw-light'>m</small>
                                    </li>
                                    <span className='text-white mx-2 fw-bold'>:</span>
                                    <li>
                                        <h6 className="countdown-number text-white fs-24 fw-semibold" id="second"></h6>
                                        <small className='text-white fs-16 fw-light'>s</small>
                                    </li>                  
                                </ul>
                            </div>}
                            {flashstrip && flashstrip.button_text && <a id="hrefId" href={flashstrip && flashstrip.url} target="_blank" className="btn bg-white px-4 py-2 rounded-2 ms-auto text-primary fs-16 fs-semibold">{flashstrip && flashstrip.button_text}</a>}
                        </div>
                    </div>
                </div>
                <button type="button" class="btn-close btn-close-white position-absolute" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>}
        <div className="modal fade" id="popModal" tabindex="-1" aria-labelledby="popModalLabel" aria-hidden="false">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                <div className="modal-body p-0">
                    <button type="button" className="btn-close btn-close-white position-absolute end-0 top-0 fs-20 m-3" data-bs-dismiss="modal" aria-label="Close"></button>
                    <a id="popHref" href="" target='_blank'>
                        <img id='popImage' className='img-fluid w-100 rounded-2' src="" alt="pop up" />
                    </a>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = dispatch=>({
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Flashstrip);

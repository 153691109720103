import React from 'react';

const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

export default function Loader() {
  return (
    <div id="avloader" className='bg-black vh-100 w-100 position-fixed start-0 top-0 d-flex align-items-center justify-content-center'>
        <img className='img-fluid rounded-3 shadow-sm p-2' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"av_loader.gif"} alt="Loader gif" width={150} height={150} />
    </div>
  )
}

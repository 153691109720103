import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const QuizCreate = ({csrf}) => {

    const [quizContext, setQuizContext] = useState(null);
    const [title, setTitle] = useState("");
    const [organizationId, setOrganizationId] = useState("");
    const [totalQuestions, setTotalQuestions] = useState(null);
    const [mcqWeightage, setMcqWeightage] = useState(null);
    const [codingWeightage, setCodingWeightage] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [maxAttempts, setMaxAttempts] = useState(null);
    const [passingCriteria, setPassingCriteria] = useState(null);
    const [subjects, setSubjects] = useState([]);
    const [selectedPool, setSelectedPool] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [quizType, setQuizType] = useState("");
    const [isTimer,setIsTimer] = useState(false);
    const [timer,setTimer] = useState(0);
    const [isQuestionTimer,setIsQuestionTimer] = useState(false);
    const [questionTimer,setQuestionTimer] = useState(0);
    const [newSubject, setNewSubject] = useState({
        subject: '',
        no_of_questions: 0,
        difficulty_level: '',
        positive_weightage: 0,
        negative_weightage: 0,
      });
    const [editingIndex, setEditingIndex] = useState(null);
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState('');

    useEffect(()=>{
        if(csrf !=""){
            quizContextData();
        }
    },[csrf]);
    
    const quizContextData = () => {
        const formData = new FormData();
        formData.append("url","quiz/context/");
        formData.append("platform", 'quizmania');
        formData.append("method", 'GET');

        fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
          })
            .then(response => response.json())
            .then(data => {
               console.log("Quiz related data is: ", data.response);
               setQuizContext(data?.response);
            })
            .catch(error => {
              console.log(error);
            });
    }

    const formatDateTime = (datetime) => {
        const dateObject = new Date(datetime);
        if (isNaN(dateObject.getTime())) {
            return null;
        }
        const formattedDate = dateObject.toISOString();
        return formattedDate;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formattedStartTime = formatDateTime(startTime);
        const formattedEndTime = formatDateTime(endTime);

        let payload = {
            "title" : title,
            "organization_id" : organizationId,
            "quiz_type" : quizType,
            "total_questions" : totalQuestions,
            "mcq_weightage" : mcqWeightage,
            "coding_weightage" : codingWeightage,
            "start_time" : formattedStartTime,
            "end_time" : formattedEndTime,
            "maximum_attempts" : maxAttempts,
            "passing_criteria" : passingCriteria,
            "pool" : selectedPool,
            "is_quiz_timer": isTimer?"True":"False",
            "quiz_time": timer,
            "is_question_timer": isQuestionTimer?"True":"False",
            "question_time": questionTimer,
            "subjects" : JSON.stringify(subjects),
            "platform_name": selectedPlatform,
        }

        console.log("subjects-----------------",payload.subjects);
        const formData = new FormData();
        formData.append("platform", 'quizmania');
        formData.append("payload", JSON.stringify(payload));
        formData.append("url", 'quiz/create/');
        formData.append("forward_user_data", "True");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => {
            return response.json();
        })
        .then(result => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                    window.location.reload();
                }, 3000);
                copyToClipboard(result.response.quiz_id);
            }
            else{
                setMessage(result.errors[0].message);
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            setMessage(error.message);
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
            console.log(error);
        });

    }

    const copyToClipboard = (text) => {
        var $tempInput = $("<input>");
        $("body").append($tempInput);
        $tempInput.val(text).select();
        document.execCommand("copy");
        $tempInput.remove();
      }
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setNewSubject((prevSubject) => ({
        ...prevSubject,
        [name]: value,
      }));
    };
  
    const handleAddSubject = () => {
      if (editingIndex !== null) {
        setSubjects((prevSubjects) => {
          const updatedSubjects = [...prevSubjects];
          updatedSubjects[editingIndex] = newSubject;
          return updatedSubjects;
        });
        setEditingIndex(null);
      } else {
        setSubjects((prevSubjects) => [...prevSubjects, newSubject]);
      }
  
      setNewSubject({
        subject: '',
        no_of_questions: 0,
        difficulty_level: '',
        positive_weightage: 0,
        negative_weightage: 0,
      });
    };
  
    const handleEditSubject = (index) => {
      setNewSubject(subjects[index]);
      setEditingIndex(index);
    };
  
    const handleDeleteSubject = (index) => {
      setSubjects((prevSubjects) => {
        const updatedSubjects = [...prevSubjects];
        updatedSubjects.splice(index, 1);
        return updatedSubjects;
      });
    };

    const handleSubjectChange = (index, field, newValue) => {
        setSubjects(prevSubjects => {
            const updatedSubjects = [...prevSubjects];
            updatedSubjects[index][field] = newValue;
            return updatedSubjects;
        });
    };
    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Quiz created successfully.Your quiz id copied."} />}
            <section id='createQuiz' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-12'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-3 text-center text-white text-uppercase fw-semibold">Request Quiz Creation</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Organization Id</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Organization Id' 
                                            value={organizationId}
                                            onChange={(event)=>setOrganizationId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Total Question <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Total Questions' 
                                            value={totalQuestions}
                                            onChange={(event)=>setTotalQuestions(event.target.value)}
                                            required
                                            min={0}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">MCQ Weightage <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            step='0.1' 
                                            placeholder='MCQ Weightage' 
                                            value={mcqWeightage}
                                            onChange={(event)=>setMcqWeightage(event.target.value)}
                                            required
                                            min={0}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Coding Weightage <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            step='0.1' 
                                            placeholder='Coding Weightage' 
                                            value={codingWeightage}
                                            onChange={(event)=>setCodingWeightage(event.target.value)}
                                            required
                                            min={0}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date</label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='Start Date' 
                                            value={startTime}
                                            onChange={(e)=>setStartTime(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">End Date</label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='End Date' 
                                            value={endTime}
                                            onChange={(e)=>setEndTime(e.target.value)}
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className="text-white">Maximum Attempts <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Maximum Attempts' 
                                            value={maxAttempts}
                                            onChange={(event)=>setMaxAttempts(event.target.value)}
                                            required
                                            min={0}
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className="text-white">Passing Criteria <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Passing Criteria' 
                                            value={passingCriteria}
                                            onChange={(event)=>setPassingCriteria(event.target.value)}
                                            required
                                            min={0}
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>
                                                Select a Pool: <sup className='text-danger'>*</sup>
                                            </label>
                                            <select value={selectedPool} className='form-select' onChange={(event)=>setSelectedPool(event.target.value)} required>
                                                <option value="">Select a Pool</option>
                                                {quizContext && quizContext?.pools && quizContext?.pools.map(([poolName, poolId]) => (
                                                    <option key={poolId} value={poolId}>
                                                    {poolName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>
                                                Select Platform: <sup className='text-danger'>*</sup>
                                            </label>
                                            <select value={selectedPlatform} className='form-select' onChange={(event)=>setSelectedPlatform(event.target.value)} required>
                                                <option value="">Select Platform</option>
                                                {quizContext && quizContext?.platforms && quizContext?.platforms.map((platformName) => (
                                                    <option key={platformName} value={platformName}>
                                                    {platformName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>
                                                Select Quiz Type: <sup className='text-danger'>*</sup>
                                            </label>
                                            <select value={quizType} className='form-select' onChange={(e)=>setQuizType(e.target.value)} required>
                                            <option value="">Select Quiz Type</option>
                                            {quizContext && quizContext?.quiz_type && quizContext?.quiz_type.map((type) => (
                                                <option key={type} value={type}>
                                                {type}
                                                </option>
                                            ))}
                                            </select>
                                        </div>

                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="checkbox" id="timerCheckbox" checked={isTimer} onChange={(e)=>{setIsTimer(e.target.checked)}} />
                                            <label class="form-check-label text-white" for="timerCheckbox">
                                                Quiz Timer
                                            </label>
                                        </div>

                                        {isTimer && <div className='form-group mb-3'>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Quiz Timer' 
                                            value={timer}
                                            onChange={(event)=>setTimer(event.target.value)}
                                            required
                                            />
                                        </div>}

                                        <div class="form-check mb-3">
                                            <input class="form-check-input" type="checkbox" value="" id="questionTimerCheckbox" checked={isQuestionTimer} onChange={(e)=>{setIsQuestionTimer(e.target.checked)}} />
                                            <label class="form-check-label text-white" for="questionTimerCheckbox">
                                                Question Timer
                                            </label>
                                        </div>

                                        {isQuestionTimer && <div className='form-group mb-3'>
                                            <input 
                                            type="number" 
                                            className='form-control' 
                                            placeholder='Quiz Timer' 
                                            value={questionTimer}
                                            onChange={(event)=>setQuestionTimer(event.target.value)}
                                            required
                                            />
                                        </div>}

                                        <div className='form-group mb-3'>
                                            <div className='row align-items-end'>
                                                <label className='col text-white'>
                                                Subject:
                                                <select name="subject" className='form-select' value={newSubject.subject} onChange={handleInputChange}>
                                                    <option value="">Select a Subject</option>
                                                    {quizContext && quizContext?.subjects && quizContext?.subjects.map((subject) => (
                                                    <option key={subject} value={subject}>
                                                        {subject}
                                                    </option>
                                                    ))}
                                                </select>
                                                </label>
                                                <label className='col text-white'>
                                                No. of Questions:
                                                <input
                                                    type="number"
                                                    name="no_of_questions"
                                                    value={newSubject.no_of_questions}
                                                    onChange={handleInputChange}
                                                    className='form-control'
                                                />
                                                </label>
                                                <label className='col text-white'>
                                                Difficulty:
                                                <select name="difficulty_level" className='form-select' value={newSubject.difficulty_level} onChange={handleInputChange}>
                                                    <option value="">Select Difficulty</option>
                                                    {quizContext && quizContext?.difficulty && quizContext?.difficulty.map((difficulty) => (
                                                    <option key={difficulty} value={difficulty}>
                                                        {difficulty}
                                                    </option>
                                                    ))}
                                                </select>
                                                </label>
                                                <label className='col text-white'>
                                                Positive Weightage:
                                                <input
                                                    type="number"
                                                    name="positive_weightage"
                                                    value={newSubject.positive_weightage}
                                                    onChange={handleInputChange}
                                                    className='form-control'
                                                    min={0}
                                                />
                                                </label>
                                                <label className='col text-white'>
                                                Negative Weightage:
                                                <input
                                                    type="number"
                                                    name="negative_weightage"
                                                    value={newSubject.negative_weightage}
                                                    onChange={handleInputChange}
                                                    className='form-control'
                                                    min={0}
                                                />
                                                </label>
                                                <button type="button" className='btn btn-dark btn-dark-primary mx-2 col-auto' onClick={handleAddSubject}>
                                                {editingIndex !== null ? 'Update Subject' : 'Add Subject'}
                                                </button>
                                            </div>
                                            {subjects.map((subject, index) => (
                                                <div key={index} className='my-4'>
                                                <p className='text-white'>{`Subject: ${subject.subject}, Questions: ${subject.no_of_questions}, Difficulty: ${subject.difficulty_level}, Positive Weightage: ${subject.positive_weightage}, Negative Weightage: ${subject.negative_weightage}`}</p>
                                                <button type="button" className='btn btn-dark btn-dark-primary mx-2' onClick={() => handleEditSubject(index)}>
                                                    Edit
                                                </button>
                                                <button type="button" className='btn btn-dark btn-dark-primary mx-2' onClick={() => handleDeleteSubject(index)}>
                                                    Delete
                                                </button>
                                                </div>
                                            ))}
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            I Want To Create A Quiz
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizCreate);
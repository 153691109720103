import React, { useEffect } from 'react'
import Footer from '../../../common/Footer/Footer';
import HomepageHeader from '../components/HomepageHeader';
import { connect } from 'react-redux';
import { fetchHomepageContent } from '../../../../store/Actions/Action';
import { scrollTop } from '../../../../utils';
import Flashstrip from '../../../common/Header/Flashstrip';
import Cookies from '../../../common/Footer/Cookies';

function CookiesPolicy({homepagecontent, fetchHomepageContentHandler}) {

	useEffect(()=>{
		if(!homepagecontent){
			fetchHomepageContentHandler();
        }
        scrollTop();
	},[]);

   return (
       <>
       <main className='background-dark-primary'>
			<HomepageHeader header_content={homepagecontent && homepagecontent.header} />
            <Flashstrip />
            <section className='static-pages' id='cookiesPolicy'>
               <div className='container'>
                   <div className="row">
                       <div className="col-md-8 col-10 mx-auto">

                           <h2>Cookies policy</h2>
                           <p>Analytics Vidhya is an online platform that provides resources and information related to data science. In order to provide a personalized and smooth user experience, we use cookies on our website. This policy outlines the use of cookies on Analytics Vidhya and how you can manage them.</p>

                           <h3>What are cookies?</h3>
                           <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. Cookies help the website recognize your device and remember certain information about your preferences, usage, and interactions with the website.</p>

                           <h3>How do we use cookies?</h3>
                           <p>Analytics Vidhya uses cookies to personalize your experience and improve our services. We use cookies to:
                               <ul>
                                       <li>Remember your preferences and settings</li>
                                       <li>Provide relevant content and recommendations</li>
                                       <li>Analyze website usage and improve our services</li>
                                       <li>Enable social sharing and integration</li>
                               </ul>
                           </p>

                           <h3>What types of cookies do we use?</h3>
                           <p>Analytics Vidhya uses both first-party and third-party cookies. First-party cookies are created and managed by Analytics Vidhya, while third-party cookies are created and managed by other companies, such as Google Analytics.</p>

                           <h3>We use the following types of cookies on our website:</h3>
                           <p>
                               <ul>
                                       <li>Necessary cookies: Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</li>
                                       <li>Statistics cookies: Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</li>
                                       <li>Preferences cookies: Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</li>
                                       <li>Marketing cookies: Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</li>
                                       <li>Unclassified cookies: Unclassified cookies are cookies that we are in the process of classifying, together with the providers of individual cookies.</li>
                               </ul>
                           </p>

                           <h3>How to manage cookies?</h3>
                           <p>You can manage cookies through your browser settings. You can choose to delete cookies or disable them altogether. However, please note that disabling cookies may affect your user experience on Analytics Vidhya and other websites.</p>
                           <p>Changes to the cookie policy We may update this cookie policy from time to time. We encourage you to review this policy regularly for any changes.</p>
                           <p className='mb-0'>Contact us If you have any questions or concerns about our cookie policy, please contact us at <a href='mailto:privacy@analyticsvidhya.com'>privacy@analyticsvidhya.com</a></p>

                       </div>
                   </div>
               </div>
           </section>
           <Footer platform={'newcookiespolicy'} />
       </main>
       <Cookies platform={'newhomepage'} />
       </>
   );
}

const mapStateToProps = (state) => ({
	homepagecontent:state.homepageContentReducer.homepagecontent
  });
  
  const mapDispatchToProps = dispatch=>({
	fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(CookiesPolicy);
import $ from 'jquery';


// cookies-based-content hide
export const allCookies = (platform)=>{
    $('#Cookies').hide();
    set_cookie(platform);
    // $.getScript("all_cookies.js");
    // $.getScript("necessary_cookies.js");
}
export const necessaryCookies = (platform)=>{
    $('#Cookies').hide();
    set_cookie(platform);
    // $.getScript("https://code.jquery.com/jquery-3.7.1.js");
}

function set_cookie(platform) {
    localStorage.setItem(`cookie_consent_${platform}`, true);
}
 
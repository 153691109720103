import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/scss/main.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';


const root = document.getElementById('root');
ReactDOM.hydrateRoot(
    root,
    <Router>
        <App />
    </Router>
);
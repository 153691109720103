import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import $ from 'jquery';
import moment from 'moment';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import 'swiper/css';
import 'swiper/css/pagination';
import { fetchAgenticAiContent } from '../../../../../store/Actions/Action';
import { getUrlParameter, scrollTop, setImpactIrclickid, submitRegisterationForm } from '../../../../../utils';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Title from '../../../../common/Header/Title';
import { getCSRF } from '../../../../../utils/Login';
import Flashstrip from '../../../../common/Header/Flashstrip';
import AgenticAiHeader from '../components/AgenticAiHeader';
import AgenticAiTwoFieldLeadModal from '../components/AgenticAiTwoFieldLeadModal';
import AgenticAiVideoModal from '../components/AgenticAiVideoModal';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
var iti1 = null;
var iti2 = null;

function AgenticAiComingSoon1({fetchAgenticAiContent,agenticaicontent,country_name}) {
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fullname, setFullname] = useState('');
  const [success,setSuccess] = useState(false);
  const [iserror,setIsError] = useState(false);
  const [userDetails,setUserDetails] = useState({
    fullname:'',
    email:'',
    phone:'',
  })
  const [formname,setFormname] = useState(null);
  const [section,setSection] = useState(null);
  const [successMessage,setSuccessMessage] = useState('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
  const [leadFormHeading,setLeadFormHeading] = useState(null);
  const [leadFormCta,setLeadFormCta] = useState(null);  
  const [csrf, setCsrf] = useState('');


  useEffect(() => {
    if(!agenticaicontent){
      fetchAgenticAiContent();
    }
    scrollTop();
  }, [fetchAgenticAiContent]);

  useEffect(()=>{
    const input = document.getElementById("agenticaiNumber");
    const input1 = document.getElementById('contactPhoneNumber');
    if(input){
      iti1 = intlTelInput(input, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti1.setCountry(country_name);
      }
    }
    if(input1){
      iti2 = intlTelInput(input1, {
        allowDropdown: true,
        autoHideDialCode: true,
        initialCountry: country_name?country_name:"IN",
        preferredCountries: ["in", "us"],
      });
      if(country_name){
        iti2.setCountry(country_name);
      }
    }
  },[agenticaicontent])
  
  useEffect(()=>{
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 100);
        return false;
        }
      }
    });

    $('.scrolling-method[href*="#"]:not([href="#"])').click(function() {
      if (window.location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && window.location.hostname === this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top - 100
        }, 100);
        return false;
        }
      }
    });   
  },[]);

  useEffect(()=>{
    getCSRF()
    .then((data)=>{
      setCsrf(data);
      console.log(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  },[])

  const saveLeadForm = (e)=>{
      e.preventDefault();
      let country_code = '+'+iti1.getSelectedCountryData().dialCode;    
      if(!validatedMobile(phone)){
          $('.error').remove();
          $('#agenticaiNumber').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid phone number</small>`);
          $('#agenticaiNumber').css('border-color','red');
          $('#agenticaiNumber').css('color','red');
          setTimeout(()=>{
              $('#agenticaiNumber').css('border-color','#383838');
              $('#agenticaiNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },3000)
          return false;
      }
      if(!validatedEmail(email)){
        $('.error').remove();
        $('#agenticaiEmail').closest('.col-xl').append(`<small class="text-danger error text-start d-block">Enter a valid email Id</small>`);
        $('#agenticaiEmail').css('border-color','red');
        $('#agenticaiEmail').css('color','red');
        setTimeout(()=>{
            $('#agenticaiEmail').css('border-color','#383838');
            $('#agenticaiEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },3000)
        return false;
    }
      let user_data = {
          "email":email,
          "phone":phone,
          "country_code":country_code,
      }
      let additional_detail ={
        "tnc":true,
        "is_whatsapp":true,
        "section":"hero_fold",
        "page_url":window.location.href
      }
      setPhone('');
        setEmail('');
        setSuccess(true);
        setSuccessMessage('Thank you for showing interest! We will get in touch with you soon!')
        setTimeout(()=>{
          setSuccess(false);
          setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        },4000)
        submitRegisterationForm(user_data,additional_detail,'agenticai',"waitlist",csrf)
        .then((data)=>{
          
        })
      .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
      })
  }

  const playVideo = ()=>{
    $('#modalVideo').trigger('play');
  }

  const updateLeadForm = (formname,heading,cta,section)=>{
    setFormname(formname);
    setLeadFormHeading(heading);
    setLeadFormCta(cta);
    setSection(section)
  }

  useEffect(()=>{
    // Timer Script
    var targetDate = "2024-10-05T23:59:59";
    var date = new Date();
    var now = new Date();
    var diffInMilliSeconds = (moment((targetDate), moment.ISO_8601).toDate() - moment(now, moment.ISO_8601).toDate())/1000;
    if (diffInMilliSeconds >0){

    
    var days = Math.floor( diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;
    
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    diffInMilliSeconds = Math.trunc(diffInMilliSeconds)
    
    var countdownNumberEl = $('.seconds');
    var countdown = diffInMilliSeconds;
    countdownNumberEl.text(countdown);
    
    var countMin = $('.minutes')
    var countmin=minutes;
    countMin.text(countmin);
    
    var countHour = $('.hours')
    var counthour=hours;
    countHour.text(counthour);
    
    var countDay = $('.days')
    var countday=days;
    countDay.text(countday);
    
    var timer=setInterval(timer_function,1000);
    function timer_function() {
    if(--countdown<= -1)
    {
      if(countmin!=0)
      {
      countdown=59;
      --countmin;
      }
      else
      {
      if(counthour!=0)
      {
        countdown=59
        countmin=59;
        --counthour;
      }
      else
      {
        if(countday!=0)
        { 
        countdown=59
        countmin=59;
        counthour=23;
        --countday;
        }
        else
        {
        countdown=0;
          if (countdown==0&&countmin==0&&counthour==0)
          {
          var myobj=document.querySelectorAll('.offer-strip');
          myobj.remove();
          clearInterval(timer);
          }
        }
        }
      }
      }
    else
    {
      countdown=countdown
    }
    
    
    countdownNumberEl.text(countdown);
    countMin.text(countmin);
    countHour.text(counthour);
    countDay.text(countday);
    } 
    }
    else{
    var myobj=$('.offer-strip');
    myobj.remove();
    }
  },[])

  return (
    <main className='background-dark-primary' id='agenticai'>
      <AgenticAiHeader updateLeadForm={updateLeadForm} />
      <Title title={'Agentic AI Pioneer Program: Master AI Agents, Build the Future!'} description={'Join the Agentic AI Pioneer Program to master AI agents! Gain 150+ hours of learning, 20+ hands-on projects, and 1:1 mentorship with experts. Enroll now!'} image_og={cloudflare_size_url+'genaipinnacle/GenAI_og_image.jpg?format=avif'} title_og={'Agentic AI Pioneer Program | Master AI Agents for Automation'} url_og={'https://www.analyticsvidhya.com/agenticaipioneer'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <AgenticAiTwoFieldLeadModal formname={formname} setSuccess={setSuccess} setIsError={setIsError} country_name={country_name} setSuccessMessage={setSuccessMessage} leadFormCta={leadFormCta} leadFormHeading={leadFormHeading} section={section} csrf={csrf} platformName={"agenticai"}/>
      <AgenticAiVideoModal />
      {iserror && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setIsError} />}
      {success && <SuccessAlert message={successMessage} />}
      <section id='firstFold' className='bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative pb-5 coming-tag' style={{ backgroundImage:`url('https://imgcdn.analyticsvidhya.com/blackbelt/hero-image-bb.png')` }}>
        <div className='container justify-content-center align-items-center h-100'>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-lg-8 mx-auto position-realtive pt-5 px-4'>
              <h1 className="fs-56 text-gradient mb-3 fw-semibold text-center">
                <span className='text-white ms-2'>Agentic AI </span>
                  <span className='text-gradient'>Pioneer </span>
                <span className='text-white'>Program</span>
              </h1>
              <h2 className='text-white fs-24 mb-4 mx-auto px-2 text-center'>Master AI Agents, Build The Future!</h2>
              <ul className='text-white mx-auto d-table'>
                <li><i></i>150+ Hours of Comprehensive Learning</li>
                <li><i></i>20+ Hands-On Projects for Skill-Building</li>
                <li><i></i>1:1 Mentorship with Agentic AI Experts</li>
              </ul>
              <div className='offer-block position-absolute offer-strip'>
                <a href='#' className='text-decoration-none text-light-primary fw-semibold'>
                  Coming Soon
                  <svg className="ms-2" xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                    <path d="M8.87615 5.22866L5.1848 1.09091L6.15801 0L11.5108 6.00005L6.15801 12L5.1848 10.9091L8.87615 6.77144H0.5V5.22866H8.87615Z" fill="#383838"></path>
                  </svg>
                </a>
                <div className='text-white ms-auto mt-1 timmer-strip'>
                  <div className="timmer-box d-inline-block me-1">
                    <span className='days'></span>d 
                  </div>: 
                  <div className="timmer-box d-inline-block me-1">
                    <span className='hours'></span>h
                  </div>:
                  <div className="timmer-box d-inline-block me-2">
                    <span className='minutes'></span>m 
                  </div>:
                  <div className="timmer-box d-inline-block">
                    <span className='seconds'></span>s
                  </div>
                </div>
              </div>
              <div className='enroll-number p-4 text-center text-white w-75 mx-auto rounded-3 rainbow'>
                <h4 className='fs-24 fw-semibold mb-4'>Become an Agentic AI Expert</h4>
                <form className="w-100" role="Phone">
                  <div className="row align-items-baseline">
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                      <input className="form-control px-4 py-2 ps-5" type="tel" placeholder="Your Phone Number" aria-label="Phone No." min={0} value={phone} onChange={(e)=>{setPhone(e.target.value)}} id="agenticaiNumber" autocomplete="off" />
                    </div>
                    <div className="col-12 col-xl mb-3 mb-xl-0">
                      <input className="form-control px-4 py-2" type="email" placeholder="Your Email Id" value={email} onChange={(e)=>{setEmail(e.target.value)}} id="agenticaiEmail" autocomplete="off" />
                    </div>
                  </div>
                 <div className='text-start mt-3'>
                  <div className="form-check form-check-inline mb-3">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox1" value="option1" defaultChecked disabled />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox1">I Agree to the <a href={'/terms'} target='_blank' className='text-dark-tertiary textdecoration-underline'>Terms & Conditions</a></label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input opacity-100" type="checkbox" id="inlineCheckbox2" value="option2" defaultChecked />
                      <label className="form-check-label text-dark-tertiary opacity-100 fs-14" htmlFor="inlineCheckbox2">Send WhatsApp Updates</label>
                    </div>
                 </div>
                  <button className="btn btn-dark btn-dark-primary w-100 mt-4 mb-2" type="submit" onClick={saveLeadForm}>Join the Waitlist</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='background-dark-primary common-style common-card-style agentic-card'>
          <div className='container'>
              <div className='row'>
                  <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                      <h2 className="fs-40 fw-normal mb-3">Insights from Industry Leaders on AI Agents</h2>
                      <p className="fs-18 fw-normal">Global Leaders on the Future of Intelligent Autonomous Agents</p>
                  </div>
              </div>
              <div className='row gx-4'>
              <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/sam.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />                         
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“We’ve seen that with the great technological revolutions of the past. Each technological revolution has gotten faster, and this will be the fastest by far. Helpful Agents Are Poised To Become AI’s Killer Function.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Sam Altman, CEO of OpenAI</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/jeff.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />                           
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“AI agents will become our digital assistants, helping us navigate the complexities of the modern world. They will make our lives easier and more efficient.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Jeff Bezos, Founder and Executive Chairman of Amazon</p>
                        </div>
                    </div>
                </div>
                <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6'>
                    <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href='#'>
                            <div className="card-img position-relative overflow-hidden rounded-2">
                                <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"agenticaipioneer/Satya%20Nadella.jpg"} alt="Card cap" loading="lazy" width="392" height="220" />      
                            </div>
                        </a>
                        <div className='card-body px-0'>
                            <p className='fs-14 text-dark-secondary'>“AI agents will become the primary way we interact with computers in the future. They will be able to understand our needs and preferences, and proactively help us with tasks and decision making.”</p>
                        </div>
                        <div className="card-footer border-0 px-0">
                          <p className='fs-14 mb-3 text-dark-secondary'>– Satya Nadella, CEO of Microsoft</p>
                        </div>
                    </div>
                </div>
              </div>
              <div className='w-100 clearfix mt-5 mx-auto d-table d-none'>
               <button className='btn btn-dark btn-dark-primary rounded-3 d-table mx-auto' aria-label="Enroll now" data-bs-toggle="modal" data-bs-target="#agenticAiLeadModal" onClick={()=>{updateLeadForm('waitlist','Become an Agentic AI Expert : Coming Soon',"Join the Waitlist","cards")}}>Join the Waitlist</button>
            </div>
          </div>
      </section>
      <section class='common-style-py d-none' id='whyChoose'>
        <div class='container z-2'>
          <div class='text-white mx-auto text-center col-lg-8 heading-with-text'>
            <h2 class='fs-40 fw-normal mb-3'>The Next Big Leap in AI & ML is Here</h2>
          </div>
          <div class='card text-bg-dark p-5 border-0 rounded-3 mt-5'>
            <div class='row'>
              <div class='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                <div class='key-points'>
                  <h3 class='fs-24 fw-semibold mb-4'>150+ Hours of Building Intelligent Agents</h3>
                  <ul class='mb-4'>
                    <li class='mb-4 fs-16 text-dark-secondary'>Full-spectrum learning curriculum</li>
                    <li class='mb-4 fs-16 text-dark-secondary'>New frontier of AI tools and frameworks</li>
                  </ul>
                </div>  
                <div class='key-points'>
                  <h3 class='fs-24 fw-semibold mb-4'>20+ Hands-On Projects</h3>
                  <ul class='mb-4 mb-md-0'>
                    <li class='mb-4 fs-16 text-dark-secondary'>Experiential learning with real-world simulations</li>
                    <li class='mb-4 fs-16 text-dark-secondary'>Diverse projects for comprehensive skill development</li>
                  </ul>
                </div>
                <div class='key-points'>
                  <h3 class='fs-24 fw-semibold mb-4'>1:1 Mentorship Sessions</h3>
                  <ul class='mb-4 mb-md-0'>
                    <li class='mb-4 fs-16 text-dark-secondary'>Personalized guidance from experts</li>
                    <li class='mb-4 fs-16 text-dark-secondary'>Accelerated learning with a tailored roadmap</li>
                  </ul>
                </div>
              </div>
              <div class='col-xl-6 col-lg-6 col-md-12 col-sm-12 position-relative'>
                <div class='video-thumb position-relative mb-5'>
                  <img src='https://imgcdn.analyticsvidhya.com/blackbelt/kunal_video_thumbnail.png?format=webp&width=643' class='img-fluid rounded-2' />
                  <button class='btn play-btn position-absolute rounded-circle d-flex align-items-center justify-content-center z-3' data-bs-toggle='modal' data-bs-target='#kunalVideoModal' onClick={playVideo}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25' fill='none'>
                      <path d='M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z' fill='white'></path>
                    </svg>
                  </button>
                </div>
                <button class='btn btn-dark btn-dark-primary rounded-3 z-3' data-bs-toggle='modal' data-bs-target='#agenticAiLeadModal' onClick={()=>{updateLeadForm('waitlist','Become an Agentic AI Expert : Coming Soon',"Join the Waitlist","video")}}>
                  Join the Waitlist
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Cookies platform={'newagenticai'}  /> */}
      {/* <Footer platform={'newagenticai'} /> */}
      {/* <Flashstrip /> */}
      </main>
  )
}


const mapStateToProps = (state) => ({
  agenticaicontent:state.agenticAIContentReducer.agenticaicontent,
  loading:state.agenticAIContentReducer.loading,
  error:state.agenticAIContentReducer.error,
  country_name:state.agenticAIContentReducer.country_code
});

const mapDispatchToProps = {
  fetchAgenticAiContent,

};

export default connect(mapStateToProps, mapDispatchToProps)(AgenticAiComingSoon1);
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import Header from '../../../common/Header/Header';
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataElevate, getQueryDataElevateUser } from '../../../../utils';
import { Editor } from '@tinymce/tinymce-react';

function UpdateStage({islogin,csrf}) {
    let { stageSlug }  = useParams();
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [id,setId] = useState(null);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [dailySubmissionLimit,setDailySubmissionLimit] = useState(0);
    const [disscussId,setDisscussId] = useState("");
    const [isAscendingLb,setIsAscendingLb] = useState(false);
    const [isProctored,setIsProctored] = useState(false);
    const [isCodeFileMandatory,setIsCodeFileMandatory] = useState(false);
    const [rakshakId,setRakshakId] = useState(false);
    const [quizId,setQuizId] = useState("");
    const [stageTypes,setStageTypes] = useState([]); 
    const [stageTypeId,setStageTypeId] = useState("");
    const [blogathonTimelines,setBlogathonTimelines] = useState("");
    const [blogathonRewards,setBlogathonRewards] = useState("");
    const [intialblogathonRewards,setIntialBlogathonRewards] = useState("");
    const [bannerImage,setBannerImage] = useState("");
    const [thumnailImage,setThumnailImage] = useState("");
    const [rules,setRules] = useState("");
    const [intialrules,setIntialRules] = useState("");
    const [winner,setWinner] = useState("");
    const [intialwinner,setIntialWinner] = useState("");

    const bannerImageRef = useRef(null);
    const thumnailImageRef = useRef(null);
    const blogathonTimelinesImageRef = useRef(null);


    useEffect(()=>{
        if(islogin){
            let query = `
            query MyQuery {
                stageData(stageSlug: "${stageSlug}") {
                  blogathonRewards
                  bannerImage
                  blogathonTimelines
                  dailySubmissionLimit
                  description
                  discussId
                  endDate
                  id
                  isActive
                  isAscendingLb
                  isProctored
                  isCodeFileMandatory
                  quizId
                  rakshakId
                  startDate
                  thumbnailImage
                  title
                  rules
                  winner: blogathonWinners
                  stageType {
                    id
                  }
                }
                
                stageTypes {
                    name
                    id
                }
            }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data);
                setStageTypes(data.data.stageTypes)
                setId(data.data.stageData.id);
                setTitle(data.data.stageData.title)
                setDescription(data.data.stageData.description)
                setDailySubmissionLimit(data.data.stageData.dailySubmissionLimit)
                setStartTime(convertDateInFormat(convertDateToLocalDatetime(data.data.stageData.startDate),"YYYY-MM-DDTHH:mm:ss"));
                setEndTime(convertDateInFormat(convertDateToLocalDatetime(data.data.stageData.endDate),"YYYY-MM-DDTHH:mm:ss"));
                setDisscussId(data.data.stageData.discussId);
                setIsAscendingLb(data.data.stageData.isAscendingLb);
                setIsProctored(data.data.stageData.isProctored);
                setIsCodeFileMandatory(data.data.stageData.isCodeFileMandatory);
                setRakshakId(data.data.stageData.rakshakId);
                setQuizId(data.data.stageData.quizId);
                setStageTypeId(data.data.stageData.stageType.id);
                setBlogathonTimelines(data.data.stageData.blogathonTimelines);
                setBlogathonRewards(data.data.stageData.blogathonRewards);
                setIntialBlogathonRewards(data.data.stageData.blogathonRewards);
                setBannerImage(data.data.stageData.bannerImage);
                setThumnailImage(data.data.stageData.thumbnailImage);
                setRules(data.data.stageData.rules);
                setIntialRules(data.data.stageData.rules);
                setWinner(data.data.stageData.winner);
                setIntialWinner(data.data.stageData.winner);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin]);

    const updateStageData = (e)=>{
        e.preventDefault();

        const startDate = new Date(startTime);
        const startOffset = startDate.getTimezoneOffset(); 
        startDate.setMinutes(startDate.getMinutes() - startOffset - 330); 

        const endDate = new Date(endTime);
        const endOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        if(!bannerImageRef.current.files.length > 0 && !thumnailImageRef.current.files.length > 0 && !blogathonTimelinesImageRef.current.files.length){
            let query = `mutation MyMutation {
                updateStage(
                id: ${id}
                blogathonRewards: """${blogathonRewards}"""
                dailySubmissionLimit: ${dailySubmissionLimit}
                description: """${description}"""
                discussId: ${disscussId?disscussId:null}
                endDate: "${formattedEndDate}"
                isAscendingLb: ${isAscendingLb}
                isProctored: ${isProctored}
                isCodeFileMandatory: ${isCodeFileMandatory}
                quizId: "${quizId}"
                rakshakId: "${rakshakId}"
                stageTypeId: ${stageTypeId}
                startDate: "${formattedStartDate}"
                title: "${title}"
                rules: """${rules}"""
                blogathonWinners: """${winner}"""
                ) {
                stage {
                    updatedAt
                }
                }
            }`;
            getQueryDataElevateUser(query,"newcompetition","True",csrf)
            .then((data)=>{
                if(!data.errors){
                    alert("Stage update successfully")
                }
                else{
                    alert(data.errors[0].message)
                }
            })
            .catch((error)=>{
                alert(error.message)
                console.log(error);
            })
            return false;
        }

        let mutation = `mutation MyMutation($bannerImage: Upload, $thumbnailImage: Upload, $title:String!, $starttime:DateTime!,$endtime:DateTime!,$stageTypeId:ID!,$quizId:String!,$blogathonRewards:String!,$blogathonTimelines:Upload,$rakshakId:String!){
            updateStage(id:${id},title:$title,rules: """${rules}""",blogathonWinners: """${winner}""" description:"""${description}""", startDate: $starttime, endDate: $endtime, stageTypeId: $stageTypeId, dailySubmissionLimit: ${dailySubmissionLimit}, quizId: $quizId, discussId: ${disscussId}, isAscendingLb: ${isAscendingLb}, bannerImage: $bannerImage, blogathonRewards: $blogathonRewards, blogathonTimelines: $blogathonTimelines, isProctored: ${isProctored}, isCodeFileMandatory: ${isCodeFileMandatory}, rakshakId: $rakshakId, thumbnailImage: $thumbnailImage ) {
                stage {
                    id, 
                    title, 
                    description 
                }
            }
        }`;

        let variables = {
            title:title,
            starttime:formattedStartDate,
            endtime:formattedEndDate,
            stageTypeId:stageTypeId,
            quizId:quizId,
            blogathonRewards:blogathonRewards,
            rakshakId:rakshakId,
            ...(bannerImageRef.current.files[0] && {bannerImage :null}),
            ...(thumnailImageRef.current.files[0] && {thumnailImage :null}),
            ...(blogathonTimelinesImageRef.current.files[0] && {blogathonTimelines :null})

        }

        let formdata = new FormData();
        const map = {};

        if(bannerImageRef.current.files.length > 0 ){
            formdata.append("0", bannerImageRef.current.files[0]);
            map["0"] = ["variables.bannerImage"]
        }
        if(thumnailImageRef.current.files.length > 0 ){
            formdata.append("1", thumnailImageRef.current.files[0]); 
            map["1"] = ["variables.thumnailImage"];
        }
        if(blogathonTimelinesImageRef.current.files.length > 0 ){
            formdata.append("2", blogathonTimelinesImageRef.current.files[0]); 
            map["2"] = ["variables.blogathonTimelines"];
        }
        formdata.append("operations", JSON.stringify({ query: mutation, variables: variables }));  
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((data) => {
            if(!data.errors){
                alert("Stage update successfully")
            }
            else{
                alert(data.errors[0].messsage)
            }
        })
        .catch((error)=>{
            alert(error.messsage)
        })
    }

    const handleBannerUpload = ()=> {
        const file = bannerImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setBannerImage(imageURL);
        } else {
            setBannerImage("");
        }
    }

    const handleThumbnailUpload = ()=> {
        const file = thumnailImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setThumnailImage(imageURL);
        } else {
            setThumnailImage("");
        }
    }

    const handleBlogathonTimelinesUpload = ()=> {
        const file = blogathonTimelinesImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setBlogathonTimelines(imageURL);
        } else {
            setBlogathonTimelines("");
        }
    }

  return (
    <>
        <Header />
        <section id="updateStage" className='background-dark-primary min-vh-100 common-style'>
            <div className="container">
                <div className="row">
                    <div className="card col-lg-8 col-md-12 mx-auto background-dark-tertiary shadow">
                        <div className="card-header border-0 bg-transparent">
                            <h3 className='text-white text-center'>Update Stage</h3>
                        </div>
                        <div className="card-body">
                            <form id="updateStageForm" onSubmit={updateStageData}>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Title</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Title' value={title} onChange={(e)=>{setTitle(e.target.value)}} required />
                                </div>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Description</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Descrition' value={description} onChange={(e)=>{setDescription(e.target.value)}} required />
                                </div>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Daily Submission Limit</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Descrition' value={dailySubmissionLimit} onChange={(e)=>{setDailySubmissionLimit(e.target.value)}} required />
                                </div>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Discuss Id</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Discuss Id' value={disscussId} onChange={(e)=>{setDisscussId(e.target.value)}} />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="me-3 text-white">Is Ascending Leaderboard:</label>
                                    <input
                                        type="checkbox"
                                        className='form-check-input'
                                        checked={isAscendingLb}
                                        onChange={(event) => setIsAscendingLb(event.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="me-3 text-white">Is Proctored:</label>
                                    <input
                                        type="checkbox"
                                        className='form-check-input'
                                        checked={isProctored}
                                        onChange={(event) => setIsProctored(event.target.checked)}
                                    />
                                </div>
                                <div className="form-group mb-3">
                                    <label className="me-3 text-white">Is Code File Mandatory:</label>
                                    <input
                                        type="checkbox"
                                        className='form-check-input'
                                        checked={isCodeFileMandatory}
                                        onChange={(event) => setIsCodeFileMandatory(event.target.checked)}
                                    />
                                </div>
                                {isProctored && <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Rakshak Id</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Discuss Id' value={rakshakId} onChange={(e)=>{setRakshakId(e.target.value)}} required={isProctored}/>
                                </div>}

                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Quiz Id</label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Quiz Id' value={quizId} onChange={(e)=>{setQuizId(e.target.value)}}/>
                                </div>
                                <div className="form-group mb-3">
                                            <label className="text-white me-3">Stage Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="stageTypeId"
                                                className="form-select"
                                                value={stageTypeId}
                                                onChange={(event) => setStageTypeId(event.target.value)}
                                                required
                                            >
                                                <option value="" disabled>Select Stage Type</option>
                                                {stageTypes && stageTypes.map((type) => (
                                                    <option key={type.id} value={type.id}>
                                                        {type.name}
                                                    </option>
                                                ))}
                                            </select>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                                    <input 
                                    type="datetime-local" 
                                    className='form-control' 
                                    placeholder='Start Date' 
                                    value={startTime}
                                    onChange={(e)=>setStartTime(e.target.value)}
                                    required
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                                    <input 
                                    type="datetime-local" 
                                    className='form-control' 
                                    placeholder='End Date' 
                                    value={endTime}
                                    onChange={(e)=>setEndTime(e.target.value)}
                                    required
                                    />
                                </div>


                                <div className="mb-3">                                         
                                    <label className="me-3 text-white">Rules</label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
                                        init={{
                                            plugins: 'anchor autolink codesample image',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        initialValue={intialrules}
                                        onEditorChange={(content,editor)=>{setRules(content)}}
                                    />
                                </div>

                                <div className="mb-3">                                         
                                    <label className="me-3 text-white">Blogathon Rewards</label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
                                        init={{
                                            plugins: 'anchor autolink codesample image',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        initialValue={intialblogathonRewards}
                                        onEditorChange={(content,editor)=>{setBlogathonRewards(content);console.log(content)}}
                                    />
                                </div>

                                <div className="mb-3">                                         
                                    <label className="me-3 text-white">Blogathon Winners</label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
                                        init={{
                                            plugins: 'anchor autolink codesample image',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        initialValue={intialwinner}
                                        onEditorChange={(content,editor)=>{setWinner(content)}}
                                    />
                                </div>

                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Banner Image</label>
                                    <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={bannerImageRef} onChange={handleBannerUpload} />
                                    {bannerImage && <a target='_blank' href={bannerImage}>View Image</a>}
                                </div>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Thumbnail Image</label>
                                    <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={thumnailImageRef} onChange={handleThumbnailUpload} />
                                    {thumnailImage && <a target='_blank' href={thumnailImage}>View Image</a>}
                                </div>
                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Blogathon Timelines Image</label>
                                    <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={blogathonTimelinesImageRef} onChange={handleBlogathonTimelinesUpload} />
                                    {blogathonTimelines && <a target='_blank' href={blogathonTimelines}>View Image</a>}
                                </div>
                                <button className='btn btn-dark btn-dark-primary mx-auto d-table'>Update</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateStage);
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataElevate, getQueryDataElevateUser } from '../../../../utils';
import { connect } from 'react-redux';
import Header from '../../../common/Header/Header';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

function UpdateTournament({islogin,csrf}) {
    const { tournamentSlug } = useParams();
    const [tournamentTypes,setTournamentTypes] = useState([]);
    const [tournamentPurposes,setTournamentPurposes] = useState([]);
    const [id,setId] = useState("");
    const [title,setTitle] = useState("");
    const [description,setDescription] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [autoRegistrationAllowed, setAutoRegistrationAllowed] = useState(false);
    const [bannerImage, setBannerImage] = useState('');
    const [companyLogo, setCompanyLogo] = useState('');
    const [customFormId, setCustomFormId] = useState('');
    const [discussId, setDiscussId] = useState('');
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [isFormRequired, setIsFormRequired] = useState(false);
    const [isPublic, setIsPublic] = useState(false);
    const [isPublished, setIsPublished] = useState(false);
    const [isTeamRequired, setIsTeamRequired] = useState(false);
    const [lateSubmissionAllowed, setLateSubmissionAllowed] = useState(false);
    const [prize, setPrize] = useState('');
    const [teamSize, setTeamSize] = useState(1);
    const [thumbnailImage, setThumbnailImage] = useState('');
    const [tournamentType,setTournamentType] = useState('');
    const [tournamentPurpose,setTournamentPurpose] = useState('');
    const [registrationDisabled, setRegistrationDisabled] = useState(false);
    const [showLeaderboard, setShowLeaderboard] = useState(true);
    const [metatitle, setMetaTitle] = useState("");
    const [metadescription, setMetaDescription] = useState("");
    const [allowedEmailDomains, setAllowedEmailDomains] = useState("");

    const bannerImageRef = useRef(null);
    const thumnailImageRef = useRef(null);
    const companyLogoRef = useRef(null);

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    useEffect(()=>{
        if(islogin){
            let query = `query MyQuery {
                tournamentData(tournamentSlug: "${tournamentSlug}") {
                    tournament {
                        autoRegistrationAllowed
                        bannerImage
                        companyLogo
                        customFormId
                        description
                        discussId
                        endDate
                        id
                        isAnonymous
                        isFormRequired
                        isPublic
                        isPublished
                        isTeamRequired
                        lateSubmissionAllowed
                        registrationDisable
                        showLeaderboard
                        prize
                        startDate
                        teamSize
                        thumbnailImage
                        metaTitle
                        metaDescription
                        title
                        allowedEmailDomains
                        tournamentType {
                            id
                        }
                        tournamentPurpose {
                            id
                        }
                    }
                }
                tournamentTypes {
                    id
                    name
                }
                tournamentPurpose {
                  id
                  name
                }
            }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data);
                setTournamentTypes(data.data.tournamentTypes);
                setTournamentPurposes(data.data.tournamentPurpose);
                setId(data.data.tournamentData.tournament.id);
                setTitle(data.data.tournamentData.tournament.title);
                setDescription(data.data.tournamentData.tournament.description);
                setStartDate(convertDateInFormat(convertDateToLocalDatetime(data.data.tournamentData.tournament.startDate),"YYYY-MM-DDTHH:mm:ss"));
                setEndDate(convertDateInFormat(convertDateToLocalDatetime(data.data.tournamentData.tournament.endDate),"YYYY-MM-DDTHH:mm:ss"));
                setAutoRegistrationAllowed(data.data.tournamentData.tournament.autoRegistrationAllowed);
                setBannerImage(data.data.tournamentData.tournament.bannerImage);
                setCompanyLogo(data.data.tournamentData.tournament.companyLogo);
                setCustomFormId(data.data.tournamentData.tournament.customFormId);
                setDiscussId(data.data.tournamentData.tournament.discussId);
                setIsAnonymous(data.data.tournamentData.tournament.isAnonymous);
                setIsFormRequired(data.data.tournamentData.tournament.isFormRequired);
                setIsPublic(data.data.tournamentData.tournament.isPublic);
                setIsPublished(data.data.tournamentData.tournament.isPublished);
                setIsTeamRequired(data.data.tournamentData.tournament.isTeamRequired);
                setLateSubmissionAllowed(data.data.tournamentData.tournament.lateSubmissionAllowed);
                setPrize(data.data.tournamentData.tournament.prize);
                setTeamSize(data.data.tournamentData.tournament.teamSize);
                setThumbnailImage(data.data.tournamentData.tournament.thumbnailImage);
                setTournamentType(data.data.tournamentData.tournament.tournamentType.id);
                setTournamentPurpose(data.data.tournamentData.tournament.tournamentPurpose.id);
                setRegistrationDisabled(data.data.tournamentData.tournament.registrationDisable);
                setShowLeaderboard(data.data.tournamentData.tournament.showLeaderboard);
                setMetaTitle(data.data.tournamentData.tournament.metaTitle);
                setMetaDescription(data.data.tournamentData.tournament.metaDescription);
                setAllowedEmailDomains(data.data.tournamentData.tournament.allowedEmailDomains);
            })
            .catch((error)=>{
                console.error(error);
            })
        }
    },[islogin]);

    let updateTournament = (e)=>{
        e.preventDefault();

        const startDateTime = new Date(startDate);
        const startOffset = startDateTime.getTimezoneOffset(); 
        startDateTime.setMinutes(startDateTime.getMinutes() - startOffset - 330); 

        const endDateTime = new Date(endDate);
        const endOffset = endDateTime.getTimezoneOffset();
        endDateTime.setMinutes(endDateTime.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDateTime.toISOString();
        const formattedEndDate = endDateTime.toISOString();

        if(!bannerImageRef.current.files.length > 0 && !thumnailImageRef.current.files.length > 0 && !companyLogoRef.current.files.length > 0){
            let query = `mutation MyMutation {
                updateTournament(
                id: "${id}"
                autoRegistrationAllowed: ${autoRegistrationAllowed}
                showLeaderboard: ${showLeaderboard}
                registrationDisable: ${registrationDisabled}
                customFormId: ${customFormId}
                datahourCategoryId: null
                description: """${description}"""
                discussId: ${discussId?discussId:null}
                endDate: "${formattedEndDate}"
                isAnonymous: ${isAnonymous}
                isFormRequired: ${isFormRequired}
                isPublic: ${isPublic}
                isPublished: ${isPublished}
                isTeamRequired: ${isTeamRequired}
                prize: """${prize}"""
                relatedDatahourCategoryIds: null
                startDate: "${formattedStartDate}"
                teamSize: ${teamSize?teamSize:null}
                title: "${title}"
                tournamentPurposeId: ${tournamentPurpose}
                tournamentTypeId: ${tournamentType}
                metaTitle: "${metatitle}"
                metaDescription: "${metadescription}"
                lateSubmissionAllowed: ${lateSubmissionAllowed}
                ) {
                tournament {
                    createdAt
                    updatedAt
                }
                }
            }`;
            getQueryDataElevateUser(query,"newcompetition","True",csrf)
            .then((data) => {
                if (!data.errors) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else {
                    setMessage(data.errors[0].message);
                    setIsError(true);   
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => console.log('error', error));
            return false;
        }

        let mutation = `mutation MyMutation($bannerImage: Upload, $thumbnailImage: Upload, $companyLogo : Upload, $title:String!, $startDate:DateTime!,$endDate:DateTime!){
            updateTournament(id:${id},title:$title, description:"""${description}""",metaTitle: "${metatitle}", metaDescription: "${metadescription}", startDate: $startDate, endDate: $endDate, discussId: ${discussId?discussId:null}, autoRegistrationAllowed: ${autoRegistrationAllowed}, registrationDisable: ${registrationDisabled}, customFormId: ${customFormId}, datahourCategoryId: null, isAnonymous: ${isAnonymous}, isFormRequired: ${isFormRequired}, showLeaderboard: ${showLeaderboard}, lateSubmissionAllowed: ${lateSubmissionAllowed}, isPublic: ${isPublic}, isPublished: ${isPublished}, isTeamRequired: ${isTeamRequired}, prize: """${prize}""", relatedDatahourCategoryIds: null, teamSize : ${teamSize}, tournamentPurposeId: ${tournamentPurpose}, tournamentTypeId: ${tournamentType}, bannerImage: $bannerImage, thumbnailImage: $thumbnailImage, companyLogo: $companyLogo ) {
                tournament {
                    createdAt
                    updatedAt
                }
            }
        }`;

        let variables = {
            title:title,
            startDate:formattedStartDate,
            endDate:formattedEndDate,
            prize:prize,
            ...(bannerImageRef.current.files[0] && {bannerImage :null}),
            ...(thumnailImageRef.current.files[0] && {thumbnailImage :null}),
            ...(companyLogoRef.current.files[0] && {companyLogo :null})
        }

        let formdata = new FormData();
        const map = {};

        if(bannerImageRef.current.files.length > 0 ){
            formdata.append("0", bannerImageRef.current.files[0]);
            map["0"] = ["variables.bannerImage"]
        }
        if(thumnailImageRef.current.files.length > 0 ){
            formdata.append("1", thumnailImageRef.current.files[0]); 
            map["1"] = ["variables.thumbnailImage"];
        }
        if(companyLogoRef.current.files.length > 0 ){
            formdata.append("2", companyLogoRef.current.files[0]); 
            map["2"] = ["variables.companyLogo"];
        }
        formdata.append("operations", JSON.stringify({ query: mutation, variables: variables }));  
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            return response.json();
        })
        .then((result) => {
            console.log(result);
            if (!result.errors) {
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
            else {
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error)
        });
        return false;
    };

    const handleBannerUpload = ()=> {
        const file = bannerImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setBannerImage(imageURL);
        } else {
            setBannerImage("");
        }
    }

    const handleThumbnailUpload = ()=> {
        const file = thumnailImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setThumbnailImage(imageURL);
        } else {
            setThumbnailImage("");
        }
    }

    const handleCompanyUpload = ()=> {
        const file = companyLogoRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setCompanyLogo(imageURL);
        } else {
            setCompanyLogo("");
        }
    }

  return (
    <>
        <Header />
        {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={"Your Tournament updated successfully"} />}
        <section id="updateTournament" className='common-style background-dark-primary min-vh-100 pb-5'>
            <div className="container">
                <div className="card col-lg-8 col-md-12 mx-auto background-dark-tertiary">
                    <div className="card-header border-0 py-4">
                        <h3 className='text-white text-uppercase text-center'>Update Tournament</h3>
                    </div>
                    <div className="card-body">
                        <form id="updateTournamentForm" onSubmit={updateTournament}>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Title</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Title' value={title} onChange={(e)=>{setTitle(e.target.value)}} required />
                            </div>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Description</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Descrition' value={description} onChange={(e)=>{setDescription(e.target.value)}} required />
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                                <input 
                                type="datetime-local" 
                                className='form-control' 
                                placeholder='Start Date' 
                                value={startDate}
                                onChange={(e)=>setStartDate(e.target.value)}
                                required
                                />
                            </div>

                            <div className="form-group mb-3">
                                <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                                <input 
                                type="datetime-local" 
                                className='form-control' 
                                placeholder='End Date' 
                                value={endDate}
                                onChange={(e)=>setEndDate(e.target.value)}
                                required
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Auto Registration Allowed:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={autoRegistrationAllowed}
                                    onChange={(event) => setAutoRegistrationAllowed(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Registration Disabled:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={registrationDisabled}
                                    onChange={(event) => setRegistrationDisabled(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Show Leaderboard:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={showLeaderboard}
                                    onChange={(event) => setShowLeaderboard(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Late Submission Allowed:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={lateSubmissionAllowed}
                                    onChange={(event) => setLateSubmissionAllowed(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-white me-3">Tournament Type <sup className='text-danger'>*</sup></label>
                                <select
                                    name="stageTypeId"
                                    className="form-select"
                                    value={tournamentType}
                                    onChange={(event) => setTournamentType(event.target.value)}
                                    required
                                >
                                    <option value="" disabled>Select Tournament Type</option>
                                    {tournamentTypes && tournamentTypes.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-white me-3">Tournament Purpose <sup className='text-danger'>*</sup></label>
                                <select
                                    name="stageTypeId"
                                    className="form-select"
                                    value={tournamentPurpose}
                                    onChange={(event) => setTournamentPurpose(event.target.value)}
                                    required
                                >
                                    <option value="" disabled>Select Tournament Type</option>
                                    {tournamentPurposes && tournamentPurposes.map((type) => (
                                        <option key={type.id} value={type.id}>
                                            {type.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Anonymous:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={isAnonymous}
                                    onChange={(event) => setIsAnonymous(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Form required:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={isFormRequired}
                                    onChange={(event) => setIsFormRequired(event.target.checked)}
                                />
                            </div>
                            {isFormRequired && 
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Custom Form Id</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Custom Form Id' value={customFormId} onChange={(e)=>{setCustomFormId(e.target.value)}} required={isFormRequired} />
                            </div>}
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Public:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={isPublic}
                                    onChange={(event) => setIsPublic(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Published:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={isPublished}
                                    onChange={(event) => setIsPublished(event.target.checked)}
                                />
                            </div>
                            <div className="form-group mb-3">
                                <label className="me-3 text-white">Is Team Required:</label>
                                <input
                                    type="checkbox"
                                    className='form-check-input'
                                    checked={isTeamRequired}
                                    onChange={(event) => setIsTeamRequired(event.target.checked)}
                                />
                            </div>
                            {isTeamRequired && 
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Team Size</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Team Size' value={teamSize} onChange={(e)=>{setTeamSize(e.target.value)}} required={isTeamRequired} />
                            </div>}
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Prize</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Prize' value={prize} onChange={(e)=>{setPrize(e.target.value)}} />
                            </div>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Discuss Id</label>
                                <input type="text" class="form-control" id="allowedExtension" placeholder='Discuss Id' value={discussId} onChange={(e)=>{setDiscussId(e.target.value)}} />
                            </div>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Banner Image</label>
                                <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={bannerImageRef} onChange={handleBannerUpload} />
                                <a target='_blank' href={bannerImage}>View Image</a>
                            </div>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Thumbnail Image</label>
                                <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={thumnailImageRef} onChange={handleThumbnailUpload} />
                                <a target='_blank' href={thumbnailImage}>View Image</a>
                            </div>
                            <div class="mb-3">
                                <label for="allowedExtension" class="form-label text-white">Company Logo</label>
                                <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={companyLogoRef} onChange={handleCompanyUpload} />
                                {companyLogo && <a target='_blank' href={companyLogo}>View Image</a>}
                            </div>

                            <div className="form-group mb-3">
                                <label className="text-white">Meta Title</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='Meta Title'
                                    value={metatitle}
                                    onChange={(event) => setMetaTitle(event.target.value)}
                                />
                            </div>

                            <div className="form-group mb-3">
                                <label className="text-white">Meta Description</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='Meta Description'
                                    value={metadescription}
                                    onChange={(event) => setMetaDescription(event.target.value)}
                                />
                            </div>
                            
                            <div className="form-group mb-3 d-none">
                                <label className="text-white">Allowed Email Domains</label>
                                <input
                                    type="text"
                                    className='form-control'
                                    placeholder='Email domains should be comma seperated gmail.com,outlook.com'
                                    value={allowedEmailDomains}
                                    onChange={(event) => setAllowedEmailDomains(event.target.value)}
                                />
                            </div>
                            <button className='btn btn-dark btn-dark-primary mx-auto d-table'>Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTournament);
import { FETCH_COMMENT_CONTENT_SUCCESS } from "../Contants";

const initialState = {
    commentData: null,
    lastpage:4
};
  
export const commentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMMENT_CONTENT_SUCCESS:
            return {
                ...state,
                commentData: action.payload.commentData,
                lastpage:action.payload.totalPages
            };
        default:
            return state;
    }
};

import React, { useEffect, useRef, useState } from 'react';
import Footer from '../../../common/Footer/Footer';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
import { validatedEmail, validatedMobile } from '../../../../utils/InputValidation';
import ReCAPTCHA from "react-google-recaptcha";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import { scrollTop, submitRegisterationForm } from '../../../../utils';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import HomepageHeader from '../components/HomepageHeader';
import { connect } from 'react-redux';
import { fetchHomepageContent } from '../../../../store/Actions/Action';
import Flashstrip from '../../../common/Header/Flashstrip';
import Cookies from '../../../common/Footer/Cookies';
import {getCSRF} from '../../../../utils/Login';

function ContactUs({homepagecontent, fetchHomepageContentHandler,country_code}) {
    const [formData, setFormData] = useState({
        name:'',
        phone_number:"",
        email:"",
        preferences:[],
        inquiry:'',
        tnc:true
    });
    const [captcha, setCaptcha] = useState(false);
    const [error,setError] = useState(false);
    const [success,setSuccess] = useState(false);
    const recaptchaRef = useRef(null); 
    const [csrf, setCsrf] = useState('');

    useEffect(()=>{
		if(!homepagecontent){
			fetchHomepageContentHandler();
		}
        scrollTop();
	},[]);

    useEffect(()=>{
        const input = document.getElementById("phonenumber");
        intlTelInput(input, {
          allowDropdown: true,
          autoHideDialCode: true,
          initialCountry: country_code?country_code:"IN",
          preferredCountries: ["in", "us"],
        });
    },[country_code]);

    useEffect(()=>{
        getCSRF()
        .then((data)=>{
        setCsrf(data);
        console.log(data);
        })
        .catch((error)=>{
        console.log(error);
        })
    },[])

    const handleCheckboxChange = (value) => {
        if (formData.preferences.includes(value)) {
            setFormData({...formData,preferences:formData.preferences.filter((item) => item !== value)});
        } else {
            setFormData({...formData,preferences:[...formData.preferences, value]});
        }
    };

    const submitContactUsForm = (e)=>{
        e.preventDefault();
        if(!captcha){
            setError(true);
                setTimeout(() => {
                    setError(false);
            }, 2000);
            return false;
        }
        if(!validatedEmail(formData.email)){
            $('.warning-danger').remove();
            $('#email').css('border-color','red');
            $('#email').closest('.col-md').append(`<small class="warning-danger">Enter a valid email address<small>`);
            scrollTop();
            setTimeout(()=>{
                $('.warning-danger').remove();
                $('#email').css('border-color','#383838');
            },[2000])
            return false;
        };
        if(!validatedMobile(formData.phone_number)){
            $('.warning-danger').remove();
            $('#phonenumber').css('border-color','red');
            $('#phonenumber').closest('.col-md').append(`<small class="warning-danger">Enter a valid phone number<small>`);
            scrollTop();
            setTimeout(()=>{
                $('.warning-danger').remove();
                $('#phonenumber').css('border-color','#383838');
            },[2000])
            return false;
        };
        let country_code = $('#contactUs .iti__selected-flag').attr('title').split(':')[1].trim();
        let responce = {
            "fullname":formData.name,
            "email":formData.email,
            "country_code":country_code,
            "phone":formData.phone_number
        }
        let additionalResponce = {
            "preferences":formData.preferences,
            "inquiry":formData.inquiry,
            "tnc":formData.tnc
        }
        submitRegisterationForm(responce,additionalResponce,'homepage',"contact",csrf)
        .then((data)=>{
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 2000);
            recaptchaRef.current?.reset();
            setCaptcha(false);
            setFormData({
                name:'',
                phone_number:"",
                email:"",
                preferences:[],
                inquiry:'',
                tnc:true
            });
        })
        .catch((error)=>{
            setError(true);
                setTimeout(() => {
                    setError(false);
            }, 2000);
        });
    }

    function onChange(value) {
        setCaptcha(true);
    }

    return (
        <>
            {error && <ErrorAlert message={"Your details Could not be submitted. please try again later sometimes."} setIsError={setError} />}
            {success && <SuccessAlert message={'Your details have been submitted successfully. Our team will contact you soon. Thank you!'} />}
            <HomepageHeader header_content={homepagecontent && homepagecontent.header} />
            <Flashstrip />
            <main className='background-dark-primary'>
                <section id='contactUs'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-lg-7 col-md-12 col-12 px-3'>
                                <div>
                                    <h2 className='fs-40 text-white'>Get in touch with us</h2>
                                    <p className='text-white'>We are here to help. Want to learn more about our services? Please get in touch, we’d love to hear from you!</p>
                                    <div className='py-5'>
                                        <form onSubmit={submitContactUsForm}>
                                            <div className="row">
                                                <div className="mb-4 me-2 col-12 col-md-12">
                                                    <label htmlFor="name" className="form-label text-white fs-14">Full Name<sup className='text-danger'>*</sup></label>
                                                    <input type="text" className="form-control background-dark-tertiary" id="name" placeholder='Enter your full name' required value={formData.name} onChange={e=>setFormData((prev)=>{return{...prev,name:e.target.value}})} />
                                                </div>
                                                <div className="mb-4 col-12 col-md">
                                                    <label htmlFor="email" className="form-label text-white fs-14">Email address<sup className='text-danger'>*</sup></label>
                                                    <input type="email" className="form-control background-dark-tertiary" id="email" placeholder='Enter your email address' required value={formData.email} onChange={e=>setFormData((prev)=>{return{...prev,email:e.target.value}})}  />
                                                </div>
                                                <div className="mb-4 col-12 col-md">
                                                    <label htmlFor="phonenumber" className="form-label text-white fs-14">Phone Number<sup className='text-danger'>*</sup></label>
                                                    <input type="tel" className="form-control background-dark-tertiary" id="phonenumber" placeholder='Enter your phone number' required value={formData.phone_number} onChange={e=>setFormData((prev)=>{return{...prev,phone_number:e.target.value}})}  />
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="preferences" className="form-label text-white fs-14">Preferences</label>
                                                <div className="preferences-tag">
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox1"} value={'Advertising'} checked={formData.preferences.includes('Advertising')} onChange={() => handleCheckboxChange('Advertising')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox1"}>Advertising</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox2"} value={'Hackathon'} checked={formData.preferences.includes('Hackathon')} onChange={() => handleCheckboxChange('Hackathon')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox2"}>Hackathon</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox3"} value={'Meetup or Event'} checked={formData.preferences.includes('Meetup or Event')} onChange={() => handleCheckboxChange('Meetup or Event')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox3"}>Meetup or Event</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox4"} value={'Training program'} checked={formData.preferences.includes('Training program')} onChange={() => handleCheckboxChange('Training program')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox4"}>Training program</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox5"} value={'Career'} checked={formData.preferences.includes('Career')} onChange={() => handleCheckboxChange('Career')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox5"}>Career</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox6"} value={'Business partnership'} checked={formData.preferences.includes('Business partnership')} onChange={() => handleCheckboxChange('Business partnership')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox6"}>Business partnership</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox7"} value={'Writing for analytics vidhya'} checked={formData.preferences.includes('Writing for analytics vidhya')} onChange={() => handleCheckboxChange('Writing for analytics vidhya')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox7"}>Writing for analytics vidhya</label>
                                                    <input type='checkbox' className="form-check-input me-2 d-none" id={"checkbox8"} value={'Other'} checked={formData.preferences.includes('Other')} onChange={() => handleCheckboxChange('Other')} />
                                                    <label className="form-check-label text-white fs-14 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" htmlFor={"checkbox8"}>Other</label>
                                                </div>
                                            </div>
                                            <div className='mb-4'>
                                                <label htmlFor="inquiry" className='form-label text-white fs-14'>Whats your Inquiry</label>
                                                <textarea className="form-control background-dark-tertiary" placeholder="Write your Inquiry here!" id="inquiry" value={formData.inquiry} onChange={e=>setFormData((prev)=>{return{...prev,inquiry:e.target.value}})}></textarea>
                                            </div>
                                            <div className='mb-4'>
                                                <input type='checkbox' id='contactUsTnc' className="form-check-input me-2" defaultChecked disabled />
                                                <label className="form-check-label text-white fs-14" htmlFor='contactUsTnc'>I agree to <a href={'/terms'} target='_blank' className='text-white'>Terms of use</a> & <a href={'/privacy-policy'} className='text-white'>Privacy policy</a></label>
                                            </div>
                                            <div className='mb-4 d-flex'>
                                                <input type='checkbox' id='contactUsCheck' className="form-check-input me-2" defaultChecked disabled />
                                                <label className="form-check-label text-white fs-14" htmlFor='contactUsCheck'>I authorize Analytics Vidhya and its representatives to Call, SMS, Email or WhatsApp me about its products and offers. This consent overrides any registration for DND / NDNC</label>
                                            </div>
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                                onChange={onChange}
                                            />
                                            <button type="submit" className="btn btn-dark btn-dark-primary mt-5 rounded-3 fs-16" disabled={!captcha}>Submit</button>
                                        </form>
                                    </div>
                                </div>
                                <div className='my-5'>
                                    <div className='card background-dark-secondary p-2 p-md-4 p-lg-5'>
                                        <div className='card-body text-white'>
                                            <h3 className='fs-32'>Visit Us</h3>
                                            <p className='mb-5'>We are here to help. Want to learn more about our services? Please get in touch, we’d love to hear from you!</p>
                                            <div className='row flex-nowrap'>
                                                <div className='mb-1 col-auto pe-0'>
                                                    <div className="icon-circle me-3 bg-dark text-white d-flex justify-content-center align-items-center">
                                                        <span>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Location">
                                                                <g id="Icon">
                                                                <path d="M13.332 8.33268C13.332 10.1736 11.8396 11.666 9.9987 11.666C8.15775 11.666 6.66536 10.1736 6.66536 8.33268C6.66536 6.49173 8.15775 4.99935 9.9987 4.99935C11.8396 4.99935 13.332 6.49173 13.332 8.33268Z" stroke="white" strokeWidth="1.5"/>
                                                                <path d="M16.6654 8.33268C16.6654 12.0146 11.6654 18.3327 9.9987 18.3327C8.33203 18.3327 3.33203 12.0146 3.33203 8.33268C3.33203 4.65078 6.3168 1.66602 9.9987 1.66602C13.6806 1.66602 16.6654 4.65078 16.6654 8.33268Z" stroke="white" strokeWidth="1.5"/>
                                                                </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col ps-0'>
                                                    <h5 className='pt-1'>Registered Office</h5>
                                                    <p className='text-dark-secondary mb-5'>207, B-Block (Chamber 1),Corporate House, 169,RNT Marg, Indore 452001</p>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='mb-1 col-auto pe-0'>
                                                    <div className="icon-circle me-3 bg-dark text-white d-flex justify-content-center align-items-center">
                                                        <span>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="Office">
                                                                <path id="Vector 13" d="M3.33203 17.5H4.16536M4.16536 17.5V5.7C4.16536 4.57989 4.16536 4.01984 4.38335 3.59202C4.5751 3.21569 4.88106 2.90973 5.25738 2.71799C5.68521 2.5 6.24526 2.5 7.36536 2.5H12.632C13.7521 2.5 14.3122 2.5 14.74 2.71799C15.1163 2.90973 15.4223 3.21569 15.614 3.59202C15.832 4.01984 15.832 4.57989 15.832 5.7V17.5M4.16536 17.5H8.33203M15.832 17.5H11.6654M15.832 17.5H16.6654M8.33203 17.5V14.1C8.33203 13.5399 8.33203 13.2599 8.44102 13.046C8.5369 12.8578 8.68988 12.7049 8.87804 12.609C9.09195 12.5 9.37198 12.5 9.93203 12.5H10.0654C10.6254 12.5 10.9054 12.5 11.1194 12.609C11.3075 12.7049 11.4605 12.8578 11.5564 13.046C11.6654 13.2599 11.6654 13.5399 11.6654 14.1V17.5M8.33203 17.5H11.6654" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                                <path id="Vector 14" d="M7.5 5.83398H8.33333" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                                <path id="Vector 14_2" d="M7.5 9.16602H8.33333" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                                <path id="Vector 14_3" d="M11.668 5.83398H12.5013" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                                <path id="Vector 14_4" d="M11.668 9.16602H12.5013" stroke="white" strokeWidth="1.5" strokeLinecap="round"/>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col ps-0'>
                                                    <h5 className='pt-1'>Corporate Office</h5>
                                                    <p className='text-dark-secondary mb-0 fw-bold'>India</p>
                                                    <p className='text-dark-secondary mb-0'>2nd Floor, MyOffiz, Plot No. 270,Udyog Vihar – Phase 2, Gurugram</p>
                                                    <p className='text-dark-secondary mb-3'>Phone number : <a href='tel:8046107668' className='text-white'>+91-8046107668</a></p>
                                                    <p className='text-dark-secondary mb-0 fw-bold'>USA</p>
                                                    <p className='text-dark-secondary mb-5'>251 Little Falls Drive, Wilmington, DE 19808</p>
                                                </div>
                                            </div>

                                            <div className='row flex-nowrap'>
                                                <div className='mb-1 col-auto pe-0'>
                                                    <div className="icon-circle me-3 bg-dark text-white d-flex justify-content-center align-items-center">
                                                        <span>
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g id="vuesax/linear/user">
                                                                <g id="user">
                                                                <path id="Vector" d="M9.9987 9.99935C12.2999 9.99935 14.1654 8.13387 14.1654 5.83268C14.1654 3.5315 12.2999 1.66602 9.9987 1.66602C7.69751 1.66602 5.83203 3.5315 5.83203 5.83268C5.83203 8.13387 7.69751 9.99935 9.9987 9.99935Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                <path id="Vector_2" d="M17.1565 18.3333C17.1565 15.1083 13.9482 12.5 9.99817 12.5C6.04817 12.5 2.83984 15.1083 2.83984 18.3333" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col ps-0'>
                                                    <h5 className='pt-1'>Grievance Officer</h5>
                                                    <p className='fs-16 text-white'>Mr. Mukesh Rathi</p>
                                                    <p className='text-dark-secondary mb-0'>Head – Customer Success,Analytics Vidhya Educon Private Limited </p>
                                                    <p className='text-dark-secondary breakAll'> Email Id: <a href="mailto:grievance@analyticsvidhya.com" className='text-white'>grievance@analyticsvidhya.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12 col-12 ms-auto'>
                                <div>
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="icon-circle me-3 bg-dark text-white d-flex justify-content-center align-items-center">
                                            <span>
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g id="Phone Number">
                                                        <path id="Vector" d="M15.1001 12.253L11.6684 12.918C9.35012 11.7546 7.91845 10.418 7.08512 8.33463L7.72678 4.89297L6.51428 1.66797H3.38845C2.44845 1.66797 1.70845 2.44464 1.84928 3.3738C2.19928 5.69297 3.23262 9.8988 6.25178 12.918C9.42262 16.0888 13.9901 17.4646 16.5034 18.0121C17.4743 18.223 18.3351 17.4663 18.3351 16.4721V13.4855L15.1001 12.253Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                        <h5 className="text-white m-0">Need Help?</h5>
                                    </div>
                                    <p className='text-dark-tertiary'>Contact our Customer Support that is always ready to help you with any possible questions, problems or information.</p>
                                    <a href="mailto:info@analyticsvidhya.com" className='fs-20 text-white'>info@analyticsvidhya.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer platform={'newcontactus'} />
            <Cookies platform={'newhomepage'} />
        </>
    )
}


const mapStateToProps = (state) => ({
	homepagecontent:state.homepageContentReducer.homepagecontent,
    country_code:state.homepageContentReducer.country_code
  });

const mapDispatchToProps = dispatch=>({
  fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);

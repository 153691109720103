import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import $ from 'jquery';
import { useParams } from 'react-router-dom';

const UpdateQuestion = ({csrf}) => {
    const { questionId } = useParams();

    const [quizContext, setQuizContext] = useState(null);
    const [title, setTitle] = useState("");
    const [optionsInput, setOptionsInput] = useState(["",""]);
    const [answers, setAnswers] = useState([]);
    const [subject, setSubject] = useState("");
    const [selectedType, setSelectedType] = useState('');
    const [selectedPool, setSelectedPool] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const questionType = ['single_choice','multi_choice'];

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState('');
    const imageRef = useRef();
    const [image,setImage] = useState(null);
    const [isValidImage,setIsValidImage] = useState(false);

    useEffect(()=>{
        if(csrf !=""){
            quizContextData();

            const formData = new FormData();
          formData.append("platform", 'quizmania');
          formData.append("url", `quiz/question/${questionId}`);
          formData.append("forward_user_data", "True");
          formData.append("method","GET");

          fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
              method: 'POST',
              body:formData,
              headers: {
                  "X-CSRFToken":csrf,
              },
              credentials:process.env.REACT_APP_CREDENTIAL_TYPE
          })
          .then(response => {
              return response.json();
          })
          .then(result => {
              console.log(result.response);
              setTitle(result.response.title);
              result.response.mcq_options.map((option)=>{
                if(option.is_correct){
                    setAnswers(option.option_name)
                }
              });
              setSubject(result.response.subject);
              setOptionsInput(result.response.mcq_options);
              setSelectedType(result.response.question_type);
              setDifficulty(result.response.difficulty_level);
              setSelectedPool(result.response.pool);
              setImage(result.response.image)
          })
          .catch(error => {
              console.log(error);
          });
        }
    },[csrf]);

    const validImage = ()=>{
        if (imageRef && imageRef.current.files[0]) {
            const reader = new FileReader();
      
            reader.onload = function (e) {
              const img = new Image();
              img.src = e.target.result;


              setImage(e.target.result);
      
              img.onload = function () {
                const width = img.width;
                const height = img.height;
                const fileSize = imageRef.current.files[0].size / 1024; // Size in KB
      
                if (width / height === 2 && fileSize < 50 && width <= 600 && height <= 300) {
                  setIsValidImage(true);
                } else {
                  if(!(fileSize < 50)){
                    $('.warning').remove();
                    $('#image').parent().append(`<small class="text-danger warning">Image size should less then 50KB</small>`);
                    setTimeout(() => {
                        $('.warning').remove();
                    }, 3000);
                  }
                  else if(!(width <= 600)){
                    $('.warning').remove();
                    $('#image').parent().append(`<small class="text-danger warning">Image width should be 600</small>`);
                    setTimeout(() => {
                        $('.warning').remove();
                    }, 3000);
                  }
                  else if(!(height <= 300)){
                    $('.warning').remove();
                    $('#image').parent().append(`<small class="text-danger warning">Image height should be 300</small>`);
                    setTimeout(() => {
                        $('.warning').remove();
                    }, 3000);
                  }
                  else if(!(width / height === 2)){
                    $('.warning').remove();
                    $('#image').parent().append(`<small class="text-danger warning">Image ratio should be 2:1</small>`);
                    setTimeout(() => {
                        $('.warning').remove();
                    }, 3000);
                  }
                  setIsValidImage(false);
                }
              };
            };
      
            reader.readAsDataURL(imageRef.current.files[0]);
        }
    }

    const quizContextData = () => {
        const formData = new FormData();
        formData.append("url","quiz/context/");
        formData.append("platform", 'quizmania');
        formData.append("method", 'GET');

        fetch(process.env.REACT_APP_ELEVATE_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => response.json())
        .then(data => {
            console.log("Quiz related data is: ", data);
            setQuizContext(data?.response);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(imageRef.current.files[0] && !isValidImage){
            $('.warning').remove();
            $('#image').parent().append(`<small class="text-danger warning">Uploaded Image is not Valid</small>`);
            setTimeout(() => {
                $('.warning').remove();
            }, 3000);
            return false;
        }

        let optionMachingList = optionsInput.map((option)=>{
            return option.option_name
        })
        if(!optionMachingList.includes(answers)){
            $('.warning').remove();
            $('#correctAnswer').parent().append(`<small class="text-danger warning">Correct answer should be in options</small>`);
            setTimeout(() => {
                $('.warning').remove();
            }, 3000);
            return false;
        }
        
        let payload = {
            "title" : title,
            "options": JSON.stringify(optionsInput),
            "answers": answers,
            "subject": subject,
            "pool" : selectedPool,
            "question_type": selectedType,
            "difficulty_level": difficulty  
        }
        const formData = new FormData();
        if(imageRef.current.files[0]){
            formData.append('image',imageRef.current.files[0])
        }
        formData.append("platform", 'quizmania');
        formData.append("payload", JSON.stringify(payload));
        formData.append("url", `quiz/question/update/${questionId}/`);
        formData.append("forward_user_data", "True");
        formData.append("method","PUT");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then((response) => {
            return response.json();
        })
        .then(result => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
        });
    }

    const addOption = (e) => {
        e.preventDefault();
        if(optionsInput.length <=5){
            let templist = optionsInput;
            templist.push("");
            setOptionsInput([...templist]);
        }
    }
    
    const removeOption = (index) => {
        let templist = optionsInput;
        if(templist[index].option_id){
            deleteOption(templist[index].option_id);
        }
        templist.splice(index, 1);
        setOptionsInput([...templist]);
    }

    const updateOption = (index, value) => {
        let tempList = [...optionsInput];
        let updatedValue = {
            "option_id": tempList[index].option_id,
            "option_name":value
        }
        tempList[index] = updatedValue;
        setOptionsInput(tempList);
    }

    const deleteOption = (option_id) =>{
        const formData = new FormData();
        formData.append("url",`/quiz/mcqOption/delete/${option_id}/`);
        formData.append("platform", 'quizmania');
        formData.append("method", 'DELETE');
        formData.append("forward_user_data","True");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => response.json())
        .then(data => {
            console.log(data);
        })
        .catch(error => {
            console.log(error);
        });
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your question created successfully"} />}
            <section id='createQuestions' className='min-vh-100 background-dark-primary common-style'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-4 text-center text-white fw-semibold text-uppercase">Request Question Updation</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Question Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>
                                        {optionsInput.map((option,index)=>{
                                            return(
                                                <div className="option-row d-flex align-items-center">
                                                    <div className="col form-group mb-3">
                                                        <label className="me-3 text-white">Options {index+1} <sup className='text-danger'>*</sup></label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={`Option ${index+1}`}
                                                            value={optionsInput[index].option_name}
                                                            onChange={(event) =>{updateOption(index, event.target.value)}}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-auto">
                                                        {index > 1 && <button type='button' className='btn btn-dark btn-dark-primary ms-3' onClick={()=>{removeOption(index)}}>-</button>}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <button className='btn btn-dark btn-dark-primary mb-3 d-table ms-auto' onClick={addOption}>Add Option</button>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Correct Answer <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Correct Answer"
                                                id='correctAnswer'
                                                value={answers}
                                                onChange={(event) => setAnswers(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Select a Subject <sup className='text-danger'>*</sup></label>
                                            <select
                                                className="form-select"
                                                value={subject}
                                                onChange={(event) => setSubject(event.target.value)}
                                                required
                                            >
                                                <option value="">Select a Subject</option>
                                                {quizContext && quizContext?.subjects && quizContext.subjects.map((subName) => (
                                                    <option key={subName} value={subName}>
                                                        {subName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Select Question Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                className="form-select"
                                                value={selectedType}
                                                onChange={(event) => setSelectedType(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Question Type</option>
                                                {questionType.map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Select Difficulty Level <sup className='text-danger'>*</sup></label>
                                            <select
                                                className="form-select"
                                                value={difficulty}
                                                onChange={(event) => setDifficulty(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Difficulty Level</option>
                                                    {quizContext && quizContext?.difficulty && quizContext?.difficulty.map((difficulty) => (
                                                    <option key={difficulty} value={difficulty}>
                                                        {difficulty}
                                                    </option>
                                                    ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Select a Pool <sup className='text-danger'>*</sup></label>
                                            <select
                                                className="form-select"
                                                value={selectedPool}
                                                onChange={(event) => setSelectedPool(event.target.value)}
                                                required
                                            >
                                                <option value="">Select a Pool</option>
                                                {quizContext && quizContext?.pools && quizContext.pools.map(([poolName, poolId]) => (
                                                    <option key={poolId} value={poolId}>
                                                        {poolName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className='text-white' htmlFor="image">Image</label>
                                            <input type="file" id='image' ref={imageRef} onChange={validImage} className='form-control' />
                                        </div>
                                        {image && <img className='img-fluid mt-3' src={image} alt={image} width={600} />}


                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Update Question
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}



const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQuestion);

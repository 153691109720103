import React from 'react';
import Header from '../../../common/Header/Header';

const DatahackAdmin = () => {
    return (
        <>
            <Header />
            <section id='datahackAdminPortal' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row align-items-stretch'>
                        <div className='col-lg-6'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary h-100'>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <h3 className="card-title text-center mb-3 text-white text-uppercase fw-semibold">Competitions Admin</h3>

                                    <div className="card-content">
                                        <a href={`/av-admin/datahack-admin/stage`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Stage
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/tournament`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Tournament
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/tournament-stage-mapping`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Tournament Stage Mapping
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/ranker`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Ranker
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/problem-statement`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Problem Statement
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/evaluation-metric`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Evaluation Metric
                                            </button>
                                        </a>


                                        <a href={`/av-admin/datahack-admin/quiz`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Quiz
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/question`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Question
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/create-question-file`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Create Question Using File Upload
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/private-registration`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Private Registration
                                            </button>
                                        </a>
                                        <a href={`/av-admin/datahack-admin/generate-hackathon-csv`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Generate Hackathon CSV
                                            </button>
                                        </a>
                                        <a href={`/av-admin/datahack-admin/generate-blogathon-csv`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Generate Blogathon CSV
                                            </button>
                                        </a>
                                        <a href={`/av-admin/datahack-admin/avcc-panel`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Avcc Panel
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary h-100'>
                                <div className="card-body d-flex flex-column justify-content-between">
                                    <h3 className="card-title text-center mb-3 text-white text-uppercase fw-semibold">Events Admin</h3>

                                    <div className="card-content">
                                        <a href={`/av-admin/datahack-admin/speaker`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Speaker
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/datahour`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Datahour
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/datahour-resources`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Datahour Resources
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/attendee`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Attendee
                                            </button>
                                        </a>

                                        <a href={`/av-admin/datahack-admin/video`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Video
                                            </button>
                                        </a>
                                        <a href={`/av-admin/datahack-admin/generate-datahour-csv`} className='text-decoration-none text-light-primary'>
                                            <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 w-100'>
                                                Generate Datahour CSV
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DatahackAdmin;
import { FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    blackbeltleadpagescontent: null,
};
  
export const blackbeltLeadPagesContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS:
            return {
                ...state,
                blackbeltleadpagescontent: JSON.parse(action.payload.data.getPlatformJsonDataByName.platformData),
            };
        default:
            return state;
    }
};
  
import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import HomepageRouters from '../components/projects/Homepage/Routers/HomepageRouters';
import Error404 from '../components/common/Alert/Error404';
import BlackbeltRouters from '../components/projects/Marketing/Routers/BlackbeltRouters';
import EventsRouters from '../components/projects/Datahack/Routers/EventsRouters';
import Blackbeltplus from '../components/projects/Marketing/Blackbelt/pages/BlackBeltPlus';
import BlackbeltLeadPagesTwoField from '../components/projects/Marketing/Blackbelt/pages/BlackbeltLeadPagesTwoField';
import CompetitionRouters from '../components/projects/Datahack/Routers/CompetitionRouters';
import GenAiPinnacleRouters from '../components/projects/Marketing/Routers/GenAiPinnacleRouters';
import FreeCoursesRouters from '../components/projects/Marketing/Routers/FreeCoursesRouters';
import EnterpriseRouters from '../components/projects/Marketing/Routers/EnterpriseRouters';
import FractalRouters from "../components/projects/Marketing/Routers/FractalRouters";
import ProtectedRouter from "./ProtectedRouter";
import AdminRoutes from '../components/projects/Admin/Routers/AdminRoutes';
import ComingSoon from '../components/common/ComingSoon/Index';
import ProfileRouters from '../components/projects/Profile/Routers/ProfileRouters';
import AgenticAiRouters from '../components/projects/Marketing/Routers/AgenticAiRouters';
import AllFreeCourses from '../components/projects/Marketing/FreeCourses/pages/AllFreeCourses';

export default function MainRouter() {
  return (
    <>
      <Routes>
          <Route path='/*' exact element={<HomepageRouters />} />
          <Route path='/datahack/*' exact element={<CompetitionRouters />} />
          <Route path='/events/*' exact element={<EventsRouters />} />
          <Route path='/profile/*' exact element={<ProfileRouters />} />
          {/* <Route path='/identity' exact element={<IdentityRouters />} />
          <Route path='/profile' exact element={<ProfileRouters />} />*/}
          <Route path='/blackbelt/*' exact element={<BlackbeltRouters />} />
          <Route path='/bbplus/*' exact element={<Blackbeltplus />} />
          <Route path='/bbplus/:platformName' exact element={<BlackbeltLeadPagesTwoField />} />
          <Route path='/genaipinnacle/*' exact element={<GenAiPinnacleRouters />} />
          <Route path='/courses/*' exact element={<FreeCoursesRouters />} />
          <Route path='/all-free-courses/' exact element={<AllFreeCourses />} />
          <Route path='/enterprise/*' exact element={<EnterpriseRouters />} />
          <Route path='/fractal/*' exact element={<FractalRouters />} />
          <Route path='/av-admin/*' exact element={<ProtectedRouter ><AdminRoutes /></ProtectedRouter>} />
          <Route path='/coming-soon' exact element={<ComingSoon />} />
          <Route path='/agenticaipioneer/*' exact element={<AgenticAiRouters />}/>
          <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';

const Datahour = () => {

    const [datahours, setDatahours] = useState([]);

    useEffect(()=>{
        const query = `
        query MyQuery {
            datahourSessions {
                slug
                id
                speaker {
                    name
                }
                modifiedAt
                sessionName
            }
        }`

        getQueryDataElevate(query, "newevents", "True")
        .then((data)=>{
            setDatahours(data?.data?.datahourSessions);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    return (
        <>
            <Header />
            <section id="datahourList" className='common-style min-vh-100 background-dark-primary'>
                <div className="container">
                    <div className="row">
                        <div className='text-end p-0'>
                            <a href={'/av-admin/datahack-admin/datahour/create-datahour'} className='btn btn-dark btn-dark-primary px-5 py-2 my-3 text-uppercase fw-bold'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                               New Datahour
                            </a>
                        </div>
                        <table className='table table-striped text-center table-dark'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Speaker</th>
                                    <th>Modified At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datahours && datahours.map((val) => (
                                    <tr>
                                        <td><a href={'/av-admin/datahack-admin/datahour/'+val.id+"/"+val.slug} className='text-white'>{val.id}</a></td>
                                        <td><a href={'/av-admin/datahack-admin/datahour/'+val.id+"/"+val.slug} className='text-white'>{val.sessionName}</a></td>
                                        <td>{val.speaker && val.speaker.name}</td>
                                        <td>{val.modifiedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Datahour;
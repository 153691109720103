import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { getQueryData } from '../../../../utils';
import { connect } from 'react-redux';

function Attendee({csrf,islogin}) {
    const [attendeeData, setAttendeeData] = useState([]);
    useEffect(()=>{
        if(islogin){
            let query = `
            query MyQuery {
                allAttendees(limit: 10000, page: 1) {
                  attendeeData {
                    id
                    name
                    createdAt
                    updatedAt
                  }
                }
            }`

            getQueryData(query,"newevents")
            .then((data)=>{
                setAttendeeData(data.data.allAttendees.attendeeData);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])
  return (
    <> 
        <Header />
        <section id="attendee" className='common-style min-vh-100 background-dark-primary'>
            <div className="container">
                <a href={'/av-admin/datahack-admin/attendee/create-attendee'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Attendee
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Attendee Id</th>
                                        <th>Name</th>
                                        <th>Created At</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendeeData && attendeeData.map((attendee)=>{
                                        return(
                                            <tr>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/attendee/'+attendee.id}>{attendee.id}</a></td>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/attendee/'+attendee.id}>{attendee.name}</a></td>
                                                <td>{attendee.createdAt}</td>
                                                <td>{attendee.updatedAt}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                </div>
            </div>
        </section>
    </>
  )
}



const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(Attendee);
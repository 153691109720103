import { FETCH_HACKATHON_CONTENT_SUCCESS,FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS,FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS,FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS, FETCH_HACKATHON_TEAM_DETAIL_SUCCESS, CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS } from '../Contants/index';
  
const initialState = {
    hackathoncontent: null,
    loading: false,
    error: null,
    hackathonDetailsContent:null,
    tournamentData:null,
    stagesData:null,
    problemStatementData:null,
    isUserRegistered:false,
    userSubmissionsData:null,
    teamDetails:null,
    fullDataVisibility:null
};
  
export const hackathonContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_HACKATHON_CONTENT_SUCCESS:
            return {
                ...state,
                hackathoncontent: action.payload,
                loading: false,
                error: null,
        };
        case FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS:
            let stagesData = action.payload.tournamentData.tournament.tournamentstagemappingSet;
            let problemStatement = null;
            if(stagesData.length>0) {
                stagesData.map((stage)=>{
                    //One practice(code round) stage will be active at once
                    if(stage.stage.isActive && stage.stage.problemstatementSet.length>0) {
                        problemStatement = stage.stage.problemstatementSet;
                    }
                })
            }
            return {
                ...state,
                hackathonDetailsContent: action.payload,
                tournamentData: action.payload.tournamentData.tournament,
                fullDataVisibility: action.payload.tournamentData.fullDataVisibility,
                stagesData: action.payload.tournamentData.tournament.tournamentstagemappingSet,
                problemStatementData: problemStatement
        };
        case CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                hackathonDetailsContent:null,
                tournamentData:null,
                stagesData:null,
                problemStatementData:null,
                isUserRegistered:false,
                userSubmissionsData:null,
                teamDetails:null,
                fullDataVisibility:null
        };
        case FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS:
            return {
                ...state,
                isUserRegistered: action.payload.isUserRegistered,
        };
        case FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS:
            return {
                ...state,
                userSubmissionsData: action.payload,
        };
        case FETCH_HACKATHON_TEAM_DETAIL_SUCCESS:
            return {
                ...state,
                teamDetails: action.payload,
        };
        default:
            return state;
    }
};
  
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../../common/Alert/Error404';
import FractalHomeTesting from '../Fractal/pages/testing';
import FractalHome from '../Fractal/pages/index';

export default function FractalRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<FractalHome />} />
        <Route path='/testing' exact element={<FractalHomeTesting />} />
        <Route path='*' element={<Error404 />} />
    </Routes>
  )
}

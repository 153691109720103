import React from 'react';

export default function ComingSoon() {
  return (
    <>
        <section className='background-dark-secondary vh-100' id='comingSoon'>
            <div className="container h-75 d-flex align-items-center justify-content-center">
                <div className="row">
                    <h1 className='text-white text-center fs-56 fw-bold mb-3'>COMING SOON!</h1>
                    <p className='text-white fs-20 text-center mb-5'>Something big is on the way. Stay tuned for the excitement</p>
                    <a href='/' className='btn btn-dark btn-dark-primary d-table mx-auto w-auto'>Back to Homepage</a>
                </div>
            </div>
        </section>
    </>
  )
}

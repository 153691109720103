import React, { useEffect, useState } from 'react';
import { getQueryData, getQueryDataElevateUser } from "../../../../utils";
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const CreateTournamentStageMapping = ({csrf,islogin}) => {
    const [stageId, setStageId] = useState();
    const [tournamentId, setTournamentId] = useState();
    const [stages, setStages] = useState([]);
    const [tournaments, setTournaments] = useState([]);
    const [leaderboardWeightage,setLeaderboardWeightage] = useState("");

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    useEffect(() => {
        if(islogin){
            let query = ` 
            query {
                allStage(limit: 1000000, page: 1) {
                    totalPages
                    stageData {
                      id
                      slug
                      updatedAt
                      createdAt
                      title
                    }
                }

                allTournament(limit: 100000, page: 1) {
                    totalPages
                    tournamentData{
                        id
                        title
                        updatedAt
                        createdAt
                        slug
                    }
                }
            }
            `
            getQueryData(query, "newcompetition")
            .then((data) => {
                setStages(data?.data?.allStage.stageData);
                setTournaments(data?.data?.allTournament.tournamentData);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [islogin]);

    const resetFormFields = () => {
        setStageId("");
        setTournamentId("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let mutation = `
        mutation{
            createTournamentStageMapping(stageId: "${stageId}", tournamentId: "${tournamentId}",leaderboardWeightage: ${leaderboardWeightage?leaderboardWeightage:0}) {
                tournamentStageMapping {
                    stage {
                        id
                        title
                    }
                    tournament {
                        id
                        title
                    }
                }
            }
        }
        `

        getQueryDataElevateUser(mutation, "newcompetition", "True", csrf)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setMessage("Something went wrong!")
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result) => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message)
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch((error) => {
            setMessage(error.message)
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.error(error.message);
        })
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your stage mapped with tournament successfully"} />}
            <section id='createTournamentStageMapping' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Tournament Stage Mapping Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Stage <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="stageId"
                                                value={stageId}
                                                onChange={(event) => setStageId(event.target.value)}
                                                className='form-select'
                                                required
                                            >
                                                <option value="">Select Stage</option>
                                                {stages && stages.map((items) => (
                                                    <option key={items.id} value={items.id}>
                                                        {items.id}-{items.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Tournament <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="tournamentId"
                                                value={tournamentId}
                                                onChange={(event) => setTournamentId(event.target.value)}
                                                className='form-select'
                                                required
                                            >
                                                <option value="">Select Tournament</option>
                                                {tournaments && tournaments.map((items) => (
                                                    <option key={items.id} value={items.id}>
                                                        {items.id}-{items.title}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white">Leaderboard Weightage</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='LeaderBoard Weightage' 
                                            value={leaderboardWeightage}
                                            onChange={(event)=>setLeaderboardWeightage(event.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Tournament Stage Mapping
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournamentStageMapping);

import React, { useState, useEffect } from 'react';
import { getQueryData } from '../../../../utils';

function CustomFormEditor({ formId, formDataToEdit, onSubmit }) {
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (formDataToEdit) {
            setQuestions(formDataToEdit.questionSet);
        }
    }, [formDataToEdit]);

    const handleAddQuestion = (questionType) => {
        if (questionType) {
            if (['text', 'textarea', 'dropdown', 'checkbox', 'radio', 'email', 'number', 'password', 'file', 'date', 'location'].includes(questionType)) {
                const newQuestion = {
                    id: null, 
                    questionType: {"name":questionType},
                    required: false,
                    optionSet: ['radio', 'checkbox', 'dropdown'].includes(questionType) ? [] : null,
                    title: '',
                    placeHolder: ''
                };

                const newQuestions = [...questions, newQuestion];
                setQuestions(newQuestions);
            }
        }
    };

    const handleDeleteQuestion = (e,index) => {
      e.preventDefault();
        const to_be_deleted_questions = questions.filter((question,questionindex) => index === questionindex);
        if(to_be_deleted_questions[0].id){
          const query = `
          mutation MyMutation {
            deleteQuestion(id: "${to_be_deleted_questions[0].id}", formId: "${formId}") {
              success
            }
          }`;

          getQueryData(query,"newcustomforms")
          .then((data)=>{
            if(data.data.deleteQuestion.success){
              const updatedQuestions = questions.filter((question,questionindex) => index !== questionindex);
              setQuestions(updatedQuestions);
            }
            else{
              alert('Unable to delete this question.Kindly refresh the page.')
            }
          })
          .catch((error)=>{
            console.log(error)
          })
        }
        else{
          const updatedQuestions = questions.filter((question,questionindex) => index !== questionindex);
          setQuestions(updatedQuestions);
        }
    };

    const handleQuestionChange = (index, inputType, value) => {
        setQuestions((prevQuestions) =>
            prevQuestions.map((question,questionindex) => {
                if (index === questionindex) {
                    if (inputType === 'isRequired') {
                        return {
                            ...question,
                            required: !question.required,
                        };
                    } 
                    if (inputType === 'questionLabel') {
                        return {
                            ...question,
                            title: value,
                        };
                    }
                    if (inputType === 'placeHolder') {
                        return {
                            ...question,
                            placeHolder: value,
                        }
                    }
                    if (inputType === 'options') {
                        return {
                          ...question,
                          optionSet:value.split(',').map((option) => ({text:option.trim()})),
                      };
                    }
                    return question;
                }
                return question;
            })
        );
    };

    const handleSubmitForm = () => {
        const hasEmptyLabels = questions.some((question) => question.title.trim() === '');
        const hasEmptyOptions = questions.some(
            (question) =>
                ['radio', 'checkbox', 'dropdown'].includes(question.questionType.name) &&
                (question.optionSet.length === 0)
        );

        if (questions.length === 0) {
            setError('Alert: Add at least one question before generating the form.');
            setTimeout(() => {
                setError(null);
            }, 2000);
            return false;
        }
        if (hasEmptyLabels) {
            setError('Alert: Question labels can not be empty.');
            setTimeout(() => {
                setError(null);
            }, 2000);
            return false;
        } 
        if (hasEmptyOptions) {
            setError('Alert: Options can not be empty.');
            setTimeout(() => {
                setError(null);
            }, 2000);
            return false;
        } 
        sendFormData(questions);
    }

    const sendFormData = async (allQuestions) => {
        const questionQueries = allQuestions.map((question) => `
            {
                questionId: ${question.id ? `${question.id}`:null}
                title: "${question.title}",
                typeName: "${question.questionType.name}",
                required: ${question.required},
                options: ${question.optionSet ? `[${question.optionSet.map(obj => `"${obj.text}"`)}]` :question.optionSet },
                formId: ${formId},
                placeHolder: "${question.placeHolder}"
            }
        `).join(',');

        const query = ` 
            mutation {
                createAllQuestion(allQuestions: [${questionQueries}]) {
                    formQuestion {
                        id
                        title
                        questionType {
                            id
                            name
                        }
                        required
                        optionSet {
                            id
                            text
                        }
                        placeholder
                    }
                }
            }
        `;

        getQueryData(query, "newcustomforms")
            .then((data) => {
                const responseData = data.data.createAllQuestion.formQuestion;

                const updatedQuestions = responseData.map((responseQuestion, index) => ({
                    ...questions[index],
                    id: responseQuestion.id,
                }));

                setQuestions(updatedQuestions);

                const formData = {
                    id: formId,
                    questionSet: updatedQuestions,
                    title:formDataToEdit.title
                };
                onSubmit(formData);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    return (
        <>
            <section className="py-5">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-lg-2">
                            <ul className="list-unstyled">
                                {[
                                    { value: 'text', typeOfQuestion: 'Text' },
                                    { value: 'textarea', typeOfQuestion: 'Text Area' },
                                    { value: 'dropdown', typeOfQuestion: 'Dropdown' },
                                    { value: 'checkbox', typeOfQuestion: 'Checkbox' },
                                    { value: 'radio', typeOfQuestion: 'Radio' },
                                    { value: 'email', typeOfQuestion: 'Email' },
                                    { value: 'number', typeOfQuestion: 'Number' },
                                    { value: 'password', typeOfQuestion: 'Password' },
                                    { value: 'date', typeOfQuestion: 'Date' },
                                    { value: 'file', typeOfQuestion: 'File' },
                                    { value: 'location', typeOfQuestion: 'Location' },
                                ].map((questionType) => (
                                    <li key={questionType.value}>
                                        <button
                                            onClick={() => handleAddQuestion(questionType.value)}
                                            className="btn btn-dark btn-dark-primary px-5 py-2 mb-2 w-100"
                                        >
                                            {questionType.typeOfQuestion}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="col-lg-8 mx-auto">
                            <div className="card p-2 border-0 shadow background-dark-tertiary">
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3">
                                        Request Form Generation
                                    </h3>
                                    {error && (
                                        <div className="alert text-center text-danger d-block">
                                            {error}
                                        </div>
                                    )}
                                    <form className="w-100">
                                        {questions.map((question,index) => (
                                            <div className="card p-1 mb-3" key={index}>
                                                <div className="card-header">
                                                    <span className="fs-5 fw-bold text-uppercase">
                                                        {question.questionType.name}
                                                    </span>
                                                    <button
                                                        onClick={(e) => handleDeleteQuestion(e,index)}
                                                        className="btn btn-sm fw-bold float-end border-0 fs-20"
                                                    >
                                                        x
                                                    </button>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            placeholder="Question Type"
                                                            value={question.questionType.name}
                                                            disabled
                                                            className="form-control mb-3 text-capitalize"
                                                        />

                                                        <input
                                                            type="text"
                                                            placeholder="Enter a label for this question"
                                                            value={question.title}
                                                            onChange={(e) =>
                                                                handleQuestionChange(
                                                                    index,
                                                                    'questionLabel',
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control mb-3 text-capitalize"
                                                        />

                                                        <input
                                                            type="text"
                                                            placeholder="Enter a placeholder for this question"
                                                            value={question.placeHolder}
                                                            onChange={(e) =>
                                                                handleQuestionChange(
                                                                    index,
                                                                    'placeHolder',
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control mb-3 text-capitalize"
                                                        />

                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                checked={question.required}
                                                                onChange={() =>
                                                                    handleQuestionChange(
                                                                        index,
                                                                        'isRequired',
                                                                        !question.required
                                                                    )
                                                                }
                                                                className="form-check-input"
                                                            />
                                                            <label className="form-check-label">Required?</label>
                                                        </div>

                                                        {['radio', 'checkbox', 'dropdown'].includes(question.questionType.name) && (
                                                            <div className="form-group">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Options (comma-separated)"
                                                                    value={question.optionSet.length > 0 ? question.optionSet.map(obj => obj.text) : ''}
                                                                    onChange={(e) => {
                                                                        handleQuestionChange(
                                                                            index,
                                                                            'options',
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className="form-control mb-3 text-capitalize"
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="text-center">
                                            <button
                                                type="button"
                                                onClick={handleSubmitForm}
                                                className="btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100"
                                            >
                                                I Want To Generate A Form
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CustomFormEditor;

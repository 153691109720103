import React, { useEffect, useState } from 'react';
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import * as $ from 'jquery';
import { validatedEmail, validatedMobile } from '../../../../../utils/InputValidation';
import { submitRegisterationForm } from '../../../../../utils';
import { getCSRF, sendOtp, verifyOtp } from '../../../../../utils/Login';

var iti3 = null;

export default function FreeTrialTwoFieldLeadModal({formname,setSuccess,setIsError,country_name,setSuccessMessage,leadFormCta,leadFormHeading,section,csrf,email,phone,otp_request_id,country_code,setEmail,setPhone,setCsrf}) {
    const [userDetails,setUserDetails] = useState({
        fullname:null,
        email:'',
        phone:'',
        country_code:''
    })
    const [additionalDetail,setAdditionalDetail] = useState({
        experience:"Yes",
        path:"Data Scientist"
    })
    const [otprequestid,setOtpRequestId] = useState('');
    const [otp,setOtp] = useState({
      digit1:'',
      digit2:'',
      digit3:'',
      digit4:'',
      digit5:'',
      digit6:''
    });
    const [resendOtpTimer,setResendOtpTimer] = useState(30);
    const [isresend,setIsResend] = useState(false);

    useEffect(()=>{
        const input = document.getElementById("leadPhoneNumber");
        if(input){
          iti3 = intlTelInput(input, {
            allowDropdown: true,
            autoHideDialCode: true,
            initialCountry: country_name?country_name:"IN",
            preferredCountries: ["in", "us"],
          });
          if(country_name){
            iti3.setCountry(country_name);
          }
        }
    },[country_name])

    useEffect(()=>{
      setUserDetails({
          fullname:null,
          email:'',
          phone:'',
          country_code:''
      });
      setAdditionalDetail({
          experience:"Yes",
          path:"Data Scientist"
      })
      $('#checkbox1').prop('checked','checked');
      $('#pathcheckbox2').prop('checked','checked');
    },[formname])

    useEffect(()=>{
      if(otp.digit1 === '' || otp.digit2 === '' || otp.digit3 === '' || otp.digit4 === '' || otp.digit5 === '' || otp.digit6 === ''){
        $('#otpSubmitBtn').attr('disabled','disabled');
      }
      else{
        $('#otpSubmitBtn').removeAttr('disabled');
      }
    },[otp])

    useEffect(()=>{
      const inputs = document.querySelectorAll('#otp > *[id]');
      for (let i = 0; i < inputs.length; i++) { 
        inputs[i].addEventListener('keydown', function (event) {
            if (event.key === "Backspace") {
              inputs[i].value = ''; 
              if (i !== 0) inputs[i - 1].focus(); 
            } 
            else {
              if (i === inputs.length - 1 && inputs[i].value !== '') {
                return true; 
              } 
              else if (event.keyCode > 47 && event.keyCode < 58) {
                inputs[i].value = event.key; 
                if (i !== inputs.length - 1) inputs[i + 1].focus(); 
                event.preventDefault(); 
              } 
              else if (event.keyCode > 64 && event.keyCode < 91) {
                // inputs[i].value = String.fromCharCode(event.keyCode);
                // if (i !== inputs.length - 1) inputs[i + 1].focus();
                // event.preventDefault(); 
                return true;
              } 
            } 
          }); 
        }
    },[])

    useEffect(()=>{
      let interval = setInterval(() => {
        setResendOtpTimer((prev)=>{
          if(prev === 0){
            clearInterval(interval);
            setIsResend(true);
            return 30;
          }
          return prev-1;
        });
      },1000);
    },[otp_request_id])

    useEffect(()=>{
      setOtpRequestId(otp_request_id);
      setUserDetails({
        ...userDetails,
        email:email,
        phone:phone,
        country_code:country_code
      })
    },[otp_request_id]);

    const saveLeadForm = (e)=>{
        e.preventDefault();
        let country_code = '+'+iti3.getSelectedCountryData().dialCode;
        setUserDetails({
          ...userDetails,
          country_code:country_code
        })
        if(!validatedMobile(userDetails.phone)){
          $('.error').remove();
          $('#leadPhoneNumber').closest('.col-md-12').append(`<small class="text-danger error d-block">Enter a Valid Phone Number</small>`);
          $('#leadPhoneNumber').css('border-color','red');
          $('#leadPhoneNumber').css('color','red');
          setTimeout(()=>{
              $('#leadPhoneNumber').css('border-color','#383838');
              $('#leadPhoneNumber').css('color','white');
              $('.error').slideUp();
              $('.error').remove();
          },2000)
          return false;
        }
          if(!validatedEmail(userDetails.email)){
            $('.error').remove();
            $('#leadEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
            $('#leadEmail').css('border-color','red');
            $('#leadEmail').css('color','red');
            setTimeout(()=>{
                $('#leadEmail').css('border-color','#383838');
                $('#leadEmail').css('color','white');
                $('.error').slideUp();
                $('.error').remove();
            },2000)
            return false;
        }
       
        let user_data = {
            "fullname":userDetails.fullname,
            "email":userDetails.email,
            "phone":userDetails.phone,
            "country_code":country_code,
        }
        let additional_detail ={
          "tnc":true,
          "is_whatsapp":true,
          "section":section,
          "page_url":window.location.href
        }
        sendEmail();
        submitRegisterationForm(user_data,additional_detail,'blackbelt','free_trial',csrf)
        .then((data)=>{
          
        })
        .catch((error)=>{
          console.log(error)
          // setIsError(true);
          // setTimeout(()=>{
          //   setIsError(false);
          // },4000)
        })
    }

    const updateExperience = (value)=>{
        setAdditionalDetail((prev)=>{return({...prev,experience:value})})
    }

    const updatePath = (value)=>{
      setAdditionalDetail((prev)=>{return({...prev,path:value})})
    }

    const removebackdrop = ()=>{
      $('.modal-backdrop.show').remove();
      $('body').removeClass('modal-open');
      $('body').removeAttr('style');
    }

    let sendEmail = ()=>{
      sendOtp(userDetails.email,csrf)
      .then((data)=>{
        setOtpRequestId(data.otp_request_id);
        window.$('#freetrialLeadModal').modal('show');
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  
    let verifyEmailOtp = ()=>{
      if(otp.digit1 === '' && otp.digit2 === '' && otp.digit3 === '' && otp.digit4 === '' && otp.digit5 === '' && otp.digit6 === ''){
        return false;
      }
      let otp_string = `${otp.digit1}${otp.digit2}${otp.digit3}${otp.digit4}${otp.digit5}${otp.digit6}`;
      let enrollment_url = null;
      if(additionalDetail.experience === "Yes"){
        if(additionalDetail.path === "Data Analyst"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-da";
        }
        else if(additionalDetail.path === "Data Scientist"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-dsd";
        }
        else if(additionalDetail.path === "Data Engineer"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-ded";
        }
        else{
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-aid";
        }
      }
      else{
        if(additionalDetail.path === "Data Analyst"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-da";
        }
        else if(additionalDetail.path === "Data Scientist"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-dsn";
        }
        else if(additionalDetail.path === "Data Engineer"){
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-den";
        }
        else{
          enrollment_url = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-program-ain";
        }
      }
      verifyOtp(userDetails.email,otp_string,otprequestid,csrf,enrollment_url)
      .then((data)=>{
        if(data.is_verified){
          getCSRF()
          .then((data)=>{
            setCsrf(data);
            saveLeadAfterVerification(data);
          })
          setOtp({
            digit1:'',
            digit2:'',
            digit3:'',
            digit4:'',
            digit5:'',
            digit6:''
          });
        }
        else{
          $('.warning').remove();
          $('#otp').parent().parent().append(`<p class="warning text-danger text-center w-100 mt-2 fs-13">${data.response}</p>`);
          setTimeout(() => {
            $('.warning').slideUp(1500,'linear',()=>{
              $('.warning').remove();
            });
          }, 3000);
        }
      })
      .catch((error)=>{
        console.log(error)
      })
    }

    let resendOtp = ()=>{
      setOtp({
        digit1:'',
        digit2:'',
        digit3:'',
        digit4:'',
        digit5:'',
        digit6:''
      });
      sendEmail();
      $('.warning').remove();
          $('#otp').parent().parent().append(`<p class="warning text-success text-center w-100 mt-2 fs-13">Otp resend successfully on ${email}</p>`);
          setTimeout(() => {
            $('.warning').slideUp(1500,'linear',()=>{
              $('.warning').remove();
            });
      }, 3000);
      let interval = setInterval(() => {
        setResendOtpTimer((prev)=>{
          if(prev === 0){
            clearInterval(interval);
            setIsResend(true);
            return 30;
          }
          return prev-1;
        });
      },1000);
      setIsResend(false);
    }

    const saveLeadAfterVerification = (tempcsrf)=>{
      let user_data = {
          "fullname":userDetails.fullname,
          "email":userDetails.email,
          "phone":userDetails.phone,
          "country_code":userDetails.country_code,
      }
      let additional_detail ={
        "tnc":true,
        "is_whatsapp":true,
        "section":section,
        "page_url":window.location.href,
        "experience":additionalDetail.experience,
        "career_path":additionalDetail.path
      }
      let form_type = null;
      if(additionalDetail.experience === "Yes"){
        if(additionalDetail.path === "Data Analyst"){
          form_type = "da_data"
        }
        else if(additionalDetail.path === "Data Scientist"){
          form_type = "ds_data"
        }
        else if(additionalDetail.path === "Data Engineer"){
          form_type = "de_data"
        }
        else{
          form_type = "al_data"
        }
      }
      else{
        if(additionalDetail.path === "Data Analyst"){
          form_type = "da_nondata"
        }
        else if(additionalDetail.path === "Data Scientist"){
          form_type = "ds_nondata"
        }
        else if(additionalDetail.path === "Data Engineer"){
          form_type = "de_nondata"
        }
        else{
          form_type = "al_nondata"
        }
      }
      setSuccess(true);
      setSuccessMessage('You personalised roadmap is on the way, you will be redirected to your customised free trial now');
      setTimeout(()=>{
        setSuccess(false);
        setSuccessMessage('Your details have been submitted successfully. Our team will contact you soon. Thank you!');
        // if(additionalDetail.experience === "Yes"){
        //   if(additionalDetail.path === "Data Analyst"){
        //     form_type = "da_data"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-da";
        //   }
        //   else if(additionalDetail.path === "Data Scientist"){
        //     form_type = "ds_data"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-dsd";
        //   }
        //   else if(additionalDetail.path === "Data Engineer"){
        //     form_type = "de_data"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-ded";
        //   }
        //   else{
        //     form_type = "al_data"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-aid";
        //   }
        // }
        // else{
        //   if(additionalDetail.path === "Data Analyst"){
        //     form_type = "da_nondata"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-da";
        //   }
        //   else if(additionalDetail.path === "Data Scientist"){
        //     form_type = "ds_nondata"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-dsn";
        //   }
        //   else if(additionalDetail.path === "Data Engineer"){
        //     form_type = "de_nondata"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-den";
        //   }
        //   else{
        //     form_type = "al_nondata"
        //     window.location.href = "https://courses.analyticsvidhya.com/bundles/certified-ai-ml-blackbelt-plus-ain";
        //   }
        // }
        window.location.href = "https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/enrollments";
        setAdditionalDetail({
          experience:"Yes",
          path:"Data Analyst"
        })
      },5000)
      setUserDetails({
        fullname:null,
        email:'',
        phone:'',
        country_code:''
      })
      setEmail('');
      setPhone('');
      $('#checkbox1').prop('checked','checked');
      $('#pathcheckbox1').prop('checked','checked');
      window.$('#freetrialLeadModal').modal('hide');
      window.$('#blackbeltLeadModal').modal('hide');

      submitRegisterationForm(user_data,additional_detail,'blackbelt',form_type,tempcsrf)
      .then((data)=>{
        
      })
      .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
      })
    }

    const copyPaste = (event)=>{
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      if (/^\d{6}$/.test(paste)) {
        setOtp({
          digit1: paste[0],
          digit2: paste[1],
          digit3: paste[2],
          digit4: paste[3],
          digit5: paste[4],
          digit6: paste[5],
        });
        $('#otpSubmitBtn').addClass('hover');
      }
      event.preventDefault();
    }

  return (
    <>
      <div className="modal lead-modal" id='blackbeltLeadModal' data-bs-backdrop="static" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content background-dark-primary shadow-sm rounded-4">
              <div className="modal-header border-0 p-0 ">
                <h2 className='text-white fs-32'>Get personalised roadmap and Start Free Trial<span className='text-orange d-none'>20% off</span></h2>
                <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
              <div className="modal-body p-0 pt-5">
                  <div className="row">
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">Phone Number</label>
                          <input type="tel" className="form-control background-dark-tertiary py-3 pe-3" id="leadPhoneNumber" placeholder="Your Phone Number" autoComplete='off' value={userDetails.phone} onChange={e=>setUserDetails((prev)=>{return{...prev,phone:e.target.value}})} />
                      </div>
                  </div>
                  <div className="row">
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">Email Id</label>
                          <input type="text" className="form-control background-dark-tertiary py-3 px-3" id="leadEmail" placeholder="Your email Id" autoComplete='off' value={userDetails.email} onChange={e=>setUserDetails((prev)=>{return{...prev,email:e.target.value}})} />
                      </div>
                  </div>
                  <div className="mt-4 mb-4 d-flex flex-wrap">
                      <div className="form-check d-flex align-items-center mb-2 me-4">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>terms & conditions</a></label>
                      </div>
                      <div className="form-check d-flex align-items-center mb-2">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                      </div>
                  </div>
              </div>
              <div className="modal-footer border-0 p-0">
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" onClick={saveLeadForm}>Start Now!</button>
              </div>
          </div>
          </div>
      </div>
      <div className="modal lead-modal" id='freetrialLeadModal' data-bs-backdrop="static" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content background-dark-primary shadow-sm rounded-4">
              <div className="modal-header border-0 p-0">
                <h2 className='text-white fs-32'>Personalize your journey, fill out the following details<span className='text-orange d-none'>20% off</span></h2>
                <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
              </div>
              <div className="modal-body p-0 pt-5">
                  <div className="row">
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">Have you ever worked as a Data Analyst, Data Scientist, ML Engineer, Data Engineer or in similar roles?</label>
                          <div className="experience-tag">
                              <input type='radio' className="form-check-input me-2 d-none" id={"checkbox1"} name="experience" onChange={()=>{updateExperience("Yes")}} defaultChecked />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox1"}>Yes</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"checkbox2"} name="experience" onChange={()=>{updateExperience("No")}} />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"checkbox2"}>No</label>
                          </div>
                      </div>
                      <div className="col-lg col-md-12 col-12 mb-3">
                          <label htmlFor="leadFullName" className="form-label text-white fs-14">What do you aspire to become?</label>
                          <div className="experience-tag">
                              <input type='radio' className="form-check-input me-2 d-none" id={"pathcheckbox1"} name="path" onChange={()=>{updatePath("Data Analyst")}} />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"pathcheckbox1"}>Data Analyst</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"pathcheckbox2"} name="path" onChange={()=>{updatePath("Data Scientist")}} defaultChecked />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"pathcheckbox2"}>Data Scientist</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"pathcheckbox3"} name="path" onChange={()=>{updatePath("Data Engineer")}} />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"pathcheckbox3"}>Data Engineer</label>
                              <input type='radio' className="form-check-input me-2 d-none" id={"pathcheckbox4"} name="path" onChange={()=>{updatePath("AI leader")}} />
                              <label className="form-check-label text-white fs-12 p-2 background-dark-tertiary rounded-2 pointer d-inline-block me-3 mb-3" for={"pathcheckbox4"}>AI Leader</label>
                          </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="otpLogin" className="form-label text-white fs-14">Enter the OTP sent on Email</label>
                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2"> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="first" maxLength="1" value={otp.digit1} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit1:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="second" maxLength="1" value={otp.digit2} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit2:e.target.value}})}} autoComplete='off'/>
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="third" maxLength="1" value={otp.digit3} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit3:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="fourth" maxLength="1" value={otp.digit4} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit4:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="fifth" maxLength="1" value={otp.digit5} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit5:e.target.value}})}} autoComplete='off'/> 
                          <input className="m-2 text-center form-control rounded background-dark-tertiary" type="text" placeholder='-' id="sixth" maxLength="1" value={otp.digit6} onPaste={copyPaste} onKeyDown={(e)=>{setOtp((prev)=>{return {...prev,digit6:e.target.value}})}} autoComplete='off'/> 
                        </div>
                      </div>
                  </div>
                  <div className="mt-4 mb-4 d-flex flex-wrap d-none">
                      <div className="form-check d-flex align-items-center mb-2 me-4">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="termAndConditionLoginModal" defaultChecked disabled />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="termAndConditionLoginModal">I Agree to the <a href={'/terms'} target='_blank' className='textdecoration-underline text-dark-tertiary' onClick={removebackdrop}>terms & conditions</a></label>
                      </div>
                      <div className="form-check d-flex align-items-center mb-2">
                          <input className="form-check-input rounded-1 me-2 opacity-100" type="checkbox" value="" id="isWhatsappLoginModal" defaultChecked />
                          <label className="form-check-label text-dark-tertiary fs-14 opacity-100" htmlFor="isWhatsappLoginModal">Send WhatsApp Updates</label>
                      </div>
                  </div>
                  {isresend ? <p className='blue pointer' onClick={resendOtp}>Resend OTP</p>:<p className='text-dark-tertiary'>Resend OTP in <span className='blue'>{resendOtpTimer}s</span></p>}  
              </div>
              <div className="modal-footer border-0 p-0">
                  <button className="btn btn-dark btn-dark-primary rounded-3 fs-16 d-flex align-items-center w-100 justify-content-center m-0" id='otpSubmitBtn' onClick={verifyEmailOtp}>Submit</button>
              </div>
          </div>
          </div>
      </div>
    </>
  )
}

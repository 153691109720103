import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { useParams } from 'react-router-dom';
import { getQueryDataElevate, getQueryDataElevateUser, trimNewlines } from '../../../../utils';
import { connect } from 'react-redux';
import { validateLinkedInUrl } from '../../../../utils/InputValidation';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const UpdateSpeaker = ({ csrf }) => {

    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyLogo, setCompanyLogo] = useState(null);
    const [displayPicture, setDisplayPicture] = useState(null);
    const [isFeaturedSpeaker, setIsFeaturedSpeaker] = useState(false);
    const [speakerDetail, setSpeakerDetail] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [displayPictureURL, setDisplayPictureURL] = useState("");
    const [companyLogoURL, setCompanyLogoURL] = useState("");

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);

    const companyLogoRef = useRef(null);
    const displayPictureRef = useRef(null);

    const { id } = useParams();

    useEffect(() => {
        const query = `
        query {
            speaker(id:${id}) {
                id
                userId
                name
                designation
                displayPicture
                displayPictureUrl
                isFeaturedSpeaker
                speakerDetail
                index
                companyLogoUrl
                companyLogoUrl
                linkedin
                companyName
            }
        }`

        getQueryDataElevate(query, "newevents", "True")
            .then((data) => {
                console.log(data.data);
                setName(data.data.speaker.name);
                setDesignation(data.data.speaker.designation);
                setCompanyName(data.data.speaker.companyName);
                setIsFeaturedSpeaker(data.data.speaker.isFeaturedSpeaker);
                setLinkedin(data.data.speaker.linkedin);
                setSpeakerDetail(data.data.speaker.speakerDetail);
                setDisplayPictureURL(data.data.speaker.displayPictureUrl);
                setCompanyLogoURL(data.data.speaker.companyLogoUrl);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    const handleSubmit = (e)=>{
        e.preventDefault();
        if (!validateLinkedInUrl(linkedin)) {
            alert("LinkedIn URL is not valid.");
            return false;
        }

        const htmlSpeakerDetail = trimNewlines(speakerDetail);
        var formdata = new FormData();

        if(!companyLogoRef.current.files.length > 0 && !displayPictureRef.current.files.length > 0){
            let query = `mutation MyMutation {
                updateSpeaker(
                    id: ${id}
                    name: "${name}"
                    designation: "${designation}"
                    companyName: "${companyName}"
                    linkedin: "${linkedin}"
                    isFeaturedSpeaker: ${isFeaturedSpeaker}
                    speakerDetail: "${htmlSpeakerDetail}"
                ) {
                    speaker { 
                        name
                        companyName 
                    }
                }
            }`;
            console.log(query);
            getQueryDataElevateUser(query,"newevents","True",csrf)
            .then((data)=>{
                if(!data.errors){
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else{
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => console.log('error', error));
            return false;
        }

        let mutationString = `mutation MyMutation($id: Int!, $name: String, $designation: String, $companyName: String, $companyLogo: Upload, $displayPicture: Upload, $speakerDetail: String) { 
        updateSpeaker(id: $id, name: $name, designation: $designation, companyName: $companyName, companyLogo: $companyLogo, displayPicture: $displayPicture, isFeaturedSpeaker: ${isFeaturedSpeaker}, speakerDetail: $speakerDetail) { 
            speaker { 
                    name, 
                    companyName 
                }
            }
        }`;

        let variables = {
            id: parseInt(id),
            name: name,
            designation: designation,
            companyName: companyName,
            linkedin: linkedin,
            speakerDetail: htmlSpeakerDetail,
            ...(companyLogoRef.current.files[0] && {companyLogo :null}),
            ...(displayPictureRef.current.files[0] && {displayPicture :null}),
        }

        const map = {};

        if(companyLogoRef.current.files.length > 0 ){
            formdata.append("0", companyLogoRef.current.files[0]);
            map["0"] = ["variables.companyLogo"]
        }
        if(displayPictureRef.current.files.length > 0 ){
            formdata.append("1", displayPictureRef.current.files[0]); 
            map["1"] = ["variables.displayPicture"];
        }  
        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newevents");
        formdata.append("forward_user_data","True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };
        console.log("Form data:", formdata);
        console.log(typeof id);
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 5000);
                return false;
            }
            return response.json();
        })
        .then((data) => {
            if(!data.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
            else{
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => console.log('error', error));
        return false;

    }

    const handleCompanyLogoUpload = ()=> {
        const file = companyLogoRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setCompanyLogo(imageURL);
        } else {
            setCompanyLogo("");
        }
    }

    const handleDisplayPictureUpload = ()=> {
        const file = displayPictureRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setDisplayPicture(imageURL);
        } else {
            setDisplayPicture("");
        }
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Network response was not ok"} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Speaker updated successfully"} />}
            <section id='updateSpeaker' className="common-style background-dark-primary min-vh-100">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold">Update Speaker</h3>

                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Name'
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Designation</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Designation'
                                                value={designation}
                                                onChange={(event) => setDesignation(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Company Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Company Name'
                                                value={companyName}
                                                onChange={(event) => setCompanyName(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Featured Speaker:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isFeaturedSpeaker}
                                                onChange={(event) => setIsFeaturedSpeaker(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Linkedin</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Linkedin'
                                                value={linkedin}
                                                onChange={(event) => setLinkedin(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Speaker Detail</label>
                                            <textarea
                                                className='form-control'
                                                value={speakerDetail}
                                                onChange={(event) => setSpeakerDetail(event.target.value)}
                                                style={{ height: '100px' }}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Display Picture:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="displayPicture"
                                                ref={displayPictureRef} 
                                                onChange={handleDisplayPictureUpload}
                                            />
                                            {displayPictureURL && (
                                                <div className='mt-2'>
                                                    <img src={displayPicture} alt="Display Picture" className='img-fluid' />
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Company Logo:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="companyLogo"
                                                ref={companyLogoRef}
                                                onChange={handleCompanyLogoUpload}
                                            />
                                            {companyLogoURL && (
                                                <div className='mt-2'>
                                                    <img src={companyLogo} alt="Company Logo" className='img-fluid' />
                                                </div>
                                            )}
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Update Speaker
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSpeaker);
import React, { useState } from 'react'
import DynamicFormFields from '../components/DynamicFormFields';
import Header from '../../../common/Header/Header';

export default function PlatformDataUpdateForm() {
	const [platform, setPlatform] = useState('genaipinnacle');

	const handlePlatformChange = (e) => {
		setPlatform(e.target.value);
	};

  return (
	<>	
		<Header />
		<section className='bg-light 100vh common-style' id='platformDataUpdate'>
			<div className="container">
				<div className='card p-4 w-50 mx-auto border-0 shadow background-dark-secondary'>
					<h1 className='text-center text-white mb-2'>CMS</h1>
					<label className='text-white mb-2'>Select platform:</label>
					<select value={platform} onChange={handlePlatformChange} className="form-select mb-5 background-dark-tertiary text-white border-0">
						<option defaultValue value="genaipinnacle">Genaipinnacle</option>
						<option value="blackbelt">Blackbelt</option>
						{/* <option value="homepage">Homepage</option> */}
						<option value="agenticai">Agentic AI</option>
					</select>
					<DynamicFormFields selectedPlatform={platform} />
				</div>
			</div>
		</section>
	</>
  )
}

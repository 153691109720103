import React, { useEffect, useState } from "react";
import { getQueryDataElevate, trimNewlines } from "../../../../utils";
import Header from "../../../common/Header/Header";
import ErrorAlert from "../../../common/Alert/ErrorAlert";
import SuccessAlert from "../../../common/Alert/SuccessAlert";
import { connect } from "react-redux";

const CreateDatahour = ({csrf}) => {

    const [sessionName, setSessionName] = useState("");
    const [sessionType, setSessionType] = useState("");
    const [speaker, setSpeaker] = useState([]);
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [description, setDescription] = useState("");
    const [registrationDisabled, setRegistrationDisabled] = useState(false);
    const [autoRegister, setAutoRegister] = useState(false);
    const [category, setCategory] = useState([]);
    const [adminApproved, setAdminApproved] = useState(false);
    const [ogImage, setOgImage] = useState(null);
    const [sessionIcon, setSessionIcon] = useState(null);
    const [sessionCoverPic, setSessionCoverPic] = useState(null);
    const [isFeatured, setIsFeatured] = useState(false);
    const [zoomlink, setZoomlink] = useState("");
    const [webinarId, setWebinarId] = useState("");
    const [webinarPasscode, setWebinarPasscode] = useState("");
    const [metatitle, setMetaTitle] = useState("");
    const [metadescription, setMetaDescription] = useState("");

    const [speakerId, setSpeakerId] = useState();
    const [categoryId, setCategoryId] = useState();
    const [priority, setPriority] = useState("");

    const [iserror,setIsError] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [issuccess,setIsSuccess] = useState(false);

    useEffect(()=>{
        const query = `
        query MyQuery {
            datahourCategories {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            speakers {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }`


        getQueryDataElevate(query, "newevents", "True")
        .then((data)=>{
            setCategory(data.data.datahourCategories);
            setSpeaker(data.data.speakers);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const resetFormFields = () => {
       setSessionName("");
       setSessionType("");
       setSpeaker("");
       setStartTime("");
       setEndTime("");
       setDescription("");
       setAdminApproved(false);
       setIsFeatured(false);
       setAutoRegister(false);
       setOgImage(null);
       setSessionCoverPic(null);
       setSessionIcon(null);
       setRegistrationDisabled(false);
       setZoomlink("");
       setWebinarId("");
       setWebinarPasscode("");
       setPriority("");
       setMetaDescription("");
       setMetaTitle("");
    };

    const handleSubmit = (e)=>{
        e.preventDefault();

        const startDate = new Date(startTime);

        const startOffset = startDate.getTimezoneOffset(); 
        startDate.setMinutes(startDate.getMinutes() - startOffset - 330); 

        const endDate = new Date(endTime);
        const endOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();

        const htmlDescription = trimNewlines(description);

        var formdata = new FormData();
        
        // eslint-disable-next-line
        formdata.append("operations", `{\"query\" : \"mutation MyMutation($sessionName: String!, $sessionType: String!, $speakerId: Int!, $startDateTime: DateTime!, $endDateTime: DateTime!, $description: String!, $categoryId: Int!, $ogImage: Upload, $sessionIcon: Upload, $sessionCoverPic: Upload, $zoomlink: String!, $webinarId: String, $webinarPasscode: String, $priorityIndex: Int,$metaTitle: String!,$metaDescription: String!) { createSession(sessionName: $sessionName,metaDescription: $metaDescription,metaTitle: $metaTitle, sessionType: $sessionType, speakerId: $speakerId, startDateTime: $startDateTime, endDateTime: $endDateTime, description: $description, registrationDisabled: ${registrationDisabled}, autoRegister: ${autoRegister}, categoryId: $categoryId, adminApproved: ${adminApproved}, ogImage: $ogImage, sessionIcon: $sessionIcon, sessionCoverPic: $sessionCoverPic, priorityIndex: $priorityIndex, isFeatured: ${isFeatured}, zoomlink: $zoomlink, webinarId: $webinarId, webinarPasscode: $webinarPasscode) { session { id sessionName }}}\",\n\"variables\" : {\"sessionName\": \"${sessionName}\", \"sessionType\": \"${sessionType}\",\"speakerId\": ${speakerId},\"startDateTime\": \"${formattedStartDate}\", \"endDateTime\": \"${formattedEndDate}\", \"categoryId\": ${categoryId},  \"description\": \"${htmlDescription}\", \"zoomlink\": \"${zoomlink}\", \"webinarId\": \"${webinarId}\", \"webinarPasscode\": \"${webinarPasscode}\", \"metaTitle\": \"${metatitle}\", \"metaDescription\": \"${metadescription}\", \"ogImage\":null, \"sessionCoverPic\":null, \"sessionIcon\":null, \"priorityIndex\": ${priority !== "" ? priority:1}}}`);
        formdata.append("map", JSON.stringify({"0": ["variables.ogImage"], "1":["variables.sessionIcon"], "2":["variables.sessionCoverPic"]}));
        formdata.append("0", ogImage);
        formdata.append("1", sessionIcon);
        formdata.append("2", sessionCoverPic);
        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
        .then(response => {
            if (!response.ok) {
                setIsError(true);
                setErrorMessage("Oops! Something went wrong on our end. 🛠️ Please report this issue to the tech team, and we'll fix it ASAP!");
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result) => {
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setErrorMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => console.log('error', error));
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Datahour created successfully"} />}
            <section id='createDatahour' className="min-vh-100 background-dark-primary common-style">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Datahour Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Session Name <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Session Name' 
                                            value={sessionName}
                                            onChange={(event)=>setSessionName(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="sessionType"
                                                className="form-select"
                                                value={sessionType}
                                                onChange={(event) => setSessionType(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Session Type</option>
                                                {["public", "private"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Speaker <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="speakerId"
                                                className="form-select"
                                                value={speakerId}
                                                onChange={(event) => setSpeakerId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Speaker</option>
                                                {speaker && speaker.edges && speaker.edges.map((val) => (
                                                    <option key={val.node.id} value={val.node.id}>
                                                        {val.node.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='Start Date' 
                                            value={startTime}
                                            onChange={(e)=>setStartTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="datetime-local" 
                                            className='form-control' 
                                            placeholder='End Date' 
                                            value={endTime}
                                            onChange={(e)=>setEndTime(e.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description (HTML Field) <sup className='text-danger'>*</sup></label>
                                            <textarea
                                                className='form-control'
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                                style={{ height: '100px' }}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">OG Image: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="ogImage"
                                                onChange={(e) => setOgImage(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Icon: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="sessionIcon"
                                                onChange={(e) => setSessionIcon(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Cover Pic: <sup className='text-danger'>*</sup></label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="sessionCoverPic"
                                                onChange={(e) => setSessionCoverPic(e.target.files[0])}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Registration Disabled:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={registrationDisabled}
                                                onChange={(event) => setRegistrationDisabled(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Autoregister:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={autoRegister}
                                                onChange={(event) => setAutoRegister(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Datahour Category <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="categoryId"
                                                className="form-select"
                                                value={categoryId}
                                                onChange={(event) => setCategoryId(event.target.value)}
                                                required
                                            >
                                                <option value="">Select Datahour Category</option>
                                                {category && category.edges && category.edges.map((val) => (
                                                    <option key={val.node.id} value={val.node.id}>
                                                        {val.node.id}-{val.node.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Admin Approved:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={adminApproved}
                                                onChange={(event) => setAdminApproved(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Featured:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isFeatured}
                                                onChange={(event) => setIsFeatured(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Priority</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Priority'
                                                value={priority}
                                                onChange={(event) => setPriority(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Zoom link <sup className='text-danger'>*</sup></label>
                                            <input
                                             type="text"
                                             className="form-control"
                                             placeholder="Zoom link"
                                             value={zoomlink}
                                             onChange={(event) => setZoomlink(event.target.value)}
                                             required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Webinar Id</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Webinar Id'
                                                value={webinarId}
                                                onChange={(event) => setWebinarId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Webinar Passcode</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Webinar Passcode'
                                                value={webinarPasscode}
                                                onChange={(event) => setWebinarPasscode(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Title</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Title'
                                                value={metatitle}
                                                onChange={(event) => setMetaTitle(event.target.value)}
                                            />
                                        </div>
                                        
                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Description</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Description'
                                                value={metadescription}
                                                onChange={(event) => setMetaDescription(event.target.value)}
                                            />
                                        </div>


                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Datahour
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateDatahour);
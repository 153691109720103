import 'intl-tel-input/build/css/intlTelInput.css';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import 'swiper/css';
import 'swiper/css/pagination';
import Avhero from '../../../../../assets/images/hero-image-bb.png';
import Cookies from '../../../../common/Footer/Cookies';
import Footer from '../../../../common/Footer/Footer';
import Flashstrip from '../../../../common/Header/Flashstrip';
import Title from '../../../../common/Header/Title';
import FractalHeader from '../components/FractalHeader';
import $ from 'jquery';
import ErrorAlert from '../../../../common/Alert/ErrorAlert';
import FractalModal from '../components/FractalModal';

const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
const identity_host = process.env.REACT_APP_IDENTITY_HOST;

function FractalHomeTesting({csrf}) {
  const [iserror,setIsError] = useState(false);
  const [isgen,setIsGen] = useState(false);
  const [isblackbelt,setIsBlackbelt] = useState(false);
  
  const submitEnrollment = (bundleid)=>{
    const url = identity_host+"/api/user-enrollment";
    $("li a").addClass("disabled");
    $('.roadmap-btn').addClass("d-none");
    const formData = new FormData();
    formData.append("bundle_id",bundleid);

    const requestOptions = {
        method: 'POST',
        headers: {
            "X-CSRFToken": csrf,
        },
        credentials:process.env.REACT_APP_CREDENTIAL_TYPE,
        body: formData
    };

    fetch(url, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // window.location.href = "https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/enrollments";
            $("button").removeAttr("disabled");
            $("a").removeClass("disabled");
            $('.roadmap-btn').removeClass("d-none");
        })
        .catch(error => {
            console.error('There was a problem with the fetch operation:', error);
            $("button").removeAttr("disabled");
            $('.roadmap-btn').remove();
            setIsError(true);
            setTimeout(() => {
              setIsError(false);
            }, 3000);
        });
  }
  return (
    <>
      <FractalHeader />
      {iserror && <ErrorAlert message={"You are not authorized to access these courses."} setIsError={setIsError} />}
      <Title title={'Best Data Science Course Online: Comprehensive AI & ML Certifications program'} description={'Embark on a certified Data Science journey with our online training program. Acquire sought-after skills in Python, AI, and ML. Enroll today and chart your path to becoming a certified Data Scientist.'} image_og={cloudflare_size_url+'blackbelt/bb_og_image.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <section id='firstFold' className='bg-size-cover bg-repeat-0 bg-position-center bg-dark position-relative pb-5 fractal-hero-fold' style={{ backgroundImage:`url('https://imgcdn.analyticsvidhya.com/blackbelt/hero-image-bb.png')` }}>
        <div className='container justify-content-center align-items-center h-100'>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-lg-9 mx-auto text-center position-realtive pt-5'>
              <h1 className="fs-56 text-gradient mb-5 fw-semibold">
                <span className="text-white text-decoration-none">Welcome to  
                </span> Analytics Vidhya 
                <span className='text-white ms-2'> Learning Portal</span>
              </h1>
            </div>
          </div>
        </div>
        <div className='container common-card-style pt-5 fractal-personalised' id='personalised'>
          <div className='text-white mx-auto text-center col-lg-9 heading-with-text mb-5'>
          <h2 className='fs-40 fw-normal mb-3 line-height-60'>Personalised Industry leading programs for you</h2>
          <p className='fs-18 fw-normal'>Embark on a learning journey with our comprehensive industry leading personalised programs</p>
          </div>
          <div className='row pt-3 g-4'>
              <div className='col-sm-12 col-lg-6 col-xl-6 col-md-12'>
                <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3 text-decoration-none'>
                      <div className='card-img position-relative overflow-hidden rounded-2'>
                      <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={cloudflare_size_url+'homepage/gen_ai2.jpg?format=webp&width=616'} alt="Card cap" loading="lazy" width={616} height={334} />
                      <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                          </svg>
                      </button>
                      </div>
                      <div className='card-body px-0'>
                          <h2 className="card-text text-white fs-20 mb-3 fw-light">Generative AI Pinnacle Program</h2>
                          <h3 className='text-white fs-20 fw-semibold mb-3 d-none'>₹59,999</h3>
                          <ul>
                            <li className='fs-16 mb-2'>200+ Hours of learning Generative AI</li>
                            <li className='fs-16 mb-2'>10+ hands-on projects</li>
                            <li className='fs-16'>26+ industry relevant libraries</li>
                          </ul>
                          <div class="collapse" id="collapseGenAi">
                            <h4 className='fs-20'>Courses</h4>
                          </div>
                      </div>
                      <div className='card-footer border-0 px-0 pt-4'>
                          <button className="btn btn-dark btn-dark-primary rounded-3 w-100 collapse-no-visible" onClick={(e)=>{if(!isgen){submitEnrollment("149658");$(e.target).attr("disabled","disabled");setIsGen(true);}}} data-bs-toggle="modal" data-bs-target="#genAiCoursesModal" >
                              Explore Courses
                          </button>
                      </div>
                  </div>
              </div>
              <div className='col-sm-12 col-lg-6 col-xl-6 col-md-12'>
              <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3 text-decoration-none'>
                    <div className='card-img position-relative overflow-hidden rounded-2'>
                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={cloudflare_size_url+'homepage/blackbelt2.jpg?format=webp&width=616'} alt="Card cap" loading="lazy" width={616} height={334} />
                    <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </button>
                    </div>
                  <div className='card-body px-0'>
                      <div className='card-body-top d-felx align-items-center'>
                      </div>
                      <h2 className="card-text text-white fs-20 mb-3 fw-light">Certified AI & ML BlackBelt Plus Program </h2>
                      <h3 className='text-white fs-20 fw-semibold mb-3 d-none'>₹69,999</h3>
                      <ul>
                        <li className='fs-16 mb-2'>500+ Hours of learning Data Science and Analytics</li>
                        <li className='fs-16 mb-2'>50+ industry relevant projects</li>
                        <li className='fs-16'>22+ industry relevant tools</li>
                      </ul>
                      <div class="collapse" id="collapseBlackBelt">
                            <h4 className='fs-20'>Courses</h4>
                            
                      </div>
                  </div>
                  <div className='card-footer border-0 px-0 pt-4'>
                      <button className="btn btn-dark btn-dark-primary rounded-3 w-100 collapse-no-visible" onClick={(e)=>{if(!isblackbelt){submitEnrollment("149719");$(e.target).attr("disabled","disabled");setIsBlackbelt(true)}}} data-bs-toggle="modal" data-bs-target="#blackbeltCoursesModal"  >
                          Explore Courses
                      </button>
                  </div>
                  </div>
              </div>
          </div>
        </div>
      </section>
      <Cookies platform={'fractal'} />
      <Flashstrip />
      <FractalModal />
    </>
  )
}


const mapStateToProps = (state) => ({
  csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(FractalHomeTesting);

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import GenAiPinnacleHome from '../GenAiPinnacle/pages';
import GenAiPinnacleOneField from '../GenAiPinnacle/pages/GenAiPinnacleOneField';
import GenAiLeadPagesOneField from '../GenAiPinnacle/pages/GenAiLeadPagesOneField';
import GenAiLeadPagesTwoField from '../GenAiPinnacle/pages/GenAiLeadPagesTwoField';
import GenAiPinncaleAVLearners from '../GenAiPinnacle/pages/GenAiPinncaleAVLearners';
import Error404 from '../../../common/Alert/Error404';
import GenAiFreeTrial from '../GenAiPinnacle/pages/GenAiFreeTrial';
import GenAiFreeTrialOneField from '../GenAiPinnacle/pages/GenAiFreeTrialOneField';
import GenAiFreeTrialTwoField from '../GenAiPinnacle/pages/GenAiFreeTrialTwoField';
import GenAiFreeTrialLearningOneField from '../GenAiPinnacle/pages/GenAiFreeTrialLearningOneField';
import GenAiFreeTrialLearningTwoField from '../GenAiPinnacle/pages/GenAiFreeTrialLearningTwoField';

export default function GenAiPinnacleRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<GenAiPinnacleHome />} />
        <Route path='/av-learners' exact element={<GenAiPinncaleAVLearners />} />
        <Route path='/2' exact element={<GenAiPinnacleOneField />} />
        <Route path='/dhs-free-trial' exact element={<GenAiFreeTrial />} />
        <Route path='/2/av-learners' exact element={<GenAiPinncaleAVLearners />} />
        <Route path='/:platformName' exact element={<GenAiLeadPagesTwoField />} />
        <Route path='/2/:platformName' exact element={<GenAiLeadPagesOneField />} />
        <Route path='*' element={<Error404 />} />
        <Route path='/free-trial' exact element={<GenAiFreeTrialTwoField />} />
        <Route path='/free-trial/2' exact element={<GenAiFreeTrialOneField />} />
        <Route path='/free-learning' exact element={<GenAiFreeTrialLearningTwoField />} />
        <Route path='/free-learning/2' exact element={<GenAiFreeTrialLearningOneField />} />
    </Routes>
  )
}

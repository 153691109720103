import React, { Suspense, useEffect, useState } from 'react'
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import Flashstrip from '../../../common/Header/Flashstrip';
import $ from 'jquery';
import { convertDateInFormat, convertDateToLocalDatetime, convertTimeToFormattedString, getQueryDataElevateUser, scrollTop } from '../../../../utils';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { clearLeadingWithDataDetailsSuccess, getCommentData, getLeadingWithDataDetailsData, updateWelcomeLoginModal } from '../../../../store/Actions/Action';
import Pagenumber from '../../../common/Page/Pagination';
import Comment from '../../../common/Comment/Comment';
import VideoModal from '../components/VideoModal';
import Cookies from '../../../common/Footer/Cookies';
import Loader from '../../../common/Loader';
const LeadingWithDataDetailsSwiperComponent1 = React.lazy(() => import("../components/LeadingWithDataDetailsSwiperComponent1"));
const LeadingWithDataDetailsSwiperComponent2 = React.lazy(() => import("../components/LeadingWithDataDetailsSwiperComponent2"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

const LeadingWithDataDetails = ({islogin,updateWelcomeLoginModalHandler,csrf,leadingWithDatadetails,leadingwithdataSpeakerSession,getLeadingWithDataDetailsDataHandler,clearLeadingWithDataDetailsSuccessHandler}) => {

    const { eventSlug } = useParams();

    const loginModal = () => {
      if(!islogin) {
        updateWelcomeLoginModalHandler({welcomeloginmodal:true})
        return;
      }
    }

    useEffect(()=>{
        if(csrf != ""){
          let tempdata = {
            eventSlug:eventSlug,
            csrf:csrf
          }
          getLeadingWithDataDetailsDataHandler(tempdata);
        }
    },[csrf,eventSlug]);

    useEffect(()=>{
      return(()=>{
        clearLeadingWithDataDetailsSuccessHandler();
      })
    },[])

    useEffect(()=>{
        scrollTop();
    },[eventSlug])

    useEffect(()=>{
        console.log("leadingWithDatadetails",leadingWithDatadetails);
        console.log("leadingwithdataSpeakerSession",leadingwithdataSpeakerSession)
      },[leadingWithDatadetails,leadingwithdataSpeakerSession])

    const playVideo = ()=>{
        // $('#modalDynamicIframe').trigger('play');
    }

  return (
    <>
      {!leadingWithDatadetails && <Loader />}
      <main className='background-dark-primary'>
          <Header />
          {leadingWithDatadetails && leadingWithDatadetails.videoData && leadingWithDatadetails.videoData.videoData && (leadingWithDatadetails.videoData.videoData.videoFileUrl || leadingWithDatadetails.videoData.videoData.videoUrl) && (leadingWithDatadetails.videoData.videoData.videoFileUrl ? <VideoModal videoUrl={leadingWithDatadetails.videoData.videoData.videoFileUrl}/> : <VideoModal videoUrl={leadingWithDatadetails.videoData.videoData.videoUrl}/>)}
          {leadingWithDatadetails && leadingWithDatadetails.videoData && leadingWithDatadetails.videoData.videoData && <section id="heroEventDetail" className='common-style background-dark-primary'>
              <div className="container">
              <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item text-dark-tertiary"><a href="/events/leading-with-data" className='text-dark-tertiary text-decoration-none'>Events</a></li>
                    <li class="breadcrumb-item active text-white" aria-current="page">Leading with Data</li>
                  </ol>
                </nav>
                  <div className="banner-image-wrapper">
                  <div className="overlay-wrapper position-relative">
                      <h1 className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center h-100">
                          <button className="btn play-btn rounded-circle d-flex align-items-center justify-content-center z-3" data-bs-toggle="modal" data-bs-target={(islogin) ? "#dynamicVideoModal" : ""} onClick={() => islogin ? playVideo() : loginModal()}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                              <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                              </svg>
                          </button>
                      </h1>
                      <img src={leadingWithDatadetails.videoData.videoData.bannerUrl} alt="Leading With Data" className='img-fluid rounded-4' width="1320"/>
                  </div>
                  </div>
              </div>
          </section>}

          {leadingWithDatadetails && <section className='background-dark-primary pt-5' id='detailSection'>
            <div className='container'>
              <div className='row'>
                <div className='col-xl-8 col-lg-8 col-md-12 col-sm-12'>
                  <div className='card border-0 p-3 background-dark-secondary mb-4'>
                    <div className="row align-items-center">
                      <div className="col">
                        <p className='fs-16 text-dark-tertiary d-flex align-items-center flex-wrap my-auto'>
                          {leadingWithDatadetails?.videoData?.videoData?.uploadedAt && convertDateInFormat(convertDateToLocalDatetime(leadingWithDatadetails?.videoData?.videoData?.uploadedAt),"DD MMMM YYYY")} • {leadingWithDatadetails?.videoData?.videoData?.duration && convertTimeToFormattedString(leadingWithDatadetails?.videoData?.videoData?.duration)}
                        </p>
                      </div>
                      <div className="col-auto">
                        <div className="bookmark-icon background-dark-secondary p-2 border border-dark border-2 rounded-1 d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M4 4V21.191C4 21.5627 4.39116 21.8044 4.72361 21.6382L11.1056 18.4472C11.6686 18.1657 12.3314 18.1657 12.8944 18.4472L19.2764 21.6382C19.6088 21.8044 20 21.5627 20 21.191V4C20 2.89543 19.1046 2 18 2H6C4.89543 2 4 2.89543 4 4Z" stroke="#F9F9F9" stroke-width="2" stroke-linecap="round"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="nav nav-tabs px-2 py-3 rounded-2 av-tab-design" id="datahourDetailTab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="true">About</button>
                    </li>
                    <li class="nav-item d-none" role="presentation">
                      <button class="nav-link" id="transcript-tab" data-bs-toggle="tab" data-bs-target="#transcript" type="button" role="tab" aria-controls="transcript" aria-selected="true">Transcript</button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="discuss-tab" data-bs-toggle="tab" data-bs-target="#discuss" type="button" role="tab" aria-controls="discuss" aria-selected="false">Discuss</button>
                    </li>
                  </ul>
                  <div class="tab-content py-4" id="datahourDetailContent">
                    <div class="tab-pane fade show active" id="about" role="tabpanel" aria-labelledby="about-tab">
                      <h2 className='text-white fs-32 fw-normal mb-3'>About the Session</h2>
                      <p className='text-dark-secondary html-field fs-18 fw-normal mb-4' dangerouslySetInnerHTML={{ __html:leadingWithDatadetails && leadingWithDatadetails?.videoData?.videoData?.description}}></p>
                      <h2 className='text-white fs-32 fw-normal mt-5 mb-4'>About the Speaker</h2>
                      <div className="row mb-4">
                        <div className="col-auto">
                          <div className="avtar-wrapper rounded-circle overflow-hidden">
                            <img className='img-fluid rounded-circle' src={leadingWithDatadetails?.videoData?.attendeeData?.photoUrl} alt="avtar" width={80} height={80}/>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className='d-flex align-items-center'>
                            <h3 className='text-white fs-32 fw-semibold text-decoration-underline pe-3'>{leadingWithDatadetails?.videoData?.attendeeData?.name}</h3>
                            <a href={leadingWithDatadetails?.videoData?.attendeeData?.linkedin} id={leadingWithDatadetails?.videoData?.attendeeData?.name} target='_blank'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <g clip-path="url(#clip0_14267_389177)">
                                  <rect x="3.20312" y="1.6001" width="25.6" height="27.2" fill="white"/>
                                  <path d="M27.2655 27.266H22.5241V19.8406C22.5241 18.07 22.4925 15.7906 20.0581 15.7906C17.5886 15.7906 17.2108 17.7198 17.2108 19.7117V27.2655H12.4695V11.996H17.0211V14.0827H17.0849C17.5404 13.3038 18.1986 12.6631 18.9895 12.2287C19.7803 11.7944 20.6742 11.5826 21.5759 11.6161C26.3815 11.6161 27.2675 14.7771 27.2675 18.8893L27.2655 27.266ZM7.1195 9.90883C5.59988 9.90908 4.36775 8.67733 4.3675 7.1577C4.36725 5.63808 5.59888 4.40595 7.1185 4.4057C8.63812 4.40533 9.87025 5.63708 9.8705 7.1567C9.87063 7.88646 9.58087 8.58638 9.06497 9.1025C8.54906 9.61862 7.84925 9.90866 7.1195 9.90883ZM9.49025 27.2661H4.74388V11.996H9.49012V27.266L9.49025 27.2661ZM29.6293 0.00232899H2.36137C1.07262 -0.012171 0.01575 1.0202 0 2.30895V29.6906C0.01525 30.98 1.072 32.0133 2.36125 31.9998H29.6293C30.9213 32.0158 31.9821 30.9825 32 29.6906V2.30683C31.9816 1.01558 30.9206 -0.016671 29.6293 0.000203987" fill="#0A66C2"/>
                                </g>
                                <defs>
                                  <clipPath id="clip0_14267_389177">
                                    <rect width="32" height="32" fill="white"/>
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                          </div>
                          <p className='text-dark-tertiary fs-16 fw-normal'>{leadingWithDatadetails?.videoData?.attendeeData?.designation} at {leadingWithDatadetails?.videoData?.attendeeData?.company}</p>
                        </div>
                      </div>
                      <p className='text-dark-secondary html-field fs-18 fw-normal mb-4' dangerouslySetInnerHTML={{ __html:leadingWithDatadetails && leadingWithDatadetails?.videoData?.attendeeData?.description}}></p>
                    </div>
                    <div class="tab-pane fade d-none" id="transcript" role="tabpanel" aria-labelledby="transcript-tab">
                      <h2 className='text-white fs-24 fw-normal mb-4'>Keynotes/transcript</h2>
                      <div className='single-note-wrapper mb-2'>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M4 17.6456V11.1646L7.39241 6H10.7848L8.60759 11.1646H11.0886V17.6456H4ZM12.9114 17.6456V11.1646L16.3038 6H19.6962L17.519 11.1646H20V17.6456H12.9114Z" fill="white"/>
                        </svg>
                        </div>
                        <p className='text-dark-secondary fs-18 fw-normal mb-1'>
                        
                        </p>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-4 ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M20 6.35443V12.8354L16.6076 18H13.2152L15.3924 12.8354H12.9114V6.35443H20ZM11.0886 6.35443V12.8354L7.6962 18H4.3038L6.48101 12.8354H4V6.35443H11.0886Z" fill="white"/>
                          </svg>
                        </div>
                        <p className='fs-20 fw-normal text-dark-tertiary'>Arnav Garg <span className='text-white'>(15:32 min)</span></p>
                      </div>
                      <div className='single-note-wrapper mb-2'>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M4 17.6456V11.1646L7.39241 6H10.7848L8.60759 11.1646H11.0886V17.6456H4ZM12.9114 17.6456V11.1646L16.3038 6H19.6962L17.519 11.1646H20V17.6456H12.9114Z" fill="white"/>
                        </svg>
                        </div>
                        <p className='text-dark-secondary fs-18 fw-normal mb-1'>
                        
                        </p>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-4 ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M20 6.35443V12.8354L16.6076 18H13.2152L15.3924 12.8354H12.9114V6.35443H20ZM11.0886 6.35443V12.8354L7.6962 18H4.3038L6.48101 12.8354H4V6.35443H11.0886Z" fill="white"/>
                          </svg>
                        </div>
                        <p className='fs-20 fw-normal text-dark-tertiary'>Arnav Garg <span className='text-white'>(15:32 min)</span></p>
                      </div>
                      <div className='single-note-wrapper mb-2'>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M4 17.6456V11.1646L7.39241 6H10.7848L8.60759 11.1646H11.0886V17.6456H4ZM12.9114 17.6456V11.1646L16.3038 6H19.6962L17.519 11.1646H20V17.6456H12.9114Z" fill="white"/>
                        </svg>
                        </div>
                        <p className='text-dark-secondary fs-18 fw-normal mb-1'>
                        
                        </p>
                        <div class="icon-wrapper d-flex align-items-center justify-content-center background-dark-secondary rounded-circle mb-4 ms-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M20 6.35443V12.8354L16.6076 18H13.2152L15.3924 12.8354H12.9114V6.35443H20ZM11.0886 6.35443V12.8354L7.6962 18H4.3038L6.48101 12.8354H4V6.35443H11.0886Z" fill="white"/>
                          </svg>
                        </div>
                        <p className='fs-20 fw-normal text-dark-tertiary'>Arnav Garg <span className='text-white'>(15:32 min)</span></p>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="discuss" role="tabpanel" aria-labelledby="discuss-tab">
                      <h2 className='text-white fs-24 fw-normal mb-3'>Participate in discussion</h2>
                      {leadingWithDatadetails && leadingWithDatadetails.videoData && leadingWithDatadetails.videoData.videoData && <Comment uniqueId={leadingWithDatadetails.videoData.videoData.id} resourceName="leading_with_data" />}
                    </div>
                  </div>
                </div>
                {leadingWithDatadetails.videoData && leadingWithDatadetails.videoData.nextVideos && leadingWithDatadetails.videoData.nextVideos.length>0 && <div className='col-xl-4 col-lg-4 col-md-12 col-sm-12'>
                  <div className='card border-0 p-3 background-dark-primary sticky-top'>
                    <h2 className="card-text text-white fs-24 mb-4">Watch Next</h2>
                    <div className='row' id='leadingWithData'>
                      {leadingWithDatadetails.videoData.nextVideos.map((video,index)=>(
                          <div className="col-lg-12 col-md-6 col-sm-12 mb-3">
                          <a href={`/events/leading-with-data/${video.slug}`} id={`watch-next-`+video.slug+`CardLink`} className='text-decoration-none'>
                          <div className="card bg-transparent border-0">
                              <div className="card-body p-0">
                                  <div className="overlay-wrapper position-relative img-ratio">
                                      <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                          <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                              <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                              </svg>
                                          </button>
                                      </div>
                                      <img className='img-fluid w-100 rounded-3' src={video.thumbnailUrl} alt="leading-with-data" />
                                  </div>
                                  <h3 className='text-white mt-2 fs-16 fw-medium'>{video.title}</h3>
                                  <p className='text-dark-tertiary fs-14 fw-light'>
                                  {convertDateInFormat(convertDateToLocalDatetime(video.uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(video.duration)}</p>
                              </div>
                          </div>
                          </a>
                          </div>                   
                      ))}
                  </div>
                  </div>
                </div>}
              </div>
            </div>
          </section>}

          <Suspense fallback={<div>Loading</div>}>
            <LeadingWithDataDetailsSwiperComponent1 eventSlug={eventSlug} />
          </Suspense>
          <section className='common-style-py background-dark-primary clearfix hover-state d-none' id='moneyGuarantee'>
              <div className='container'>
              <div className='row background-dark-secondary p-5 rounded-4 px-2 px-md-5'>
                  <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
                      <div className='text-white heading-with-text mb-5'>
                          <h2 className='fs-40 fw-normal mb-3 fs-18'>Become a Speaker
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                  <path d="M6.67188 15.9995H25.3385" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                                  <path d="M16 6.66602L25.3333 15.9993L16 25.3327" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg>
                          </h2>
                          <p className='text-dark-tertiary'> Share your vision, inspire change, and leave a mark on the industry. We're calling for innovators and thought leaders to speak at our event</p>
                      </div>
                      <div className='d-flex flex-wrap'>
                          <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Professional Exposure</span>
                              </li>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Networking Opportunities</span>
                              </li>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Thought Leadership</span>
                              </li>
                          </ul>
                          <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Knowledge Exchange</span>
                              </li>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Leading-Edge Insights</span>
                              </li>
                              <li className='mb-4 d-flex'>
                                  <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                      <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                  </svg>
                                  <span className='overflow-hidden fs-18 ms-1'>Community Contribution</span>
                              </li>
                          </ul>
                      </div>
                  </div>
                  <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12 my-auto'>
                      <img className='img-fluid rounded-2 d-table mx-auto' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/become_a_speaker_listing.png?format=webp&width=648"} alt="" />
                  </div>
              </div>
              </div>
          </section>
          <Suspense fallback={<div>Loading</div>}>
            <LeadingWithDataDetailsSwiperComponent2 />
          </Suspense>
          <Footer platform={"newevent_leading_with_data_detail"}/>
          <Cookies platform={'newevents'}  />
          <Flashstrip />
      </main>
    </>
  )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
    leadingWithDatadetails:state.leadingWithDataContentReducer.leadingWithDatadetails,
    leadingwithdataSpeakerSession:state.leadingWithDataContentReducer.leadingwithdataSpeakerSession
  });
  
  const mapDispatchToProps = dispatch=>({
    getLeadingWithDataDetailsDataHandler:data=>dispatch(getLeadingWithDataDetailsData(data)),
    updateWelcomeLoginModalHandler:data=>dispatch(updateWelcomeLoginModal(data)),
    clearLeadingWithDataDetailsSuccessHandler:data=>dispatch(clearLeadingWithDataDetailsSuccess(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(LeadingWithDataDetails);

import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryDataElevate } from '../../../../utils';
import Pagenumber from '../../../common/Page/Pagination';

function TournamentStageMapping({islogin,csrf}) {
    const [tournamentStageMapping,setTournamentStageMapping] = useState(null);
    const [totalPages,setTotalPages] = useState(null);

    useEffect(()=>{
        if(islogin){
            let query = `query MyQuery {
                tournamentStageMappings(limit: 10, page: 1) {
                  totalPages
                  tournamentStageMapping {
                    stage {
                      id
                      title
                      slug
                    }
                    tournament {
                      id
                      title
                      slug
                    }
                    id
                  }
                }
              }`;

            getQueryDataElevate(query,"newcompetition","True")
            .then((data)=>{
                console.log(data.data.tournamentStageMappings.tournamentStageMapping);
                setTournamentStageMapping(data.data.tournamentStageMappings.tournamentStageMapping);
                setTotalPages(data.data.tournamentStageMappings.totalPages);
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin])

    const updateTournamentStageMappingByPageNumber = (pagenumber) => {
        let query = `query MyQuery {
            tournamentStageMappings(limit: 10, page: ${pagenumber}) {
              totalPages
              tournamentStageMapping {
                stage {
                  id
                  title
                  slug
                }
                tournament {
                  id
                  title
                  slug
                }
                id
              }
            }
          }`;

        getQueryDataElevate(query,"newcompetition","True")
        .then((data)=>{
            console.log(data.data.tournamentStageMappings.tournamentStageMapping);
            setTournamentStageMapping(data.data.tournamentStageMappings.tournamentStageMapping);
            setTotalPages(data.data.tournamentStageMappings.totalPages);
        })
        .catch((error)=>{
            console.log(error);
        })
    }
  return (
    <>
        <Header />
        <section id="tournamentStageMapping" className='background-dark-primary min-vh-100 common-style'>
            <div className="container">
            <a href={'/av-admin/datahack-admin/tournament-stage-mapping/create-tournament-stage-mapping'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                    <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                    New Tournament Stage Mapping
                </a>
                <div className="row">
                    <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Tournament</th>
                                        <th>Stage</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tournamentStageMapping && tournamentStageMapping.map((obj,index)=>{
                                        return(
                                            <tr key={index}>
                                                <td><a className='text-white' href={'/av-admin/datahack-admin/tournament-stage-mapping/'+obj.stage.slug}>{obj.id}</a></td>
                                                <td><a className='text-white' href={`/av-admin/datahack-admin/tournament/${obj.tournament.slug}`}>{obj.tournament.title}</a></td>
                                                <td><a className='text-white' href={`/av-admin/datahack-admin/stage/${obj.stage.slug}`}>{obj.stage.title}</a></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                    </div>
                    {totalPages && <Pagenumber last_page={totalPages} updatePageNumber={updateTournamentStageMappingByPageNumber} />}
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(TournamentStageMapping);

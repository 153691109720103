import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryDataElevate } from '../../../../utils';

function EmailTemplate({ islogin, csrf }) {
    const [emailTemplates, setEmailTemplates] = useState(null);

    useEffect(() => {
        if (islogin) {
            let query = `query MyQuery {
                allEmailTemplates {
                    body
                    id
                    isContactTemplate
                    name
                    subject
                    managedBy
                }
            }`

            getQueryDataElevate(query, "newnotifications", "True")
            .then((data) => {
                setEmailTemplates(data?.data?.allEmailTemplates);
            })
            .catch((error) => {
                console.log(error);
            })
        }
    }, [islogin]);

    return (
        <>
            <Header />
            <section id="emailTemplates" className='background-dark-primary min-vh-100 common-style px-5'>
                <div className="container-fluid px-5">
                    <a href={'/av-admin/email-template/create-email-template'} className='btn btn-dark btn-dark-primary mb-3 ms-auto d-table'>
                        <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg>
                        New Email Template
                    </a>
                    <div className="row">
                        <div class="table-responsive">
                            <table class="table table-striped text-center table-dark">
                                <thead>
                                    <tr>
                                        <th>Template Id</th>
                                        <th>Name</th>
                                        <th>Managed By</th>
                                        <th>Subject</th>
                                        <th>Body</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {emailTemplates && emailTemplates.map((val) => (
                                        <tr>
                                            <td><a href={'/av-admin/email-template/'+val.id} className='text-white'>{val.id}</a></td>
                                            <td><a href={'/av-admin/email-template/'+val.id} className='text-white'>{val.name}</a></td>
                                            <td>{val.managedBy}</td>
                                            <td>{val.subject}</td>
                                            <td className="text-truncate" style={{ maxWidth: '300px' }}>{val.body}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
    islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);

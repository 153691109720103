import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const courseslist = eval(process.env.REACT_APP_FREE_COURSES_LIST);
export default function Course() {
  const navigate = useNavigate();
    useEffect(()=>{
      const randomIndex = Math.floor(Math.random() * courseslist.length);
      const randomLink = courseslist[randomIndex];
      navigate(randomLink);
    },[])
  return (
    <div>Loading</div>
  )
}

import { FETCH_BLOG_CONTENT_REQUEST, FETCH_BLOG_CONTENT_SUCCESS, FETCH_BLOG_CONTENT_FAILURE } from '../Contants/index';
  
const initialState = {
    blogcontent: null,
    loading: false,
    error: null,
};
  
export const blogContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BLOG_CONTENT_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_BLOG_CONTENT_SUCCESS:
            return {
                ...state,
                blogcontent: action.payload,
                loading: false,
                error: null,
            };
        case FETCH_BLOG_CONTENT_FAILURE:
        return {
            ...state,
            blogcontent: null,
            loading: false,
            error: action.payload,
        };
        default:
            return state;
    }
};
  
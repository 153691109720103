import React, {useEffect, useState} from 'react'
import { submitPlatformFormData } from '../../../../utils';
import { connect } from 'react-redux';
import {fetchBlackbeltContent} from '../../../../store/Actions/Action';
import {getCSRF} from '../../../../utils/Login';

function BlackbeltForm({platform,fetchBlackbeltContent,blackbeltcontent}) {
  const [formData, setFormData] = useState(null);
  const [formData1, setFormData1] = useState(null);
  const [formData2, setFormData2] = useState(null);
  const [originalData,setOriginalData] = useState(null);
  const [csrfToken, setCsrfToken] = useState('');

    
  useEffect(()=>{
    fetchBlackbeltContent();
  },[])

  useEffect(()=>{
    if(blackbeltcontent){
      setFormData(blackbeltcontent.latestOffers);
      setFormData1(blackbeltcontent.latestOffersUsAndCanada);
      setFormData2(blackbeltcontent.latestOffersRestOfWorld);
      setOriginalData(blackbeltcontent);
    }
  },[blackbeltcontent]);

  useEffect(()=>{
    getCSRF()
    .then((data)=>{
      setCsrfToken(data);
    })
    .catch((error)=>{
      console.log(error);
    })
  },[])

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {...originalData,latestOffers:formData,latestOffersUsAndCanada:formData1,latestOffersRestOfWorld:formData2};
    submitPlatformFormData(platform,data,csrfToken)
    .then((data)=>{
      alert('Data updated successfully');
    })
    .catch((error)=>{
      console.log(error);
      alert(error);
    });
  };

  const updateElementAtIndex = (index, newValue) => {
    setFormData((prevState) => {
      const updatedList = [...prevState.points];
      updatedList[index] = newValue;
      return {
        ...prevState,
        points: updatedList,
      };
    });
  };

  const updateInnerValue = (innerKey, newValue) => {
    setFormData((prevState) => {
      const updatedInnerDict = {
        ...prevState.cta,
        [innerKey]: newValue,
      };
      return {
        ...prevState,
        cta: updatedInnerDict,
      };
    });
  };

  const updateElementAtIndex1 = (index, newValue) => {
    setFormData1((prevState) => {
      const updatedList = [...prevState.points];
      updatedList[index] = newValue;
      return {
        ...prevState,
        points: updatedList,
      };
    });
  };

  const updateInnerValue1 = (innerKey, newValue) => {
    setFormData1((prevState) => {
      const updatedInnerDict = {
        ...prevState.cta,
        [innerKey]: newValue,
      };
      return {
        ...prevState,
        cta: updatedInnerDict,
      };
    });
  };

  const updateElementAtIndex2 = (index, newValue) => {
    setFormData2((prevState) => {
      const updatedList = [...prevState.points];
      updatedList[index] = newValue;
      return {
        ...prevState,
        points: updatedList,
      };
    });
  };

  const updateInnerValue2 = (innerKey, newValue) => {
    setFormData2((prevState) => {
      const updatedInnerDict = {
        ...prevState.cta,
        [innerKey]: newValue,
      };
      return {
        ...prevState,
        cta: updatedInnerDict,
      };
    });
  };


  return (
    <div>
      <h3 className='text-center mb-3 text-white'>Blackbelt</h3>
      {formData &&
        <form onSubmit={handleSubmit} className='json-update-form overflow-auto'>
          <div className="av-tab-design background-dark-secondary border-start-0 border-end-0">
            <ul class="nav nav-tabs py-4 border-0 flex-nowrap me-5" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="india-tab" data-bs-toggle="tab" data-bs-target="#india" type="button" role="tab" aria-controls="india" aria-selected="true">INDIA</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="us-tab" data-bs-toggle="tab" data-bs-target="#us" type="button" role="tab" aria-controls="us" aria-selected="false">US/CANADA</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="row-tab" data-bs-toggle="tab" data-bs-target="#row" type="button" role="tab" aria-controls="row" aria-selected="false">REST OF WORLD</button>
              </li>
            </ul>
          </div>
          <div class="tab-content pt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="india" role="tabpanel" aria-labelledby="india-tab">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" checked={formData.active} id="flexCheckDefault" onChange={(e)=>{setFormData((prev)=>{return({...prev,active:e.target.checked})})}} />
                <label class="form-check-label text-white" for="flexCheckDefault">
                  Offer Active
                </label>
              </div>
              <h5 htmlFor="subtitle" className="form-label text-white d-block">CTA:</h5>
              <div className="mb-3 border rounded-1 py-2 px-3">
                <label htmlFor="subtitle" className="form-label text-white">Link:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white mb-3"
                  value={formData.cta.link}
                  onChange={(e)=>{updateInnerValue('link',e.target.value)}}
                />
                <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData.cta.text}
                  onChange={(e)=>{updateInnerValue('text',e.target.value)}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label text-white">Discount End Date:</label>
                <input className='form-control background-dark-tertiary border-0 text-white' type="datetime-local" id="birthdaytime" name="birthdaytime" value={formData.discountEndDate.replace('+05:30',"")} 
                  onChange={(e)=>{setFormData((prev)=>{return({...prev,discountEndDate:e.target.value.replace('T'," ")+":00+05:30"})})}}></input>
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Discount Percentage:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData.discountPercentage}
                  onChange={(e)=>{setFormData((prev)=>{return({...prev,discountPercentage:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Heading:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData.heading}
                  onChange={(e)=>{setFormData((prev)=>{return({...prev,heading:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Points:</label>
                {formData.points.map((point,index)=>{
                  return(
                    <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white mb-3"
                    value={point}
                    onChange={(e)=>{updateElementAtIndex(index,e.target.value)}}
                  />
                  )
                })}
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData.text}
                  onChange={(e)=>{setFormData((prev)=>{return({...prev,text:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">First Fold Offer Strip Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData.first_fold_text}
                  onChange={(e)=>{setFormData((prev)=>{return({...prev,first_fold_text:e.target.value})})}}
                />
              </div>
            </div>
            <div class="tab-pane fade" id="us" role="tabpanel" aria-labelledby="us-tab">
                <div class="form-check mb-3">
                  <input class="form-check-input" type="checkbox" checked={formData1.active} id="flexCheckDefault1" onChange={(e)=>{setFormData1((prev)=>{return({...prev,active:e.target.checked})})}} />
                  <label class="form-check-label text-white" for="flexCheckDefault1">
                    Offer Active
                  </label>
                </div>
                <h5 htmlFor="subtitle" className="form-label text-white d-block">CTA:</h5>
                <div className="mb-3 border rounded-1 py-2 px-3">
                  <label htmlFor="subtitle" className="form-label text-white">Link:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white mb-3"
                    value={formData1.cta.link}
                    onChange={(e)=>{updateInnerValue1('link',e.target.value)}}
                  />
                  <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white"
                    value={formData1.cta.text}
                    onChange={(e)=>{updateInnerValue1('text',e.target.value)}}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="title" className="form-label text-white">Discount End Date:</label>
                  <input className='form-control background-dark-tertiary border-0 text-white' type="datetime-local" id="birthdaytime" name="birthdaytime" value={formData1.discountEndDate.replace('+05:30',"")} 
                    onChange={(e)=>{setFormData1((prev)=>{return({...prev,discountEndDate:e.target.value.replace('T'," ")+":00+05:30"})})}}></input>
                </div>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label text-white">Discount Percentage:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white"
                    value={formData1.discountPercentage}
                    onChange={(e)=>{setFormData1((prev)=>{return({...prev,discountPercentage:e.target.value})})}}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label text-white">Heading:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white"
                    value={formData1.heading}
                    onChange={(e)=>{setFormData1((prev)=>{return({...prev,heading:e.target.value})})}}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label text-white">Points:</label>
                  {formData1.points.map((point,index)=>{
                    return(
                      <input
                      type="text"
                      id="subtitle"
                      name="subtitle"
                      className="form-control background-dark-tertiary border-0 text-white mb-3"
                      value={point}
                      onChange={(e)=>{updateElementAtIndex1(index,e.target.value)}}
                    />
                    )
                  })}
                </div>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white"
                    value={formData1.text}
                    onChange={(e)=>{setFormData1((prev)=>{return({...prev,text:e.target.value})})}}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label text-white">First Fold Offer Strip Text:</label>
                  <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white"
                    value={formData1.first_fold_text}
                    onChange={(e)=>{setFormData1((prev)=>{return({...prev,first_fold_text:e.target.value})})}}
                  />
                </div>
            </div>
            <div class="tab-pane fade" id="row" role="tabpanel" aria-labelledby="row-tab">
              <div class="form-check mb-3">
                <input class="form-check-input" type="checkbox" checked={formData2.active} id="flexCheckDefault2" onChange={(e)=>{setFormData2((prev)=>{return({...prev,active:e.target.checked})})}} />
                <label class="form-check-label text-white" for="flexCheckDefault2">
                  Offer Active
                </label>
              </div>
              <h5 htmlFor="subtitle" className="form-label text-white d-block">CTA:</h5>
              <div className="mb-3 border rounded-1 py-2 px-3">
                <label htmlFor="subtitle" className="form-label text-white">Link:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white mb-3"
                  value={formData2.cta.link}
                  onChange={(e)=>{updateInnerValue2('link',e.target.value)}}
                />
                <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData2.cta.text}
                  onChange={(e)=>{updateInnerValue2('text',e.target.value)}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="title" className="form-label text-white">Discount End Date:</label>
                <input className='form-control background-dark-tertiary border-0 text-white' type="datetime-local" id="birthdaytime" name="birthdaytime" value={formData2.discountEndDate.replace('+05:30',"")} 
                  onChange={(e)=>{setFormData2((prev)=>{return({...prev,discountEndDate:e.target.value.replace('T'," ")+":00+05:30"})})}}></input>
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Discount Percentage:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData2.discountPercentage}
                  onChange={(e)=>{setFormData2((prev)=>{return({...prev,discountPercentage:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Heading:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData2.heading}
                  onChange={(e)=>{setFormData2((prev)=>{return({...prev,heading:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Points:</label>
                {formData2.points.map((point,index)=>{
                  return(
                    <input
                    type="text"
                    id="subtitle"
                    name="subtitle"
                    className="form-control background-dark-tertiary border-0 text-white mb-3"
                    value={point}
                    onChange={(e)=>{updateElementAtIndex2(index,e.target.value)}}
                  />
                  )
                })}
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData2.text}
                  onChange={(e)=>{setFormData2((prev)=>{return({...prev,text:e.target.value})})}}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label text-white">First Fold Offer Strip Text:</label>
                <input
                  type="text"
                  id="subtitle"
                  name="subtitle"
                  className="form-control background-dark-tertiary border-0 text-white"
                  value={formData2.first_fold_text}
                  onChange={(e)=>{setFormData2((prev)=>{return({...prev,first_fold_text:e.target.value})})}}
                />
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-dark btn-dark-primary px-5 py-1 mx-auto mt-4 d-block">Submit</button>
        </form>
      }
    </div>
  )
}



const mapStateToProps = (state) => ({
  blackbeltcontent:state.blackbeltContentReducer.blackbeltcontent
});

const mapDispatchToProps = {
  fetchBlackbeltContent,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlackbeltForm);
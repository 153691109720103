import React, { Suspense, useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import { getActiveTournamentByPageNumberData, getClosedTournamentByPageNumberData, getCompetitionListData, getLateSubmissionTournamentByPageNumberData } from '../../../../store/Actions/Action';
import { connect } from 'react-redux';
import { calculateTimeRemainingInMilliSeconds, convertDateInFormat, convertDateToLocalDatetime, scrollTop } from '../../../../utils';
import Pagination from '../../../common/Page/Pagination';
import AvLogo from '../../../../assets/images/AV_logo.png';
import Loader from '../../../common/Loader';
import Cookies from '../../../common/Footer/Cookies';
import Flashstrip from '../../../common/Header/Flashstrip';

const HackathonListingSwiperComponent = React.lazy(() => import("../components/HackathonListingSwiperComponent"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;
const datahack_s3_url=process.env.REACT_APP_DATAHACK_S3_URL;

function HackathonListing({featuredSessionBanner,HackathonList,getCompetitionListDataHandler,getClosedTournamentByPageNumberDataHandler,getLateSubmissionTournamentByPageNumberDataHandler,closedHackathonList,lateSubmissionHackthonList,getActiveTournamentByPageNumberDataHandler,activeHackathonList}) {
    const [showLoader,setShowLoader] = useState(false);
    const [filter,setFilter] = useState("all");
    const [activePageNumber,setActivePageNumber] = useState(1);
    const [latePageNumber,setLatePageNumber] = useState(1);
    const [closedPageNumber,setClosedPageNumber] = useState(1);

    useEffect(()=>{
        scrollTop();
        let data = {
            filter:`${filter}`,
            page_number1:activePageNumber,
            page_number1:closedPageNumber,
            page_number1:latePageNumber,
        }
        getCompetitionListDataHandler(data);
    },[filter])


    let updateClosedHackthonPageNumber = (page_number)=>{
        setShowLoader(true);
        let tempdata ={
            tournament:"hackathon",
            page_number:page_number,
            limit:6,
            filter: filter ? filter : "all",
            setShowLoader
        }
        getClosedTournamentByPageNumberDataHandler(tempdata);
    }

    let updateLateSubmissionHackthonPageNumber = (page_number)=>{
        setShowLoader(true);
        let tempdata ={
            tournament:"hackathon",
            page_number:page_number,
            filter: filter ? filter : "all",
            limit:6,
            setShowLoader
        }
        getLateSubmissionTournamentByPageNumberDataHandler(tempdata);
    }

    let updateActiveSubmissionHackthonPageNumber = (page_number)=>{
        setShowLoader(true);
        let tempdata ={
            tournament:"hackathon",
            page_number:page_number,
            filter: filter ? filter : "all",
            limit:6,
            setShowLoader
        }
        getActiveTournamentByPageNumberDataHandler(tempdata);
    }

    return (
        <>
            {showLoader && <Loader />}
            <Header />
            <section id="heroEventListing" className='common-style pb-5 background-dark-primary'>
                <div className="container">
                    <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item text-dark-tertiary"><a href="/datahack" className='text-dark-tertiary text-decoration-none'>Competitions</a></li>
                            <li class="breadcrumb-item active text-white" aria-current="page">Hackathon</li>
                        </ol>
                    </nav>
                    {HackathonList && HackathonList.activeHackathonTournament && HackathonList.activeHackathonTournament.tournamentData[0] &&  <a href={`/datahack/contest/${HackathonList && HackathonList.activeHackathonTournament &&  HackathonList.activeHackathonTournament.tournamentData[0].slug}`} className="banner-image-wrapper text-decoration-none">
                        <img className='img-fluid rounded-2' src={HackathonList && HackathonList.activeHackathonTournament && HackathonList.activeHackathonTournament.tournamentData[0].bannerImage} width="1320" alt="Featured Session Banner" />
                    </a>}
                </div>
            </section>
            <section className='av-tab-design background-dark-secondary border-start-0 border-end-0' id='tabs'>
                <div className='container'>
                    <div className='row align-items-center flex-nowrap overflow-auto'>
                        <ul class="col nav nav-tabs py-4 border-0 flex-nowrap me-5" id="datahourListTabs" role="tablist">
                            {HackathonList && HackathonList.upcomingHackathonTournament && HackathonList.upcomingHackathonTournament.tournamentData.length > 0 && <li class="nav-item" role="presentation">
                                <button class="nav-link active text-nowrap" id="upcoming-events-tab" data-bs-toggle="tab" data-bs-target="#upcoming-events" type="button" role="tab" aria-controls="upcoming-events" aria-selected="true">Upcoming ({HackathonList.upcomingHackathonTournament.totalTournamentCount || 0})</button>
                            </li>}
                            {activeHackathonList && activeHackathonList.tournamentData.length > 0 && <li class="nav-item" role="presentation">
                                <button class={`nav-link text-nowrap ${HackathonList && HackathonList.upcomingHackathonTournament && HackathonList.upcomingHackathonTournament.tournamentData.length <= 0 && "active" }`} id="active-events-tab" data-bs-toggle="tab" data-bs-target="#active-events" type="button" role="tab" aria-controls="active-events" aria-selected="false">Active ({(activeHackathonList && activeHackathonList.totalTournamentCount) ? activeHackathonList.totalTournamentCount : 0})</button>
                            </li>}
                            {lateSubmissionHackthonList && lateSubmissionHackthonList.tournamentData.length > 0 && <li class="nav-item" role="presentation">
                                <button class={`nav-link text-nowrap ${activeHackathonList && activeHackathonList.tournamentData.length <= 0 && "active" }`} id="late-submission-events-tab" data-bs-toggle="tab" data-bs-target="#late-submission-events" type="button" role="tab" aria-controls="late-submission-events" aria-selected="false">Late Submission ({(lateSubmissionHackthonList && lateSubmissionHackthonList.totalTournamentCount) ? lateSubmissionHackthonList.totalTournamentCount : 0})</button>
                            </li>}
                            {closedHackathonList && closedHackathonList.tournamentData.length > 0 && <li class="nav-item" role="presentation">
                                <button class={`nav-link text-nowrap ${lateSubmissionHackthonList && lateSubmissionHackthonList.tournamentData.length <= 0 && "active" }`} id="closed-events-tab" data-bs-toggle="tab" data-bs-target="#closed-events" type="button" role="tab" aria-controls="closed-events" aria-selected="false">Closed ({(closedHackathonList && closedHackathonList.totalTournamentCount) ? closedHackathonList.totalTournamentCount : 0})</button>
                            </li>}
                        </ul>
                        <div className="col-auto position-relative">
                            <select class="tournament-select form-select background-dark-tertiary text-white border-0 pe-5 shadow-none" aria-label="Default select example" value={filter} onChange={(e)=>{setFilter(e.target.value)}}>
                                <option value="all">All</option>
                                <option value="practice">Practice</option>
                                <option value="normal">Prize Money</option>
                                <option value="flagship">Flagship</option>
                            </select>
                            <svg className='tournament-select-svg position-absolute top-0 end-0 me-4' xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                                <path d="M13 1L7.70711 6.29289C7.31658 6.68342 6.68342 6.68342 6.29289 6.29289L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </section>
            <section className="common-style-py common-card-style background-dark-primary">
                <div className="container">
                    <div className="tab-content" id="eventsTabContent">
                        <div className={`tab-pane fade ${HackathonList && HackathonList.upcomingHackathonTournament && HackathonList.upcomingHackathonTournament.tournamentData.length > 0 && "show active" }`} id="upcoming-events" role="tabpanel" aria-labelledby="upcoming-events-tab">
                            <div className='row g-4'>
                                {HackathonList && HackathonList.upcomingHackathonTournament && HackathonList.upcomingHackathonTournament.tournamentData.map((upcoming,index)=>{
                                    return(
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div id={upcoming.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                                                <a href={"/datahack/contest/"+upcoming.slug} rel="noreferrer" >
                                                <div className='card-img position-relative overflow-hidden rounded-2'>
                                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={datahack_s3_url+upcoming.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                                    <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                    </svg>
                                                    </button>
                                                </div>
                                                </a>
                                                <div className='card-body px-0'>
                                                <div className='card-body-top d-felx align-items-center'>
                                                    <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                                                        Starts on {convertDateInFormat(convertDateToLocalDatetime(upcoming.startDate),"DD MMM YYYY")} - 
                                                        Ends on {convertDateInFormat(convertDateToLocalDatetime(upcoming.endDate),"DD MMM YYYY")}
                                                    </p>
                                                </div>
                                                <a className='text-decoration-none' href={"/datahack/contest/"+upcoming.slug} rel="noreferrer" ><h2 className="card-text text-white fs-20 mb-3 text-truncate-2">{upcoming.title}</h2></a>
                                                <h3 className='text-white fs-18 fw-light mb-3 d-flex align-items-start'>
                                                    {upcoming.prize ?<svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                                    </svg>:
                                                    <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M22 17.2399V5.16994C22 3.96994 21.02 3.07994 19.83 3.17994H19.77C17.67 3.35994 14.48 4.42994 12.7 5.54994L12.53 5.65994C12.24 5.83994 11.76 5.83994 11.47 5.65994L11.22 5.50994C9.44 4.39994 6.26 3.33994 4.16 3.16994C2.97 3.06994 2 3.96994 2 5.15994V17.2399C2 18.1999 2.78 19.0999 3.74 19.2199L4.03 19.2599C6.2 19.5499 9.55 20.6499 11.47 21.6999L11.51 21.7199C11.78 21.8699 12.21 21.8699 12.47 21.7199C14.39 20.6599 17.75 19.5499 19.93 19.2599L20.26 19.2199C21.22 19.0999 22 18.1999 22 17.2399Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 5.98999V20.99" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 8.98999H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M8.5 11.99H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    <p className="html-field" dangerouslySetInnerHTML={{__html:upcoming.prize}}></p>
                                                </h3>
                                                <div className="d-flex img-row align-items-center my-4">
                                                    {upcoming.companyLogo ? <img className='rounded-1 bg-white me-2 object-fit-contain' width={80} height={30} src={datahack_s3_url+upcoming.companyLogo} alt="company logo" loading="lazy" />:<img className='rounded-1 me-2 object-fit-contain' width={80} height={30} src={AvLogo} alt="company logo" loading="lazy" />}
                                                </div>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {upcoming.registrationCount}
                                                </button>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03977 1.89009C7.12868 1.88012 7.21911 1.875 7.30696 1.875C8.2719 1.875 9.05415 2.65724 9.05415 3.62219C9.05415 4.58714 8.2719 5.36938 7.30696 5.36938C7.09223 5.36938 6.8811 5.38219 6.67485 5.40694C6.40067 5.43985 6.20508 5.68879 6.23799 5.96296C6.27089 6.23713 6.51983 6.43272 6.794 6.39982C6.96064 6.37982 7.13198 6.36938 7.30696 6.36938C8.2021 6.36938 8.99326 6.64213 9.54936 7.05921C10.1053 7.47617 10.4025 8.01402 10.4025 8.56601C10.4025 8.84215 10.6263 9.06601 10.9025 9.06601C11.1786 9.06601 11.4025 8.84215 11.4025 8.56601C11.4025 7.62869 10.8947 6.81823 10.1494 6.25921C9.84348 6.02979 9.49355 5.83879 9.11186 5.69332C9.68928 5.18971 10.0541 4.44856 10.0541 3.62219C10.0541 2.10496 8.82419 0.875 7.30696 0.875C6.99436 0.875 6.63767 0.920273 6.32319 1.02612C6.29746 1.03478 6.2729 1.04533 6.24962 1.05756C6.37333 1.13614 6.49035 1.22618 6.59945 1.32829C6.77722 1.49466 6.92362 1.68337 7.03977 1.89009Z" fill="white"/>
                                                        <path d="M4.59551 5.49438C5.8366 5.49438 6.8427 4.48828 6.8427 3.24719C6.8427 2.0061 5.8366 1 4.59551 1C3.35442 1 2.34831 2.0061 2.34831 3.24719C2.34831 4.48828 3.35442 5.49438 4.59551 5.49438ZM4.59551 5.49438C2.60976 5.49438 1 6.7017 1 8.19101M4.59551 5.49438C6.58125 5.49438 8.19101 6.7017 8.19101 8.19101" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {upcoming.teamsCount}
                                                </button>
                                                <button type="button" className={`btn ${(calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(upcoming.startDate))/3600000/24).toFixed(0) >=10 ? "btn-green":((calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(upcoming.startDate))/3600000/24).toFixed(0)>=5 ?"btn-yellow":"btn-red")} text-success btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center`}>
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                                        <g clip-path="url(#clip0_16631_89205)">
                                                        <path d="M6 3.81927V6.81927H9" stroke="#85D992" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M6 11.8207C8.7615 11.8207 11 9.58221 11 6.82071C11 4.05921 8.7615 1.82071 6 1.82071C3.2385 1.82071 1 4.05921 1 6.82071C1 9.58221 3.2385 11.8207 6 11.8207Z" stroke="#85D992" stroke-linecap="round" stroke-linejoin="round"/>
                                                        </g>
                                                        <defs>
                                                        <clipPath id="clip0_16631_89205">
                                                        <rect width="12" height="12" fill="white" transform="translate(0 0.819275)"/>
                                                        </clipPath>
                                                        </defs>
                                                    </svg>
                                                    {(calculateTimeRemainingInMilliSeconds(convertDateToLocalDatetime(upcoming.startDate))/3600000/24).toFixed(0)} day left
                                                </button>
                                                </div>
                                
                                                <div className='card-footer border-0 px-0'>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={"/datahack/contest/"+upcoming.slug} rel="noreferrer" >
                                                    Participate Now
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className={`tab-pane fade ${HackathonList && HackathonList.upcomingHackathonTournament && HackathonList.upcomingHackathonTournament.tournamentData.length <= 0 && "show active" }`} id="active-events" role="tabpanel" aria-labelledby="active-events-tab">
                            <div className='row mb-5 g-4'>
                                {activeHackathonList && activeHackathonList.tournamentData.map((active,index)=>{
                                    return(
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div id={active.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                                                <a href={"/datahack/contest/"+active.slug} rel="noreferrer" >
                                                <div className='card-img position-relative overflow-hidden rounded-2'>
                                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={datahack_s3_url+active.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                                    <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                    </svg>
                                                    </button>
                                                </div>
                                                </a>
                                                <div className='card-body px-0'>
                                                <div className='card-body-top d-felx align-items-center'>
                                                    <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                                                        <span>Started on </span>
                                                        {convertDateInFormat(convertDateToLocalDatetime(active.startDate),"DD MMM YYYY")} -
                                                         Ends on {convertDateInFormat(convertDateToLocalDatetime(active.endDate),"DD MMM YYYY")}
                                                    {/* <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                        {convertDateInFormat(convertDateToLocalDatetime(active.endDate),"hh:mma")} */}
                                                    </p>
                                                </div>
                                                <a className='text-decoration-none' href={"/datahack/contest/"+active.slug} rel="noreferrer" ><h2 className="card-text text-white fs-20 mb-3 text-truncate-2">{active.title}</h2></a>
                                                <h3 className='text-white fs-18 fw-light mb-3 d-flex align-items-start'>
                                                    {active.prize ?<svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                                    </svg>:
                                                    <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M22 17.2399V5.16994C22 3.96994 21.02 3.07994 19.83 3.17994H19.77C17.67 3.35994 14.48 4.42994 12.7 5.54994L12.53 5.65994C12.24 5.83994 11.76 5.83994 11.47 5.65994L11.22 5.50994C9.44 4.39994 6.26 3.33994 4.16 3.16994C2.97 3.06994 2 3.96994 2 5.15994V17.2399C2 18.1999 2.78 19.0999 3.74 19.2199L4.03 19.2599C6.2 19.5499 9.55 20.6499 11.47 21.6999L11.51 21.7199C11.78 21.8699 12.21 21.8699 12.47 21.7199C14.39 20.6599 17.75 19.5499 19.93 19.2599L20.26 19.2199C21.22 19.0999 22 18.1999 22 17.2399Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 5.98999V20.99" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 8.98999H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M8.5 11.99H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    <p className="html-field" dangerouslySetInnerHTML={{__html:active.prize}}></p>
                                                </h3>
                                                <div className="d-flex img-row align-items-center my-4">
                                                    {active.companyLogo ?<img className='rounded-1 bg-white me-2 object-fit-contain' width={80} height={30} src={datahack_s3_url+active.companyLogo} alt="company logo" loading="lazy" />:<img className='rounded-1 me-2 object-fit-contain' width={80} height={30} src={AvLogo} alt="company logo" loading="lazy" />}
                                                </div>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {active.registrationCount}
                                                </button>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03977 1.89009C7.12868 1.88012 7.21911 1.875 7.30696 1.875C8.2719 1.875 9.05415 2.65724 9.05415 3.62219C9.05415 4.58714 8.2719 5.36938 7.30696 5.36938C7.09223 5.36938 6.8811 5.38219 6.67485 5.40694C6.40067 5.43985 6.20508 5.68879 6.23799 5.96296C6.27089 6.23713 6.51983 6.43272 6.794 6.39982C6.96064 6.37982 7.13198 6.36938 7.30696 6.36938C8.2021 6.36938 8.99326 6.64213 9.54936 7.05921C10.1053 7.47617 10.4025 8.01402 10.4025 8.56601C10.4025 8.84215 10.6263 9.06601 10.9025 9.06601C11.1786 9.06601 11.4025 8.84215 11.4025 8.56601C11.4025 7.62869 10.8947 6.81823 10.1494 6.25921C9.84348 6.02979 9.49355 5.83879 9.11186 5.69332C9.68928 5.18971 10.0541 4.44856 10.0541 3.62219C10.0541 2.10496 8.82419 0.875 7.30696 0.875C6.99436 0.875 6.63767 0.920273 6.32319 1.02612C6.29746 1.03478 6.2729 1.04533 6.24962 1.05756C6.37333 1.13614 6.49035 1.22618 6.59945 1.32829C6.77722 1.49466 6.92362 1.68337 7.03977 1.89009Z" fill="white"/>
                                                        <path d="M4.59551 5.49438C5.8366 5.49438 6.8427 4.48828 6.8427 3.24719C6.8427 2.0061 5.8366 1 4.59551 1C3.35442 1 2.34831 2.0061 2.34831 3.24719C2.34831 4.48828 3.35442 5.49438 4.59551 5.49438ZM4.59551 5.49438C2.60976 5.49438 1 6.7017 1 8.19101M4.59551 5.49438C6.58125 5.49438 8.19101 6.7017 8.19101 8.19101" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {active.teamsCount}
                                                </button>
                                                </div>
                                
                                                <div className='card-footer border-0 px-0'>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={"/datahack/contest/"+active.slug} rel="noreferrer" >
                                                    Participate Now
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {activeHackathonList && <Pagination updatePageNumber={updateActiveSubmissionHackthonPageNumber} last_page={activeHackathonList.totalPages}/>}
                        </div>
                        <div className={`tab-pane fade ${activeHackathonList && activeHackathonList.tournamentData.length <= 0 && "show active" }`} id="late-submission-events" role="tabpanel" aria-labelledby="late-submission-events-tab">
                            <div className='row mb-5 g-4'>
                                {lateSubmissionHackthonList && lateSubmissionHackthonList.tournamentData.map((closed,index)=>{
                                    return(
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div id={closed.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                                                <a href={"/datahack/contest/"+closed.slug} rel="noreferrer" >
                                                <div className='card-img position-relative overflow-hidden rounded-2'>
                                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={datahack_s3_url+closed.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                                    <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                    </svg>
                                                    </button>
                                                </div>
                                                </a>
                                                <div className='card-body px-0'>
                                                <div className='card-body-top d-felx align-items-center'>
                                                    <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                                                        <span>Started on </span>
                                                        {convertDateInFormat(convertDateToLocalDatetime(closed.startDate),"DD MMM YYYY")} -
                                                         Ended on {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"DD MMM YYYY")}
                                                    {/* <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                        {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"hh:mma")} */}
                                                    </p>
                                                </div>
                                                <a className='text-decoration-none' href={"/datahack/contest/"+closed.slug} rel="noreferrer" ><h2 className="card-text text-white fs-20 mb-3 text-truncate-2">{closed.title}</h2></a>
                                                <h3 className='text-white fs-18 fw-light mb-3 d-flex align-items-start'>
                                                    {closed.prize ?<svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                                    </svg>:
                                                    <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M22 17.2399V5.16994C22 3.96994 21.02 3.07994 19.83 3.17994H19.77C17.67 3.35994 14.48 4.42994 12.7 5.54994L12.53 5.65994C12.24 5.83994 11.76 5.83994 11.47 5.65994L11.22 5.50994C9.44 4.39994 6.26 3.33994 4.16 3.16994C2.97 3.06994 2 3.96994 2 5.15994V17.2399C2 18.1999 2.78 19.0999 3.74 19.2199L4.03 19.2599C6.2 19.5499 9.55 20.6499 11.47 21.6999L11.51 21.7199C11.78 21.8699 12.21 21.8699 12.47 21.7199C14.39 20.6599 17.75 19.5499 19.93 19.2599L20.26 19.2199C21.22 19.0999 22 18.1999 22 17.2399Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 5.98999V20.99" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 8.98999H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M8.5 11.99H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    <p className="html-field" dangerouslySetInnerHTML={{__html:closed.prize}}></p>
                                                </h3>
                                                <div className="d-flex img-row align-items-center my-4">
                                                    {closed.companyLogo ?<img className='rounded-1 bg-white me-2 object-fit-contain' width={80} height={30} src={datahack_s3_url+closed.companyLogo} alt="company logo" loading="lazy" />:<img className='rounded-1 me-2 object-fit-contain' width={80} height={30} src={AvLogo} alt="company logo" loading="lazy" />}
                                                </div>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {closed.registrationCount}
                                                </button>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03977 1.89009C7.12868 1.88012 7.21911 1.875 7.30696 1.875C8.2719 1.875 9.05415 2.65724 9.05415 3.62219C9.05415 4.58714 8.2719 5.36938 7.30696 5.36938C7.09223 5.36938 6.8811 5.38219 6.67485 5.40694C6.40067 5.43985 6.20508 5.68879 6.23799 5.96296C6.27089 6.23713 6.51983 6.43272 6.794 6.39982C6.96064 6.37982 7.13198 6.36938 7.30696 6.36938C8.2021 6.36938 8.99326 6.64213 9.54936 7.05921C10.1053 7.47617 10.4025 8.01402 10.4025 8.56601C10.4025 8.84215 10.6263 9.06601 10.9025 9.06601C11.1786 9.06601 11.4025 8.84215 11.4025 8.56601C11.4025 7.62869 10.8947 6.81823 10.1494 6.25921C9.84348 6.02979 9.49355 5.83879 9.11186 5.69332C9.68928 5.18971 10.0541 4.44856 10.0541 3.62219C10.0541 2.10496 8.82419 0.875 7.30696 0.875C6.99436 0.875 6.63767 0.920273 6.32319 1.02612C6.29746 1.03478 6.2729 1.04533 6.24962 1.05756C6.37333 1.13614 6.49035 1.22618 6.59945 1.32829C6.77722 1.49466 6.92362 1.68337 7.03977 1.89009Z" fill="white"/>
                                                        <path d="M4.59551 5.49438C5.8366 5.49438 6.8427 4.48828 6.8427 3.24719C6.8427 2.0061 5.8366 1 4.59551 1C3.35442 1 2.34831 2.0061 2.34831 3.24719C2.34831 4.48828 3.35442 5.49438 4.59551 5.49438ZM4.59551 5.49438C2.60976 5.49438 1 6.7017 1 8.19101M4.59551 5.49438C6.58125 5.49438 8.19101 6.7017 8.19101 8.19101" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {closed.teamsCount}
                                                </button>
                                                </div>
                                
                                                <div className='card-footer border-0 px-0'>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={"/datahack/contest/"+closed.slug} rel="noreferrer" disabled >
                                                    Post-Event Submission
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {lateSubmissionHackthonList && <Pagination updatePageNumber={updateLateSubmissionHackthonPageNumber} last_page={lateSubmissionHackthonList.totalPages}/>}
                        </div>
                        <div className={`tab-pane fade ${lateSubmissionHackthonList && lateSubmissionHackthonList.tournamentData.length <= 0 && "show active" }`} id="closed-events" role="tabpanel" aria-labelledby="closed-events-tab">
                            <div className='row mb-5 g-4'>
                                {closedHackathonList && closedHackathonList.tournamentData.map((closed,index)=>{
                                    return(
                                        <div className="col-12 col-md-6 col-lg-4">
                                            <div id={closed.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                                                <a href={"/datahack/contest/"+closed.slug} rel="noreferrer" >
                                                <div className='card-img position-relative overflow-hidden rounded-2'>
                                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={datahack_s3_url+closed.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                                                    <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                        <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                                                    </svg>
                                                    </button>
                                                </div>
                                                </a>
                                                <div className='card-body px-0'>
                                                <div className='card-body-top d-felx align-items-center'>
                                                    <p className='fs-14 mb-0 fw-light text-dark-tertiary'>
                                                        
                                                    </p>
                                                    <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                                                        <span>Started on </span>
                                                        {convertDateInFormat(convertDateToLocalDatetime(closed.startDate),"DD MMM YYYY")} -
                                                         Ended on {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"DD MMM YYYY")}
                                                    {/* <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                        <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                                                    </svg>
                                                        {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"hh:mma")} */}
                                                    </p>
                                                </div>
                                                <a className='text-decoration-none' href={"/datahack/contest/"+closed.slug} rel="noreferrer" ><h2 className="card-text text-white fs-20 mb-3 text-truncate-2">{closed.title}</h2></a>
                                                <h3 className='text-white fs-18 fw-light mb-3 d-flex align-items-start'>
                                                    {closed.prize ?<svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                                                    </svg>:
                                                    <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                        <path d="M22 17.2399V5.16994C22 3.96994 21.02 3.07994 19.83 3.17994H19.77C17.67 3.35994 14.48 4.42994 12.7 5.54994L12.53 5.65994C12.24 5.83994 11.76 5.83994 11.47 5.65994L11.22 5.50994C9.44 4.39994 6.26 3.33994 4.16 3.16994C2.97 3.06994 2 3.96994 2 5.15994V17.2399C2 18.1999 2.78 19.0999 3.74 19.2199L4.03 19.2599C6.2 19.5499 9.55 20.6499 11.47 21.6999L11.51 21.7199C11.78 21.8699 12.21 21.8699 12.47 21.7199C14.39 20.6599 17.75 19.5499 19.93 19.2599L20.26 19.2199C21.22 19.0999 22 18.1999 22 17.2399Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M12 5.98999V20.99" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M7.75 8.98999H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M8.5 11.99H5.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </svg>}
                                                    <p className="html-field" dangerouslySetInnerHTML={{__html:closed.prize}}></p>
                                                </h3>
                                                <div className="d-flex img-row align-items-center my-4">
                                                    {closed.companyLogo ? <img className='rounded-1 bg-white me-2 object-fit-contain' width={80} height={30} src={datahack_s3_url+closed.companyLogo} alt="company logo" loading="lazy" />:<img className='rounded-1 me-2 object-fit-contain' width={80} height={30} src={AvLogo} alt="company logo" loading="lazy" />}
                                                </div>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                        <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {closed.registrationCount}
                                                </button>
                                                <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                                                    <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10" fill="none">
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.03977 1.89009C7.12868 1.88012 7.21911 1.875 7.30696 1.875C8.2719 1.875 9.05415 2.65724 9.05415 3.62219C9.05415 4.58714 8.2719 5.36938 7.30696 5.36938C7.09223 5.36938 6.8811 5.38219 6.67485 5.40694C6.40067 5.43985 6.20508 5.68879 6.23799 5.96296C6.27089 6.23713 6.51983 6.43272 6.794 6.39982C6.96064 6.37982 7.13198 6.36938 7.30696 6.36938C8.2021 6.36938 8.99326 6.64213 9.54936 7.05921C10.1053 7.47617 10.4025 8.01402 10.4025 8.56601C10.4025 8.84215 10.6263 9.06601 10.9025 9.06601C11.1786 9.06601 11.4025 8.84215 11.4025 8.56601C11.4025 7.62869 10.8947 6.81823 10.1494 6.25921C9.84348 6.02979 9.49355 5.83879 9.11186 5.69332C9.68928 5.18971 10.0541 4.44856 10.0541 3.62219C10.0541 2.10496 8.82419 0.875 7.30696 0.875C6.99436 0.875 6.63767 0.920273 6.32319 1.02612C6.29746 1.03478 6.2729 1.04533 6.24962 1.05756C6.37333 1.13614 6.49035 1.22618 6.59945 1.32829C6.77722 1.49466 6.92362 1.68337 7.03977 1.89009Z" fill="white"/>
                                                        <path d="M4.59551 5.49438C5.8366 5.49438 6.8427 4.48828 6.8427 3.24719C6.8427 2.0061 5.8366 1 4.59551 1C3.35442 1 2.34831 2.0061 2.34831 3.24719C2.34831 4.48828 3.35442 5.49438 4.59551 5.49438ZM4.59551 5.49438C2.60976 5.49438 1 6.7017 1 8.19101M4.59551 5.49438C6.58125 5.49438 8.19101 6.7017 8.19101 8.19101" stroke="white" stroke-linecap="round"/>
                                                    </svg>
                                                    {closed.teamsCount}
                                                </button>
                                                </div>
                                
                                                <div className='card-footer border-0 px-0'>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={"/datahack/contest/"+closed.slug} rel="noreferrer" disabled >
                                                    Event Closed
                                                </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            {closedHackathonList && <Pagination updatePageNumber={updateClosedHackthonPageNumber} last_page={closedHackathonList.totalPages}/>}
                        </div>
                    </div>
                </div>
            </section>
            <Suspense fallback={<div>Loading</div>}>
                <HackathonListingSwiperComponent />
            </Suspense>
            <Footer platform={"newcompetition_hackathon_list "} />
            <Flashstrip />
            <Cookies platform={'newcompetition'}  />
        </>
    )
}


const mapStateToProps = (state) => ({
	eventList:state.eventContentReducer.eventList,
    featuredSessionBanner: state.eventContentReducer.featuredSessionBanner,
    pastEventList:state.eventContentReducer.pastEventList,
    pastEventListTotalPages:state.eventContentReducer.pastEventListTotalPages,
	HackathonList:state.competitionContentReducer.competitionList,
    activeHackathonList:state.competitionContentReducer.activeHackathonTournament,
    closedHackathonList:state.competitionContentReducer.closedHackathonTournament,
    lateSubmissionHackthonList:state.competitionContentReducer.lateSubmissionHackthonTournament
});

const mapDispatchToProps = dispatch=>({
    getCompetitionListDataHandler:data=>{dispatch(getCompetitionListData(data))},
    getActiveTournamentByPageNumberDataHandler:data=>{dispatch(getActiveTournamentByPageNumberData(data))},
    getClosedTournamentByPageNumberDataHandler:data=>{dispatch(getClosedTournamentByPageNumberData(data))},
    getLateSubmissionTournamentByPageNumberDataHandler:data=>{dispatch(getLateSubmissionTournamentByPageNumberData(data))},
});

export default connect(mapStateToProps,mapDispatchToProps)(HackathonListing);
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export default function Title({title,description,image_og,title_og,url_og,type_og,site_name_og,locale_og}) {
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title_og} />
        <meta property="og:image" content={image_og} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url_og} />
        <meta property="og:type" content={type_og} />
        <meta property="og:site_name" content={site_name_og} />
        <meta property="og:locale" content={locale_og} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={image_og} />
    </Helmet>
  )
}

import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import { useParams } from 'react-router-dom';
import { getQueryDataElevate } from '../../../../utils';

function UpdateEmailTemplate({ csrf, islogin }) {
	const [name, setName] = useState("");
	const [emailSubject, setEmailSubject] = useState("");
	const [emailBody, setEmailBody] = useState("");

	const [iserror, setIsError] = useState(false);
	const [issuccess, setIsSuccess] = useState(false);
	const [message, setMessage] = useState("");

	const { templateId } = useParams();

	useEffect(()=>{
        if(islogin){
            let query = `
			query MyQuery {
				emailTemplate(id: ${templateId}) {
				  body
				  name
				  subject
				}
			  }
            `;

            getQueryDataElevate(query,"newnotifications","True")
            .then((data)=>{
                console.log(data.data);
                setEmailBody(data?.data?.emailTemplate?.body);
				setEmailSubject(data?.data?.emailTemplate?.subject);
				setName(data?.data?.emailTemplate?.name);	
            })
            .catch((error)=>{
                console.log(error);
            })
        }
    },[islogin]);

	const handleSubmit = async (e) => {
        e.preventDefault();

        let payload = {
            "name" : name,
			"body": emailBody,
			"subject": emailSubject,
			"template_id": parseInt(templateId)
        }

        const formData = new FormData();
        formData.append("platform", 'newnotifications');
        formData.append("payload", JSON.stringify(payload));
        formData.append("url", 'api/update/email-template');
        formData.append("forward_user_data", "True");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            headers: {
                "X-CSRFToken":csrf,
            },
            credentials:process.env.REACT_APP_CREDENTIAL_TYPE
        })
        .then(response => {
            return response.json();
        })
        .then(result => {
            if(!result.errors){
				setMessage(result.response);
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
            else{
                setMessage("Network response was not ok!");
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            setMessage("Some error occured!");
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            return false;
        });
    }

	return (
		<>
			<Header />
			{iserror && <ErrorAlert message={message} setIsError={setIsError} />}
			{issuccess && <SuccessAlert message={message} />}
			<section id='updateEmailTemplates' className='common-style background-dark-primary min-vh-100'>
				<div className='container py-5 px-5'>
					<div className='row d-flex justify-content-center align-items-center'>
						<div className='col-lg-9'>
							<div className='card p-4 border-0 shadow background-dark-tertiary'>
								<div className="card-body">
									<h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Update Email Template</h3>
									<form className='w-100' onSubmit={handleSubmit}>

										<div className="form-group mb-3">
											<label className="text-white">Name <sup className='text-danger'>*</sup></label>
											<input
												type="text"
												className='form-control'
												placeholder='Name'
												value={name}
												onChange={(event) => setName(event.target.value)}
												disabled
											/>
										</div>

										<div className="form-group mb-3">
											<label className="text-white">Email Subject <sup className='text-danger'>*</sup></label>
											<textarea
												className='form-control'
												value={emailSubject}
												onChange={(event) => setEmailSubject(event.target.value)}
												style={{ height: '70px' }}
												required
											/>
										</div>	

										<div className="form-group mb-3">
											<label className="text-white">Email Body <sup className='text-danger'>*</sup></label>
											<textarea
												className='form-control'
												value={emailBody}
												onChange={(event) => setEmailBody(event.target.value)}
												style={{ height: '300px' }}
												required
											/>
										</div>

										<button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
											Update
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}


const mapStateToProps = (state) => ({
	csrf: state.LoginModalReducer.csrf,
	islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmailTemplate);

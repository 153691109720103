import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../../common/Alert/Error404';
import UserCompetitionsProfile from '../pages/UserCompetitionsProfile';


export default function ProfileRouters() {

  return (
    <>
      <Routes>
          <Route path='/:username' exact element={<UserCompetitionsProfile />} />
          <Route path='*' element={<Error404 />} />
      </Routes>
    </>
  )
}
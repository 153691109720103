import React, { Suspense, useEffect } from 'react'
import Footer from '../../../common/Footer/Footer';
import Header from '../../../common/Header/Header';
import Flashstrip from '../../../common/Header/Flashstrip';
import { connect } from 'react-redux';
import { getEventListData, getLeadingWithDataListData } from '../../../../store/Actions/Action';
import { convertDateInFormat, convertDateToLocalDatetime, convertTimeToFormattedString } from '../../../../utils';
import $ from 'jquery';
import Cookies from '../../../common/Footer/Cookies';
import Loader from '../../../common/Loader';
const EventHomeSwiperComponent1 = React.lazy(() => import("../components/EventHomeSwiperComponent1"));
const EventHomeSwiperComponent2 = React.lazy(() => import("../components/EventHomeSwiperComponent2"));
const EventHomeSwiperComponent3 = React.lazy(() => import("../components/EventHomeSwiperComponent3"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

function EventHome({getEventListDataHandler, eventList, leadingwithdataList, last6monthsData, getLeadingWithDataListDataHandler}) {

    useEffect(()=>{
        getEventListDataHandler();
        getLeadingWithDataListDataHandler();
    },[getEventListDataHandler, getLeadingWithDataListDataHandler]);

    useEffect(()=>{ 
        console.log("leadingWithdatalist",leadingwithdataList);
    },[leadingwithdataList]);

    useEffect(()=>{
        $('#experience .nav-link').click((e)=>{
            let height = $('#experience .nav-link.active').index();
            $('#v-pills-tab').css('--height', `${25 * (height+1)}%`);
        })
    },[]);


  return (
    <>
        {!eventList || !leadingwithdataList && <Loader />}
        <Header />
        <Suspense fallback={<div>Loading</div>}>
            <EventHomeSwiperComponent1 />
        </Suspense>
        <section className='background-dark-secondary' id='number'>
            <div className='container'>
                <div className='row gx-4'>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>4M+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Community Members</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>1500+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Experts</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>1000+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Events Organised</h3>
                        </div>
                    </div>
                    <div className='col-sm-6 col-lg-4 col-xl-3'>
                        <div className='number-box px-5'>
                            <h2 className='text-white text-center'>100+</h2>
                            <h3 className='fs-18 fw-normal text-center'>Partners</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='common-style-py background-dark-primary event-tabs' id='experience'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text">
                        <h2 className="fs-40 fw-normal mb-3">Transform Attendance to Achievements</h2>
                        <p className="fs-18 fw-normal">Gain a portfolio of new skills, connections, and memorable achievements</p>
                    </div>
                </div>
                <div className="d-flex align-items-start col-lg-11 mx-auto" id='experienceTabs'>
                    <div className="nav flex-column nav-pills me-3 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active p-4 text-start" id="v-pills-discover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-discover" type="button" role="tab" aria-controls="v-pills-discover" aria-selected="true">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>LEARN</h3>
                        <p className='fs-16 fw-light'>Gain cutting-edge AI knowledge from leading experts</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-learn-tab" data-bs-toggle="pill" data-bs-target="#v-pills-learn" type="button" role="tab" aria-controls="v-pills-learn" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>NETWORK</h3>
                        <p className='fs-16 fw-light'>Engage with industry experts and peers to build a powerful network</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-engage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-engage" type="button" role="tab" aria-controls="v-pills-engage" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>EARN CERTIFICATION</h3>
                        <p className='fs-16 fw-light'>Boost your credibility and share your recognized certification</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-contribute-tab" data-bs-toggle="pill" data-bs-target="#v-pills-contribute" type="button" role="tab" aria-controls="v-pills-contribute" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>CONTRIBUTE</h3>
                        <p className='fs-16 fw-light'>Share your AI expertise and contribute to the community</p>
                        </button>
                    </div>
                    <div className="tab-content col-lg-6 col-12 rounded-3" id="v-pills-tabContent">
                        <div className="card tab-pane fade show active bg-transparent border-0 m-0" id="v-pills-discover" role="tabpanel" aria-labelledby="v-pills-discover-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-discover">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-discover" data-bs-parent="#experienceTabs" aria-expanded="true" aria-controls="v-collapse-discover">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>LEARN</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Gain cutting-edge AI knowledge from leading experts</p>
                                </a>
                            </div>
                            <div id="v-collapse-discover" className="collapse show" role="tabpanel" aria-labelledby="v-collapse-heading-discover" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto my-4 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/events_tabs1.png?format=webp&width=536"} alt="image" />
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent tab-pane fade" id="v-pills-learn" role="tabpanel" aria-labelledby="v-pills-learn-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-learn">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-learn" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-learn">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>NETWORK</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Engage with industry experts and peers to build a powerful network</p>
                                </a>
                            </div>
                            <div id="v-collapse-learn" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-learn" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto my-4 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/events_tabs2.png?format=webp&width=536"} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-engage" role="tabpanel" aria-labelledby="v-pills-engage-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-engage">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-engage" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-engage">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>EARN CERTIFICATION</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Boost your credibility and share your recognized certification</p>
                                </a>
                            </div>
                            <div id="v-collapse-engage" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-engage" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto my-4 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/certificate_new2.png?format=webp&width=536"} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-contribute" role="tabpanel" aria-labelledby="v-pills-contribute-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-contribute">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-contribute" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-contribute">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>CONTRIBUTE</h3>
                                <p className='fs-16 fw-normal pe-4 text-white'>Share your AI expertise and contribute to the community</p>
                                </a>
                            </div>
                            <div id="v-collapse-contribute" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-contribute" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto my-4 rounded-3' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/contribute_new2.png?format=webp&width=536"} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='background-dark-secondary common-style-py common-card-style'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                        <h2 className="fs-40 fw-normal mb-3">Join Community of Data Enthusiasts</h2>
                        <p className="fs-18 fw-normal">Dive into engaging sessions, build practical skills, and connect with experts</p>
                    </div>
                </div>
                <div className='row gx-4'>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href={'/events/leading-with-data'} id='eventsLWDCardLink'>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={"https://imgcdn.analyticsvidhya.com/events/Leading%20with%20data.png"} alt="Card cap" loading="lazy" width="392" height="220" />
                                    
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>Leading with Data</h3>
                                <p className='fs-14 mb-3'>One-on-one conversation with AI thought leaders</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={'/events/leading-with-data'} id='eventsLWDParticipateNowCTA'>
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href={'/events/datahour'} id='eventsDatahourCardLink'>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={"https://imgcdn.analyticsvidhya.com/events/Datahour.png"} alt="Card cap" loading="lazy" width="392" height="220" />
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>DataHour</h3>
                                <p className='fs-14 mb-3'>Interactive action-packed AI webinars led by top industry experts</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={'/events/datahour'} id='eventsDatahourParticipateNowCTA'>
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4'>
                        <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                            <a href="https://www.analyticsvidhya.com/datahacksummit/" target="_blank" id='eventsDHSCardLink'>
                                <div className="card-img position-relative overflow-hidden rounded-2">
                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/datahack_summit.png?format=webp&width=392"} alt="Card cap" loading="lazy" width="392" height="220" />                                    
                                
                                </div>
                            </a>
                            <div className='card-body px-0'>
                                <h3 className='text-white fs-24 mb-3'>DataHack Summit</h3>
                                <p className='fs-14 mb-3'>India’s most futuristic AI conference</p>
                            </div>
                            <div className='card-footer border-0 px-0'>
                                <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href="https://www.analyticsvidhya.com/datahacksummit/" target='_blank' id='eventsDHSParticipateNowCTA'>
                                    Participate Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {eventList && eventList.length > 0 && <section className='background-dark-primary common-style common-card-style'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                        <h2 className="fs-40 fw-normal mb-3">DataHour</h2>
                        <p className="fs-18 fw-normal">Interactive action-packed AI webinars led by top industry experts</p>
                    </div>
                </div>
                
                <div className='row gx-4'>
                    {eventList && eventList.map((upcoming,index)=>{
                    return (
                        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4' key={index}>
                            <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                                <a href={`/events/datahour/${upcoming.node.slug}`} id={upcoming.node.slug+`CardLink`}>
                                    <div className="card-img position-relative overflow-hidden rounded-2">
                                        {upcoming.node.speaker && <img className="card-img-hrefp rounded-2 img-fluid object-fit-contain" src={upcoming.node.speaker.displayPictureUrl} alt="Card cap" loading="lazy" width="392" height="220" />}
                                    </div>
                                </a>
                                <div className='card-body px-0'>
                                    <div class="card-body-top d-flex align-items-center">
                                        <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                            {convertDateInFormat(convertDateToLocalDatetime(upcoming.node.startDateTime),"DD MMM")}
                                            <svg class="mx-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                <circle cx="3" cy="3" r="3" fill="#8E8E8E"></circle>
                                            </svg>
                                            {convertDateInFormat(convertDateToLocalDatetime(upcoming.node.startDateTime),"hh:mm A")} IST
                                        </p>
                                    </div>
                                    <h3 className='text-white fs-20 mb-3'>{upcoming.node.sessionName}</h3>
                                    <div className="d-flex flex-wrap my-4">
                                        <div className="col">
                                            {upcoming.node.speaker && <p className='text-white mb-0'>{upcoming.node.speaker.name}</p>}
                                            {upcoming.node.speaker && <p className='text-dark-tertiary mb-0'>{upcoming.node.speaker.designation}</p>}
                                        </div>
                                        {upcoming.node.speaker && upcoming.node.speaker.companyLogoUrl && <div class="col-auto d-flex img-row align-items-center">
                                            {upcoming.node.speaker && <img class="rounded-1 bg-white me-2  no-pointer object-fit-contain" width="80" height="30" src={upcoming.node.speaker.companyLogoUrl} alt={upcoming.node.speaker.companyName} loading="lazy" />}
                                        </div>}
                                    </div>
                                </div>
                                <div className='card-footer border-0 px-0'>
                                    <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={`/events/datahour/${upcoming.node.slug}`} id={upcoming.node.slug+`ParticipateNowCTA`}>
                                        Participate Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                    })}
                </div>
                <a className="btn btn-dark btn-dark-primary rounded-3 mx-auto d-table mt-4" href={'/events/datahour'} target='_blank' id='eventsDatahourExploreCTA'>Explore More Sessions</a>
            </div>
        </section>}
        {leadingwithdataList && <section className='background-dark-primary common-style common-card-style' id='leadingWithData'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text mb-5">
                        <h2 className="fs-40 fw-normal mb-3">Leading with Data</h2>
                        <p className="fs-18 fw-normal">One-on-one conversation with AI thought leaders</p>
                    </div>
                </div>
                <div className='row gx-4'>
                    {leadingwithdataList.videoData && leadingwithdataList.videoData[0] && <div className="col-lg-6 col-md-12 col-12 mb-4">
                        <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[0].slug} className='text-decoration-none' id={leadingwithdataList.videoData[0].slug+`CardLink`}>
                        <div className="card bg-transparent border-0">
                            <div className="card-body p-0">
                                <div className="overlay-wrapper position-relative">
                                    <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                        <button className="btn play-btn rounded-circle d-flex align-items-center justify-content-center z-3">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                            <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <img className='img-fluid rounded-4' src={leadingwithdataList.videoData[0].thumbnailUrl} alt="leading-with-data" />
                                </div>
                                <h3 className='text-white mt-3 fs-32 fw-medium'>{leadingwithdataList.videoData[0].title}</h3>
                                <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(leadingwithdataList.videoData[0].uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(leadingwithdataList.videoData[0].duration)}</p>
                            </div>
                        </div>
                        </a>
                    </div>}
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="row">
                            {leadingwithdataList.videoData && leadingwithdataList.videoData[1] && <div className="col-lg-6 col-md-6 col-12 mb-3">
                                <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[1].slug} className='text-decoration-none' id={leadingwithdataList.videoData[1].slug+`CardLink`}>
                                <div className="card bg-transparent border-0">
                                    <div className="card-body p-0">
                                        <div className="overlay-wrapper position-relative">
                                            <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                    <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <img className='img-fluid w-100 rounded-4' src={leadingwithdataList.videoData[1].thumbnailUrl} alt="leading-with-data" />
                                        </div>
                                        <h3 className='text-white mt-2 fs-16 fw-medium'>{leadingwithdataList.videoData[1].title}</h3>
                                        <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(leadingwithdataList.videoData[1].uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(leadingwithdataList.videoData[1].duration)}</p>
                                    </div>
                                </div>
                                </a>
                            </div>}
                            {leadingwithdataList.videoData && leadingwithdataList.videoData[2] && <div className="col-lg-6 col-md-6 col-12 mb-3">
                                <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[2].slug} className='text-decoration-none' id={leadingwithdataList.videoData[2].slug+`CardLink`}>
                                <div className="card bg-transparent border-0">
                                    <div className="card-body p-0">
                                        <div className="overlay-wrapper position-relative">
                                            <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                    <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <img className='img-fluid w-100 rounded-4' src={leadingwithdataList.videoData[2].thumbnailUrl} alt="leading-with-data" />
                                        </div>
                                        <h3 className='text-white mt-2 fs-16 fw-medium'>{leadingwithdataList.videoData[2].title}</h3>
                                        <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(leadingwithdataList.videoData[2].uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(leadingwithdataList.videoData[2].duration)}</p>
                                    </div>
                                </div>
                                </a>
                            </div>}
                            {leadingwithdataList.videoData && leadingwithdataList.videoData[3] && <div className="col-lg-6 col-md-6 col-12 mb-3">
                                <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[3].slug} className='text-decoration-none' id={leadingwithdataList.videoData[3].slug+`CardLink`}>
                                <div className="card bg-transparent border-0">
                                    <div className="card-body p-0">
                                        <div className="overlay-wrapper position-relative">
                                            <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                    <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <img className='img-fluid w-100 rounded-4' src={leadingwithdataList.videoData[3].thumbnailUrl} alt="leading-with-data" />
                                        </div>
                                        <h3 className='text-white mt-2 fs-16 fw-medium'>{leadingwithdataList.videoData[3].title}</h3>
                                        <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(leadingwithdataList.videoData[3].uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(leadingwithdataList.videoData[3].duration)}</p>
                                    </div>
                                </div>
                                </a>
                            </div>}
                            {leadingwithdataList.videoData && leadingwithdataList.videoData[4] && <div className="col-lg-6 col-md-6 col-12 mb-3">
                                <a href={'/events/leading-with-data/'+leadingwithdataList.videoData[4].slug} className='text-decoration-none' id={leadingwithdataList.videoData[4].slug+`CardLink`}>
                                <div className="card bg-transparent border-0">
                                    <div className="card-body p-0">
                                        <div className="overlay-wrapper position-relative">
                                            <div className="overlay position-absolute w-100 top-0 start-0 d-flex align-items-center justify-content-center">
                                                <button className="btn video-play-btn sm rounded-circle d-flex align-items-center justify-content-center z-3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                                    <path d="M19.624 12.6673L9.0254 19.7331C8.79564 19.8863 8.4852 19.8242 8.33202 19.5944C8.27727 19.5123 8.24805 19.4158 8.24805 19.3171V5.18555C8.24805 4.90941 8.47191 4.68555 8.74805 4.68555C8.84676 4.68555 8.94327 4.71477 9.0254 4.76953L19.624 11.8353C19.8537 11.9885 19.9158 12.2989 19.7626 12.5287C19.726 12.5836 19.6789 12.6307 19.624 12.6673Z" fill="white"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                            <img className='img-fluid w-100 rounded-4' src={leadingwithdataList.videoData[4].thumbnailUrl} alt="leading-with-data" />
                                        </div>
                                        <h3 className='text-white mt-2 fs-16 fw-medium'>{leadingwithdataList.videoData[4].title}</h3>
                                        <p className='text-dark-tertiary fs-14 fw-light'>{convertDateInFormat(convertDateToLocalDatetime(leadingwithdataList.videoData[4].uploadedAt),"DD MMMM YYYY")} • {convertTimeToFormattedString(leadingwithdataList.videoData[4].duration)}</p>
                                    </div>
                                </div>
                                </a>
                            </div>}
                        </div>
                    </div>
                </div>
                <a className="btn btn-dark btn-dark-primary rounded-3 mx-auto d-table mt-4" href={'/events/leading-with-data'} target='_blank' id='eventsLWDExploreCTA'>Explore More Sessions</a>
            </div>
        </section>}
        <Suspense fallback={<div>Loading</div>}>
            <EventHomeSwiperComponent2 />
        </Suspense>
        <section className='common-style background-dark-primary clearfix hover-state d-none' id='moneyGuarantee'>
            <div className='container'>
            <div className='row background-dark-secondary p-5 rounded-4 px-2 px-md-5'>
                <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
                    <div className='text-white heading-with-text mb-5'>
                        <h2 className='fs-40 fw-normal mb-3 fs-18'>Become a Speaker
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M6.67188 15.9995H25.3385" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16 6.66602L25.3333 15.9993L16 25.3327" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </h2>
                        <p className='text-dark-tertiary'>Convey your vision, drive change, and make an impact. Join us as a speaker to lead the conversation at our premier event</p>
                    </div>
                    <div className='d-flex flex-wrap'>
                        <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Knowledge Sharing</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Community Contribution</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Increased Visibility</span>
                            </li>
                        </ul>
                        <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Thought Leadership</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Feedback & Engagement</span>
                            </li>
                            <li className='mb-4 d-flex'>
                                <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                </svg>
                                <span className='overflow-hidden fs-18 ms-1'>Networking Opportunities</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
                    <img className='img-fluid rounded-2 d-table mx-auto' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/become_a_speaker.png?format=webp&width=648"} alt="" />
                </div>
            </div>
            </div>
        </section>
        <Suspense fallback={<div>Loading</div>}>
            <EventHomeSwiperComponent3 />
        </Suspense>
        <section className="seo-links background-dark-secondary d-none" id='seoFooter'>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                        <h2 className='text-white fs-24 mb-3'></h2>
                        <ul className='list-style-none mb-5'>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        <li className='fs-16 text-white mb-3'></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Footer platform={"newevents_home"}/>
        <Cookies platform={'newevents'}  />
        <Flashstrip />
    </>
    
    
  )
}

const mapStateToProps = (state) => ({
	eventList:state.eventContentReducer.eventList,
    leadingwithdataList:state.leadingWithDataContentReducer.leadingwithdataList,
});

const mapDispatchToProps = dispatch=>({
    getEventListDataHandler:data=>{dispatch(getEventListData(data))},
    getLeadingWithDataListDataHandler:data=>{dispatch(getLeadingWithDataListData(data))},
});

export default connect(mapStateToProps,mapDispatchToProps)(EventHome);

import React, { Suspense, useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import Pagination from '../../../common/Page/Pagination';
import { getBlogathonListData, getClosedTournamentByPageNumberData } from '../../../../store/Actions/Action';
import { convertDateInFormat, convertDateToLocalDatetime, scrollTop } from '../../../../utils';
import Loader from '../../../common/Loader';
import Cookies from '../../../common/Footer/Cookies';
import Flashstrip from '../../../common/Header/Flashstrip';

const BlogathonListingPageSwiperComponent = React.lazy(() => import("../components/BlogathonListingPageSwiperComponent"));


const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

const BlogathonListingPage = ({activeBlogathonTournament,closedBlogathonTournament,fetchBlogathonListContentSuccessHandler,getClosedTournamentByPageNumberDataHandler}) => {

    const [showLoader,setShowLoader] = useState(false);

    let updateClosedPageNumber = (page_number)=>{
        setShowLoader(true);
        let tempdata ={
            tournament:"blogathon",
            page_number:page_number,
            limit:9,
            setShowLoader
        }
        getClosedTournamentByPageNumberDataHandler(tempdata);
    }

    useEffect(()=>{
        scrollTop();
        fetchBlogathonListContentSuccessHandler();
    },[])

  return (
    <>
        {showLoader && <Loader/>}
        <Header />
        <section id="heroEventListing" className='common-style pb-5 background-dark-primary'>
            <div className="container">
                <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item text-dark-tertiary"><a href="/datahack" className='text-dark-tertiary text-decoration-none'>Competitions</a></li>
                        <li class="breadcrumb-item active text-white" aria-current="page">Blogathon</li>
                    </ol>
                </nav>
                {activeBlogathonTournament && activeBlogathonTournament.tournamentData && activeBlogathonTournament.tournamentData.length>0 && activeBlogathonTournament.tournamentData.map((active,index)=>(
                <a className='text-decoration-none' href={`/datahack/contest/${active.slug}`} key={index}>
                    <div className="banner-image-wrapper">
                        <img id={active.slug.replace("-","")} className='img-fluid rounded-3' src={active.bannerImage} width="1320" alt="Active Blogathon Banner" />
                    </div>
                </a>
                ))}
            </div>
        </section>
        <section className='common-card-style background-dark-primary' id='compete'>
        <div className="container">
            <h1 className='fs-40 text-white pb-4'>Past Blogathons</h1>
            <div className="row">
            {closedBlogathonTournament && closedBlogathonTournament.tournamentData && closedBlogathonTournament.tournamentData.length>0 && closedBlogathonTournament.tournamentData.map((closed,index)=>(
                <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
                    <div id={closed.slug.replace("-","")} className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-secondary text-white rounded-3'>
                        <a href={"/datahack/contest/"+closed.slug} rel="noreferrer" >
                        <div className='card-img position-relative overflow-hidden rounded-2'>
                            <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={closed.thumbnailImage} alt="Card cap" loading="lazy"  width={392} height={220}  />
                            <button className='d-none btn bookmark-btn position-absolute rounded-circle d-flex align-items-center justify-content-center'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M3.33334 3.66675V17.5244C3.33334 17.8961 3.72449 18.1378 4.05694 17.9716L9.10558 15.4473C9.66863 15.1658 10.3314 15.1658 10.8944 15.4473L15.9431 17.9716C16.2755 18.1378 16.6667 17.8961 16.6667 17.5244V3.66675C16.6667 2.56218 15.7712 1.66675 14.6667 1.66675H5.33334C4.22877 1.66675 3.33334 2.56218 3.33334 3.66675Z" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                            </button>
                        </div>
                        </a>
                        <div className='card-body px-0'>
                        <div className='card-body-top d-flex align-items-center justify-content-between'>
                            <p className='fs-14 mb-3 fw-light text-dark-tertiary'>
                            <span>Started on </span>
                            {convertDateInFormat(convertDateToLocalDatetime(closed.startDate),"DD MMM YYYY")} -
                             Ended on {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"DD MMM YYYY")}
                            {/* <svg className='mx-2' xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                <circle cx="3" cy="3" r="3" fill="#8E8E8E"/>
                            </svg>
                            {convertDateInFormat(convertDateToLocalDatetime(closed.endDate),"hh:mma")} */}
                            </p>
                        </div>
                        <a href={"/datahack/contest/"+closed.slug} className='text-decoration-none'><h2 className="card-text text-white fs-20 mb-3 text-truncate-2">{closed.title}</h2></a>
                        <h3 className='text-white fs-18 fw-normal d-flex'>
                            <svg className='me-2 col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.75 6H19.5V5.25C19.5 4.85218 19.342 4.47064 19.0607 4.18934C18.7794 3.90804 18.3978 3.75 18 3.75H6C5.60218 3.75 5.22064 3.90804 4.93934 4.18934C4.65804 4.47064 4.5 4.85218 4.5 5.25V6H2.25C1.85218 6 1.47064 6.15804 1.18934 6.43934C0.908035 6.72064 0.75 7.10218 0.75 7.5V9C0.75 9.99456 1.14509 10.9484 1.84835 11.6517C2.19657 11.9999 2.60997 12.2761 3.06494 12.4645C3.51991 12.653 4.00754 12.75 4.5 12.75H4.84219C5.28398 14.1501 6.12634 15.39 7.26516 16.3166C8.40398 17.2431 9.78933 17.8157 11.25 17.9634V20.25H9C8.80109 20.25 8.61032 20.329 8.46967 20.4697C8.32902 20.6103 8.25 20.8011 8.25 21C8.25 21.1989 8.32902 21.3897 8.46967 21.5303C8.61032 21.671 8.80109 21.75 9 21.75H15C15.1989 21.75 15.3897 21.671 15.5303 21.5303C15.671 21.3897 15.75 21.1989 15.75 21C15.75 20.8011 15.671 20.6103 15.5303 20.4697C15.3897 20.329 15.1989 20.25 15 20.25H12.75V17.9606C15.7444 17.6578 18.2288 15.5569 19.1325 12.75H19.5C20.4946 12.75 21.4484 12.3549 22.1516 11.6517C22.8549 10.9484 23.25 9.99456 23.25 9V7.5C23.25 7.10218 23.092 6.72064 22.8107 6.43934C22.5294 6.15804 22.1478 6 21.75 6ZM4.5 11.25C3.90326 11.25 3.33097 11.0129 2.90901 10.591C2.48705 10.169 2.25 9.59674 2.25 9V7.5H4.5V10.5C4.5 10.75 4.51219 11 4.53656 11.25H4.5ZM18 10.4156C18 13.7456 15.3291 16.4756 12.0459 16.5H12C10.4087 16.5 8.88258 15.8679 7.75736 14.7426C6.63214 13.6174 6 12.0913 6 10.5V5.25H18V10.4156ZM21.75 9C21.75 9.59674 21.5129 10.169 21.091 10.591C20.669 11.0129 20.0967 11.25 19.5 11.25H19.4531C19.4839 10.9729 19.4995 10.6944 19.5 10.4156V7.5H21.75V9Z" fill="white"/>
                            </svg>
                            <p dangerouslySetInnerHTML={{ __html:closed.prize}}></p>
                        </h3>
                        <button type="button" className="btn btn-secondary btn-sm me-3 border-0 fs-14 fw-light mb-2  no-pointer d-inline-flex align-items-center">
                            <svg className='me-1' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 12 12" fill="none">
                                <path d="M6 7C7.38071 7 8.5 5.88071 8.5 4.5C8.5 3.11929 7.38071 2 6 2C4.61929 2 3.5 3.11929 3.5 4.5C3.5 5.88071 4.61929 7 6 7ZM6 7C3.79086 7 2 8.34315 2 10M6 7C8.20914 7 10 8.34315 10 10" stroke="white" stroke-linecap="round"/>
                            </svg>
                            {closed.registrationCount}
                        </button>
                        </div>

                        <div className='card-footer border-0 px-0'>
                            <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={"/datahack/contest/"+closed.slug} rel="noreferrer" disabled >
                            Event Closed
                            </a>
                        </div>
                    </div>
                </div>
            ))}
            </div>
            {closedBlogathonTournament && <Pagination updatePageNumber={updateClosedPageNumber} last_page={closedBlogathonTournament && closedBlogathonTournament.totalPages}/>}
        </div>
        </section>
        <Suspense fallback={<div>Loading</div>}>
            <BlogathonListingPageSwiperComponent />
        </Suspense>
        <Cookies platform={'newcompetition'}  />
        <Footer />
        <Flashstrip />
    </>
  )
}

const mapStateToProps = (state) => ({
    activeBlogathonTournament:state.competitionContentReducer.activeBlogathonTournament,
    closedBlogathonTournament:state.competitionContentReducer.closedBlogathonTournament,
});

const mapDispatchToProps = dispatch=>({
    getClosedTournamentByPageNumberDataHandler:data=>{dispatch(getClosedTournamentByPageNumberData(data))},
    fetchBlogathonListContentSuccessHandler:data=>{dispatch(getBlogathonListData(data))}
});

export default connect(mapStateToProps,mapDispatchToProps)(BlogathonListingPage);
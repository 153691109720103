import React, { useState } from 'react';
import { getQueryDataElevateUser } from "../../../../utils";
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const LeadConversion = ({csrf,islogin}) => {
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    const [program, setProgram] = useState("");
    const [email, setEmail] = useState("");
    const [price, setPrice] = useState();
    const [currency, setCurrency] = useState("");

    const resetFormFields = () => {
        setEmail("");
        setProgram("");
        setCurrency("");
        setPrice("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let mutation = `mutation 
        {
            convertedLead(
              email: "${email}"
              platformName: "${program}"
              currency: "${currency}"
              price: ${price}
            ) {
              message
              success
            }
        }
        `
        getQueryDataElevateUser(mutation, "newmarketingpages", "True", csrf)
        .then((result)=> {
            console.log(result);
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage("Network response was not ok.");
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error);
        });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Converted successfully"} />}
            <section id='createLeadEntry' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Converted Lead Entry Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        
                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Program <sup className='text-danger'>*</sup></label>
                                            <select
                                            name="program"
                                            className="form-select"
                                            value={program}
                                            onChange={(event) => setProgram(event.target.value)}
                                            required
                                            >
                                                <option value="">Select Program</option>
                                                <option value="blackbelt">Blackbelt</option>
                                                <option value="genaipinnacle">GenAI</option>
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Email <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="email"
                                            className='form-control'
                                            placeholder='Email'
                                            value={email}
                                            onChange={(event) => setEmail(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Price <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="number"
                                            className='form-control'
                                            placeholder='Price'
                                            value={price}
                                            onChange={(event) => setPrice(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className='form-group mb-3'>
                                            <label className='text-white'>Currency <sup className='text-danger'>*</sup></label>
                                            <select
                                            name="currency"
                                            className="form-select"
                                            value={currency}
                                            onChange={(event) => setCurrency(event.target.value)}
                                            required
                                            >
                                                <option value="">Select Currency</option>
                                                <option value="INR">INR</option>
                                                <option value="USD">USD</option>
                                            </select>
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Submit
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadConversion);

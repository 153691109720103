import React, { useEffect, useState } from 'react'
import { getQueryDataElevateUser, getTimeInUTCFormat } from '../../../../utils';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

function CloneTournament({csrf}) {
  const { tournamentId } = useParams();
  const [stageData,setStageData] =  useState(null);

  const [title, setTitle] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  useEffect(()=>{
    if(csrf){
      const query = `
      query MyQuery {
        allGetCloneData(tournamentId: "${tournamentId}") {
          tournamentId
          stages {
            problemStatementId
            quizId
            stageId
            stageType
          }
        }
      }`;
      getQueryDataElevateUser(query, "newcompetition", "True",csrf)
      .then((data)=>{
        let tempData = data.data.allGetCloneData.stages;
        tempData = tempData.map((obj)=>{
          return {...obj,isClone:true};
        })
        setStageData(tempData)
      })
      .catch((error)=>{
          console.log(error);
      })
    }
  },[csrf]);

  const cloneTournament = (e)=>{
    e.preventDefault();
    let clonedStage = stageData.filter((obj)=>{return obj.isClone == true});
    if(clonedStage.length == 0 ){
      alert("Unable to clone: No stage is available to be cloned, or an incorrect tournament has been selected. Please verify your selection and try again.");
      return false;
    }
    clonedStage = clonedStage.map((obj)=>{
      delete obj.isClone;
      obj.startDate = getTimeInUTCFormat(obj.startDate);
      obj.endDate = getTimeInUTCFormat(obj.endDate);
      return obj
    })
    const formattedArrayList = "["+clonedStage.map(obj => {
      return "{"+Object.keys(obj)
        .map(key => `${key}:"${obj[key]}"`)+"}";
    })+"]";

    const query = `
      mutation MyMutation {
        createCloneTournament(
          tournament: {endDate: "${getTimeInUTCFormat(endTime)}", startDate: "${getTimeInUTCFormat(startTime)}", title: "${title}", tournamentId: "${tournamentId}", tournamentType: "hackathon", stages:${formattedArrayList}}
        ) {
          success
        }
      }`;
      getQueryDataElevateUser(query, "newcompetition", "True",csrf)
      .then((data)=>{
        window.location.href = "/av-admin/datahack-admin/tournament";
      })
      .catch((error)=>{
          console.log(error);
      })
  }

  const updateStageData = (index,key,value)=>{
    var tempData = stageData;
    tempData[index][key] = value;
    setStageData([...tempData]);
  }

  useEffect(()=>{
    console.log(stageData)
  },[stageData])
 
  return (
    <>
      <Header />
      <section id="tournament" className='background-dark-primary min-vh-100 common-style'>
        <div className="container">
          <div className="row">
              <div className="card border-0 shadow background-dark-tertiary">
                <div className="card-body">
                  <h1 className='text-white text-center'>Clone the Tournament</h1>
                  <form onSubmit={cloneTournament}>
                    <div className="form-group mb-3">
                        <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                        <input 
                        type="text" 
                        className='form-control background-dark-tertiary text-white' 
                        placeholder='Title' 
                        value={title}
                        onChange={(event)=>setTitle(event.target.value)}
                        required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                        <input 
                        type="datetime-local" 
                        className='form-control background-dark-tertiary text-white' 
                        placeholder='Start Date' 
                        value={startTime}
                        onChange={(e)=>setStartTime(e.target.value)}
                        required
                        />
                    </div>
                    <div className="form-group mb-3">
                        <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                        <input 
                        type="datetime-local" 
                        className='form-control  background-dark-tertiary text-white' 
                        placeholder='End Date' 
                        value={endTime}
                        onChange={(e)=>setEndTime(e.target.value)}
                        required
                        />
                    </div>
                    {stageData?.map((obj,index)=>{
                      return(
                      <div className='mt-5'>
                        <div className="form-group mb-3">
                          <label className="me-3 text-white fw-bold">Stage {obj.stageId}:</label>
                          <input
                              type="checkbox"
                              className='form-check-input d-none'
                              checked={obj.isClone}
                              onChange={(event) => updateStageData(index,"isClone",event.target.checked)}
                          />
                        </div>
                        {obj.isClone && obj.stageType == "practice" && <div className="form-group mb-3">
                          <label className="text-white">Problem Statement Title <sup className='text-danger'>*</sup></label>
                          <input 
                          type="text" 
                          className='form-control background-dark-tertiary text-white' 
                          placeholder='Title' 
                          value={obj.title}
                          onChange={(event)=>updateStageData(index,"title",event.target.value)}
                          required
                          />
                        </div>}
                        {obj.isClone && <div className="form-group mb-3">
                            <label className="text-white">Start Date <sup className='text-danger'>*</sup></label>
                            <input 
                            type="datetime-local" 
                            className='form-control background-dark-tertiary text-white' 
                            placeholder='Start Date' 
                            value={obj.startDate}
                            onChange={(event)=>updateStageData(index,"startDate",event.target.value)}
                            required
                            />
                        </div>}
                        {obj.isClone && <div className="form-group mb-3">
                            <label className="text-white">End Date <sup className='text-danger'>*</sup></label>
                            <input 
                            type="datetime-local" 
                            className='form-control background-dark-tertiary text-white' 
                            placeholder='End Date' 
                            value={obj.endDate}
                            onChange={(event)=>updateStageData(index,"endDate",event.target.value)}
                            required
                            />
                        </div>}
                      </div>)
                    })}
                    <button className='btn btn-dark btn-dark-primary d-table mx-auto' type='submit'>Submit</button>
                  </form>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = (state) => ({
  csrf:state.LoginModalReducer.csrf,
  islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CloneTournament);
import React, { useEffect } from 'react';
import $ from 'jquery';
import Player from '@vimeo/player';

const cloudflare_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL;

export default function BlackbeltVideoModal({videoUrl}) {
    
    useEffect(()=>{
        $(document).ready(function(){
            $('.video-modal .btn-close').click((e)=>{
                $($(e.target).closest('.video-modal').find('video')[0]).trigger('pause');
            });
            window.$('.video-modal').on('shown.bs.modal',(e)=>{
                $($(e.target).closest('.video-modal').find('video')[0]).trigger('play');
            })
        });
    },[]);

    useEffect(()=>{
        window.$('.video-modal').on('hidden.bs.modal', function (e) {
            if($(e.target).find("iframe")[0]) {
                var player = new Player($(e.target).find("iframe")[0]);
                player.pause();
            }
        })

        window.$('.video-modal').on('shown.bs.modal', function (e) {
            if($(e.target).find("iframe")[0]){
                var player = new Player($(e.target).find("iframe")[0]);
                setTimeout(() => {
                    player.play();
                }, 400);
            }
        })
    },[])

    useEffect(()=>{
        if(videoUrl){
            var player = new Player($("#dynamicVideoModal #modalDynamicIframe")[0]);

            window.$('#dynamicVideoModal').on('hidden.bs.modal', function () {
                    player.pause();
            })

            window.$('#dynamicVideoModal').on('shown.bs.modal', function () {
                setTimeout(() => {
                    player.play();
                }, 400);
            })
        }
    },[videoUrl])

  return (
    <>
        <div class="modal fade video-modal" id="kunalVideoModal" tabindex="-1" aria-labelledby="kunalVideoModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <button type="button" class="btn-close btn-close-white fs-4 p-3 position-absolute z-3 end-0" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <div class="ratio ratio-16x9">
                            <video id='modalVideo' controls>
                                <source src={cloudflare_url+'blackbelt/video/blackbelt_kunal_video.mp4'} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade video-modal" id="feedbackVideoModal" tabindex="-1" aria-labelledby="hitiVideoModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <button type="button" class="btn-close btn-close-white fs-4 p-3 position-absolute z-3 end-0" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body p-0">
                        <div class="ratio ratio-16x9">
                            <video id='modalVideo0' controls>
                                <source src={cloudflare_url+'blackbelt/video/HITI NEW THEME.mp4'} type="video/mp4" />
                                Your browser does not support HTML video.
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade video-modal" id="dynamicVideoModal" tabIndex="-1" aria-labelledby="dynamicVideoModalLabel" aria-hidden="true">
            <button type="button" className="btn-close btn-close-white fs-4 p-3 position-absolute z-3 end-0" data-bs-dismiss="modal" aria-label="Close"></button>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <div className="ratio ratio-16x9">
                            {videoUrl && <iframe src={videoUrl} id="modalDynamicIframe" title="Dynamic Video" allow="autoplay"></iframe>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Header from '../../../common/Header/Header';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataElevate, getQueryDataElevateUser, trimNewlines } from '../../../../utils';

const UpdateDatahour = ({ csrf }) => {

    const [sessionName, setSessionName] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [description, setDescription] = useState("");
    const [registrationDisabled, setRegistrationDisabled] = useState(false);
    const [autoRegister, setAutoRegister] = useState(false);
    const [adminApproved, setAdminApproved] = useState(false);
    const [isFeatured, setIsFeatured] = useState(false);
    const [zoomlink, setZoomlink] = useState("");
    const [webinarId, setWebinarId] = useState("");
    const [webinarPasscode, setWebinarPasscode] = useState("");

    const [sessionType, setSessionType] = useState("");

    const [ogImage, setOgImage] = useState(null);
    const [sessionIcon, setSessionIcon] = useState(null);
    const [sessionCoverPic, setSessionCoverPic] = useState(null);
    const [ogImageURL, setOgImageURL] = useState("");
    const [sessionIconURL, setSessionIconURL] = useState("");
    const [sessionCoverPicURL, setSessionCoverPicURL] = useState("");
    const ogImageRef = useRef(null);
    const sessionIconRef = useRef(null);
    const sessionCoverPicRef = useRef(null);
    const [metatitle, setMetaTitle] = useState("");
    const [metadescription, setMetaDescription] = useState("");

    const [speakerId, setSpeakerId] = useState();
    const [speaker, setSpeaker] = useState([]);

    const [categoryId, setCategoryId] = useState();
    const [category, setCategory] = useState([]);

    const [priority, setPriority] = useState("");

    const [iserror, setIsError] = useState(false);
    const [errorMessage,setErrorMessage] = useState("");
    const [issuccess, setIsSuccess] = useState(false);

    const { id, slug } = useParams();

    useEffect(() => {
        const query = `
        query MyQuery {
            datahourSession(sessionSlug: "${slug}") {
                session {
                    sessionName
                    sessionType
                    speaker {
                        id
                        name
                    }
                    startDateTime
                    endDateTime
                    description
                    ogImage
                    ogImageUrl
                    sessionIcon
                    sessionIconUrl
                    sessionCoverPic
                    sessionCoverPicUrl
                    registrationDisabled
                    autoRegister
                    priorityIndex
                    metaTitle
                    metaDescription
                    category {
                        name
                        id
                    }
                    adminApproved
                    isFeatured
                    zoomlink
                    webinarId
                    webinarPasscode
                }
            }
            datahourCategories {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            speakers {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }`

        getQueryDataElevate(query, "newevents", "True")
            .then((data) => {
                setSessionName(data.data.datahourSession.session.sessionName);
                setDescription(data.data.datahourSession.session.description);
                setRegistrationDisabled(data.data.datahourSession.session.registrationDisabled);
                setAutoRegister(data.data.datahourSession.session.autoRegister);
                setAdminApproved(data.data.datahourSession.session.adminApproved);
                setIsFeatured(data.data.datahourSession.session.isFeatured);
                setZoomlink(data.data.datahourSession.session.zoomlink);
                setWebinarId(data.data.datahourSession.session.webinarId);
                setWebinarPasscode(data.data.datahourSession.session.webinarPasscode);
                setEndTime(convertDateInFormat(convertDateToLocalDatetime(data.data.datahourSession.session.endDateTime), "YYYY-MM-DDTHH:mm:ss"));
                setStartTime(convertDateInFormat(convertDateToLocalDatetime(data.data.datahourSession.session.startDateTime), "YYYY-MM-DDTHH:mm:ss"));
                setOgImageURL(data.data.datahourSession.session.ogImageUrl);
                setSessionIconURL(data.data.datahourSession.session.sessionIconUrl);
                setSessionCoverPicURL(data.data.datahourSession.session.sessionCoverPicUrl);
                setMetaTitle(data.data.datahourSession.session.metaTitle);
                setMetaDescription(data.data.datahourSession.session.metaDescription);

                setCategory(data.data.datahourCategories.edges.map(edge => edge.node));
                setCategoryId(data.data.datahourSession.session.category.id);

                setPriority(data.data.datahourSession.session.priorityIndex);

                setSpeaker(data.data.speakers.edges.map(edge => edge.node));
                setSpeakerId(data.data.datahourSession.session.speaker.id);

                setSessionType(data.data.datahourSession.session.sessionType);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [slug]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const startDate = new Date(startTime);
        const endDate = new Date(endTime);

        const startOffset = startDate.getTimezoneOffset(); 
        startDate.setMinutes(startDate.getMinutes() - startOffset - 330); 

        const endOffset = endDate.getTimezoneOffset();
        endDate.setMinutes(endDate.getMinutes() - endOffset - 330); 

        const formattedStartDate = startDate.toISOString();
        const formattedEndDate = endDate.toISOString();
        
        const htmlDescription = trimNewlines(description);

        var formdata = new FormData();

        if (!ogImageRef.current.files.length > 0 && !sessionIconRef.current.files.length > 0 && !sessionCoverPicRef.current.files.length > 0) {
            let query = `mutation {
                updateSession(
                    id: ${id}
                    sessionName: "${sessionName}"
                    sessionType: "${sessionType}"
                    speakerId: ${speakerId}
                    startDateTime: "${formattedStartDate}"
                    endDateTime: "${formattedEndDate}"
                    description: """${htmlDescription}"""
                    categoryId: ${categoryId} 
                    adminApproved: ${adminApproved}
                    isFeatured: ${isFeatured}
                    zoomlink: "${zoomlink}"
                    webinarId: "${webinarId}"
                    webinarPasscode: "${webinarPasscode}",
                    priorityIndex: ${priority !== "" ? priority : 1}
                    metaDescription: "${metadescription}"
                    metaTitle: "${metatitle}"
                    autoRegister: ${autoRegister}
                ) {
                    session {
                        id
                        sessionName
                        sessionType
                        isFeatured
                    }
                }
            }
            `;
            getQueryDataElevateUser(query, "newevents", "True", csrf)
                .then((data) => {
                    if (!data.errors) {
                        setIsSuccess(true);
                        setTimeout(() => {
                            setIsSuccess(false);
                        }, 3000)
                    }
                    else {
                        setIsError(true);
                        setErrorMessage(data.errors[0].message);
                        setTimeout(() => {
                            setIsError(false);
                        }, 3000);
                    }
                })
                .catch(error => console.log('error', error));
            return false;
        }

        let mutationString = `
            mutation UpdateSession($id: Int!, $sessionName: String, $sessionType: String, $speakerId: Int, $startDateTime: DateTime, $endDateTime: DateTime, $description: String, $categoryId: Int, $ogImage: Upload, $sessionIcon: Upload, $sessionCoverPic: Upload, $zoomlink: String!, $webinarId: String, $webinarPasscode: String, $priorityIndex: Int) { updateSession(id: $id, sessionName: $sessionName, sessionType: $sessionType, speakerId: $speakerId, startDateTime: $startDateTime, endDateTime: $endDateTime, description: $description, registrationDisabled: ${registrationDisabled},metaDescription: "${metadescription}", metaTitle: "${metatitle}", autoRegister: ${autoRegister}, categoryId: $categoryId, adminApproved: ${adminApproved}, ogImage: $ogImage, sessionIcon: $sessionIcon, sessionCoverPic: $sessionCoverPic, isFeatured: ${isFeatured}, zoomlink: $zoomlink, webinarId: $webinarId, webinarPasscode: $webinarPasscode, priorityIndex: $priorityIndex) { session { id sessionName sessionType speaker { id userId name companyLogo } startDateTime endDateTime description registrationDisabled autoRegister category { id name } adminApproved ogImage sessionIcon sessionCoverPic isFeatured } message } }
        `;

        let variables = {
            id: parseInt(id),
            sessionName: sessionName,
            sessionType: sessionType,
            speakerId: parseInt(speakerId),
            startDateTime: formattedStartDate,
            endDateTime: formattedEndDate,
            description: htmlDescription,
            categoryId: parseInt(categoryId),
            zoomlink: zoomlink,
            webinarId: parseInt(webinarId),
            webinarPasscode: webinarPasscode,
            priorityIndex: priority !== "" ? parseInt(priority) : 1,
            ...(ogImageRef.current.files[0] && { ogImage: null }),
            ...(sessionIconRef.current.files[0] && { sessionIcon: null }),
            ...(sessionCoverPicRef.current.files[0] && { sessionCoverPic: null }),
        }

        const map = {};

        if (ogImageRef.current.files.length > 0) {
            formdata.append("0", ogImageRef.current.files[0]);
            map["0"] = ["variables.ogImage"]
        }
        if (sessionIconRef.current.files.length > 0) {
            formdata.append("1", sessionIconRef.current.files[0]);
            map["1"] = ["variables.sessionIcon"];
        }
        if (sessionCoverPicRef.current.files.length > 0) {
            formdata.append("1", sessionCoverPicRef.current.files[0]);
            map["1"] = ["variables.sessionCoverPic"];
        }
        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 5000);
                    return false;
                }
                return response.json();
            })
            .then((data) => {
                if (!data.errors) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else {
                    setIsError(true);
                    setErrorMessage(data.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => console.log('error', error));
        return false;

    }

    const handleOgImageUpload = () => {
        const file = ogImageRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setOgImage(imageURL);
        } else {
            setOgImage("");
        }
    }

    const handleSessionIconUpload = () => {
        const file = sessionIconRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setSessionIcon(imageURL);
        } else {
            setSessionIcon("");
        }
    }

    const handleSessionCoverPicUpload = () => {
        const file = sessionCoverPicRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setSessionCoverPic(imageURL);
        } else {
            setSessionCoverPic("");
        }
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Your Datahour updated successfully"} />}
            <section id='updateDatahour' className="min-vh-100 background-dark-primary common-style">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold">Update Datahour</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Session Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Session Name'
                                                value={sessionName}
                                                onChange={(event) => setSessionName(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="sessionType"
                                                className="form-select"
                                                value={sessionType}
                                                onChange={(event) => setSessionType(event.target.value)}
                                                required
                                            >
                                                <option value="" disabled>Select Session Type</option>
                                                {["public", "private"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Speaker <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="speakerId"
                                                className="form-select"
                                                value={speakerId}
                                                onChange={(event) => setSpeakerId(event.target.value)}
                                                required
                                            >
                                                <option value="" disabled>Select Speaker</option>
                                                {speaker.map((val) => (
                                                    <option key={val.id} value={val.id}>
                                                        {val.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Start Date</label>
                                            <input
                                                type="datetime-local"
                                                className='form-control'
                                                placeholder='Start Date'
                                                value={startTime}
                                                onChange={(e) => setStartTime(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">End Date</label>
                                            <input
                                                type="datetime-local"
                                                className='form-control'
                                                placeholder='End Date'
                                                value={endTime}
                                                onChange={(e) => setEndTime(e.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description (HTML Field)</label>
                                            <textarea
                                                className='form-control'
                                                value={description}
                                                onChange={(event) => setDescription(event.target.value)}
                                                style={{ height: '100px' }}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">OG Image:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="ogImage"
                                                ref={ogImageRef}
                                                onChange={handleOgImageUpload}
                                            />
                                            {ogImageURL && (
                                                <div className='mt-1'>
                                                    <img src={ogImage} alt="Company Logo" className='img-fluid' />
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Icon:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="sessionIcon"
                                                ref={sessionIconRef}
                                                onChange={handleSessionIconUpload}
                                            />
                                            {sessionIconURL && (
                                                <div className='mt-1'>
                                                    <img src={sessionIcon} alt="Company Logo" className='img-fluid' />
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Session Cover Pic:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="sessionCoverPic"
                                                ref={sessionCoverPicRef}
                                                onChange={handleSessionCoverPicUpload}
                                            />
                                            {sessionCoverPicURL && (
                                                <div className='mt-1'>
                                                    <img src={sessionCoverPic} alt="Company Logo" className='img-fluid' />
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Registration Disabled:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={registrationDisabled}
                                                onChange={(event) => setRegistrationDisabled(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Autoregister:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={autoRegister}
                                                onChange={(event) => setAutoRegister(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Datahour Category <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="categoryId"
                                                className="form-select"
                                                value={categoryId}
                                                onChange={(event) => setCategoryId(event.target.value)}
                                            >
                                                <option value="" disabled>Select Datahour Category</option>
                                                {category.map((val) => (
                                                    <option key={val.id} value={val.id}>
                                                        {val.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Admin Approved:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={adminApproved}
                                                onChange={(event) => setAdminApproved(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Is Featured:</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={isFeatured}
                                                onChange={(event) => setIsFeatured(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Priority</label>
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder='Priority'
                                                value={priority}
                                                onChange={(event) => setPriority(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Zoom link <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Zoom link"
                                                value={zoomlink}
                                                onChange={(event) => setZoomlink(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Webinar Id</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Webinar Id'
                                                value={webinarId}
                                                onChange={(event) => setWebinarId(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Webinar Passcode</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Webinar Passcode'
                                                value={webinarPasscode}
                                                onChange={(event) => setWebinarPasscode(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Title</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Title'
                                                value={metatitle}
                                                onChange={(event) => setMetaTitle(event.target.value)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Meta Description</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Meta Description'
                                                value={metadescription}
                                                onChange={(event) => setMetaDescription(event.target.value)}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Update Datahour
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDatahour);
import React, { useEffect, useRef, useState } from 'react';
import { getQueryDataElevate, getQueryDataElevateUser } from "../../../../utils";
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import { useParams } from 'react-router-dom';


const UpdateRanker = ({ csrf, islogin }) => {
    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    const [name, setName] = useState("");
    const [designation, setDesignation] = useState("");
    const [bio, setBio] = useState("");
    const [photo, setPhoto] = useState('');
    const photoRef = useRef(null);
    const [rankerType, setRankerType] = useState("");

    const { id } = useParams();

    useEffect(() => {
        const query = `
        query {
            topRankerData(id:${id}) {
                id
                bio
                designation
                name
                photo
                rankerType
            }
        }`

        getQueryDataElevate(query, "newcompetition", "True")
            .then((data) => {
                console.log(data.data);
                setName(data?.data?.topRankerData?.name);
                setDesignation(data?.data?.topRankerData?.designation);
                setBio(data?.data?.topRankerData?.bio);
                setPhoto(data?.data?.topRankerData?.photo);
                setRankerType(data?.data?.topRankerData?.rankerType);
            })
            .catch((error) => {
                console.log(error);
            })
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        var formdata = new FormData();

        if (!photoRef.current.files.length > 0) {
            let query = `mutation MyMutation {
                updateTopRanker(
                    id: ${id}
                    name: "${name}"
                    designation: "${designation}"
                    bio: """${bio}"""
                    
                ) {
                    topRanker { 
                        name
                    }
                }
            }`;
            console.log(query);
            getQueryDataElevateUser(query, "newcompetition", "True", csrf)
                .then((data) => {
                    if (!data.errors) {
                        setIsSuccess(true);
                        setTimeout(() => {
                            setIsSuccess(false);
                        }, 3000);
                    }
                    else {
                        setIsError(true);
                        setTimeout(() => {
                            setIsError(false);
                        }, 3000);
                    }
                })
                .catch(error => console.log('error', error));
            return false;
        }

        let mutationString = `mutation MyMutation($photo: Upload,$name: String, $designation: String, $ranker: String)  {updateTopRanker(id:${id},bio: """${bio}""",designation: $designation,name: $name,photo: $photo,rankerType: $ranker) {topRanker {photo,id}}}`;

        let variables = {
            name: name,
            ranker: rankerType,
            designation: designation,
            ...(photoRef.current.files[0] && { photo: null }),
        }

        if (photo) {
            formdata.append("0", photo);
            variables.photo = null;
        }

        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        const map = {};
        if (photoRef.current.files.length > 0) {
            formdata.append("0", photoRef.current.files[0]);
            map["0"] = ["variables.photo"]
        }
        if (Object.keys(map).length > 0) {
            formdata.append("map", JSON.stringify(map));
        }

        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };

        console.log("Formdata", formdata);
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then((result) => {
                console.log(result);
                if (!result.errors) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else {
                    setIsError(true);
                    setMessage(result.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setIsError(true);
                setMessage(error.message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                console.log('error', error)
            });
        return false;
    }

    const handlePhotoUpload = () => {
        const file = photoRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setPhoto(imageURL);
        } else {
            setPhoto("");
        }
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Ranker updated successfully"} />}
            <section id='updateRanker' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Update Ranker</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Name</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Name'
                                                value={name}
                                                onChange={(event) => setName(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Designation</label>
                                            <input
                                                type="text"
                                                className='form-control'
                                                placeholder='Designation'
                                                value={designation}
                                                onChange={(event) => setDesignation(event.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Bio</label>
                                            <textarea
                                                className='form-control'
                                                value={bio}
                                                onChange={(event) => setBio(event.target.value)}
                                                style={{ height: '100px' }}
                                                required
                                            />
                                        </div>

                                        <div class="form-group mb-3">
                                            <label for="allowedExtension" class="form-label text-white">Photo</label>
                                            <input type="file" class="form-control mb-3" id="allowedExtension" accept='image/*' ref={photoRef} onChange={handlePhotoUpload} />
                                            <a target='_blank' className='text-blue' href={photo}>View Image</a>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Ranker Type</label>
                                            <select
                                                name="sessionType"
                                                className="form-select"
                                                value={rankerType}
                                                onChange={(event) => setRankerType(event.target.value)}
                                                required
                                            >
                                                <option value="" disabled>Select Ranker Type</option>
                                                {["HACKER", "AUTHOR"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Update
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
    islogin: state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateRanker);
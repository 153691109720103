import React, { useState } from 'react'
import { connect } from 'react-redux';
import { getQueryDataElevateUser } from '../../../utils';
import { getCommentData, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import $ from 'jquery';

function Reply({comment,islogin,csrf,uniqueId,resourceName,getCommentDataHandler,updateWelcomeLoginModalHandler}) {
    const [newReply, setNewReply] = useState("");

    const sendReplyHandler = (comment_id,e) => {
        e.preventDefault();

        if(!islogin) {
            updateWelcomeLoginModalHandler({welcomeloginmodal:true})
            return;
        }

        if(newReply === ""){
        $('.reply-warning').remove();
        $('#replyTextarea').css("border-color","red");
        $('#replyTextarea').parent().append(`<small class="reply-warning col-12 text-danger">Reply shouldn't be empty</small>`)
        setTimeout(() => {
            $('#replyTextarea').css("border-color","gray");
            $('.reply-warning').slideUp();
            $('.reply-warning').remove();
        }, 2000);
        return false;
        }

        let mutation = `
        mutation {
            createReply(commentId: ${comment_id}, repliedText: """${newReply}""") {
            reply {
                id
                isDeleted
                isEdited
                likedBy
                userId
                repliedText
                replyMentions
                updatedAt
                createdAt
            }
            }
        }
        `;
        getQueryDataElevateUser(mutation, 'newdiscussion', "True", csrf)
        .then((data)=>{
            setNewReply("");
            let tempdata = {
            uniqueId:uniqueId,
            page:1,
            islogin:islogin,
            csrf:csrf,
            resourceName:resourceName
            }
            getCommentDataHandler(tempdata);
            window.$('.collapse').collapse('hide');
        })
        .catch((error)=>{
            console.log(error);
        })
    };
    return (
        <>
            <div className="comment-box background-dark-tertiary mb-3 p-3 rounded-3">
                <textarea class="form-control background-transparent mb-3" id="replyTextarea" placeholder='What are your thoughts?...' value={newReply} onChange={(e)=>{setNewReply(e.target.value)}}></textarea>
                <div className="btn-group-comment d-flex flex-wrap justify-content-end">
                    <button className="btn text-white border-0 text-decoration-underline" onClick={()=>{setNewReply("")}}>Clear</button>
                    <button className="btn btn-dark btn-dark-primary ms-3" onClick={(e)=>{sendReplyHandler(comment.id,e)}}>Submit Reply</button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    islogin:state.LoginModalReducer.islogin,
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
    updateWelcomeLoginModalHandler:data=>dispatch(updateWelcomeLoginModal(data)),
    getCommentDataHandler:data=>dispatch(getCommentData(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(Reply);
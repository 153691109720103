import React, { Suspense, useEffect, useState } from 'react';
import B2Bheader from '../components/B2Bheader';
import Footer from '../../../../common/Footer/Footer';
import $ from 'jquery';
import Modal from '../components/Modal';
import { validatedEmail } from '../../../../../utils/InputValidation';
import { scrollTop, submitRegisterationForm } from '../../../../../utils';
import SuccessAlert from '../../../../common/Alert/SuccessAlert';
import { connect } from 'react-redux';

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

function AnalyticsMaturity({csrf}) {
    const [formData,setFormData] = useState({
        formType:"",
        section:""
    });
    const [email,setEmail] = useState('');
    const [success,setSuccess] = useState(false);
    const [heading,setHeading] = useState(<>Contact us today!</>);
    const [buttonTxt, setButtonTxt] = useState(<>Contact us</>);
    const [message, setMessage] = useState("");

    useEffect(()=>{
        $(document).ready(function() {
            $(window).scroll(function() {
                if ($(this).scrollTop() >= 50) {
                $('.navbar').addClass('active');
                } else {
                $('.navbar').removeClass('active');
                }
            });
        });
    },[]);

    useEffect(()=>{
        $('#experience .nav-link').click((e)=>{
            let height = $('#experience .nav-link.active').index();
            $('#v-pills-tab').css('--height', `${25 * (height+1)}%`);
        })
    },[]);

    const saveNewsletterForm = (e)=>{
        e.preventDefault();

        if(!validatedEmail(email)){
        $('.error').remove();
        $('#newsletterEmail').parent().append(`<small class="text-danger error d-block">Enter a Valid Email Id</small>`);
        $('#newsletterEmail').css('border-color','red');
        $('#newsletterEmail').css('color','red');
        setTimeout(()=>{
            $('#newsletterEmail').css('border-color','#383838');
            $('#newsletterEmail').css('color','white');
            $('.error').slideUp();
            $('.error').remove();
        },2000)
        return false;
        }
    
        let user_data = {
            "fullname":null,
            "email":email,
            "phone":null,
            "country_code":null,
        }
        let additional_detail ={
        "page_url":window.location.href
        }
        setEmail("");
        setSuccess(true);
        setTimeout(()=>{
        setSuccess(false);
        window.open('https://newsletter.ai/',"_blank");
        },4000)
        submitRegisterationForm(user_data,additional_detail,"enterprise-ai-maturity","newsletter",csrf)
        .then((data)=>{
        })
        .catch((error)=>{
        console.log(error)
        // setIsError(true);
        // setTimeout(()=>{
        //   setIsError(false);
        // },4000)
        })
    }

    useEffect(()=>{
        scrollTop();
    },[])

    useEffect(()=>{
        new window.Swiper('.swiper1', {
            loop: true,
            spaceBetween:0,
            slidesPerView:7,
            speed:3000,
            autoplay:{
                delay: 1,
                disableOnInteraction: false,
                pauseOnMouseEnter:true
            },
            breakpoints:{
                0: {
                    slidesPerView: 2,
                },
                599: {
                    slidesPerView: 3,
                },
                1024: {
                    slidesPerView: 4,
                },
                1400: {
                    slidesPerView: 6,
                },
            }
        });
    },[]);

  return (
    <main id='b2b'>
        <B2Bheader />
        {success && <SuccessAlert message={message} />}
        <Modal platform="enterprise-ai-maturity" formtype={formData.formType} section={formData.section} heading={heading} buttonTxt={buttonTxt} message={message}/>
        <section id="b2bHeroFold" style={{backgroundImage: `url('https://imgcdn.analyticsvidhya.com/b2b/ai_maturity_hero.png'), linear-gradient(to right, #1A181B 0 80%, transparent)`}}>
            <div className="container align-items-center h-100">
                <div className="row align-items-center h-100">
                    <div className="col-lg-7 col-md-12 col-12">
                        <h1 className="fs-56  mb-3 fw-semibold">
                            <span className='text-white'>Benchmark & Boost Your </span>
                            <span className="text-gradient">AI Maturity</span>
                        </h1>
                        <p className='text-white fs-20 mb-4'>Benchmark against industry leaders, gain actionable insights and unlock the full potential of AI for your enterprise</p>
                        <button className='btn btn-dark btn-dark-primary me-3 mb-5' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"hero-fold"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
                        <button className='btn btn-dark btn-dark-primary mb-5' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'benchmark',section:"hero-fold"}); setHeading(<>Benchmark your AI Maturity</>); setButtonTxt(<>Assess</>); setMessage("Thank you for your inquiry about our AI maturity Benchmark. We'll get in touch with you soon.")}}>Benchmark now</button>
                        <ul className='list-style-none mt-5 d-flex justify-content-between flex-wrap'>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>10.1 Million+</h2>
                                <p className='text-dark-secondary fs-18'>Professionals benefited</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>350K+</h2>
                                <p className='text-dark-secondary fs-18'>Learners impacted</p>
                            </li>
                            <li className='col-lg-4 mb-4'>
                                <h2 className='text-white fs-24'>400+</h2>
                                <p className='text-dark-secondary fs-18'>Global firms impacted</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className='py-60 background-dark-secondary' id='alumniLogo'>
            <div className='text-white col-lg-8 heading-with-text mb-5 text-center mx-auto'>
            <h2 className='fs-40 fw-normal mb-3'>Trusted by 500+ Enterprises</h2>
            <p className='text-dark-secondary'>Your enterprise could be the next to leverage our proven AI expertise and strong partnership</p>
            </div>
            <div className='swiper1 overflow-hidden'> 
                <div className="swiper-wrapper">
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/ABINBEV-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/AE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/Artboard 23-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/B&C-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/BOSCH-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/CBRE-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/FRACTAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/GENPACT-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/HDFC-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/INFOSYS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/JSL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/L&T-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/LM-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/M&c-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PIRAMAL-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/PS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/RIPIK.AI-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/SB-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/SYNCHRONY-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/TVS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/WNS-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                    <div className='swiper-slide'>
                        <img className='img-fluid d-table mx-auto rounded-2' src={cloudflare_size_url+'b2b/company_logo/ZEPTO-100.jpg?format=webp'} alt="company_logo" />
                    </div>
                </div>
            </div>
        </section>
        <section className='event-tabs py-120 bg-white' id='experience'>
            <div className='container'>
                <div className='row'>
                    <div className="text-white mx-auto text-center col-lg-8 heading-with-text">
                        <h2 className="fs-40 fw-normal mb-3 text-">What is AI Maturity and Why Does it Matter?</h2>
                        <p className="fs-18 fw-normal">AI Maturity signifies your readiness to adopt and excel with AI, enhancing decision-making and driving innovation</p>
                    </div>
                </div>
                <div className="d-flex align-items-start col-lg-11 mx-auto" id='experienceTabs'>
                    <div className="nav nav-4 flex-column nav-pills me-3 col-lg-6" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button className="nav-link active p-4 text-start" id="v-pills-discover-tab" data-bs-toggle="pill" data-bs-target="#v-pills-discover" type="button" role="tab" aria-controls="v-pills-discover" aria-selected="true">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Awareness and Exploration</h3>
                        <p className='fs-16 fw-normal'>We support your team's journey toward data-driven growth, highlighting the transformative power of AI and fostering awareness</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-learn-tab" data-bs-toggle="pill" data-bs-target="#v-pills-learn" type="button" role="tab" aria-controls="v-pills-learn" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Experimentation and Pilot Projects</h3>
                        <p className='fs-16 fw-normal'>We assist your organization in transforming AI concepts into actionable strategies through targeted pilot projects</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-engage-tab" data-bs-toggle="pill" data-bs-target="#v-pills-engage" type="button" role="tab" aria-controls="v-pills-engage" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Operationalization and Integration</h3>
                        <p className='fs-16 fw-normal'>Our support extends to integrating AI across your organization, enhancing efficiency, and scaling operations</p>
                        </button>
                        <button className="nav-link p-4 fw-bold fs-20 text-start" id="v-pills-prescriptive-tab" data-bs-toggle="pill" data-bs-target="#v-pills-prescriptive" type="button" role="tab" aria-controls="v-pills-prescriptive" aria-selected="false">
                        <h3 className='fw-semibold fs-20 mb-3 text-uppercase'>Transformation and Innovation</h3>
                        <p className='fs-16 fw-normal'>We nurture your business's data-driven transformation, promoting sustained innovation and positioning you as an industry leader</p>
                        </button>
                    </div>
                    <div className="tab-content col-lg-6 col-12 rounded-3" id="v-pills-tabContent">
                        <div className="card tab-pane fade show active bg-transparent border-0 m-0" id="v-pills-discover" role="tabpanel" aria-labelledby="v-pills-discover-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-discover">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-discover" data-bs-parent="#experienceTabs" aria-expanded="true" aria-controls="v-collapse-discover">
                                <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Awareness and Exploration</h3>
                                <p className='fs-16 fw-normal pe-4 text-white mb-3'>We support your team's journey toward data-driven growth, highlighting the transformative power of AI and fostering awareness</p>
                                </a>
                            </div>
                            <div id="v-collapse-discover" className="collapse show" role="tabpanel" aria-labelledby="v-collapse-heading-discover" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/data_driven.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card border-0 bg-transparent tab-pane fade" id="v-pills-learn" role="tabpanel" aria-labelledby="v-pills-learn-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-learn">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-learn" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-learn">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Experimentation and Pilot Projects</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>We assist your organization in transforming AI concepts into actionable strategies through targeted pilot projects</p>
                                </a>
                            </div>
                            <div id="v-collapse-learn" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-learn" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/experiment.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-engage" role="tabpanel" aria-labelledby="v-pills-engage-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-engage">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-engage" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-engage">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Operationalization and Integration</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>Our support extends to integrating AI across your organization, enhancing efficiency, and scaling operations</p>
                                </a>
                            </div>
                            <div id="v-collapse-engage" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-engage" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/operations.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                        <div className="card bg-transparent border-0 tab-pane fade" id="v-pills-prescriptive" role="tabpanel" aria-labelledby="v-pills-prescriptive-tab">
                            <div className="card-header border-0" role="tab" id="v-collapse-heading-prescriptive">
                                <a className='text-decoration-none d-block p-4' data-bs-toggle="collapse" href="#v-collapse-prescriptive" data-bs-parent="#experienceTabs" aria-expanded="false" aria-controls="v-collapse-prescriptive">
                                    <h3 className='fw-bold fs-20 mb-3 text-uppercase'>Transformation and Innovation</h3>
                                    <p className='fs-16 fw-normal pe-4 text-white mb-3'>We nurture your business's data-driven transformation, promoting sustained innovation and positioning you as an industry leader</p>
                                </a>
                            </div>
                            <div id="v-collapse-prescriptive" className="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-prescriptive" data-bs-parent="#experienceTabs">
                                <img className='img-fluid ms-lg-auto d-table mx-auto py-4' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/transformation.png?format=webp&width=536'} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
                <button className='btn btn-primary mx-auto d-table py-2 px-4 mt-4 d-none' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'get_demo',section:"hero-fold"}); setHeading(<>Get More Details</>); setButtonTxt(<>Know More</>); setMessage("Thank you for reaching out. We will be in touch soon.")}}>Know more</button>
            </div>
        </section>
        <section className='common-style-py background-dark-secondary'>
            <div className="container">
                <div className="row g-4 align-items-center mb-5">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="text-white mx-auto heading-with-text mb-5">
                            <h2 className="fs-40 fw-normal mb-3">Why AI Maturity is Important?</h2>
                            <p className="fs-18 fw-normal text-dark-secondary">"AI Maturity is essential because it integrates AI deeply into organizational operations, leading to significant enhancements in decision-making, efficiency, innovation, and risk management."</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="image-wrapper">
                            <img className='img-fluid' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/ai_maturity_is_important.png?format=webp'} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row g-4">
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2 className='text-dark-tertiary fs-40 fw-semibold mb-3'>01</h2>
                        <h3 className='text-white fs-24 fw-medium'>Smarter Decisions</h3>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-16'>Mature AI analyzes vast data for clearer insights, guiding informed choices.</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2 className='text-dark-tertiary fs-40 fw-semibold mb-3'>02</h2>
                        <h3 className='text-white fs-24 fw-medium'>Streamlined Efficiency</h3>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-16'>Automates routine tasks, increasing productivity and cost savings.</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2 className='text-dark-tertiary fs-40 fw-semibold mb-3'>03</h2>
                        <h3 className='text-white fs-24 fw-medium'>Competitive Innovation</h3>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-16'>Fosters unique opportunities and solutions, giving a market advantage.</p>
                    </div>
                    <div className="col-lg-3 col-md-6 col-12">
                        <h2 className='text-dark-tertiary fs-40 fw-semibold mb-3'>04</h2>
                        <h3 className='text-white fs-24 fw-medium'>Risk Management</h3>
                        <div className="underline-bold mb-3"></div>
                        <p className='text-dark-secondary fs-16'>Addresses biases and ethical concerns, ensuring safer, reliable AI operations.</p>
                    </div>
                </div>
            </div>
        </section>
        <section className='common-style-py background-light-secondary d-none'>
            <div className="container">
                <div className="row g-4 align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <h2 className='fs-24 fw-normal'>Stay ahead of the curve with top AI stories and insights delivered to your inbox</h2>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <form id='newsletterForm' className='row g-4' onSubmit={saveNewsletterForm}>
                            <div className="col-lg col-md col-12">
                                <input className='form-control h-100 text-dark' type="email" id='newsletterEmail' value={email} onChange={(e)=>{setEmail(e.target.value)}} placeholder='Email address' autoComplete='off' />
                            </div>
                            <div className="col-lg-auto col-md-auto col-12">
                                <button className='btn btn-primary px-4 py-2 w-100'>Sign Me Up</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <section className='py-120 bg-white' id='trainingSolution'>
            <div className='container'>
                <div className="mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Navigating the Path to AI Maturity with Our Offerings</h2>
                    <p className="fs-18 fw-normal">Discover the full potential of your data with AI Maturity solutions tailored for market leaders</p>
                </div>
                <div className='row g-4'>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/ai_maturity_assesment.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>AI Maturity Assessment</h2>
                            <p className='fs-16 text-light-primary'>Assess your AI capabilities and future-proof your strategies with our comprehensive evaluation</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/skillset.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Skillset & Talent Evaluation</h2>
                            <p className='fs-16 text-light-primary'>Uncover the potential within your team and identify crucial skills to advance your AI maturity</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 shadow-sm background-light-secondary p-3 h-100">
                            <div className="image-wrapper mb-3">
                                <img className='card-img-top' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+'b2b/benchmark.png?format=webp'} alt="training solution" />
                            </div>
                            <h2 className='fs-24 fw-regular text-light-primary mb-3'>Benchmarking Services</h2>
                            <p className='fs-16 text-light-primary'>Gain strategic insights by benchmarking your AI practices against industry standards and leaders</p>
                        </div>
                    </div>
                </div>
                <button className="btn btn-primary mx-auto d-table mt-5 d-none" data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"cutting-edge-training-solution"}); setHeading(<>Download Brochure!</>); setButtonTxt(<>Download Brochure</>)}}>Download brochure</button>
            </div>
        </section>
        <section id='b2bPartnerWithUs' className='common-style pb-60 background-light-secondary'>
            <div className="container">
                <div className="text-dark mx-auto text-center col-lg-8 heading-with-text mb-5">
                    <h2 className="fs-40 fw-normal mb-3">Are You Ready to Make Your Organisation AI-ready?</h2>
                    <p className="fs-18 fw-normal">Partner with us to innovate and drive growth with AI</p>
                </div>
                <button className='btn btn-primary mx-auto d-table py-2 px-4' data-bs-toggle="modal" data-bs-target="#b2bModal" onClick={()=>{setFormData({formType:'contact',section:"partner-with-us"}); setHeading(<>Contact us today!</>); setButtonTxt(<>Contact us</>); setMessage("Thank you for reaching out. We'll be in touch soon.")}}>Contact us</button>
            </div>
        </section>
        <Footer platform={"new_b2b"}/>
    </main>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
  });
  
  const mapDispatchToProps = dispatch=>({
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsMaturity);
import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryData } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import $ from 'jquery';

const GenerateHackathonCSV = ({ csrf }) => {
    const [stagesData, setStagesData] = useState(null);
    const [stageId, setStageId] = useState(null);
    const [selectedStageTitle, setSelectedStageTitle] = useState("");

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        let query = `query MyQuery {
            allStage(limit: 100000, page: 1) {
                stageData {
                    id
                    title
                    stageType {
                        name
                    }
                }
            }
        }`;
        getQueryData(query, "newcompetition")
            .then(data => {
                setStagesData(data?.data?.allStage.stageData);
            })
            .catch(error => {
                console.error(error);
            })
    }, [])

    const resetFormFields = () => {
        setSelectedStageTitle("");
        setStageId(null);
        document.getElementById("stageIdValue").value = null;
    }

    const handleStageChange = (event) => {
        if (stagesData.some((stage) => {
            if (stage.id === event.target.value) {
                setSelectedStageTitle(stage.title);
            }
            return stage.id === event.target.value;
        })) {
            setStageId(event.target.value);
        } else {
            setStageId(null);
            setSelectedStageTitle("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        $('#csvGenerateBtn').addClass('d-none');
        $('#generatingBtn').removeClass('d-none');

        const formData = new FormData();
        formData.append("platform", 'newcompetition');
        formData.append("method", 'GET');
        formData.append("forward_user_data", "True");
        formData.append("url", 'download-hackathon-csv/?stage_id='+stageId);
        formData.append("timeout", 1000);
        
        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
        method: 'POST',
        body:formData,
        credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
        headers: {
            "X-CSRFToken":csrf,
        }
        })
        .then(response => {
            $('#csvGenerateBtn').removeClass('d-none');
            $('#generatingBtn').addClass('d-none');
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        })
        .then((result)=> {
            if(result?.response?.status_code === 200){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }   
            else {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
        })
        .catch(error => {
            console.log('error', error);
            $('#csvGenerateBtn').removeClass('d-none');
            $('#generatingBtn').addClass('d-none');
            setIsError(true);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
        });
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Error occured."} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"CSV URL emailed successfully."} />}
            <section id='privateRegistration' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title mb-3 text-center text-white fw-semibold text-uppercase">Generate Hackathon CSV</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <span className=" text-white me-2">Select Stage <sup className='text-danger'>*</sup></span>
                                            <input id="stageIdValue" list="stages" className='form-control' onChange={handleStageChange} placeholder="Select a Stage" required autoComplete='off'/>
                                            <datalist id="stages">
                                                {stagesData && stagesData.map((stage) => (<>
                                                    {(stage?.stageType?.name === "practice" || stage?.stageType?.name === "skill test") && <option key={stage.id} value={stage.id}>
                                                        {stage.title}
                                                    </option>}
                                                    </>
                                                ))}
                                            </datalist>
                                            {selectedStageTitle && <p className="text-white pt-2">Selected Stage Name: <strong>{selectedStageTitle}</strong></p>}
                                        </div>

                                        <button type="submit" id='csvGenerateBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Generate CSV
                                        </button>
                                        <button id='generatingBtn' className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100 d-none' disabled>
                                            Generating CSV...
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateHackathonCSV);
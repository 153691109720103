import React, { Suspense, useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import Footer from '../../../common/Footer/Footer';
import { getEventListData, getPastEventByPageNumberData } from '../../../../store/Actions/Action';
import { connect } from 'react-redux';
import { convertDateInFormat, convertDateToLocalDatetime, scrollTop } from '../../../../utils';
import Pagination from '../../../common/Page/Pagination';
import Cookies from '../../../common/Footer/Cookies';
import Flashstrip from '../../../common/Header/Flashstrip';
import Loader from '../../../common/Loader';
const EventListingPageSwiperComponent = React.lazy(() => import("../components/EventListingPageSwiperComponent"));

const cloudflare_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL;
const cloudflare_size_url = process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE;

const EventListingPage = ({featuredSessionBanner, getEventListDataHandler, eventList, getPastEventByPageNumberDataHandler, pastEventList, pastEventListTotalPages}) => {
    const [isLoader, setIsLoader] = useState(false);

    useEffect(()=>{
        scrollTop();
    },[]);

    useEffect(()=>{
        getEventListDataHandler();
        getPastEventByPageNumberDataHandler(1);

    },[getEventListDataHandler, getPastEventByPageNumberDataHandler]);

    useEffect(()=>{
        console.log("sdhfgsd",featuredSessionBanner);
    },[eventList])

    let updatePastEventPageNumber = (page_number)=>{
        setIsLoader(true);
        getPastEventByPageNumberDataHandler(page_number,setIsLoader);
    }

    return (
        <>
            {isLoader && <Loader />}
            <Header />
            <section id="heroEventListing" className='common-style pb-5 background-dark-primary'>
                <div className="container">
                    <nav className='mb-3 pt-1' style={{"--bs-breadcrumb-divider": '">"'}} aria-label="breadcrumb">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item text-dark-tertiary"><a href="/events" className='text-dark-tertiary text-decoration-none'>Events</a></li>
                            <li class="breadcrumb-item active text-white" aria-current="page">DataHour</li>
                        </ol>
                    </nav>
                    {featuredSessionBanner && featuredSessionBanner[0] && <a href={`/events/datahour/${featuredSessionBanner[0].slug}`} id={`datahour-featured-`+featuredSessionBanner[0].slug}>
                        <div className="banner-image-wrapper">
                        {featuredSessionBanner && featuredSessionBanner.length > 0  && <img className='img-fluid rounded-3' src={featuredSessionBanner[0].sessionCoverPicUrl} width="1320" alt="Featured Session Banner" />}
                        </div>
                    </a>}
                </div>
            </section>
            <section className='av-tab-design' id='tabs'>
                <div className='container'>
                    <div className='row'>
                        <ul class="nav nav-tabs py-4 border-0" id="datahourListTabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="upcoming-events-tab" data-bs-toggle="tab" data-bs-target="#upcoming-events" type="button" role="tab" aria-controls="upcoming-events" aria-selected="true">Upcoming</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="past-events-tab" data-bs-toggle="tab" data-bs-target="#past-events" type="button" role="tab" aria-controls="past-events" aria-selected="false">Closed</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section className="common-style-py common-card-style background-dark-primary">
                <div className="container">
                    <div className="tab-content" id="eventsTabContent">
                        <div className="tab-pane fade show active" id="upcoming-events" role="tabpanel" aria-labelledby="upcoming-events-tab">
                            <div className='row'>
                                {eventList && eventList.map((upcoming,index)=>{
                                    return(
                                        <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4' key={index}>
                                            <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                                                <a href={`/events/datahour/${upcoming.node.slug}`} id={`datahour-upcoming-`+upcoming.node.slug+`CardLink`}>
                                                    <div className="card-img position-relative overflow-hidden rounded-2">
                                                        <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={upcoming.node.sessionIconUrl} alt="Card cap" loading="lazy" width="392" height="220" />
                                                    </div>
                                                </a>
                                                <div className='card-body px-0'>
                                                    <div class="card-body-top d-flex align-items-center">
                                                        <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                        {convertDateInFormat(convertDateToLocalDatetime(upcoming.node.startDateTime),"DD MMM")}
                                                            <svg class="mx-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                                <circle cx="3" cy="3" r="3" fill="#8E8E8E"></circle>
                                                            </svg>
                                                            {convertDateInFormat(convertDateToLocalDatetime(upcoming.node.startDateTime),"hh:mm A")} IST
                                                        </p>
                                                    </div>
                                                    <h3 className='text-white fs-20 mb-3'>{upcoming.node.sessionName}</h3>
                                                    <div className="d-flex flex-wrap my-4">
                                                        <div className="col">
                                                            {upcoming.node.speaker && <p className='text-white mb-0'>{upcoming.node.speaker.name}</p>}
                                                            {upcoming.node.speaker && <p className='text-dark-tertiary mb-0'>{upcoming.node.speaker.designation}</p>}
                                                        </div>
                                                        {upcoming.node.speaker && upcoming.node.speaker.companyLogoUrl && <div class="col-auto d-flex img-row align-items-center">
                                                            {upcoming.node.speaker && <img class="rounded-1 bg-white me-2  no-pointer object-fit-contain" width="80" height="30" src={upcoming.node.speaker.companyLogoUrl} alt={upcoming.node.speaker.companyName} loading="lazy" />}
                                                        </div>}
                                                    </div>
                                                </div>
                                                <div className='card-footer border-0 px-0'>
                                                    <a className="btn btn-dark btn-dark-primary rounded-3 w-100" href={`/events/datahour/${upcoming.node.slug}`} id={`datahour-upcoming-`+upcoming.node.slug+`ParticipateNowCTA`}>
                                                        Participate Now
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="tab-pane fade" id="past-events" role="tabpanel" aria-labelledby="past-events-tab">
                            <div className='row mb-5'>
                                {pastEventList && pastEventList.map((past,index)=>{
                                    return(
                                    <div className='col-sm-12 col-lg-4 col-xl-4 col-md-6 mb-4' key={index}>
                                        <div className='card p-3 shadow-sm h-100 border-0 rounded-2 position-relative background-dark-tertiary text-white rounded-3'>
                                            <a href={`/events/datahour/${past.slug}`} id={`datahour-past-`+past.slug+`CardLink`}>
                                                <div className="card-img position-relative overflow-hidden rounded-2">
                                                    <img className="card-img-top rounded-2 img-fluid object-fit-contain" src={past.sessionIconUrl} alt="Card cap" loading="lazy" width="392" height="220" />
                                                </div>
                                            </a>
                                            <div className='card-body px-0'>
                                                <div class="card-body-top d-flex align-items-center">
                                                    <p class="fs-14 mb-3 fw-light text-dark-tertiary">
                                                    {convertDateInFormat(convertDateToLocalDatetime(past.startDateTime),"DD MMM")}
                                                        <svg class="mx-2" xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                                                            <circle cx="3" cy="3" r="3" fill="#8E8E8E"></circle>
                                                        </svg>
                                                        {convertDateInFormat(convertDateToLocalDatetime(past.startDateTime),"hh:mm A")} IST
                                                    </p>
                                                </div>
                                                <h3 className='text-white fs-20 mb-3'>{past.sessionName}</h3>
                                                <div className="d-flex flex-wrap my-4">
                                                    <div className="col">
                                                        {past.speaker && <p className='text-white mb-0'>{past.speaker.name}</p>}
                                                        {past.speaker && <p className='text-dark-tertiary mb-0'>{past.speaker.designation}</p>}
                                                    </div>
                                                    {past.speaker && past.speaker.companyLogoUrl && <div class="col-auto d-flex img-row align-items-center">
                                                        {past.speaker && <img class="rounded-1 bg-white me-2  no-pointer object-fit-contain" width="80" height="30" src={past.speaker.companyLogoUrl} alt={past.speaker.companyName} loading="lazy" />}
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className='card-footer border-0 px-0'>
                                                <a className="btn btn-dark btn-dark-primary rounded-3 d-flex align-items-center justify-content-center w-100" href={`/events/datahour/${past.slug}`} id={`datahour-past-`+past.slug+`ViewRecordingCTA`}>
                                                    <svg class="me-1" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g id="play">
                                                        <path id="Icon" d="M5.83594 14.9485V5.05133C5.83594 3.66854 7.42276 2.88719 8.51879 3.73029L14.9519 8.67885C15.8192 9.34601 15.8192 10.6538 14.9519 11.3209L8.51879 16.2695C7.42276 17.1126 5.83594 16.3312 5.83594 14.9485Z" stroke="white" stroke-width="2" stroke-linecap="round"/>
                                                        </g>
                                                    </svg>
                                                    View Recording
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div>
                            <Pagination updatePageNumber={updatePastEventPageNumber} last_page={pastEventListTotalPages}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className='common-style background-dark-primary clearfix hover-state d-none' id='moneyGuarantee'>
                <div className='container'>
                <div className='row background-dark-secondary p-5 rounded-4 px-2 px-md-5'>
                    <div className='col-xl-7 col-lg-7 col-md-12 col-sm-12'>
                        <div className='text-white heading-with-text mb-5'>
                            <h2 className='fs-40 fw-normal mb-3 fs-18'>Become a Speaker
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M6.67188 15.9995H25.3385" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16 6.66602L25.3333 15.9993L16 25.3327" stroke="#F8F8F8" stroke-width="2.25578" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </h2>
                            <p className='text-dark-tertiary'>Convey your vision, drive change, and make an impact. Join us as a speaker to lead the conversation at our premier event</p>
                        </div>
                        <div className='d-flex flex-wrap'>
                            <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Networking Opportunities</span>
                                </li>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Thought Leadership</span>
                                </li>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Increased Visibility</span>
                                </li>
                            </ul>
                            <ul className='col-12 col-md-6 text-white mb-0 mb-md-2 list-style-none'>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Knowledge Sharing</span>
                                </li>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Feedback & Engagement</span>
                                </li>
                                <li className='mb-4 d-flex'>
                                    <svg className='col-auto' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 2.25C6.62391 2.25 2.25 6.62391 2.25 12C2.25 17.3761 6.62391 21.75 12 21.75C17.3761 21.75 21.75 17.3761 21.75 12C21.75 6.62391 17.3761 2.25 12 2.25ZM17.0742 8.73234L10.7742 16.2323C10.7051 16.3147 10.6191 16.3812 10.5221 16.4273C10.425 16.4735 10.3192 16.4983 10.2117 16.5H10.1991C10.0939 16.5 9.99 16.4778 9.89398 16.435C9.79797 16.3922 9.71202 16.3297 9.64172 16.2516L6.94172 13.2516C6.87315 13.1788 6.81981 13.0931 6.78483 12.9995C6.74986 12.9059 6.73395 12.8062 6.73805 12.7063C6.74215 12.6064 6.76617 12.5084 6.8087 12.4179C6.85124 12.3275 6.91142 12.2464 6.98572 12.1796C7.06002 12.1127 7.14694 12.0614 7.24136 12.0286C7.33579 11.9958 7.43581 11.9822 7.53556 11.9886C7.63531 11.995 7.73277 12.0213 7.82222 12.0659C7.91166 12.1106 7.99128 12.1726 8.05641 12.2484L10.1794 14.6072L15.9258 7.76766C16.0547 7.61863 16.237 7.52631 16.4335 7.51066C16.6299 7.49501 16.8246 7.55728 16.9754 7.68402C17.1263 7.81075 17.2212 7.99176 17.2397 8.18793C17.2582 8.3841 17.1988 8.57966 17.0742 8.73234Z" fill="white"/>
                                    </svg>
                                    <span className='overflow-hidden fs-18 ms-1'>Community Contribution</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-12 col-sm-12'>
                        <img className='img-fluid rounded-2 d-table mx-auto' src={process.env.REACT_APP_BASE_CLOUDFLARE_URL_SIZE+"events/become_a_speaker_listing.png?format=webp&width=648"} alt="" />
                    </div>
                </div>
                </div>
            </section>
            <Suspense fallback={<div>Loading</div>}>
                <EventListingPageSwiperComponent />
            </Suspense>
            <Footer platform={"newevents_datahour_listing"}/>
            <Cookies platform={'newevents'}  />
            <Flashstrip />
        </>
    )
}

const mapStateToProps = (state) => ({
	eventList:state.eventContentReducer.eventList,
    featuredSessionBanner: state.eventContentReducer.featuredSessionBanner,
    pastEventList:state.eventContentReducer.pastEventList,
    pastEventListTotalPages:state.eventContentReducer.pastEventListTotalPages
});

const mapDispatchToProps = dispatch=>({
    getEventListDataHandler:data=>{dispatch(getEventListData(data))},   
    getPastEventByPageNumberDataHandler:(data,setIsLoader)=>{dispatch(getPastEventByPageNumberData(data,setIsLoader))},
});

export default connect(mapStateToProps,mapDispatchToProps)(EventListingPage);
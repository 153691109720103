import { CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS, FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS, FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS, FETCH_EVENT_LIST_CONTENT_SUCCESS, FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS  } from '../Contants/index';
  
const initialState = {
    eventList: null,
    featuredSessionBanner: null,
    pastEventList: null,
    pastEventListTotalPages: 4,
    eventdatahour:null,
    eventSpeakerSession:null
};
  
export const eventContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENT_LIST_CONTENT_SUCCESS:
            return {
                ...state,
                eventList: action.payload.upcomingDatahours.edges,
                featuredSessionBanner: action.payload.featuredDatahourSession
            };
        case FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                eventdatahour: action.payload,
            };
        case CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                eventdatahour: null,
            };
        case FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS:
            return {
                ...state,
                eventSpeakerSession:action.payload
            }

        case FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS:
            return {
                ...state,
                pastEventList: action.payload.completedDatahours,
                pastEventListTotalPages: action.payload.totalPages
        };

        default:
            return state;
    }
};
  
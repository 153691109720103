import React, { useEffect, useState } from 'react';
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';

const Ranker = () => {
    const [rankers, setRankers] = useState([]);

    useEffect(()=>{
        const query = `query MyQuery {
            allTopRanker {
                id
                name
                rankerType
                updatedAt
            }
        }`

        getQueryDataElevate(query, "newcompetition", "True")
        .then((data)=>{
            console.log(data?.data);
            setRankers(data?.data?.allTopRanker);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    return (
        <>
            <Header />
            <section id="rankerList" className='common-style min-vh-100 background-dark-primary'>
                <div className="container">
                    <div className="row">
                        <div className='text-end p-0'>
                            <a href={'/av-admin/datahack-admin/ranker/create-ranker'} className='btn btn-dark btn-dark-primary px-5 py-2 my-3 text-uppercase fw-bold'>
                                <svg className='me-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M9.99993 1.51472V18.4853M18.4852 10L1.51466 10" stroke="white" stroke-width="2" stroke-linecap="round" />
                                </svg>
                               New Ranker
                            </a>
                        </div>
                        <table className='table table-striped text-center table-dark'>
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Ranker Type</th>
                                    <th>Updated At</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rankers && rankers.map((val) => (
                                    <tr>
                                        <td><a href={'/av-admin/datahack-admin/ranker/'+val.id} className='text-white'>{val.id}</a></td>
                                        <td><a href={'/av-admin/datahack-admin/ranker/'+val.id} className='text-white'>{val.name}</a></td>
                                        <td>{val.rankerType}</td>
                                        <td>{val.updatedAt}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Ranker;
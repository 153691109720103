import React, { useEffect, useRef, useState } from "react";
import { getQueryData, getQueryDataElevateUser } from "../../../../utils";
import Header from "../../../common/Header/Header";
import { connect } from "react-redux";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import { useParams } from "react-router-dom";

const UpdateDatahourResource = ({csrf}) => {
    const [title, setTitle] = useState("");
    const [link, setLink] = useState("");
    const [resourceType, setResourceType] = useState("");
    const [datahours, setDatahours] = useState([]);
    const [datahourId, setDatahourId] = useState("");

    const [fileURL, setFileURL] = useState("");
    const [thumbnailURL, setThumbnailURL] = useState("");

    const [file, setFile] = useState("");
    const [thumbnail, setThumbnail] = useState("");

    const fileRef = useRef(null);
    const thumbnailRef = useRef(null);

    const {id} = useParams();

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    useEffect(()=>{
        const query = `
        query MyQuery {
            allDatahours {
                edges {
                    node {
                        sessionName
                        id
                    }
                }
            }
            datahourResource(id: "${id}") {
                createdAt
                file
                fileUrl
                id
                link
                modifiedAt
                resourceType
                session {
                    id
                    sessionName
                }
                thumbnail
                thumbnailUrl
                title
            }
        }`

        getQueryData(query, "newevents")
        .then((data)=>{
            console.log(data.data);
            setDatahours(data.data.allDatahours.edges.map(edge => edge.node));
            setDatahourId(data.data.datahourResource[0].session.id);
            setTitle(data.data.datahourResource[0].title);
            setLink(data.data.datahourResource[0].link);
            setResourceType(data.data.datahourResource[0].resourceType);
            setThumbnailURL(data.data.datahourResource.thumbnailUrl);
            setFileURL(data.data.datahourResource.fileUrl);
        })
        .catch((error)=>{
            console.log(error);
        })
    },[]);

    const handleDeleteResource = (id) => {
        if (window.confirm("Are You Sure?") === true) {
            if (window.confirm("Do you really want to delete this resource?\nThis will be informed to admin.") === true) {
                if(id) {
                    const delQuery = `mutation {
                        deleteDatahourResource(id: ${id}) {
                            success
                            message
                        }
                    }`;
                    getQueryDataElevateUser(delQuery, "newevents", "True", csrf)
                    .then((data) => {
                        if (!data.errors) {
                            setIsSuccess(true);
                            setMessage("Your Resource deleted successfully.");
                            setTimeout(() => {
                                setIsSuccess(false);
                                window.location.href = '/av-admin/datahack-admin/datahour-resources'
                            }, 3000)
                        }
                        else {
                            setIsError(true);
                            setMessage(data.errors[0].message);
                            setTimeout(() => {
                                setIsError(false);
                            }, 3000);
                        }
                    })
                    .catch(error => {
                        setIsError(true);
                        setMessage(error.message);
                        setTimeout(() => {
                            setIsError(false);
                        }, 3000);
                        console.log('error', error)
                    });
                    return false;
                }
                else {
                    setIsError(true);
                    setMessage("Resource cannot be deleted.");
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            }
        }
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        var formdata = new FormData();

        if ((!thumbnailRef?.current || !thumbnailRef.current.files || thumbnailRef.current.files.length === 0) && (!fileRef?.current || !fileRef.current.files || fileRef.current.files.length === 0)) {
            let query = `mutation {
                updateDatahourResource(
                    id: ${id}
                    resourceType: "${resourceType.toLowerCase()}"
                    link: "${link}"
                    title: "${title}"
                    ) { 
                    datahourResource { 
                            id session { id } resourceType 
                        } 
                    }
                }
            `;
            console.log(query);
            getQueryDataElevateUser(query, "newevents", "True", csrf)
                .then((data) => {
                    if (!data.errors) {
                        setIsSuccess(true);
                        setMessage("Your Resource updated successfully.");
                        setTimeout(() => {
                            setIsSuccess(false);
                        }, 3000)
                    }
                    else {
                        setIsError(true);
                        setMessage(data.errors[0].message);
                        setTimeout(() => {
                            setIsError(false);
                        }, 3000);
                    }
                })
                .catch(error => {
                    setIsError(true);
                    setMessage(error.message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                    console.log('error', error)
                });
            return false;
        }

        let mutationString = `mutation UpdateDatahourResource($id: ID!, $resourceType: String!, $title: String, $link: String, $file: Upload, $thumbnail: Upload) { 
            updateDatahourResource(id: $id, resourceType: $resourceType, link: $link, file: $file, title: $title, thumbnail: $thumbnail) { 
                datahourResource { id session { id } resourceType link file title thumbnail } 
            }
        }
        `;

        let variables = {
            id: id,
            resourceType: resourceType.toLowerCase(),
            link: link,
            title: title,
            ...((thumbnailRef && thumbnailRef.current && thumbnailRef.current.files && thumbnailRef.current.files[0]) && { thumbnail: null }),
            ...((fileRef && fileRef.current && fileRef.current.files && fileRef.current.files[0]) && {file: null})
        }

        const map = {};

        if (fileRef?.current?.files?.length > 0) {
            formdata.append("0", fileRef?.current?.files[0]);
            map["0"] = ["variables.file"]
        }
        if (thumbnailRef?.current?.files?.length > 0) {
            formdata.append("1", thumbnailRef?.current?.files[0]);
            map["1"] = ["variables.thumbnail"];
        }
        formdata.append("operations", JSON.stringify({ query: mutationString, variables: variables }));
        formdata.append("map", JSON.stringify(map));
        formdata.append("source", "newevents");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };
        console.log("FormDtaa: ",formdata)
        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
            .then(response => {
                if (!response.ok) {
                    setIsError(true);
                    setMessage("Something went wrong");
                    setTimeout(() => {
                        setIsError(false);
                    }, 5000);
                    return false;
                }
                return response.json();
            })
            .then((data) => {
                if (!data.errors) {
                    setIsSuccess(true);
                    setMessage("Your Resource updated successfully.")
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                }
                else {
                    setIsError(true);
                    setMessage(data.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setIsError(true);
                setMessage(error.message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                console.log('error', error);
            });
        return false;
    }

    const handleFileUpload = () => {
        const file = fileRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setFile(imageURL);
        } else {
            setFile("");
        }
    }

    const handleThumbnailUpload = () => {
        const file = thumbnailRef.current.files[0];

        if (file) {
            const imageURL = URL.createObjectURL(file);
            setThumbnail(imageURL);
        } else {
            setThumbnail("");
        }
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={message} />}
            <section id='createDatahourResource' className="common-style min-vh-100 background-dark-primary">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold">Update Datahour Resource</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Datahour</label>
                                            <select
                                                name="datahourId"
                                                className="form-select"
                                                value={datahourId}
                                                onChange={(event) => setDatahourId(event.target.value)}
                                                disabled
                                            >
                                                <option value="" disabled>Select Datahour</option>
                                                {datahours.map((val) => (
                                                    <option key={val.id} value={val.id}>
                                                        {val.sessionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Resource Type <sup className='text-danger'>*</sup></label>
                                            <select
                                                name="resourceType"
                                                className="form-select"
                                                value={resourceType}
                                                onChange={(event) => setResourceType(event.target.value)}
                                                required
                                            >
                                                <option value="" disabled>Select Resource Type</option>
                                                {["VIDEO", "DOCUMENT", "NOTES"].map((type) => (
                                                    <option key={type} value={type}>
                                                        {type}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        {(resourceType == "VIDEO") && <div className="form-group mb-3">
                                            <label className="text-white">Link</label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Link' 
                                            value={link}
                                            onChange={(event)=>setLink(event.target.value)}
                                            />
                                        </div>}

                                        {(resourceType == "NOTES" || resourceType == "DOCUMENT") && <div className="form-group mb-3">
                                            <label className="me-3 text-white">File:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="file"
                                                ref={fileRef}
                                                onChange={handleFileUpload}
                                            />
                                        </div>}

                                        {(resourceType == "VIDEO") && <div className="form-group mb-3">
                                            <label className="me-3 text-white">Thumbnail:</label>
                                            <input
                                                className="form-control"
                                                type="file"
                                                name="thumbnail"
                                                ref={thumbnailRef}
                                                onChange={handleThumbnailUpload}
                                            />
                                        </div>}

                                        <div>
                                            <a className="text-blue" target='_blank' href={fileURL ? fileURL : link}>View Resource</a>
                                        </div>

                                        <div className="d-flex align-items-center">
                                            <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100 me-3'>
                                                Update Resource
                                            </button>
                                            <svg onClick={()=>{handleDeleteResource(id)}} className="mt-3" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="43" height="43" viewBox="0,0,256,256">
                                            <g fill="#ffffff" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" ><g transform="scale(10.66667,10.66667)"><path d="M10.80664,2c-0.517,0 -1.01095,0.20431 -1.37695,0.57031l-0.42969,0.42969h-5c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h16c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-5l-0.42969,-0.42969c-0.365,-0.366 -0.85995,-0.57031 -1.37695,-0.57031zM4.36523,7l1.52734,13.26367c0.132,0.99 0.98442,1.73633 1.98242,1.73633h8.24805c0.998,0 1.85138,-0.74514 1.98438,-1.74414l1.52734,-13.25586z"></path></g></g>
                                            </svg>  
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>    
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDatahourResource);
import React, { useEffect } from 'react'
import Footer from '../../../common/Footer/Footer';
import HomepageHeader from '../components/HomepageHeader';
import { connect } from 'react-redux';
import { fetchHomepageContent } from '../../../../store/Actions/Action';
import { scrollTop } from '../../../../utils';
import Flashstrip from '../../../common/Header/Flashstrip';
import Cookies from '../../../common/Footer/Cookies';

function PrivacyPolicy({homepagecontent, fetchHomepageContentHandler}) {

	useEffect(()=>{
		if(!homepagecontent){
			fetchHomepageContentHandler();
		}
        scrollTop();
	},[]);

	return (
		<>
		<main className='background-dark-primary'>
			<HomepageHeader header_content={homepagecontent && homepagecontent.header} />
			<Flashstrip />
			<section className='static-pages' id='privacyPolicy'>
				<div className='container'>
					<div className="row">
						<div className="col-md-8 col-10 mx-auto">

							<h2>Privacy policy</h2>
								<p>Welcome, and thank you for your interest in Analytics Vidhya, and our website(s), products, services and applications (the “Services”). This Privacy Policy is meant to help you understand what information we collect, why we collect it, and how you can update, manage, export, and delete your information.</p>
								<p>It also describes your choices regarding use, access, withdrawal of consent (as per the terms hereof) and correction of your personal information. The use of information collected through our Service(s) shall be limited to the purpose of providing the service that you have chosen. The capitalized terms used in this Policy but not defined herein shall have the same meaning as defined in our Terms and Conditions at (“Terms and Conditions ”). For the purpose of this Privacy Policy, sensitive personal data or information of a personal nature is as defined under applicable privacy laws. Please note that usage of the terms personal information/ personally identifiable information/ personal data, in this Privacy Policy includes sensitive personal data or information, wherever appropriate and/or mandated under applicable privacy laws.</p>
								<p> If you have any questions about this Privacy Policy, you can <a href="https://analyticsvidhya.com/contact/" target='_blank'>contact us here</a>.</p>
								<p>When you clicked “accept” or “agree” in connection with registering your account, we made available to you this Privacy Policy and the information handling practices described in here. This Privacy Policy only covers Analytics Vidhya’s practices regarding personal information, and not information more generally. We may use aggregated, anonymized data that we derived from your personal information before you deleted it, but not in a manner that incorporates any of your personal information or would identify you personally.</p>
								<p>We’ll ask for your consent before using your information for a purpose that isn’t covered in this Privacy Policy. You may, of course, decline to share certain personal information with Analytics Vidhya, in which case Analytics Vidhya may not be able to provide to you some of the features and functionality found on the Services.</p>

							<h3>Commitment</h3>
								<p>The privacy of our Website users, whether you are our former or existing registered user or merely a visitor to our Website, is very important to us and we are strongly committed to your right to privacy and to keeping your personal and other information secure. We encourage you to read our privacy statement to understand what types of personally identifiable information we collect and how we use this information.</p>

							<h3>Information Analytics Vidhya Collects</h3>
								<p>We collect information to provide better Services to all our users. When you’re not signed into an Analytics Vidhya account, we store the information we collect with unique identifiers tied to the browser, application, or device you’re using. When you’re signed in, we collect information that we store with your Analytics Vidhya account:</p>
								<p>
									<ul>
										<li>Any personal or personally identifiable information that you may submit or may be required to submit for registration and continued use of the services offered by us;</li>
										<li>Your name, title, gender, date of birth, email address, telephone number (Home/work/mobile telephonic number), financial information (bank account, wallet or other payment details) profile/display picture, login name, screen name, nickname, or handle, country/state/postcode or city of residence, postal or other physical address, name(s) of the school/university (including grades and graduation year), name(s) of the workplace, job position/designation (including salary), resume/CV, IP addresses and other information collected passively may be collected; and</li>
										<li>Any other personal information you may choose to further provide us, without limitation, any information to update your account and profile, if required, to fill out any forms, provide your feedback to surveys, write any articles on the platform, or to use any features offered by us.</li>
									</ul>
								</p>
								<p> We value transparency and collaboration, and as such, we may share the data collected through the DataHack Summit App with our trusted sponsors who share our commitment to fostering innovation and advancing the field of data science.</p>
							<h3>Purpose of collecting Personal Data</h3>
							<p>In addition to verifying your identity, we collect and process your Personal Data for a variety of reasons including the following:
								<ul>
									<li>deliver our products and services;</li>
									<li>provide you with the websites and services, together with any support you may request;</li>
									<li>respond to your inquiries or fulfil your requests;</li>
									<li>diagnose technical problems;</li>
									<li>relay announcements, newsletters, educational materials, and event information;</li>
									<li>enhance and improve our services;</li>
									<li>prevent, detect and mitigate any fraudulent activities;</li>
									<li>contact/reach out to you via email, phone, WhatsApp, text or any other similar channels to offer you our services and offers, as applicable;</li>
									<li>comply with our legal obligations, resolving disputes with users, enforcing our agreements; and</li>
									<li>provide chat room services.</li>
								</ul>
							</p>
							<h3>Things You Create or Provide to Us</h3>
							<p>When you create an Analytics Vidhya account, submit competition entries, apply to job postings or otherwise use the Services, you provide us with personal information that includes your name and a password. You may also provide your mailing address and home/mobile phone number, or other personal information.</p>
							<p>Your provision of this personal data is required for you to enter into the <a href="https://www.analyticsvidhya.com/terms/" target='_blank'>Terms of Use</a>, a contract, with us, as well as for you to enter competitions hosted and for applying on job postings on the Services. These entries are also a contract between you and the competition host, and we need your personal information to host the Services offered under that contract (see the <a href="https://www.analyticsvidhya.com/terms/" target='_blank'>Terms of Use</a> for additional information). If you do not provide the minimum amount of personal data required to register an account, including your name and email address, you cannot use the Services, as this information is necessary for contractual reasons. Please see “European Requirements” below for further information on the bases for our processing your personal information.</p>
							<p>We also collect the content you create, upload, or receive from others when using our Services. This includes things like messages you write and receive, forum posts, activities, datasets, and competition submissions.</p>

							<h3 className='mb-40'>Information We Collect as You Use Our Services</h3>
								<h4>Your apps, browsers & devices</h4>
									<p>We collect information about the apps, browsers, and devices you use to access our Services or open one of our HTML emails by using different types of technology, including “clear gifs” or “web beacons.” This “automatically collected” information may include Internet Protocol address (also known as “IP address”) or other device address or ID, web browser or device type, the web pages or sites that you visit just before or just after the Services, the pages you view on the Services, and the dates and times that you visit the Services. <br/> We use various technologies to collect and store information, including cookies, pixel tags, local storage, such as browser web storage or application data caches, databases, and server logs.</p>
								<h4>Your activity</h4>
									<p>We collect information about your activity in our Services, which may include:
										<ul>
												<li>Views and interactions with content and ads.</li>
												<li>TPeople with whom you communicate or share content.</li>
												<li>Which datasets you view or download.</li>
										</ul>
									</p>
								<h4>Cookies and web beacons</h4>
									<p>When you use the Services, we send one or more cookies – small text files containing a string of alphanumeric characters – to your computer. For example, we use cookies to ensure proper navigation between pages on the Services. Analytics Vidhya may use both session cookies and persistent cookies. A session cookie disappears after you close your browser. A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Services. <br/>Persistent cookies can be removed. Please review your web browser’s “Help” file to learn the proper way to modify your cookie settings. If you delete, or choose not to accept, cookies from the Services, you may not be able to utilize the features of the Services to their fullest potential. We may also implement on the Services third party content, such as advertising or analytic services, that uses “clear gifs,” “web beacons,” or other similar techniques, which allow the third party content provider to read and write cookies to your browser or implement similar tracking mechanisms. This information is collected directly by the third party, and Analytics Vidhya does not participate in that data transmission. Information collected by third parties in this manner is subject to that third party’s own data collection, use, and disclosure policies. <span className='fs-18'>We currently implement services provided by Intercom, Google Analytics, Facebook, and Microsoft Application Insights, CleverTap, HotJar, Amazon, Firebase, OneSignal, Discourse, JetPack, KPMG, Thinkific, Discourse, Truepush, Webengage, Webpushr and Freshsales</span></p>
								<h4>Linked accounts</h4>
									<p>When you link an account you may have on a third party service (such as a third party social networking site or email provider) to your Analytics Vidhya account, Analytics Vidhya collects certain information stored in connection with that account that you have configured that service to make available, including your email address, provider ID, first and last name, and profile picture. By linking your accounts in this manner, you authorize Analytics Vidhya to access and use your account on the third party service in connection with your use of the Services.</p>

							<h3>Why Analytics Vidhya Collects Information</h3>
								<p>Analytics Vidhya uses the information we collect for the following purposes:</p>
								<h4>Provide Our Services</h4>
									<p>We use your information to deliver our Services, like administering competitions you enter or hosting datasets you upload or access. For example, we use the IP address assigned to your device to send you the data you requested, such as loading a dataset. Another example is that we use unique identifiers stored in cookies on your device to help us authenticate you as the person who should have access to your Analytics Vidhya account.</p>
								<h4>Maintain & Improve Our Services</h4>
									<p>We also use your information to ensure our Services are working as intended, such as tracking outages or troubleshooting issues that you report to us. For example, we continuously monitor our systems to look for problems. And if we find something wrong with a specific feature, reviewing activity information collected before the problem started allows us to fix things more quickly.</p>
									<p>And we use your information to make improvements to our Services – for example, to understand and analyze the usage trends and preferences of our Analytics Vidhya account members and to improve the way the Services work and look.</p>
									<p>For example, we use cookies to analyze how people interact with our Services, which can help us build better products. It may help us discover that it’s taking people too long to complete a certain task or that they have trouble finishing steps at all. We can then redesign that feature and improve the product for everyone.</p>
								<h4>Develop New Services</h4>
									<p>We use the information we collect in existing Services to help us create new features and functionality.</p>
								<h4>Provide Personalized Services</h4>
									<p>We use the information we collect to customize our Services for you, including remembering your information so that you will not have to log-in again the next time you visit the Services if you haven't signed out. We also use data to provide customized third party content and information and to track your status in any Competitions or other activities. In addition, we may use the information we collect to provide personalized and relevant advertising, for example showing you positions from our jobs board in your location based on your IP address.</p>
								<h4>Measure Performance</h4>
									<p>We use data for analytics and measurement to understand how our Services are used. For example, we analyze data about your visits to our sites to do things like optimize product design, monitor and analyze the operation and effectiveness of the Services and related activities, and monitor aggregate site usage metrics such as total number of visitors and pages viewed. We use a variety of tools to do this, including Google Analytics. Google Analytics relies on first-party cookies, which means the cookies are set by Analytics Vidhya. You can opt-out from Google Analytics by clicking <a href="https://tools.google.com/dlpage/gaoptout" target='_blank'>here</a>.</p>
								<h4>Communicate with You</h4>
									<p>We use information we collect, like your email address, to interact with you directly. For example, we may send you a notification if we detect suspicious activity on your account, or we may let you know about upcoming changes or improvements to our Services. We will also use your email address to contact you with respect to competition entries, prize awards and otherwise in connection with competitions; for administrative and customer service purposes; to address intellectual property infringement, rights of privacy, or defamation issues; or regarding things you post on the Services. And if you contact Analytics Vidhya, we’ll keep a record of your request to help solve any issues you might be facing.</p>
									<p>Analytics Vidhya also uses your email address or other personal information to send you messages about the Services and communications from other Members. We may also send you messages related to the Services and the activities of third parties we work with. These messages may be targeted at you based on your activities on our Services, for example your interaction with a particular dataset or your entry into a specific competition. You will have the ability to opt-out of receiving any such communications through links provided in the messages, or by sending us a mail at <a href='mailto:privacy@analyticsvidhya.com'>privacy@analyticsvidhya.com</a></p>
									<p>When we detect spam, malware, illegal content, and other forms of abuse on our systems in violation of our policies, we may disable your account or take other appropriate action. In certain circumstances, we may also report the violation to appropriate authorities.</p>
								<h4>Managing and Deleting Your Information</h4>
									<p>When you’re signed into your Analytics Vidhya account, you can always review and update information by visiting your account profile.</p>

							<h3>Exporting, Removing & Deleting Your Information</h3>
								<p>You can export a copy of content in your Analytics Vidhya account if you want to back it up or use it with a service outside of Analytics Vidhya by <a href="https://analyticsvidhya.com/contact/" target='_blank'>contacting us here</a>. You may update, correct, or delete your profile information and preferences at any time by accessing your account settings page or editing your Analytics Vidhya account profile through the Services.<br/> You can also delete your discussion posts, uploaded datasets, and kernels by using the available tools in our Services. If desired, you can delete your entire Analytics Vidhya account by choosing that option in your account settings page. <br/> Please note that while your deletions are reflected promptly in active member databases, in some cases, we retain data for limited periods when it needs to be kept for legitimate business or legal purposes. See “Data Retention” below for more information. <br/> There are other ways to control the information Analytics Vidhya collects whether or not you’re signed in to a Analytics Vidhya account, including configuring your browser to indicate when Analytics Vidhya has set a cookie in your browser. You can also configure your browser to block all cookies from a specific domain or all domains. But remember that our Services rely on cookies to function properly.</p>

							<h3>Account Deletion</h3>
								<p>As we describe above, you can request for deletion of your entire Analytics Vidhya account by mailing us on <a href='mailto:privacy@analyticsvidhya.com'>privacy@analyticsvidhya.com</a> <br/> Information Retained For Extended Time Periods for Limited Purposes <br/> Sometimes business and legal requirements require us to retain certain information for specific purposes for an extended period of time. For example, when you submit an entry in a competition or apply on job posting, we may retain this information for a longer period of time to guard against cheating, for example, or for legal purposes. Reasons we might retain some data for longer periods of time include:
									<ul>
										<li>Security, fraud & abuse prevention</li>
										<li>Financial record-keeping</li>
										<li>Complying with legal or regulatory requirements</li>
										<li>Ensuring the continuity of our services</li>
									</ul>
								</p>

							<h3>Enabling Safe and Complete Deletion</h3>
								<p>As we describe above, you can request for deletion of your entire Analytics Vidhya account by mailing us on <a href='mailto:privacy@analyticsvidhya.com'>privacy@analyticsvidhya.com</a> <br/> Information Retained For Extended Time Periods for Limited Purposes <br/> Sometimes business and legal requirements require us to retain certain information for specific purposes for an extended period of time. For example, when you submit an entry in a competition or apply on job posting, we may retain this information for a longer period of time to guard against cheating, for example, or for legal purposes. Reasons we might retain some data for longer periods of time include:
									<ul>
										<li>Security, fraud & abuse prevention</li>
										<li>Financial record-keeping</li>
										<li>Complying with legal or regulatory requirements</li>
										<li>Ensuring the continuity of our services</li>
									</ul>
								</p>

							<h3>Sharing Your Information</h3>
								<h4>When You Share Your Information</h4>
									<p>Many of our Services let you share information with other people, and you have control over how you share. Any information that you voluntarily choose to include in an area of the Services accessible to other Analytics Vidhya members, such as a public profile page or in a posting, will be available to other Analytics Vidhya members who access that content. This also applies to information you choose to make available through features that connect to third party services, if applicable. Examples of third parties with whom we may share Personal Data includes, government bodies, including tax and social security authorities, to comply with applicable laws (including employment and tax laws), to obtain licenses or approvals, and upon request during an audit or assessment; with suppliers, subcontractors and service providers, to maintain an efficient and commercially viable business, including technology, telecom, internet providers; with professional advisers, consultants, and employment and recruitment agencies, to conduct background verification and reference checks, administer benefits and payroll, deal with disciplinary and grievance issues and maintain emergency contact details; with our legal advisors and external auditors for legal advice and to conduct business audits; with service providers for business continuity management and contingency planning in the event of business disruptions; or with certain companies in order to establish a membership to participate in digital wallets, payment services or rewards programme</p>
									<p>Also, if you communicate directly with another Analytics Vidhya member, any information that you voluntarily choose to include in such a communication with other Analytics Vidhya members will be available to such other members. Once you make your information available in any of these ways, it could be collected and used by the recipients without restriction. We urge Analytics Vidhya members to exercise common sense, prudence and good judgment about what information to make available to others through the Services.</p>
								<h4>When Analytics Vidhya Shares Your Information</h4>
									<p>We are not in the business of selling your information. We do, however, disclose your personal information in connection with developing and maintaining the Services and operating Competitions. We share your personal information in the following cases:</p>
									<h5>With your consent</h5>
										<p>We’ll share personal information outside of Analytics Vidhya when we have your consent. For example, if you give us permission to share your information with a third party who may be a prospective employer.</p>
										<p>Consent may also be implied where a user is given notice and a reasonable opportunity to opt-out of his or her personal information being used for mail-outs, the marketing of new services or products, and the client, customer, member does not opt-out.</p>
									<h5>To operate competitions</h5>
										<p>Analytics Vidhya may disclose your personal information to the hosts of competitions to enable your participation in the competitions and our operation of the competitions. The terms of that disclosure are covered in the competition rules, which is a contract between you and the competition sponsor.</p>
								    <h5>For Offline Events - </h5>
									<ul className='mb-5'>
										<li>We may share your registration information with our sponsors for marketing purposes. This information may include your name, company name (if applicable), email address, and any other information you provided during registration.</li>
										<li>Our sponsors are prohibited from using your information for any other purpose than promoting their products or services that are relevant to the event.</li>
										<li>You have the right to opt out of having your information shared with our sponsors. You can opt out by contacting us at <a className='text-white' href='mailto:dhs@analyticsvidhya.com'>dhs@analyticsvidhya.com</a>.</li>
									</ul>
									<h5>For external processing</h5>
										<p>We provide personal information to our affiliates and other trusted businesses or persons to process it for us, based on our instructions and in compliance with our Privacy Policy and any other appropriate confidentiality and security measures. For example, we use service providers to help us with customer support. These third parties may have access to or process your personal information as a result of performing the services they were engaged to perform.</p>
									<h5>For legal reasons</h5>
										<p>We will share personal information outside of Analytics Vidhya if we have a good-faith belief that access, use, preservation, or disclosure of the information is reasonably necessary to:
											<ul className='mb-0'>
												<li>Meet any applicable law, regulation, legal process, or enforceable governmental request.</li>
												<li>Enforce our <a href="https://www.analyticsvidhya.com/terms/" target='_blank'>Terms of Use</a>, including investigation of potential violations, and take precautions against liability.</li>
												<li>Detect, prevent, or otherwise address fraud, abuse, security, or technical issues.</li>
												<li>Protect against harm to the rights, property or safety of Analytics Vidhya or our affiliates, our users, or the public as required or permitted by law.</li>
												<li>Protect the security or integrity of the Services and to protect or enforce third party rights.</li>
											</ul>
										We may share non-personally identifiable information publicly and with our partners — like publishers, advertisers, developers, or rights holders. For example, we share information publicly to show trends about the general use of our Services.
										</p>
										<p>If Analytics Vidhya is involved in a merger, acquisition, or sale of assets, we’ll continue to ensure the confidentiality of your personal information and give affected users notice before personal information is transferred or becomes subject to a different privacy policy.</p>

							<h3>Our Commitment to Data Security</h3>
								<p>Analytics Vidhya uses a variety of physical, managerial, and technical safeguards designed to improve the security of our systems and your personal information. We cannot, however, ensure or warrant the security of any information you transmit to Analytics Vidhya, nor can we guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. You transfer your information to Analytics Vidhya at your own risk. <br/> If Analytics Vidhya learns of a security breach, we will notify you in accordance with applicable law.</p>

							<h3>Third Party Websites</h3>
								<p>The Services contain links to websites and services provided by third parties. Any personal information you provide on third party sites or services is provided directly to that third party and is subject to that third party’s policies governing privacy and security. We are not responsible for the content or privacy and security practices and policies of third party sites or services to which links are displayed on the Services. We encourage you to learn about third parties’ privacy and security policies before providing them with personal information.</p>

							<h3>Compliance & Cooperation with Regulators</h3>
								<p>We regularly review this Privacy Policy and make sure that we process your information in ways that comply with it.</p>

							<h3>Data Transfers</h3>
								<p>Your information may be processed on servers located outside of the country where you live. Data protection laws vary among countries, with some providing more protection than others. Regardless of where your information is processed, we apply the same protections described in this policy. We transfer your personal data from the European Union to the India, Singapore and United States in accordance with standard contractual clauses we entered into. The standard contractual clauses can be read <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex%3A32004D0915" target='_blank'>here</a>.</p>
								<p>When we receive formal written complaints, we respond by contacting the person who made the complaint. We work with the appropriate regulatory authorities, including local data protection authorities, to resolve any complaints regarding the transfer of your data that we cannot resolve with you directly.</p>

							<h3>European Requirements</h3>
								<p>If European Union (EU) data protection law applies to the processing of your information, you can exercise your right to request access to, update, remove, and restrict the processing of your information. You also have the right to object to the processing of your information or export your information to another service. Please visit <a href="https://analyticsvidhya.com/contact/" target='_blank'>Analytics Vidhya Support</a> to exercise these rights.</p>
								<p>If you have any questions, you may contact our data protection office at <a href='mailto:privacy@analyticsvidhya.com'>privacy@analyticsvidhya.com</a>. If you have concerns regarding your rights under local law, you can contact your local data protection authority.</p>
								<p>We process your information for the purposes described in this policy, based on the following legal grounds:</p>
								<h4>With your consent</h4>
									<p>We ask for your agreement to process your information for specific purposes and you have the right to withdraw your consent at any time. You can manage these settings in your Analytics Vidhya account.</p>
								<h4>When we’re pursuing legitimate interests</h4>
									<p>We process your information for our legitimate interests while applying appropriate safeguards that protect your privacy. This means that we process your information for things like:
										<ul>
											<li>Providing, maintaining, and improving our Services to meet the needs of our users</li>
											<li>Developing new products and features that are useful for our users</li>
											<li>Understanding how people use our Services to ensure and improve the performance of our Services</li>
											<li>Customizing our Services to provide you with a better user experience</li>
											<li>Marketing to inform users about our Services</li>
											<li>Providing advertising to make many of our Services freely available for users</li>
											<li>Detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our Services</li>
											<li>Protecting against harm to the rights, property or safety of Analytics Vidhya, our users, or the public as required or permitted by law</li>
											<li>Performing research that improves our Services for our users and benefits the public</li>
											<li>Fulfilling obligations to our partners like developers and rights holders</li>
											<li>Enforcing legal claims, including investigation of potential violations of our <a href="https://www.analyticsvidhya.com/terms/" target='_blank'>Terms of Use</a></li>
										</ul>
									</p>
								<h4>When we’re providing a service</h4>
									<p>We process your data to provide a service you’ve asked for under a contract. For example, we process your information in connection with competitions you enter or job posting you applied to.</p>
								<h4>When we’re complying with legal obligations</h4>
									<p>We’ll process your data when we have a legal obligation to do so, for example, if we’re responding to legal process or an enforceable governmental request.</p>
								<h4>Retention of Personal Data</h4>
								<p>We retain your Personal Data, not longer than necessary for the purposes for which it was collected. The length of time to retain Personal Data depends on the purposes for which we collect and use it and/or as may be required to comply with applicable laws, to establish, exercise, or defend our legal rights.</p>
								<p>Also, if in case required to extend the period of retention of such data, we shall obtain your consent for the same. Further, we may also dispose the data prior to completion of the period of retention, if the purpose for which it was collected is exhausted.</p>
							<h3>Changes and Updates to this Privacy Policy</h3>
								<p>We change this Privacy Policy from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. Note that our amended Privacy Policy will take become effective on a going-forward basis as stated in the Terms, except that (i) unless you agree otherwise, we will use your personal information in the manner described in the Privacy Policy in effect when we received that information and (ii) if you do not agree with any changes to the Privacy Policy, you must terminate your Analytics Vidhya membership and cease use of the Services. Your continued use of the Services after a revised Privacy Policy has become effective indicates that you have read, understood and agreed to the current version of the Privacy Policy.</p>

							<h3>Use of this website and our Terms of Service</h3>
							<p>This website is the property of the Analytics Vidhya and our Terms of Use and this Privacy Policy collectively govern the use of the Platform and the Programs offered by Analytics Vidhya. This Privacy Policy shall form a part of the Terms by way of reference. By using this website and the information offered herein, you indicate your acceptance of these Terms of Use.</p>
							<h3>Analytics Vidhya Contact Information</h3>
								<p>Please contact Analytics Vidhya with any questions or comments about this Privacy Policy, your personal information, our use and disclosure practices, or your consent choices through <a href="https://analyticsvidhya.com/contact/" target='_blank'>Analytics Vidhya Support</a>.</p>
								<h4>Specific Contact Emails:</h4>
									<p>General Privacy/ Data Protection Office: privacy@analyticsvidhya.com</p>
									<p>I have more questions about privacy policy <br/> If you have any questions about this Privacy Policy, the practices of this Website, or your dealings with this Website, please contact us at privacy@analyticsvidhya.com.</p>
							
							<h3>Delete Account</h3>
								<p>If you want to delete your Analytics Vidhya account, this can be done by going to user dashboard <a href='https://id.analyticsvidhya.com/?utm_source=blog-privacy-delete' target='-blank'>here</a> and using the Delete Account selection</p>

							<h3>Your Consent</h3>
								<p>By accessing our Website and using it on a continued basis, you expressly consent and confirm to Analytics Vidhya collecting, maintaining, using, processing and disclosing your Personal Information and other information in accordance with this Privacy Policy.</p>
						</div>
					</div>
				</div>
			</section>
			<Footer platform={'newprivacypolicy'} />
		</main>
		<Cookies platform={'newhomepage'} />
		</>
	);
}

const mapStateToProps = (state) => ({
	homepagecontent:state.homepageContentReducer.homepagecontent
  });
  
  const mapDispatchToProps = dispatch=>({
	fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data))
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
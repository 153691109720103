import React from 'react';

export default function Error404() {
  return (
    <section id='error' className='vh-100 background-dark-primary'>
      <div className="container d-flex align-items-center h-100 justify-content-center">
        <div className="row col-lg-4 col-12">
            <h1 className='text-white text-center m-0 fw-bold fs-56'>404</h1>
            <h2 className='text-white text-center m-0 fw-semibold mb-2 fs-40'>OOPSS! Page not found</h2>
            <p className='text-dark-tertiary text-center m-0 mb-5'>Couldn’t find the page you are looking for</p>
            <a className='btn btn-dark btn-dark-primary w-auto mx-auto' href='/'>Back to Homepage</a>
        </div>
      </div>
    </section>
  )
}

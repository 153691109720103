import React, { useEffect, useRef, useState } from 'react';
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import { getQueryData } from '../../../../utils';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const PrivateRegistration = ({ csrf }) => {
    const [tournamentData, setTournamentData] = useState(null);
    const [tournamentId, setTournamentId] = useState(null);
    const [selectedTournamentTitle, setSelectedTournamentTitle] = useState("");
    const [emailCsv, setEmailCsv] = useState(null);
    const [unregisteredEmails, setUnregisteredEmails] = useState(null);
    const [showForm, setShowForm] = useState(true);

    const [iserror, setIsError] = useState(false);
    const [issuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("");

    useEffect(() => {
        let query = `query MyQuery {
            allTournament(limit: 100000, page: 1) {
                totalPages
                tournamentData {
                    id
                    slug
                    updatedAt
                    title
                }
            }
        }`;
        getQueryData(query, "newcompetition")
            .then(data => {
                setTournamentData(data?.data?.allTournament.tournamentData);
            })
            .catch(error => {
                console.error(error);
            })
    }, [])

    const resetFormFields = () => {
        setSelectedTournamentTitle("");
        setTournamentId(null);
        document.getElementById("tournamentIdValue").value = null;
        document.getElementById("emailCSV").value = null;
    }

    const handleTournamentChange = (event) => {
        if (tournamentData.some((tournament) => {
            if (tournament.id === event.target.value) {
                setSelectedTournamentTitle(tournament.title);
            }
            return tournament.id === event.target.value;
        })) {
            setTournamentId(event.target.value);
        } else {
            setTournamentId(null);
            setSelectedTournamentTitle("");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        var formdata = new FormData();

        let mutation = `mutation MyMutation($tournamentId: Int!, $emailCsv: Upload!) {
            privateTournamentRegistration(emailCsv: $emailCsv, tournamentId: $tournamentId ) {
                success, 
                unregisteredEmails
            }
        }`

        let variables = {
            tournamentId: parseInt(tournamentId),
            ...(emailCsv && { emailCsv: null }),
        }

        formdata.append("operations", JSON.stringify({ query: mutation, variables: variables }));
        formdata.append("map", JSON.stringify({ "0": ["variables.emailCsv"] }));
        formdata.append("0", emailCsv);
        formdata.append("source", "newcompetition");
        formdata.append("forward_user_data", "True");

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: {
                "X-CSRFToken": csrf,
            },
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE
        };

        fetch(process.env.REACT_APP_ELEVATE_USER_GRAPHQL_URL, requestOptions)
            .then(response => {
                return response.json();
            })
            .then((result) => {
                if (!result.errors) {
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    resetFormFields();
                    setShowForm(false);
                    setUnregisteredEmails(result?.data?.privateTournamentRegistration?.unregisteredEmails);
                }
                else {
                    setIsError(true);
                    setMessage(result.errors[0].message);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                }
            })
            .catch(error => {
                setIsError(true);
                setMessage(error.message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                console.log('error', error)
            });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Registration added successfully"} />}
            <section id='privateRegistration' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            {showForm ? (
                                <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                    <div className="card-body">
                                        <h3 className="card-title mb-3 text-center text-white fw-semibold text-uppercase">Private Registration Form</h3>
                                        <form className='w-100' onSubmit={handleSubmit}>
                                            <div className="form-group mb-3">
                                                <span className=" text-white me-2">Select Tournament <sup className='text-danger'>*</sup></span>
                                                <input id="tournamentIdValue" list="stages" className='form-control' onChange={handleTournamentChange} placeholder="Select a Tournament" required />
                                                <datalist id="stages">
                                                    {tournamentData && tournamentData.map((tournament) => (
                                                        <option key={tournament.id} value={tournament.id}>
                                                            {tournament.title}
                                                        </option>
                                                    ))}
                                                </datalist>
                                                {selectedTournamentTitle && <p className="text-white pt-2">Selected Tournament Name: <strong>{selectedTournamentTitle}</strong></p>}
                                            </div>

                                            <div className="form-group mb-3">
                                                <label className="me-3 text-white">Email CSV File <sup className='text-danger'>*</sup></label>
                                                <input
                                                    className="form-control"
                                                    type="file"
                                                    name="emailCsv"
                                                    id="emailCSV"
                                                    onChange={(e) => setEmailCsv(e.target.files[0])}
                                                    required
                                                />
                                            </div>

                                            <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                                Register
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            ) : (
                                <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                    <div className="card-body text-center">
                                        <h3 className="card-title mb-3 text-white fw-semibold text-uppercase">Unregistered Emails</h3>
                                        <div className='text-white'>
                                            <ul className='list-unstyled'>
                                                {unregisteredEmails && unregisteredEmails.map((email, index) => (
                                                    <li key={index} className='mb-2 fs-20'>{email}</li>
                                                ))}
                                            </ul>
                                        </div>
                                        <button className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold' onClick={() => setShowForm(true)}>
                                            Back to Form
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf: state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRegistration);
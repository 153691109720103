import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom'
import Header from '../../../common/Header/Header';
import { getQueryDataElevate } from '../../../../utils';
import { Editor } from '@tinymce/tinymce-react';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import ErrorAlert from '../../../common/Alert/ErrorAlert';

function UpdateProblemStatement({islogin,csrf}) {
    const { stageId } = useParams();
    const [allowedExtension,setAllowedExtension] = useState("");
    const [allowedExtensionCode,setAllowedExtensionCode] = useState("");
    const [description,setDescription] = useState('');
    const [id,setId] = useState('');
    const [idealSolution,setIdealSolution] = useState(null); 
    const [metricId,setMetricId] = useState("");
    const [metricName,setMetricName] = useState("");
    const [privateLbSolution,setPrivateLbSolution] = useState(null); 
    const [publicLbSolution,setPublicLbSolution] = useState(null); 
    const [sampleCodeFile,setSampleCodeFile] = useState(null); 
    const [sampleSubmission,setSampleSubmission] = useState(null); 
    const [testFile,setTestFile] = useState(null); 
    const [trainFile,setTrainFile] = useState(null); 
    const [title,setTitle] = useState("");

    const idealSolutionRef = useRef(null);
    const privateLbSolutionRef = useRef(null);
    const publicLbSolutionRef = useRef(null);
    const sampleCodeFileRef = useRef(null);
    const sampleSubmissionRef = useRef(null);
    const testFileRef = useRef(null);
    const trainFileRef = useRef(null);

    const [intialdataDictionary, setInitialDataDictionary] = useState("");
    const [dataDictionary, setDataDictionary] = useState("");
    const [externalSampleSubmissionUrl, setExternalSampleSubmissionUrl] = useState("");
    const [externalTestFileUrl, setExternalTestFileUrl] = useState("");
    const [externalTrainFileUrl, setExternalTrainFileUrl] = useState("");

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);

    useEffect(()=>{
        if(islogin){
            let query = `query MyQuery {
                problemModel(stageId: ${stageId}) {
                    allowedExtensions
                    allowedExtensionsCode
                    description
                    id
                    idealSolution
                    metric {
                        id
                        name
                    }
                    privateLbSolution
                    publicLbSolution
                    sampleCodeFile
                    sampleSubmission
                    testFile
                    trainFile
                    title
                    dataDictionary
                    externalSampleSubmissionUrl
                    externalTestFileUrl
                    externalTrainFileUrl
                }
            }`;

            getQueryDataElevate(query,'newcompetition',"True")
            .then((data)=>{
                console.log(data.data.problemModel);
                setAllowedExtension(data.data.problemModel.allowedExtensions.join(","));
                setAllowedExtensionCode(data.data.problemModel.allowedExtensionsCode.join(","));
                setDescription(data.data.problemModel.description);
                setId(data.data.problemModel.id)
                setIdealSolution(data.data.problemModel.idealSolution)
                setMetricId(data.data.problemModel.metric && data.data.problemModel.metric.id)
                setMetricName(data.data.problemModel.metric && data.data.problemModel.metric.name)
                setPrivateLbSolution(data.data.problemModel.privateLbSolution)
                setPublicLbSolution(data.data.problemModel.publicLbSolution)
                setSampleCodeFile(data.data.problemModel.sampleCodeFile)
                setSampleSubmission(data.data.problemModel.sampleSubmission)
                setTestFile(data.data.problemModel.testFile)
                setTrainFile(data.data.problemModel.trainFile)
                setTitle(data.data.problemModel.title);
                setDataDictionary(data.data.problemModel.dataDictionary);
                setInitialDataDictionary(data.data.problemModel.dataDictionary);
                setExternalSampleSubmissionUrl(data.data.problemModel.externalSampleSubmissionUrl);
                setExternalTestFileUrl(data.data.problemModel.externalTestFileUrl);
                setExternalTrainFileUrl(data.data.problemModel.externalTrainFileUrl);
            })
            .catch((error)=>{
                console.error(error);
            })
        }
    },[islogin])

    let updateProblemStatement = (e)=>{
        e.preventDefault();
        let payload = {
            "problem_statement_id":id,
            "stage_id":stageId,
            "title":title,
            "description":description,
            "allowed_extensions": allowedExtension,
            "allowed_extensions_code": allowedExtensionCode,
            "metric_id":metricId,
            "data_dictionary":dataDictionary || "",
            "external_sample_submission_url":externalSampleSubmissionUrl || "",
            "external_test_file_url":externalTestFileUrl || "",
            "external_train_file_url":externalTrainFileUrl || ""
        }

        console.log(payload);
        const formData = new FormData();

        formData.append("test_file", testFileRef.current.files[0]);
        formData.append("train_file", trainFileRef.current.files[0]);
        formData.append("sample_submission_file", sampleSubmissionRef.current.files[0]);
        formData.append("sample_code_file", sampleCodeFileRef.current.files[0]);
        formData.append("ideal_solution_file", idealSolutionRef.current.files[0]);
        formData.append("public_lb_solution_file", publicLbSolutionRef.current.files[0]);
        formData.append("private_lb_solution_file", privateLbSolutionRef.current.files[0]);

        formData.append("platform", 'newcompetition');
        formData.append("payload", JSON.stringify(payload));
        formData.append("url", 'api/problem-statement/');
        formData.append("forward_user_data","True");
        formData.append("method","PUT");

        fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body: formData,
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
            headers: {
                "X-CSRFToken":csrf,
            },
        }).then(response => {
            if (!response.ok) {
                setIsError(true);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
                return false;
            }
            return response.json();
        }).then((result)=> {
            if(result){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
            }
        }).catch(error => console.log('error', error));
    }
    
  return (
    <>      
        <Header />
        {iserror && <ErrorAlert message={"Network response was not ok"} setIsError={setIsError} />}
        {issuccess && <SuccessAlert message={"Problem statement updated successfully"} />}
        <section id="updateProblemStatement" className="common-style background-dark-primary min-vh-100">
            <div className="container">
                <div className="row">
                    <div className="card col-lg-8 col-md-12 mx-auto background-dark-tertiary shadow">
                        <div className="card-header border-0 py-3">
                            <h3 className='text-white text-center'>Update Problem Statement [ Stage Id - {stageId} ]</h3>
                        </div>
                        <div className="card-body">
                            <form id='updateProblemStatementForm' onSubmit={updateProblemStatement}>
                    
                                <div class="mb-3">
                                    <label class="form-label text-white">Metric Id</label>
                                    <input type="text" class="form-control" placeholder="Metric Id" value={metricId} disabled/>
                                    <p className="text-white pt-2">Selected Metric Name: <strong>{metricName}</strong></p>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Title</label>
                                    <input type="text" class="form-control" placeholder='Title' value={title} onChange={(e)=>{setTitle(e.target.value)}}/>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Description</label>
                                    <input type="text" class="form-control" placeholder='Description' value={description} onChange={(e)=>{setDescription(e.target.value)}} />
                                </div>

                                <div class="mb-3">
                                    <label for="allowedExtension" class="form-label text-white">Allowed Extension <span className='text-dark-tertiary'>(comma seperated)</span></label>
                                    <input type="text" class="form-control" id="allowedExtension" placeholder='Allowed Extension' value={allowedExtension} onChange={(e)=>setAllowedExtension(e.target.value.trim())} />
                                </div>

                                <div class="mb-3">
                                    <label for="allowedExtensionCode" class="form-label text-white">Allowed Extension Code <span className='text-dark-tertiary'>(comma seperated)</span></label>
                                    <input type="text" class="form-control" id="allowedExtensionCode" placeholder='Allowed Extension Code' value={allowedExtensionCode} onChange={(e)=>setAllowedExtensionCode(e.target.value.trim())} />
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Test File </label>
                                    <input type="file" class="form-control mb-3"  ref={testFileRef} placeholder='Test File' />
                                    <a className='text-blue' target="_blank" href={testFile}>{testFile}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Train File</label>
                                    <input type="file" class="form-control mb-3" ref={trainFileRef} placeholder='Train File' />
                                    <a className='text-blue' target="_blank" href={trainFile}>{trainFile}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Sample Submission</label>
                                    <input type="file" class="form-control mb-3" ref={sampleSubmissionRef} placeholder='Sample Submission' />
                                    <a className='text-blue' target="_blank" href={sampleSubmission}>{sampleSubmission}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Sample Code File </label>
                                    <input type="file" class="form-control mb-3" ref={sampleCodeFileRef} placeholder='Sample Code File' />
                                    <a className='text-blue' target="_blank" href={sampleCodeFile}>{sampleCodeFile}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Ideal Solution </label>
                                    <input type="file" class="form-control mb-3" ref={idealSolutionRef} placeholder='Ideal Solution' />
                                    <a className='text-blue' target="_blank" href={idealSolution}>{idealSolution}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Private Lb Solution </label>
                                    <input type="file" class="form-control mb-3" ref={privateLbSolutionRef} placeholder='Private Lb Solution' />
                                    <a className='text-blue' target="_blank" href={privateLbSolution}>{privateLbSolution}</a>
                                </div>

                                <div class="mb-3">
                                    <label class="form-label text-white">Public Lb Solution</label>
                                    <input type="file" class="form-control mb-3" ref={publicLbSolutionRef} placeholder='Public Lb Solution' />
                                    <a className='text-blue' target="_blank" href={publicLbSolution}>{publicLbSolution}</a>
                                </div>

                                <div className="form-group mb-3">
                                    <label className="text-white">External Sample Submission Url</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="External Sample Submission Url"
                                        value={externalSampleSubmissionUrl}
                                        onChange={(e) => setExternalSampleSubmissionUrl(e.target.value.trim())}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="text-white">External Test File Url</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="External Test File Url"
                                        value={externalTestFileUrl}
                                        onChange={(e) => setExternalTestFileUrl(e.target.value.trim())}
                                    />
                                </div>

                                <div className="form-group mb-3">
                                    <label className="text-white">External Train File Url</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="External Train File Url"
                                        value={externalTrainFileUrl}
                                        onChange={(e) => setExternalTrainFileUrl(e.target.value.trim())}
                                    />
                                </div>

                                <div className="mb-3">                                         
                                    <label className="me-3 text-white">Data Dictionary</label>
                                    <Editor
                                        apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
                                        init={{
                                            plugins: 'anchor autolink codesample image',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                        }}
                                        initialValue={intialdataDictionary}
                                        onEditorChange={(content,editor)=>{setDataDictionary(content)}}
                                    />
                                </div>

                                <div className='text-center'>
                                    <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold'>Update Problem Statement</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProblemStatement);

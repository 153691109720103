import React, { useState } from 'react';
import { getQueryData } from '../../../../utils';

function CustomFormCreate({ onFormCreate }) {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    createdBy: '',
    platformName: ''
  });

  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { title, description, createdBy, platformName } = formData;
    const descriptionValue = description.trim() ? `"${description}"` : 'null';
    const createdByIdValue = createdBy.trim() ? `"${createdBy}"` : 'null';
    const platformNameValue = platformName.trim() ? `"${platformName}"` : 'null';

    const query = `
      mutation CreateForm {
        createForm(title: "${title}", description: ${descriptionValue}, createdBy: ${createdByIdValue}, platformName: ${platformNameValue}) {
          form {
            id
          }
        }
      }
    `;

    getQueryData(query, "newcustomforms")
      .then((data) => {
        const formId = data.data.createForm.form.id;
        onFormCreate(formId);
        // console.log(data.data);
        setFormData((formData) => ({ ...formData, formId }));
        // console.log('Updated formData:', JSON.stringify(formData, null, 2));
        console.log(formId);
      })
      .catch((error) => {
        setError('Error creating the form. Please try again.');
        console.error('Error fetching data:', error);
      });
  };

  return (
    <>
      <section id="generateForm" className='py-5'>
        <div className='container'>
          <div className='row d-flex justify-content-center align-items-center'>
            <div className='col-lg-8'>
              <div className='card p-4 border-0 shadow text-center background-dark-tertiary'>
                <div className="card-body">
                  <h3 className="card-title mb-3 text-white text-uppercase">Request Form Creation</h3>
                  {error && (
                    <div className="alert text-danger d-block">
                      {error}
                    </div>
                  )}
                  <form onSubmit={handleSubmit} className='w-100'>
                    <div className="form-group mb-3">
                      <input
                        type="text"
                        value={formData.title}
                        onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                        className='form-control'
                        placeholder='Title'
                      />
                    </div>

                    <div className="form-group mb-3">
                      <textarea
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        className='form-control'
                        placeholder='Description'
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        value={formData.createdBy}
                        onChange={(e) => setFormData({ ...formData, createdBy: e.target.value })}
                        className='form-control'
                        placeholder='Created By'
                      />
                    </div>

                    <div className="form-group mb-3">
                      <input
                        type="text"
                        value={formData.platformName}
                        onChange={(e) => setFormData({ ...formData, platformName: e.target.value })}
                        className='form-control'
                        placeholder='Tenant ID'
                      />
                    </div>

                    <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3'>
                      I Want To Create A Form
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CustomFormCreate;

import React, { useEffect, useRef, useState } from "react";
import { getQueryData } from "../../../../utils";
import Header from "../../../common/Header/Header";
import { Editor } from '@tinymce/tinymce-react';
import { connect } from "react-redux";
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';


const CreateProblemStatement = ({csrf}) => {
    const [stageId, setStageId] = useState(null);
    const [metricId, setMetricId] = useState(null);
    const [dataDictionary,setDataDictionary] = useState('');
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [allowedExtensions, setAllowedExtensions] = useState("");
    const [allowedExtensionsCode, setAllowedExtensionsCode] = useState("");
    const [externalSampleSubmissionUrl, setExternalSampleSubmissionUrl] = useState("");
    const [externalTestFileUrl, setExternalTestFileUrl] = useState("");
    const [externalTrainFileUrl, setExternalTrainFileUrl] = useState("");
    const testFileInputRef = useRef(null);
    const trainFileInputRef = useRef(null);
    const sampleSubmissionFileInputRef = useRef(null);
    const sampleCodeFileInputRef = useRef(null);
    const idealSolutionFileInputRef = useRef(null);
    const publicLbSolutionFileInputRef = useRef(null);
    const privateLbSolutionFileInputRef = useRef(null);

    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);

    const [stagesData,setStagesData] = useState(null);
    const [selectedStageTitle, setSelectedStageTitle] = useState("");
    const [metricsData,setMetricsData] = useState(null);
    const [selectedMetricsName, setSelectedMetricsName] = useState("");

    useEffect(()=>{
        let query = `query MyQuery {
            allStage(limit: 100000, page: 1) {
                totalPages
                stageData {
                  id
                  slug
                  updatedAt
                  createdAt
                  title
                }
            }
            allMetrics(limit: 100000, page: 1) {
                evaluationMetric {
                  id
                  name
                }
            }
        }`;
        getQueryData(query,"newcompetition")
        .then(data =>{
            setStagesData(data?.data?.allStage.stageData);
            setMetricsData(data?.data?.allMetrics.evaluationMetric);
        })
        .catch(error => {
            console.error(error);
        })
    },[])

    const handleStageChange = (event) => {
        if (stagesData.some((stage) => {
            if(stage.id === event.target.value) {
                setSelectedStageTitle(stage.title);
            }
            return stage.id === event.target.value;
        })) {
            setStageId(event.target.value);
          } else {
            setStageId(null);
            setSelectedStageTitle("");
          }
    };

    const handleMetricChange = (event) => {
        if (metricsData.some((metric) => {
            if(metric.id === event.target.value) {
                setSelectedMetricsName(metric.name);
            }
            return metric.id === event.target.value;
        })) {
            setMetricId(event.target.value);
          } else {
            setMetricId(null);
            setSelectedMetricsName("");
          }
    };

    const resetFormFields = () => {
        setStageId(null);
        setMetricId(null);
        setDataDictionary("");
        setTitle("");
        setDescription("");
        setAllowedExtensions("");
        setAllowedExtensionsCode("");
        setExternalSampleSubmissionUrl("");
        setExternalTestFileUrl("");
        setExternalTrainFileUrl("");
        testFileInputRef.current.value = '';
        trainFileInputRef.current.value = '';
        sampleSubmissionFileInputRef.current.value = '';
        sampleCodeFileInputRef.current.value = '';
        idealSolutionFileInputRef.current.value = '';
        publicLbSolutionFileInputRef.current.value = '';
        privateLbSolutionFileInputRef.current.value = '';

        setSelectedStageTitle("");
        setSelectedMetricsName("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            "stage_id": stageId,
            "metric_id": metricId,
            "title": title,
            "description": description,
            "allowed_extensions": allowedExtensions,
            "allowed_extensions_code": allowedExtensionsCode,
            "data_dictionary":dataDictionary,
            "external_sample_submission_url":externalSampleSubmissionUrl,
            "external_test_file_url":externalTestFileUrl,
            "external_train_file_url":externalTrainFileUrl
          };
      
          const formData = new FormData();
          formData.append("test_file", testFileInputRef.current.files[0]);
          formData.append("train_file", trainFileInputRef.current.files[0]);
          formData.append("sample_submission_file", sampleSubmissionFileInputRef.current.files[0]);
          formData.append("sample_code_file", sampleCodeFileInputRef.current.files[0]);
          formData.append("ideal_solution_file", idealSolutionFileInputRef.current.files[0]);
          formData.append("public_lb_solution_file", publicLbSolutionFileInputRef.current.files[0]);
          formData.append("private_lb_solution_file", privateLbSolutionFileInputRef.current.files[0]);
          
          formData.append("platform", 'newcompetition');
          formData.append("url", 'api/problem-statement/');
          formData.append("payload", JSON.stringify(payload));
          formData.append("forward_user_data","True");
      
          fetch(process.env.REACT_APP_ELEVATE_USER_REST_URL, {
            method: 'POST',
            body:formData,
            credentials: process.env.REACT_APP_CREDENTIAL_TYPE,
            headers: {
                "X-CSRFToken":csrf,
            },
          }).then(response => {
                if (!response.ok) {
                    setIsError(true);
                    setTimeout(() => {
                        setIsError(false);
                    }, 3000);
                    return false;
                }
                return response.json();
            })
            .then((result)=> {
                if(result){
                    setIsSuccess(true);
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 3000);
                    resetFormFields();
                }
            })
            .catch(error => console.log('error', error));

    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={"Network response was not ok"} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Problem statement created successfully"} />}
            <section id='createStage' className="common-style background-dark-primary">
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-8'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold">Create Problem Statement</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>

                                        <div className="form-group mb-3">
                                            <span className=" text-white me-2">Choose Stage from the list:</span>
                                            <input list="stages" onChange={handleStageChange} placeholder="Select a stage"/>
                                            <datalist id="stages">
                                                {stagesData && stagesData.map((stage) => (
                                                <option key={stage.id} value={stage.id}>
                                                    {stage.title}
                                                </option>
                                                ))}
                                            </datalist>
                                            <p className="text-white pt-2">Selected stage Name: <strong>{selectedStageTitle}</strong></p>
                                        </div>

                                        <div className="form-group mb-3">
                                            <span className=" text-white me-2">Choose Metric from the list:</span>
                                            <input list="metrics" onChange={handleMetricChange} placeholder="Select a metric"/>
                                            <datalist id="metrics">
                                                {metricsData && metricsData.map((metric) => (
                                                <option key={metric.id} value={metric.id}>
                                                    {metric.name}
                                                </option>
                                                ))}
                                            </datalist>
                                            <p className="text-white pt-2">Selected metric Name: <strong>{selectedMetricsName}</strong></p>
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Title <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Title' 
                                            value={title}
                                            onChange={(event)=>setTitle(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Description <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Description' 
                                            value={description}
                                            onChange={(event)=>setDescription(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Allowed Extensions <sup className='text-danger'>*</sup></label>
                                            <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Input comma separated extensions like: .py,.csv,.pynb"
                                            value={allowedExtensions}
                                            onChange={(e) => setAllowedExtensions(e.target.value.trim())}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Allowed Extensions Code <sup className='text-danger'>*</sup></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Input comma separated extensions like: .py,.csv,.pynb"
                                                value={allowedExtensionsCode}
                                                onChange={(e) => setAllowedExtensionsCode(e.target.value.trim())}
                                                required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">External Sample Submission Url</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="External Sample Submission Url"
                                                value={externalSampleSubmissionUrl}
                                                onChange={(e) => setExternalSampleSubmissionUrl(e.target.value.trim())}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">External Test File Url</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="External Test File Url"
                                                value={externalTestFileUrl}
                                                onChange={(e) => setExternalTestFileUrl(e.target.value.trim())}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">External Train File Url</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="External Train File Url"
                                                value={externalTrainFileUrl}
                                                onChange={(e) => setExternalTrainFileUrl(e.target.value.trim())}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Test File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={testFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Train File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={trainFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Sample Submission File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={sampleSubmissionFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Sample Code File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={sampleCodeFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Ideal Solution File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={idealSolutionFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Public Leaderboard Solution File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={publicLbSolutionFileInputRef}
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <label className="text-white pe-2">Private Leaderboard Solution File:</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                ref={privateLbSolutionFileInputRef}
                                            />
                                        </div>
                                        
                                        <div className="mb-3">                                         
                                            <label className="me-3 text-white">Data Dictionary</label>
                                            <Editor
                                                apiKey={process.env.REACT_APP_TINYMCE_TOKEN}
                                                init={{
                                                    plugins: 'anchor autolink codesample image',
                                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                                }}
                                                initialValue=""
                                                onEditorChange={(content,editor)=>{setDataDictionary(content)}}
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Problem Statement
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProblemStatement);

export const FETCH_GENAI_CONTENT_REQUEST = 'FETCH_GENAI_CONTENT_REQUEST';
export const FETCH_GENAI_CONTENT_SUCCESS = 'FETCH_GENAI_CONTENT_SUCCESS';
export const FETCH_GENAI_CONTENT_FAILURE = 'FETCH_GENAI_CONTENT_FAILURE';
export const FETCH_BLACKBELT_CONTENT_REQUEST = 'FETCH_BLACKBELT_CONTENT_REQUEST';
export const FETCH_BLACKBELT_CONTENT_SUCCESS = 'FETCH_BLACKBELT_CONTENT_SUCCESS';
export const FETCH_BLACKBELT_CONTENT_FAILURE = 'FETCH_BLACKBELT_CONTENT_FAILURE';
export const FETCH_HOMEPAGE_CONTENT_REQUEST = 'FETCH_HOMEPAGE_CONTENT_REQUEST';
export const FETCH_HOMEPAGE_CONTENT_SUCCESS = 'FETCH_HOMEPAGE_CONTENT_SUCCESS';
export const FETCH_HOMEPAGE_CONTENT_FAILURE = 'FETCH_HOMEPAGE_CONTENT_FAILURE';
export const FETCH_BLOG_CONTENT_REQUEST = 'FETCH_BLOG_CONTENT_REQUEST';
export const FETCH_BLOG_CONTENT_SUCCESS = 'FETCH_BLOG_CONTENT_SUCCESS';
export const FETCH_BLOG_CONTENT_FAILURE = 'FETCH_BLOG_CONTENT_FAILURE';
export const FETCH_DATAHOUR_CONTENT_SUCCESS = 'FETCH_DATAHOUR_CONTENT_SUCCESS';
export const FETCH_HACKATHON_CONTENT_SUCCESS = 'FETCH_HACKATHON_CONTENT_SUCCESS';
export const UPDATE_WELCOME_LOGIN_MODAL = 'UPDATE_WELCOME_LOGIN_MODAL';
export const FETCH_COMPETITION_CONTENT_SUCCESS = 'FETCH_COMPETITION_CONTENT_SUCCESS';
export const FETCH_FREE_COURSES_CONTENT_REQUEST = 'FETCH_FREE_COURSES_CONTENT_REQUEST';
export const FETCH_FREE_COURSES_CONTENT_SUCCESS = 'FETCH_FREE_COURSES_CONTENT_SUCCESS';
export const FETCH_FREE_COURSES_CONTENT_FAILURE = 'FETCH_FREE_COURSES_CONTENT_FAILURE';
export const FETCH_COMPETITION_LIST_CONTENT_SUCCESS = 'FETCH_COMPETITION_LIST_CONTENT_SUCCESS';
export const FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS = 'FETCH_COMPETITION_DETAIL_CONTENT_SUCCESS';
export const FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_CLOSED_BLOGATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_ACTIVE_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_CLOSED_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LATE_SUBMISSION_HACKATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_CLOSED_JOBATHON_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_EVENT_LIST_CONTENT_SUCCESS = 'FETCH_EVENT_LIST_CONTENT_SUCCESS';
export const FETCH_COMMENT_CONTENT_SUCCESS = 'FETCH_COMMENT_CONTENT_SUCCESS';
export const FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS='FETCH_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS';
export const FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS='FETCH_EVENT_DATAHOUR_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS';
export const FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_PAST_EVENT_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS = 'FETCH_HACKATHON_DETAIL_CONTENT_SUCCESS';
export const FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS = 'FETCH_HACKATHON_DETAIL_REGISTRATION_SUCCESS';
export const FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS = 'FETCH_HACKATHON_DETAIL_SUBMISSION_SUCCESS';
export const FETCH_HACKATHON_TEAM_DETAIL_SUCCESS = 'FETCH_HACKATHON_TEAM_DETAIL_SUCCESS';
export const FETCH_SEE_WHERE_YOU_STAND_SUCCESS = 'FETCH_SEE_WHERE_YOU_STAND_SUCCESS';
export const FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS = 'FETCH_LEADING_WITH_DATA_LIST_CONTENT_SUCCESS';
export const FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LAST_WEEK_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LAST_MONTH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LAST_3_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LAST_6_MONTHS_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS = 'FETCH_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS';
export const FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS = 'FETCH_EVENT_LEADING_WITH_DATA_SPEAKER_SESSION_DETAIL_CONTENT_SUCCESS';
export const FETCH_BLOGATHON_LIST_CONTENT_SUCCESS = 'FETCH_BLOGATHON_LIST_CONTENT_SUCCESS';
export const FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS = 'FETCH_BLACKBELT_LEAD_PAGES_CONTENT_SUCCESS';
export const FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS = 'FETCH_GENAI_LEAD_PAGES_CONTENT_SUCCESS';
export const FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS = 'FETCH_LEADING_WITH_DATA_BY_PAGE_NUMBER_CONTENT_SUCCESS';
export const CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS = 'CLEAR_HACKATHON_DETAIL_CONTENT_SUCCESS';
export const CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS = "CLEAR_EVENT_DATAHOUR_DETAIL_CONTENT_SUCCESS";
export const CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS = "CLEAR_LEADING_WITH_DATA_DETAILS_CONTENT_SUCCESS";
export const CLEAR_CSRF = "CLEAR_CSRF";
export const FETCH_AGENTICAI_CONTENT_REQUEST = 'FETCH_AGENTICAI_CONTENT_REQUEST';
export const FETCH_AGENTICAI_CONTENT_SUCCESS = 'FETCH_AGENTICAI_CONTENT_SUCCESS';
export const FETCH_AGENTICAI_CONTENT_FAILURE = 'FETCH_AGENTICAI_CONTENT_FAILURE';
export const FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS = 'FETCH_AGENTICAI_LEAD_PAGES_CONTENT_SUCCESS';
import React, { useEffect } from 'react';
import AvLogo from '../../../assets/images/AV_logo.png';
import $ from 'jquery';
import { allCookies, necessaryCookies } from '../../../utils/Cookies';

export default function Cookies({platform}) {
    useEffect(()=>{
        $(document).ready(function () {
            if (localStorage.getItem(`cookie_consent_${platform}`)) {
                $("#Cookies").css("display", "none");
                allCookies(platform);
            }
        })
    },[])
  return (
    <>
        <div className="cookies-backdrop"></div>
        <div className="cookies-based-content background-dark-secondary position-fixed bottom-0 w-100 z-3 p-3" id="Cookies">
            <div className="container">
            <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12">
                <p className='text-white fs-12 fw-light mb-1'>
                    We use cookies essential for this site to function well. Please click to help us improve its usefulness with additional cookies. Learn about our use of cookies in our  <a href={'/privacy-policy'} className='text-white' href="https://www.analyticsvidhya.com/privacy-policy/?utm_source=courses&amp;utm_medium=footer">Privacy Policy</a> & <a href={'/cookies-policy'} className='text-white'  href="https://www.analyticsvidhya.com/cookies-policy/">Cookies Policy</a>.
                </p>
                <p className="text-white mt-1 pointer text-decoration-underline fs-16 fw-light m-1" data-bs-toggle="modal" data-bs-target="#cookiesModal">Show details</p>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 justify-content-center row align-items-center">
                <div className="col-sm col-12">
                    <button type="button" className="btn btn-dark btn-dark-primary rounded-3 fs-12 w-100 mb-2 mb-sm-0" onClick={()=>{allCookies(platform)}} aria-label='Accept all cookies'>Accept all cookies</button>
                </div>
                <div className="col-sm col-12">
                    <button type="button" className="col btn btn-dark btn-dark-primary rounded-3 fs-12 w-100" onClick={()=>{necessaryCookies(platform)}} aria-label='Use Necessary cookies'>Use necessary cookies</button>
                </div>
            </div>
            </div>
            </div>
        </div>
        <div className="modal fade" id="cookiesModal" tabIndex="-1" aria-labelledby="cookiesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content background-dark-secondary p-4">
                    <div className="modal-header border-0">
                        <h5 className="modal-title fs-16 fw-normal text-white" id="cookiesModalLabel">Powered By  
                            <img className='ms-2' src={AvLogo} width={'114'} height={'33'} alt="Av Logo White" loading="lazy" />
                        </h5>
                        <button type="button" className="btn background-dark-tertiary p-2 rounded-circle" data-bs-dismiss="modal" aria-label="Close">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                        </button>            
                    </div>
                    <div className="modal-body p-0">
                        <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active text-center w-100" id="consent-tab" data-bs-toggle="tab" data-bs-target="#consent" type="button" role="tab" aria-controls="consent" aria-selected="true">Consent</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-center w-100" id="details-tab" data-bs-toggle="tab" data-bs-target="#details" type="button" role="tab" aria-controls="details" aria-selected="false">Details</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-center w-100" id="about-tab" data-bs-toggle="tab" data-bs-target="#about" type="button" role="tab" aria-controls="about" aria-selected="false">About</button>
                            </li>
                        </ul>
                        <div className="tab-content pt-3 px-4" id="myTabContent">
                            <div className="tab-pane fade show active py-3" id="consent" role="tabpanel" aria-labelledby="consent-tab">
                                <h6 className='text-white'>Cookies</h6>
                                <p className='text-white'>This site uses cookies to ensure that you get the best experience possible. To learn more about how we use cookies, please refer to our <a href={'/privacy-policy'}  className='text-white' target='_blank' >Privacy Policy</a> & <a href={'/cookies-policy'}  className='text-white' target='_blank'>Cookies Policy</a>.</p>
                            </div>
                            <div className="tab-pane fade py-2" id="details" role="tabpanel" aria-labelledby="details-tab">
                                <div className="accordion">
                                    <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed d-block" aria-controls="collapseNecessary" aria-expanded="true" data-bs-target="#collapseNecessary" data-bs-toggle="collapse" type="button">
                                            <h6>Necessary (2)</h6>
                                            <p className="mt-3">Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</p>
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" aria-labelledby="headingNecessary" data-bs-parent="#accordionDetails" id="collapseNecessary">
                                        <div className="accordion-body">
                                            <div className="accordion">
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseNecessaryAnalyticsVidhya" aria-expanded="true" data-bs-target="#collapseNecessaryAnalyticsVidhya" data-bs-toggle="collapse" type="button">
                                                        <h6>Analytics Vidhya (4)</h6>
                                                        <a className='text-white fw-light' href="https://www.analyticsvidhya.com/privacy-policy/" target="_blank">
                                                            learn more about analytics vidhya privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="NecessaryAnalyticsVidhya" data-bs-parent="#collapseNecessary" id="collapseNecessaryAnalyticsVidhya">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>brahmaid</h6>
                                                            <p>It is needed for personalizing the website.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>csrftoken</h6>
                                                            <p>This cookie is used to prevent Cross-site request forgery (often abbreviated as CSRF) attacks of the website</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTPS</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>Identityid</h6>
                                                            <p>Preserves the login/logout state of users across the whole site.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTPS</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>sessionid</h6>
                                                            <p>Preserves users' states across page requests.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTPS</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseNecessaryGoogle" aria-expanded="true" data-bs-target="#collapseNecessaryGoogle" data-bs-toggle="collapse" type="button">
                                                        <h6>Google (1)</h6>
                                                        <a className='text-white fw-light' href="https://policies.google.com/privacy" target="_blank">
                                                            learn more about google privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="NecessaryGoogle" data-bs-parent="#collapseNecessary" id="collapseNecessaryGoogle">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>g_state</h6>
                                                            <p>Google One-Tap login adds this g_state cookie to set the user status on how they interact with the One-Tap modal.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>365 days</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed d-block" aria-controls="collapseStatistics" aria-expanded="true" data-bs-target="#collapseStatistics" data-bs-toggle="collapse" type="button">
                                            <h6>Statistics (4)</h6>
                                            <p className="mt-3">Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</p>
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" aria-labelledby="headingStatistics" data-bs-parent="#accordionDetails" id="collapseStatistics">
                                        <div className="accordion-body">
                                            <div className="accordion">
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseStatisticsMicrosoft" aria-expanded="true" data-bs-target="#collapseStatisticsMicrosoft" data-bs-toggle="collapse" type="button">
                                                        <h6>Microsoft (7)</h6>
                                                        <a className='text-white fw-light' href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">
                                                            learn more about microsoft policy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="StatisticsMicrosoft" data-bs-parent="#collapseStatistics" id="collapseStatisticsMicrosoft">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>MUID</h6>
                                                            <p>Used by Microsoft Clarity, to store and track visits across websites.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 Year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_clck</h6>
                                                            <p>Used by Microsoft Clarity, Persists the Clarity User ID and preferences, unique to that site, on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_clsk</h6>
                                                            <p>Used by Microsoft Clarity, Connects multiple page views by a user into a single Clarity session recording.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 Day</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SRM_I</h6>
                                                            <p>Collects user data is specifically adapted to the user or device. The user can also be followed outside of the loaded website, creating a picture of the visitor's behavior.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SM</h6>
                                                            <p>Use to measure the use of the website for internal analytics</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>CLID</h6>
                                                            <p>The cookie is set by embedded Microsoft Clarity scripts. The purpose of this cookie is for heatmap and session recording.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SRM_B</h6>
                                                            <p>Collected user data is specifically adapted to the user or device. The user can also be followed outside of the loaded website, creating a picture of the visitor's behavior.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseStatisticsGoogle" aria-expanded="true" data-bs-target="#collapseStatisticsGoogle" data-bs-toggle="collapse" type="button">
                                                        <h6>Google (7)</h6>
                                                        <a className='text-white fw-light' href="https://policies.google.com/privacy" target="_blank">
                                                            learn more about google privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="StatisticsGoogle" data-bs-parent="#collapseStatistics" id="collapseStatisticsGoogle">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_gid</h6>
                                                            <p>This cookie is installed by Google Analytics. The cookie is used to store information of how visitors use a website and helps in creating an analytics report of how the website is doing. The data collected includes the number of visitors, the source where they have come from, and the pages visited in an anonymous form.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>399 days</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_ga_#</h6>
                                                            <p>Used by Google Analytics, to store and count pageviews.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>399 Days</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_gat_#</h6>
                                                            <p>Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 day</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>collect</h6>
                                                            <p>Used to send data to Google Analytics about the visitor's device and behavior. Tracks the visitor across devices and marketing channels.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>PIXEL</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>AEC</h6>
                                                            <p>cookies ensure that requests within a browsing session are made by the user, and not by other sites.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>G_ENABLED_IDPS</h6>
                                                            <p>use the cookie when customers want to make a referral from their gmail contacts; it helps auth the gmail account.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>test_cookie</h6>
                                                            <p>This cookie is set by DoubleClick (which is owned by Google) to determine if the website visitor's browser supports cookies.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseStatisticsWebengage" aria-expanded="true" data-bs-target="#collapseStatisticsWebengage" data-bs-toggle="collapse" type="button">
                                                        <h6>Webengage (2)</h6>
                                                        <a className='text-white fw-light' href="https://webengage.com/privacy-policy/" target="_blank">
                                                            Learn more about webengage privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="StatisticsWebengage" data-bs-parent="#collapseStatistics" id="collapseStatisticsWebengage">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_we_us</h6>
                                                            <p>this is used to send push notification using webengage.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>WebKlipperAuth</h6>
                                                            <p>used by webenage to track auth of webenagage.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseStatisticsLinkedIn" aria-expanded="true" data-bs-target="#collapseStatisticsLinkedIn" data-bs-toggle="collapse" type="button">
                                                        <h6>LinkedIn (16)</h6>
                                                        <a className='text-white fw-light' href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
                                                            learn more about linkedin privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="StatisticsLinkedIn" data-bs-parent="#collapseStatistics" id="collapseStatisticsLinkedIn">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>ln_or</h6>
                                                            <p>Linkedin sets this cookie to registers statistical data on users' behavior on the website for internal analytics.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 day</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>JSESSIONID</h6>
                                                            <p>Use to maintain an anonymous user session by the server.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>li_rm</h6>
                                                            <p>Used as part of the LinkedIn Remember Me feature and is set when a user clicks Remember Me on the device to make it easier for him or her to sign in to that device.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>AnalyticsSyncHistory</h6>
                                                            <p>Used to store information about the time a sync with the lms_analytics cookie took place for users in the Designated Countries.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>lms_analytics</h6>
                                                            <p>Used to store information about the time a sync with the AnalyticsSyncHistory cookie took place for users in the Designated Countries.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>liap</h6>
                                                            <p>Cookie used for Sign-in with Linkedin and/or to allow for the Linkedin follow feature.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>visit</h6>
                                                            <p>allow for the Linkedin follow feature.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>li_at</h6>
                                                            <p>often used to identify you, including your name, interests, and previous activity.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>s_plt</h6>
                                                            <p>Tracks the time that the previous page took to load</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>lang</h6>
                                                            <p>Used to remember a user's language setting to ensure LinkedIn.com displays in the language selected by the user in their settings</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>s_tp</h6>
                                                            <p>Tracks percent of page viewed</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>AMCV_14215E3D5995C57C0A495C55%40AdobeOrg</h6>
                                                            <p>Indicates the start of a session for Adobe Experience Cloud</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>s_pltp</h6>
                                                            <p>Provides page name value (URL) for use by Adobe Analytics</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>Session</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>s_tslv</h6>
                                                            <p>Used to retain and fetch time since last visit in Adobe Analytics</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>li_theme</h6>
                                                            <p>Remembers a user's display preference/theme setting</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>li_theme_set</h6>
                                                            <p>Remembers which users have updated their display / theme preferences</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed d-block" aria-controls="collapsePreferences" aria-expanded="true" data-bs-target="#collapsePreferences" data-bs-toggle="collapse" type="button">
                                            <h6>Preferences (0)</h6>
                                            <p className="mt-3">Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</p>
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" aria-labelledby="headingPreferences" data-bs-parent="#accordionDetails" id="collapsePreferences">
                                        <div className="accordion-body">
                                            <div className="accordion">
                                                <div className="fw-medium accordion-item text-white fs-18">We do not use cookies of this type.</div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed d-block" aria-controls="collapseMarketing" aria-expanded="true" data-bs-target="#collapseMarketing" data-bs-toggle="collapse" type="button">
                                            <h6>Marketing (4)</h6>
                                            <p className="mt-3">Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</p>
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" aria-labelledby="headingMarketing" data-bs-parent="#accordionDetails" id="collapseMarketing">
                                        <div className="accordion-body">
                                            <div className="accordion">
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseMarketingGoogle" aria-expanded="true" data-bs-target="#collapseMarketingGoogle" data-bs-toggle="collapse" type="button">
                                                        <h6>Google (11)</h6>
                                                        <a className='text-white fw-light' href="https://policies.google.com/privacy" target="_blank">
                                                            learn more about google privacy 
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="MarketingGoogle" data-bs-parent="#collapseMarketing" id="collapseMarketingGoogle">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_gcl_au</h6>
                                                            <p>Used by Google Adsense, to store and track conversions.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>3 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SID</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SAPISID</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>__Secure-#</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>APISID</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>SSID</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>HSID</h6>
                                                            <p>Save certain preferences, for example the number of search results per page or activation of the SafeSearch Filter. Adjusts the ads that appear in Google Search.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 years</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>DV</h6>
                                                            <p>These cookies are used for the purpose of targeted advertising.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 hours</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>NID</h6>
                                                            <p>These cookies are used for the purpose of targeted advertising.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 month</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>1P_JAR</h6>
                                                            <p>These cookies are used to gather website statistics, and track conversion rates.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 month</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>OTZ</h6>
                                                            <p>Aggregate analysis of website visitors</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseMarketingFacebook" aria-expanded="true" data-bs-target="#collapseMarketingFacebook" data-bs-toggle="collapse" type="button">
                                                        <h6>Facebook (2)</h6>
                                                        <a className='text-white fw-light' href="https://www.facebook.com/privacy/policy/" target="_blank">
                                                            learn more about facebook privacy
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="MarketingFacebook" data-bs-parent="#collapseMarketing" id="collapseMarketingFacebook">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>_fbp</h6>
                                                            <p>This cookie is set by Facebook to deliver advertisements when they are on Facebook or a digital platform powered by Facebook advertising after visiting this website.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>4 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>fr</h6>
                                                            <p>Contains a unique browser and user ID, used for targeted advertising.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>2 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseMarketingLinkedIn" aria-expanded="true" data-bs-target="#collapseMarketingLinkedIn" data-bs-toggle="collapse" type="button">
                                                        <h6>LinkedIn (6)</h6>
                                                        <a className='text-white fw-light' href="https://www.linkedin.com/legal/privacy-policy" target="_blank">
                                                            Learn about linkedin policy
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="MarketingLinkedIn" data-bs-parent="#collapseMarketing" id="collapseMarketingLinkedIn">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>bscookie</h6>
                                                            <p>Used by LinkedIn to track the use of embedded services.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>lidc</h6>
                                                            <p>Used by LinkedIn for tracking the use of embedded services.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 day</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>bcookie</h6>
                                                            <p>Used by LinkedIn to track the use of embedded services.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>aam_uuid</h6>
                                                            <p>Use these cookies to assign a unique ID when users visit a website.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>UserMatchHistory</h6>
                                                            <p>These cookies are set by LinkedIn for advertising purposes, including: tracking visitors so that more relevant ads can be presented, allowing users to use the 'Apply with LinkedIn' or the 'Sign-in with LinkedIn' functions, collecting information about how visitors use the site, etc.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>6 months</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>li_sugr</h6>
                                                            <p>Used to make a probabilistic match of a user's identity outside the Designated Countries</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>90 days</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed d-block" aria-controls="collapseMarketingMicrosoft" aria-expanded="true" data-bs-target="#collapseMarketingMicrosoft" data-bs-toggle="collapse" type="button">
                                                        <h6>Microsoft (2)</h6>
                                                        <a className='text-white fw-light' href="https://privacy.microsoft.com/en-us/privacystatement" target="_blank">
                                                            Learn more about microsoft privacy.
                                                        </a>
                                                    </button>
                                                </h2>
                                                <div className="accordion-collapse collapse" aria-labelledby="MarketingMicrosoft" data-bs-parent="#collapseMarketing" id="collapseMarketingMicrosoft">
                                                    <div className="accordion-body">
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>MR</h6>
                                                            <p>Used to collect information for analytics purposes.</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 year</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className="card mb-3">
                                                            <div className="card-body pb-1">
                                                            <h6>ANONCHK</h6>
                                                            <p>Used to store session ID for a users session to ensure that clicks from adverts on the Bing search engine are verified for reporting purposes and for personalisation</p>
                                                            </div>
                                                            <div className="card-footer d-flex">
                                                            <div className="col-6">
                                                                <p>Expiry: <span>1 day</span></p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p>Type: <span>HTTP</span></p>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed d-block" aria-controls="collapseUnclassNameified" aria-expanded="true" data-bs-target="#collapseUnclassNameified" data-bs-toggle="collapse" type="button">
                                            <h6>UnclassNameified (0)</h6>
                                            <p className="mt-3">UnclassNameified cookies are cookies that we are in the process of classNameifying, together with the providers of individual cookies.</p>
                                        </button>
                                    </h2>
                                    <div className="accordion-collapse collapse" aria-labelledby="headingUnclassNameified" data-bs-parent="#accordionDetails" id="collapseUnclassNameified">
                                        <div className="accordion-body">
                                            <div className="accordion">
                                                <div className="fw-medium accordion-item text-white fs-18">We do not use cookies of this type.</div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                <p className="mt-3 ms-2 text-white">Cookie declaration last updated on 24/03/2023 by Analytics Vidhya.</p>
                            </div>
                            <div className="tab-pane fade py-2" id="about" role="tabpanel" aria-labelledby="about-tab">
                                <p className='text-white fs-18 fs-light'>Cookies are small text files that can be used by websites to make a user's experience more efficient. The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies, we need your permission. This site uses different types of cookies. Some cookies are placed by third-party services that appear on our pages. Learn more about who we are, how you can contact us, and how we process personal data in our <a href={'/privacy-policy'} className='text-white' target='_blank'>Privacy Policy</a>.</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-start border-0">
                        <button type="button" className="btn btn-dark btn-dark-primary rounded-3 fs-16" onClick={()=>{allCookies(platform)}} data-bs-dismiss="modal">Accept all cookies</button>
                        <button type="button" className="btn btn-dark btn-dark-primary rounded-3 fs-16 me-3" onClick={()=>{necessaryCookies(platform)}} data-bs-dismiss="modal">Use necessary cookies</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

import React, { Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchBlogContentSuccess, fetchDatahourContentSuccess, fetchHomepageContent, fetchHackathonContentSuccess, updateWelcomeLoginModal } from '../../../store/Actions/Action';
import HomepageHeader from './components/HomepageHeader';
import 'swiper/css';
import 'swiper/css/pagination';
import { fetchBlogData, fetchDatahackData, setImpactIrclickid } from '../../../utils';
import Title from '../../common/Header/Title';
import $ from 'jquery';
import Flashstrip from '../../common/Header/Flashstrip';
import HompageModal from './components/HompageModal';
const HomepageAllFold = React.lazy(() => import("./components/HomepageAllFold"));

const cloudflare_url = process.env.REACT_APP_CLOUDFLARE_URL;
const cloudflare_size_url=process.env.REACT_APP_CLOUDFLARE_URL_SIZE;

function Homepage({homepagecontent,fetchHomepageContentHandler,fetchBlogContentSuccessHandler,blogContent,fetchDatahourContentSuccessHandler,datahourContent,fetchHackathonContentSuccessHandler,hackathonContent,updateWelcomeLoginModalHandler,islogin}) {
  useEffect(() => { 
    // Function calling for getting homepage static data
    if(!homepagecontent){
      fetchHomepageContentHandler();
    }
    // Function calling for getting blog data for homepage
    if(!blogContent){
      fetchBlogData(process.env.REACT_APP_MARKETING_PAGES,'newmarketingpages')
      .then((data)=>{
        fetchBlogContentSuccessHandler(data);
      })
      .catch((error)=>{
        console.log(error.message);
      })
    }
    // Function calling for getting datahack data for homepage
    if(!hackathonContent){
      fetchDatahackData('hackathon')
      .then((data)=>{
        fetchHackathonContentSuccessHandler(data)
      })
      .catch((error)=>{
        console.log(error);
      })
    }
  }, []);


  useEffect(() => { 
    setImpactIrclickid();
  },[]);

  return (
    <main className='background-dark-primary'>
      <HompageModal />
      <Title title={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} description={'Analytics Vidhya is the leading community of Analytics, Data Science and AI professionals. We are building the next generation of AI professionals. Get the latest data science, machine learning, and AI courses, news, blogs, tutorials, and resources.'} image_og={cloudflare_size_url+'og.jpg?format=avif'} title_og={'Analytics Vidhya | The ultimate place for Generative AI, Data Science and Data Engineering'} url_og={'https://www.analyticsvidhya.com'} type_og={'website'} site_name_og={'Analytics Vidhya'} locale_og={'en_US'} />
      <HomepageHeader header_content={homepagecontent && homepagecontent.header} />
      <Flashstrip />
      <section className='bg-size-cover bg-repeat-0 bg-position-center pt-5 bg-transparent' id='heroContent' >
        <div className='container justify-content-center align-items-center h-100 position-relative z-1'>
          <div className='row justify-content-center align-items-center h-100'>
            <div className='col-lg-8 mx-auto text-center position-realtive'>
              <h1 className='fs-56 text-gradient mb-3'><span className='text-white'>Building</span> Next-Generation</h1>
              <h2 className='d-block text-white fs-56 mb-3'>AI Professionals</h2>
              <h3 className='fs-18 fw-normal text-white'>Unlocking knowledge, skills & opportunities through in-depth blogs, world class courses, exciting hackathons & a thriving community</h3>
              {!islogin && <button className="btn btn-dark btn-dark-primary mt-5 rounded-3 fs-16" onClick={()=>{updateWelcomeLoginModalHandler({welcomeloginmodal:true})}} >
                Login/Register
              </button>}
            </div>
          </div>
        </div>
        <video autoPlay loop muted>
          <source src={process.env.REACT_APP_CLOUDFLARE_URL+'Video/first_fold_video.mp4'} type="video/mp4" />
        </video>
      </section>
      <Suspense fallback={<div>Loading</div>}>
        <HomepageAllFold />
      </Suspense>
    </main>
  )
}


const mapStateToProps = (state) => ({
  homepagecontent:state.homepageContentReducer.homepagecontent,
  loading:state.homepageContentReducer.loading,
  error:state.homepageContentReducer.error,
  blogContent:state.blogContentReducer.blogcontent,
  datahourContent:state.datahourContentReducer.datahourcontent,
  hackathonContent:state.hackathonContentReducer.hackathoncontent,
  islogin:state.LoginModalReducer.islogin
});

const mapDispatchToProps = dispatch=>({
  fetchHomepageContentHandler:data=>dispatch(fetchHomepageContent(data)),
  fetchBlogContentSuccessHandler:data=>dispatch(fetchBlogContentSuccess(data)),
  fetchDatahourContentSuccessHandler:data=>dispatch(fetchDatahourContentSuccess(data)),
  fetchHackathonContentSuccessHandler:data=>dispatch(fetchHackathonContentSuccess(data)),
  updateWelcomeLoginModalHandler:data=>{dispatch(updateWelcomeLoginModal(data))}
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AgenticAiComingSoon1 from '../AgenticAi/pages/AgenticAiComingSoon1';
import AgenticAiComingSoon2 from '../AgenticAi/pages/AgenticAiComingSoon2';
import Error404 from '../../../common/Alert/Error404';
import AgenticAiComingSoon3 from '../AgenticAi/pages/AgenticAiComingSoon3';
import AgenticAiHome from '../AgenticAi/pages/index';
import AgenticAiLeadPagesOneField from '../AgenticAi/pages/AgenticAiLeadPagesOneField';
import AgenticAiLeadPagesTwoField from '../AgenticAi/pages/AgenticAiLeadPagesTwoField';

export default function AgenticAiRouters() {
  return (
    <Routes>
        <Route path='/' exact element={<AgenticAiHome />} />
        <Route path='/testing' exact element={<AgenticAiComingSoon1 />} />
        <Route path='/2' exact element={<AgenticAiComingSoon2 />} />
        <Route path='/3' exact element={<AgenticAiComingSoon3 />} />
        <Route path='/2/:platformName' exact element={<AgenticAiLeadPagesOneField />} />
        <Route path='/:platformName' exact element={<AgenticAiLeadPagesTwoField />} />
        <Route path='*' exact element={<Error404 />} />
    </Routes>
  )
}

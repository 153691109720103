import React, { useState } from 'react';
import { getQueryDataElevateUser } from "../../../../utils";
import Header from '../../../common/Header/Header';
import { connect } from 'react-redux';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';

const CreateEvaluationMetric = ({csrf,islogin}) => {
    const [iserror,setIsError] = useState(false);
    const [issuccess,setIsSuccess] = useState(false);
    const [message,setMessage] = useState("");

    const [name,setName] = useState("");
    const [codeHandlesValidation, setCodeHandlesValidation] = useState(false);
    const [code, setCode] = useState("");

    const resetFormFields = () => {
        setName("");
        setCode("");
        setCodeHandlesValidation(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let mutation = `mutation {
            createEvaluation(
                code: """${code}""", 
                codeHandlesValidation: ${codeHandlesValidation}, 
                name: "${name}"
            ) {
                success
            }
        }`
        console.log("muation", mutation);
        getQueryDataElevateUser(mutation, "newcompetition", "True", csrf)
        .then((result)=> {
            console.log(result);
            if(!result.errors){
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 3000);
                resetFormFields();
            }
            else{
                setIsError(true);
                setMessage(result.errors[0].message);
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            }
        })
        .catch(error => {
            setIsError(true);
            setMessage(error.message);
            setTimeout(() => {
                setIsError(false);
            }, 3000);
            console.log('error', error);
        });
        return false;
    }

    return (
        <>
            <Header />
            {iserror && <ErrorAlert message={message} setIsError={setIsError} />}
            {issuccess && <SuccessAlert message={"Metric added successfully"} />}
            <section id='createEvaluationMetric' className='common-style background-dark-primary min-vh-100'>
                <div className='container py-5 px-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-lg-10'>
                            <div className='card p-4 border-0 shadow background-dark-tertiary'>
                                <div className="card-body">
                                    <h3 className="card-title text-center mb-3 text-white fw-semibold text-uppercase">Evaluation Metric Form</h3>
                                    <form className='w-100' onSubmit={handleSubmit}>
                                        
                                        <div className="form-group mb-3">
                                            <label className="text-white">Name <sup className='text-danger'>*</sup></label>
                                            <input 
                                            type="text" 
                                            className='form-control' 
                                            placeholder='Name' 
                                            value={name}
                                            onChange={(event)=>setName(event.target.value)}
                                            required
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="me-3 text-white">Code Handles Validation?</label>
                                            <input
                                                type="checkbox"
                                                className='form-check-input'
                                                checked={codeHandlesValidation}
                                                onChange={(event) => setCodeHandlesValidation(event.target.checked)}
                                            />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label className="text-white">Code <sup className='text-danger'>*</sup></label>
                                            <textarea
                                                className='form-control'
                                                value={code}
                                                onChange={(event) => setCode(event.target.value)}
                                                style={{ height: '400px' }}
                                                required
                                            />
                                        </div>

                                        <button type="submit" className='btn btn-dark btn-dark-primary px-5 py-2 mt-3 text-uppercase fw-bold w-100'>
                                            Create Evaluation Metric
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


const mapStateToProps = (state) => ({
    csrf:state.LoginModalReducer.csrf,
    islogin:state.LoginModalReducer.islogin,
});

const mapDispatchToProps = dispatch=>({
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateEvaluationMetric);
import React, { useEffect, useState } from 'react'
import Header from '../../../common/Header/Header'
import Pagination from '../../../common/Page/Pagination'
import { convertDateInFormat, convertDateToLocalDatetime, getQueryDataUserProfile } from '../../../../utils';
import { useParams } from 'react-router-dom';
import ErrorAlert from '../../../common/Alert/ErrorAlert';
import SuccessAlert from '../../../common/Alert/SuccessAlert';
import Loader from '../../../common/Loader';

const UserCompetitionsProfile = () => {
  const [activeTab, setActiveTab] = useState('hackathon');
  const [activeIndex, setActiveIndex] = useState(0);
  const [hackathonData, setHackathonData] = useState(null);
  const [blogathonData, setBlogathonData] = useState(null);
  const [userDateJoined, setUserDateJoined] = useState(null);
  const { username } = useParams();

  const [showLoader, setShowLoader] = useState(true);
  const [iserror,setIsError] = useState(false);
  const [errorMessage,setErrorMessage] = useState("");
  const [issuccess,setIsSuccess] = useState(false);
  const [successMessage,setSuccessMessage] = useState("");

  useEffect(()=>{
    if(username) {
      let query = `query {
        userProfileData(tournamentType:"hackathon",page:1, limit:10) {
          totalPages
          userDateJoined
          tournamentData {
            title
            slug
            startDate
            endDate
            tournamentEnded
            tournamentType {
                name
            }
          }
        }
      }`;
      getQueryDataUserProfile(query,username,"newcompetition")
      .then((data)=>{
          setShowLoader(false);
          if(data.message) {
            setErrorMessage(data.message);
            setIsError(true);
            setTimeout(()=>{
                setIsError(false);
                setErrorMessage("");
            },3000)
          }
          setHackathonData(data?.data?.userProfileData);
          setUserDateJoined(data?.data?.userProfileData?.userDateJoined);
      })
      .catch((error)=>{
        setShowLoader(false);
        console.error(error.message);
        setErrorMessage(error.message);
        setIsError(true);
        setTimeout(()=>{
            setIsError(false);
            setErrorMessage("");
        },3000)
      })
    }
  },[username]);

  const navtabsClickHandler = (tab,index) => {
    setActiveTab(tab);
    setActiveIndex(index);

    if(username) {
      if((tab==="hackathon" ? !hackathonData : !blogathonData)) {
        setShowLoader(true);
        let query = `query {
          userProfileData(tournamentType:"${tab}",page:1, limit:10) {
            totalPages
            tournamentData {
              title
              slug
              startDate
              endDate
              tournamentEnded
              tournamentType {
                  name
              }
            }
          }
        }`;
        getQueryDataUserProfile(query,username,"newcompetition")
        .then((data)=>{
          setShowLoader(false);
          if(data.message) {
            setErrorMessage(data.message);
            setIsError(true);
            setTimeout(()=>{
                setIsError(false);
                setErrorMessage("");
            },3000)
          }
          tab==="hackathon" ? setHackathonData(data?.data?.userProfileData) : setBlogathonData(data?.data?.userProfileData);
        })
        .catch((error)=>{
          setShowLoader(false);
          console.error(error.message);
          setErrorMessage(error.message);
          setIsError(true);
          setTimeout(()=>{
              setIsError(false);
              setErrorMessage("");
          },3000)
        })
      }
    }
  }

  const pageChangeHandler = (tab,index,page) => {
    if(username) {
      setShowLoader(true);
      let query = `query {
        userProfileData(tournamentType:"${tab}",page:${page}, limit:10) {
          totalPages
          tournamentData {
            title
            slug
            startDate
            endDate
            tournamentEnded
            tournamentType {
                name
            }
          }
        }
      }`;
      getQueryDataUserProfile(query,username,"newcompetition")
      .then((data)=>{
        setShowLoader(false);
        if(data.message) {
          setErrorMessage(data.message);
          setIsError(true);
          setTimeout(()=>{
              setIsError(false);
              setErrorMessage("");
          },3000)
        }
        tab==="hackathon" ? setHackathonData(data?.data?.userProfileData) : setBlogathonData(data?.data?.userProfileData);
      })
      .catch((error)=>{
        setShowLoader(false);
        console.error(error.message);
          setErrorMessage(error.message);
          setIsError(true);
          setTimeout(()=>{
              setIsError(false);
              setErrorMessage("");
          },3000)
      })
    }
  }

  return (
    <>
    {showLoader && <Loader />}
    {iserror && <ErrorAlert message={errorMessage} setIsError={setIsError} />}
    {issuccess && <SuccessAlert message={successMessage} />}
      <Header />
      <section className='common-style background-dark-primary pb-5 min-vh-100' id="leaderboard">
          <div className="container">
              <div className='text-white heading-with-text mb-5'>
                  <span className='fs-32 fw-normal my-text-gradient'>{username}</span>
                  {userDateJoined && <p className='fs-16 fw-normal text-dark-tertiary'>Registered Since: {convertDateInFormat(convertDateToLocalDatetime(userDateJoined),"DD MMM YYYY")}</p>}
              </div>
              <ul class="nav nav-tabs px-2 py-3 rounded-2 av-tab-design overflow-auto flex-nowrap" id="hackathonDetailTab" role="tablist">
                  <li class="nav-item" role="presentation">
                      <button class={`nav-link text-nowrap ${(activeIndex === 0)? 'active' : ''}`} id={`hackathon-tab`} data-bs-toggle="tab" data-bs-target={`#hackathon`} type="button" role="tab" aria-controls={`hackathon`} aria-selected={(activeIndex === 0) ? "true" : "false"} onClick={()=>{navtabsClickHandler('hackathon',0)}}>Hackathons</button>
                  </li>
                  <li class="nav-item" role="presentation">
                      <button class={`nav-link text-nowrap ${(activeIndex === 1)? 'active' : ''}`} id={`blogathon-tab`} data-bs-toggle="tab" data-bs-target={`#blogathon`} type="button" role="tab" aria-controls={`blogathon`} aria-selected={(activeIndex === 1) ? "true" : "false"} onClick={()=>{navtabsClickHandler('blogathon',1)}}>Blogathons</button>
                  </li>
              </ul>
              <div class="tab-content py-4" id="hackathonDetailContent">
                <div class={`tab-pane fade ${(activeIndex === 0)? 'show active' : ''}`} id={`hackathon`} role="tabpanel" aria-labelledby={`hackathon-tab`}>
                    {hackathonData?.tournamentData?.length>0 ? <div class="table-responsive my-3">
                        <table class="table text-white background-dark-secondary table-striped">
                            <thead className='text-white border-bottom border-dark'>
                                <td >Contest Name</td>
                                <td >Start Date</td>
                                <td >End Date</td>
                                <td >Status</td>
                            </thead>
                            {hackathonData && hackathonData.tournamentData && hackathonData.tournamentData.length>0 && hackathonData.tournamentData.map((data,index)=>(
                                <tr>
                                <td class="text-nowrap"><a href={`/datahack/contest/${data.slug}`} className='text-decoration-none bg-transparent text-white'>{data.title}</a></td>
                                <td class="text-nowrap">{convertDateInFormat(convertDateToLocalDatetime(data.startDate),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(data.startDate),"hh:mm A")}</td>
                                <td class="text-nowrap">{convertDateInFormat(convertDateToLocalDatetime(data.endDate),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(data.endDate),"hh:mm A")}</td>
                                <td class={`text-nowrap ${data.tournamentEnded ? "text-danger" : "text-success"}`}>{data.tournamentEnded ? "Closed" : "Active"}</td>
                                </tr>
                            ))}
                        </table>
                    </div> : <p className='text-dark-secondary'> No participation in Hackathon.</p>}
                    {hackathonData && hackathonData?.tournamentData?.length>0 && hackathonData.totalPages &&<Pagination last_page={hackathonData.totalPages} updatePageNumber={(page)=>pageChangeHandler('hackathon',0,page)}/>}
                </div>
                <div class={`tab-pane fade ${(activeIndex === 1)? 'show active' : ''}`} id={`blogathon`} role="tabpanel" aria-labelledby={`blogathon-tab`}>
                  {blogathonData?.tournamentData?.length>0 ? <div class="table-responsive my-3">
                      <table class="table text-white background-dark-secondary table-striped">
                          <thead className='text-white border-bottom border-dark'>
                              <td>Contest Name</td>
                              <td>Start Date</td>
                              <td>End Date</td>
                              <td>Status</td>
                          </thead>
                          {blogathonData && blogathonData.tournamentData && blogathonData.tournamentData.length>0 && blogathonData.tournamentData.map((data,index)=>(
                              <tr key={index}>
                                <td class="text-nowrap"><a href={`/datahack/contest/${data.slug}`} className='text-decoration-none bg-transparent text-white'>{data.title}</a></td>
                                <td class="text-nowrap">{convertDateInFormat(convertDateToLocalDatetime(data.startDate),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(data.startDate),"hh:mm A")}</td>
                                <td class="text-nowrap">{convertDateInFormat(convertDateToLocalDatetime(data.endDate),"DD MMM YYYY")} • {convertDateInFormat(convertDateToLocalDatetime(data.endDate),"hh:mm A")}</td>
                                <td class={`text-nowrap ${data.tournamentEnded ? "text-danger" : "text-success"}`}>{data.tournamentEnded ? "Closed" : "Active"}</td>
                              </tr>
                          ))}
                      </table>
                  </div> : <p className='text-dark-secondary'> No participation in Blogathon.</p>}
                  {blogathonData && blogathonData?.tournamentData?.length>0 && blogathonData.totalPages && <Pagination last_page={blogathonData.totalPages} updatePageNumber={(page)=>pageChangeHandler('blogathon',1,page)}/>}
                </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default UserCompetitionsProfile;
import React from 'react'

export default function FractalModal() {
  return (
    <>
      <div className="modal lead-modal" data-bs-backdrop="static" id='genAiCoursesModal' tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>Courses <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-3">
              <a className='btn btn-dark btn-dark-primary mb-4 mx-auto d-table roadmap-btn' href="https://imgcdn.analyticsvidhya.com/fractal/GenAI_Fractal_Roadmap_v4.pdf" target='_blank' download>Download Roadmap</a>
              <ol className='text-white'>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/introduction-to-generative-ai">Introduction to Generative  AI</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/generative-ai">Generative AI: A Way of Life</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/human-decision-making-and-its-biases">Human Decision Making and its Biases</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/structured-approach-to-problem-solving">Structured Approach to Problem Solving</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/applied-machine-learning-ml">Applied Machine Learning</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/getting-started-with-deep-learning">Getting Started with Deep Learning</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/nlp-using-deep-learning">Natural Language Processing using PyTorch</a></li>
                {/* <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/introduction-to-nlp">Introduction to Natural Language Processing (NLP)</a></li> */}
                {/* <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/exploring-natural-language-processing-nlp-using-deep-learning">Exploring Natural Language Processing (NLP) using Deep Learning</a></li> */}
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/getting-started-with-llms">Getting Started with Large Language Models</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/building-llm-applications-using-prompt-engineering">Building LLM Applications using Prompt Engineering</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/building-end-to-end-generative-ai-applications">Building End-to-End Generative AI Applications</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/rag-systems-using-llamaindex">Building Production Ready RAG systems using LlamaIndex</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/finetuning-llms">Finetuning LLMs</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/training-llms-from-scratch/">Training LLMs from Scratch</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/getting-started-with-stable-diffusion/">Getting started with Stable Diffusion</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/mastering-methods-and-tools-of-stable-diffusion/">Mastering Methods and Tools of Stable Diffusion</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/advanced-stable-diffusion-techniques/">Advanced Stable Diffusion Techniques</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/responsible-ai-in-the-generative-ai-era">Responsible AI in the Generative AI Era</a></li>
              </ol>
            </div>
        </div>
        </div>
      </div>
      <div className="modal lead-modal" data-bs-backdrop="static" id='blackbeltCoursesModal' tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content background-dark-primary shadow-sm rounded-4">
            <div className="modal-header border-0 p-0">
              <h2 className='text-white fs-32'>Courses <span className='text-orange d-none'>20% off</span></h2>
              <button type="button" className="btn background-dark-secondary p-2 rounded-circle close" data-bs-dismiss="modal" aria-label="Close" >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M18 6L6 18M18 18L6 6.00001" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                </svg>
              </button>
            </div>
            <div className="modal-body p-0 pt-3">
            <a className='btn btn-dark btn-dark-primary mb-4 mx-auto d-table roadmap-btn' href="https://imgcdn.analyticsvidhya.com/fractal/BB PLUS Fractal Roadmap_v4.pdf" target='_blank' download>Download Roadmap</a>
              <ol className='text-white'>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/introduction-of-certified-ai-ml-blackbelt-program">Introduction of Certified AI & ML BlackBelt+ Program</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/Discovering-data-science-role">Discovering Data Science</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/structured-approach-to-problem-solving">Structured Approach to Problem Solving</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/human-decision-making-and-its-biases">Human Decision Making and its Biases</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/intro-to-python">Introduction to Python</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/python-for-data-science">Python for Data Science</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/eda-statistics-for-data-science">EDA & Statistics for Data science</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/building-your-first-ml-model">Building your First ML model</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/foundational-ml-algorithms-i">Foundational ML Algorithms</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/foundational-ml-algorithms-ii">Foundational ML Algorithms II</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/advanced-ml-algorithms">Advanced ML Algorithms</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/unsupervised-ml-models">Unsupervised ML models</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/behavior-architecture-understanding-human-behavior">Behavior Architecture - Understanding Human Behavior</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/retail-demand-prediction-using-machine-learning">Retail Demand Prediction using Machine Learning</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/insights-of-power-bi">Insights of Power BI</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/tableau-2-0">Tableau 2.0</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/microsoft-excel-from-beginner-to-advanced-2-0">Microsoft Excel: From Beginner to Advanced-2.0</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/data-analysis-using-sql">Data Analysis using SQL</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/creating-time-series-forecast-using-python">Time Series Forecasting using Python</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/recommender-systems-with-python">Recommender Systems with Python</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/introduction-to-deep-learning-using-pytorch">Introduction to Deep Learning using PyTorch</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/Applied-Computer-Vision-using-Deep-Learning">Applied Computer Vision using Deep Learning</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/nlp-using-deep-learning">Natural Language Processing using PyTorch</a></li>
                {/* <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/getting-started-with-nlp">Getting Started with NLP</a></li> */}
                {/* <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/Natural-Language-Processing-NLP-Using-Deep-learning">Natural Language Processing (NLP) Using Deep Learning</a></li> */}
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/ai-and-ml-for-business-leaders">AI and ML for Business Leaders</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/responsible-ai-principles-and-ethical-considerations">Responsible AI - Principles and Ethical Considerations</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/overview-of-data-engineering">Overview of Data Engineering</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/Advanced-Python-Programming-and-Software-Engineering-Fundamentals">Advanced Python Programming and Software Engineering Fundamentals</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/fundamentals-of-apache-hadoop">Fundamentals of Apache Hadoop</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/fundamentals-of-apache-hive">Fundamentals of Apache Hive</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/mastering-apache-spark-using-python">Mastering Apache Spark using Python</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/introduction-to-spark-streaming">Introduction to Spark Streaming</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/model-interpretability">Model Interpretability</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/mastering-mlops-development-to-deployment">Mastering MLOps - Development to Deployment</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/fundamentals-of-microsoft-azure">Fundamentals of Microsoft Azure</a></li>
                <li className="mb-2"><a className="text-white text-decoration-none" target='_blank' href="https://id.analyticsvidhya.com/sso/custom/thinkific/login?return_to=https://courses.analyticsvidhya.com/courses/take/mongodb">Fundamentals of MongoDB</a></li>
                </ol>
            </div>
        </div>
        </div>
      </div>
    </>
  )
}

import { combineReducers } from "redux";
import { blackbeltContentReducer } from "./blackbeltreducers";
import { genAIContentReducer } from "./genaireducers";
import { homepageContentReducer } from "./homepagereducers";
import { blogContentReducer } from './blogreducers';
import { datahourContentReducer } from './datahourreducers';
import { hackathonContentReducer } from "./hackathonreducers";
import { LoginModalReducer } from "./loginreducers";
import { competitionContentReducer } from "./competitionreducers";
import { freeCourseContentReducer } from "./freecoursesreducer";
import { eventContentReducer } from "./eventreducers";
import { commentReducer } from "./commentreducers";
import { leadingWithDataContentReducer } from "./leadingwithdatareducers";
import { genaiLeadPagesContentReducer } from "./genaileadpagesreducer";
import { blackbeltLeadPagesContentReducer } from "./blackbeltleadpagesreducer";
import { agenticAIContentReducer } from "./agenticaireducers";
import { agenticaiLeadPagesContentReducer } from "./agenticaileadpagesreducer";

const rootReducer = combineReducers({
    genAIContentReducer,
    blackbeltContentReducer,
    homepageContentReducer,
    blogContentReducer,
    datahourContentReducer,
    hackathonContentReducer,
    LoginModalReducer,
    competitionContentReducer,
    freeCourseContentReducer,
    eventContentReducer,
    commentReducer,
    leadingWithDataContentReducer,
    genaiLeadPagesContentReducer,
    blackbeltLeadPagesContentReducer,
    agenticAIContentReducer,
    agenticaiLeadPagesContentReducer,
})
export default rootReducer;